import PropType from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../../../../configs/constant';
import generateSafetySchema from '../../../../Util/measurement/safetySchema';
import measurementMeasuredMinMaxCommentTableHeader from '../Util/measurementMeasuredMinMaxCommentTableHeader';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { safetyTableId } from './constant';

const BlankMeasurementDataSheetSafetyTable = ({ measurement }) => {
  const { formatMessage } = useIntl();
  const metaDataColumns = measurementMeasuredMinMaxCommentTableHeader(
    MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
    formatMessage,
    'cellKeySafety',
    <FormattedMessage
      id="measurement.safetyTable.title"
      defaultMessage="SAFETY"
    />
  );

  const generatedData = generateSafetySchema(measurement);

  return (
    <BaseTable
      className="generated-file-measurement-table measurement-safety-table"
      columns={metaDataColumns}
      dataSource={generatedData}
      pagination={false}
      tableElementId={safetyTableId}
    />
  );
};

BlankMeasurementDataSheetSafetyTable.propTypes = {
  measurement: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetSafetyTable;
