import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../../../../../configs/constant';
import {
  capitalizeFirstLetter,
  changePointToCommaExport,
  changePointToCommaWithDecimals
} from '../../../../../../../../utils/common';

const lastMinMaxRowIndex = 5;

function renderMinMax(value, row, index) {
  if (index <= lastMinMaxRowIndex) {
    if (
      row.testTitleKey.id ===
      MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID.densityDIN51757
    ) {
      return changePointToCommaWithDecimals(value, 3);
    }
    return changePointToCommaExport(value);
  }

  return { props: { colSpan: 0 } };
}

export default formatMessage => {
  return [
    {
      title: (
        <FormattedMessage id="detergent.testType" defaultMessage="Test Type" />
      ),
      dataIndex: 'testTitleKey',
      render: value => {
        // TODO: add default message
        const { id, defaultMessage } = value;
        return formatMessage({ id, defaultMessage });
      },
      width: 150
    },
    {
      title: (
        <FormattedMessage
          id="detergent.targetValue"
          defaultMessage="Target value"
        />
      ),
      dataIndex: 'measured',
      render: (value, data) => {
        if (
          data.testTitleKey.id ===
          MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID.refrectionIndex
        ) {
          return changePointToCommaWithDecimals(value, 4);
        }
        if (
          data.testTitleKey.id ===
          MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID.densityDIN51757
        ) {
          return changePointToCommaWithDecimals(value, 3);
        }
        return changePointToCommaExport(value);
      },
      width: 52
    },
    {
      title: (
        <FormattedMessage
          id="detergent.tolerances"
          defaultMessage="Tolerances"
        />
      ),
      children: [
        {
          title: <FormattedMessage id="detergent.min" defaultMessage="min" />,
          dataIndex: 'min',
          render: renderMinMax,
          width: 45
        },
        {
          title: <FormattedMessage id="detergent.max" defaultMessage="max" />,
          dataIndex: 'max',
          render: renderMinMax,
          width: 45
        }
      ],
      width: 90
    },
    {
      title: 'measured',
      colSpan: 0,
      // dataIndex: 'measured',
      render: (value, row, index) => {
        return index <= lastMinMaxRowIndex
          ? { props: { colSpan: 0 } }
          : {
              props: { colSpan: 2, width: 90 },
              children: value
            };
      }
    },
    {
      title: (
        <FormattedMessage
          id="measurement.measured.title"
          defaultMessage="Measured value"
        />
      ),
      children: [
        {
          title: (
            <FormattedMessage
              id="detergent.tester.qualityControl"
              defaultMessage="Tester quality control"
            />
          ),
          dataIndex: 'testerQualityControl',
          width: 115
        },
        {
          title: (
            <FormattedMessage
              id="detergent.tester.production"
              defaultMessage="Tester production"
            />
          ),
          dataIndex: 'testerProduction',
          width: 115
        }
      ],
      width: 230
    },
    {
      title: <FormattedMessage id="comment.title" defaultMessage="Comment" />,
      dataIndex: 'comment',
      render: value => capitalizeFirstLetter(value)
    }
  ];
};
