import { put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  requestPresignedUrlAndUploadSuccess,
  REQUEST_PRESIGNED_URL_AND_UPLOAD,
  requestPresignedUrlAndUploadError,
  QUEUE_EMAIL,
  queueEmailSuccess,
  queueEmailError,
  GET_PRECALCULATION_XLSX_AS_BASE64,
  requestPresignedUrlAndUpload
} from './action';
import { getPresignedUrlAndUpload } from '../../../../../../utils/s3Service';
import { postEmailQueue } from './api';
import { errorHandle } from '../../../../../../utils/common';
import { downloadPrecalculationApi } from '../../../../data/detergent/api';
import base64ToBytesConverter from '../../../Util/base64ToBytesConverter';

export function* requestPresignedUrlAndUploadSaga(action) {
  const { payload } = action;
  try {
    yield getPresignedUrlAndUpload(
      payload.name,
      payload.contentType,
      payload.file
    );
    yield put(requestPresignedUrlAndUploadSuccess(payload.name));
  } catch (error) {
    errorHandle(error);
    yield put(requestPresignedUrlAndUploadError(payload.name));
  }
}

export function* queueEmailSaga(action) {
  const { payload } = action;
  try {
    yield postEmailQueue(payload);
    yield put(queueEmailSuccess(payload.name));
  } catch (error) {
    errorHandle(error);
    yield put(queueEmailError(payload.name));
  }
}

export function* getPrecalculationXlsxAsBase64Saga(action) {
  const { payload } = action;
  const { detergent, languageCode } = payload;
  const formulaId = detergent.formulas?.[0].id;
  const fileName = languageCode === "en" ? `Precalculation - ${detergent.name}-${detergent.formulas?.[0].version}.csv` :`Vorkalkulation - ${detergent.name}-${detergent.formulas?.[0].version}.csv`;
  try {
    const response = yield downloadPrecalculationApi(formulaId, languageCode);
    const byteArrays = base64ToBytesConverter(response.data.data);
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    yield put(
      requestPresignedUrlAndUpload({
        name: `${detergent.name}-${detergent.formulas?.[0].version}/${fileName}`,
        contentType: contentType,
        file: new Blob(byteArrays, { type: contentType })
      })
    );
  } catch (error) {
    errorHandle(error);
    yield put(requestPresignedUrlAndUploadError(fileName));
  }
}

export default function* procurementEmailSaga() {
  yield takeEvery(
    REQUEST_PRESIGNED_URL_AND_UPLOAD,
    requestPresignedUrlAndUploadSaga
  );
  yield takeLeading(QUEUE_EMAIL, queueEmailSaga);
  yield takeLeading(
    GET_PRECALCULATION_XLSX_AS_BASE64,
    getPrecalculationXlsxAsBase64Saga
  );
}
