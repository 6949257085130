import { formatDateByMoment } from '../../../../../utils/common';
import { DATE_FORMAT_UTC } from '../../../../../configs/constant';

export const transformProductionCycleDataBeforeSaving = (
  values,
  groupPartNumbersById,
  selectedProductionCycle = null // edit single production cycle
) => {
  const productionCycles = [];

  if (values.productionCycles) {
    if (selectedProductionCycle) {
      const selectedProductionCycleKeyId = `id_${selectedProductionCycle.id}`;
      const selectedProductionCycleValue =
        values.productionCycles[selectedProductionCycleKeyId];
      values.productionCycles = {
        [selectedProductionCycleKeyId]: selectedProductionCycleValue
      };
    }

    Object.keys(values.productionCycles).forEach(key => {
      const cycleId = parseFloat(key.split('_')[1]);
      const currentValue = values.productionCycles[key];

      const partNumbers = currentValue.partNumbers
        .map(partNumberId => {
          if (!groupPartNumbersById[partNumberId]) return null;

          return {
            id: partNumberId,
            partNumber: groupPartNumbersById[partNumberId]?.partNumber
          };
        })
        .filter(e => !!e);

      const productionCycleProducer =
        currentValue.partNumbers.length > 0 &&
        groupPartNumbersById[currentValue.partNumbers[0]]?.detergentProducer
          ? {
              productionCycleProducerId:
                groupPartNumbersById[currentValue.partNumbers[0]]
                  .detergentProducer.id,
              productionCycleProducerName:
                groupPartNumbersById[currentValue.partNumbers[0]]
                  .detergentProducer.name
            }
          : {
              productionCycleProducerId: null,
              productionCycleProducerName: ''
            };

      const producerAccessDate = formatDateByMoment(
        currentValue.producerAccessDate,
        DATE_FORMAT_UTC
      );

      const productionDate = formatDateByMoment(
        currentValue.productionDate,
        DATE_FORMAT_UTC
      );

      const productionCycle = {
        ...currentValue,
        id: cycleId,
        partNumbers,
        producerAccessDate,
        productionDate,
        ...productionCycleProducer
      };

      productionCycles.push(productionCycle);
    });
  }

  return productionCycles;
};
