import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  renderRowFields,
  renderColumnsWithinRowFields,
  renderColumnsWithinRowFieldsProjectType
} from './renderFieldsFunction';
import { UPLOAD_TYPES } from '../../../../../configs/constant';
import PreviewFilesDetail from '../../../../../components/BaseUpload/PreviewFilesDetail';
import {
  getDensityLabel,
  getFullNameFromEmail,
  changePointToComma
} from '../../../../../utils/common';
import { validateDocumentCategories } from '../../../../../utils/validator';
import { UploadDocuments } from '../../../../../components/BaseUpload/UploadDocuments';
import { ProductionCyclesSelection } from '../EditModal/ProductionCyclesSelection';

export const ViewDetergent = ({
  detergent,
  intl,
  areEditingProductionCycles,
  isComparingDetergents,
  form: { getFieldDecorator }
}) => {
  const { formatMessage } = intl;
  const creationDate = detergent?.formulas?.[0]?.createAt;
  const approvedDate = detergent?.formulas?.[0]?.approvedDate;

  const dateFormation = date => (date ? intl.formatDate(date) : null);

  const formulaDocuments = _.get(detergent, 'formulas.0.documents', null);
  const densityLabel = _.get(detergent, 'formulas.0.form', '');
  const densityValue = _.get(detergent, 'formulas.0.density', '');

  return (
    <>
      {renderRowFields(
        formatMessage({
          id: 'detergent.number',
          defaultMessage: 'Detergent Number'
        }),
        _.get(detergent, 'number', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'detergent.name',
          defaultMessage: 'Detergent Name'
        }),
        _.get(detergent, 'name', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'dbFromCountry',
          defaultMessage: 'Country'
        }),
        _.get(detergent, 'fromCountry.name', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'formula.version',
          defaultMessage: 'Formula Version'
        }),
        _.get(detergent, 'formulas.0.version', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'formula.type',
          defaultMessage: 'Formula type'
        }),
        _.get(detergent, 'formulas.0.sample', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'formula.form',
          defaultMessage: 'Form'
        }),
        _.get(detergent, 'formulas.0.form', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        getDensityLabel(densityLabel),
        changePointToComma(densityValue),
        isComparingDetergents
      )}

      {areEditingProductionCycles ? (
        <Form.Item className="upload-detergent">
          <div className="row-detail">
            <div className="label">
              <FormattedMessage
                id="detergent.upload"
                defaultMessage="Upload documents"
              />
            </div>
            <div className="value">
              {getFieldDecorator(`documents`, {
                valuePropName: 'fileList',
                initialValue: detergent?.formulas?.[0]?.documents,
                rules: [
                  {
                    validator: validateDocumentCategories(intl.formatMessage)
                  }
                ]
              })(
                <UploadDocuments
                  type={UPLOAD_TYPES.DETERGENT}
                  expandSelection={(fileItem, onChangeDocumentProperty) => (
                    <ProductionCyclesSelection
                      fileItem={fileItem}
                      onChangeDocumentProperty={onChangeDocumentProperty}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </Form.Item>
      ) : (
        renderRowFields(
          formatMessage({
            id: 'detergent.formulas.documents',
            defaultMessage: 'Documents'
          }),
          <PreviewFilesDetail fileList={formulaDocuments} />,
          isComparingDetergents
        )
      )}

      {renderRowFields(
        formatMessage({
          id: 'formula.instructions',
          defaultMessage: 'Production instructions'
        }),
        _.get(detergent, 'formulas.0.productionInstruction', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'detergent.description.properties',
          defaultMessage: 'Description of properties'
        }),
        _.get(detergent, 'formulas.0.propertyDescription', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'formula.note',
          defaultMessage: 'Note on formula changes'
        }),
        _.get(detergent, 'formulas.0.noteOnFormulaChanges', ''),
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'formula.status',
          defaultMessage: 'Formula Status'
        }),
        _.get(detergent, 'formulas.0.status', ''),
        isComparingDetergents
      )}
      {renderColumnsWithinRowFields(
        [
          {
            label: (
              <FormattedMessage
                id="productionCycle.columnCreateDate"
                defaultMessage="Creation Date"
              />
            ),
            value: dateFormation(creationDate)
          },
          {
            label: (
              <FormattedMessage
                id="detergent.label.approvalDate"
                defaultMessage="Approval Date"
              />
            ),
            value: dateFormation(approvedDate)
          }
        ],
        isComparingDetergents
      )}
      {renderColumnsWithinRowFields(
        [
          {
            label: (
              <FormattedMessage
                id="formula.creator"
                defaultMessage="Formula creator"
              />
            ),
            value: (
              <span style={{ textTransform: 'capitalize' }}>
                {getFullNameFromEmail(detergent?.formulas?.[0]?.creatorEmail)}
              </span>
            )
          },
          {
            label: (
              <FormattedMessage
                id="detergent.label.formulaApprover"
                defaultMessage="Formula approver"
              />
            ),
            value: (
              <span style={{ textTransform: 'capitalize' }}>
                {getFullNameFromEmail(detergent?.formulas?.[0]?.approverEmail)}
              </span>
            )
          }
        ],
        isComparingDetergents
      )}
      {renderRowFields(
        formatMessage({
          id: 'detergent.label.formulaDeveloper',
          defaultMessage: 'Formula developer'
        }),
        <span style={{ textTransform: 'capitalize' }}>
          {getFullNameFromEmail(detergent?.formulas?.[0]?.developerEmail)}
        </span>,
        isComparingDetergents
      )}
      {renderColumnsWithinRowFieldsProjectType(
        [
          {
            label: (
              <FormattedMessage
                id="formula.projectType"
                defaultMessage="Project type"
              />
            ),
            value: detergent?.formulas?.[0]?.projectType
          },
          {
            label: (
              <FormattedMessage
                id="formula.projectNumber"
                defaultMessage="Project number"
              />
            ),
            value: detergent?.formulas?.[0]?.projectNumber
          }
        ],
        isComparingDetergents
      )}
    </>
  );
};

ViewDetergent.propTypes = {
  detergent: PropTypes.objectOf(PropTypes.any).isRequired,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  areEditingProductionCycles: PropTypes.bool.isRequired,
  isComparingDetergents: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func
  }).isRequired
};
