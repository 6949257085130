import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Input, Form, Select, Icon } from 'antd';
import {
  renderRowStatus,
  renderRowFields
} from '../../../Util/renderRowFields';
import {
  FORM_STATUS,
  PART_NUMBER_STATUS
} from '../../../../../configs/constant';
import { ContainerSize } from '../../ContainerSize';
import { DetergentSelection } from '../../DetergentSelection';
import { DetergentProducerField } from '../../DetergentProducerField';
import { AdditionalDetergentProducerField } from '../../AdditionalDetergentProducerField';
import { validatorFormatPatNumber } from '../../../../../utils/validatorFormatPartNumber';
import Country from '../../../../../components/Country';

export const EditPartNumber = props => {
  const {
    form,
    form: { getFieldDecorator },
    partNumberDetailsData,
    detergentProducerNonPaging,
    detergentNonPaging,
    handleDensityChange
  } = props;

  const { formatMessage, formatDate } = useIntl();

  return (
    <div className="part-number edit-form-fields">
      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.columnPartNumber"
            defaultMessage="Part number"
          />
        }>
        {getFieldDecorator('partNumber.partNumber', {
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'common.validatePartNumber',
                defaultMessage: 'Part number is required'
              })
            },
            {
              validator: validatorFormatPatNumber(formatMessage)
            }
          ],
          initialValue: partNumberDetailsData.partNumber
        })(<Input />)}
      </Form.Item>
      <Country
        getFieldDecorator={getFieldDecorator}
        initValue={partNumberDetailsData.fromCountryId}
        field="partNumber.fromCountryId"
      />
      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.columnSAPDescription"
            defaultMessage="SAP description"
          />
        }>
        {getFieldDecorator('partNumber.descriptionSAP', {
          initialValue: partNumberDetailsData.descriptionSAP
        })(<Input />)}
      </Form.Item>

      <DetergentSelection
        form={form}
        handleDensityChange={handleDensityChange}
        detergentNonPaging={detergentNonPaging}
        formStatus={FORM_STATUS.UPDATE}
        initialDetergentIdValue={_.get(
          partNumberDetailsData,
          'detergentFormula.detergentId',
          ''
        )}
        initialFormulaIdValue={_.get(
          partNumberDetailsData,
          'detergentFormula.version',
          ''
        )}
      />

      <ContainerSize
        form={form}
        formStatus={FORM_STATUS.UPDATE}
        initialContainerTypeValue={partNumberDetailsData}
      />

      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.columnStatus"
            defaultMessage="Product Status"
          />
        }>
        {getFieldDecorator('partNumber.status', {
          rules: [
            {
              required: true
            }
          ],
          initialValue: partNumberDetailsData.status
        })(
          <Select suffixIcon={<Icon type="caret-down" />}>
            {PART_NUMBER_STATUS.map(status => {
              return (
                <Select.Option key={status.value} value={status.value}>
                  {status.name}
                </Select.Option>
              );
            })}
          </Select>
        )}
      </Form.Item>

      <DetergentProducerField
        form={form}
        detergentProducerNonPaging={detergentProducerNonPaging}
        initialValue={_.get(partNumberDetailsData, 'detergentProducer.id', '')}
      />

      <AdditionalDetergentProducerField
        form={form}
        detergentProducerNonPaging={detergentProducerNonPaging}
        initialValue={_.get(
          partNumberDetailsData,
          'additionalDetergentProducer.id',
          ''
        )}
      />

      {renderRowStatus(
        formatMessage({
          id: 'partNumbers.columnFormulaStatus',
          defaultMessage: 'Formula status'
        }),
        _.get(partNumberDetailsData, 'detergentFormula.status', '')
      )}

      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnFormulaApprovalDate',
          defaultMessage: 'Formula approval date'
        }),
        partNumberDetailsData?.detergentFormula?.approvedDate
          ? formatDate(partNumberDetailsData?.detergentFormula?.approvedDate)
          : null
      )}
    </div>
  );
};

EditPartNumber.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired
  }).isRequired,
  partNumberDetailsData: PropTypes.shape({
    partNumber: PropTypes.string.isRequired,
    fromCountryId: PropTypes.number.isRequired,
    descriptionSAP: PropTypes.string,
    status: PropTypes.string.isRequired,
    detergentFormula: PropTypes.shape({
      approvedDate: PropTypes.string
    })
  }).isRequired,
  detergentProducerNonPaging: PropTypes.shape({}).isRequired,
  detergentNonPaging: PropTypes.shape({}).isRequired,
  handleDensityChange: PropTypes.func.isRequired
};
