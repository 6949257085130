import React, { useEffect } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ProducerCycleList from './List';
import { STATUS_ENUM } from '../../../../../../configs/constant';
import { ProtectedComponent } from '../../../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../../../configs/rolesOfPermissions';
import { getDetergentProducerNonPaging } from '../../../../../../data/detergentProducerNonPaging/action';

const ProductionCycles = ({
  formulaId,
  onAddNewProductionCycle,
  formularStatus,
  onEditProductionCyle,
  onDeleteProductionCycle
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetergentProducerNonPaging());
  }, []);

  return (
    <>
      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.CREATE}>
        {formularStatus === STATUS_ENUM.APPROVED ? (
          <div className="text-right production-cycle-buttons">
            <Button
              icon="plus"
              className="yellow-button"
              onClick={onAddNewProductionCycle}>
              <FormattedMessage
                id="detergent.addNewProductionCycle"
                defaultMessage="Add New Production Cycle"
              />
            </Button>
          </div>
        ) : null}
      </ProtectedComponent>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.READ}>
        <ProducerCycleList
          formulaId={formulaId}
          onEditProductionCyle={onEditProductionCyle}
          onDeleteProductionCycle={onDeleteProductionCycle}
        />
      </ProtectedComponent>
    </>
  );
};

ProductionCycles.propTypes = {
  formulaId: PropTypes.number.isRequired,
  onAddNewProductionCycle: PropTypes.func.isRequired,
  onEditProductionCyle: PropTypes.func.isRequired,
  onDeleteProductionCycle: PropTypes.func.isRequired,
  formularStatus: PropTypes.string.isRequired
};

export default ProductionCycles;
