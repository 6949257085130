import { USER_ROLES } from './constant';

export const ROLES_OF_PERMISSIONS = {
  RAW_MATERIAL_PRODUCERS_SUPPLIERS: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  RAW_MATERIAL_GROUPS: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  DETERGENT_PRODUCERS: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  RAW_MATERIALS: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  DETERGENTS_AND_FORMULAS: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  PRODUCTION_CYCLES: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  PART_NUMBER_ALLOCATION: {
    CREATE: [
      USER_ROLES.Administrator,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.DetergentDevelopment
    ],
    EDIT: [
      USER_ROLES.Administrator,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.DetergentDevelopment
    ],
    DEACTIVATE: [
      USER_ROLES.Administrator,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers
    ],
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  },
  USER_ADMINISTRATION: {
    CREATE: [USER_ROLES.Administrator],
    EDIT: [USER_ROLES.Administrator],
    DEACTIVATE: [USER_ROLES.Administrator],
    READ: [USER_ROLES.Administrator]
  },
  CONTAINER_TYPE: {
    READ: [
      USER_ROLES.Administrator,
      USER_ROLES.DetergentDevelopment,
      USER_ROLES.Procurement,
      USER_ROLES.ExternalDetergentProducers,
      USER_ROLES.InHouseProduction
    ]
  }
};
