import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FileSelectorCheckBox from '../FileSelectorCheckBox';
import '../GeneratingFileSelectorModal.scss';
import { createPdfRenderer, addPdfPage } from './pdf';
import { DOCUMENTS_FOR_PROCUREMENT } from '../../../../../../configs/constant';

const GeneratedFileCheckBoxes = ({
  isOkClicked,
  isCancel,
  isDocumentsForProcurementCheckBoxSelected,
  onPDFRendered,
  initialPdfCheckBoxes
}) => {
  const { detergent } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent
  }));

  const [fileCheckBoxes, setFileCheckboxes] = useState(initialPdfCheckBoxes);

  const isFileCheckBoxesSelected = useMemo(
    () => !!fileCheckBoxes.find(file => file.isSelected),
    [fileCheckBoxes]
  );

  const pdfRenderer = useMemo(() => {
    const selectedCheckBox = fileCheckBoxes.find(file => file.isSelected);
    if (isOkClicked && selectedCheckBox) {
      return createPdfRenderer();
    }
    // free the object to ensure no memory leak
    return null;
  }, [isOkClicked]);

  const selectedUnrenderedFileCheckBox = useMemo(() => {
    const selectedCheckBox = fileCheckBoxes.find(
      file => file.isSelected && !file.isRendered
    );
    if (pdfRenderer && selectedCheckBox) {
      addPdfPage(pdfRenderer);
    }
    return selectedCheckBox;
  }, [fileCheckBoxes]);

  useEffect(() => {
    if (isOkClicked && !selectedUnrenderedFileCheckBox) {
      onPDFRendered({
        pdfRenderer: isFileCheckBoxesSelected ? pdfRenderer : null,
        fileName: `${detergent.name}-${detergent.formulas?.[0].version}`
      });
    }
  }, [isOkClicked, selectedUnrenderedFileCheckBox, pdfRenderer]);

  useEffect(() => {
    if (isCancel) {
      setFileCheckboxes(initialPdfCheckBoxes);
      return;
    }
    const newFileCheckBoxes = [...fileCheckBoxes];
    newFileCheckBoxes.forEach(fileCheckBox => {
      if (DOCUMENTS_FOR_PROCUREMENT.includes(fileCheckBox.fileName)) {
        fileCheckBox.isSelected = isDocumentsForProcurementCheckBoxSelected;
      }
    });
    setFileCheckboxes(newFileCheckBoxes);
  }, [isDocumentsForProcurementCheckBoxSelected, isCancel]);

  // optional blob
  const onPDFRenderedComplete = () => {
    // tag the current state to be render and
    selectedUnrenderedFileCheckBox.isRendered = true;
    setFileCheckboxes([...fileCheckBoxes]);
  };

  return (
    <>
      {fileCheckBoxes.map((fileCheckBox, index) => {
        return (
          <FileSelectorCheckBox
            key={fileCheckBox.formattedMessageId}
            formattedMessageId={fileCheckBox.formattedMessageId}
            defaultMessage={fileCheckBox.defaultMessage}
            checked={fileCheckBox.isSelected}
            onChange={() => {
              const newFileCheckBoxes = [...fileCheckBoxes];
              newFileCheckBoxes[index].isSelected = !newFileCheckBoxes[index]
                .isSelected;
              setFileCheckboxes(newFileCheckBoxes);
            }}
            disabled={isOkClicked}
          />
        );
      })}
      {selectedUnrenderedFileCheckBox &&
        selectedUnrenderedFileCheckBox.refs.map((ref, index) => {
          const renderedComponent =
            selectedUnrenderedFileCheckBox.components[index];
          return (
            <div className="rendered-container" ref={ref}>
              <div className="rendered-container__content">
                {React.createElement(renderedComponent, {
                  pdfRenderer,
                  onPDFRenderedComplete,
                  isRenderPdfNow:
                    isOkClicked && !selectedUnrenderedFileCheckBox.isRendered,
                  componentProps:
                    selectedUnrenderedFileCheckBox.componentProps || {}
                })}
              </div>
            </div>
          );
        })}
    </>
  );
};

GeneratedFileCheckBoxes.propTypes = {
  isOkClicked: PropTypes.bool.isRequired,
  isCancel: PropTypes.bool.isRequired,
  isDocumentsForProcurementCheckBoxSelected: PropTypes.bool.isRequired,
  onPDFRendered: PropTypes.func.isRequired,
  initialPdfCheckBoxes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default GeneratedFileCheckBoxes;
