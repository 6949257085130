import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import LabelField from './LabelField';
import Style from './style';
import ValueField from './ValueField';

const Header = () => {
  const { detergent } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent
  }));

  return (
    <section style={Style.container}>
      <section style={{ width: '360px' }}>
        <LabelField
          className="production-test-protocol-label"
          label={
            <FormattedMessage
              id="detergent.testProtocol"
              defaultMessage="Test protocol number:"
            />
          }
        />
        <LabelField
          className="production-test-protocol-label"
          label={
            <FormattedMessage
              id="detergent.testDevelopment"
              defaultMessage="Tester development/ quality control:"
            />
          }
        />
        <LabelField
          className="production-test-protocol-label"
          label={
            <FormattedMessage id="detergent.date" defaultMessage="Date:" />
          }
        />
        <LabelField
          className="production-test-protocol-label"
          label={
            <FormattedMessage
              id="detergent.location"
              defaultMessage="Location:"
            />
          }
        />
        <LabelField
          className="production-test-protocol-label"
          label={
            <FormattedMessage
              id="detergent.orderNumber"
              defaultMessage="Order Number:"
            />
          }
        />
      </section>
      <section style={{ width: '360px' }}>
        <section>
          <ValueField
            label={
              <FormattedMessage
                id="detergent.numberExport"
                defaultMessage="Detergent number:"
              />
            }
            value={detergent.number}
            valueClass="generated-file-detergent-name"
            labelClass="generated-file-detergent-name-label"
            containerClass="generated-file-detergent-name-container"
          />
          <ValueField
            label={
              <FormattedMessage
                id="detergent.nameExport"
                defaultMessage="Detergent name:"
              />
            }
            value={detergent.name}
            valueClass="generated-file-detergent-name"
            containerClass="generated-file-detergent-name-container"
            labelClass="generated-file-detergent-name-label"
          />
          <LabelField
            className="production-test-protocol-batch-label"
            label={
              <FormattedMessage
                id="detergent.batchNumber"
                defaultMessage="Batch number:"
              />
            }
          />
          <LabelField
            className="production-test-protocol-batch-label"
            label={
              <FormattedMessage
                id="detergent.supplier"
                defaultMessage="Supplier:"
              />
            }
          />
        </section>
      </section>
    </section>
  );
};

export default Header;
