import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Select, Icon } from 'antd';
import EditableColumnField from '../../../../components/EditableColumnField';
import { CURRENCIES } from '../../../../configs/constant';
import {
  priceOfOneKilogramOfDetergent,
  priceOfDetergentContainer,
  changePointToComma,
  changeRatioDotToComma
} from '../../../../utils/common';

export const renderPrecalculationColumns = isEditable => [
  {
    title: (
      <span className="raw-material-column-title">
        <FormattedMessage
          id="rawMaterial.title"
          defaultMessage="RAW MATERIALS"
        />
      </span>
    ),
    key: 'rawMaterials',
    width: '22%',
    render: record => (
      <span className="text-bold text-black">{record.rawMaterial.name}</span>
    )
  },
  {
    title: (
      <FormattedMessage id="rawMaterial.columnRatio" defaultMessage="Ratio" />
    ),
    key: 'Ratio',
    width: '10%',
    render: record => {
      return (
        <EditableColumnField
          editable={isEditable}
          readableField={
            <Input
              className="hide-fields"
              value={`${changeRatioDotToComma(record.ratio)}%`}
              disabled
            />
          }
          // hide-fields
          editableField={
            <Input
              value={`${changeRatioDotToComma(record.ratio)}%`}
              className=" precalculation-ratio"
              disabled
            />
          }
        />
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="detergent.columnPricePerKg"
        defaultMessage="Price per kg"
      />
    ),
    key: 'Price',
    width: '15%',
    render: record => {
      return (
        <EditableColumnField
          columnKey="price"
          cellKey={record.rawMaterialId}
          tabName="precalculation"
          editable={isEditable}
          readableField={
            <Input
              defaultValue={changePointToComma(record.rawMaterial.price)}
              disabled
              type="string"
              className="hide-fields"
            />
          }
          editableField={
            <Input
              defaultValue={changePointToComma(record.rawMaterial.price)}
              disabled={false}
              type="string"
            />
          }
        />
      );
    }
  },
  {
    title: '',
    key: 'Currency',
    width: '9%',
    render: record => {
      return (
        <EditableColumnField
          columnKey="currency"
          cellKey={record.rawMaterialId}
          tabName="precalculation"
          editable={isEditable}
          readableField={
            <Select
              placeholder={
                <FormattedMessage
                  id="placeholder.type"
                  defaultMessage="Select type"
                />
              }
              className="precalculation-currency hide-select-fields"
              suffixIcon={<Icon type="caret-down" />}
              defaultValue={record.rawMaterial.currency}
              disabled
              loading>
              {CURRENCIES.map(currency => (
                <Select.Option key={currency.value} value={currency.name}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          }
          editableField={
            <Select
              placeholder={
                <FormattedMessage
                  id="placeholder.type"
                  defaultMessage="Select type"
                />
              }
              disabled={false}
              className="precalculation-currency"
              suffixIcon={<Icon type="caret-down" />}
              defaultValue={record.rawMaterial.currency}
              loading>
              {CURRENCIES.map(currency => (
                <Select.Option key={currency.value} value={currency.name}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          }
        />
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="detergent.columnPricePerKgOfDetergent"
        defaultMessage="Price per 1 kg of detergent"
      />
    ),
    key: 'PriceOfDetergent',
    width: '22%',
    render: record => {
      return (
        <EditableColumnField
          editable={isEditable}
          readableField={
            <Input
              className="hide-fields"
              value={changePointToComma(priceOfOneKilogramOfDetergent(record))}
              disabled
              type="string"
            />
          }
          editableField={
            <Input
              value={changePointToComma(priceOfOneKilogramOfDetergent(record))}
              disabled
              type="string"
            />
          }
        />
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="detergent.columnPricePerContainer"
        defaultMessage="Price per container"
      />
    ),
    key: 'PriceOfDetergentContainer',
    width: '22%',
    render: record => {
      return (
        <EditableColumnField
          editable={isEditable}
          readableField={
            <Input
              className="hide-fields"
              value={changePointToComma(priceOfDetergentContainer(record))}
              disabled
              type="string"
            />
          }
          editableField={
            <Input
              value={changePointToComma(priceOfDetergentContainer(record))}
              disabled
              type="string"
            />
          }
        />
      );
    }
  }
];

export const footerColumns = [
  {
    width: '22%',
    key: 'rawMaterials'
  },
  {
    width: '10%',
    key: 'ratios'
  },
  {
    width: '15%',
    key: 'pricePerKg'
  },
  {
    width: '9%',
    key: 'currencies'
  },
  {
    width: '22%',
    key: 'pricePerDetergent',
    render: record => {
      const sumOfDetergentPrice = changePointToComma(
        record.sumOfDetergentPrice
      );
      return (
        <>
          <span className="sum-prices-title">
            <FormattedMessage
              id="detergent.sumOfAbove"
              defaultMessage="Sum of above"
            />
          </span>
          <span className="sum-prices">{sumOfDetergentPrice}</span>
        </>
      );
    }
  },
  {
    width: '25%',
    key: 'pricePerDetergentContainer',
    render: record => {
      const sumOfDetergentContainer = changePointToComma(
        record.sumOfDetergentContainerPrice
      );
      return <span className="sum-prices">{sumOfDetergentContainer}</span>;
    }
  }
];
