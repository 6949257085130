import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { ACTION_BUTTON_TYPES, STATUS_ENUM } from '../../../../configs/constant';
import { locationString, renderProducerNames } from '../../../../utils/common';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';

export const getProducerColumnsFormat = intl => {
  const { formatMessage } = intl;
  return [
    {
      title: (
        <FormattedMessage id="producer.id" defaultMessage="Producer ID" />
      ),
      dataIndex: 'externalId',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage id="producer.name" defaultMessage="Producer name" />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '15%'
    },
    {
      title: <FormattedMessage id="erp.title" defaultMessage="ERP number" />,
      dataIndex: 'erpNumber',
      key: 'erpNumber',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage
          id="producer.locationAddress"
          defaultMessage="Location address"
        />
      ),
      key: 'location',
      width: '20%',
      render: record => {
        if (!isEmpty(record)) {
          return (
            <div className="">
              {locationString(
                record.street,
                record.street_number,
                record.postal_code,
                record.city,
                record.country
              )}
            </div>
          );
        }
      }
    },
    {
      title: (
        <FormattedMessage
          id="producer.contactPersonInformation"
          defaultMessage="Contact person information"
        />
      ),
      key: 'personInfo',
      width: '20%',
      render: record => {
        if (!isEmpty(record)) {
          return <ContactList contact_list={record.contact_list} />;
        }
      }
    },
    {
      title: <FormattedMessage id="comment.title" defaultMessage="Comment" />,
      dataIndex: 'comment',
      key: 'comment',
      width: '20%'
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: record => {
        if (!isEmpty(record)) {
          return (
            <div className="action-col">
              <ProtectedComponent
                allowRoles={
                  ROLES_OF_PERMISSIONS.DETERGENT_PRODUCERS.DEACTIVATE
                }>
                {record.status === STATUS_ENUM.ACTIVE ? (
                  <Tooltip
                    placement="bottomLeft"
                    title={formatMessage({
                      id: 'common.statusEnumDeactivate',
                      defaultMessage: 'Deactivate'
                    })}>
                    <img
                      className={`${ACTION_BUTTON_TYPES.BLOCK_DETERGENT_PRODUCER} action-item`}
                      alt=""
                      src="/icon/block.svg"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="bottomLeft"
                    title={formatMessage({
                      id: 'common.statusEnumActivate',
                      defaultMessage: 'Activate'
                    })}>
                    <img
                      className={`${ACTION_BUTTON_TYPES.UNBLOCK_DETERGENT_PRODUCER} action-item`}
                      alt=""
                      src="/icon/active.svg"
                    />
                  </Tooltip>
                )}
              </ProtectedComponent>

              <ProtectedComponent
                allowRoles={ROLES_OF_PERMISSIONS.DETERGENT_PRODUCERS.EDIT}>
                <img
                  className={`${ACTION_BUTTON_TYPES.EDIT_DETERGENT_PRODUCER} action-item`}
                  alt=""
                  src="/icon/ic-edit.svg"
                />
              </ProtectedComponent>
            </div>
          );
        }
      }
    }
  ];
};

const ContactList = ({ contact_list = [] }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const contacts = isShowMore ? contact_list : contact_list.slice(0, 1);
  const lastIndex = contacts.length - 1;

  return (
    <div className="contact-list">
      {contacts.map((contact, idx) => {
        if (contact_list.length === 0 || contact_list.length === 1) {
          return (
            <div key={idx} className="single-person">
              <div className="text-bold">
                {renderProducerNames(
                  contact?.title,
                  contact?.first_name,
                  contact?.last_name
                )}
              </div>
              <div>{contact.email}</div>
              <div>{contact.phone}</div>
            </div>
          );
        }
        return (
          <div key={idx} className="single-person">
            <div className="text-bold">
              {renderProducerNames(
                contact?.title,
                contact?.first_name,
                contact?.last_name
              )}
            </div>
            <div>{contact.email}</div>
            <div>{contact.phone}</div>
            {idx === lastIndex ? (
              <div
                className="show-more-less"
                onClick={event => {
                  event.stopPropagation();
                  setIsShowMore(!isShowMore);
                }}>
                {isShowMore ? (
                  <FormattedMessage
                    id="common.showLess"
                    defaultMessage="Show less"
                  />
                ) : (
                  <FormattedMessage
                    id="common.showMore"
                    defaultMessage="Show more"
                  />
                )}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
