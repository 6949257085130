import React from 'react';
import PropType from 'prop-types';
import Style from '../Util/style';

const fillableNoteMargin = { ...Style.fillable, ...Style.fillableNoteMargin };

const FillableNote = ({ children, width }) => {
  return (
    <section style={{ width: `${width}%` }}>
      <p style={fillableNoteMargin} />
      <span> {children} </span>
    </section>
  );
};

FillableNote.propTypes = {
  children: PropType.node,
  width: PropType.number
};

FillableNote.defaultProps = {
  children: null,
  width: 20
};

export default FillableNote;
