import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Icon } from 'antd';

const OverlayLoading = props => {
  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<Icon type="sync" spin />}
      styles={{
        wrapper: {
          overflow: 'hidden'
        },
        overlay: base => ({
          ...base,
          zIndex: 1350
        })
      }}>
      {props.children}
    </LoadingOverlay>
  );
};

export { OverlayLoading };
