import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BaseTable } from '../../../../../../../components/BaseTable';
import {
  getDetergentProductionCycleList,
  sendEmailToSafetyTeam,
  sendEmailToLabelingTeam
} from '../../../../../data/detergent/actions';
import { renderDetergentProductionCyclesColumns } from './renderDetergentProductionCyclesColumns';
import { ExpandIcon } from '../../../../../../../components/Table/ExpandIcon';
import { ExpandedProductionCycleRow } from './ExpandedProductionCycleRow';
import {
  EMAIL_TEMPLATE,
  URL_PARAMS_ACTIONS,
  ACTION_BUTTON_TYPES
} from '../../../../../../../configs/constant';
import { buildUrlQueryParams } from '../../../../../../../utils/common';
import './index.scss';

const ProducerCycleList = props => {
  const { formulaId, onEditProductionCyle, onDeleteProductionCycle } = props;
  const intl = useIntl();

  const {
    productionCycleData,
    productionCycleLoading,
    detergent
  } = useSelector(state => ({
    productionCycleData: state.detergent.productionCycleList.data,
    productionCycleLoading: state.detergent.productionCycleList.fetching,
    detergent: state.detergent.emptyDetergent
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetergentProductionCycleList({ formulaId }));
  }, []);

  const onSendEmailToSafetyTeam = useCallback(
    productionCycleId => {
      const { name, number, id: detergentId } = detergent;
      const { version: formulaVersion, id: detergentFormulaId } =
        detergent?.formulas?.[0] || {};

      const data = {
        template: EMAIL_TEMPLATE.SAFETY_TEAM,
        param: {
          detergent: {
            name,
            number,
            formulaVersion
          },
          viewDetailOnKddUrl: buildUrlQueryParams('/detergent', {
            data: {
              detergentId,
              formulaId: detergentFormulaId,
              detergentName: name
            },
            action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
          }),
          productionCycleId
        }
      };

      dispatch(sendEmailToSafetyTeam(data));
    },
    [dispatch]
  );

  const onSendEmailToLabelingTeam = useCallback(
    productionCycleId => {
      const { name, number, id: detergentId } = detergent;
      const { version: formulaVersion, id: detergentFormulaId } =
        detergent?.formulas?.[0] || {};

      const data = {
        template: EMAIL_TEMPLATE.LABELING_TEAM,
        param: {
          detergent: {
            name,
            number,
            formulaVersion
          },
          viewDetailOnKddUrl: buildUrlQueryParams('/detergent', {
            data: {
              detergentId,
              formulaId: detergentFormulaId,
              detergentName: name
            },
            action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
          }),
          productionCycleId
        }
      };

      dispatch(sendEmailToLabelingTeam(data));
    },
    [dispatch]
  );

  const onViewDetailHandler = (event, record) => {
    if (typeof event.target.className !== 'string') return;

    const isEditProductionCylceEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.EDIT_PRODUCTION_CYCLE_SINGLE
    );

    const isDeleteProductionCycleEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.DELETE_PRODUCTION_CYCLE
    );

    if (isEditProductionCylceEvent !== -1) {
      onEditProductionCyle(record);
    }

    if (isDeleteProductionCycleEvent !== -1) {
      onDeleteProductionCycle(record.id);
    }
  };

  return (
    <BaseTable
      className="detergent-production-cycle-table"
      dataSource={productionCycleData}
      onRow={record => {
        return {
          onClick: event => onViewDetailHandler(event, record)
        };
      }}
      bordered
      columns={renderDetergentProductionCyclesColumns(intl)}
      loading={
        productionCycleLoading && { indicator: <Icon type="sync" spin /> }
      }
      expandIconAsCell={false}
      expandIconColumnIndex={6}
      expandedRowRender={record => (
        <ExpandedProductionCycleRow
          record={record}
          onSendEmailToSafetyTeam={onSendEmailToSafetyTeam}
          onSendEmailToLabelingTeam={onSendEmailToLabelingTeam}
        />
      )}
      expandIcon={expandIconProps => <ExpandIcon {...expandIconProps} />}
    />
  );
};

ProducerCycleList.propTypes = {
  formulaId: PropTypes.number.isRequired,
  onEditProductionCyle: PropTypes.func.isRequired,
  onDeleteProductionCycle: PropTypes.func.isRequired
};

export default ProducerCycleList;
