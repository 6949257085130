import { reducer } from 'redux-oidc';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';

/** * reducers */
import detergentProducersReducer from '../containers/DetergentProducers/data/detergentProducers/reducer';
import rawMaterialProducersReducer from '../containers/RawMaterialProducer/data/rawMaterialProducers/reducer';
import rawMaterialsReducer from '../containers/RawMaterial/data/rawMaterial/reducer';
import rawMaterialGroup from '../containers/RawMaterialGroup/data/rawMaterialGroup/reducer';
import detergent from '../containers/Detergent/data/detergent/reducer';
import userManagementReducer from '../containers/UserManagement/data/userManagement/reducer';
import containerTypeReducer from '../containers/ContainerType/data/containerType/reducer';

import languageReducer from '../data/language/reducer';
import countriesReducer from '../data/countries/reducer';
import partNumberNonPagingReducer from '../data/partNumberNonPaging/reducer';
import detergentNonPagingReducer from '../data/detergentNonPaging/reducer';
import partNumbersReducer from '../containers/PartNumbers/data/partNumbers/reducer';
import detergentProducerNonPagingReducer from '../data/detergentProducerNonPaging/reducer';
import administrationReducer from '../data/administration/reducer';
import procurementEmailReducer from '../containers/Detergent/components/Modal/ProcurementEmailFileSelectorModal/state/reducer';
import logsReducer from '../containers/Logs/data/logs/reducer';
import sapPrice from '../data/sap/reducer';
import productionCyclesReducer from '../containers/ProductionCycle/data/productionCycles/reducer';
import universalSearchReducer from '../data/universalSearch/reducer';

const rootReducer = combineReducers({
  auth: reducer,
  loadingBar: loadingBarReducer,
  detergentProducers: detergentProducersReducer,
  rawMaterialProducers: rawMaterialProducersReducer,
  rawMaterials: rawMaterialsReducer,
  rawMaterialGroup,
  countries: countriesReducer,
  detergent,
  language: languageReducer,
  partNumbers: partNumbersReducer,
  detergentNonPaging: detergentNonPagingReducer,
  detergentProducerNonPaging: detergentProducerNonPagingReducer,
  partNumberNonPaging: partNumberNonPagingReducer,
  administration: administrationReducer,
  userManagement: userManagementReducer,
  procurementEmail: procurementEmailReducer,
  logs: logsReducer,
  sapPrice,
  containerType: containerTypeReducer,
  productionCycles: productionCyclesReducer,
  universalSearch: universalSearchReducer
});

export default rootReducer;
