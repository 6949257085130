import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { BaseTable } from '../../../../../components/BaseTable';
import { renderProductionCycleColumns } from './renderProductionCycleColumns';
import {
  getDetergentProductionCycleList,
  sendEmailToSafetyTeam,
  sendEmailToLabelingTeam,
  processDetergentModal,
  updateProductionCycles
} from '../../../data/detergent/actions';
import { ExpandIcon } from '../../../../../components/Table/ExpandIcon';
import { ExpandedProductionCycleRow } from './ExpandedProductionCycleRow';
import {
  EMAIL_TEMPLATE,
  URL_PARAMS_ACTIONS,
  STATUS_ENUM,
  FORM_STATUS
} from '../../../../../configs/constant';
import { buildUrlQueryParams } from '../../../../../utils/common';
import { ProtectedComponent } from '../../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../../configs/rolesOfPermissions';
import { getFormFieldKey } from '../../Util/productionCycle/getFormFieldKey';
import { transformProductionCycleDataBeforeSaving } from '../../Util/productionCycle/transformProductionCycleDataBeforeSaving';

const EditProductionCycles = props => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const {
    form,
    detergent,
    selectedProductionCycle,
    groupPartNumbersById
  } = props;
  const dispatch = useDispatch();
  const formulaId = detergent?.formulas?.[0]?.id;
  const intl = useIntl();

  const {
    productionCycleData,
    productionCycleLoading,
    isPartNumberNonPagingFetching,
    partNumberNonPagingData
  } = useSelector(state => ({
    productionCycleData: state.detergent.productionCycleList.data,
    productionCycleLoading: state.detergent.productionCycleList.fetching,
    isPartNumberNonPagingFetching: state.partNumberNonPaging.isFetching,
    partNumberNonPagingData: state.partNumberNonPaging.data
  }));

  const isFetching = productionCycleLoading || isPartNumberNonPagingFetching;
  const formularStatus = detergent?.formulas?.[0]?.status;

  useEffect(() => {
    dispatch(getDetergentProductionCycleList({ formulaId }));
  }, []);

  useEffect(() => {
    let newExpandedRowKeys = [];
    if (selectedProductionCycle && productionCycleData) {
      productionCycleData.reduce((accumulator, currentValue) => {
        if (currentValue.id === selectedProductionCycle.id) {
          newExpandedRowKeys.push(currentValue.id);
        }
        return accumulator;
      }, []);
    } else {
      newExpandedRowKeys = productionCycleData
        ? productionCycleData.map(productionCycle => productionCycle.id)
        : [];
    }

    setExpandedRowKeys(newExpandedRowKeys);
  }, [productionCycleData]);

  const handleExpandRow = (expanded, record) => {
    const rowKey = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];

    if (expanded) {
      // open
      newExpandedRowKeys.push(rowKey);
    } else {
      // close
      const index = newExpandedRowKeys.indexOf(rowKey);
      newExpandedRowKeys.splice(index, 1);
    }

    setExpandedRowKeys(newExpandedRowKeys);
  };

  const onSendEmailToSafetyTeam = useCallback(
    productionCycle => {
      const { name, number, id: detergentId } = detergent;
      const { version: formulaVersion, id: detergentFormulaId } =
        detergent?.formulas?.[0] || {};

      const comment = form.getFieldValue(
        getFormFieldKey(productionCycle, 'comment')
      );
      const data = {
        template: EMAIL_TEMPLATE.SAFETY_TEAM,
        param: {
          detergent: {
            name,
            number,
            formulaVersion
          },
          viewDetailOnKddUrl: buildUrlQueryParams('/detergent', {
            data: {
              detergentId,
              formulaId: detergentFormulaId
            },
            action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
          }),
          productionCycleId: productionCycle.id,
          comment
        }
      };

      dispatch(sendEmailToSafetyTeam(data));

      // Autosave production cycles
      onUpdateProductionCyclesWhenSendMail();
    },
    [detergent.id, detergent?.formulas?.[0]?.id]
  );

  const onSendEmailToLabelingTeam = useCallback(
    productionCycle => {
      const { name, number, id: detergentId } = detergent;
      const { version: formulaVersion, id: detergentFormulaId } =
        detergent?.formulas?.[0] || {};

      const comment = form.getFieldValue(
        getFormFieldKey(productionCycle, 'comment')
      );
      const data = {
        template: EMAIL_TEMPLATE.LABELING_TEAM,
        param: {
          detergent: {
            name,
            number,
            formulaVersion
          },
          viewDetailOnKddUrl: buildUrlQueryParams('/detergent', {
            data: {
              detergentId,
              formulaId: detergentFormulaId
            },
            action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
          }),
          productionCycleId: productionCycle.id,
          comment
        }
      };

      dispatch(sendEmailToLabelingTeam(data));

      // Autosave production cycles
      onUpdateProductionCyclesWhenSendMail();
    },
    [detergent.id, detergent?.formulas?.[0]?.id]
  );

  const onUpdateProductionCyclesWhenSendMail = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        const tranformedproductionCycles = transformProductionCycleDataBeforeSaving(
          values,
          groupPartNumbersById,
          selectedProductionCycle
        );

        dispatch(
          updateProductionCycles({
            productionCycles: tranformedproductionCycles,
            detergent,
            documents: values.documents
          })
        );
      }
    });
  }, []);

  const onAddNewProductionCycle = useCallback(() => {
    dispatch(
      processDetergentModal({
        detergent,
        statusForm: FORM_STATUS.ADD_PRODUCTION_CYCLE,
        title: (
          <FormattedMessage
            id="detergent.modalAddNewProductionCycleTitle"
            defaultMessage="ADD NEW PRODUCTION CYCLE"
          />
        ),
        isVisible: true
      })
    );
  }, [detergent.id]);

  return (
    <>
      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.CREATE}>
        {formularStatus === STATUS_ENUM.APPROVED ? (
          <div className="text-right production-cycle-buttons">
            <Button
              icon="plus"
              className="yellow-button"
              onClick={onAddNewProductionCycle}>
              <FormattedMessage
                id="detergent.addNewProductionCycle"
                defaultMessage="Add New Production Cycle"
              />
            </Button>
          </div>
        ) : null}
      </ProtectedComponent>

      <BaseTable
        className="production-cycle--edit-table"
        dataSource={productionCycleData}
        columns={renderProductionCycleColumns({
          intl,
          form,
          partNumbers: partNumberNonPagingData,
          selectedProductionCycle
        })}
        loading={isFetching && { indicator: <Icon type="sync" spin /> }}
        expandIconAsCell={false}
        expandIconColumnIndex={6}
        expandedRowRender={record => (
          <ExpandedProductionCycleRow
            record={record}
            form={form}
            onSendEmailToSafetyTeam={onSendEmailToSafetyTeam}
            onSendEmailToLabelingTeam={onSendEmailToLabelingTeam}
            selectedProductionCycle={selectedProductionCycle}
            groupPartNumbersById={groupPartNumbersById}
            partNumbers={partNumberNonPagingData}
          />
        )}
        expandIcon={expandIconProps => <ExpandIcon {...expandIconProps} />}
        defaultExpandAllRows
        defaultExpandedRowKeys={expandedRowKeys}
        expandedRowKeys={expandedRowKeys}
        onExpand={handleExpandRow}
        rowKey={record => record.id}
      />
    </>
  );
};

EditProductionCycles.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
  detergent: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EditProductionCycles;
