import PropType from 'prop-types';
import React from 'react';
import { kaercherLogo } from '../../../../../../../assets';

const BaseGeneratedFileHeader = ({ children }) => {
  return (
    <header className="generated-file-header generated-file-aligned-content">
      <img className="formula-logo" src={kaercherLogo} alt="logo" />
      <section className="formula-meta-data">{children}</section>
    </header>
  );
};

BaseGeneratedFileHeader.propTypes = {
  children: PropType.node.isRequired
};

export default BaseGeneratedFileHeader;
