import React from 'react';
import { Icon, Select } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FilterableSelect from '../../../../../components/FilterableSelect';

export const ProductionCyclesSelection = ({
  fileItem,
  onChangeDocumentProperty
}) => {
  const { formatMessage } = useIntl();
  const { productionCycleList } = useSelector(state => ({
    productionCycleList: state.detergent.productionCycleList.data
  }));

  const initialValue =
    fileItem && fileItem.productionCycles?.length > 0
      ? fileItem.productionCycles.map(({ id }) => id)
      : [];

  return (
    <div
      id="select-document-production-cycles"
      className="select-document-production-cycles">
      <FilterableSelect
        mode="multiple"
        defaultValue={initialValue}
        getPopupContainer={() =>
          document.getElementById('select-document-production-cycles')
        }
        placeholder={formatMessage({
          id: 'detergent.selectProductionCycles',
          defaultMessage: 'Select production cycles'
        })}
        suffixIcon={<Icon type="caret-down" />}
        loading
        onChange={values => {
          onChangeDocumentProperty(fileItem, 'productionCycles', values);
        }}>
        {productionCycleList.map(productionCycle => (
          <Select.Option key={productionCycle.id} value={productionCycle.id}>
            {productionCycle.id}
          </Select.Option>
        ))}
      </FilterableSelect>
    </div>
  );
};

ProductionCyclesSelection.propTypes = {
  fileItem: PropTypes.shape({
    productionCycles: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  onChangeDocumentProperty: PropTypes.func.isRequired
};

ProductionCyclesSelection.defaultProps = {
  fileItem: {
    productionCycles: []
  }
};
