import React from 'react';
import { Select, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

export const PageSizeOption = props => {
  const { handleChangeSize, pageSizeOptions = [25, 50, 100] } = props;
  return (
    <span className="table__page-size-option">
      <span>
        <FormattedMessage id="common.pageSizeView" defaultMessage="View" />
      </span>
      <Select
        onChange={handleChangeSize}
        suffixIcon={<Icon type="down" />}
        defaultValue={props.pageSize}
        className="table__page-size-selectbox">
        {pageSizeOptions.map((pageSize, index) => (
          <Select.Option key={index} value={pageSize}>
            {pageSize}
          </Select.Option>
        ))}
      </Select>
      <span>
        <FormattedMessage
          id="common.pageSizeEntries"
          defaultMessage="Entries"
        />
      </span>
    </span>
  );
};
