import React from 'react';
import { Typography } from 'antd';
import PropType from 'prop-types';
import { FormattedMessage } from 'react-intl';

const GeneratedFileUtilDetergentProductionInstruction = ({
  productionInstruction
}) => {
  return (
    <>
      <Typography.Title level={4} className="formula-production-instruction">
        <strong>
          <FormattedMessage
            id="formula.instructions"
            defaultMessage="formula.instructions"
          />
          :
        </strong>
      </Typography.Title>
      <p className="formula-production-instruction-text">
        {productionInstruction}
      </p>
    </>
  );
};

GeneratedFileUtilDetergentProductionInstruction.propTypes = {
  productionInstruction: PropType.string.isRequired
};

export default GeneratedFileUtilDetergentProductionInstruction;
