import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFormFieldKey } from '../../../Util/productionCycle/getFormFieldKey';
import { getCheckboxConfig } from '../../../../../../utils/common';
import { disableRowBySelectedProductionCyle } from '../util/disableRowBySelectedProductionCyle';

export const SafetyTeam = ({
  form,
  record,
  onSendEmailToLabelingTeam,
  selectedProductionCycle,
  isInactivePartNumber
}) => {
  const { areSendingToLabelingTeamForProductionCycleIds } = useSelector(
    state => ({
      areSendingToLabelingTeamForProductionCycleIds:
        state.detergent.mail.labelingTeam
          .areSendingToLabelingTeamForProductionCycleIds
    })
  );

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.safetyTeam"
            defaultMessage="Safety team"
          />
        </span>
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'safetyDataSheetUploaded'),
          getCheckboxConfig(record?.safetyDataSheetUploaded)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.safetyDataSheetUploaded"
              defaultMessage="6. Safety data sheet uploaded"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'safetyTextReviewed'),
          getCheckboxConfig(record?.safetyTextReviewed)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.safetyTextReviewed"
              defaultMessage="7. Safety text reviewed"
            />
          </Checkbox>
        )}

        {form.getFieldDecorator(
          getFormFieldKey(record, 'noteSafetyTextReviewed'),
          {
            initialValue: record?.noteSafetyTextReviewed
          }
        )(
          <Input
            className="text-reviewed-note"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }
          />
        )}
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.labelingInformation"
            defaultMessage="8. Labeling information:"
          />
        </span>
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'labelingInformation.newLabel'),
          getCheckboxConfig(record?.labelingInformation?.newLabel)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.newLabel"
              defaultMessage="New label"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'labelingInformation.useUpLabelsInStock'),
          getCheckboxConfig(record?.labelingInformation?.useUpLabelsInStock)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.useUpLabelsInStock"
              defaultMessage="Use up labels in stock"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'labelingInformation.destroyLabelsInStock'),
          getCheckboxConfig(record?.labelingInformation?.destroyLabelsInStock)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.destroyLabelsInStock"
              defaultMessage="Destroy labels in stock"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'labelingInformation.labelsNotAffected'),
          getCheckboxConfig(record?.labelingInformation?.labelsNotAffected)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.labelsNotAffected"
              defaultMessage="Labels. not affected"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        <Button
          className="yellow-button"
          icon="right"
          disabled={
            areSendingToLabelingTeamForProductionCycleIds.includes(record.id) ||
            disableRowBySelectedProductionCyle(
              selectedProductionCycle,
              record
            ) ||
            isInactivePartNumber
          }
          onClick={() => onSendEmailToLabelingTeam(record)}
          loading={areSendingToLabelingTeamForProductionCycleIds.includes(
            record.id
          )}>
          <FormattedMessage
            id="productionCycle.forwardToLabelingTeam"
            defaultMessage="Forward to labeling team"
          />
        </Button>
      </div>
    </>
  );
};

SafetyTeam.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.shape(PropTypes.any).isRequired,
  onSendEmailToLabelingTeam: PropTypes.func.isRequired,
  selectedProductionCycle: PropTypes.shape({}),
  isInactivePartNumber: PropTypes.bool.isRequired
};

SafetyTeam.defaultProps = {
  selectedProductionCycle: undefined
};
