import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Select, Icon, Form } from 'antd';
import FilterableSelect from '../../../../components/FilterableSelect/index';
import { BLANK_OPTION } from '../../../../configs/constant';

export const DetergentProducerField = props => {
  const {
    form: { getFieldDecorator },
    detergentProducerNonPaging,
    initialValue
  } = props;
  const [producers, setProducers] = useState([]);

  useEffect(() => {
    if (Array.isArray(detergentProducerNonPaging)) {
      const producersForSelection = detergentProducerNonPaging.map(
        ({ id, name }) => ({
          id,
          name
        })
      );

      setProducers(producersForSelection);
    }
  }, [detergentProducerNonPaging]);

  return (
    <Form.Item
      label={
        <FormattedMessage
          id="partNumber.form.detergentProducer"
          defaultMessage="Detergent producer"
        />
      }>
      <div id="detergent-producer-node">
        {getFieldDecorator('partNumber.detergentProducer.id', {
          initialValue: initialValue || undefined
        })(
          <FilterableSelect
            getPopupContainer={() =>
              document.getElementById('detergent-producer-node')
            }
            suffixIcon={<Icon type="caret-down" />}
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectProducer"
                defaultMessage="Select producer"
              />
            }>
            {producers
              .map(producer => (
                <Select.Option value={producer.id} key={producer.id}>
                  {producer.name}
                </Select.Option>
              ))
              .concat(
                <Select.Option key={BLANK_OPTION.id} value={BLANK_OPTION.value}>
                  {BLANK_OPTION.name}
                </Select.Option>
              )}
          </FilterableSelect>
        )}
      </div>
    </Form.Item>
  );
};

DetergentProducerField.defaultProps = {
  initialValue: null
};

DetergentProducerField.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  detergentProducerNonPaging: PropTypes.arrayOf(PropTypes.any).isRequired,
  initialValue: PropTypes.number
};
