import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { BaseTable } from '../../../../components/BaseTable';
import { renderPartNumberColumns } from './renderPartNumberColumns';
import { ACTION_BUTTON_TYPES } from '../../../../configs/constant';

const _List = props => {
  const { onEditPartNumber, onViewPartNumberDetail } = props;
  const { pagination, loading, listPartNumber } = useSelector(state => ({
    listPartNumber: state.partNumbers.list.data.results,
    pagination: state.partNumbers.list.data.pagination,
    loading: state.partNumbers.list.fetching
  }));

  return (
    <div className="table-producer table-part-number">
      <BaseTable
        bordered
        stickyHeader
        onRow={currentRowRecord => {
          return {
            onClick: event => {
              const isEditProducts = event.target.className.indexOf(
                ACTION_BUTTON_TYPES.EDIT_PARTNUMBER_ACTION
              );

              const isEditStatus = event.target.className.indexOf(
                ACTION_BUTTON_TYPES.EDIT_PART_NUMBER_STATUS
              );

              const isPreventOnRowTrigger = event.target.className.indexOf(
                ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER
              );

              const isOtherClickEvents = event.target.className.indexOf(
                ACTION_BUTTON_TYPES.SELECT_DROPDOWN
              );

              if (isEditProducts !== -1) {
                onEditPartNumber(currentRowRecord);
              } else if (isEditStatus !== -1 || isPreventOnRowTrigger !== -1 || isOtherClickEvents !== -1) {
                // do nothing
              } else {
                onViewPartNumberDetail(currentRowRecord);
              }
            }
          };
        }}
        pagination={pagination}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        columns={renderPartNumberColumns({
          onEditPartNumber,
          onViewPartNumberDetail
        })}
        dataSource={listPartNumber}
        onPageSizeChange={value => props.onPageSizeChange(value)}
        onPageChange={current => props.onPageChange(current)}
        rowKey="partNumber"
      />
    </div>
  );
};

_List.propTypes = {
  onEditPartNumber: PropTypes.func.isRequired,
  onViewPartNumberDetail: PropTypes.func.isRequired
};

export const PartNumberList = _List;
