import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CheckBoxIcon from './CheckBoxIcon';

export const SafetyTeam = ({ record, onSendEmailToLabelingTeam }) => {
  const { areSendingToLabelingTeamForProductionCycleIds } = useSelector(
    state => ({
      areSendingToLabelingTeamForProductionCycleIds:
        state.detergent.mail.labelingTeam
          .areSendingToLabelingTeamForProductionCycleIds
    })
  );

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.safetyTeam"
            defaultMessage="Safety team"
          />
        </span>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.safetyDataSheetUploaded}>
          <FormattedMessage
            id="productionCycle.safetyDataSheetUploaded"
            defaultMessage="6. Safety data sheet uploaded"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.safetyTextReviewed}>
          <FormattedMessage
            id="productionCycle.safetyTextReviewed"
            defaultMessage="7. Safety text reviewed"
          />
        </CheckBoxIcon>

        <Input
          className="text-reviewed-note"
          value={record?.noteSafetyTextReviewed}
          disabled
        />
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.labelingInformation"
            defaultMessage="8. Labeling information:"
          />
        </span>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon checked={record?.labelingInformation?.newLabel}>
          <FormattedMessage
            id="productionCycle.newLabel"
            defaultMessage="New label"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon checked={record?.labelingInformation?.useUpLabelsInStock}>
          <FormattedMessage
            id="productionCycle.useUpLabelsInStock"
            defaultMessage="Use up labels in stock"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={record?.labelingInformation?.destroyLabelsInStock}>
          <FormattedMessage
            id="productionCycle.destroyLabelsInStock"
            defaultMessage="Destroy labels in stock"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon checked={record?.labelingInformation?.labelsNotAffected}>
          <FormattedMessage
            id="productionCycle.labelsNotAffected"
            defaultMessage="Labels. not affected"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <Button
          className="yellow-button"
          icon="right"
          disabled={areSendingToLabelingTeamForProductionCycleIds.includes(
            record.id
          )}
          onClick={() => onSendEmailToLabelingTeam(record.id)}
          loading={areSendingToLabelingTeamForProductionCycleIds.includes(
            record.id
          )}>
          <FormattedMessage
            id="productionCycle.forwardToLabelingTeam"
            defaultMessage="Forward to labeling team"
          />
        </Button>
      </div>
    </>
  );
};

SafetyTeam.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onSendEmailToLabelingTeam: PropTypes.func.isRequired
};
