import { AxiosService } from '../../../../services/axiosService';

export const getRawMaterialGroupApi = options => {
  return AxiosService.get(
    'raw-material-group',
    {
      search: options.searchByText,
      order: options.order,
      filter: options.filter
    },
    {
      pagination: options.pagination
    }
  );
};

export const addRawMaterialGroupApi = data => {
  return AxiosService.post('raw-material-group', data);
};

export const updateRawMaterialGroupApi = (id, data) => {
  return AxiosService.put(`raw-material-group/${id}`, data);
};

export const deleteRawMaterialGroupApi = id => {
  return AxiosService.delete(`raw-material-group/${id}`);
};

export const getAllRawMaterialGroupApi = () => {
  return AxiosService.get('raw-material-group/non-paging');
};
