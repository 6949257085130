import React from 'react';
import BaseSheetOne from './BaseSheetOne';

import measurementInitialState from '../../../../../data/detergent/measurementInitialState';
import '../index.scss';
import { propTypes, defaultProps } from '../Util/detergentPropType';
import formulaInitialState from './formulaInitialState';

const BlankMeasurementDataSheetOne = ({
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer
}) => {
  return (
    <BaseSheetOne
      pdfRenderer={pdfRenderer}
      isRenderPdfNow={isRenderPdfNow}
      onPDFRenderedComplete={onPDFRenderedComplete}
      className="rendered-blank-measurement-data generated-file--padding-top"
      measurement={measurementInitialState}
      formula={formulaInitialState}
    />
  );
};

BlankMeasurementDataSheetOne.propTypes = propTypes;
BlankMeasurementDataSheetOne.defaultProps = defaultProps;

export default BlankMeasurementDataSheetOne;
