import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Select, Icon } from 'antd';
import { FORM_STATUS } from '../../../../configs/constant';
import { validateDensityForPartnumber } from './utils/validators';
import FilterableSelect from '../../../../components/FilterableSelect/index';

export const DetergentSelection = props => {
  const { formatMessage } = useIntl();
  const {
    form: { getFieldDecorator, setFieldsValue },
    initialDetergentIdValue,
    initialFormulaIdValue,
    detergentNonPaging,
    formStatus,
    handleDensityChange
  } = props;

  const [detergents, setDetergents] = useState([]);
  const [detergentFormulas, setDetergentFormulas] = useState([]);
  const detergentFieldKey =
    formStatus === FORM_STATUS.UPDATE
      ? 'partNumber.detergentFormula.detergent.id'
      : 'detergentId';

  useEffect(() => {
    if (!Array.isArray(detergentNonPaging)) return;

    const detergentsForSelection = [];
    let formulasByInitialDetergentId = [];

    detergentNonPaging.forEach(detergent => {
      const { id, number, formulas } = detergent;

      detergentsForSelection.push({
        id,
        number,
        formulas
      });

      if (initialDetergentIdValue && initialDetergentIdValue === id) {
        formulasByInitialDetergentId = formulas
          .sort((a, b) => a.version - b.version)
          .map(formula => ({
            id: formula?.id,
            version: formula?.version,
            density: formula?.density
          }));
      }
    });

    setDetergents(detergentsForSelection);
    setDetergentFormulas(formulasByInitialDetergentId);
  }, [detergentNonPaging]);

  const handleChange = valueChangedId => {
    const formulasBySelectedDetergent = detergents.find(
      detergent => detergent.id === valueChangedId
    ).formulas;

    const formulasForSelection = formulasBySelectedDetergent.map(
      ({ id, version, density }) => ({ id, version, density })
    );

    setFieldsValue({
      'partNumber.detergentFormula.id': null
    });

    setDetergentFormulas(formulasForSelection);
  };

  const handleVersionChange = selectedFormulaId => {
    if (formStatus === FORM_STATUS.UPDATE)
      handleDensityChange(detergentFormulas, selectedFormulaId);
  };

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage
            id="header.componentDetergent"
            defaultMessage="Detergent"
          />
        }>
        <div id="detergent-selection-node">
          {getFieldDecorator(detergentFieldKey, {
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'partNumber.validate.detergent',
                  defaultMessage: 'Detergent is required'
                })
              }
            ],
            initialValue: initialDetergentIdValue || undefined
          })(
            <FilterableSelect
              getPopupContainer={() =>
                document.getElementById('detergent-selection-node')
              }
              onChange={handleChange}
              suffixIcon={<Icon type="caret-down" />}
              placeholder={
                <FormattedMessage
                  id="partNumber.form.selectDetergent"
                  defaultMessage="Select detergent"
                />
              }>
              {detergents.map(detergent => (
                <Select.Option key={detergent.id} value={detergent.id}>
                  {detergent.number}
                </Select.Option>
              ))}
            </FilterableSelect>
          )}
        </div>
      </Form.Item>

      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.columnFormulaVersion"
            defaultMessage="Formula Version"
          />
        }>
        <div id="formula-selection-node">
          {getFieldDecorator('partNumber.detergentFormula.id', {
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'partNumber.validate.detergentFormula',
                  defaultMessage: 'Detergent formula is required'
                })
              },
              {
                validator: validateDensityForPartnumber(
                  detergentFormulas,
                  formatMessage
                )
              }
            ],
            initialValue: initialFormulaIdValue || undefined
          })(
            <FilterableSelect
              getPopupContainer={() =>
                document.getElementById('formula-selection-node')
              }
              placeholder={
                <FormattedMessage
                  id="partNumber.form.selectFormulaVersion"
                  defaultMessage="Select formula version"
                />
              }
              onChange={selectedVersion => handleVersionChange(selectedVersion)}
              suffixIcon={<Icon type="caret-down" />}>
              {detergentFormulas.map(formula => (
                <Select.Option
                  value={formula.id}
                  key={`${formula.id}.${formula.version}`}>
                  {formula.version}
                </Select.Option>
              ))}
            </FilterableSelect>
          )}
        </div>
      </Form.Item>
    </>
  );
};

DetergentSelection.defaultProps = {
  initialDetergentIdValue: null,
  initialFormulaIdValue: null,
  formStatus: FORM_STATUS.CREATE,
  handleDensityChange: () => {}
};

DetergentSelection.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialDetergentIdValue: PropTypes.number,
  initialFormulaIdValue: PropTypes.number,
  detergentNonPaging: PropTypes.arrayOf(PropTypes.any).isRequired,
  formStatus: PropTypes.string,
  handleDensityChange: PropTypes.func
};
