import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ACTION_BUTTON_TYPES,
  MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID,
  MAPPING_FORMULA_STATUS_TO_CLASS_NAME,
  URL_PARAMS_ACTIONS,
  COMMODITY_GROUPS,
  RAW_MATERIAL_LABELS,
  FILTER_DETERGENT_LIST_BY_STATUS_LABEL
} from '../../../../configs/constant';
import {
  buildUrlQueryParams,
  getTotalAnnualTonnageByYear,
  changePointToComma,
  changeRatioDotToComma
} from '../../../../utils/common';
import { parseNameById } from '../ViewDetail/renderFieldsFunction';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';
import TotalAnnualTonnage from '../../../../components/AnnualTonnageDropdown/totalAnnualTonnage';

export const RawMaterialColumnsFormat = () => {
  const { formatMessage } = useIntl();

  return [
    {
      title: (
        <FormattedMessage id="rawMaterial.columnName" defaultMessage="Name" />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '15%'
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnProducer"
          defaultMessage="Producer"
        />
      ),
      dataIndex: 'producers',
      key: 'producer',
      width: '10%',
      render: value => {
        return (
          <div>{value && value.map(element => <div>{element.name}</div>)}</div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnSupplier"
          defaultMessage="Supplier"
        />
      ),
      dataIndex: 'suppliers',
      key: 'supplier',
      width: '10%',
      render: value => {
        return (
          <div>{value && value.map(element => <div>{element.name}</div>)}</div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnGroup"
          defaultMessage="Raw Material Group"
        />
      ),
      dataIndex: 'rawMaterialGroups',
      key: 'group',
      width: '18%',
      render: value => {
        return (
          <div>{value && value.map(element => <div>{element.name}</div>)}</div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnPartNumber"
          defaultMessage="Part Number"
        />
      ),
      className: 'text-align-right-important',
      key: 'partNumber',
      width: '12%',
      render: record => {
        const partNumberName = record?.partNumber;
        return <span className="float-right">{partNumberName}</span>;
      }
    },
    {
      title: (
        <span className="text-align-right">
          {' '}
          <FormattedMessage
            id="rawMaterial.columns.totalAnnualTonnage"
            defaultMessage="Total annual tonnage"
          />
        </span>
      ),
      key: 'total annual tonnage',
      className: `${ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER}`,
      width: '12%',
      render: record => {
        const currentYear = new Date().getFullYear();
        const rawMaterialRatios = record?.rawMaterialRatios;
        if (rawMaterialRatios && rawMaterialRatios.length !== 0) {
          const totalAnnualTonnageCurrentYear = getTotalAnnualTonnageByYear(
            currentYear,
            rawMaterialRatios
          );

          return (
            <TotalAnnualTonnage
              record={record}
              valueOfCurrentYear={totalAnnualTonnageCurrentYear}
            />
          );
        }

        return null;
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columns.totalValueOfAnnualTonnage"
          defaultMessage="Total value of annual tonnage"
        />
      ),
      className: 'text-align-right-important',
      key: 'total value of annual tonnage',
      width: '12%',
      render: record => {
        const currentYear = new Date().getFullYear();
        const rawMaterialRatios = record?.rawMaterialRatios;
        const rawMaterialPrice =
          record?.price === null ? 0 : parseFloat(record?.price);
        if (rawMaterialRatios && rawMaterialRatios.length !== 0) {
          const valueOfTotalAnnualTonnageCurrentYear =
            getTotalAnnualTonnageByYear(currentYear, rawMaterialRatios) *
            rawMaterialPrice;

          return (
            <span style={{ float: 'right' }}>
              {changePointToComma(valueOfTotalAnnualTonnageCurrentYear)}
            </span>
          );
        }

        return null;
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnStatus"
          defaultMessage="Status"
        />
      ),
      key: 'status',
      width: '10%',
      render: record => {
        return (
          <div className="status-actions-container">
            <span
              className={`raw-material-status-cells ${
                MAPPING_FORMULA_STATUS_TO_CLASS_NAME[record?.status]
              }`}>
              <FormattedMessage
                id={MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID[record?.status]}
                defaultMessage={record?.status}
              />
            </span>
            <div className="action-col">
              <ProtectedComponent
                allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIALS.DEACTIVATE}>
                {record.status === 'Blocked' ? (
                  <Tooltip
                    placement="bottom"
                    title={formatMessage({
                      id: 'common.unblock',
                      defaultMessage: 'Unblock'
                    })}>
                    <img
                      className={` action-item ${ACTION_BUTTON_TYPES.UNLOCK_RAW_MATERIAL}
                      `}
                      alt=""
                      src="/icon/active.svg"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="bottom"
                    title={formatMessage({
                      id: 'common.block',
                      defaultMessage: 'Block'
                    })}>
                    <img
                      className={`${ACTION_BUTTON_TYPES.BLOCK_RAW_MATERIAL} action-item`}
                      alt=""
                      src="/icon/block.svg"
                    />
                  </Tooltip>
                )}
              </ProtectedComponent>

              <ProtectedComponent
                allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIALS.READ}>
                <img
                  className={`${ACTION_BUTTON_TYPES.VIEW_RAW_MATERIAL_DETAIL} action-item`}
                  alt=""
                  src="/icon/ic-view-details.svg"
                />
              </ProtectedComponent>

              <ProtectedComponent
                allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIALS.EDIT}>
                <img
                  className={`${ACTION_BUTTON_TYPES.EDIT_RAW_MATERIAL} action-item`}
                  alt=""
                  src="/icon/ic-edit.svg"
                />
              </ProtectedComponent>
            </div>
          </div>
        );
      }
    }
  ];
};

export const getDetergentListColumns = (isExpandRow = false) => {
  const statusIndex = isExpandRow ? 'status' : 'formulas.0.status';
  const versionIndex = isExpandRow ? 'version' : 'formulas.0.version';
  const sampleTypeIndex = isExpandRow ? 'sample' : 'formulas.0.sample';
  const ratioIndex = isExpandRow
    ? 'rawMaterialRatios.0.ratio'
    : 'formulas.0.rawMaterialRatios.0.ratio';
  return [
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnNumber"
          defaultMessage="Number"
        />
      ),
      dataIndex: 'number',
      key: 'number',
      width: '15%'
    },
    {
      title: (
        <FormattedMessage id="rawMaterial.columnName" defaultMessage="Name" />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '15%'
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnRatio"
          defaultMessage="Ratio in % [w/w]"
        />
      ),
      dataIndex: ratioIndex,
      key: 'ratio',
      width: '10%',
      render: ratio => <span>{changeRatioDotToComma(ratio)}%</span>
    },
    {
      title: (
        <FormattedMessage id="detergent.sample" defaultMessage="Sample Type" />
      ),
      dataIndex: sampleTypeIndex,
      key: 'sampleType',
      width: '15%'
    },
    {
      title: (
        <FormattedMessage
          id="formula.version"
          defaultMessage="Formula Version"
        />
      ),
      dataIndex: versionIndex,
      key: 'version',
      width: '15%'
    },
    {
      title: (
        <FormattedMessage id="formula.status" defaultMessage="Formula Status" />
      ),
      dataIndex: statusIndex,
      key: 'status',
      width: '15%',
      render: status => {
        return (
          <span className={MAPPING_FORMULA_STATUS_TO_CLASS_NAME[status]}>
            <FormattedMessage
              id={MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID[status]}
              defaultMessage={status}
            />
          </span>
        );
      },
      filters: FILTER_DETERGENT_LIST_BY_STATUS_LABEL,
      filterMultiple: false
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnViewDetails"
          defaultMessage="View details"
        />
      ),
      key: 'action',
      render: ({ id, detergentId }) => {
        if (isExpandRow) {
          const viewDetailUrl = buildUrlQueryParams('/detergent', {
            data: {
              detergentId,
              formulaId: id
            },
            action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
          });
          return (
            <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
              <img
                className={`${ACTION_BUTTON_TYPES.VIEW_DETERGENT_DETAIL} action-item-visible-no-effect`}
                title=""
                alt=""
                src="/icon/ic-view-details.svg"
              />
            </a>
          );
        }

        return null;
      }
    }
  ];
};

export const historyColumns = [
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnDatetime"
        defaultMessage="Datetime"
      />
    ),
    key: 'create_at',
    render: value => {
      return <div>{moment(value.create_at).format('lll')}</div>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnContent"
        defaultMessage="Content"
      />
    ),
    key: 'content',
    render: value => {
      return (
        <div>
          <span className="text-bold text-black">{value.user} </span>
          <FormattedMessage
            id="common.madeChanges"
            defaultMessage="made changes"
          />
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage id="rawMaterial.columnField" defaultMessage="Field" />
    ),
    key: 'field',
    ellipsis: true,
    render: value => {
      return (
        <div className="div-changes">
          {value.changes.map((item, idx) => {
            if (Array.isArray(item.fieldLabel)) {
              if (!item.oldValue.length > 0 && !item.newValue.length > 0)
                return;
              return <div>- {item.fieldLabel}</div>;
            }
            return <div>- {String(item.fieldLabel)}</div>;
          })}
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnOriginValue"
        defaultMessage="Origin Value"
      />
    ),
    key: 'originalValue',
    ellipsis: true,
    render: value => {
      return (
        <div className="div-changes">
          {value.changes.map((item, idx) => {
            if (Array.isArray(item.oldValue)) {
              if (!item.oldValue.length > 0 && !item.newValue.length > 0)
                return;
              return (
                <div>
                  -
                  {item.oldValue.map((childItem, childIdx) => {
                    if (childIdx === item.oldValue.length - 1) {
                      return (
                        <span key={childItem.value + idx}>
                          {' '}
                          {childItem.value}
                        </span>
                      );
                    }
                    return (
                      <span key={childItem.value + idx}>
                        {' '}
                        {childItem.value},
                      </span>
                    );
                  })}
                </div>
              );
            }
            if (item.fieldLabel === RAW_MATERIAL_LABELS.commodityGroup) {
              return (
                <div>- {parseNameById(item.oldValue, COMMODITY_GROUPS)}</div>
              );
            }
            return <div>- {String(item.oldValue)}</div>;
          })}
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnNewValue"
        defaultMessage="New Value"
      />
    ),
    key: 'newValue',
    ellipsis: true,
    render: value => {
      return (
        <div className="div-changes">
          {value.changes.map((item, idx) => {
            if (Array.isArray(item.newValue)) {
              if (!item.oldValue.length > 0 && !item.newValue.length > 0)
                return;
              return (
                <div>
                  -
                  {item.newValue.map((childItem, childIdx) => {
                    if (childIdx === item.newValue.length - 1) {
                      return (
                        <span key={childItem.value + idx}>
                          {' '}
                          {childItem.value}
                        </span>
                      );
                    }
                    return (
                      <span key={childItem.value + idx}>
                        {' '}
                        {childItem.value},
                      </span>
                    );
                  })}
                </div>
              );
            }
            if (item.fieldLabel === RAW_MATERIAL_LABELS.commodityGroup) {
              return (
                <div>- {parseNameById(item.newValue, COMMODITY_GROUPS)}</div>
              );
            }
            return <div>- {String(item.newValue)}</div>;
          })}
        </div>
      );
    }
  }
];
