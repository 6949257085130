import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import * as _ from 'lodash';
import PropType from 'prop-types';
import { Form, Input, Button, Row, Col } from 'antd';
import { OverlayLoading } from '../../../../../components/Loading';
import { createPartNumber } from '../../../data/partNumbers/actions';
import ContainerSize from './ContainerSize/ContainerSize';
import AnnualDemandContainer from './AnnualDemandContainer/AnnualDemandContainer';
import {
  getAnnualDemandYears,
  trimStringFieldsInObject,
  changeCommaToPoint
} from '../../../../../utils/common';
import { DetergentSelection } from '../../DetergentSelection/index';
import { getDetergentNonPaging } from '../../../../../data/detergentNonPaging/action';
import { getDetergentProducerNonPaging } from '../../../../../data/detergentProducerNonPaging/action';
import { DetergentProducerField } from '../../DetergentProducerField/index';
import { AdditionalDetergentProducerField } from '../../AdditionalDetergentProducerField/index';
import { FORM_STATUS } from '../../../../../configs/constant';
import { validatorFormatPatNumber } from '../../../../../utils/validatorFormatPartNumber';
import Country from '../../../../../components/Country';

const PartNumberForm = props => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const {
    form,
    form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue },
    onCancel
  } = props;

  const dispatch = useDispatch();
  const [annualDemandCount, setAnnualDemandCount] = useState(1);

  const {
    partNumbersData,
    modalLoading,
    detergentNonPaging,
    detergentProducerNonPaging,
    isDetergentProducerNonPagingFetching,
    isDetergentNonPagingFetching
  } = useSelector(state => ({
    partNumbersData: state.partNumbers.partNumberData,
    modalLoading: state.partNumbers.modal.loading,
    isDetergentProducerNonPagingFetching:
      state.detergentProducerNonPaging.isFetching,
    detergentProducerNonPaging: state.detergentProducerNonPaging.data,
    isDetergentNonPagingFetching: state.detergentNonPaging.isFetching,
    detergentNonPaging: state.detergentNonPaging.data
  }));

  useEffect(() => {
    dispatch(getDetergentProducerNonPaging());
    dispatch(getDetergentNonPaging());
  }, []);

  getFieldDecorator('annualDemandIndexes', {
    initialValue: _.isEmpty(partNumbersData.annualDemands)
      ? [0]
      : Object.keys(partNumbersData.annualDemands)
  });

  const annualDemandIndexes = getFieldValue('annualDemandIndexes');
  const annualDemandList = partNumbersData.annualDemands;

  const setYearsSelection = annualDemandSelectedYear => {
    const seletedYears = annualDemandSelectedYear.map(
      selectedYear => selectedYear.year
    );
    setRestrictedYears(yearList.filter(year => !seletedYears.includes(year)));
  };

  const handleAddMoreAnnualDemand = () => {
    if (annualDemandCount >= 7) return;
    const newIndex = getFieldValue('partNumber.annualDemands').length;
    const newAnnualDemandIndexes = annualDemandIndexes.concat(newIndex);

    setAnnualDemandCount(annualDemandCount + 1);
    setFieldsValue({ annualDemandIndexes: newAnnualDemandIndexes });
  };

  const handleRemoveAnnualDemand = index => {
    const newAnnualDemandIndexes = annualDemandIndexes.filter(
      annualDemandIndex => annualDemandIndex !== index
    );

    const annualDemandYears = getFieldValue(`partNumber.annualDemands`);

    const removeSelectedYears = annualDemandYears.filter((year, yearIndex) =>
      newAnnualDemandIndexes.includes(yearIndex)
    );

    setYearsSelection(removeSelectedYears);
    setAnnualDemandCount(annualDemandCount - 1);
    setFieldsValue({ annualDemandIndexes: newAnnualDemandIndexes });
  };

  const yearList = getAnnualDemandYears(); // 7 years

  const [restrictedYears, setRestrictedYears] = useState(yearList);
  const onChangeHandler = () => {
    const annualDemandYears = getFieldValue(`partNumber.annualDemands`);

    setYearsSelection(annualDemandYears);
  };

  const clearRemovedAnnualDemands = annualDemands =>
    annualDemands.filter(annualDemand => annualDemand !== null);

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) return;
      const formData = trimStringFieldsInObject(values.partNumber);
      const annualItem = formData.annualDemands.map(item => {
        return {
          numberOfContainers: changeCommaToPoint(item.numberOfContainers),
          year: item.year
        };
      });
      const submitData = {
        ...formData,
        annualDemands: clearRemovedAnnualDemands(annualItem)
      };
      _.set(
        submitData,
        'containerSize',
        changeCommaToPoint(submitData.containerSize)
      );

      if (submitData.detergentProducer.id === undefined) {
        _.set(submitData, 'detergentProducer', undefined);
      }

      if (submitData.additionalDetergentProducer.id === undefined) {
        _.set(submitData, 'additionalDetergentProducer', undefined);
      }

      dispatch(createPartNumber(submitData));
    });
  };

  const renderAnnualDemandList = () => {
    return annualDemandIndexes
      ? annualDemandIndexes.map(annualDemandIndex => (
          <div key={annualDemandIndex}>
            <AnnualDemandContainer
              form={form}
              annualDemandList={annualDemandList}
              annualDemandIndex={annualDemandIndex}
              onChangeHandler={onChangeHandler}
              yearList={restrictedYears}
              handleRemoveAnnualDemand={handleRemoveAnnualDemand}
            />
          </div>
        ))
      : null;
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 13 }
    }
  };

  return (
    <OverlayLoading
      loading={
        modalLoading ||
        isDetergentProducerNonPagingFetching ||
        isDetergentNonPagingFetching
      }>
      <div className="part-number">
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={handleSubmit}>
          <Form.Item
            label={
              <FormattedMessage
                id="partNumber.form.partNumber"
                defaultMessage="Part number"
              />
            }>
            {getFieldDecorator('partNumber.partNumber', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'partNumber.validate.partNumber',
                    defaultMessage: 'Part number is required.'
                  })
                },
                {
                  validator: validatorFormatPatNumber(formatMessage)
                }
              ],
              initialValue: partNumbersData.partNumber
            })(<Input />)}
          </Form.Item>
          <Country
            getFieldDecorator={getFieldDecorator}
            initValue={partNumbersData.fromCountryId || undefined}
            field="partNumber.fromCountryId"
          />
          <Form.Item
            label={
              <FormattedMessage
                id="partNumber.form.SAPDescription"
                defaultMessage="SAP Description"
              />
            }>
            {getFieldDecorator('partNumber.descriptionSAP', {
              initialValue: partNumbersData.descriptionSAP
            })(<Input />)}
          </Form.Item>

          <DetergentSelection
            form={form}
            detergentNonPaging={detergentNonPaging}
            partNumbersData={partNumbersData}
            formStatus={FORM_STATUS.CREATE}
          />

          <ContainerSize
            initialValue={partNumbersData}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
          />

          <DetergentProducerField
            form={form}
            detergentProducerNonPaging={detergentProducerNonPaging}
          />

          <AdditionalDetergentProducerField
            form={form}
            detergentProducerNonPaging={detergentProducerNonPaging}
          />

          <Form.Item
            label={
              <span className="annual-demand-label">
                <span>
                  <span className="required-mark">*</span>
                  <FormattedMessage
                    id="partNumber.form.annualDemand"
                    defaultMessage="Annual demand"
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="partNumber.form.inNumberOfContainers"
                    defaultMessage="in number of containers"
                  />
                </span>
              </span>
            }>
            {renderAnnualDemandList()}
          </Form.Item>

          {annualDemandCount < 7 ? (
            <Row>
              <Col md={{ span: 24, offset: 4 }} lg={{ span: 3, push: 4 }}>
                <div className="add-more" onClick={handleAddMoreAnnualDemand}>
                  <span>
                    <span className="add-more-plus">+</span>
                    <span>
                      <u>
                        <FormattedMessage
                          id="common.addMore"
                          defaultMessage="Add more"
                        />
                      </u>
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          ) : null}

          <Row gutter={24}>
            <Col span={24}>
              <div className="form-btn-actions">
                <Button className="btn-cancel" onClick={onCancel}>
                  <FormattedMessage
                    id="common.cancelButton"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <Button className="btn-save" type="primary" htmlType="submit">
                  <FormattedMessage
                    id="common.saveButton"
                    defaultMessage="SAVE"
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverlayLoading>
  );
};

PartNumberForm.propTypes = {
  form: PropType.shape({
    getFieldDecorator: PropType.func.isRequired,
    validateFields: PropType.func.isRequired,
    getFieldValue: PropType.func.isRequired,
    setFieldsValue: PropType.func.isRequired
  }).isRequired,
  onCancel: PropType.func.isRequired
};

export default Form.create({ name: 'part-number-form' })(PartNumberForm);
