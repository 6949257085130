import React from 'react';
import { isEmpty } from 'lodash';
import { Tooltip, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import {
  DISPLAY_PRODUCTION_CYCLE_STATUS,
  URL_PARAMS_ACTIONS
} from '../../../../configs/constant';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';
import { buildUrlQueryParams } from '../../../../utils/common';

export const renderProductionCycleColumns = dateFormation => [
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnCycleID"
        defaultMessage="Cycle ID"
      />
    ),
    key: 'id',
    dataIndex: 'id',
    width: '10%'
  },
  {
    title: (
      <FormattedMessage
        id="detergent.number"
        defaultMessage="Detergent Number"
      />
    ),
    key: 'detergentNumber',
    render: record => (
      <>{record?.partNumbers?.[0]?.detergentFormula?.detergent?.number}</>
    ),
    width: '18%'
  },
  {
    title: (
      <FormattedMessage id="detergent.name" defaultMessage="Detergent Name" />
    ),
    key: 'detergentName',
    render: record => {
      if (!isEmpty(record?.partNumbers?.[0]?.detergentFormula?.detergent)) {
        const {
          partNumbers: [
            {
              detergentFormula: {
                detergent: { id: detergentId, name: detergentName },
                id: formulaId
              }
            }
          ]
        } = record;
        const viewDetailUrl = buildUrlQueryParams('/detergent', {
          data: {
            detergentId,
            formulaId
          },
          action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
        });
        return (
          <div className="production-cycles--detergent-column">
            <span>{detergentName}</span>
            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.READ}>
              <Tooltip
                placement="bottomLeft"
                title={
                  <FormattedMessage
                    id="productionCycle.viewDetergent"
                    defaultMessage="View detergent"
                  />
                }>
                <a
                  href={viewDetailUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    className="action-item"
                    title=""
                    alt=""
                    src="/icon/ic-view-details.svg"
                  />
                </a>
              </Tooltip>
            </ProtectedComponent>
          </div>
        );
      }
      return null;
    },
    width: '18%'
  },
  {
    title: (
      <FormattedMessage
        id="partNumber.form.detergentProducer"
        defaultMessage="Detergent Producer"
      />
    ),
    key: 'detergentProducer',
    render: record => <>{record?.partNumbers?.[0]?.detergentProducer?.name}</>,
    width: '18%'
  },
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnCreateDate"
        defaultMessage="Creation Date"
      />
    ),
    key: 'createAt',
    dataIndex: 'createAt',
    render: creationDate => <span>{dateFormation(creationDate)}</span>,
    width: '12%'
  },
  {
    title: (
      <FormattedMessage id="productionCycle.status" defaultMessage="Status" />
    ),
    key: 'status',
    dataIndex: 'status',
    render: status => <span>{DISPLAY_PRODUCTION_CYCLE_STATUS[status]}</span>,
    width: '12%'
  },
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnCycleClosed"
        defaultMessage="Cycle closed"
      />
    ),
    key: 'cycleClosed',
    dataIndex: 'cycleClosed',
    align: 'center',
    render: cycleClosed => (
      <Checkbox
        className="production-cycles--checkbox"
        disabled
        defaultChecked={!!cycleClosed}
      />
    ),
    width: '12%'
  }
];
