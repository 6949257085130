import { AxiosService } from '../../../../services/axiosService';

export const getContainerTypeApi = ({ order, filter, pagination }) => {
  return AxiosService.get(
    'container-type',
    {
      order,
      filter
    },
    {
      pagination
    }
  );
};

export const exportContainerTypeApi = ({ filter }) => {
  return AxiosService.get('container-type/export', {
    filter
  });
};
