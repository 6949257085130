import React, { useEffect, useCallback, useReducer, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Typography } from 'antd';
import PropType from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import GeneratedFileCheckBoxesContainer from '../GeneratingFileSelectorModal/util/GeneratedFileCheckBoxesContainer';
import PrecalculationDownLoadCheckBox from '../GeneratingFileSelectorModal/GeneratedFileOption/PrecalculationDownLoadCheckBox';
import UploadedFileCheckBoxes from './UploadedFileCheckBoxes';
import { showSuccessToast } from '../../../../../services/toasterService';
import {
  pdfContentType,
  pdfExtension
} from '../../../../../components/ReactToPdf/constants';
import './index.scss';
import {
  reducer,
  ON_MODAL_OPEN,
  ON_CLEAR,
  ON_OK,
  ON_PRECALCULATION,
  ON_FILES_GENERATED_COMPLETE,
  SET_UPLOADED_FILES,
  getInitialStates
} from './indexReducer';

import {
  queueEmail,
  clearProcurementEmail,
  requestPresignedUrlAndUpload,
  getPrecalculationXlsxAsBase64
} from './state/action';
import { buildUrlQueryParams } from '../../../../../utils/common';
import {
  FORM_STATUS,
  URL_PARAMS_ACTIONS
} from '../../../../../configs/constant';
import { getFormFieldKey } from '../../Util/productionCycle/getFormFieldKey';
import { updateProductionCycles } from '../../../data/detergent/actions';
import { transformProductionCycleDataBeforeSaving } from '../../Util/productionCycle/transformProductionCycleDataBeforeSaving';

const ProcurementEmailFileSelectorModal = ({
  type,
  visible,
  setVisible,
  productionCycles,
  form,
  record,
  selectedProductionCycle,
  groupPartNumbersById
}) => {
  const { formatMessage } = useIntl();
  const { detergent, procurementEmail } = useSelector(state => ({
    precalculationFile: state.detergent.file.precalculation,
    detergent: state.detergent.emptyDetergent,
    procurementEmail: state.procurementEmail
  }));
  const [localState, localDispatch] = useReducer(reducer, getInitialStates());
  const {
    isOkClicked,
    isCancel,
    isPrecalculationSelected,
    generatedCompleteFiles,
    isHandleGeneratedFileComplete,
    uploadedFiles
  } = localState;
  
  const { languageCode } = useSelector(state => {
    return {
      languageCode: state.language.languageCode
    };
  });
  
  const dispatch = useDispatch();

  const onCancel = () => {
    setVisible(false);
    dispatch(clearProcurementEmail());
    localDispatch({
      type: ON_CLEAR,
      payload: {
        isCancel: true
      }
    });
  };

  useEffect(() => {
    if (!visible) return;
    const documents = detergent?.formulas?.[0]?.documents
      .map(documentItem => ({
        ...documentItem,
        productionCycles: documentItem.productionCycles.map(({ id }) => id)
      }))
      .filter(
        documentItem =>
          documentItem.productionCycles.indexOf(productionCycles.id) !== -1 ||
          documentItem.productionCycles?.length === 0
      );

    localDispatch({
      type: ON_MODAL_OPEN,
      payload: {
        documents
      }
    });
  }, [visible]);

  const onOk = useCallback(() => {
    // Dispatch Ok for the children components to sync
    localDispatch({
      type: ON_OK
    });
    if (isPrecalculationSelected) {
      // TODO: handle upload precalculation
      dispatch(
        getPrecalculationXlsxAsBase64({
          detergent,
          languageCode: languageCode ?? "en"
        })
      );
    }
  }, [isPrecalculationSelected]);

  // handle complete
  useEffect(() => {
    if (!procurementEmail.isQueuedSuccessful) return;
    onCancel();
    showSuccessToast('detergent.email.procurement.modal.successfulMessage');
  }, [procurementEmail.isQueuedSuccessful]);

  useEffect(() => {
    let uploadedFilesToWait = generatedCompleteFiles.length;
    if (isPrecalculationSelected) uploadedFilesToWait += 1;

    const validationStatus =
      procurementEmail.failedUploadedGeneratedFiles.length === 0 &&
      procurementEmail.uploadedGeneratedFiles.length === uploadedFilesToWait &&
      isHandleGeneratedFileComplete;

    if (!validationStatus) return;

    let comment = '';
    if (form) {
      comment = form.getFieldValue(getFormFieldKey(record, 'comment'));
    }

    const param = {
      files: uploadedFiles
        .filter(file => file.isSelected)
        .map(file => file.key),
      generatedFiles: procurementEmail.uploadedGeneratedFiles,
      rawMaterialsNotUsedAnymore: {
        ...productionCycles.rawMaterialsNotUsedAnymore
      },
      labelingInformation: {
        ...productionCycles.labelingInformation
      },
      producerName: productionCycles?.partNumbers?.[0]?.detergentProducer
        ? productionCycles?.partNumbers?.[0]?.detergentProducer?.name
        : 'Producer Name (in case the name has not been entered yet: Please select a producer in the KDD)',
      presenceOfDetergentDevelopment:
        productionCycles.presenceOfDetergentDevelopment,
      detergent: {
        id: detergent.id,
        formulaId: detergent?.formulas?.[0]?.id,
        name: detergent.name,
        number: detergent.number,
        formulaVersion: detergent?.formulas?.[0]?.version
      },
      viewDetailOnKddUrl: buildUrlQueryParams('/detergent', {
        data: {
          detergentId: detergent.id,
          formulaId: detergent?.formulas?.[0]?.id,
          detergentName: detergent.name
        },
        action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
      }),
      productionCycleId: record ? record.id : productionCycles.id,
      comment
    };
    dispatch(
      queueEmail({
        template: 'PROCUREMENT',
        param
      })
    );

    // save Safety, Labeling and Procurement
    if (type === FORM_STATUS.UPDATE) {
      form.validateFields((err, values) => {
        if (!err) {
          update(values);
        }
      });
    }
  }, [
    procurementEmail.failedUploadedGeneratedFiles.length,
    procurementEmail.uploadedGeneratedFiles.length,
    isHandleGeneratedFileComplete
  ]);

  const update = values => {
    const tranformedproductionCycles = transformProductionCycleDataBeforeSaving(
      values,
      groupPartNumbersById,
      selectedProductionCycle
    );
    dispatch(
      updateProductionCycles({
        productionCycles: tranformedproductionCycles,
        detergent,
        documents: values.documents
      })
    );
  };

  const folder = useMemo(
    () => `${detergent.name}-${detergent.formulas?.[0].version}`
  );

  useEffect(() => {
    if (isHandleGeneratedFileComplete && generatedCompleteFiles.length) {
      generatedCompleteFiles.forEach(({ pdfRenderer, fileName }) => {
        dispatch(
          requestPresignedUrlAndUpload({
            name: `${folder}/${fileName}.${pdfExtension}`,
            contentType: pdfContentType,
            file: pdfRenderer.output('blob')
          })
        );
      });
    }
  }, [isHandleGeneratedFileComplete]);

  const setIsPrecalculationSelected = value => {
    localDispatch({
      type: ON_PRECALCULATION,
      payload: {
        value
      }
    });
  };

  return (
    <>
      <Modal
        width="625px"
        className="procurement-email-attachment-file-modal"
        onCancel={onCancel}
        footer={
          <>
            <Button onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button
              className="generating-file-save-button icon-button"
              loading={isOkClicked}
              onClick={onOk}>
              <FormattedMessage
                id="common.sendEmail"
                defaultMessage="SEND EMAIL"
              />
            </Button>
          </>
        }
        visible={visible}
        title={formatMessage({
          id: 'detergent.email.procurement.modal',
          defaultMessage: 'ATTACH FILES'
        })}>
        <Typography.Paragraph>
          <FormattedMessage
            id="detergent.email.procurement.modal.description"
            defaultMessage="Please select the documents to send to procurement"
          />
        </Typography.Paragraph>

        <section className="procurement-email-attachments-container">
          <section className="procurement-email-attachment-uploaded">
            <Typography.Title
              className="procurement-email-attachment-header-title"
              level={4}>
              <FormattedMessage
                id="detergent.email.procurement.attachment.uploaded"
                defaultMessage="THE UPLOADED DOCUMENTS"
              />
            </Typography.Title>
            <UploadedFileCheckBoxes
              files={uploadedFiles}
              setFiles={files => {
                localDispatch({
                  type: SET_UPLOADED_FILES,
                  payload: {
                    files
                  }
                });
              }}
              disable={isOkClicked}
              isCancel={isCancel}
            />
          </section>
          <section className="procurement-email-attachment-generated">
            <Typography.Title
              className="procurement-email-attachment-header-title"
              level={4}>
              <FormattedMessage
                id="detergent.email.procurement.attachment.generated"
                defaultMessage="THE GENERATED DOCUMENTS"
              />
            </Typography.Title>
            <GeneratedFileCheckBoxesContainer
              isCancel={isCancel}
              onHandleFileComplete={files => {
                localDispatch({
                  type: ON_FILES_GENERATED_COMPLETE,
                  payload: {
                    generatedCompleteFiles: files
                  }
                });
              }}
              isOkClicked={isOkClicked}
              setIsPrecalculationSelected={setIsPrecalculationSelected}
            />
            <PrecalculationDownLoadCheckBox
              isPrecalculationSelected={isPrecalculationSelected}
              setIsPrecalculationSelected={setIsPrecalculationSelected}
              isOkClicked={isOkClicked}
            />
          </section>
        </section>
      </Modal>
    </>
  );
};

ProcurementEmailFileSelectorModal.propTypes = {
  type: PropType.string.isRequired,
  visible: PropType.bool,
  setVisible: PropType.func.isRequired,
  form: PropType.objectOf(PropType.shape({})).isRequired,
  record: PropType.objectOf(PropType.any).isRequired,
  productionCycles: PropType.shape({
    id: PropType.number,
    rawMaterialsNotUsedAnymore: PropType.shape({}),
    labelingInformation: PropType.shape({}),
    presenceOfDetergentDevelopment: PropType.bool
  }),
  selectedProductionCycle: PropType.shape({}),
  groupPartNumbersById: PropType.shape({})
};

ProcurementEmailFileSelectorModal.defaultProps = {
  visible: false,
  productionCycles: {
    id: null,
    rawMaterialsNotUsedAnymore: {},
    labelingInformation: {},
    presenceOfDetergentDevelopment: null
  },
  selectedProductionCycle: {},
  groupPartNumbersById: {}
};

export default ProcurementEmailFileSelectorModal;
