// Production Test Protocol

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { jsPDF } from 'jspdf';
import BaseProductTestProtocol from '../BaseProductTestProtocol/index';

const InitialProductionTestProtocol = ({
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer
}) => {
  const headerDivider = (
    <span>
      <FormattedMessage
        id="detergent.path"
        defaultMessage="Path: Tester initial production -> Tester quality control -> Development lead (filling, distributor: procurement, quality control, sales)"
      />
    </span>
  );
  return (
    <BaseProductTestProtocol
      title={
        <FormattedMessage
          id="detergent.initial.production.test.protocol"
          defaultMessage="INITIAL PRODUCTION TEST PROTOCOL"
        />
      }
      headerDivider={headerDivider}
      pdfRenderer={pdfRenderer}
      isRenderPdfNow={isRenderPdfNow}
      onPDFRenderedComplete={onPDFRenderedComplete}
    />
  );
};

InitialProductionTestProtocol.propTypes = {
  isRenderPdfNow: PropTypes.bool,
  onPDFRenderedComplete: PropTypes.func.isRequired,
  pdfRenderer: PropTypes.instanceOf(jsPDF)
};

InitialProductionTestProtocol.defaultProps = {
  isRenderPdfNow: false,
  pdfRenderer: null
};

export default InitialProductionTestProtocol;
