const chemicalQualityControlTestTableId = 'chemicalQualityControlTestTableId';

const chemicalQualityControlTestTableColumnWidth = {
  0: { cellWidth: 105 },
  1: { cellWidth: 50, halign: 'center' },
  2: { cellWidth: 40, halign: 'center' },
  3: { cellWidth: 40, halign: 'center' },
  4: { cellWidth: 95, halign: 'center' },
  5: { cellWidth: 75, halign: 'center' }
};

export {
  chemicalQualityControlTestTableId,
  chemicalQualityControlTestTableColumnWidth
};
