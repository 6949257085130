import { put, takeLatest } from 'redux-saga/effects';
import { errorHandle } from '../../utils/common';
import { getSearchDataApi } from './api';
import {
  getSearchDataSuccess,
  getSearchDataError,
  GET_SEARCH_DATA
} from './actions';

export function* getSearchDataSaga({ payload: { keywords, pagination } }) {
  try {
    const response = yield getSearchDataApi({
      keywords,
      pagination
    });

    yield put(getSearchDataSuccess(response.data));
  } catch (error) {
    yield put(getSearchDataError(error));
    errorHandle(error);
  }
}

export default function* universalSearchSaga() {
  yield takeLatest(GET_SEARCH_DATA, getSearchDataSaga);
}
