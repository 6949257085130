import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import CheckBoxIcon from './CheckBoxIcon';
import ProcurementEmailFileSelectorModal from '../../../../ProcurementEmailFileSelectorModal/index';
import { FORM_STATUS } from '../../../../../../../../configs/constant';

export const LabelingTeam = ({ record }) => {
  const [
    isProcurementEmailFileSelectorModalVisible,
    setIsProcurementEmailFileSelectorModal
  ] = useState(false);

  const handleProcurementEmailFileSelectorModalVisibility = useCallback(() => {
    setIsProcurementEmailFileSelectorModal(
      !isProcurementEmailFileSelectorModalVisible
    );
  }, [isProcurementEmailFileSelectorModalVisible]);

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.labelingTeam"
            defaultMessage="Labeling team"
          />
        </span>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.partsListUploaded}>
          <FormattedMessage
            id="productionCycle.partsListUploaded"
            defaultMessage="9. Parts list uploaded"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.productLabelUploaded}>
          <FormattedMessage
            id="productionCycle.productLabelUploaded"
            defaultMessage="10. Product label uploaded"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.transportLabelUploaded}>
          <FormattedMessage
            id="productionCycle.transportLabelUploaded"
            defaultMessage="11. Transport label uploaded"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record?.labelingInstructionsUploaded}>
          <FormattedMessage
            id="productionCycle.labelingInstructionsUploaded"
            defaultMessage="12. Labeling instructions uploaded"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <Button
          // disabled={!isEmailAttachmentPopupDisable}
          className="yellow-button"
          icon="right"
          onClick={handleProcurementEmailFileSelectorModalVisibility}>
          <FormattedMessage
            id="productionCycle.forwardToProcurement"
            defaultMessage="Forward to procurement"
          />
        </Button>
      </div>
      <ProcurementEmailFileSelectorModal
        type={FORM_STATUS.VIEW_DETAIL}
        visible={isProcurementEmailFileSelectorModalVisible}
        setVisible={setIsProcurementEmailFileSelectorModal}
        productionCycles={record}
      />
    </>
  );
};

LabelingTeam.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired
};
