import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DISPLAY_FORMULA_STATUS,
  ACTION_BUTTON_TYPES,
  MAPPING_FORMULA_STATUS_TO_CLASS_NAME
} from '../../../../configs/constant';
import Actions from './Actions';
import { getFullNameFromEmail } from '../../../../utils/common';
import FormulaEditForm from '../Form/UpdateFormula';
import { checkPermissionByRoles } from '../../../../utils/checkPermission';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';

export const detergentColumns = [
  {
    title: <FormattedMessage id="detergent.number" defaultMessage="Number" />,
    dataIndex: 'number',
    key: 'number',
    width: '12%'
  },
  {
    title: <FormattedMessage id="detergent.name" defaultMessage="Name" />,
    dataIndex: 'name',
    key: 'name',
    width: '25%'
  },
  {
    title: <FormattedMessage id="formula.version" defaultMessage="Version" />,
    key: 'version',
    width: '12%',
    render: record => <div>{record?.formulas?.[0]?.version}</div>
  },
  {
    title: (
      <FormattedMessage id="detergent.sample" defaultMessage="Sample Type" />
    ),
    key: 'sampleType',
    className: `${ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER} sample-cell`,
    width: '12%',
    render: record => {
      if (
        !checkPermissionByRoles(
          ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT
        )
      ) {
        return <div>{record?.formulas?.[0]?.sample}</div>;
      }

      return (
        <FormulaEditForm
          record={record}
          isSample
          isExpandListElement={false}
          currentId={record?.formulas?.[0]?.id}
        />
      );
    }
  },
  {
    title: <FormattedMessage id="formula.status" defaultMessage="Status" />,
    key: 'status',
    className: `${ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER} sample-cell`,
    width: '12%',
    render: record => {
      if (
        !checkPermissionByRoles(
          ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT
        )
      ) {
        const formulaStatus = record?.formulas?.[0]?.status;

        return (
          <div className={MAPPING_FORMULA_STATUS_TO_CLASS_NAME[formulaStatus]}>
            {DISPLAY_FORMULA_STATUS[formulaStatus]}
          </div>
        );
      }

      return (
        <FormulaEditForm
          record={record}
          isSample={false}
          isExpandListElement={false}
          currentId={record?.formulas?.[0]?.id}
        />
      );
    }
  },
  {
    title: (
      <FormattedMessage id="detergent.creatorName" defaultMessage="Creator" />
    ),
    key: 'creatorEmail',
    width: '14%',
    render: record => {
      return (
        <span style={{ textTransform: 'capitalize' }}>
          {getFullNameFromEmail(record?.formulas?.[0]?.creatorEmail)}
        </span>
      );
    }
  },
  {
    title: '',
    key: 'action',
    width: '15%'
  }
];

export const formulaColumns = selectedRowKeys => [
  {
    dataIndex: 'number',
    key: 'number',
    width: '12%'
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: '25%'
  },
  {
    key: 'version',
    width: '12%',
    dataIndex: 'version'
  },
  {
    key: 'sampleType',
    className: ' sample-cell',
    width: '12%',
    render: record => {
      if (
        !checkPermissionByRoles(
          ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT
        )
      ) {
        return <div>{record.sample}</div>;
      }

      return (
        <FormulaEditForm
          record={record}
          isSample
          isExpandListElement
          currentId={record.id}
        />
      );
    }
  },
  {
    key: 'status',
    className: ' sample-cell',
    width: '12%',
    render: record => {
      if (
        !checkPermissionByRoles(
          ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT
        )
      ) {
        const formulaStatus = record.status;

        return (
          <div className={MAPPING_FORMULA_STATUS_TO_CLASS_NAME[formulaStatus]}>
            {DISPLAY_FORMULA_STATUS[formulaStatus]}
          </div>
        );
      }

      return (
        <FormulaEditForm
          record={record}
          isSample={false}
          isExpandListElement
          currentId={record.id}
        />
      );
    }
  },
  {
    key: 'creatorEmail',
    width: '14%',
    render: record => {
      return (
        <span style={{ textTransform: 'capitalize' }}>
          {getFullNameFromEmail(record?.creatorEmail)}
        </span>
      );
    }
  },
  {
    key: 'action',
    width: '15%',
    render: record => {
      return (
        <div className="action-col">
          <Actions
            isSubList
            record={record}
            selectedRowKeys={selectedRowKeys}
          />
        </div>
      );
    }
  }
];
