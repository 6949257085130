import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import '../index.scss';
import { defaultProps, propTypes } from '../Util/detergentPropType';
import GeneratedFileTitleDateHeader from '../Util/GeneratedFileTitleDateHeader';
import BlankMeasurementDataSheetOneTable from './BlankMeasurementDataSheetOneTable';
import { halignCenterHeader } from '../../util/util';
import {
  phValueTableId,
  safetyTableId,
  storageTestTableId,
  testTableId,
  otherTableId,
  otherAppendixTableId,
  formulaMetaDataTableId,
  measurementMeasuredMinMaxCommentTableColumnWidth,
  storageTestTableColumnWidth,
  otherTableColumnWidth,
  otherAppendixTableColumnWidth
} from './constant';
import {
  addDynamicTables,
  addElementToPdf,
  parsePixelToPoint
} from '../../util/pdf';

import FormulaMetaData from './FormulaMetaData';
import { backgroundFillCell } from '../../util/constant';

const BaseSheetOne = ({
  className,
  measurement,
  formula,
  pdfRenderer,
  isRenderPdfNow,
  onPDFRenderedComplete
}) => {
  const { detergent } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent
  }));

  const detergentInfoContainer = useRef();
  const conclusionContainer = useRef();

  useEffect(() => {
    // we handle the effect of the generation here, not in saga because it couples with the DOM
    async function render() {
      const { canvas } = await addElementToPdf(
        pdfRenderer,
        detergentInfoContainer.current
      );

      addDynamicTables(pdfRenderer, [
        {
          id: testTableId,
          y: parsePixelToPoint(canvas.height),
          headStyles: backgroundFillCell,
          columnStyles: measurementMeasuredMinMaxCommentTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: phValueTableId,
          headStyles: backgroundFillCell,
          columnStyles: measurementMeasuredMinMaxCommentTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: safetyTableId,
          headStyles: backgroundFillCell,
          columnStyles: measurementMeasuredMinMaxCommentTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: storageTestTableId,
          headStyles: backgroundFillCell,
          columnStyles: storageTestTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: otherTableId,
          headStyles: backgroundFillCell,
          columnStyles: otherTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: otherAppendixTableId,
          headStyles: backgroundFillCell,
          columnStyles: otherAppendixTableColumnWidth,
          willDrawCell: data => halignCenterHeader(data)
        },
        {
          id: formulaMetaDataTableId,
          headStyles: backgroundFillCell,
          willDrawCell: data => halignCenterHeader(data)
        }
      ]);

      onPDFRenderedComplete();
    }

    if (isRenderPdfNow) {
      render();
    }
  }, [isRenderPdfNow]);

  return (
    <>
      <div ref={detergentInfoContainer} className={className}>
        <section className="generated-file-header-height">
          <GeneratedFileTitleDateHeader
            title={
              <FormattedMessage
                id="detergent.measurement.data.sheet"
                defaultMessage="MEASUREMENT DATA SHEET"
              />
            }
          />
        </section>
        <hr />
        <section className="generated-file-aligned-content">
          <section>
            <span>
              <FormattedMessage
                id="detergent.number"
                defaultMessage="Detergent Number"
              />
              : <strong> {detergent.number} </strong>
            </span>
            <span className="formula-version">
              <FormattedMessage
                id="formula.version"
                defaultMessage="Formula Version"
              />
              : <strong> {detergent.formulas?.[0]?.version} </strong>
            </span>
          </section>
          <p className="measurement-detergent-name">
            <FormattedMessage
              id="detergent.name"
              defaultMessage="Detergent Name"
            />
            :
            <strong className="detergent-name-label-pdf">
              {detergent.name}
            </strong>
          </p>
        </section>
      </div>
      <div className={className}>
        <BlankMeasurementDataSheetOneTable
          measurement={measurement}
          formula={formula}
        />
      </div>
      <div ref={conclusionContainer} className={className}>
        <FormulaMetaData formula={formula} />
      </div>
    </>
  );
};

BaseSheetOne.propTypes = propTypes;
BaseSheetOne.defaultProps = defaultProps;

export default BaseSheetOne;
