import { put, takeLatest } from 'redux-saga/effects';

import {
  getRawMaterialGroupApi,
  addRawMaterialGroupApi,
  updateRawMaterialGroupApi,
  getAllRawMaterialGroupApi
} from './api';
import {
  GET_RAW_MATERIAL_GROUP,
  getRawMaterialGroupSuccess,
  getRawMaterialGroupError,
  CREATE_RAW_MATERIAL_GROUP,
  createRawMaterialGroupSuccess,
  createRawMaterialGroupError,
  UPDATE_RAW_MATERIAL_GROUP,
  updateRawMaterialGroupSuccess,
  updateRawMaterialGroupError,
  getAllRawMaterialGroupSuccess,
  getAllRawMaterialGroupError,
  GET_ALL_RAW_MATERIAL_GROUP
} from './action';

import { showSuccessToast } from '../../../../services/toasterService';

import { errorHandle } from '../../../../utils/common';
import { BASE_DB_FIELDS } from '../../../../configs/constant';

export function* getRawMaterialGroupSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    if (
      !action.payload.modalLoadLatestDataAfterCreateOrUpdate &&
      order.hasOwnProperty(BASE_DB_FIELDS.UPDATE_AT)
    ) {
      delete order[BASE_DB_FIELDS.UPDATE_AT];
    }

    const response = yield getRawMaterialGroupApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        name: action.payload.searchByText
      }),
      order: JSON.stringify(order),
      filter: JSON.stringify(action.payload.filterList || '')
    });

    if (order.name) {
      response.data.data.results.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    if (order.status) {
      response.data.data.results.sort((a, b) => {
        return a.status.localeCompare(b.status);
      });
    }

    yield put(getRawMaterialGroupSuccess(response.data));
  } catch (error) {
    yield put(getRawMaterialGroupError(error));
  }
}

export function* createRawMaterialGroupSaga(action) {
  try {
    const response = yield addRawMaterialGroupApi(action.payload);
    yield put(createRawMaterialGroupSuccess(response.data));
    showSuccessToast('common.notify.rawMaterialGroup.createSuccess');
  } catch (error) {
    yield put(createRawMaterialGroupError(error));
    errorHandle(error);
  }
}

export function* updateRawMaterialGroupSaga(action) {
  try {
    const response = yield updateRawMaterialGroupApi(
      action.payload.id,
      action.payload.group
    );
    yield put(updateRawMaterialGroupSuccess(response.data));
    showSuccessToast('common.notify.rawMaterialGroup.updateSuccess');
  } catch (error) {
    yield put(updateRawMaterialGroupError(error));
    errorHandle(error);
  }
}

export function* getAllRawMaterialGroupSaga() {
  try {
    const response = yield getAllRawMaterialGroupApi();
    yield put(getAllRawMaterialGroupSuccess(response.data.data));
  } catch (error) {
    yield put(getAllRawMaterialGroupError(error));
    errorHandle(error);
  }
}

export default function* rawMaterialGroupSaga() {
  yield takeLatest(GET_RAW_MATERIAL_GROUP, getRawMaterialGroupSaga);
  yield takeLatest(CREATE_RAW_MATERIAL_GROUP, createRawMaterialGroupSaga);
  yield takeLatest(UPDATE_RAW_MATERIAL_GROUP, updateRawMaterialGroupSaga);
  yield takeLatest(GET_ALL_RAW_MATERIAL_GROUP, getAllRawMaterialGroupSaga);
}
