export const validatorFormatPatNumber = formatMessage => (
  rule,
  val,
  callback
) => {
  try {
    const count = val.length;
    const reg = /([0-9]{1}\.[0-9]{3}-[0-9]{3}\.[0-9]{1})/g;
    if (val !== '') {
      if (!reg.test(val) || count !== 11) {
        throw new Error(
          formatMessage({
            id: 'partNumber.formatNumber',
            defaultMessage: 'Part number just have format number is #.###-###.#'
          })
        );
      }
    }
    callback();
  } catch (error) {
    callback(error);
  }
};
