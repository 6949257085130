export const GET_PRODUCTION_CYCLE = 'GET_PRODUCTION_CYCLE';
export const GET_PRODUCTION_CYCLE_SUCCESS = 'GET_PRODUCTION_CYCLE_SUCCESS';
export const GET_PRODUCTION_CYCLE_ERROR = 'GET_PRODUCTION_CYCLE_ERROR';
export const CLEAR_PRODUCTION_CYCLE_DATA_WHEN_UNMOUNT =
  'CLEAR_PRODUCTION_CYCLE_DATA_WHEN_UNMOUNT';

export const getProductionCycle = payload => ({
  type: GET_PRODUCTION_CYCLE,
  payload
});

export const getProductionCycleSuccess = payload => ({
  type: GET_PRODUCTION_CYCLE_SUCCESS,
  payload
});

export const getProductionCycleError = payload => ({
  type: GET_PRODUCTION_CYCLE_ERROR,
  payload
});

export const clearProductionCycleDataWhenUnmount = () => ({
  type: CLEAR_PRODUCTION_CYCLE_DATA_WHEN_UNMOUNT
});
