import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Icon, Row, Col, Button, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FilterableSelect from '../../../../../components/FilterableSelect';
import { FILTER_PRODUCTION_CYCLE_BY_STATUS } from '../../../../../configs/constant';

const _FilterFormProductionCycle = props => {
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    filterValues,
    onFilter,
    onClearFilter,
    onCancel
  } = props;

  const { allDetergentProducers } = useSelector(state => ({
    allDetergentProducers: state.detergentProducers.allProducersSuppliers.list
  }));

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onCancel();
        onFilter(values);
      }
    });
  };

  const onClearFilterForm = () => {
    resetFields();
    onClearFilter();
    onCancel();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 10 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 14 }
    }
  };

  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onSubmit={handleSubmit}>
      <Form.Item
        className="filter-production-cycle-item"
        label={
          <FormattedMessage
            id="partNumber.form.detergentProducer"
            defaultMessage="Detergent producer"
          />
        }>
        {getFieldDecorator('detergentProducer', {
          initialValue: filterValues.detergentProducer || undefined
        })(
          <FilterableSelect
            className="filter-select"
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectProducer"
                defaultMessage="Select producer"
              />
            }
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {allDetergentProducers.map(producer => (
              <Select.Option key={producer.id} value={producer.id}>
                {producer.name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </Form.Item>
      <Form.Item
        className="filter-production-cycle-item"
        label={
          <FormattedMessage
            id="productionCycle.status"
            defaultMessage="Status"
          />
        }>
        {getFieldDecorator('status', {
          initialValue: filterValues.status || undefined
        })(
          <Select
            className="filter-select"
            placeholder={
              <FormattedMessage
                id="productionCycle.form.selectStatus"
                defaultMessage="Select status"
              />
            }
            suffixIcon={<Icon type="caret-down" />}>
            {FILTER_PRODUCTION_CYCLE_BY_STATUS.map(status => (
              <Select.Option key={status.value} value={status.value}>
                {status.text}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button className="btn-cancel" onClick={() => onClearFilterForm()}>
              <FormattedMessage
                id="common.clearFilter"
                defaultMessage="CLEAR FILTER"
              />
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              <FormattedMessage id="common.okButton" defaultMessage="OK" />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export const FilterFormProductionCycle = Form.create({
  name: 'filter-production-cycle'
})(_FilterFormProductionCycle);

_FilterFormProductionCycle.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  filterValues: PropTypes.shape({
    detergentProducer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    status: PropTypes.string
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
