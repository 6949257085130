import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Row, Col, Button, Select, Icon } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CONTAINER_GROUP } from '../../../../configs/constant';
import FilterableSelect from '../../../../components/FilterableSelect';

const ContainerTypeFilterForm = ({
  form: { getFieldDecorator, validateFields },
  onCancel,
  onFilter,
  onClearFilter
}) => {
  const { formatMessage } = useIntl();
  const { filterList, detergentProducers } = useSelector(state => ({
    filterList: state.containerType.filterList,
    detergentProducers: state.detergentProducers.allProducersSuppliers.list
  }));
  const containerGroupList = CONTAINER_GROUP(formatMessage);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        onFilter(values);
        onCancel();
      }
    });
  }, []);

  const handleClear = useCallback(() => {
    onClearFilter();
    onCancel();
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 10 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 14 }
    }
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerGroup"
            defaultMessage="Container group"
          />
        }>
        <div id="container-type-container-group">
          {getFieldDecorator('containerGroup', {
            initialValue: filterList.containerGroup || undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('container-type-container-group')
              }
              placeholder={
                <FormattedMessage
                  id="partNumber.form.selectContainerGroup"
                  defaultMessage="Select container group"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {containerGroupList.map(({ value, containerGroup }) => (
                <Select.Option key={value} value={value}>
                  {containerGroup}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="rawMaterial.columnProducer"
            defaultMessage="Producer"
          />
        }>
        {getFieldDecorator('detergentProducer', {
          initialValue: filterList.detergentProducer || undefined
        })(
          <FilterableSelect
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectProducer"
                defaultMessage="Select producer"
              />
            }
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {detergentProducers.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="containerType.columnContainerSize"
            defaultMessage="Container Size"
          />
        }>
        {getFieldDecorator('containerSize', {
          initialValue: filterList.containerSize || undefined
        })(
          <Input
            placeholder={formatMessage({
              id: 'partNumber.enterContainerSize',
              defaultMessage: 'Enter container size'
            })}
          />
        )}
      </Form.Item>

      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button className="btn-cancel" onClick={handleClear}>
              <FormattedMessage
                id="common.clearFilter"
                defaultMessage="CLEAR FILTER"
              />
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              <FormattedMessage id="common.okButton" defaultMessage="OK" />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

ContainerTypeFilterForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default Form.create({ name: 'container-type-filter-form' })(
  ContainerTypeFilterForm
);
