import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import { changePointToCommaExport } from '../../../../../../../utils/common';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { rawMaterialTableId } from './constant';

const GeneratedFormulaFileRawMaterialTable = ({ ratios }) => {
  const data = ratios.map(ratio => {
    return {
      ...ratio,
      ...ratio.rawMaterial
    };
  });
  const parseData = () => {
    for (let index = 0; index < data.length; index++) {
      data[index].number = index + 1;
    }
    return data;
  };
  const metaDataColumns = [
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnNumber"
          defaultMessage="Number"
        />
      ),
      dataIndex: 'number'
    },
    {
      title: (
        <FormattedMessage id="rawMaterial.columnRatio" defaultMessage="Ratio" />
      ),
      className: 'column-money',
      render: record => {
        return (
          <div>{changePointToCommaExport((record.ratio * 100).toFixed(4))}</div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="generateFormula.rawMaterial"
          defaultMessage="Raw Material"
        />
      ),
      dataIndex: 'name'
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnProducer"
          defaultMessage="Producer"
        />
      ),
      dataIndex: 'producer',
      key: 'producer',
      render: (text, record) => {
        const producers = record.producers || [];
        return producers.map(producer => producer.name).join(', ');
      }
    }
  ];

  return (
    <BaseTable
      className="formula-number-ratio-rawmaterials-producer"
      columns={metaDataColumns}
      dataSource={parseData()}
      pagination={false}
      tableElementId={rawMaterialTableId}
    />
  );
};

GeneratedFormulaFileRawMaterialTable.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  ratios: PropType.arrayOf({}),
  footerRef: PropType.shape({ current: PropType.instanceOf(Element) })
    .isRequired
};
GeneratedFormulaFileRawMaterialTable.defaultProps = {
  ratios: []
};

export default GeneratedFormulaFileRawMaterialTable;
