import { MEASUREMENT_MAPPING_FIELD } from '../../../../../configs/constant';

const generate = measurement => {
  const cloudPointDIN53917Info = { ...measurement.cloudPointDIN53917 };
  const densityDIN51757Info = { ...measurement.densityDIN51757 };
  const refrectionIndexInfo = { ...measurement.refrectionIndex };
  const bulkDensityDIN697Info = { ...measurement.bulkDensityDIN697 };
  const dryWeightMettlerIRdryer155Info = {
    ...measurement.dryWeightMettlerIRdryer155
  };
  const waterSolubilityInfo = { ...measurement.waterSolubility };
  const appearanceColourSmellInfo = {
    ...measurement.appearanceColourSmell
  };
  const massInfo = { ...measurement.mass };
  const dimensionsInfo = { ...measurement.dimensions };
  const durabilityInfo = { ...measurement.durability };
  const recommendedStorageTemparatureInfo = {
    ...measurement.recommendedStorageTemparature
  };
  const dissolutionTimeMinutesInfo = {
    ...measurement.dissolutionTimeMinutes
  };

  return [
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.cloudPointDIN53917.key,
      measured: cloudPointDIN53917Info.measured,
      min: cloudPointDIN53917Info.min,
      max: cloudPointDIN53917Info.max,
      comment: cloudPointDIN53917Info.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.densityDIN51757.key,
      measured: densityDIN51757Info.measured,
      min: densityDIN51757Info.min,
      max: densityDIN51757Info.max,
      comment: densityDIN51757Info.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.refrectionIndex.key,
      measured: refrectionIndexInfo.measured,
      min: refrectionIndexInfo.min,
      max: refrectionIndexInfo.max,
      comment: refrectionIndexInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.bulkDensityDIN697.key,

      measured: bulkDensityDIN697Info.measured,
      min: bulkDensityDIN697Info.min,
      max: bulkDensityDIN697Info.max,
      comment: bulkDensityDIN697Info.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.dissolutionTimeMinutes.key,
      measured: dissolutionTimeMinutesInfo.measured,
      min: dissolutionTimeMinutesInfo.min,
      max: dissolutionTimeMinutesInfo.max,
      comment: dissolutionTimeMinutesInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.waterSolubility.key,
      measured: waterSolubilityInfo.measured,
      comment: waterSolubilityInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.dryWeightMettlerIRdryer155.key,

      measured: dryWeightMettlerIRdryer155Info.measured,
      comment: dryWeightMettlerIRdryer155Info.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.appearanceColourSmell.key,
      measured: appearanceColourSmellInfo.measured,
      comment: appearanceColourSmellInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.mass.key,

      measured: massInfo.measured,
      comment: massInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.dimensions.key,

      measured: dimensionsInfo.measured,
      comment: dimensionsInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.durability.key,
      measured: durabilityInfo.measured,
      comment: durabilityInfo.comment
    },
    {
      cellKeyTest: MEASUREMENT_MAPPING_FIELD.recommendedStorageTemparature.key,
      measured: recommendedStorageTemparatureInfo.measured,
      comment: recommendedStorageTemparatureInfo.comment
    }
  ];
};

export default generate;
