import {
  GET_RAW_MATERIALS,
  GET_RAW_MATERIALS_SUCCESS,
  GET_RAW_MATERIALS_ERROR,
  CREATE_RAW_MATERIAL,
  CREATE_RAW_MATERIAL_SUCCESS,
  CREATE_RAW_MATERIAL_ERROR,
  GET_RAW_MATERIAL_BY_ID,
  GET_RAW_MATERIAL_BY_ID_SUCCESS,
  GET_RAW_MATERIAL_BY_ID_ERROR,
  RAW_MATERIAL_MODAL,
  BLOCK_RAW_MATERIAL,
  BLOCK_RAW_MATERIAL_SUCCESS,
  BLOCK_RAW_MATERIAL_ERROR,
  UPDATE_RAW_MATERIAL,
  UPDATE_RAW_MATERIAL_SUCCESS,
  UPDATE_RAW_MATERIAL_ERROR,
  GET_RAW_MATERIAL_HISTORY,
  GET_RAW_MATERIAL_HISTORY_SUCCESS,
  GET_RAW_MATERIAL_HISTORY_ERROR,
  GET_ALL_RAW_MATERIAL,
  GET_ALL_RAW_MATERIAL_SUCCESS,
  GET_ALL_RAW_MATERIAL_ERROR,
  CLEAR_RAW_MATERIAL_DATA_WHEN_UNMOUNT,
  GET_DETERGENT_BY_RAW_MATERIAL_ID,
  GET_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS,
  GET_DETERGENT_BY_RAW_MATERIAL_ID_ERROR,
  SET_LOADING_RAW_MATERIAL,
  CHECK_DETERGENT_FORMULA_BLOCK,
  CHECK_DETERGENT_FORMULA_BLOCK_SUCCESS,
  CHECK_DETERGENT_FORMULA_BLOCK_ERROR,
  RAW_MATERIAL_FETCH_FLAG,
  CLEAR_DETERGENT_DATA_BY_RAW_MATERIAL_ID_WHEN_UNMOUNT,
  EXPORT_DETERGENT_BY_RAW_MATERIAL_ID,
  EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS,
  EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_ERROR
} from './action';
import {
  downloadFile,
  transformHistoryToStandardData
} from '../../../../utils/common';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    }
  },
  allRawMaterials: {
    fetching: false,
    list: []
  },
  detergentListByRawMaterial: {
    fetching: false,
    data: {}
  },
  getDetergentByRawMaterial: {
    fetchingStatus: '',
    data: {}
  },
  searchByText: '',
  fetching: false,
  filterList: {
    rawMaterialProducer: '',
    rawMaterialSupplier: '',
    rawMaterialGroup: '',
    casNumber: '',
    einecsNumber: '',
    status: '',
    commodityGroup: '',
    fromCountryId: ''
  },
  order: {
    name: 'ASC',
    id: ''
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    countsRefeshList: 0,
    errorMessage: ''
  },
  rawMaterialData: {
    name: '',
    fromCountryId: '',
    isSupplierDifferentFromProducer: false,
    commodityGroup: '',
    chemicalName: '',
    casNumber: '',
    einecsNumber: '',
    price: null,
    currency: 'EUR',
    supplierOrProducerName: '',
    partNumber: '',
    additionalInformation: '',
    documents: [],
    isRestricted: false,
    retrictedCountries: [],
    producers: [],
    suppliers: [],
    rawMaterialGroups: []
  },
  history: {
    isFetchingHistory: false,
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 5,
        current: 1
      }
    }
  },
  export: {
    detergentListByRawMaterial: {
      isExporting: false
    }
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RAW_MATERIALS:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          }
        },
        fetching: true,
        searchByText: action.payload.searchByText || '',
        filterList: action.payload.filterList || initialState.filterList,
        order: action.payload.order || initialState.order,
        modal: {
          ...state.modal
        }
      };
    case GET_RAW_MATERIALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.payload
      };
    case GET_RAW_MATERIALS_ERROR:
      return {
        ...state,
        fetching: false
      };
    case CREATE_RAW_MATERIAL:
      return {
        ...state,
        rawMaterialData: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        rawMaterialData: initialState.rawMaterialData,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };

    case CREATE_RAW_MATERIAL_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case UPDATE_RAW_MATERIAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        rawMaterialData: initialState.rawMaterialData,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case UPDATE_RAW_MATERIAL_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case GET_RAW_MATERIAL_BY_ID:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };

    case GET_RAW_MATERIAL_BY_ID_SUCCESS:
      return {
        ...state,
        rawMaterialData: action.payload,
        modal: {
          ...state.modal,
          loading: false
        }
      };

    case GET_RAW_MATERIAL_BY_ID_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };

    case BLOCK_RAW_MATERIAL:
      return {
        ...state,
        fetching: true,

        modal: {
          ...state.modal,
          errorMessage: ''
        }
      };

    case BLOCK_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        fetching: false,
        modal: {
          ...state.modal,
          countsRefeshList: state.modal.countsRefeshList + 1,
          errorMessage: ''
        }
      };

    case BLOCK_RAW_MATERIAL_ERROR:
      return {
        ...state,
        fetching: false,
        modal: {
          ...state.modal,
          errorMessage: action.payload.response.data.data
        }
      };

    case GET_RAW_MATERIAL_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: true
        }
      };

    case GET_RAW_MATERIAL_HISTORY_SUCCESS: {
      const { results, pagination } = action.payload;
      const transformResults = transformHistoryToStandardData(null, results);

      return {
        ...state,
        history: {
          ...state.history,
          data: {
            results: transformResults,
            pagination
          },
          isFetchingHistory: false
        }
      };
    }

    case GET_RAW_MATERIAL_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: false
        }
      };

    case RAW_MATERIAL_MODAL:
      return {
        ...state,
        rawMaterialData:
          action.payload.rawMaterial || initialState.rawMaterialData,
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false
        },
        history: {
          ...initialState.history
        }
      };
    case GET_ALL_RAW_MATERIAL:
      return {
        ...state,
        allRawMaterials: {
          ...state.allRawMaterials,
          list: [],
          fetching: true
        }
      };
    case GET_ALL_RAW_MATERIAL_SUCCESS:
      return {
        ...state,
        allRawMaterials: {
          ...state.allRawMaterials,
          list: action.payload,
          fetching: false
        }
      };
    case GET_ALL_RAW_MATERIAL_ERROR:
      return {
        ...state,
        allRawMaterials: {
          ...state.allRawMaterials,
          fetching: false
        }
      };
    case CLEAR_RAW_MATERIAL_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    case GET_DETERGENT_BY_RAW_MATERIAL_ID:
      return {
        ...state,
        detergentListByRawMaterial: {
          ...state.detergentListByRawMaterial,
          fetching: true
        }
      };
    case GET_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS: {
      const { results, pagination } = action.payload.data.data;

      return {
        ...state,
        detergentListByRawMaterial: {
          ...state.detergentListByRawMaterial,
          data: {
            results,
            pagination
          },
          fetching: false
        }
      };
    }
    case GET_DETERGENT_BY_RAW_MATERIAL_ID_ERROR:
      return {
        ...state,
        detergentListByRawMaterial: {
          ...state.detergentListByRawMaterial,
          fetching: false
        }
      };
    case CLEAR_DETERGENT_DATA_BY_RAW_MATERIAL_ID_WHEN_UNMOUNT:
      return {
        ...state,
        detergentListByRawMaterial: {
          ...initialState.detergentListByRawMaterial
        }
      };
    case SET_LOADING_RAW_MATERIAL:
      return {
        ...state,
        fetching: action.payload.fetching
      };

    case CHECK_DETERGENT_FORMULA_BLOCK:
      return {
        ...state,
        fetching: true,
        getDetergentByRawMaterial: {
          ...state.getDetergentByRawMaterial,
          data: {},
          fetchingStatus: 'fetch'
        }
      };
    case CHECK_DETERGENT_FORMULA_BLOCK_SUCCESS:
      return {
        ...state,
        fetching: false,
        getDetergentByRawMaterial: {
          ...state.getDetergentByRawMaterial,
          data: action.payload.data.data.results,
          fetchingStatus: 'success'
        }
      };
    case CHECK_DETERGENT_FORMULA_BLOCK_ERROR:
      return {
        ...state,
        fetching: false,
        getDetergentByRawMaterial: {
          ...state.getDetergentByRawMaterial,
          fetchingStatus: 'fail'
        }
      };
    case RAW_MATERIAL_FETCH_FLAG:
      return {
        ...state,
        getDetergentByRawMaterial: {
          ...state.getDetergentByRawMaterial,
          data: {},
          fetchingStatus: 'close'
        }
      };

    case EXPORT_DETERGENT_BY_RAW_MATERIAL_ID:
      return {
        ...state,
        export: {
          detergentListByRawMaterial: {
            ...state.export.detergentListByRawMaterial,
            isExporting: true
          }
        }
      };

    case EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS: {
      const rawMaterialName = state.rawMaterialData.name;
      const type = 'data:text/csv;charset=utf-8';
      const fileName = `Raw-Material-${rawMaterialName}-Detergent-List.csv`;
      downloadFile(fileName, action.payload, type);
      return {
        ...state,
        export: {
          detergentListByRawMaterial: {
            ...state.export.detergentListByRawMaterial,
            isExporting: false
          }
        }
      };
    }

    case EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_ERROR:
      return {
        ...state,
        export: {
          detergentListByRawMaterial: {
            ...state.export.detergentListByRawMaterial,
            isExporting: false
          }
        }
      };

    default:
      return state;
  }
}
