import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Input, Icon } from 'antd';
import PropType from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import { setMeasurementDataForm } from '../../data/detergent/actions';
import EditableColumnField from '../../../../components/EditableColumnField';
import generateTestSchema from '../Util/measurement/testSchema';
import generateStorageTestSchema from '../Util/measurement/storageTestSchema';
import generatePhValueSchema from '../Util/measurement/phValueSchema';
import generateSafetySchema from '../Util/measurement/safetySchema';
import generateOtherSchema from '../Util/measurement/otherSchema';
import { renderMeasurementTestsColumns } from './renderMeasurementTestsColumns';
import { renderMeasurementStorageTestColumns } from './renderMeasurementStorageTestColumns';
import { renderMeasurementPhValueColumns } from './renderMeasurementPhValueColumns';
import { renderMeasurementSafetyColumns } from './renderMeasurementSafetyColumns';
import { renderMeasurementOtherColumns } from './renderMeasurementOtherColumns';

const MeasurementData = ({ isEditable }) => {
  const {
    listMeasurement,
    measureFetching,
    listMeasurementDataForm
  } = useSelector(state => ({
    listMeasurement: state.detergent.measurementData.data,
    measureFetching: state.detergent.measurementData.isFetching,
    listMeasurementDataForm: state.detergent.measurementDataForm
  }));

  const measurement = isEditable ? listMeasurementDataForm : listMeasurement;

  const test = generateTestSchema(measurement);
  const storageTest = generateStorageTestSchema(measurement);
  const phValue = generatePhValueSchema(measurement);
  const safety = generateSafetySchema(measurement);
  const other = generateOtherSchema(measurement);
  const renderLabInfo = () => {
    return (
      <EditableColumnField
        editable={isEditable}
        readableField={
          <Input.TextArea
            rows={4}
            disabled={!isEditable}
            defaultValue={listMeasurement.labInformation}
            className="measure-lab-info"
          />
        }
        editableField={
          <Input.TextArea
            rows={4}
            disabled={!isEditable}
            defaultValue={listMeasurement.labInformation}
          />
        }
        columnKey="labInformation"
        cellKey="labInformation"
        tabName="measurement"
      />
    );
  };
  return (
    <div className="measurement-data-table">
      <p className="tolerance-value">
        <FormattedMessage
          id="measurement.toleranceValues"
          defaultMessage="TOLERANCE VALUES"
        />
      </p>
      <BaseTable
        bordered
        columns={renderMeasurementTestsColumns(isEditable)}
        dataSource={test}
        loading={measureFetching && { indicator: <Icon type="sync" spin /> }}
      />
      <BaseTable
        bordered
        columns={renderMeasurementStorageTestColumns(isEditable)}
        dataSource={storageTest}
        className="measure-storage-style"
      />
      <BaseTable
        bordered
        columns={renderMeasurementPhValueColumns(isEditable)}
        dataSource={phValue}
      />
      <BaseTable
        bordered
        columns={renderMeasurementSafetyColumns(isEditable)}
        dataSource={safety}
      />
      <BaseTable
        bordered
        columns={renderMeasurementOtherColumns(isEditable)}
        dataSource={other}
      />
      <p style={{ marginTop: '10px' }}>
        <FormattedMessage
          id="measurement.labInformation.title"
          defaultMessage="LAB INFORMATION"
        />
      </p>
      {renderLabInfo()}
    </div>
  );
};

MeasurementData.protoTypes = {
  isEditable: PropType.bool
};

MeasurementData.defaultProps = {
  isEditable: false
};

export default MeasurementData;
