import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Select, Icon } from 'antd';
import {
  MEASUREMENT_MAPPING_FIELD,
  DISPLAY_MEASUREMENT_ASF,
  DISPLAY_MEASUREMENT_TEMPERATURES
} from '../../../../configs/constant';
import EditableColumnField from '../../../../components/EditableColumnField';
import FormattedInputNumber from '../../../../components/FormattedInputNumber';

const yesNo = [
  {
    value: '0',
    name: <FormattedMessage id="productionCycle.no" defaultMessage="No" />
  },
  {
    value: '1',
    name: <FormattedMessage id="productionCycle.yes" defaultMessage="Yes" />
  },
  {
    value: '2',
    name: <FormattedMessage id="productionCycle.blank" defaultMessage="Blank" />
  }
];

const temparatures = [
  {
    id: 'temperature-20',
    value: '20',
    name: '20 °C'
  },
  {
    id: 'temperature-25',
    value: '25',
    name: '25 °C'
  },
  {
    id: 'temperature-blank',
    value: '',
    name: <FormattedMessage id="productionCycle.blank" defaultMessage="Blank" />
  }
];

export const renderMeasurementOtherColumns = isEditable => [
  {
    title: (
      <FormattedMessage
        id="measurement.otherTable.title"
        defaultMessage="OTHER"
      />
    ),
    render: data => {
      return _.get(
        MEASUREMENT_MAPPING_FIELD,
        `${data.cellKeyOther}.name`,
        `No label: ${data.cellKeyOther}`
      );
    },
    key: 'cellKeyOther',
    width: '30%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.measured.title"
          defaultMessage="Measured value"
        />
      </div>
    ),
    key: 'measured',
    render: record => {
      if (record.cellKeyOther === 'asfOenorm5106') {
        const getValue = () => {
          if (record.measured) {
            return (
              <FormattedMessage
                id={`${DISPLAY_MEASUREMENT_ASF[record.measured]}`}
              />
            );
          }
          return record.measured;
        };
        return (
          <div id="otherMeasurement-parent-node">
            <EditableColumnField
              tabName="measurement"
              editable={isEditable}
              readableField={
                <Select
                  suffixIcon={<Icon type="caret-down" />}
                  disabled={!isEditable}
                  className="select-yesno-read"
                  defaultValue={getValue()}>
                  {yesNo.map((item, idx) => {
                    return (
                      <Select.Option key={idx} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
              cellKey={record.cellKeyOther}
              columnKey="measured"
              editableField={
                <Select
                  getPopupContainer={() =>
                    document.getElementById('otherMeasurement-parent-node')
                  }
                  suffixIcon={<Icon type="caret-down" />}
                  disabled={!isEditable}
                  className="select-yesno-edit"
                  defaultValue={getValue()}>
                  {yesNo.map((item, idx) => {
                    return (
                      <Select.Option key={idx} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
            />
          </div>
        );
      }
      if (record.cellKeyOther === 'temperature') {
        return (
          <div id="otherMeasurement-temperature">
            <EditableColumnField
              tabName="measurement"
              editable={isEditable}
              cellKey={record.cellKeyOther}
              columnKey="measured"
              readableField={
                <Input
                  className="measurement-temperature-input-read"
                  disabled
                  value={DISPLAY_MEASUREMENT_TEMPERATURES[record.measured]}
                />
              }
              editableField={
                <Select
                  getPopupContainer={() =>
                    document.getElementById('otherMeasurement-temperature')
                  }
                  suffixIcon={<Icon type="caret-down" />}
                  disabled={!isEditable}
                  className="select-yesno-edit"
                  defaultValue={
                    record.measured ? String(record.measured) : record.measured
                  }>
                  {temparatures.map(item => (
                    <Select.Option key={item.id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          </div>
        );
      }
      return (
        <EditableColumnField
          tabName="measurement"
          editable={isEditable}
          readableField={
            <FormattedInputNumber
              className="measure-display"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          editableField={
            <FormattedInputNumber
              className="measure-edit"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          cellKey={record.cellKeyOther}
          columnKey="measured"
        />
      );
    },
    width: '15%'
  },
  {
    width: '15%'
  },
  {
    width: '15%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.comment.title"
          defaultMessage="Comment"
        />
      </div>
    ),
    key: 'comment',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        record={record}
        editable={isEditable}
        readableField={
          <Input.TextArea
            className="measure-display"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
        editableField={
          <Input.TextArea
            className="measure-edit-comment"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
        cellKey={record.cellKeyOther}
        columnKey="comment"
      />
    ),
    width: '25%'
  }
];
