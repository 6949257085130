import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BaseTable } from '../BaseTable';

const BaseExpandableTable = ({ dataSource, ...props }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // Should clear expanded row keys when fetching data
  useEffect(() => {
    setExpandedRowKeys([]);
  }, [dataSource]);

  const onRowExpanded = (expanded, record) => {
    const newExpandedRowKeys = [...expandedRowKeys];
    if (expanded) {
      newExpandedRowKeys.push(record.id);
    } else {
      const index = newExpandedRowKeys.indexOf(record.id);
      if (index > -1) {
        newExpandedRowKeys.splice(index, 1);
      }
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  return (
    <BaseTable
      dataSource={dataSource}
      onExpand={(expanded, record) => onRowExpanded(expanded, record)}
      expandedRowKeys={expandedRowKeys}
      {...props}
    />
  );
};

BaseExpandableTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({}))
};

BaseExpandableTable.defaultProps = {
  dataSource: []
};

export default BaseExpandableTable;
