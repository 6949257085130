import React from 'react';
import { DatePicker, Select, Icon, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  wrapDateStringWithMoment,
  getLongDateFormat
} from '../../../../../utils/common';
import {
  getCurrentProductionCycleInstanceKey,
  getFormFieldKey
} from '../../Util/productionCycle/getFormFieldKey';
import FilterableSelect from '../../../../../components/FilterableSelect';
import { validatePartNumbers } from '../../Util/productionCycle/validator';
import { disableRowBySelectedProductionCyle } from './util/disableRowBySelectedProductionCyle';
import { STATUS_ENUM } from '../../../../../configs/constant';
import { checkInactivePartNumber } from './util/checkInactivePartNumber';

export const renderProductionCycleColumns = ({
  intl,
  form,
  partNumbers,
  selectedProductionCycle
}) => {
  const { locale, formatDate, formatMessage } = intl;
  const datePickerFormat = getLongDateFormat(locale);

  return [
    {
      title: (
        <FormattedMessage
          id="productionCycle.columnCycleID"
          defaultMessage="Cycle ID"
        />
      ),
      dataIndex: 'id',
      key: 'id',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnProducer"
          defaultMessage="Producer"
        />
      ),
      dataIndex: 'partNumbers.0.detergentProducer.name',
      key: 'detergentProducer',
      width: '20%',
      render: (text, record) => {
        let initialValue = '';
        if (record.partNumbers[0].detergentProducer) {
          initialValue = record.partNumbers[0].detergentProducer.name;
        }

        return <span>{initialValue}</span>;
      }
    },
    {
      title: (
        <FormattedMessage
          id="partNumber.form.partNumber"
          defaultMessage="Part number"
        />
      ),
      key: 'partNumber',
      width: '20%',
      className: 'partNumberSelection',
      render: record => {
        const fieldKey = getFormFieldKey(record, 'partNumbers');
        const initialValue = Array.isArray(record?.partNumbers)
          ? record.partNumbers.map(partNumber => partNumber.id)
          : [];
        const currentProductionCycleId = record.id;

        return (
          <Form.Item>
            {form.getFieldDecorator(fieldKey, {
              initialValue,
              required: true,
              rules: [
                {
                  validator: validatePartNumbers(
                    partNumbers,
                    currentProductionCycleId,
                    form,
                    formatMessage
                  )
                }
              ]
            })(
              <FilterableSelect
                mode="multiple"
                suffixIcon={<Icon type="caret-down" />}
                disabled={disableRowBySelectedProductionCyle(
                  selectedProductionCycle,
                  record
                )}
                placeholder={
                  <FormattedMessage
                    id="detergent.selectPartNumbers"
                    defaultMessage="Select part numbers"
                  />
                }>
                {partNumbers.map(({ id, partNumber, status }) => (
                  <Select.Option
                    className={
                      status !== STATUS_ENUM.ACTIVE
                        ? 'disabled-select-part-number'
                        : ''
                    }
                    key={id}
                    value={id}>
                    {partNumber}
                  </Select.Option>
                ))}
              </FilterableSelect>
            )}
          </Form.Item>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="productionCycle.columnCreateDate"
          defaultMessage="Creation Date"
        />
      ),
      key: 'createDate',
      width: '15%',
      render: record => {
        return (
          <span>{record.createAt ? formatDate(record.createAt) : null}</span>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="partnumbers.producerAccessDate"
          defaultMessage="Producer Access Date"
        />
      ),
      key: 'accessDate',
      width: '15%',
      render: record => {
        const fieldKey = getFormFieldKey(record, 'producerAccessDate');
        const currentProductionCycleInstanceKey = getCurrentProductionCycleInstanceKey(
          record
        );
        const productionCycles = form.getFieldValue('productionCycles');

        return form.getFieldDecorator(fieldKey, {
          initialValue: wrapDateStringWithMoment(record?.producerAccessDate)
        })(
          <DatePicker
            format={datePickerFormat}
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) ||
              checkInactivePartNumber(
                productionCycles,
                currentProductionCycleInstanceKey,
                partNumbers
              )
            }
          />
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="productionCycle.columnProductionDate"
          defaultMessage="Production date"
        />
      ),
      key: 'productionDate',
      width: '15%',
      render: record => {
        const fieldKey = getFormFieldKey(record, 'productionDate');
        const currentProductionCycleInstanceKey = getCurrentProductionCycleInstanceKey(
          record
        );
        const productionCycles = form.getFieldValue('productionCycles');

        return form.getFieldDecorator(fieldKey, {
          initialValue: wrapDateStringWithMoment(record?.productionDate)
        })(
          <DatePicker
            format={datePickerFormat}
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) ||
              checkInactivePartNumber(
                productionCycles,
                currentProductionCycleInstanceKey,
                partNumbers
              )
            }
          />
        );
      }
    },
    {
      title: '',
      width: '5%',
      render: () => {
        // render drop down button icon
        return <></>;
      }
    }
  ];
};
