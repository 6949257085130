import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Icon, Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  TYPE_FORMULA_FILTER,
  FORMULA_STATUS,
  DISPLAY_FORMULA_STATUS,
  COGNITO_USER_ATTRIBUTES,
  DISPLAY_COUNTRY
} from '../../../../configs/constant';
import { getDetergent } from '../../data/detergent/actions';
import {
  getFullNameFromEmail,
  getCognitoUserInfo
} from '../../../../utils/common';
import useCountriesByRole from '../../../../hooks/useCountriesByRole';

const FilterForm = props => {
  const dispatch = useDispatch();
  const countries = useCountriesByRole();
  const {
    userList,
    form: { getFieldDecorator, resetFields, validateFields },
    onCancel
  } = props;
  const { filterList, pagination, searchByText, order } = useSelector(
    state => ({
      filterList: state.detergent.filterList,
      pagination: state.detergent.list.data.pagination,
      searchByText: state.detergent.searchByText,
      order: state.detergent.order
    })
  );

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onCancel();
        pagination.current = 1;
        dispatch(
          getDetergent({ pagination, searchByText, order, filterList: values })
        );
      }
    });
  };

  const onClearFilter = () => {
    resetFields();
    const keys = Object.keys(filterList);
    keys.forEach(element => {
      filterList[element] = '';
    });
    pagination.current = 1;
    dispatch(getDetergent({ pagination, searchByText, order, filterList }));
    onCancel();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 15 }
    }
  };
  return (
    <Form
      className="filter-form"
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onSubmit={handleSubmit}>
      <Form.Item
        label={
          <FormattedMessage id="formula.type" defaultMessage="Formula type" />
        }>
        <div id="parent-node-filter-sample" className="filter-selector">
          {getFieldDecorator('sample', {
            initialValue: filterList.sample ? filterList.sample : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('parent-node-filter-sample')
              }
              placeholder={
                <FormattedMessage
                  id="placeholder.type"
                  defaultMessage="Select type"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {TYPE_FORMULA_FILTER.map(psItem => (
                <Select.Option key={psItem.value} value={psItem.value}>
                  {psItem.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.columnFormulaStatus"
            defaultMessage="Formula status"
          />
        }>
        <div id="parent-node-filter-status" className="filter-selector">
          {getFieldDecorator('status', {
            initialValue: filterList.status ? filterList.status : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('parent-node-filter-status')
              }
              placeholder={
                <FormattedMessage
                  id="partNumbers.selectStatus"
                  defaultMessage="Select status"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {FORMULA_STATUS.map(status => (
                <Select.Option key={status.value} value={status.value}>
                  {DISPLAY_FORMULA_STATUS[status.value]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="formula.creator"
            defaultMessage="Formula creator"
          />
        }>
        <div id="parent-node-filter-creatorEmail" className="filter-selector">
          {getFieldDecorator('creatorEmail', {
            initialValue: filterList.creatorEmail
              ? filterList.creatorEmail
              : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('parent-node-filter-creatorEmail')
              }
              placeholder={
                <FormattedMessage
                  id="formula.selectCreator"
                  defaultMessage="Select creator"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {userList.map(user => (
                <Select.Option
                  key={user?.Username}
                  value={getCognitoUserInfo(
                    user,
                    COGNITO_USER_ATTRIBUTES.email
                  )}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {getFullNameFromEmail(
                      getCognitoUserInfo(user, COGNITO_USER_ATTRIBUTES.email)
                    )}
                  </span>
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id="common.fromCountry" defaultMessage="Country" />
        }>
        <div id="parent-node-filter-country" className="filter-selector">
          {getFieldDecorator('fromCountryId', {
            initialValue: filterList.fromCountryId
              ? filterList.fromCountryId
              : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('parent-node-filter-country')
              }
              placeholder={
                <FormattedMessage
                  id="common.selectCountry"
                  defaultMessage="Select country"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {countries.map(country => (
                <Select.Option key={country.value} value={country.value}>
                  {DISPLAY_COUNTRY[country.value]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="Cancel"
              />
            </Button>
            <Button className="btn-cancel" onClick={() => onClearFilter()}>
              <FormattedMessage
                id="common.clearFilter"
                defaultMessage="CLEAR FILTER"
              />
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              <FormattedMessage id="common.okButton" defaultMessage="OK" />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

FilterForm.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

FilterForm.defaultProps = {
  userList: []
};

export default Form.create({ name: 'filter-detergent-form' })(FilterForm);
