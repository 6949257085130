import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  renderRowFormulaVersion,
  renderRowFields,
  renderRowStatus,
  renderContainerTypeFields,
  renderContainerGroupFields
} from '../../../Util/renderRowFields';
import { CONTAINER_TYPE } from '../../../../../configs/constant';
import { changePointToComma } from '../../../../../utils/common';

export const ViewPartNumbers = ({ partNumberDetailsData }) => {
  const containerTypes = CONTAINER_TYPE(true);
  const { formatMessage, formatDate } = useIntl();
  const { Others } = containerTypes;

  return (
    <>
      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnPartNumber',
          defaultMessage: 'Part number'
        }),
        _.get(partNumberDetailsData, 'partNumber', '')
      )}

      {renderRowFields(
        formatMessage({
          id: 'producer.country',
          defaultMessage: 'Country'
        }),
        _.get(partNumberDetailsData, 'fromCountry.name', '')
      )}

      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnSAPDescription',
          defaultMessage: 'SAP description'
        }),
        _.get(partNumberDetailsData, 'descriptionSAP', '')
      )}

      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnDetergentName',
          defaultMessage: 'Detergent Number'
        }),
        _.get(partNumberDetailsData, 'detergentFormula.detergent.number', '')
      )}

      {renderRowFormulaVersion(
        formatMessage({
          id: 'partNumbers.columnFormulaVersion',
          defaultMessage: 'Formula Version'
        }),
        _.get(partNumberDetailsData, 'detergentFormula.version', ''),
        _.get(partNumberDetailsData, 'detergentFormula.detergentId', ''),
        _.get(partNumberDetailsData, 'detergentFormula.id', '')
      )}

      {renderContainerTypeFields(
        formatMessage({
          id: 'partNumbers.columnContainerType',
          defaultMessage: 'Container type'
        }),
        _.get(partNumberDetailsData, 'containerType', '')
      )}
      {_.get(partNumberDetailsData, 'containerType', '') === Others.id &&
        renderRowFields(
          formatMessage({
            id: 'partNumbers.columnContainerTypeDescription',
            defaultMessage: 'Container type description'
          }),
          _.get(partNumberDetailsData, 'containerTypeDescription', '')
        )}
      {renderRowFields(
        formatMessage({
          id: 'detergent.containerSizeTitle',
          defaultMessage: 'Container size'
        }),
        changePointToComma(_.get(partNumberDetailsData, 'containerSize', ''))
      )}
      {renderContainerGroupFields(
        formatMessage({
          id: 'partNumber.form.containerGroup',
          defaultMessage: 'Container group'
        }),
        _.get(partNumberDetailsData, 'containerGroup', '')
      )}
      {renderRowStatus(
        formatMessage({
          id: 'partNumbers.columnStatus',
          defaultMessage: 'Product Status'
        }),
        _.get(partNumberDetailsData, 'status', '')
      )}
      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnDetergentProducerName',
          defaultMessage: 'Detergent producer'
        }),
        _.get(partNumberDetailsData, 'detergentProducer.name', '')
      )}

      {renderRowStatus(
        formatMessage({
          id: 'partNumbers.columnFormulaStatus',
          defaultMessage: 'Formula status'
        }),
        _.get(partNumberDetailsData, 'detergentFormula.status', '')
      )}

      {renderRowFields(
        formatMessage({
          id: 'partNumbers.columnFormulaApprovalDate',
          defaultMessage: 'Formula approval date'
        }),
        partNumberDetailsData?.detergentFormula?.approvedDate
          ? formatDate(partNumberDetailsData?.detergentFormula?.approvedDate)
          : null
      )}
    </>
  );
};

ViewPartNumbers.propTypes = {
  partNumberDetailsData: PropTypes.objectOf(PropTypes.any).isRequired
};
