import React, { useCallback, useMemo } from 'react';
import { Icon } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import { renderProductionCycleColumns } from './renderColumns';

export const ProductionCycleList = props => {
  const {
    dataList,
    isLoadingList,
    pagination,
    onPageSizeChange,
    onPageChange
  } = props;
  const { formatDate } = useIntl();

  const dateFormation = useCallback(
    date => (date ? formatDate(date) : null),
    []
  );

  const columns = useMemo(
    () => renderProductionCycleColumns(dateFormation),
    []
  );

  const tableProps = useMemo(
    () => ({
      bordered: true,
      loading: isLoadingList && { indicator: <Icon type="sync" spin /> }
    }),
    [isLoadingList]
  );

  return (
    <div className="production-cycles--table">
      <BaseTable
        {...tableProps}
        stickyHeader
        pagination={pagination}
        columns={columns}
        dataSource={dataList}
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
      />
    </div>
  );
};

ProductionCycleList.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoadingList: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};
