export const halignCenterHeader = data => {
  if (data.section === 'head' && data.column.index !== 0) {
    data.cell.styles.halign = 'center';
  }
};

export const customMetaDataTableStyles = data => {
  if (data.section === 'head' && data.column.index === 0) {
    data.cell.styles.halign = 'right';
  }
};

export const customRawMaterialTableStyles = data => {
  if (
    data.section === 'head' &&
    (data.column.index === 0 || data.column.index === 1)
  ) {
    data.cell.styles.halign = 'right';
  }
};
