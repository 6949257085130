import React from 'react';
import { FormattedMessage } from 'react-intl';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';
import BaseProductTestProtocol from '../BaseProductTestProtocol/index';

const ProductionTestProtocol = ({
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer
}) => {
  const headerDivider = (
    <span>
      <FormattedMessage
        id="detergent.fillingQualityControl"
        defaultMessage="Path: Tester initial production -> Tester quality control -> Development -> Quality control (filling quality control)"
      />
    </span>
  );
  return (
    <BaseProductTestProtocol
      title={
        <FormattedMessage
          id="detergent.production.test.protocol"
          defaultMessage="PRODUCTION TEST PROTOCOL"
        />
      }
      headerDivider={headerDivider}
      pdfRenderer={pdfRenderer}
      isRenderPdfNow={isRenderPdfNow}
      onPDFRenderedComplete={onPDFRenderedComplete}
    />
  );
};

ProductionTestProtocol.propTypes = {
  isRenderPdfNow: PropTypes.bool,
  onPDFRenderedComplete: PropTypes.func.isRequired,
  pdfRenderer: PropTypes.instanceOf(jsPDF)
};

ProductionTestProtocol.defaultProps = {
  isRenderPdfNow: false,
  pdfRenderer: null
};

export default ProductionTestProtocol;
