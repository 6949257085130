import React from 'react';
import PropTypes from 'prop-types';
import { Select, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

export const SelectionYears = ({
  onChange,
  yearList,
  restrictedYears,
  value
}) => {
  return (
    <div id="annual-demand-node">
      <Select
        onChange={onChange}
        value={value}
        getPopupContainer={() => document.getElementById('annual-demand-node')}
        placeholder={
          <FormattedMessage
            id="partNumber.form.selectAYear"
            defaultMessage="Select a year"
          />
        }
        suffixIcon={<Icon type="caret-down" />}>
        {yearList.map(year => (
          <Select.Option
            disabled={restrictedYears.includes(year)}
            key={year}
            value={year}>
            {year}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

SelectionYears.propTypes = {
  onChange: PropTypes.func.isRequired,
  yearList: PropTypes.arrayOf(PropTypes.number).isRequired,
  restrictedYears: PropTypes.arrayOf(PropTypes.number).isRequired,
  value: PropTypes.string.isRequired
};
