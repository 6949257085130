import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import FormularSelection from './FormularSelection';

const ValidatedFormulaSelection = ({
  getFieldDecorator,
  field,
  rawMaterials,
  status,
  placeholder,
  initialValue,
  label,
  disabled
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form.Item label={label}>
      {getFieldDecorator(field, {
        rules: [
          {
            required: true,
            message: formatMessage({
              id: 'common.validateRawMaterial',
              defaultMessage: 'Raw Materials is required.'
            }),
            type: 'array'
          },
          {
            validator: (rule, val, callback) => {
              const checkTotal = _.sumBy(val, ratio => ratio.ratio * 100);
              if (checkTotal.toFixed(4) / 100 !== 1) {
                callback(new Error());
              }
              callback();
            },
            message: formatMessage({
              id: 'common.messagePleaseChangeTheFormula',
              defaultMessage:
                'Please change the formula so that the total of the ratios is 100%.'
            })
          }
        ],
        initialValue
      })(
        <FormularSelection
          rawMaterials={rawMaterials}
          status={status}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};

ValidatedFormulaSelection.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  rawMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  status: PropTypes.string.isRequired,
  placeholder: PropTypes.node.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

ValidatedFormulaSelection.defaultProps = {
  initialValue: [],
  disabled: false
};

export default ValidatedFormulaSelection;
