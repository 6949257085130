import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "antd";
import { injectIntl } from "react-intl";
import { BaseTable } from "../../../../components/BaseTable";
import { getProducerColumnsFormat } from "./renderColumns";
import { ACTION_BUTTON_TYPES, STATUS_ENUM } from "../../../../configs/constant";

const _List = props => {
  const { pagination, detergentProducers, loading } = useSelector(state => ({
    pagination: state.detergentProducers.list.data.pagination,
    detergentProducers: state.detergentProducers,
    loading: state.detergentProducers.fetching
  }));

  const onClickRow = (event, record) => {
    const isEditRecordEvent = event.target.className.startsWith(ACTION_BUTTON_TYPES.EDIT_DETERGENT_PRODUCER);
    const isBlockRecordEvent = event.target.className.startsWith(ACTION_BUTTON_TYPES.BLOCK_DETERGENT_PRODUCER);
    const isUnblockRecordEvent = event.target.className.startsWith(ACTION_BUTTON_TYPES.UNBLOCK_DETERGENT_PRODUCER);

    if (isEditRecordEvent) {
      props.onEditProducer(record);
    } 
    else if (isBlockRecordEvent) {
      props.onDeactiveProducer(record, STATUS_ENUM.IN_ACTIVE);
    } 
    else if (isUnblockRecordEvent) {
      props.onDeactiveProducer(record, STATUS_ENUM.ACTIVE);
    }
  };

  return (
    <div className="table-producer">
      <BaseTable
        bordered
        stickyHeader
        onRow={record => {
          return {
            onClick: event => onClickRow(event, record)
          };
        }}
        pagination={pagination}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        columns={getProducerColumnsFormat(props.intl)}
        dataSource={detergentProducers.list.data.results}
        onPageSizeChange={value => props.onPageSizeChange(value)}
        onPageChange={current => props.onPageChange(current)}
      />
    </div>
  );
};

export const List = injectIntl(_List);
