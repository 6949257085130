import { getCognitoInfo } from './auth/cognito';

export const checkPermissionByRoles = (allowRoles = []) => {
  const userRoles = getCognitoInfo().roles;

  return (
    Array.isArray(allowRoles) &&
    userRoles.some(userRole => allowRoles.includes(userRole))
  );
};
