export const REQUEST_PRESIGNED_URL_AND_UPLOAD =
  'REQUEST_PRESIGNED_URL_AND_UPLOAD';
export const REQUEST_PRESIGNED_URL_AND_UPLOAD_SUCCESS =
  'REQUEST_PRESIGNED_URL_AND_UPLOAD_SUCCESS';
export const REQUEST_PRESIGNED_URL_AND_UPLOAD_ERROR =
  'REQUEST_PRESIGNED_URL_AND_UPLOAD_ERROR';

export const requestPresignedUrlAndUpload = payload => ({
  type: REQUEST_PRESIGNED_URL_AND_UPLOAD,
  payload
});

export const requestPresignedUrlAndUploadSuccess = payload => ({
  type: REQUEST_PRESIGNED_URL_AND_UPLOAD_SUCCESS,
  payload
});

export const requestPresignedUrlAndUploadError = payload => ({
  type: REQUEST_PRESIGNED_URL_AND_UPLOAD_ERROR,
  payload
});

export const QUEUE_EMAIL = 'QUEUE_EMAIL';
export const QUEUE_EMAIL_SUCCESS = 'QUEUE_EMAIL_SUCCESS';
export const QUEUE_EMAIL_ERROR = 'QUEUE_EMAIL_ERROR';
export const CLEAR_PROCUREMENT_EMAIL = 'CLEAR_PROCUREMENT_EMAIL';
export const GET_PRECALCULATION_XLSX_AS_BASE64 =
  'GET_PRECALCULATION_XLSX_AS_BASE64';

export const queueEmail = payload => ({
  type: QUEUE_EMAIL,
  payload
});

export const queueEmailSuccess = payload => ({
  type: QUEUE_EMAIL_SUCCESS,
  payload
});

export const queueEmailError = payload => ({
  type: QUEUE_EMAIL_ERROR,
  payload
});

export const getPrecalculationXlsxAsBase64 = payload => ({
  type: GET_PRECALCULATION_XLSX_AS_BASE64,
  payload
});

export const clearProcurementEmail = payload => ({
  type: CLEAR_PROCUREMENT_EMAIL,
  payload
});
