import { put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import {
  getPartNumberByIdApi,
  getPartNumberHistoryApi,
  createPartNumberApi,
  getPartNumberApi,
  updatePartNumberApi,
  patchPartNumberApi,
  migrateAnnualDemandApi
} from './api';
import {
  GET_PART_NUMBER_BY_ID,
  getPartNumberByIdSuccess,
  getPartNumberByIdError,
  GET_PART_NUMBER_HISTORY,
  getPartNumberHistorySuccess,
  getPartNumberHistoryError,
  CREATE_PART_NUMBER,
  createPartNumberSuccess,
  createPartNumberError,
  GET_PART_NUMBER,
  getPartNumberSuccess,
  getPartNumberError,
  UPDATE_PART_NUMBER,
  updatePartNumberSuccess,
  updatePartNumberError,
  PATCH_PART_NUMBER,
  patchPartNumberSuccess,
  patchPartNumberError,
  MIGRATE_ANNUAL_DEMAND,
  migrateAnnualDemandSuccess,
  migrateAnnualDemandError
} from './actions';
import { getPresignedUrlAndUpload } from '../../../../utils/s3Service';
import { errorHandle } from '../../../../utils/common';
import { showSuccessToast } from '../../../../services/toasterService';

export function* getPartNumberByIdSaga(action) {
  try {
    const { partNumberId } = action.payload;

    const response = yield getPartNumberByIdApi(partNumberId);
    yield put(getPartNumberByIdSuccess(response.data.data));
  } catch (error) {
    yield put(getPartNumberByIdError(error));
    errorHandle(error);
  }
}

export function* getPartNumberHistorySaga(action) {
  try {
    const response = yield getPartNumberHistoryApi(
      action.payload.id,
      action.payload.pagination
    );
    yield put(getPartNumberHistorySuccess(response.data.data));
  } catch (error) {
    yield put(getPartNumberHistoryError(error));
    errorHandle(error);
  }
}

export function* createPartNumberSaga(action) {
  try {
    const response = yield createPartNumberApi(action.payload);
    yield put(createPartNumberSuccess(response.data));
    showSuccessToast('common.notify.partNumber.createSuccess');
  } catch (error) {
    yield put(createPartNumberError(error));
    errorHandle(error);
  }
}

export function* getPartNumberSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    const response = yield getPartNumberApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        value: action.payload.searchByText
      }),
      order: JSON.stringify(order),
      filter: JSON.stringify(action.payload.filterList || '')
    });
    yield put(getPartNumberSuccess(response.data));
  } catch (error) {
    yield put(getPartNumberError(error));
  }
}

export function* updatePartNumberSaga(action) {
  try {
    const response = yield updatePartNumberApi(
      action.payload.id,
      action.payload
    );
    yield put(updatePartNumberSuccess(response.data));

    showSuccessToast('common.notify.partNumber.updateSuccess');
  } catch (error) {
    yield put(updatePartNumberError(error));
    errorHandle(error);
  }
}

export function* patchPartNumberSaga({ payload: { id, data } }) {
  try {
    const response = yield patchPartNumberApi(id, data);
    yield put(
      patchPartNumberSuccess({ data: response.data.data, status: data.status })
    );
    showSuccessToast('common.notify.partNumber.updateSuccess');
  } catch (error) {
    yield put(patchPartNumberError(error));
    errorHandle(error);
  }
}

export function* migrateAnnualDemandSaga(action) {
  const {
    payload: { year, uploadFile }
  } = action;
  try {
    yield getPresignedUrlAndUpload(
      uploadFile.name,
      uploadFile.contentType,
      uploadFile.file
    );

    const response = yield migrateAnnualDemandApi({
      year,
      fileName: uploadFile.name
    });
    yield put(migrateAnnualDemandSuccess(response.data));
    showSuccessToast('common.notify.partNumber.migrateAnnualDemandSuccess');
  } catch (error) {
    yield put(migrateAnnualDemandError(error));
    errorHandle(error);
  }
}

export default function* partNumberSaga() {
  yield takeLatest(GET_PART_NUMBER_BY_ID, getPartNumberByIdSaga);
  yield takeLatest(GET_PART_NUMBER_HISTORY, getPartNumberHistorySaga);
  yield takeLatest(CREATE_PART_NUMBER, createPartNumberSaga);
  yield takeLatest(GET_PART_NUMBER, getPartNumberSaga);
  yield takeLatest(UPDATE_PART_NUMBER, updatePartNumberSaga);
  yield takeLatest(PATCH_PART_NUMBER, patchPartNumberSaga);
  yield takeLatest(MIGRATE_ANNUAL_DEMAND, migrateAnnualDemandSaga);
}
