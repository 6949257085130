export const disableRowBySelectedProductionCyle = (
  selectedProductionCycle,
  record
) => {
  if (!selectedProductionCycle) {
    return false;
  }

  if (selectedProductionCycle) {
    const isDisable = selectedProductionCycle.id === record.id ? false : true;
    return isDisable;
  }

  return false;
};
