export default {
  container: { display: 'flex', justifyContent: 'space-between' },
  halfWidth: { width: '50%' },
  thirtyPercentWidth: { width: '30%' },
  seventyPercentWidth: { width: '70%' },
  fillable: {
    borderBottom: '1px dashed'
  },
  inlineBlock: {
    display: 'inline-block'
  },
  divider: {
    padding: '0px 30px',
    border: '1px #ccc',
    borderStyle: 'solid none solid none',
    marginBottom: '0px',
    textAlign: 'center',
    lineHeight: '28px'
  },
  sectionMargin: {
    marginTop: '20px'
  },
  fillableNoteMargin: {
    margin: '16px 0 0 0'
  },
  pLineHeight: {
    marginBottom: '0px',
    textAlign: 'center',
    lineHeight: '30px',
    padding: '0 40px'
  }
};
