import { Form, Icon, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BaseTable } from '../../../../../components/BaseTable';
import FilterableSelect from '../../../../../components/FilterableSelect/index';
import { validatePartNumbers } from '../../Util/productionCycle/validator';
import { renderReviewPartNumberColumns } from './renderReviewPartNumberColumns';
import { renderEditPartNumberColumns } from './renderEditPartNumberColumns';
import useDeviceDetect from '../../../../../hooks/useDeviceDetect';

export const PartNumberFields = props => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { height } = useDeviceDetect();
  const { form, partNumbers } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const selectedPartNumberIds = getFieldValue('partNumbers');
  const [selectedPartNumbers, setSelectedPartNumbers] = useState([]);
  const [editPartNumbers, setEditPartNumbers] = useState([]);

  useEffect(() => {
    if (Array.isArray(selectedPartNumberIds)) {
      const tempSelectedPartNumbers = [];

      selectedPartNumberIds.forEach(selectedPartNumberId => {
        const selectedPartNumber = partNumbers.find(
          partNumber => partNumber.id === selectedPartNumberId
        );
        tempSelectedPartNumbers.push(selectedPartNumber);
      });

      setSelectedPartNumbers(tempSelectedPartNumbers);
      if (tempSelectedPartNumbers.length === 0) {
        setEditPartNumbers([]);
      }
    }
  }, [selectedPartNumberIds]);

  const scrollablePartNumberTableHeight = screenHeight => {
    let scrollY = '30vh';
    if (screenHeight < 600) {
      scrollY = '20vh';
    } else if (screenHeight < 768) {
      scrollY = '25vh';
    }
    return scrollY;
  };

  const onEditPartNumber = record => {
    const newEditPartNumbers = [...editPartNumbers];
    newEditPartNumbers.push(record);
    setEditPartNumbers(newEditPartNumbers);
  };

  const onCancelEditPartNumber = record => {
    let newEditPartNumbers = [...editPartNumbers];
    newEditPartNumbers = newEditPartNumbers.filter(
      partnumber => partnumber.id !== record.id
    );
    setEditPartNumbers(newEditPartNumbers);
  };

  const renderReviewPartNumber = useCallback(() => {
    if (!selectedPartNumbers.length) {
      return null;
    }

    if (editPartNumbers.length > 0) {
      return (
        <div className="formItem">
          <div className="label-col" />
          <div className="value-col">
            <BaseTable
              className="table-background-gray-color"
              columns={renderEditPartNumberColumns({
                editPartNumbers,
                onEditPartNumber,
                onCancelEditPartNumber,
                form,
                intl,
                partNumbers
              })}
              dataSource={selectedPartNumbers}
              scroll={{ y: scrollablePartNumberTableHeight(height) }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="formItem">
        <div className="label-col" />
        <div className="value-col">
          <BaseTable
            className="table-background-gray-color"
            columns={renderReviewPartNumberColumns({ onEditPartNumber })}
            dataSource={selectedPartNumbers}
            scroll={{ y: scrollablePartNumberTableHeight(height) }}
          />
        </div>
      </div>
    );
  }, [
    editPartNumbers,
    selectedPartNumbers,
    scrollablePartNumberTableHeight(height)
  ]);

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.title"
            defaultMessage="Part Numbers"
          />
        }>
        {getFieldDecorator('partNumbers', {
          rules: [
            {
              validator: validatePartNumbers(
                partNumbers,
                null,
                form,
                formatMessage
              )
            }
          ]
        })(
          <FilterableSelect
            mode="multiple"
            suffixIcon={<Icon type="caret-down" />}
            placeholder={
              <FormattedMessage
                id="detergent.selectPartNumbers"
                defaultMessage="Select part numbers"
              />
            }>
            {partNumbers.map(partNumberItem => {
              return (
                <Select.Option
                  key={partNumberItem.id}
                  value={partNumberItem.id}>
                  {partNumberItem.partNumber}
                </Select.Option>
              );
            })}
          </FilterableSelect>
        )}
      </Form.Item>

      {renderReviewPartNumber()}
    </>
  );
};

PartNumberFields.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired,
  partNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
