import React from 'react';
import * as _ from 'lodash';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import merge from 'lodash/merge';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  createRawMaterialGroup,
  updateRawMaterialGroup
} from '../../data/rawMaterialGroup/action';
import { OverlayLoading } from '../../../../components/Loading';
import { FORM_STATUS } from '../../../../configs/constant';
import {
  trimStringFieldsInObject,
  filterObject,
  getFromCountry
} from '../../../../utils/common';
import Country from '../../../../components/Country';

const _RawMaterialGroup = props => {
  const {
    form: { getFieldDecorator },
    status: modalStatus,
    onCancel
  } = props;
  const dispatch = useDispatch();

  const { modalLoading, emptyGroup, countries } = useSelector(state => ({
    modalLoading: state.rawMaterialGroup.modal.loading,
    emptyGroup: state.rawMaterialGroup.emptyGroup,
    countries: state.countries.list
  }));

  const handleSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        if (modalStatus === FORM_STATUS.CREATE) {
          const groupFormData = trimStringFieldsInObject(values);
          dispatch(createRawMaterialGroup(groupFormData));
        } else if (modalStatus === FORM_STATUS.UPDATE) {
          const oldGroup = { ...emptyGroup };
          const newGroup = merge(oldGroup, values);
          const groupFormData = filterObject(
            trimStringFieldsInObject(newGroup),
            ['status']
          );
          _.set(
            groupFormData,
            'fromCountry',
            getFromCountry(countries, newGroup.fromCountryId)
          );
          dispatch(
            updateRawMaterialGroup({ id: newGroup.id, group: groupFormData })
          );
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 20 }
    }
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <div className="material-group">
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={handleSubmit}>
          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterialGroup.name"
                defaultMessage="Name"
              />
            }
            className="name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="common.validateName"
                      defaultMessage="Name is required!"
                    />
                  )
                }
              ],
              initialValue: emptyGroup.name
            })(<Input />)}
          </Form.Item>

          <Country
            getFieldDecorator={getFieldDecorator}
            initValue={emptyGroup.fromCountryId || undefined}
            field="fromCountryId"
            className="name"
          />

          <Form.Item
            label={<FormattedMessage id="rawMaterialGroup.description" />}
            className="description">
            {getFieldDecorator('description', {
              initialValue: emptyGroup.description
            })(<Input.TextArea rows={4} />)}
          </Form.Item>

          <Row>
            <Col span={24}>
              <div className="form-btn-actions">
                <Button className="btn-cancel" onClick={onCancel}>
                  <FormattedMessage
                    id="common.cancelButton"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <Button className="btn-save" type="primary" htmlType="submit">
                  <FormattedMessage
                    id="common.saveButton"
                    defaultMessage="SAVE"
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverlayLoading>
  );
};

_RawMaterialGroup.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const RawMaterialGroupForm = Form.create({ name: 'contact' })(
  _RawMaterialGroup
);
