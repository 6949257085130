import React, { useMemo } from 'react';
import PropType from 'prop-types';
import { useIntl } from 'react-intl';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { formulaMetaDataTableId } from './constant';

const BoldLabelValue = ({ label, value }) => {
  return (
    <p>
      <strong>{label}</strong> {value}
    </p>
  );
};

BoldLabelValue.propTypes = {
  label: PropType.string.isRequired,
  value: PropType.string.isRequired
};

const FormulaMetaData = ({ formula }) => {
  const { formatDate, formatMessage } = useIntl();
  const {
    noteOnFormulaChanges,
    approvedDate,
    createAt,
    updateAt,
    status,
    projectNumber,
    projectType
  } = formula;
  const date = new Date().toISOString();
  const formulaBlockage = useMemo(() => {
    return status === 'Blocked' ? formatDate(updateAt) : '';
  }, [status]);
  const project = useMemo(() => {
    if (projectNumber) {
      return projectType
        ? `${projectType} (${projectNumber})`
        : `(${projectNumber})`;
    }
    return projectType || '';
  }, [projectNumber, projectType]);

  const columns = useMemo(
    () => [
      {
        title: `${formatMessage({
          id: 'formula.note',
          defaultMessage: 'Note on formula changes'
        })}: ${noteOnFormulaChanges || ''}`,
        align: 'left',
        dataIndex: 'left',
        colSpan: 2
      },
      {
        colSpan: 0,
        dataIndex: 'right'
      }
    ],
    [formatMessage, noteOnFormulaChanges]
  );

  const data = useMemo(
    () => [
      {
        left: `${formatMessage({
          id: 'formula.creation',
          defaultMessage: 'Formula creation'
        })}: ${createAt ? formatDate(createAt) : ''}`,
        right: `${formatMessage({
          id: 'detergent.project',
          defaultMessage: 'project'
        })}: ${project}`
      },
      {
        left: `${formatMessage({
          id: 'formula.approval',
          defaultMessage: 'Formula approval'
        })}: ${approvedDate ? formatDate(approvedDate) : ''}`,
        right: `${formatMessage({
          id: 'formula.document.creation',
          defaultMessage: 'Document creation'
        })}: ${formatDate(date)}`
      },
      {
        left: `${formatMessage({
          id: 'formula.blockage',
          defaultMessage: 'Formula blockage'
        })}: ${formulaBlockage}`
      }
    ],
    [formatMessage, project, createAt, formulaBlockage, date, approvedDate]
  );

  return (
    <BaseTable
      columns={columns}
      dataSource={data}
      pagination={false}
      tableElementId={formulaMetaDataTableId}
    />
  );
};

FormulaMetaData.propTypes = {
  formula: PropType.shape({
    noteOnFormulaChanges: PropType.string,
    createAt: PropType.string,
    approvedDate: PropType.string,
    updateAt: PropType.string,
    status: PropType.string,
    projectNumber: PropType.string,
    projectType: PropType.string
  })
};

FormulaMetaData.defaultProps = {
  formula: {
    noteOnFormulaChanges: '',
    createAt: null,
    approvedDate: null
  }
};

export default FormulaMetaData;
