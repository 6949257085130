import { Modal } from 'antd';

const { confirm } = Modal;

/**
 * openConfirmModal({
 *  title: string | ReactNode,
 *  content: string | ReactNode,
 *  record: any, => this is the main argument for the function onOK
 *  onOk: function,
 *  onCancel: function,
 *  okText: string,
 *  cancelText: string,
 *  okType: string,
 *  width: number
 * })
 */
export const openConfirmModal = props => {
  confirm({
    // Default params
    className: 'base-confirm-modal',
    okType: 'danger',
    okText: 'Yes',
    cancelText: 'No',
    width: 520,
    // Override default params
    ...props,
    onOk() {
      return props.onOk(props.record);
    },
    onCancel() {
      if (typeof onCancel === 'function') {
        props.onCancel(props);
      }
    }
  });
};
