import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'antd';
import FilterForm from '../../components/Filter';
import ContainerTypeFilterForm from './components/Form/ContainerTypeFilterForm';
import ContainerTypeList from './components/List';
import * as ContainerTypeActions from './data/containerType/actions';
import { getAllDetergentProducers } from '../DetergentProducers/data/detergentProducers/actions';

const ContainerType = () => {
  const dispatch = useDispatch();
  const { pagination, filterList, order, loadingExport } = useSelector(
    state => ({
      pagination: state.containerType.list.data.pagination,
      filterList: state.containerType.filterList,
      order: state.containerType.order,
      loadingExport: state.containerType.export.fetching
    })
  );

  const handlePageSizeChange = useCallback(pageSize => {
    dispatch(ContainerTypeActions.changeTablePageSize({ pageSize }));
  }, []);

  const handlePageChange = useCallback(currentPageIndex => {
    dispatch(
      ContainerTypeActions.changeTablePage({ page: currentPageIndex + 1 })
    );
  }, []);

  const onFilter = useCallback(values => {
    dispatch(ContainerTypeActions.setFilterList({ filterList: values }));
  }, []);

  const onClearFilter = useCallback(() => {
    dispatch(ContainerTypeActions.clearFilterList());
  }, []);

  useEffect(() => {
    dispatch(getAllDetergentProducers());

    return () => {
      dispatch(ContainerTypeActions.clearContainerTypeDataWhenUnmount());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      ContainerTypeActions.getContainerType({
        pagination: {
          pageSize: pagination.pageSize,
          current: pagination.current
        },
        order,
        filterList
      })
    );
  }, [dispatch, pagination.pageSize, pagination.current, order, filterList]);

  const onExportContainerTypes = useCallback(() => {
    dispatch(ContainerTypeActions.exportContainerType({ filterList }));
  }, [filterList]);

  return (
    <>
      <div className="detergent-database-content">
        <div className="main-title">
          <FormattedMessage
            id="header.componentContainerType"
            defaultMessage="Container Type"
          />
        </div>
        <div className="container-type-filter-area filter-area">
          <FilterForm
            dropdownClassName="filter-partNumber filter-containerType"
            dropdownOverlayClassName="filter-container-type-content"
            FilterFormLayout={ContainerTypeFilterForm}
            hasMarginRight={false}
            filterFormProps={{
              onFilter,
              onClearFilter
            }}
          />
          <Button
            className="export-button"
            loading={loadingExport}
            onClick={onExportContainerTypes}>
            <img
              src="/icon/ic-print.svg"
              alt=""
              className="generate-file-icon"
            />
            <span>Export</span>
          </Button>
        </div>
      </div>
      <ContainerTypeList
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default injectIntl(ContainerType);
