import React, { useCallback } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { Pagination } from './components/Pagination';
import OveriddenTableElement from './components/OveriddenTableElement';

export const BaseTable = ({
  pagination,
  rowKey,
  onPageChange,
  onPageSizeChange,
  tableElementId,
  stickyHeader,
  className,
  ...props
}) => {
  const current = pagination ? pagination.current - 1 : 0;
  const totalPage = pagination
    ? Math.ceil(pagination.total / pagination.pageSize)
    : 0;

  const TableElement = useCallback(
    ({ children }) => {
      return (
        <OveriddenTableElement id={tableElementId}>
          {children}
        </OveriddenTableElement>
      );
    },
    [tableElementId]
  );

  return (
    <>
      <Table
        className={`${className} ${
          stickyHeader ? 'base-table--sticky-header' : ''
        }`}
        rowKey={rowKey}
        {...props}
        pagination={false}
        components={{
          table: TableElement
        }}
      />
      {pagination && (
        <Pagination
          pageSize={pagination.pageSize}
          pages={totalPage}
          page={current}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          calculateTotalPage={() => {}}
        />
      )}
    </>
  );
};

BaseTable.propTypes = {
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number
  }),
  rowKey: PropTypes.string,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  tableElementId: PropTypes.string,
  stickyHeader: PropTypes.bool,
  className: PropTypes.string
};

BaseTable.defaultProps = {
  pagination: null,
  rowKey: 'id',
  onPageChange: () => {},
  onPageSizeChange: () => {},
  tableElementId: '',
  stickyHeader: false,
  className: ''
};
