import { takeLatest } from 'redux-saga/effects';
import { updateUserApi } from './api';
import { CHANGE_LANGUAGE } from './action';
import { errorHandle } from '../../utils/common';
import userManager from '../../utils/auth/userManager';

export function* changeLanguageSaga(action) {
  try {
    const { languageCode: lang, changedByHeader } = action.payload;

    if (changedByHeader) {
      const user = yield userManager.getUser();
      const modifiedUserId = user?.profile?.username;

      yield updateUserApi(modifiedUserId, { lang });
    }
  } catch (error) {
    errorHandle(error);
  }
}

export default function* languageSaga() {
  yield takeLatest(CHANGE_LANGUAGE, changeLanguageSaga);
}
