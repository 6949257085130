/**
 * productionCycles['id_1'].productionDate
 * productionCycles['id_1'].rawMaterialsNotUsedAnymore.useUpInPreviousFormulaVersion
 * @param {*} record
 * @param {*} path
 */
export const getFormFieldKey = (record, childPath) => {
  const parentPath = `productionCycles['id_${record.id}']`;
  return [parentPath, childPath].join('.');
};

export const getCurrentProductionCycleInstanceKey = record => {
  return `id_${record.id}`;
};
