import React from 'react';
import PropType from 'prop-types';

const OveriddenTableElement = ({ id, children }) => {
  return <table id={id}>{children}</table>;
};

OveriddenTableElement.propTypes = {
  id: PropType.string,
  children: PropType.element.isRequired
};

OveriddenTableElement.defaultProps = {
  id: ''
};

export default OveriddenTableElement;
