export const GET_PART_NUMBER_NON_PAGING = 'GET_PART_NUMBER_NON_PAGING';
export const GET_PART_NUMBER_NON_PAGING_SUCCESS =
  'GET_PART_NUMBER_NON_PAGING_SUCCESS';
export const GET_PART_NUMBER_NON_PAGING_ERROR =
  'GET_PART_NUMBER_NON_PAGING_ERROR';

export const getPartNumberNonPaging = payload => ({
  type: GET_PART_NUMBER_NON_PAGING,
  payload
});

export const getPartNumberNonPagingSuccess = payload => ({
  type: GET_PART_NUMBER_NON_PAGING_SUCCESS,
  payload
});

export const getPartNumberNonPagingError = payload => ({
  type: GET_PART_NUMBER_NON_PAGING_ERROR,
  payload
});
