export const initialCreateProductionCycleData = {
  partNumbers: [],
  comment: '',
  formulaReviewed: null,
  productionInstructionsEntered: null,
  presenceOfDetergentDevelopment: null,
  safetyDataSheetUploaded: null,
  safetyTextReviewed: null,
  partsListUploaded: null,
  productLabelUploaded: null,
  transportLabelUploaded: null,
  labelingInstructionsUploaded: null,
  necessaryProtocols: {
    initialProductionTestProtocol: null,
    productionTestProtocol: null
  },
  rawMaterialsNotUsedAnymore: {
    useUpInPreviousFormulaVersion: null,
    useInOtherFormulas: null,
    doNotUseAnymore: null,
    rawMaterialsAreNotAffected: null
  },
  labelingInformation: {
    newLabel: null,
    useUpLabelsInStock: null,
    destroyLabelsInStock: null,
    labelsNotAffected: null
  },
  productionCycleProducerName: ''
};

export const initialCreateDummyPartNumberData = {
  partNumber: null,
  descriptionSAP: null,
  detergentProducer: null,
  detergentFormula: {
    id: null
  },
  containerType: null,
  annualDemands: []
};
