import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import { defaultProps, propTypes } from '../Util/detergentPropType';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { metaDataTableId } from './constant';
import { PROJECT_TYPES } from '../../../../../../../configs/constant';
import {
  changePointToCommaWithDecimals,
  getDensityLabel
} from '../../../../../../../utils/common';

const GeneratedFormulaFileMetaDataTable = ({ detergent }) => {
  const projectTypesName = useMemo(
    () => PROJECT_TYPES.reduce((a, b) => ({ ...a, [b.value]: b.name }), {}),
    [PROJECT_TYPES]
  );

  const densityLabel = _.get(detergent, 'formulas.0.form', '');

  const metaDataColumns = [
    {
      title: getDensityLabel(densityLabel),
      dataIndex: 'density'
    },
    {
      title: (
        <FormattedMessage id="detergent.project" defaultMessage="Project" />
      ),
      dataIndex: 'project'
    },
    {
      title: (
        <FormattedMessage
          id="formula.projectNumber"
          defaultMessage="Project Number"
        />
      ),
      dataIndex: 'projectNumber'
    },
    {
      title: (
        <FormattedMessage
          id="detergent.development"
          defaultMessage="Development"
        />
      ),
      dataIndex: 'development'
    }
  ];

  const data = [
    {
      density: changePointToCommaWithDecimals(
        detergent.formulas?.[0]?.density,
        3
      ),
      project: projectTypesName[detergent.formulas?.[0]?.projectType] || (
        <FormattedMessage
          id="formula.project.export"
          defaultMessage="Not available"
        />
      ),
      projectNumber: detergent.formulas?.[0]?.projectNumber || (
        <FormattedMessage
          id="formula.project.export"
          defaultMessage="Not available"
        />
      ),
      development: (
        <FormattedMessage
          id="formula.project.export"
          defaultMessage="Not available"
        />
      )
    }
  ];

  return (
    <BaseTable
      columns={metaDataColumns}
      dataSource={data}
      pagination={false}
      className="formula-project-number"
      tableElementId={metaDataTableId}
    />
  );
};

GeneratedFormulaFileMetaDataTable.propTypes = propTypes;
GeneratedFormulaFileMetaDataTable.defaultProps = defaultProps;

export default GeneratedFormulaFileMetaDataTable;
