import React, { useEffect, useRef } from 'react';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Input, Dropdown, Menu, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RawMaterialList } from './components/List';
import { RawMaterialModal } from './components/Modal';
import {
  getRawMaterials,
  processRawMaterialModal,
  blockRawMaterial,
  clearRawMaterialDataWhenUnmount,
  checkDetergentFormulaBlock,
  rawMaterialFetchFlag,
  getAllRawMaterial
} from './data/rawMaterial/action';
import { FilterFormRawMaterial } from './components/Form/FilterFormRawMaterial';
import { getCountries } from '../../data/countries/action';
import { getAllRawMaterialProducer } from '../RawMaterialProducer/data/rawMaterialProducers/actions';
import { getAllRawMaterialGroup } from '../RawMaterialGroup/data/rawMaterialGroup/action';
import {
  FORM_STATUS,
  URL_QUERY_NAMES,
  URL_PARAMS_ACTIONS
} from '../../configs/constant';

import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';
import { getUrlParameterByName } from '../../utils/common';
import FilterForm from '../../components/Filter/index';
import { getDetergentNonPaging } from '../../data/detergentNonPaging/action';
import { showErrorToast } from '../../services/toasterService';

const { confirm } = Modal;

const RawMaterial = props => {
  const { formatMessage } = props.intl;

  const dispatch = useDispatch();
  const {
    pagination,
    rawMaterials,
    searchByText,
    filterList,
    order,
    modalLoadLatestDataAfterCreateOrUpdate,
    rawMaterial,
    countsRefeshList,
    detergentNonPaging,
    listDetergentByRawMaterial,
    fetchingStatus
  } = useSelector(state => ({
    pagination: state.rawMaterials.list.data.pagination,
    rawMaterials: state.rawMaterials,
    searchByText: state.rawMaterials.searchByText,
    filterList: state.rawMaterials.filterList,

    modalLoadLatestDataAfterCreateOrUpdate:
      state.rawMaterials.modal.loadLatestDataAfterCreateOrUpdate,
    order: state.rawMaterials.order,

    rawMaterial: state.rawMaterials.rawMaterialData,
    countsRefeshList: state.rawMaterials.modal.countsRefeshList,
    detergentNonPaging: state.detergentNonPaging.data,
    listDetergentByRawMaterial:
      state.rawMaterials.getDetergentByRawMaterial.data,
    fetchingStatus: state.rawMaterials.getDetergentByRawMaterial.fetchingStatus
  }));
  const val = useRef('');
  const types = useRef('');

  useEffect(() => {
    let errorBlockRawMaterialMessage = '';
    const exitsDetergentName = [];

    if (val.current !== '' || types.current !== '') {
      if (fetchingStatus === 'success') {
        listDetergentByRawMaterial.forEach(detergent => {
          if (detergent.formulas?.length) {
            detergent.formulas.forEach(formula => {
              if (!['Blocked', 'Inactive'].includes(formula?.status)) {
                exitsDetergentName.push(detergent.name);
              }
            });
          }
        });

        if (exitsDetergentName.length > 0) {
          const configMessage = [
            {
              id: 'rawMaterial.messageBlock',
              defaultMessage:
                'This raw material is used in formula “{name}”. Please deactivate or block the formula before blocking the raw material.'
            },
            {
              name: exitsDetergentName.join(', ')
            }
          ];
          errorBlockRawMaterialMessage = formatMessage(...configMessage);
          showErrorToast(errorBlockRawMaterialMessage);
        } else if (types.current === 'Inactive') {
          confirm({
            className: 'base-confirm-modal',
            title: formatMessage({
              id: 'rawMaterial.modalUnBlockRawMaterialTitle',
              defaultMessage: 'UNBLOCK RAW MATERIAL'
            }),
            content: messageModal(
              val.current,
              types.current,
              errorBlockRawMaterialMessage
            ),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk() {
              blockRawMeterial(val.current, types.current);
            }
          });
        } else {
          confirm({
            className: 'base-confirm-modal',
            title: formatMessage({
              id: 'rawMaterial.modalBlockRawMaterialTitle',
              defaultMessage: 'BLOCK RAW MATERIAL'
            }),
            content: messageModal(
              val.current,
              types.current,
              errorBlockRawMaterialMessage
            ),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            icon: null,
            onOk() {
              blockRawMeterial(val.current, types.current);
            }
          });
        }
        dispatch(rawMaterialFetchFlag());
      }
    }
  }, [fetchingStatus]);
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getAllRawMaterial());
    dispatch(getAllRawMaterialProducer());
    dispatch(getAllRawMaterialGroup());
    dispatch(getDetergentNonPaging());

    return () => {
      dispatch(clearRawMaterialDataWhenUnmount());
    };
  }, []);
  useEffect(() => {
    const newOrder = { ...order };
    const newPagination = { ...pagination };

    dispatch(
      getRawMaterials({
        pagination: newPagination,
        searchByText,
        order: newOrder,
        filterList,
        modalLoadLatestDataAfterCreateOrUpdate
      })
    );
  }, [countsRefeshList]);

  useEffect(() => {
    const pathParams = getUrlParameterByName(URL_QUERY_NAMES.PARAMS);
    if (!_.isEmpty(pathParams)) {
      const { data, action } = pathParams;
      switch (action) {
        case URL_PARAMS_ACTIONS.OPEN_RAW_MATERIAL_DETAIL_DIALOG:
          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            onViewDetail(data.rawMaterialId);
          });
          break;
        default:
          break;
      }
    }
  }, []);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    dispatch(getRawMaterials({ pagination, searchByText, order, filterList }));
  };

  const onPageChange = current => {
    pagination.current = ++current;
    dispatch(getRawMaterials({ pagination, searchByText, order, filterList }));
  };

  const onEditRawMaterial = record => {
    if (!isEmpty(record)) {
      dispatch(
        processRawMaterialModal({
          rawMaterial: record,
          statusForm: FORM_STATUS.UPDATE,
          title: formatMessage({
            id: 'rawMaterial.modalEditRawMaterialTitle',
            defaultMessage: 'EDIT RAW MATERIAL'
          }),
          isVisible: true
        })
      );
    }
  };

  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');
    pagination.current = 1;
    dispatch(
      getRawMaterials({
        pagination,
        searchByText: valueChange,
        order,
        filterList
      })
    );
  };

  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(
        getRawMaterials({ pagination, searchByText: '', order, filterList })
      );
    }
  };

  const onSortChange = key => {
    if (key === 'name') {
      if (order[key] === 'DESC') {
        order[key] = 'ASC';
      } else {
        order[key] = 'DESC';
      }
    } else if (order[key] === '') {
      order[key] = 'ASC';
    } else {
      order[key] = '';
    }

    pagination.current = 1;
    dispatch(getRawMaterials({ pagination, searchByText, order, filterList }));
  };

  const onViewDetail = id => {
    dispatch(
      processRawMaterialModal({
        rawMaterial: { id },
        statusForm: FORM_STATUS.VIEW_DETAIL,
        title: formatMessage({
          id: 'rawMaterial.modalViewRawMaterialDetailsTitle',
          defaultMessage: 'VIEW RAW MATERIAL DETAILS'
        }),
        isVisible: true
      })
    );
  };

  const popupAction = action => {
    if (action === 'open') {
      dispatch(
        processRawMaterialModal({
          statusForm: FORM_STATUS.CREATE,
          title: formatMessage({
            id: 'rawMaterial.modalAddRawMaterialTitle',
            defaultMessage: 'ADD NEW RAW MATERIAL'
          }),
          isVisible: true
        })
      );
    } else {
      dispatch(
        processRawMaterialModal({
          isVisible: false
        })
      );
    }
  };

  const blockRawMeterial = (record, type) => {
    const data = {
      ...record,
      status: type,
      chemicalName: record.chemicalName === null ? '' : record.chemicalName
    };
    dispatch(blockRawMaterial({ data }));
  };
  const messageModal = (record, type, errorBlockRawMaterialMessage) => {
    if (errorBlockRawMaterialMessage !== '') {
      return <p className="content">{errorBlockRawMaterialMessage}</p>;
    }
    return (
      <p className="content">
        <Icon type="warning" className="warning-icon" />
        <div>
          <div>
            {formatMessage({
              id: 'common.messageAreYouSureWantTo',
              defaultMessage: 'Are you sure you want to'
            })}{' '}
            <span>
              {type === 'Blocked'
                ? formatMessage({
                    id: 'common.block',
                    defaultMessage: 'block'
                  })
                : formatMessage({
                    id: 'common.unblock',
                    defaultMessage: 'unblock'
                  })}
            </span>{' '}
            <b>“{record.name}”</b>?
          </div>
          {type === 'Blocked' ? (
            <div>
              {formatMessage({
                id:
                  'common.messageWhenTheRawMaterialIsBlockedItCanNoLongerBeAvailableForDetergent',
                defaultMessage:
                  'When the raw material is blocked it can no longer be available for detergents.'
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      </p>
    );
  };

  const onBlockRawMaterial = async (record, type) => {
    dispatch(checkDetergentFormulaBlock(record.id));
    val.current = record;
    types.current = type;
  };

  // Edit on View Raw Material Detail Popup
  const onEditWhenOnViewDetail = () => {
    dispatch(
      processRawMaterialModal({
        rawMaterial,
        isVisible: false
      })
    );

    dispatch(
      processRawMaterialModal({
        rawMaterial,
        statusForm: FORM_STATUS.UPDATE,
        title: formatMessage({
          id: 'rawMaterial.modalEditRawMaterialTitle',
          defaultMessage: 'EDIT RAW MATERIAL'
        }),
        isVisible: true
      })
    );
  };

  const SortPlace = () => {
    return (
      <Menu className="menu-sort">
        <Menu.Item
          key="0"
          className="sort-item"
          onClick={() => onSortChange('name')}>
          <div>
            {formatMessage({
              id: 'common.sortByName',
              defaultMessage: 'By name'
            })}
          </div>{' '}
          {order.name === 'DESC' ? <Icon type="check" /> : ''}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <div className="detergent-database-content">
        <div className="main-title">
          <FormattedMessage
            id="rawMaterial.title"
            defaultMessage="Raw Materials"
          />
        </div>
        <div className="rawMaterial-filter-area filter-area">
          <FilterForm
            dropdownClassName="filter-partNumber"
            dropdownOverlayClassName="filter-content"
            FilterFormLayout={FilterFormRawMaterial}
          />
          <div id="rawMaterial-sort-parent-node">
            <Dropdown
              getPopupContainer={() =>
                document.getElementById('rawMaterial-sort-parent-node')
              }
              className="sort-dropdown sort_box"
              overlay={<SortPlace />}
              trigger={['click']}
              overlayClassName="content-sort-form">
              <div className="ant-dropdown-link">
                <div className="edit_caret">
                  <Icon type="caret-up" />
                  <Icon type="caret-down" />
                </div>
                <div className="sort">
                  <FormattedMessage id="header.sort" defaultMessage="Sort" />
                </div>
              </div>
            </Dropdown>
          </div>
          <Input.Search
            className="search-place"
            name="raw-material-search"
            placeholder={formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearchList(value)}
          />

          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIALS.CREATE}>
            <Button className="add-button" onClick={() => popupAction('open')}>
              <Icon className="add-icon" type="plus" />
              <div className="text">
                {' '}
                {formatMessage({ id: 'rawMaterial.createButton' })}
              </div>
            </Button>
          </ProtectedComponent>

          <RawMaterialModal
            status={rawMaterials.modal.statusForm}
            title={rawMaterials.modal.title}
            isVisible={rawMaterials.modal.isVisible}
            onEditWhenOnViewDetail={() => onEditWhenOnViewDetail()}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <RawMaterialList
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onEditRawMaterial={record => onEditRawMaterial(record)}
        onViewDetail={id => onViewDetail(id)}
        onBlockRawMaterial={(record, type) => onBlockRawMaterial(record, type)}
      />
    </>
  );
};

export default injectIntl(RawMaterial);
