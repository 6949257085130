export const metaDataTableId = 'formula-metaDataTableId';
export const rawMaterialTableId = 'formula-rawMaterialTableId';

export const metaDataTableColumnWidth = {
  0: { cellWidth: 70, halign: 'right' },
  2: { cellWidth: 160 },
  3: { cellWidth: 160 }
};

export const rawMaterialTableColumnWidth = {
  0: { cellWidth: 50, halign: 'right' },
  1: { cellWidth: 95, halign: 'right' }
};
