import React from 'react';

import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { getCognitoInfo } from '../utils/auth/cognito';
import { isUserLoggedIn } from '../utils/auth/checkLogin';
import PublicRouteComponent from './PublicRouteComponent';
import DashboardComponent from '../containers/layout/Dashboard';

const { createBrowserHistory } = require('history');

const history = createBrowserHistory();

history.listen(() => {});

function AppRouter() {
  // this state is from redux oidc
  // if it is authenticated redirect to the admin panel else login page will be redirected
  const { token, roles } = getCognitoInfo();
  const auth = useSelector(state => state.auth);

  return (
    <div>
      <Router history={history}>
        {!isUserLoggedIn(token, auth) ? (
          <PublicRouteComponent />
        ) : (
          <DashboardComponent userRoles={roles} />
        )}
      </Router>
    </div>
  );
}

export default AppRouter;
