import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  buildUrlQueryParams,
  changeRatioDotToComma
} from '../../../../../utils/common';
import {
  URL_PARAMS_ACTIONS,
  ACTION_BUTTON_TYPES
} from '../../../../../configs/constant';

export const renderRawMaterialFomularColumns = () => [
  {
    title: (
      <FormattedMessage id="rawMaterial.columnName" defaultMessage="Name" />
    ),
    dataIndex: 'name',
    key: 'name',
    width: '20%'
  },
  {
    title: (
      <FormattedMessage id="rawMaterial.columnRatio" defaultMessage="Ratio" />
    ),
    key: 'ratio',
    render: record => {
      return <p>{changeRatioDotToComma(record.ratio)}%</p>;
    },
    width: '14%'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnProducer"
        defaultMessage="Producer"
      />
    ),
    dataIndex: 'producer',
    key: 'producer',
    render: (text, record) => {
      const producers = record.producers || [];
      return producers.map(producer => producer.name).join(', ');
    },
    width: '13%'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnSupplier"
        defaultMessage="Supplier"
      />
    ),
    dataIndex: 'supplier',
    key: 'supplier',
    render: (text, record) => {
      const suppliers = record.suppliers || [];
      return suppliers.map(producer => producer.name).join(', ');
    },
    width: '13%'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnPartNumber"
        defaultMessage="Part Number"
      />
    ),
    key: 'partNumber',
    render: record => {
      return <p>{record.partNumber}</p>;
    },
    width: '25%'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnViewDetails"
        defaultMessage="View details"
      />
    ),
    key: 'action',
    render: ({ rawMaterialId }) => {
      const viewDetailUrl = buildUrlQueryParams('/raw-material', {
        data: {
          rawMaterialId
        },
        action: URL_PARAMS_ACTIONS.OPEN_RAW_MATERIAL_DETAIL_DIALOG
      });
      return (
        <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
          <img
            className={`${ACTION_BUTTON_TYPES.VIEW_RAW_MATERIAL_DETAIL} action-item-visible-no-effect`}
            title=""
            alt=""
            src="/icon/ic-view-details.svg"
          />
        </a>
      );
    },
    width: '15%'
  }
];
