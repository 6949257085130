import {
  GET_DETERGENT_NON_PAGING,
  GET_DETERGENT_NON_PAGING_SUCCESS,
  GET_DETERGENT_NON_PAGING_ERROR
} from './action';

const initialState = {
  isFetching: false,
  data: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DETERGENT_NON_PAGING:
      return {
        ...state,
        isFetching: true
      };
    case GET_DETERGENT_NON_PAGING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case GET_DETERGENT_NON_PAGING_ERROR:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
}
