import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BaseTable } from '../../../../components/BaseTable';
import { renderLogListColumns } from './renderLogListColumns';

const LogsList = ({ onPageSizeChange, onPageChange }) => {
  const intl = useIntl();
  const { loading, logsList, pagination } = useSelector(state => ({
    logsList: state.logs.list.data.results,
    pagination: state.logs.list.data.pagination,
    loading: state.logs.list.fetching
  }));

  return (
    <div className="table-producer table-user-mangament">
      <BaseTable
        stickyHeader
        pagination={pagination}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        bordered
        columns={renderLogListColumns(intl)}
        dataSource={logsList}
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
      />
    </div>
  );
};

LogsList.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default LogsList;
