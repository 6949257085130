import {
  GET_PART_NUMBER_BY_ID,
  GET_PART_NUMBER_BY_ID_SUCCESS,
  GET_PART_NUMBER_BY_ID_ERROR,
  GET_PART_NUMBER_HISTORY,
  GET_PART_NUMBER_HISTORY_SUCCESS,
  GET_PART_NUMBER_HISTORY_ERROR,
  CREATE_PART_NUMBER,
  CREATE_PART_NUMBER_SUCCESS,
  CREATE_PART_NUMBER_ERROR,
  GET_PART_NUMBER,
  GET_PART_NUMBER_SUCCESS,
  GET_PART_NUMBER_ERROR,
  UPDATE_PART_NUMBER,
  UPDATE_PART_NUMBER_SUCCESS,
  UPDATE_PART_NUMBER_ERROR,
  CLEAR_PART_NUMBERS_DATA_WHEN_UNMOUNT,
  PART_NUMBERS_MODAL,
  MIGRATE_ANNUAL_DEMAND,
  MIGRATE_ANNUAL_DEMAND_SUCCESS,
  MIGRATE_ANNUAL_DEMAND_ERROR,
  SET_SELECTED_DROPDOWN_FIELD_FOR_PART_NUMBER,
  PATCH_PART_NUMBER,
  PATCH_PART_NUMBER_SUCCESS,
  PATCH_PART_NUMBER_ERROR
} from './actions';
import { transformHistoryToStandardData } from '../../../../utils/common';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    },
    fetching: false
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    countsRefeshList: 0,
    modalData: {}
  },
  searchByText: '',
  filterList: {
    detergentStatus: '',
    fromCountryId: ''
  },
  order: {
    partNumber: 'ASC'
  },
  detergentList: [],
  detergentProducerList: [],
  partNumberData: {
    partNumber: '',
    fromCountryId: '',
    descriptionSAP: '',
    annualDemands: [], // { year: null, numberOfContainers: 0 }
    containerType: '',
    containerTypeDescription: '',
    containerSize: '',
    detergentFormula: {},
    detergentProducer: {}
  },
  partNumberDetailsData: {
    isFetching: false,
    data: {}
  },
  selectedPartNumberChange: {
    id: 0
  },
  history: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    },
    isFetchingHistory: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PART_NUMBERS_MODAL:
      return {
        ...state,
        partNumberData:
          action.payload.partNumber || initialState.partNumberData,
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false,
          modalData: action.payload.modalData || {}
        }
      };

    case GET_PART_NUMBER_BY_ID:
      return {
        ...state,
        partNumberDetailsData: {
          ...state.partNumberDetailsData,
          isFetching: true
        }
      };
    case GET_PART_NUMBER_BY_ID_SUCCESS:
      return {
        ...state,
        partNumberDetailsData: {
          ...state.partNumberDetailsData,
          isFetching: false,
          data: action.payload
        }
      };
    case GET_PART_NUMBER_BY_ID_ERROR:
      return {
        ...state,
        partNumberDetailsData: {
          ...state.partNumberDetailsData,
          isFetching: false
        }
      };
    case GET_PART_NUMBER_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: true
        }
      };

    case GET_PART_NUMBER_HISTORY_SUCCESS: {
      const { results, pagination } = action.payload;
      const transformResults = transformHistoryToStandardData(null, results);

      return {
        ...state,
        history: {
          ...state.history,
          data: {
            results: transformResults,
            pagination
          },
          isFetchingHistory: false
        }
      };
    }

    case GET_PART_NUMBER_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: false
        }
      };

    case GET_PART_NUMBER:
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          },
          fetching: true
        },
        filterList: action.payload.filterList || initialState.filterList,
        searchByText: action.payload.searchByText || '',
        order: action.payload.order || initialState.order,
        modal: {
          ...state.modal
        }
      };
    case GET_PART_NUMBER_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          fetching: false
        }
      };
    }
    case GET_PART_NUMBER_ERROR: {
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    }
    case CREATE_PART_NUMBER:
      return {
        ...state,
        partNumberData: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_PART_NUMBER_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: 1
        }
      };
    case CREATE_PART_NUMBER_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case UPDATE_PART_NUMBER:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_PART_NUMBER_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case UPDATE_PART_NUMBER_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case PATCH_PART_NUMBER:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case PATCH_PART_NUMBER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            results: state.list.data.results.map(partNumber =>
              partNumber.id === action.payload.data.id
                ? {
                    ...partNumber,
                    status:
                      action.payload?.data?.status?.newValue ||
                      action.payload?.status
                  }
                : partNumber
            )
          },
          fetching: false
        }
      };
    case PATCH_PART_NUMBER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    case MIGRATE_ANNUAL_DEMAND:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };

    case MIGRATE_ANNUAL_DEMAND_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };

    case MIGRATE_ANNUAL_DEMAND_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case SET_SELECTED_DROPDOWN_FIELD_FOR_PART_NUMBER: {
      return {
        ...state,
        selectedPartNumberChange: {
          id: action.payload.selectedPartNumberId
        }
      };
    }
    case CLEAR_PART_NUMBERS_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
