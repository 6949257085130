import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { useSelector } from 'react-redux';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  sidebarLinksOfUserMode,
  sidebarLinksOfAdminMode,
  sidebarLinksOfSubMenu,
  otherLinkMenu
} from '../../../configs/sidebarLinks';
import { checkPermissionByRoles } from '../../../utils/checkPermission';

const { Sider } = Layout;

const Sidebar = () => {
  const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const { isActivatingAdminMode } = useSelector(state => ({
    isActivatingAdminMode: state.administration.isActivatingAdminMode
  }));
  const [sidebarLinksByPageMode, setSidebarLinksByPageMode] = useState([]);
  const [sidebarLinksBySubMenu, setSidebarLinksBySubMenu] = useState([]);
  const onCollapse = value => {
    setCollapsed(value);
  };

  const scanAndSetActiveMenu = useCallback(() => {
    const pathName = window.location.pathname;
    const split = pathName.split('/');

    if (split.length >= 2) {
      const rootPath = `/${split[1]}`;
      setActiveLink(rootPath);
    }
  }, []);

  useEffect(() => {
    scanAndSetActiveMenu();

    return history.listen(() => {
      scanAndSetActiveMenu();
    });
  }, [history, scanAndSetActiveMenu]);

  useEffect(() => {
    let sidebarLinks = isActivatingAdminMode
      ? sidebarLinksOfAdminMode
      : sidebarLinksOfUserMode;
    let sidebarLinksGroup = isActivatingAdminMode ? [] : sidebarLinksOfSubMenu;
    // Checking the permission of the route containers
    sidebarLinks = sidebarLinks.filter(sidebarLink =>
      checkPermissionByRoles(sidebarLink.roles)
    );
    sidebarLinksGroup = sidebarLinksGroup.filter(sidebarLink =>
      checkPermissionByRoles(sidebarLink.roles)
    );
    setSidebarLinksByPageMode(sidebarLinks);
    setSidebarLinksBySubMenu(sidebarLinksGroup);
  }, [isActivatingAdminMode]);

  const displayGroup = useMemo(() => {
    if (isActivatingAdminMode || sidebarLinksBySubMenu.length === 0) {
      return '';
    }
    return (
      <SubMenu
        key="sub1"
        title={
          <p style={{ display: 'flex' }}>
            <Icon
              className="icon-edit"
              component={() => (
                <>
                  <img
                    className="icon-menu icon-others"
                    alt=""
                    src="/icon/ic-raw-material-supplier.svg"
                  />
                </>
              )}
            />
            <span>{otherLinkMenu}</span>
          </p>
        }
        className="submenu-others">
        {sidebarLinksBySubMenu.map(sidebarLink => (
          <Menu.Item key={sidebarLink.href} className={sidebarLink.className}>
            <Link
              to={sidebarLink.href}
              style={{ display: 'flex' }}
              className="sidebar_group">
              <span className="sidebar_name">{sidebarLink.name}</span>
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>
    );
  }, [isActivatingAdminMode, sidebarLinksBySubMenu]);

  const renderSidebarLinksByPageMode = useMemo(
    () =>
      sidebarLinksByPageMode.map(sidebarLink => (
        <Menu.Item key={sidebarLink.href} className={sidebarLink.className}>
          <Link to={sidebarLink.href} style={{ display: 'flex' }}>
            <Icon
              className="icon-edit"
              component={() => (
                <>
                  <img className="icon-menu" alt="" src={sidebarLink.icon} />
                  <img
                    style={{ display: 'none' }}
                    className="icon-menu__hover"
                    alt=""
                    src={sidebarLink.icon_hover}
                  />
                </>
              )}
            />
            <span className="sidebar_name">{sidebarLink.name}</span>
          </Link>
        </Menu.Item>
      )),
    [sidebarLinksByPageMode]
  );

  return (
    <Sider
      width={250}
      className="db-sidebar"
      theme="dark"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}>
      <Menu theme="dark" mode="inline" selectedKeys={[activeLink]}>
        {renderSidebarLinksByPageMode}
        {displayGroup}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
