import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROLES_OF_PERMISSIONS } from './rolesOfPermissions';

export const sidebarLinksOfUserMode = [
  {
    name: (
      <FormattedMessage
        id="header.componentFormulas"
        defaultMessage="Formulas"
      />
    ),
    icon: '/icon/ic-formula.svg',
    icon_hover: '/icon/ic-formula_yellow.svg',
    href: '/detergent',
    className: 'ic-partNumbers',
    roles: ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.READ
  },
  {
    name: (
      <FormattedMessage id="rawMaterial.title" defaultMessage="Raw Materials" />
    ),
    icon: '/icon/ic-raw-material.svg',
    icon_hover: '/icon/ic-raw-material_yellow.svg',
    href: '/raw-material',
    className: 'ic-rawMaterial',
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIALS.READ
  },
  {
    name: (
      <FormattedMessage
        id="header.componentProducts"
        defaultMessage="Products"
      />
    ),
    icon: '/icon/ic-detergent.svg',
    icon_hover: '/icon/ic-detergent_yellow.svg',
    href: '/part-numbers',
    className: 'ic-detergent',
    roles: ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.READ
  },
  {
    name: (
      <FormattedMessage
        id="header.componentContainerType"
        defaultMessage="Container Type"
      />
    ),
    icon: '/icon/icon-part-number.svg',
    icon_hover: '/icon/icon-part-number_yellow.svg',
    href: '/container-type',
    className: 'ic-detergent',
    roles: ROLES_OF_PERMISSIONS.CONTAINER_TYPE.READ
  },
  {
    name: (
      <FormattedMessage
        id="header.componentProductionCycles"
        defaultMessage="Production Cycles"
      />
    ),
    icon: '/icon/ic-production-cycle.svg',
    icon_hover: '/icon/ic-production-cycle_yellow.svg',
    href: '/production-cycle',
    className: 'ic-detergent',
    roles: ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.READ
  }
];

export const sidebarLinksOfAdminMode = [
  {
    name: (
      <FormattedMessage
        id="header.componentUserManagement"
        defaultMessage="User Management"
      />
    ),
    icon: '/icon/ic-detergent-producer.svg',
    icon_hover: '/icon/ic-detergent-producer_yellow.svg',
    href: '/user-management',
    className: 'ic-userManagement',
    roles: ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ
  },
  {
    name: <FormattedMessage id="header.componentLogs" defaultMessage="Logs" />,
    icon: '/icon/ic-logs.svg',
    icon_hover: '/icon/ic-logs_yellow.svg',
    href: '/logs',
    className: 'ic-logs',
    roles: ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ
  }
];

export const sidebarLinksOfSubMenu = [
  {
    name: (
      <FormattedMessage
        id="header.componentRawMaterialGroup"
        defaultMessage="Raw Material Groups"
      />
    ),
    icon: '/icon/ic-raw-material-supplier.svg',
    icon_hover: '/icon/ic-raw-material-supplier_yellow.svg',
    href: '/group',
    className: 'ic-rawMaterialGroup',
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIAL_GROUPS.READ
  },
  {
    name: (
      <FormattedMessage
        id="header.componentRawMaterialProducer"
        defaultMessage="Raw Material Producers"
      />
    ),
    icon: '/icon/ic-raw-material-producer.svg',
    icon_hover: '/icon/ic-raw-material-producer_yellow.svg',
    href: '/raw-material-producers',
    className: 'ic-rawMaterialProducer',
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIAL_PRODUCERS_SUPPLIERS.READ
  },
  {
    name: (
      <FormattedMessage
        id="header.componentDetergentProducer"
        defaultMessage="Detergent Producers"
      />
    ),
    icon: '/icon/ic-detergent-producer.svg',
    icon_hover: '/icon/ic-detergent-producer_yellow.svg',
    href: '/detergent-producers',
    className: 'ic-rawMaterialProducer',
    roles: ROLES_OF_PERMISSIONS.DETERGENT_PRODUCERS.READ
  }
];

export const otherLinkMenu = (
  <FormattedMessage id="common.others" defaultMessage="Others" />
);
