import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { BaseTable } from '../../../../components/BaseTable';
import { renderUserListColumns } from './renderUserListColumns';

const UserList = props => {
  const { onEditUserRole, onChangeUserStatus } = props;
  const { loading, internalUserList } = useSelector(state => ({
    internalUserList: state.userManagement.list.data.results,
    loading: state.userManagement.list.fetching
  }));

  return (
    <div className="table-producer table-user-mangament">
      <BaseTable
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        bordered
        stickyHeader
        columns={renderUserListColumns({
          onEditUserRole,
          onChangeUserStatus
        })}
        dataSource={internalUserList}
        rowKey="Username"
      />
    </div>
  );
};

UserList.propTypes = {
  onEditUserRole: PropTypes.func.isRequired,
  onChangeUserStatus: PropTypes.func.isRequired
};

export default UserList;
