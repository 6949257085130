import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ACTION_BUTTON_TYPES } from '../../../../../../../configs/constant';

export const renderDetergentProductionCyclesColumns = intl => [
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnCycleID"
        defaultMessage="Cycle ID"
      />
    ),
    dataIndex: 'id',
    key: 'id',
    width: '8%'
  },
  {
    title: <FormattedMessage id="producer.id" defaultMessage="Producer ID" />,
    dataIndex: 'partNumbers[0].detergentProducer.externalId',
    width: '8%'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnProducer"
        defaultMessage="Producer"
      />
    ),
    key: 'detergentProducer',
    width: '15%',
    render: record => {
      const productionCycleProducerName = record?.productionCycleProducerName;
      const partNumbers = record?.partNumbers;
      const producerName = partNumbers[0]?.detergentProducer?.name;
      return <span>{productionCycleProducerName || producerName}</span>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumber.form.partNumber"
        defaultMessage="Part number"
      />
    ),
    key: 'partNumber',
    width: '15%',
    render: record => {
      const partNumbers = record?.partNumbers;
      const partNumberNames = partNumbers
        .map(partnumber => partnumber?.partNumber)
        .join(' | ');
      return <span>{partNumberNames}</span>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnCreateDate"
        defaultMessage="Creation Date"
      />
    ),
    key: 'createDate',
    width: '15%',
    render: record => {
      return (
        <span>
          {record?.createAt ? intl.formatDate(record?.createAt) : null}
        </span>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="partnumbers.producerAccessDate"
        defaultMessage="Producer Access Date"
      />
    ),
    key: 'accessDate',
    width: '14%',
    render: record => {
      return (
        <span>
          {record?.producerAccessDate
            ? intl.formatDate(record?.producerAccessDate)
            : null}
        </span>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="productionCycle.columnProductionDate"
        defaultMessage="Production date"
      />
    ),
    key: 'productionDate',
    width: '14%',
    render: record => {
      return (
        <span>
          {record.productionDate
            ? intl.formatDate(record.productionDate)
            : null}
        </span>
      );
    }
  },
  {
    title: '',
    width: '9%',
    render: () => {
      return (
        <>
          <img
            className={`${ACTION_BUTTON_TYPES.EDIT_PRODUCTION_CYCLE_SINGLE} action-item`}
            alt=""
            src="/icon/ic-edit.svg"
          />
          <img
            className={`${ACTION_BUTTON_TYPES.DELETE_PRODUCTION_CYCLE} action-item`}
            alt=""
            src="/icon/ic-delete.svg"
          />
        </>
      );
    }
  }
];
