import PropType from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { otherAppendixTableId, otherTableId } from './constant';
import { BaseTable } from '../../../../../../../components/BaseTable';
import {
  MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
  MEASUREMENT_ASF_CORE_VALUE
} from '../../../../../../../configs/constant';
import {
  capitalizeFirstLetter,
  changePointToCommaExport
} from '../../../../../../../utils/common';
import generateOtherSchema from '../../../../Util/measurement/otherSchema';
import renderPDFCheckBox from '../Util/renderPDFCheckBox';

const BlankMeasurementDataSheetOtherTable = ({ measurement }) => {
  const onYesRender = useCallback(value => {
    return renderPDFCheckBox({
      checked: value === MEASUREMENT_ASF_CORE_VALUE.yes
    });
  }, []);

  const onNoRender = useCallback(value => {
    return renderPDFCheckBox({
      checked: value === MEASUREMENT_ASF_CORE_VALUE.no
    });
  }, []);

  const otherTableMinMaxColumns = [
    {
      title: (
        <FormattedMessage
          id="measurement.otherTable.title"
          defaultMessage="OTHER"
        />
      ),
      dataIndex: 'cellKeyOther',
      render: value => {
        // TODO: add default message
        return (
          <FormattedMessage
            id={MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID[value]}
          />
        );
      },
      width: 300
    },
    {
      title: (
        <div
          className="alignCenterTitleColumn"
          style={{ alignItems: 'center', overflowWrap: 'break-word' }}>
          <FormattedMessage
            id="measurement.measured.title"
            defaultMessage="Measured value"
          />
        </div>
      ),
      dataIndex: 'measured',
      render: value => {
        return changePointToCommaExport(value);
      },
      width: 120
    },
    {
      title: <FormattedMessage id="comment.title" defaultMessage="Comment" />,
      dataIndex: 'comment',
      render: value => capitalizeFirstLetter(value),
      width: 300
    }
  ];

  const otherTableYesNoColumns = [
    {
      title: '',
      dataIndex: 'cellKeyOther',
      render: value => {
        // TODO: add default message
        return (
          <FormattedMessage
            id={MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID[value]}
          />
        );
      },
      width: 300
    },
    {
      title: <FormattedMessage id="productionCycle.yes" defaultMessage="Yes" />,
      dataIndex: 'measured',
      render: onYesRender,
      width: 60
    },
    {
      title: <FormattedMessage id="productionCycle.no" defaultMessage="No" />,
      dataIndex: 'measured',
      render: onNoRender,
      width: 60
    },
    {
      title: <FormattedMessage id="comment.title" defaultMessage="Comment" />,
      dataIndex: 'comment',
      render: value => capitalizeFirstLetter(value),
      width: 300
    }
  ];

  const generatedData = generateOtherSchema(measurement);

  const yesNoFormatData = generatedData.splice(generatedData.length - 1);

  return (
    <>
      <BaseTable
        className="generated-file-measurement-table measurement-other-table"
        columns={otherTableMinMaxColumns}
        dataSource={generatedData}
        pagination={false}
        tableElementId={otherTableId}
      />
      <BaseTable
        className="generated-file-measurement-table measurement-other-table-yes-no"
        columns={otherTableYesNoColumns}
        dataSource={yesNoFormatData}
        pagination={false}
        tableElementId={otherAppendixTableId}
      />
    </>
  );
};

BlankMeasurementDataSheetOtherTable.propTypes = {
  measurement: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetOtherTable;
