export const validateDocumentCategories = formatMessage => (
  rule,
  documents,
  callback
) => {
  try {
    if (Array.isArray(documents) && documents.length > 0) {
      const isInvalidDocumentsCategory = documents.some(
        document => document.category === undefined
      );

      if (isInvalidDocumentsCategory) {
        throw new Error(
          formatMessage({
            id: 'error.validateDocumentCategories'
          })
        );
      }
    }

    callback();
  } catch (error) {
    callback(error);
  }
};
