import React from 'react';
import { Icon } from 'antd';

export const ConfirmDeactiveDialog = ({ intl, record, isRelated }) => {
  let warningMessage = null;
  if (isRelated) {
    warningMessage = (
      <p className="content">
        <Icon type="warning" className="warning-icon" theme="filled" />
        <span>
          {intl.formatHTMLMessage(
            {
              id: 'detergentProducer.modal.deactivate.warningMessage',
              defaultMessage:
                '<b>“{name}”</b> is selected as producer for at least one part number. If you deactivate it, it will be removed from all part number'
            },
            { name: <strong>'{record.name}'</strong> }
          )}
        </span>
      </p>
    );
  }
  return (
    <span>
      {warningMessage}
      <p className="content">
        {!isRelated ? (
          <Icon type="warning" className="warning-icon" theme="filled" />
        ) : null}
        <span className="content-deactive">
          {intl.formatMessage({
            id: 'common.modal.deactivate.confirmMessage',
            defaultMessage: 'Are you sure you want to continue?'
          })}
        </span>
      </p>
    </span>
  );
};
