export const validatePartNumbers = (
  partNumbers,
  currentProductionCycleId,
  form,
  formatMessage
) => (rule, partNumberSelectedIds, callback) => {
  try {
    const selectedDetergentProducerIds = [];

    partNumbers.forEach(partNumber => {
      if (partNumberSelectedIds.includes(partNumber.id)) {
        selectedDetergentProducerIds.push(partNumber?.detergentProducer?.id);
      }
    });

    const isNotIdentical = selectedDetergentProducerIds.some(
      detergentProducerId =>
        detergentProducerId !== selectedDetergentProducerIds[0]
    );

    if (isNotIdentical) {
      // formatMessage
      throw new Error(
        formatMessage({ id: 'error.messageDetergentProducerIdentical' })
      );
    }

    callback();
  } catch (error) {
    callback(error);
  }
};
