import PropType from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { jsPDF } from 'jspdf';
import { changeDateFormatToDot } from '../../../../../../../utils/common';
import GeneratedFileTitle from '../Util/GeneratedFileTitle';
import ChemicalQualityControl from './ChemicalQualityControl/index';
import TechnicalQualityControl from './TechnicalQualityControl';
import TestResult from './TestResult';
import Header from './Util/Header';
import Style from './Util/style';
import {
  addDynamicTable,
  addElementToPdf,
  parsePixelToPoint,
  addElementOnlyAfterDynamicTable
} from '../../util/pdf';
import {
  chemicalQualityControlTestTableId,
  chemicalQualityControlTestTableColumnWidth
} from './constant';
import TestTable from './TestTable';
import './index.scss';
import { backgroundFillCell } from '../../util/constant';
import { halignCenterHeader } from '../../util/util';

const BaseProductTestProtocol = ({
  title,
  headerDivider,
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer
}) => {
  const testMetaDataContainerRef = useRef();
  const signatureContainerRef = useRef();

  useEffect(() => {
    // we handle the effect of the generation here, not in saga because it couples with the DOM
    async function render() {
      const { canvas } = await addElementToPdf(
        pdfRenderer,
        testMetaDataContainerRef.current
      );

      addDynamicTable(pdfRenderer, {
        id: chemicalQualityControlTestTableId,
        y: parsePixelToPoint(canvas.height),
        headStyles: backgroundFillCell,
        columnStyles: chemicalQualityControlTestTableColumnWidth,
        willDrawCell: data => halignCenterHeader(data)
      });

      await addElementOnlyAfterDynamicTable(
        pdfRenderer,
        signatureContainerRef.current
      );

      onPDFRenderedComplete();
    }

    if (isRenderPdfNow) {
      render();
    }
  }, [isRenderPdfNow]);
  return (
    <>
      <section
        className="generated-file--padding-top"
        ref={testMetaDataContainerRef}>
        <section className="production-header-height">
          <GeneratedFileTitle title={title}>
            <div className="production-instructions">
              <strong>
                <FormattedMessage
                  defaultMessage="Detergents and Care Products"
                  id="detergent.careProducts"
                />
              </strong>
            </div>
          </GeneratedFileTitle>
        </section>
        <p style={Style.divider}>{headerDivider}</p>
        <section className="production-section">
          <Header />
          <TechnicalQualityControl />
          <ChemicalQualityControl />
        </section>
      </section>

      <TestTable />

      <section
        className="generated-file--padding-top"
        ref={signatureContainerRef}>
        <TestResult />
        <p className="production-protocal-footer-line" style={Style.divider}>
          <div>
            <FormattedMessage
              id="detergent.vers"
              defaultMessage="Vers. from 15.09.2020 / TCD-D - Attachment KäN 053.036"
            />
          </div>
          <div>
            <FormattedMessage
              id="detergent.printDate"
              defaultMessage="Print Date"
            />
            : {changeDateFormatToDot()}
          </div>
        </p>
      </section>
    </>
  );
};

BaseProductTestProtocol.propTypes = {
  title: PropType.string.isRequired,
  headerDivider: PropType.string.isRequired,
  isRenderPdfNow: PropType.bool,
  onPDFRenderedComplete: PropType.func.isRequired,
  pdfRenderer: PropType.instanceOf(jsPDF)
};

BaseProductTestProtocol.defaultProps = {
  isRenderPdfNow: false,
  pdfRenderer: null
};

export default BaseProductTestProtocol;
