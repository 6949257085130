import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Form, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { OverlayLoading } from '../../../../../components/Loading';
import HistoryList from '../../List/HistoryList';
import {
  getDetergentFormulaById,
  processDetergentModal,
  setMeasurementDataForm,
  updateProductionCycles,
  deleteProductionCycle
} from '../../../data/detergent/actions';
import { ViewDetergent } from './ViewDetergent';
import ViewRawMaterialFormula from './ViewRawMaterialFormula';
import {
  FORM_STATUS,
  LIST_EDITING_DETERGENT_PERMISIONS,
  LIST_EDITING_PRODUCTION_CYCLES_PERMISIONS
} from '../../../../../configs/constant';
import Precalculation from '../../Precalculation';
import MeasurementData from '../../MeasurementData';
import ProductionCycles from './ProductionCyclesTab';
import GeneratingFileSelectorModal from './GeneratingFileSelectorModal';
import PartNumber from './PartNumberTab/partNumberList';
import { ProtectedComponent } from '../../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../../configs/rolesOfPermissions';
import EditProductionCyclesTab from '../EditProductionCyclesTab';
import { getPartNumberNonPaging } from '../../../../../data/partNumberNonPaging/action';
import { transformProductionCycleDataBeforeSaving } from '../../Util/productionCycle/transformProductionCycleDataBeforeSaving';
import { openConfirmModal } from '../../../../../utils/modals/openConfirmModal';
import ProductionCycleHistory from './ProductionCycleHistoryTab/List';

const _ViewModal = props => {
  const { form, onCancel } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [
    areEditingProductionCycles,
    setActiveEditableProductionCycles
  ] = useState(false);
  const [groupPartNumbersById, setGroupPartNumbersById] = useState({});
  const [selectedProductionCycle, setSelectedProductionCyle] = useState(null);

  const {
    detergentId,
    modalLoading,
    detergent,
    measurementData,
    isMeasurementFetching,
    partNumberNonPagingData
  } = useSelector(state => ({
    detergentId: state.detergent.emptyDetergent.id,
    modalLoading: state.detergent.modal.loading,
    detergent: JSON.parse(JSON.stringify(state.detergent.emptyDetergent)),
    measurementData: state.detergent.measurementData.data,
    isMeasurementFetching: state.detergent.measurementData.isFetching,
    partNumberNonPagingData: state.partNumberNonPaging.data
  }));

  const formulaId = detergent?.formulas?.[0]?.id;
  const formularStatus = detergent?.formulas?.[0]?.status;
  const [activeTab, setActiveTab] = useState('1');
  const [randomKey, setRandomKey] = useState(Date.now().toString());
  const canEditDetergent = LIST_EDITING_DETERGENT_PERMISIONS.includes(
    detergent?.formulas?.[0]?.status
  );
  const canEditProductionCycles = LIST_EDITING_PRODUCTION_CYCLES_PERMISIONS.includes(
    detergent?.formulas?.[0]?.status
  );
  const currentFormulaId = detergent.formulas?.[0]?.id;

  useEffect(() => {
    dispatch(
      getPartNumberNonPaging({
        formulaId: currentFormulaId
      })
    );
  }, [currentFormulaId]);

  useEffect(() => {
    const groupPartNumbers = {};
    partNumberNonPagingData.forEach(partNumberItem => {
      groupPartNumbers[partNumberItem.id] = partNumberItem;
    });

    setGroupPartNumbersById(groupPartNumbers);
  }, [partNumberNonPagingData]);

  useEffect(() => {
    dispatch(getDetergentFormulaById({ id: detergentId, formulaId }));

    // Active tab Production Cycle if the user comes from mail
    if (detergent._viewDetailFromMail) {
      setActiveTab('6');
    }
  }, []);

  useEffect(() => {
    dispatch(setMeasurementDataForm(measurementData));
  }, [isMeasurementFetching]);

  const changeTab = activeKey => {
    setActiveTab(activeKey);
    setRandomKey(Date.now().toString());
  };
  // const displayButton
  const openEditModal = () => {
    dispatch(
      processDetergentModal({
        detergent,
        statusForm: FORM_STATUS.UPDATE,
        title: (
          <FormattedMessage
            id="detergent.modalUpdateDetergentFormulaTitle"
            defaultMessage={`EDIT DETERGENT {detergentName} - FORMULA VERSION {formulaVersion}`}
            values={{
              detergentName: detergent.name,
              formulaVersion: detergent.formulas[0].version
            }}
          />
        ),
        isVisible: true
      })
    );
  };

  const onAddNewProductionCycle = useCallback(() => {
    dispatch(
      processDetergentModal({
        detergent,
        statusForm: FORM_STATUS.ADD_PRODUCTION_CYCLE,
        title: (
          <FormattedMessage
            id="detergent.modalAddNewProductionCycleTitle"
            defaultMessage="ADD NEW PRODUCTION CYCLE"
          />
        ),
        isVisible: true
      })
    );
  }, [dispatch]);

  const onEditProductionCyle = item => {
    setSelectedProductionCyle(item);
    setActiveEditableProductionCycles(true);
  };

  const onDeleteProductionCycle = useCallback(id => {
    const translateConfig = [
      {
        id: 'common.messageWarningDeleteProductionCycle',
        defaultMessage:
          'Are you sure you want to delete production cycle with ID is “{id}”?'
      },
      {
        id
      }
    ];
    const message = (
      <p className="content">
        <Icon type="warning" className="warning-icon" />
        <span>{formatMessage(...translateConfig)}</span>
      </p>
    );

    openConfirmModal({
      title: formatMessage({
        id: 'productionCycle.modalDeleteProductionCycleTitle',
        defaultMessage: 'DELETE PRODUCTION CYCLE'
      }),
      content: message,
      onOk: () => {
        dispatch(deleteProductionCycle({ id }));
      }
    });
  });

  const [
    isGeneratingFileSelectorVisible,
    setGeneratingFileSelectorVisible
  ] = useState(false);

  const onGeneratingFileSelectorModalOpen = () => {
    setGeneratingFileSelectorVisible(true);
  };

  const handleSubmitEditProductionCycle = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        update(values);
      }
    });
  };

  const displayButtonEditCycle = () => {
    if (areEditingProductionCycles) {
      return (
        <Button type="primary" htmlType="submit" className="btn-save">
          <FormattedMessage
            id="productionCycle.saveProductionCycle"
            defaultMessage="SAVE PRODUCTION CYCLES"
          />
        </Button>
      );
    }
    return (
      <Button
        className="btn-cancel"
        htmlType="button"
        onClick={event => {
          event.preventDefault();
          setActiveEditableProductionCycles(true);
        }}>
        <FormattedMessage
          id="productionCycle.editProductionCycle"
          defaultMessage="EDIT PRODUCTION CYCLES"
        />
      </Button>
    );
  };
  form.getFieldDecorator('_isFormInViewModal', { initialValue: true });

  const update = values => {
    const productionCycles = transformProductionCycleDataBeforeSaving(
      values,
      groupPartNumbersById,
      selectedProductionCycle
    );

    dispatch(
      updateProductionCycles({
        productionCycles,
        detergent,
        documents: values.documents
      })
    );
  };

  return (
    <>
      <OverlayLoading loading={modalLoading}>
        <Form
          className="detergent-detail"
          onSubmit={handleSubmitEditProductionCycle}>
          <ViewDetergent
            detergent={detergent}
            intl={intl}
            areEditingProductionCycles={areEditingProductionCycles}
            isComparingDetergents={false}
            form={form}
          />

          <Tabs
            className="tabs-detergent-history"
            onChange={changeTab}
            activeKey={activeTab}
            key={randomKey}>
            <Tabs.TabPane
              className="rawMaterial-detail"
              tab={
                <FormattedMessage
                  id="detergent.rawMaterialListTabTitle"
                  defaultMessage="Raw Material"
                />
              }
              key="1">
              <ViewRawMaterialFormula formulaId={formulaId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="history-details"
              tab={
                <FormattedMessage
                  id="rawMaterial.historyTabTitle"
                  defaultMessage="History"
                />
              }
              key="2">
              <div className="title">
                <FormattedMessage
                  id="detergent.allTheChangesThatHaveBeenMadeTo"
                  defaultMessage="All the changes that have been made to the detergent"
                />{' '}
              </div>

              <div className="history-list-detail">
                <HistoryList
                  detergentId={detergentId}
                  formulaId={currentFormulaId}
                />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              className="measurement-details"
              tab={
                <FormattedMessage
                  id="detergent.measurementDataTabTitle"
                  defaultMessage="Measurement Data"
                />
              }
              key="3">
              <MeasurementData />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="precalculation-details"
              tab={
                <FormattedMessage
                  id="detergent.precalculationTabTitle"
                  defaultMessage="Precalculation"
                />
              }
              key="4">
              <Precalculation currentData={detergent} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="partNumber-details"
              key="5"
              tab={
                <FormattedMessage
                  id="detergent.partNumberTabTitle"
                  defaultMessage="Part Number"
                />
              }>
              <PartNumber formulaId={formulaId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="production-cycle-list"
              tab={
                <FormattedMessage
                  id="detergent.productionCycleTabTitle"
                  defaultMessage="Production Cycles"
                />
              }
              key="6">
              {!areEditingProductionCycles ? (
                <ProductionCycles
                  formulaId={formulaId}
                  formularStatus={formularStatus}
                  onAddNewProductionCycle={onAddNewProductionCycle}
                  onEditProductionCyle={onEditProductionCyle}
                  onDeleteProductionCycle={onDeleteProductionCycle}
                />
              ) : (
                <EditProductionCyclesTab
                  form={form}
                  detergent={detergent}
                  selectedProductionCycle={selectedProductionCycle}
                  groupPartNumbersById={groupPartNumbersById}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              className="production-cycle-history-list"
              tab={
                <FormattedMessage
                  id="detergent.productionCycleHistoryTabTitle"
                  defaultMessage="Production Cycle History"
                />
              }
              key="7">
              <ProductionCycleHistory formulaId={formulaId} />
            </Tabs.TabPane>
          </Tabs>
          <div className="form-btn-actions">
            <Button
              className="btn-cancel icon-button"
              onClick={onGeneratingFileSelectorModalOpen}>
              <img
                src="/icon/ic-print.svg"
                alt=""
                className="generate-file-icon"
              />
              <FormattedMessage
                id="detergent.button.accessFileButton"
                defaultMessage="Access files"
              />
            </Button>

            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT}>
              {canEditDetergent ? (
                <Button className="btn-cancel" onClick={openEditModal}>
                  <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                </Button>
              ) : null}
            </ProtectedComponent>
            {canEditProductionCycles ? (
              <ProtectedComponent
                allowRoles={ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.EDIT}>
                {activeTab === '6' ? displayButtonEditCycle() : null}
              </ProtectedComponent>
            ) : null}

            <Button className="btn-save" onClick={onCancel}>
              <FormattedMessage id="common.close" defaultMessage="CLOSE" />
            </Button>
          </div>
        </Form>
      </OverlayLoading>
      <GeneratingFileSelectorModal
        visible={isGeneratingFileSelectorVisible}
        setVisible={setGeneratingFileSelectorVisible}
      />
    </>
  );
};

_ViewModal.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export const ViewModal = Form.create({
  name: 'form-edit-production-cycles',
  fuckingForm: 1
})(_ViewModal);
