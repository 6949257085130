import React from 'react';
import { Select, Icon, Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FilterableSelect from '../../../../../components/FilterableSelect/index';
import { ACTION_BUTTON_TYPES } from '../../../../../configs/constant';
import { getFormFieldKey } from '../../Util/productionCycle/getFormFieldKey';
import { validateProducers } from './util/validator';

export const renderEditPartNumberColumns = ({
  editPartNumbers,
  onEditPartNumber,
  onCancelEditPartNumber,
  form,
  intl
}) => {
  const { formatMessage } = intl;
  return [
    {
      title: (
        <FormattedMessage
          id="partNumbers.columnPartNumber"
          defaultMessage="Part number"
        />
      ),
      dataIndex: 'partNumber',
      key: 'partNumber'
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnProducer"
          defaultMessage="Producer"
        />
      ),
      dataIndex: 'detergentProducer.name',
      key: '`detergentProducer.name`',
      render: (text, record) => {
        const fieldKey = getFormFieldKey(record, 'detergentProducer');
        const isEditPartNumber = editPartNumbers.find(
          item => item.id === record.id
        );

        if (isEditPartNumber) {
          const producers = [];
          if (isEditPartNumber.detergentProducer) {
            producers.push({
              id: isEditPartNumber.detergentProducer.id,
              name: isEditPartNumber.detergentProducer.name
            });
          }

          if (isEditPartNumber.additionalDetergentProducer) {
            producers.push({
              id: isEditPartNumber.additionalDetergentProducer.id,
              name: isEditPartNumber.additionalDetergentProducer.name
            });
          }
          return (
            <Form.Item>
              {form.getFieldDecorator(fieldKey, {
                rules: [
                  {
                    validator: validateProducers(
                      editPartNumbers,
                      form,
                      formatMessage
                    )
                  }
                ]
              })(
                <FilterableSelect
                  suffixIcon={<Icon type="caret-down" />}
                  name={fieldKey}
                  placeholder={
                    <FormattedMessage
                      id="partNumber.form.selectProducer"
                      defaultMessage="Select producer"
                    />
                  }>
                  {producers.map(producer => (
                    <Select.Option value={producer.id} key={producer.id}>
                      {producer.name}
                    </Select.Option>
                  ))}
                </FilterableSelect>
              )}
            </Form.Item>
          );
        }

        return <span>{text}</span>;
      }
    },
    {
      key: 'action',
      width: '10%',
      render: (text, record) => {
        const isEditPartNumber = editPartNumbers.find(
          item => item.id === record.id
        );
        if (!isEditPartNumber) {
          return (
            <Button type="link" onClick={() => onEditPartNumber(record)}>
              <img
                className={`${ACTION_BUTTON_TYPES.EDIT_PARTNUMBER_ACTION} action-item`}
                alt=""
                src="/icon/ic-edit.svg"
              />
            </Button>
          );
        }
        return (
          <Button type="link" onClick={() => onCancelEditPartNumber(record)}>
            <img className="action-item" alt="" src="/icon/cancel-change.svg" />
          </Button>
        );
      }
    }
  ];
};
