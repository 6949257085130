import PropTypes from 'prop-types';
import React from 'react';
import { preventOnRowTrigger } from '../../../../components/AnnualTonnageDropdown/configContent';

const AnnualDemandDetail = ({ year, value }) => (
  <>
    <span className={`${preventOnRowTrigger} annual-year`}>{year}</span>
    <span className={`${preventOnRowTrigger} total-annual-tonnage-value`}>
      {value}
    </span>
  </>
);

AnnualDemandDetail.propTypes = {
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired
};

export default AnnualDemandDetail;