import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import AppRouter from './app-router/AppRouter';
import store from './app-state/store';
import userManager from './utils/auth/userManager';
import ErrorBoundary from './components/ErrorBoundary';

import messageEn from './Translations/en.json';
import messageDe from './Translations/de.json';

import 'moment/locale/de';

function App() {
  const messages = {
    de: messageDe,
    en: messageEn
  };

  const { languageCode } = useSelector(state => {
    return {
      languageCode: state.language.languageCode
    };
  });

  return (
    <IntlProvider locale={languageCode} messages={messages[languageCode]}>
      <ErrorBoundary>
        <OidcProvider store={store} userManager={userManager}>
          <>
            <Suspense fallback="Loading...">
              <AppRouter />
            </Suspense>
            <ToastContainer />
          </>
        </OidcProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
}

export default App;
