import React from 'react';
import * as _ from 'lodash';
import { Form, Select, Input, Icon, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

export const ContactList = props => {
  const {
    form: { getFieldDecorator },
    contactLists
  } = props;

  const getInitialFieldValue = (contactIndex, fieldName) => {
    return _.get(contactLists, `${contactIndex}.${fieldName}`, '');
  };

  return (
    <>
      <Row style={{ marginTop: '20px' }}>
        <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item>
            {getFieldDecorator(
              `producer.contact_list[${props.contactIndex}]['id']`,
              {
                initialValue:
                  getInitialFieldValue(props.contactIndex, 'id') || undefined
              }
            )(<Input type="hidden" />)}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="contactPerson.titleName"
                defaultMessage="Title"
              />
            }>
            <div id="parent-node">
              {getFieldDecorator(
                `producer.contact_list[${props.contactIndex}]['title']`,
                {
                  initialValue: getInitialFieldValue(
                    props.contactIndex,
                    'title'
                  )
                    ? getInitialFieldValue(props.contactIndex, 'title')
                    : undefined
                }
              )(
                <Select
                  placeholder={
                    <FormattedMessage
                      id="contactList.placeHolder"
                      defaultMessage="Select title"
                    />
                  }
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  suffixIcon={<Icon type="caret-down" />}>
                  <Select.Option value="Mr">
                    <FormattedMessage id="common.mr" defaultMessage="Mr." />
                  </Select.Option>
                  <Select.Option value="Mrs">
                    <FormattedMessage id="common.mrs" defaultMessage="Mrs." />
                  </Select.Option>
                  <Select.Option value="">
                    <FormattedMessage
                      id="common.blank"
                      defaultMessage="Blank"
                    />
                  </Select.Option>
                </Select>
              )}
            </div>
          </Form.Item>
        </Col>
        {props.contactIndex > 0 && (
          <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
            <div
              className="remove"
              onClick={() =>
                props.handleRemoveProducerContact(props.contactIndex)
              }>
              <p style={{ marginRight: '5px' }}>x</p>
              <u>
                <FormattedMessage
                  id="common.deleteButton"
                  defaultMessage="Delete"
                />
              </u>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item
            label={
              <FormattedMessage
                id="contactPerson.firstName"
                defaultMessage="First name"
              />
            }>
            {getFieldDecorator(
              `producer.contact_list[${props.contactIndex}]['first_name']`,
              {
                initialValue: getInitialFieldValue(
                  props.contactIndex,
                  'first_name'
                )
              }
            )(<Input />)}
          </Form.Item>
        </Col>
        <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item
            label={
              <FormattedMessage
                id="contactPerson.lastName"
                defaultMessage="Last name"
              />
            }>
            {getFieldDecorator(
              `producer.contact_list[${props.contactIndex}]['last_name']`,
              {
                initialValue: getInitialFieldValue(
                  props.contactIndex,
                  'last_name'
                )
              }
            )(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item
            label={
              <FormattedMessage
                id="contactPerson.email"
                defaultMessage="Email"
              />
            }>
            {getFieldDecorator(
              `producer.contact_list[${props.contactIndex}]['email']`,
              {
                initialValue: getInitialFieldValue(props.contactIndex, 'email')
              }
            )(<Input />)}
          </Form.Item>
        </Col>
        <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Form.Item
            label={
              <FormattedMessage
                id="contactPerson.phone"
                defaultMessage="Phone"
              />
            }>
            {getFieldDecorator(
              `producer.contact_list[${props.contactIndex}]['phone']`,
              {
                initialValue: getInitialFieldValue(props.contactIndex, 'phone')
              }
            )(<Input />)}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
