import React from 'react';
import PropType from 'prop-types';
import Label from './Label';
import Style from './style';

const labelSpanField = {
  ...Style.fillable,
  ...Style.inlineBlock,
  ...Style.halfWidth
};

const LabelField = ({
  label,
  fillableClass,
  className,
  labelMeasurementEquipment,
  flag,
  containerClass
}) => {
  const labelMeasurement = () => {
    if (flag) {
      return (
        <div style={{ display: 'inline-block' }}>
          {labelMeasurementEquipment}
        </div>
      );
    }
    return <span className={fillableClass} style={labelSpanField} />;
  };
  return (
    <section className={containerClass}>
      <Label className={className} label={label} />
      {labelMeasurement()}
    </section>
  );
};

LabelField.propTypes = {
  label: PropType.string.isRequired,
  fillableClass: PropType.string,
  className: PropType.string,
  flag: PropType.bool,
  labelMeasurementEquipment: PropType.string,
  containerClass: PropType.string
};

LabelField.defaultProps = {
  fillableClass: '',
  className: '',
  flag: false,
  labelMeasurementEquipment: '',
  containerClass: ''
};

export default LabelField;
