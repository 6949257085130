import React from 'react';
import { useSelector } from 'react-redux';
import BaseSheetOne from '../BlankMeasurementDataSheet/BaseSheetOne';
import { propTypes, defaultProps } from '../Util/detergentPropType';
import '../index.scss';

const FilledMeasurementDataSheet = ({
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer
}) => {
  const { measurement, detergent } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent,
    measurement: state.detergent.measurementData.data
  }));

  return (
    <BaseSheetOne
      className="rendered-filled-measurement-data generated-file--padding-top"
      measurement={measurement}
      pdfRenderer={pdfRenderer}
      isRenderPdfNow={isRenderPdfNow}
      onPDFRenderedComplete={onPDFRenderedComplete}
      formula={detergent?.formulas?.[0]}
    />
  );
};

FilledMeasurementDataSheet.propTypes = propTypes;
FilledMeasurementDataSheet.defaultProps = defaultProps;

export default FilledMeasurementDataSheet;
