import * as _ from 'lodash';
import React from 'react';
import { Upload, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { PreviewUploadFiles } from './PreviewUploadFiles';
import { uniqId } from '../../utils/common';
import { ACCEPT_FILE_TYPES_OF_DOCUMENT } from '../../configs/constant';

const _UploadDocuments = props => {
  const { fileList, onChange, type, disabled, expandSelection } = props;

  const uploadProps = {
    accept: ACCEPT_FILE_TYPES_OF_DOCUMENT,
    showUploadList: false,
    multiple: true,
    beforeUpload: (f, files) => {
      if (!_.isEmpty(files)) {
        const newFileList = [];
        const acceptFiles = ACCEPT_FILE_TYPES_OF_DOCUMENT.split(',');
        const unAcceptFiles = files.filter(
          file => !acceptFiles.includes(file.type)
        );

        if (unAcceptFiles.length > 0) {
          return false;
        }

        files.forEach(file => {
          newFileList.push({
            name: file.name,
            tempId: uniqId(),
            file,
            category: undefined
          });
        });

        onChange([...fileList, ...newFileList]);
      }

      return false;
    }
  };

  const onChangeDocumentProperty = (fileItem, property, value) => {
    if (!fileItem) return;
    const keyName = fileItem.tempId ? 'tempId' : 'id';
    const indexOfFileItem = _.findIndex(fileList, {
      [keyName]: fileItem[keyName]
    });

    if (indexOfFileItem > -1) {
      const newFileList = [...fileList];
      newFileList[indexOfFileItem] = {
        ...newFileList[indexOfFileItem],
        [property]: value
      };

      onChange(newFileList);
    }
  };

  const onDeleteFile = fileItem => {
    if (!fileItem) return;
    const keyName = fileItem.tempId ? 'tempId' : 'id';
    const indexOfFileItem = _.findIndex(fileList, {
      [keyName]: fileItem[keyName]
    });

    if (indexOfFileItem > -1) {
      const newFileList = [...fileList];
      newFileList.splice(indexOfFileItem, 1);

      onChange(newFileList);
    }
  };

  return (
    <>
      <Upload {...uploadProps}>
        <Button className="upload-file-button" disabled={disabled}>
          <Icon type="upload" />
          <FormattedMessage
            id="common.uploadFiles"
            defaultMessage="Select File"
          />
        </Button>
      </Upload>
      <PreviewUploadFiles
        documentType={type}
        fileList={fileList}
        onChangeDocumentProperty={onChangeDocumentProperty}
        onDeleteFile={onDeleteFile}
        expandSelection={fileItem =>
          expandSelection(fileItem, onChangeDocumentProperty)
        }
      />
    </>
  );
};

_UploadDocuments.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  expandSelection: PropTypes.func
};

_UploadDocuments.defaultProps = {
  fileList: [],
  onChange: () => {},
  disabled: false,
  expandSelection: () => {}
};

export const UploadDocuments = _UploadDocuments;
