import React from 'react';
import { Checkbox, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';

const FileSelectorCheckBox = ({
  onChange,
  formattedMessageId,
  defaultMessage,
  checked,
  disabled
}) => {
  return (
    <section className="document-files">
      <Checkbox onChange={onChange} checked={checked} disabled={disabled}>
        <Icon className="margin-right-5 attach-icon-align" type="paper-clip" />
        <FormattedMessage
          id={formattedMessageId}
          defaultMessage={defaultMessage}
        />
      </Checkbox>
    </section>
  );
};

FileSelectorCheckBox.propTypes = {
  onChange: PropType.func.isRequired,
  formattedMessageId: PropType.string.isRequired,
  defaultMessage: PropType.string.isRequired,
  checked: PropType.bool.isRequired,
  disabled: PropType.bool
};

FileSelectorCheckBox.defaultProps = {
  disabled: false
};
export default FileSelectorCheckBox;
