import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as _ from 'lodash';
import { Button } from 'antd';
import FilterForm from '../../components/Filter/index';
import Search from '../../components/Search/index';
import AddButton from '../../components/AddButton/index';
import { PartNumberModal } from './components/Modal/index';
import { PartNumberList } from './components/List';
import {
  FORM_STATUS,
  URL_PARAMS_ACTIONS,
  URL_QUERY_NAMES
} from '../../configs/constant';
import {
  processPartNumbersModal,
  getPartNumber,
  clearPartNumbersDataWhenUnmount,
  setSelectedDropdownFieldForPartNumber
} from './data/partNumbers/actions';
import { FilterFormPartNumber } from './components/Form/FilterFormPartNumber';
import { closest, getUrlParameterByName } from '../../utils/common';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';
import { getCountries } from '../../data/countries/action';

const PartNumbers = () => {
  const dispatch = useDispatch();
  const {
    pagination,
    modal,
    searchByText,
    order,
    countsRefeshList,
    filterList
  } = useSelector(state => ({
    modal: state.partNumbers.modal,
    pagination: state.partNumbers.list.data.pagination,
    filterList: state.partNumbers.filterList,

    searchByText: state.partNumbers.searchByText,
    countsRefeshList: state.partNumbers.modal.countsRefeshList,
    order: state.partNumbers.order
  }));
  const [paginationPage, setPaginationPage] = useState({ pagination });

  useEffect(() => {
    const newOrder = { ...order };
    const newPagination = { ...pagination };

    dispatch(
      getPartNumber({
        pagination: newPagination,
        searchByText,
        order: newOrder,
        filterList
      })
    );
  }, [countsRefeshList, paginationPage]);

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearPartNumbersDataWhenUnmount());
    };
  }, []);

  useEffect(() => {
    const pathParams = getUrlParameterByName(URL_QUERY_NAMES.PARAMS);

    if (!_.isEmpty(pathParams)) {
      const { data, action } = pathParams;

      switch (action) {
        case URL_PARAMS_ACTIONS.OPEN_PARTNUMBER_DETAIL_DIALOG:
          setTimeout(() => {
            onViewPartNumberDetail({
              id: data.id
            });
          });
          break;

        default:
          break;
      }
    }
  }, []);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    setPaginationPage(pagination);
  };

  const onPageChange = current => {
    pagination.current = ++current;
    setPaginationPage(pagination);
  };

  const popupAction = useCallback(
    action => {
      if (action === 'open') {
        dispatch(
          processPartNumbersModal({
            statusForm: FORM_STATUS.CREATE,
            title: (
              <FormattedMessage
                id="partNumbers.modalAddPartNumberTitle"
                defaultMessage="ADD NEW PART NUMBER"
              />
            ),
            isVisible: true
          })
        );
      } else if (action === 'migrateAnnualDemand') {
        dispatch(
          processPartNumbersModal({
            statusForm: FORM_STATUS.MIGRATE_ANNUAL_DEMAND,
            title: (
              <FormattedMessage
                id="partNumbers.modalMigrateAnnualDemand"
                defaultMessage="MIGRATE ANNUAL DEMAND"
              />
            ),
            isVisible: true
          })
        );
      } else {
        dispatch(
          processPartNumbersModal({
            isVisible: false
          })
        );
      }
    },
    [dispatch]
  );

  const onViewPartNumberDetail = useCallback(
    record => {
      dispatch(
        processPartNumbersModal({
          statusForm: FORM_STATUS.VIEW_DETAIL,
          title: (
            <FormattedMessage
              id="partNumbers.modalViewPartNumberDetailsTitle"
              defaultMessage="VIEW PART NUMBER DETAILS"
            />
          ),
          isVisible: true,
          modalData: {
            partNumberId: record.id
          }
        })
      );
    },
    [dispatch]
  );

  const onEditPartNumber = useCallback(
    record => {
      dispatch(
        processPartNumbersModal({
          statusForm: FORM_STATUS.UPDATE,
          title: (
            <FormattedMessage
              id="partNumbers.modalEditPartNumberTitle"
              defaultMessage="EDIT PART NUMBER"
            />
          ),
          isVisible: true,
          modalData: {
            partNumberId: record.id
          }
        })
      );
    },
    [dispatch]
  );

  // // event
  const handleClick = e => {
    if (
      closest(e.srcElement, '.part-number-status-wrapper') === null &&
      closest(e.srcElement, '.part-number-filter-form') === null
    ) {
      dispatch(
        setSelectedDropdownFieldForPartNumber({
          selectedPartNumberId: 0
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [dispatch]);

  return (
    <>
      <div className="detergent-database-content">
        <div className="main-title">
          <FormattedMessage
            id="header.componentProducts"
            defaultMessage="Products"
          />
        </div>
        <div className="partnumber-filter-area filter-area">
          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.CREATE}>
            <Button
              className="partnumber--migrate-button"
              onClick={() => popupAction('migrateAnnualDemand')}>
              <div className="text">
                <FormattedMessage
                  id="partNumbers.modalMigrateAnnualDemand"
                  defaultMessage="MIGRATE ANNUAL DEMAND"
                />
              </div>
            </Button>
          </ProtectedComponent>
          <FilterForm
            dropdownClassName="filter-partNumber"
            dropdownOverlayClassName="filter-content"
            FilterFormLayout={FilterFormPartNumber}
          />
          <Search
            getData={getPartNumber}
            pagination={pagination}
            order={order}
            filterList={filterList}
          />

          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.CREATE}>
            <AddButton onClick={() => popupAction('open')} />
          </ProtectedComponent>

          <PartNumberModal
            status={modal.statusForm}
            title={modal.title}
            isVisible={modal.isVisible}
            modalData={modal.modalData}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <PartNumberList
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onViewPartNumberDetail={onViewPartNumberDetail}
        onEditPartNumber={onEditPartNumber}
      />
    </>
  );
};

export default injectIntl(PartNumbers);
