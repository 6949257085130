import React from 'react';
import { Icon } from 'antd';

export const ConfirmDeactiveDialog = ({ intl, record, isRelated }) => {
  let warningMessage = null;
  if (isRelated) {
    warningMessage = (
      <p className="content">
        <Icon type="warning" className="warning-icon" theme="filled" />
        <span>
          {intl.formatHTMLMessage(
            {
              id: 'rawMaterialProducer.modal.deactivate.warningMessage',
              defaultMessage:
                '<b>“{name}”</b> is selected as supplier or producer for at least one raw material. If you deactivate it, it will be removed from all raw materials.'
            },
            {
              name: (
                <strong style={{ color: '#2b2b2b' }}>'{record.name}'</strong>
              )
            }
          )}
        </span>
      </p>
    );
  }

  return (
    <span>
      {warningMessage}
      <p>
        {!isRelated ? (
          <Icon type="warning" className="warning-icon" theme="filled" />
        ) : null}
        <span className="margin-left">
          {intl.formatHTMLMessage({
            id: 'common.modal.deactivate.confirmMessage',
            defaultMessage: 'Are you sure you want to continue?'
          })}
        </span>
      </p>
    </span>
  );
};
