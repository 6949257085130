import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../../components/BaseTable';
import { renderAnnualDemandColumns } from './renderAnnualDemandColumns';

export const AnnualDemandList = props => {
  const { results, density } = props;
  const [resultsSortYear, setResultsSortYear] = useState([]);
  const sortYear = (a, b) => {
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    if (results !== undefined) {
      const resultsYear = results.sort(sortYear);
      setResultsSortYear(resultsYear);
    }
  }, [results]);
  return (
    <BaseTable
      className="annual-demand"
      bordered
      columns={renderAnnualDemandColumns({ density })}
      dataSource={resultsSortYear}
    />
  );
};

AnnualDemandList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  density: PropTypes.number.isRequired
};
