import { MEASUREMENT_MAPPING_FIELD } from '../../../../../configs/constant';

const generate = measurement => {
  const roomTemperatureFourWeeksInfo = {
    ...measurement.roomTemperatureFourWeeks
  };
  const roomTemperatureEightWeeksInfo = {
    ...measurement.roomTemperatureEightWeeks
  };
  const roomTemperatureSixMonthsInfo = {
    ...measurement.roomTemperatureSixMonths
  };
  const roomTemperatureTwelveMonthsInfo = {
    ...measurement.roomTemperatureTwelveMonths
  };
  const roomTemperatureTwentyFourMonthsInfo = {
    ...measurement.roomTemperatureTwentyFourMonths
  };
  const fiftyCThreeDaysInfo = { ...measurement.fiftyCThreeDays };
  const thirtySevenCFourWeeksInfo = {
    ...measurement.thirtySevenCFourWeeks
  };
  const fourCFourWeeksInfo = { ...measurement.fourCFourWeeks };
  const negativeTwentyOneDayInfo = { ...measurement.negativeTwentyOneDay };

  return [
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.roomTemperatureFourWeeks.key,

      passFailed: roomTemperatureFourWeeksInfo.passFailed,
      comment: roomTemperatureFourWeeksInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.roomTemperatureEightWeeks.key,

      passFailed: roomTemperatureEightWeeksInfo.passFailed,
      comment: roomTemperatureEightWeeksInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.roomTemperatureSixMonths.key,

      passFailed: roomTemperatureSixMonthsInfo.passFailed,
      comment: roomTemperatureSixMonthsInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.roomTemperatureTwelveMonths.key,

      passFailed: roomTemperatureTwelveMonthsInfo.passFailed,
      comment: roomTemperatureTwelveMonthsInfo.comment
    },
    {
      cellKeyStorage:
        MEASUREMENT_MAPPING_FIELD.roomTemperatureTwentyFourMonths.key,

      passFailed: roomTemperatureTwentyFourMonthsInfo.passFailed,
      comment: roomTemperatureTwentyFourMonthsInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.fiftyCThreeDays.key,

      passFailed: fiftyCThreeDaysInfo.passFailed,
      comment: fiftyCThreeDaysInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.thirtySevenCFourWeeks.key,

      passFailed: thirtySevenCFourWeeksInfo.passFailed,
      comment: thirtySevenCFourWeeksInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.fourCFourWeeks.key,

      passFailed: fourCFourWeeksInfo.passFailed,
      comment: fourCFourWeeksInfo.comment
    },
    {
      cellKeyStorage: MEASUREMENT_MAPPING_FIELD.negativeTwentyOneDay.key,

      passFailed: negativeTwentyOneDayInfo.passFailed,
      comment: negativeTwentyOneDayInfo.comment
    }
  ];
};

export default generate;
