import { showErrorToast } from '../../services/toasterService';

const preLoginPathNameKey = 'PRE_LOGIN_PATH_NAME';
export const getPreLoginPathName = () => {
  return localStorage.getItem(preLoginPathNameKey);
};

export const clearPreLoginPathName = () => {
  return localStorage.removeItem(preLoginPathNameKey);
};

export const setPreLoginPathName = value => {
  return localStorage.setItem(preLoginPathNameKey, value);
};

export const loginErrorHandle = error => {
  let message = 'error.errorMessageDefault';
  const responseDataMessage =
    error?.response?.data?.data || error?.response?.data || error?.message;

  if (
    responseDataMessage === 'User+is+not+enabled' ||
    responseDataMessage === 'invalid_grant'
  ) {
    message = 'error.errorMessageUserIsNotEnabled';
  }

  showErrorToast(message);
};
