export const GET_DETERGENT = 'GET_DETERGENT';
export const GET_DETERGENT_SUCCESS = 'GET_DETERGENT_SUCCESS';
export const GET_DETERGENT_ERROR = 'GET_DETERGENT_ERROR';

export const CREATE_DETERGENT = 'CREATE_DETERGENT';
export const CREATE_DETERGENT_SUCCESS = 'CREATE_DETERGENT_SUCCESS';
export const CREATE_DETERGENT_ERROR = 'CREATE_DETERGENT_ERROR';

export const UPDATE_DETERGENT = 'UPDATE_DETERGENT';
export const UPDATE_DETERGENT_SUCCESS = 'UPDATE_DETERGENT_SUCCESS';
export const UPDATE_DETERGENT_ERROR = 'UPDATE_DETERGENT_ERROR';

export const GET_DETERGENT_BY_ID = 'GET_DETERGENT_BY_ID';
export const GET_DETERGENT_BY_ID_SUCCESS = 'GET_DETERGENT_BY_ID_SUCCESS';
export const GET_DETERGENT_BY_ID_ERROR = 'GET_DETERGENT_BY_ID_ERROR';

export const CREATE_FORMULA = 'CREATE_FORMULA';
export const CREATE_FORMULA_SUCCESS = 'CREATE_FORMULA_SUCCESS';
export const CREATE_FORMULA_ERROR = 'CREATE_FORMULA_ERROR';

export const DETERGENT_MODAL = 'DETERGENT_MODAL';
export const CLEAR_DETERGENT_DATA_WHEN_UNMOUNT =
  'CLEAR_DETERGENT_DATA_WHEN_UNMOUNT';

export const PATCH_FORUMLA = 'PATCH_FORUMLA';
export const PATCH_FORUMLA_SUCCESS = 'PATCH_FORUMLA_SUCCESS';
export const PATCH_FORUMLA_ERROR = 'PATCH_FORUMLA_ERROR';

export const GET_DETERGENT_HISTORY = 'GET_DETERGENT_HISTORY';
export const GET_DETERGENT_HISTORY_SUCCESS = 'GET_DETERGENT_HISTORY_SUCCESS';
export const GET_DETERGENT_HISTORY_ERROR = 'GET_DETERGENT_HISTORY_ERROR';

export const GET_MEASUREMENT = 'GET_MEASUREMENT';
export const GET_MEASUREMENT_SUCCESS = 'GET_MEASUREMENT_SUCCESS';
export const GET_MEASUREMENT_ERROR = 'GET_MEASUREMENT_ERROR';

export const SET_MEASUREMENT_DATA_FORM = 'SET_MEASUREMENT_DATA_FORM';
export const SET_MEASUREMENT_DATA_FIELD = 'SET_MEASUREMENT_DATA_FIELD';
export const SET_MEASUREMENT_DATA_NON_GROUP = 'SET_MEASUREMENT_DATA_NON_GROUP';

export const GET_RAW_MATERIAL_BY_FORMULA = 'GET_RAW_MATERIAL_BY_FORMULA';
export const GET_RAW_MATERIAL_BY_FORMULA_SUCCESS =
  'GET_RAW_MATERIAL_BY_FORMULA_SUCCESS';
export const GET_RAW_MATERIAL_BY_FORMULA_ERROR =
  'GET_RAW_MATERIAL_BY_FORMULA_ERROR';

export const GET_RAW_MATERIAL_BY_FORMULA_COMPARE =
  'GET_RAW_MATERIAL_BY_FORMULA_COMPARE';
export const GET_RAW_MATERIAL_BY_FORMULA_COMPARE_SUCCESS =
  'GET_RAW_MATERIAL_BY_FORMULA_COMPARE_SUCCESS';
export const GET_RAW_MATERIAL_BY_FORMULA_COMPARE_ERROR =
  'GET_RAW_MATERIAL_BY_FORMULA_COMPARE_ERROR';

export const UPDATE_DISPLAY_FIELDS = 'UPDATE_DISPLAY_FIELDS';

export const SET_PRECALCULATION_DATA = 'SET_PRECALCULATION_DATA';
export const SET_PRECALCULATION_FIELD = 'SET_PRECALCULATION_FIELD';

export const SET_CONTAINER_SIZE = 'SET_CONTAINER_SIZE';

export const SET_MESSAGE_TOTAL = 'SET_MESSAGE_TOTAL';
export const SET_SELECT_ALL = 'SET_SELECT_ALL';

export const SET_DISABLE_DROPDOWN_ALL = 'SET_DISABLE_DROPDOWN_ALL';

export const SET_SELECTED_DROPDOWN_FIELD_FOR_DETERGENT =
  'SET_SELECTED_DROPDOWN_FIELD_FOR_DETERGENT';

export const UPDATE_DETERGENT_FORMULA = 'UPDATE_DETERGENT_FORMULA';
export const UPDATE_DETERGENT_FORMULA_SUCCESS =
  'UPDATE_DETERGENT_FORMULA_SUCCESS';
export const UPDATE_DETERGENT_FORMULA_ERROR = 'UPDATE_DETERGENT_FORMULA_ERROR';

export const GET_DETERGENT_PRODUCTION_CYCLE_LIST =
  'GET_DETERGENT_PRODUCTION_CYCLE_LIST';
export const GET_DETERGENT_PRODUCTION_CYCLE_LIST_SUCCESS =
  'GET_DETERGENT_PRODUCTION_CYCLE_LIST_SUCCESS';
export const GET_DETERGENT_PRODUCTION_CYCLE_LIST_ERROR =
  'GET_DETERGENT_PRODUCTION_CYCLE_LIST_ERROR';

export const CREATE_PRODUCTION_CYCLE = 'CREATE_PRODUCTION_CYCLE';
export const CREATE_PRODUCTION_CYCLE_SUCCESS =
  'CREATE_PRODUCTION_CYCLE_SUCCESS';
export const CREATE_PRODUCTION_CYCLE_ERROR = 'CREATE_PRODUCTION_CYCLE_ERROR';

export const CREATE_DUMMY_PART_NUMBER = 'CREATE_DUMMY_PART_NUMBER';
export const CREATE_DUMMY_PART_NUMBER_ERROR = 'CREATE_DUMMY_PART_NUMBER_ERROR';
export const DOWNLOAD_PRECALCULATION_SUCCESS =
  'DOWNLOAD_PRECALCULATION_SUCCESS';
export const DOWNLOAD_PRECALCULATION = 'DOWNLOAD_PRECALCULATION';
export const DOWNLOAD_PRECALCULATION_ERROR = 'DOWNLOAD_PRECALCULATION_ERROR';
export const CLEAR_DOWNLOAD_PRECALCULATION_ERROR =
  'CLEAR_DOWNLOAD_PRECALCULATION_ERROR';

export const GET_PART_NUMBER_LIST_BY_FORMULA =
  'GET_PART_NUMBER_LIST_BY_FORMULA';
export const GET_PART_NUMBER_LIST_BY_FORMULA_SUCCESS =
  'GET_PART_NUMBER_LIST_BY_FORMULA_SUCCESS';
export const GET_PART_NUMBER_LIST_BY_FORMULA_ERROR =
  'GET_PART_NUMBER_LIST_BY_FORMULA_ERROR';

export const SEND_EMAIL_TO_SAFETY_TEAM = 'SEND_EMAIL_TO_SAFETY_TEAM';
export const SEND_EMAIL_TO_SAFETY_TEAM_SUCCESS =
  'SEND_EMAIL_TO_SAFETY_TEAM_SUCCESS';
export const SEND_EMAIL_TO_SAFETY_TEAM_ERROR =
  'SEND_EMAIL_TO_SAFETY_TEAM_ERROR';

export const SEND_EMAIL_TO_LABELING_TEAM = 'SEND_EMAIL_TO_LABELING_TEAM';
export const SEND_EMAIL_TO_LABELING_TEAM_SUCCESS =
  'SEND_EMAIL_TO_LABELING_TEAM_SUCCESS';
export const SEND_EMAIL_TO_LABELING_TEAM_ERROR =
  'SEND_EMAIL_TO_LABELING_TEAM_ERROR';

export const UPDATE_PRODUCTION_CYCLES = 'UPDATE_PRODUCTION_CYCLES';
export const UPDATE_PRODUCTION_CYCLES_SUCCESS =
  'UPDATE_PRODUCTION_CYCLES_SUCCESS';
export const UPDATE_PRODUCTION_CYCLES_ERROR = 'UPDATE_PRODUCTION_CYCLES_ERROR';
export const SET_LOADING_DETERGENT = 'SET_LOADING_DETERGENT';
export const CHECK_RAW_MATERIAL_UNBLOCK = 'CHECK_RAW_MATERIAL_UNBLOCK';
export const CHECK_RAW_MATERIAL_UNBLOCK_SUCCESS =
  'CHECK_RAW_MATERIAL_UNBLOCK_SUCCESS';
export const CHECK_RAW_MATERIAL_UNBLOCK_ERROR =
  'CHECK_RAW_MATERIAL_UNBLOCK_ERROR';
export const SET_NAME_PRECALCULATION_DATA = 'SET_NAME_PRECALCULATION_DATA';

export const DELETE_PRODUCTION_CYCLE = 'DELETE_PRODUCTION_CYCLE';
export const DELETE_PRODUCTION_CYCLE_SUCCESS =
  'DELETE_PRODUCTION_CYCLE_SUCCESS';
export const DELETE_PRODUCTION_CYCLE_ERROR = 'DELETE_PRODUCTION_CYCLE_ERROR';

export const GET_PRODUCTION_CYCLE_HISTORY_LIST =
  'GET_PRODUCTION_CYCLE_HISTORY_LIST';
export const GET_PRODUCTION_CYCLE_HISTORY_LIST_SUCCESS =
  'GET_PRODUCTION_CYCLE_HISTORY_LIST_SUCCESS';
export const GET_PRODUCTION_CYCLE_HISTORY_LIST_ERROR =
  'GET_PRODUCTION_CYCLE_HISTORY_LIST_ERROR';

export const getDetergent = payload => ({
  type: GET_DETERGENT,
  payload
});

export const getDetergentSuccess = payload => ({
  type: GET_DETERGENT_SUCCESS,
  payload
});

export const getDetergentError = payload => ({
  type: GET_DETERGENT_ERROR,
  payload
});

export const processDetergentModal = payload => ({
  type: DETERGENT_MODAL,
  payload
});

export const createDetergent = payload => ({
  type: CREATE_DETERGENT,
  payload
});

export const createDetergentSuccess = payload => ({
  type: CREATE_DETERGENT_SUCCESS,
  payload
});

export const createDetergentError = payload => ({
  type: CREATE_DETERGENT_ERROR,
  payload
});

// Update
export const updateDetergent = payload => ({
  type: UPDATE_DETERGENT,
  payload
});

export const updateDetergentSuccess = payload => ({
  type: UPDATE_DETERGENT_SUCCESS,
  payload
});

export const updateDetergentError = payload => ({
  type: UPDATE_DETERGENT_ERROR,
  payload
});

export const createFormula = payload => ({
  type: CREATE_FORMULA,
  payload
});

export const createFormulaSuccess = payload => ({
  type: CREATE_FORMULA_SUCCESS,
  payload
});

export const createFormulaError = payload => ({
  type: CREATE_FORMULA_ERROR,
  payload
});

export const clearDetergentDataWhenUnmount = payload => ({
  type: CLEAR_DETERGENT_DATA_WHEN_UNMOUNT,
  payload
});

// get detergent by id
export const getDetergentFormulaById = payload => ({
  type: GET_DETERGENT_BY_ID,
  payload
});

export const getDetergentFormulaByIdSuccess = payload => ({
  type: GET_DETERGENT_BY_ID_SUCCESS,
  payload
});

export const getDetergentFormulaByIdError = payload => ({
  type: GET_DETERGENT_BY_ID_ERROR,
  payload
});
export const patchFormula = payload => ({
  type: PATCH_FORUMLA,
  payload
});

export const patchFormulaSuccess = payload => ({
  type: PATCH_FORUMLA_SUCCESS,
  payload
});

export const patchFormulaError = payload => ({
  type: PATCH_FORUMLA_ERROR,
  payload
});

// get detergent history
export const getDetergentHistory = payload => ({
  type: GET_DETERGENT_HISTORY,
  payload
});

export const getDetergentHistorySuccess = payload => ({
  type: GET_DETERGENT_HISTORY_SUCCESS,
  payload
});

export const getDetergentHistoryError = payload => ({
  type: GET_DETERGENT_HISTORY_ERROR,
  payload
});

export const getMeasurement = payload => ({
  type: GET_MEASUREMENT,
  payload
});

export const getMeasurementSuccess = payload => ({
  type: GET_MEASUREMENT_SUCCESS,
  payload
});

export const getMeasurementError = payload => ({
  type: GET_MEASUREMENT_ERROR,
  payload
});

export const setMeasurementDataForm = payload => ({
  type: SET_MEASUREMENT_DATA_FORM,
  payload
});

export const setMeasurementDataField = payload => ({
  type: SET_MEASUREMENT_DATA_FIELD,
  payload
});

export const setMeasurementDataNonGroup = payload => ({
  type: SET_MEASUREMENT_DATA_NON_GROUP,
  payload
});

export const getRawMaterialByFormula = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA,
  payload
});

export const getRawMaterialByFormulaSuccess = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA_SUCCESS,
  payload
});

export const getRawMaterialByFormulaError = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA_ERROR,
  payload
});

// get raw material from 2 formulas to compare
export const getRawMaterialByFormulasCompare = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA_COMPARE,
  payload
});

export const getRawMaterialByFormulasCompareSuccess = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA_COMPARE_SUCCESS,
  payload
});

export const getRawMaterialByFormulasCompareError = payload => ({
  type: GET_RAW_MATERIAL_BY_FORMULA_COMPARE_ERROR,
  payload
});

export const updateDisplay = payload => ({
  type: UPDATE_DISPLAY_FIELDS,
  payload
});

export const setPrecalculationData = payload => ({
  type: SET_PRECALCULATION_DATA,
  payload
});

export const setPrecalculationField = payload => ({
  type: SET_PRECALCULATION_FIELD,
  payload
});

export const setContainerSize = payload => ({
  type: SET_CONTAINER_SIZE,
  payload
});

export const setMessageTotal = payload => ({
  type: SET_MESSAGE_TOTAL,
  payload
});

export const setSelectAll = payload => ({
  type: SET_SELECT_ALL,
  payload
});

export const setDisableDropdownAll = payload => ({
  type: SET_DISABLE_DROPDOWN_ALL,
  payload
});

export const setSelectedDropdownFieldForDetergent = payload => ({
  type: SET_SELECTED_DROPDOWN_FIELD_FOR_DETERGENT,
  payload
});

export const updateDetergentFormula = payload => ({
  type: UPDATE_DETERGENT_FORMULA,
  payload
});

export const updateDetergentFormulaSuccess = () => ({
  type: UPDATE_DETERGENT_FORMULA_SUCCESS
});

export const updateDetergentFormulaError = () => ({
  type: UPDATE_DETERGENT_FORMULA_ERROR
});

export const getDetergentProductionCycleList = payload => ({
  type: GET_DETERGENT_PRODUCTION_CYCLE_LIST,
  payload
});

export const getDetergentProductionCycleListSuccess = payload => ({
  type: GET_DETERGENT_PRODUCTION_CYCLE_LIST_SUCCESS,
  payload
});

export const getDetergentProductionCycleListError = payload => ({
  type: GET_DETERGENT_PRODUCTION_CYCLE_LIST_ERROR,
  payload
});

// Create production cycle
export const createProductionCycle = payload => ({
  type: CREATE_PRODUCTION_CYCLE,
  payload
});

export const createProductionCycleSuccess = () => ({
  type: CREATE_PRODUCTION_CYCLE_SUCCESS
});

export const createProductionCycleError = () => ({
  type: CREATE_PRODUCTION_CYCLE_ERROR
});

// Create dummy part number
export const createDummyPartNumber = payload => ({
  type: CREATE_DUMMY_PART_NUMBER,
  payload
});

export const createDummyPartNumberError = () => ({
  type: CREATE_DUMMY_PART_NUMBER_ERROR
});

export const downloadPrecalculation = (formulaId, languageCode) => ({
  type: DOWNLOAD_PRECALCULATION,
  payload: { formulaId, languageCode }
});

export const downloadPrecalculationSucess = blob => ({
  type: DOWNLOAD_PRECALCULATION_SUCCESS,
  payload: blob
});

export const downloadPrecalculationError = error => ({
  type: DOWNLOAD_PRECALCULATION_ERROR,
  payload: {
    error
  }
});

export const clearDownloadPrecalculationError = () => ({
  type: CLEAR_DOWNLOAD_PRECALCULATION_ERROR
});

export const getPartNumberListByFormula = payload => ({
  type: GET_PART_NUMBER_LIST_BY_FORMULA,
  payload
});

export const getPartNumberListByFormulaSuccess = payload => ({
  type: GET_PART_NUMBER_LIST_BY_FORMULA_SUCCESS,
  payload
});

export const getPartNumberListByFormulaError = payload => ({
  type: GET_PART_NUMBER_LIST_BY_FORMULA_ERROR,
  payload
});

export const sendEmailToSafetyTeam = payload => ({
  type: SEND_EMAIL_TO_SAFETY_TEAM,
  payload
});

export const getProductionCycleHistoryByFormula = payload => ({
  type: GET_PRODUCTION_CYCLE_HISTORY_LIST,
  payload
});

export const getProductionCycleHistoryByFormulaSuccess = payload => ({
  type: GET_PRODUCTION_CYCLE_HISTORY_LIST_SUCCESS,
  payload
});

export const getProductionCycleHistoryByFormulaError = payload => ({
  type: GET_PRODUCTION_CYCLE_HISTORY_LIST_ERROR,
  payload
});

export const sendEmailToSafetyTeamSuccess = payload => ({
  type: SEND_EMAIL_TO_SAFETY_TEAM_SUCCESS,
  payload
});

export const sendEmailToSafetyTeamError = payload => ({
  type: SEND_EMAIL_TO_SAFETY_TEAM_ERROR,
  payload
});

export const sendEmailToLabelingTeam = payload => ({
  type: SEND_EMAIL_TO_LABELING_TEAM,
  payload
});

export const sendEmailToLabelingTeamSuccess = payload => ({
  type: SEND_EMAIL_TO_LABELING_TEAM_SUCCESS,
  payload
});

export const sendEmailToLabelingTeamError = payload => ({
  type: SEND_EMAIL_TO_LABELING_TEAM_ERROR,
  payload
});

export const updateProductionCycles = payload => ({
  type: UPDATE_PRODUCTION_CYCLES,
  payload
});

export const updateProductionCyclesSuccess = payload => ({
  type: UPDATE_PRODUCTION_CYCLES_SUCCESS,
  payload
});

export const updateProductionCyclesError = payload => ({
  type: UPDATE_PRODUCTION_CYCLES_ERROR,
  payload
});

export const setLoadingDetergent = payload => ({
  type: SET_LOADING_DETERGENT,
  payload
});

// Check raw material block, unblock

export const checkRawMaterialBlock = payload => ({
  type: CHECK_RAW_MATERIAL_UNBLOCK,
  payload
});

export const checkRawMaterialBlockSuccess = payload => ({
  type: CHECK_RAW_MATERIAL_UNBLOCK_SUCCESS,
  payload
});

export const checkRawMaterialBlockError = payload => ({
  type: CHECK_RAW_MATERIAL_UNBLOCK_ERROR,
  payload
});

export const setNamePrecalculationData = payload => ({
  type: SET_NAME_PRECALCULATION_DATA,
  payload
});

export const deleteProductionCycle = payload => ({
  type: DELETE_PRODUCTION_CYCLE,
  payload
});

export const deleteProductionCycleSuccess = payload => ({
  type: DELETE_PRODUCTION_CYCLE_SUCCESS,
  payload
});

export const deleteProductionCycleError = payload => ({
  type: DELETE_PRODUCTION_CYCLE_ERROR,
  payload
});
