import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import { getRawMaterialHistory } from '../../data/rawMaterial/action';
import { renderRawMaterialHistoryColumns } from './renderRawMaterialHistoryColumns';

export const HistoryList = ({ rawMaterialId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { history, isFetchingHistory } = useSelector(state => ({
    history: state.rawMaterials.history.data,
    isFetchingHistory: state.rawMaterials.history.isFetchingHistory
  }));

  function dispatchGetHistoryList(pagination = history.pagination) {
    dispatch(
      getRawMaterialHistory({
        rawMaterialId,
        pagination
      })
    );
  }

  useEffect(() => {
    dispatchGetHistoryList();
  }, [rawMaterialId]);

  function onPageSizeChange(value) {
    const pagination = {
      ...history.pagination,
      current: 1,
      pageSize: value
    };

    dispatchGetHistoryList(pagination);
  }

  function onPageChange(current) {
    const pagination = {
      ...history.pagination,
      current: current + 1
    };

    dispatchGetHistoryList(pagination);
  }

  return (
    <div className="detergent-history-table">
      <BaseTable
        className="raw-material-detail-list-table"
        pagination={history.pagination}
        bordered
        loading={isFetchingHistory && { indicator: <Icon type="sync" spin /> }}
        columns={renderRawMaterialHistoryColumns(intl)}
        dataSource={history.results}
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
      />
    </div>
  );
};

HistoryList.propTypes = {
  rawMaterialId: PropTypes.number.isRequired
};
