import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AnnualTonnageDropDown from './AnnualTonnageDropDown';
import {
  changePointToComma,
  getTotalAnnualTonnageByYear
} from '../../utils/common';

const TotalAnnualTonnage = props => {
  const { record, valueOfCurrentYear } = props;

  const rawMaterialRatios = record?.rawMaterialRatios;

  const title = (
    <FormattedMessage
      id="rawMaterial.annualTonnage.title"
      defaultMessage="Total annual tonnage of the past years :"
    />
  );

  const value = year =>
    changePointToComma(getTotalAnnualTonnageByYear(year, rawMaterialRatios));

  return (
    <AnnualTonnageDropDown
      dropdownTitle={title}
      value={value}
      valueOfCurrentYear={valueOfCurrentYear}
      record={record}
    />
  );
};

TotalAnnualTonnage.propTypes = {
  record: PropTypes.shape({}).isRequired,
  valueOfCurrentYear: PropTypes.number.isRequired
};

export default TotalAnnualTonnage;
