import PropType from 'prop-types';

const propTypes = {
  detergent: PropType.shape({
    name: PropType.string,
    number: PropType.string,
    formulas: PropType.array
  })
};

const defaultProps = {
  detergent: {
    formulas: [{}]
  }
};
export { propTypes, defaultProps };
