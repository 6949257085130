import { put, takeLatest } from 'redux-saga/effects';
import { GET_LOGS, getLogsSuccess, getLogsError } from './actions';
import { getLogsApi } from './api';
import { errorHandle } from '../../../../utils/common';

export function* getLogsSaga(action) {
  try {
    const response = yield getLogsApi(action.payload.pagination);
    yield put(getLogsSuccess(response.data));
  } catch (error) {
    yield put(getLogsError(error));
    errorHandle(error);
  }
}

export default function* logsSaga() {
  yield takeLatest(GET_LOGS, getLogsSaga);
}
