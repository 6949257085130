import React from 'react';
import { Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

const AddButton = props => {
  const { onClick } = props;
  return (
    <Button className="add-button" onClick={onClick}>
      <Icon className="add-icon" type="plus" />
      <div className="text">
        {' '}
        <FormattedMessage
          id="partNumber.add"
          defaultMessage="ADD PART NUMBER"
        />
      </div>
    </Button>
  );
};
export default AddButton;
