import React from 'react';
import { FormattedMessage } from 'react-intl';
import MetaData from './MetaTable';

const ChemicalQualityControl = () => {
  return (
    <section>
      <h6 style={{ fontWeight: 'bold' }}>
        <FormattedMessage
          id="detergent.chemical.quality"
          defaultMessage="Chemical quality control"
        />
      </h6>
      <MetaData />
    </section>
  );
};

export default ChemicalQualityControl;
