import React from 'react';
import { FormattedMessage } from 'react-intl';
import LabelField from './Util/LabelField';
import Style from './Util/style';

const TechnicalQualityControl = () => {
  return (
    <section>
      <h6 style={{ fontWeight: 'bold' }}>
        <FormattedMessage
          defaultMessage="Technical quality control"
          id="detergent.technical.qualityControl"
        />
      </h6>
      <section>
        <LabelField
          label={
            <FormattedMessage
              id="detergent.typePackaging"
              defaultMessage="Type packaging:"
            />
          }
        />
        <LabelField
          label={
            <FormattedMessage
              id="detergent.containerAmount"
              defaultMessage="Container amount per package:"
            />
          }
        />
        <LabelField
          label={
            <FormattedMessage
              id="detergent.batchSite"
              defaultMessage="Batch site:"
            />
          }
        />
      </section>
      <section style={Style.container}>
        <table className="generated-file-bordered-table">
          <tr>
            <th> </th>
            <th>
              <FormattedMessage id="detergent.passed" defaultMessage="Passed" />
            </th>
            <th>
              <FormattedMessage
                id="detergent.notPassed"
                defaultMessage="Not passed"
              />
            </th>
          </tr>
          <tr>
            <td>
              <FormattedMessage
                id="detergent.impermeability"
                defaultMessage="Impermeability of container"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>
              <FormattedMessage
                id="detergent.palletizing"
                defaultMessage="Palletizing"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>
              <FormattedMessage
                id="detergent.labeling"
                defaultMessage="labeling"
              />
            </td>
            <td />
            <td />
          </tr>
        </table>

        <section className="technical-quality-control-part-number container-label-right">
          <LabelField
            label={
              <FormattedMessage
                id="detergent.partNumber"
                defaultMessage="Part number:"
              />
            }
          />
          <LabelField
            label={
              <FormattedMessage
                id="detergent.codeNumber"
                defaultMessage="Code number:"
              />
            }
          />
          <LabelField
            label={
              <FormattedMessage
                id="detergent.containerType"
                defaultMessage="Container type:"
              />
            }
          />
          <LabelField
            label={
              <FormattedMessage
                id="detergent.containerSize"
                defaultMessage="Container size:"
              />
            }
          />
          <LabelField
            label={
              <FormattedMessage
                id="detergent.containerPartNumber"
                defaultMessage="Container part number:"
              />
            }
          />
          <LabelField
            flag="true"
            labelMeasurementEquipment={
              <FormattedMessage
                id="detergent.measureEquipment"
                defaultMessage="Measurement equipment calibrated"
              />
            }
          />
        </section>
      </section>
      <section className="container-label-right">
        <LabelField
          fillableClass="technical-quality-control-footer-label"
          label={
            <FormattedMessage
              id="detergent.accordingToLabeling"
              defaultMessage="According to labeling instructions from:"
            />
          }
        />
        <LabelField
          fillableClass="technical-quality-control-footer-label"
          label={
            <FormattedMessage
              id="detergent.accordingInformation"
              defaultMessage="According information:"
            />
          }
        />
      </section>
    </section>
  );
};

export default TechnicalQualityControl;
