import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14">
      <path
        fill="#646464"
        fillRule="nonzero"
        d="M6.99 7.42l4.974-6.202c.083-.103.013-.26-.116-.26h-1.512a.303.303 0 0 0-.233.113L6 6.187 1.897 1.071a.298.298 0 0 0-.233-.113H.152c-.13 0-.2.157-.116.26L5.011 7.42.036 13.622a.163.163 0 0 0-.022.168.15.15 0 0 0 .138.091h1.512c.09 0 .174-.041.233-.113L6 8.653l4.103 5.115a.298.298 0 0 0 .233.113h1.512c.13 0 .2-.156.116-.26L6.989 7.42z"
      />
    </svg>
  );
};
