import React from 'react';
import { FormattedMessage } from 'react-intl';
import store from '../../../../../../../app-state/store';
import { MEASUREMENT_MAPPING_FIELD } from '../../../../../../../configs/constant';
import {
  capitalizeFirstLetter,
  changePointToCommaExport,
  changePointToCommaWithDecimals
} from '../../../../../../../utils/common';

export default (
  MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
  formatMessage,
  keyIdx,
  titleKeyId
) => {
  return [
    {
      title: titleKeyId,
      dataIndex: keyIdx,
      render: (value, data) => {
        // TODO: add default message
        if (keyIdx !== 'cellKeyPh') {
          const keyId = MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID[value];
          return formatMessage({ id: keyId });
        }
        const keyId = MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID[value];
        if (data.cellKeyPh === MEASUREMENT_MAPPING_FIELD.concentrate.key) {
          return <span>{formatMessage({ id: keyId })}</span>;
        }
        return (
          <span id="in-tab-water-node">
            <span>
              {`${store.getState().detergent.measurementDataForm.tabWaterLabel *
                100}% `}
            </span>
            <span>{formatMessage({ id: keyId })}</span>
          </span>
        );
      },
      width: 220
    },
    {
      title: (
        <div className="text-center">
          <FormattedMessage
            id="measurement.measured.title"
            defaultMessage="Measured value"
          />
        </div>
      ),
      dataIndex: 'measured',
      render: (value, data) => {
        if (
          data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.refrectionIndex.key
        ) {
          return changePointToCommaWithDecimals(value, 4);
        }
        if (
          data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.densityDIN51757.key
        ) {
          return changePointToCommaWithDecimals(value, 3);
        }
        return changePointToCommaExport(value);
      },
      width: 120
    },
    {
      title: (
        <FormattedMessage
          id="detergent.tolerance"
          defaultMessage="Tolerance values"
        />
      ),
      width: 160,
      children: [
        {
          title: (
            <div className="text-center">
              <FormattedMessage id="detergent.min" defaultMessage="min" />
            </div>
          ),
          dataIndex: 'min',
          render: (value, data) => {
            if (
              data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.refrectionIndex.key
            ) {
              return changePointToCommaWithDecimals(value, 4);
            }
            if (
              data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.densityDIN51757.key
            ) {
              return changePointToCommaWithDecimals(value, 3);
            }
            return changePointToCommaExport(value);
          },
          width: 80
        },
        {
          title: (
            <div className="text-center">
              <FormattedMessage id="detergent.max" defaultMessage="max" />
            </div>
          ),
          dataIndex: 'max',
          render: (value, data) => {
            if (
              data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.refrectionIndex.key
            ) {
              return changePointToCommaWithDecimals(value, 4);
            }
            if (
              data.cellKeyTest === MEASUREMENT_MAPPING_FIELD.densityDIN51757.key
            ) {
              return changePointToCommaWithDecimals(value, 3);
            }
            return changePointToCommaExport(value);
          },
          width: 80
        }
      ]
    },
    {
      title: (
        <div className="text-center">
          <FormattedMessage id="comment.title" defaultMessage="Comment" />
        </div>
      ),
      width: 220,
      dataIndex: 'comment',
      render: value => capitalizeFirstLetter(value)
    }
  ];
};
