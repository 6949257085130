import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Input, Icon, Row, Col, Button, InputNumber } from 'antd';
import * as _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createDetergentProducers,
  updateDetergentProducers
} from '../../data/detergentProducers/actions';
import { OverlayLoading } from '../../../../components/Loading';
import {
  transformFields,
  trimStringFieldsInObject,
  getFromCountry
} from '../../../../utils/common';
import { BLANK_OPTION, FORM_STATUS } from '../../../../configs/constant';
import { ContactList } from './ContactList';
import { validatorErpNumber } from '../../../../utils/validatorErpNumber';
import FilterableSelect from '../../../../components/FilterableSelect';
import country from '../../../../configs/country.json';
import countryUSA from '../../../../configs/countryUSA.json';
import { getCognitoInfo } from '../../../../utils/auth/cognito';
// eslint-disable-next-line no-underscore-dangle
const _ProducerContactForm = props => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const { modalLoading, detergentProducer, countries } = useSelector(state => ({
    modalLoading: state.detergentProducers.modal.loading,
    detergentProducer: JSON.parse(
      JSON.stringify(state.detergentProducers.detergentProducer)
    ),
    countries: state.countries.list
  }));
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { roles } = getCognitoInfo();
  const role = roles.join();
  const countrySelect = () => {
    switch (role) {
      case 'kna':
        // eslint-disable-next-line no-return-assign
        return countryUSA;
      default:
        // eslint-disable-next-line no-return-assign
        return country;
    }
  };
  getFieldDecorator('contactIndexes', {
    initialValue: _.isEmpty(detergentProducer.contact_list)
      ? [0]
      : Object.keys(detergentProducer.contact_list)
  });

  const contactIndexes = getFieldValue('contactIndexes');
  const contactLists = detergentProducer.contact_list;

  const handleRemoveProducerContact = contactIndex => {
    const newContactIndexes = contactIndexes.filter(
      contactIndexValue => contactIndexValue !== contactIndex
    );

    setFieldsValue({
      contactIndexes: newContactIndexes
    });
  };

  const handleAddProducerContact = () => {
    const newIndex = getFieldValue('producer.contact_list').length;
    const newContactIndexes = contactIndexes.concat(newIndex);

    setFieldsValue({
      contactIndexes: newContactIndexes
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        const detergentFormData = _.assign(
          {},
          detergentProducer,
          values.producer,
          {
            contact_list: _.compact(values.producer.contact_list)
          }
        );
        if (detergentFormData.country === undefined) {
          _.set(detergentFormData, 'country', '');
        }
        if (props.status === FORM_STATUS.CREATE) {
          _.set(
            detergentFormData,
            'erpNumber',
            values?.producer?.erpNumber
              ? parseFloat(values?.producer?.erpNumber)
              : null
          );

          dispatch(
            createDetergentProducers(
              trimStringFieldsInObject(detergentFormData)
            )
          );
        } else if (props.status === FORM_STATUS.UPDATE) {
          dispatch(
            updateDetergentProducers({
              id: detergentFormData.id,
              producer: trimStringFieldsInObject(detergentFormData)
            })
          );
        } else {
          props.onCancel();
        }
      }
    });
  };

  const renderContactList = () => {
    if (!contactIndexes) return null;

    return contactIndexes.map(contactIndex => (
      <div key={contactIndex}>
        <ContactList
          status={props.status}
          form={props.form}
          contactIndex={contactIndex}
          contactLists={contactLists}
          handleRemoveProducerContact={handleRemoveProducerContact}
        />
      </div>
    ));
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <div className="contact-producer">
        <Form
          labelCol={{ md: 24, lg: 8 }}
          wrapperCol={{ md: 24, lg: 16 }}
          className="info-producer"
          onSubmit={handleSubmit}>
          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="producer.name"
                    defaultMessage="Producer name"
                  />
                }>
                {getFieldDecorator('producer.name', {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="error.producerNameIsRequired"
                          defaultMessage="Producer name is required!"
                        />
                      )
                    }
                  ],
                  initialValue: detergentProducer.name
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="erp.title"
                    defaultMessage="ERP number"
                  />
                }>
                <div id="parent-node">
                  {getFieldDecorator('producer.erpNumber', {
                    rules: [
                      {
                        validator: validatorErpNumber(formatMessage)
                      }
                    ],
                    initialValue: detergentProducer.erpNumber
                  })(<InputNumber type="number" />)}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="producer.street"
                    defaultMessage="Street"
                  />
                }>
                {getFieldDecorator('producer.street', {
                  initialValue: detergentProducer.street
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="producer.streetNumber"
                    defaultMessage="Street number"
                  />
                }
                type="number">
                {getFieldDecorator('producer.street_number', {
                  initialValue: detergentProducer.street_number
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="producer.postalCode"
                    defaultMessage="Postal code"
                  />
                }
                type="number">
                {getFieldDecorator('producer.postal_code', {
                  initialValue: detergentProducer.postal_code
                })(<InputNumber />)}
              </Form.Item>
            </Col>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={
                  <FormattedMessage id="producer.city" defaultMessage="City" />
                }>
                {getFieldDecorator('producer.city', {
                  initialValue: detergentProducer.city
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
              <Form.Item
                labelCol={{ md: 24, lg: 4 }}
                wrapperCol={{ md: 24, lg: 20 }}
                label={
                  <FormattedMessage
                    id="producer.country"
                    defaultMessage="Country"
                  />
                }>
                {getFieldDecorator('producer.country', {
                  initialValue: detergentProducer.country || undefined
                })(
                  <Select
                    showSearch
                    placeholder={
                      <FormattedMessage
                        id="rawMaterial.selectCountries"
                        defaultMessage="Select country"
                      />
                    }
                    suffixIcon={<Icon type="caret-down" />}
                    optionFilterProp="children"
                    getPopupContainer={() =>
                      document.getElementById('parent-node')
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {countries
                      .map(item => (
                        <Select.Option key={item.code} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))
                      .concat(
                        <Select.Option key={BLANK_OPTION.id} value="">
                          {BLANK_OPTION.name}
                        </Select.Option>
                      )}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              className="record-belong"
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}>
              <Form.Item
                labelCol={{ md: 24, lg: 4 }}
                wrapperCol={{ md: 24, lg: 20 }}
                label={
                  <FormattedMessage
                    id="producer.recordBelongTo"
                    defaultMessage="Record belongs"
                  />
                }>
                <div className="value" id="parent-node">
                  {getFieldDecorator('producer.fromCountryId', {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="error.country"
                            defaultMessage="Country is required!"
                          />
                        )
                      }
                    ],
                    initialValue: detergentProducer.fromCountryId || undefined
                  })(
                    <FilterableSelect
                      getPopupContainer={() =>
                        document.getElementById('parent-node')
                      }
                      placeholder={
                        <FormattedMessage
                          id="rawMaterial.selectCountries"
                          defaultMessage="Select countries"
                        />
                      }
                      suffixIcon={<Icon type="caret-down" />}>
                      {countrySelect().map((item, idx) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </FilterableSelect>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <div className="title-contact-person">
            <FormattedMessage
              id="contactPerson.title"
              defaultMessage="Contact person"
            />
          </div>

          {renderContactList()}

          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 3, push: 4 }}>
              <div className="add-more" onClick={handleAddProducerContact}>
                <p style={{ marginRight: '5px' }}>+</p>
                <u>
                  <FormattedMessage
                    id="common.addMore"
                    defaultMessage="Add more"
                  />
                </u>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
              <Form.Item
                labelCol={{ md: 24, lg: 4 }}
                wrapperCol={{ md: 24, lg: 20 }}
                label={
                  <FormattedMessage
                    id="comment.title"
                    defaultMessage="Comment"
                  />
                }
                className="comment">
                {getFieldDecorator('producer.comment', {
                  initialValue: detergentProducer.comment
                })(<Input.TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="form-btn-actions">
                <Button
                  className="btn-cancel"
                  onClick={() => {
                    props.onCancel();
                  }}>
                  <FormattedMessage
                    id="common.cancelButton"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <Button className="btn-save" type="primary" htmlType="submit">
                  <FormattedMessage
                    id="common.saveButton"
                    defaultMessage="SAVE"
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverlayLoading>
  );
};

const ProducerContactForm = Form.create({
  name: 'Producer',
  mapPropsToFields(props) {
    const { user } = props;
    const transformed = transformFields({ user });
    return transformed;
  },
  onValuesChange(_, values) {}
})(_ProducerContactForm);

export const ProducerForm = ProducerContactForm;
