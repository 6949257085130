export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_ERROR = 'GET_LOGS_ERROR';
export const CLEAR_LOGS_DATA_WHEN_UNMOUNT = 'CLEAR_LOGS_DATA_WHEN_UNMOUNT';

export const getLogs = payload => ({
  type: GET_LOGS,
  payload
});

export const getLogsSuccess = payload => ({
  type: GET_LOGS_SUCCESS,
  payload
});

export const getLogsError = payload => ({
  type: GET_LOGS_ERROR,
  payload
});

export const clearLogsDataWhenUnmount = () => ({
  type: CLEAR_LOGS_DATA_WHEN_UNMOUNT
});
