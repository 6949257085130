import React from 'react';
import PropType from 'prop-types';
import Label from './Label';

const ValueField = ({
  value,
  label,
  valueClass,
  containerClass,
  labelClass
}) => {
  return (
    <section className={containerClass}>
      <Label className={labelClass} label={label} />
      <span className={valueClass}>{value}</span>
    </section>
  );
};

ValueField.propTypes = {
  label: PropType.string.isRequired,
  value: PropType.string.isRequired,
  valueClass: PropType.string,
  containerClass: PropType.string,
  labelClass: PropType.string
};

ValueField.defaultProps = {
  valueClass: '',
  containerClass: '',
  labelClass: ''
};

export default ValueField;
