import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ACTION_BUTTON_TYPES } from '../../../../../configs/constant';

export const renderReviewPartNumberColumns = ({ onEditPartNumber }) => [
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnPartNumber"
        defaultMessage="Part number"
      />
    ),
    dataIndex: 'partNumber',
    key: 'partNumber'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnProducer"
        defaultMessage="Producer"
      />
    ),
    dataIndex: 'detergentProducer.name',
    key: '`detergentProducer.name`'
  },
  {
    key: 'action',
    render: record => {
      return (
        <Button type="link" onClick={() => onEditPartNumber(record)}>
          <img
            className={`${ACTION_BUTTON_TYPES.EDIT_PARTNUMBER_ACTION} action-item`}
            alt=""
            src="/icon/ic-edit.svg"
          />
        </Button>
      );
    }
  }
];
