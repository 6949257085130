import React, { useState } from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

const FilterForm = props => {
  const {
    FilterFormLayout,
    dropdownClassName,
    dropdownOverlayClassName,
    hasMarginRight = true,
    filterFormProps
  } = props;
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const onVisibleChange = visibility => {
    setIsFilterVisible(visibility);
  };
  const FilterPlace = () => {
    return (
      <div className={dropdownOverlayClassName}>
        <FilterFormLayout
          onCancel={() => onVisibleChange(false)}
          {...filterFormProps}
        />
      </div>
    );
  };
  return (
    <div
      id="dropdown-parent-node"
      style={{ marginRight: hasMarginRight ? '20px' : 0 }}>
      <Dropdown
        getPopupContainer={() =>
          document.getElementById('dropdown-parent-node')
        }
        className={dropdownClassName}
        overlay={<FilterPlace />}
        trigger={['click']}
        onVisibleChange={visibility => onVisibleChange(visibility)}
        placement="bottomCenter"
        forceRender
        visible={isFilterVisible}
        overlayClassName="content-filter-form filter-form-style">
        <div className="ant-dropdown-link">
          <div className="edit_filter">
            <img src="icon/ic-filter-2.svg" alt="" />
          </div>
          <div className="sort">Filter</div>
        </div>
      </Dropdown>
    </div>
  );
};

FilterForm.propTypes = {
  FilterFormLayout: PropTypes.func.isRequired,
  dropdownClassName: PropTypes.string.isRequired,
  dropdownOverlayClassName: PropTypes.string.isRequired,
  hasMarginRight: PropTypes.bool,
  filterFormProps: PropTypes.shape({})
};

FilterForm.defaultProps = {
  hasMarginRight: false,
  filterFormProps: {}
};

export default FilterForm;
