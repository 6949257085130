import {
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_ERROR,
  CLEAR_LOGS_DATA_WHEN_UNMOUNT
} from './actions';
import { transformHistoryToStandardData } from '../../../../utils/common';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    },
    fetching: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGS:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case GET_LOGS_SUCCESS: {
      const { results, pagination } = action.payload.data;
      const transformResults = transformHistoryToStandardData(null, results);

      return {
        ...state,
        list: {
          ...state.list,
          data: {
            results: transformResults,
            pagination
          },
          fetching: false
        }
      };
    }
    case GET_LOGS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    case CLEAR_LOGS_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
