import React, { useState, useEffect } from 'react';
import { Checkbox, Icon } from 'antd';
import PropType from 'prop-types';
import * as _ from 'lodash';
import FileSelectorCheckBox from '../GeneratingFileSelectorModal/FileSelectorCheckBox';
import { DETERGENT_FORMULA_DOCUMENT_CATEGORIES } from '../../../../../configs/constant';

const UploadedFileCheckBoxes = ({ files, setFiles, disable, isCancel }) => {
  const [isAllCheckBoxSelected, setIsAllCheckBoxSelected] = useState(false);
  useEffect(() => {
    if (isCancel) setIsAllCheckBoxSelected(false);
  }, [isCancel]);

  const getCurrentCategory = catelogyIndex =>
    _.find(
      DETERGENT_FORMULA_DOCUMENT_CATEGORIES,
      o => o.id === parseInt(catelogyIndex, 0)
    )?.name;

  return (
    <>
      <FileSelectorCheckBox
        defaultMessage="All"
        formattedMessageId="common.all"
        checked={isAllCheckBoxSelected}
        disabled={disable}
        onChange={() => {
          const selectedValue = !isAllCheckBoxSelected;
          const newFiles = [...files];
          newFiles.forEach(file => {
            file.isSelected = selectedValue;
          });

          setIsAllCheckBoxSelected(selectedValue);
          setFiles(newFiles);
        }}
      />
      {files.map((doc, index) => {
        return (
          <section className="document-files" key={doc.key}>
            <Checkbox
              onChange={() => {
                const newFiles = [...files];
                newFiles[index].isSelected = !doc.isSelected;
                setFiles(newFiles);
              }}
              checked={doc.isSelected}
              disabled={disable}>
              <Icon
                className="margin-right-5 attach-icon-align"
                type="paper-clip"
              />
              {doc.name}
              <span className="document-file-category">
                ({getCurrentCategory(doc.category)})
              </span>
            </Checkbox>
          </section>
        );
      })}
    </>
  );
};

UploadedFileCheckBoxes.propTypes = {
  files: PropType.shape([]).isRequired,
  setFiles: PropType.func.isRequired,
  disable: PropType.bool,
  isCancel: PropType.bool.isRequired
};

UploadedFileCheckBoxes.defaultProps = {
  disable: false
};

export default UploadedFileCheckBoxes;
