import React from 'react';
import PropType from 'prop-types';
import SvgIcon from '../../../../../../../../components/SvgIcon';

const CheckBoxIcon = ({ checked, children }) => {
  return (
    <section>
      <SvgIcon
        className="production-cycle-checkbox-icon"
        componentId={checked ? 'check' : 'dot'}
      />
      <span className="production-cycle-checkbox-text">{children}</span>
    </section>
  );
};

CheckBoxIcon.propTypes = {
  checked: PropType.bool.isRequired,
  children: PropType.node.isRequired
};

export default CheckBoxIcon;
