import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DetergentDevelopment } from './ExpandedSections/DetergentDevelopment';
import { SafetyTeam } from './ExpandedSections/SafetyTeam';
import { LabelingTeam } from './ExpandedSections/LabelingTeam';

export const ExpandedProductionCycleRow = ({
  record,
  onSendEmailToSafetyTeam,
  onSendEmailToLabelingTeam
}) => {
  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="top"
        className="expanded-production-cycle-row">
        <Col span={14} className="col-left">
          <DetergentDevelopment
            record={record}
            onSendEmailToSafetyTeam={onSendEmailToSafetyTeam}
          />
        </Col>
        <Col span={10} className="col-right">
          <SafetyTeam
            record={record}
            onSendEmailToLabelingTeam={onSendEmailToLabelingTeam}
          />
          <LabelingTeam record={record} />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="top"
        className="expanded-production-cycle-row">
        <Col span={24} className="production-cycle-comment">
          <strong>
            <FormattedMessage
              id="productionCycle.comment"
              defaultMessage="Comment"
            />
          </strong>
          : {record.comment}
        </Col>
      </Row>
    </>
  );
};

ExpandedProductionCycleRow.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onSendEmailToSafetyTeam: PropTypes.func.isRequired,
  onSendEmailToLabelingTeam: PropTypes.func.isRequired
};
