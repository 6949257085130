import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  BASE_DB_FIELDS,
  FORM_STATUS,
  STATUS_ENUM
} from '../../configs/constant';
import { openDeactiveProducerConfirmModal } from '../../utils/dialogs';
import { getRawMaterialApi } from '../RawMaterial/data/rawMaterial/api';
import { FilterForm } from './components/Form/filterForm';
import { ProducerContactModal } from './components/Modal';
import { List } from './components/List';
import {
  getRawMaterialProducers,
  processProducerModal,
  updateRawMaterialProducer,
  clearRawMaterialProducerDataWhenUnmount,
  setLoadingRawMaterialProducer
} from './data/rawMaterialProducers/actions';
import { ConfirmDeactiveDialog } from './components/ConfirmDeactiveDialog';
import { getCountries } from '../../data/countries/action';
import Filter from '../../components/Filter';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';

const RawMaterialProducer = ({ intl }) => {
  const [isUpdatingProducerStatus, setIsUpdatingProducerStatus] = useState(
    false
  );

  const dispatch = useDispatch();
  const {
    pagination,
    rawMaterialProducers,
    searchByText,
    modalLoadLatestDataAfterCreateOrUpdate,
    order,
    filterList,
    confirmModalVisibility,
    countsRefeshList
  } = useSelector(state => {
    return {
      pagination: state.rawMaterialProducers.list.data.pagination,
      rawMaterialProducers: state.rawMaterialProducers,
      modalLoadLatestDataAfterCreateOrUpdate:
        state.rawMaterialProducers.modal.loadLatestDataAfterCreateOrUpdate,
      searchByText: state.rawMaterialProducers.searchByText,
      order: state.rawMaterialProducers.order,
      filterList: state.rawMaterialProducers.filterList,
      confirmModalVisibility:
        state.rawMaterialProducers.modal.confirmModalVisibility,
      countsRefeshList: state.rawMaterialProducers.modal.countsRefeshList
    };
  });

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearRawMaterialProducerDataWhenUnmount());
    };
  }, []);

  useEffect(() => {
    // Reload list
    if (!confirmModalVisibility) {
      let newOrder = { ...order };
      const newPagination = { ...pagination };

      if (modalLoadLatestDataAfterCreateOrUpdate) {
        newOrder = {
          [BASE_DB_FIELDS.UPDATE_AT]: 'DESC'
        };
        newPagination.current = 1;
      }
      dispatch(
        getRawMaterialProducers({
          pagination: newPagination,
          searchByText,
          order: newOrder,
          modalLoadLatestDataAfterCreateOrUpdate,
          filterList
        })
      );
    }

    // Update flag isUpdatingProducerStatus
    if (!confirmModalVisibility) {
      setIsUpdatingProducerStatus(false);
    }
  }, [countsRefeshList, confirmModalVisibility]);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    dispatch(
      getRawMaterialProducers({ pagination, searchByText, order, filterList })
    );
  };

  const onPageChange = current => {
    pagination.current = ++current;
    dispatch(
      getRawMaterialProducers({ pagination, searchByText, order, filterList })
    );
  };

  const onEditProducer = record => {
    if (!isEmpty(record)) {
      dispatch(
        processProducerModal({
          producer: record,
          statusForm: FORM_STATUS.UPDATE,
          title: intl.formatMessage({
            id: 'rawMaterialProducer.modal.edit.title',
            defaultMessage: 'EDIT RAW MATERIAL PRODUCER/SUPPLIER'
          }),
          isVisible: true
        })
      );
    }
  };

  const deactiveProducerHandler = async (record, type) => {
    const data = {
      ...record,
      status: type
    };
    dispatch(
      updateRawMaterialProducer({
        id: record.id,
        producer: data
      })
    );

    return true;
  };

  const checkProducerRelatedToRawMaterial = async producerId => {
    const rawMaterialProducer = await getRawMaterialApi({
      filter: JSON.stringify({
        rawMaterialProducer: producerId
      })
    });
    const countProducers = rawMaterialProducer.data.data.results.length;
    if (countProducers > 0) {
      return true;
    }

    // Check supplier
    const rawMaterialSupplier = await getRawMaterialApi({
      filter: JSON.stringify({
        rawMaterialSupplier: producerId
      })
    });
    const countSupplier = rawMaterialSupplier.data.data.results.length;
    if (countSupplier > 0) {
      return true;
    }

    return false;
  };

  const onDeactiveProducer = async (record, type) => {
    if (isUpdatingProducerStatus) {
      return;
    }

    dispatch(setLoadingRawMaterialProducer({ fetching: true }));
    setIsUpdatingProducerStatus(true);

    let configTitleTranslation = {
      id: 'rawMaterialProducer.modal.deactivate.title',
      defaultMessage: 'DEACTIVATE RAW MATERIAL PRODUCER/SUPPLIER'
    };

    if (type === STATUS_ENUM.ACTIVE) {
      configTitleTranslation = {
        id: 'rawMaterialProducer.modal.activate.title',
        defaultMessage: 'ACTIVATE RAW MATERIAL PRODUCER/SUPPLIER'
      };
    }

    const getConfigConfirmModal = (isRelated = false) => ({
      intl,
      title: intl.formatMessage(configTitleTranslation),
      record,
      type,
      content: (
        <ConfirmDeactiveDialog
          intl={intl}
          type={type}
          isRelated={isRelated}
          record={record}
        />
      ),
      deactiveProducerHandler,
      onCancel: () => {
        setIsUpdatingProducerStatus(false);
        dispatch(setLoadingRawMaterialProducer({ fetching: false }));
      }
    });

    // Case active
    if (type === STATUS_ENUM.ACTIVE) {
      openDeactiveProducerConfirmModal(getConfigConfirmModal(false));
      return;
    }

    // Case inactive
    const isRelated = await checkProducerRelatedToRawMaterial(record.id);
    openDeactiveProducerConfirmModal(getConfigConfirmModal(isRelated));
  };

  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');

    pagination.current = 1;
    dispatch(
      getRawMaterialProducers({
        pagination,
        searchByText: valueChange,
        order,
        filterList
      })
    );
  };

  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(
        getRawMaterialProducers({
          pagination,
          searchByText: '',
          order,
          filterList
        })
      );
    }
  };

  const popupAction = action => {
    if (action === 'open') {
      dispatch(
        processProducerModal({
          statusForm: FORM_STATUS.CREATE,
          title: intl.formatMessage({
            id: 'rawMaterialProducer.modal.add.title',
            defaultMessage: 'ADD NEW RAW MATERIAL PRODUCER/SUPPLIER'
          }),
          isVisible: true
        })
      );
    } else {
      dispatch(
        processProducerModal({
          isVisible: false
        })
      );
    }
  };

  return (
    <>
      <div className="detergent-database-content raw-material-producer-tab">
        <div className="main-title">
          <FormattedMessage
            id="label.rawMaterialProducer"
            defaultMessage="Raw Material Producers/Suppliers"
          />
        </div>
        <div className="filter-area">
          <Filter
            dropdownClassName="sort-dropdown"
            dropdownOverlayClassName="filter-detergentProducer"
            FilterFormLayout={FilterForm}
          />
          <Input.Search
            className="search-place"
            placeholder={intl.formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearchList(value)}
          />
          <ProtectedComponent
            allowRoles={
              ROLES_OF_PERMISSIONS.RAW_MATERIAL_PRODUCERS_SUPPLIERS.CREATE
            }>
            <Button className="add-button" onClick={() => popupAction('open')}>
              <Icon className="add-icon" type="plus" />
              <div className="text">
                <FormattedMessage
                  id="common.addProducerButton"
                  defaultMessage="ADD PRODUCER"
                />
              </div>
            </Button>
          </ProtectedComponent>
          <ProducerContactModal
            status={rawMaterialProducers.modal.statusForm}
            title={rawMaterialProducers.modal.title}
            isVisible={rawMaterialProducers.modal.isVisible}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <List
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onEditProducer={record => onEditProducer(record)}
        onDeactiveProducer={(record, type) => onDeactiveProducer(record, type)}
      />
    </>
  );
};

export default injectIntl(RawMaterialProducer);
