import React from 'react';
import { Typography } from 'antd';
import { FormattedDate, FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import GeneratedFileTitle from './GeneratedFileTitle';
import { changeDateFormatToDot } from '../../../../../../../utils/common';

const GeneratedFileTitleDateHeader = ({ title, datePrefix }) => {
  const datePrefixOrVersion = () => {
    if (title === 'MEASUREMENT DATA SHEET') {
      // eslint-disable-next-line no-return-assign
      return (datePrefix = (
        <FormattedMessage id="detergent.version" defaultMessage="Version" />
      ));
    }
    return datePrefix;
  };

  const DateDotOrVersion = () => {
    if (title === 'MEASUREMENT DATA SHEET') {
      return <FormattedDate value={new Date()} />;
    }
    return changeDateFormatToDot();
  };

  return (
    <GeneratedFileTitle title={title}>
      <div className="production-instructions">
        <Typography.Text type="secondary">
          {datePrefixOrVersion()}:{' '}
        </Typography.Text>
        <Typography.Text strong className="date">
          {DateDotOrVersion()}
        </Typography.Text>
      </div>
    </GeneratedFileTitle>
  );
};

GeneratedFileTitleDateHeader.propTypes = {
  title: PropType.string.isRequired,
  datePrefix: PropType.string
};

GeneratedFileTitleDateHeader.defaultProps = {
  datePrefix: (
    <FormattedMessage id="detergent.printDate" defaultMessage="Print Date" />
  )
};

export default GeneratedFileTitleDateHeader;
