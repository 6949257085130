import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Radio, Select, Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import assign from 'lodash/assign';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayLoading } from '../../../../components/Loading';
import ValidatedFormulaSelection from './ValidatedFormulaSelection';
import { UploadDocuments } from '../../../../components/BaseUpload/UploadDocuments';
import {
  FORMULA_SAMPLE,
  FORMULA_FORM,
  FORM_STATUS,
  UPLOAD_TYPES
} from '../../../../configs/constant';
import {
  createDetergent,
  updateDetergent,
  createFormula
} from '../../data/detergent/actions';
import { validateDocumentCategories } from '../../../../utils/validator';
import {
  getDensityLabel,
  trimStringFieldsInObject,
  changeCommaToPoint
} from '../../../../utils/common';
import Country from '../../../../components/Country';

// eslint-disable-next-line no-underscore-dangle
const _DetergentForm = props => {
  const {
    form: { getFieldDecorator, getFieldValue, validateFields },
    status,
    onCancel
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const [detergent, setDetergent] = useState({});
  const [detergentFormulaLatest, setDetergentFormulaLatest] = useState({});
  const { modalLoading, emptyDetergent, allRawMaterials } = useSelector(
    state => ({
      modalLoading: state.detergent.modal.loading,
      emptyDetergent: JSON.parse(
        JSON.stringify(state.detergent.emptyDetergent)
      ),
      allRawMaterials: state.rawMaterials.allRawMaterials.list
    })
  );
  const firstDetergentFormulaInstance = 'formulas[0]';

  useEffect(() => {
    const formulaLatest = _.chain(emptyDetergent.formulas)
      .sort(function(a, b) {
        const c = a.version;
        const d = b.version;
        return c - d;
      })
      .last()
      .value();

    if (formulaLatest) {
      setDetergentFormulaLatest(formulaLatest);
    }

    mappingArrayObjectToArrayString(['rawMaterials']);
    setDetergent(emptyDetergent);
  }, []);

  const mappingArrayObjectToArrayString = fieldKeys => {
    fieldKeys.forEach(fieldKey => {
      const mappingObjectsToStrings = [];
      if (!emptyDetergent[fieldKey]) return;

      if (Array.isArray(emptyDetergent[fieldKey])) {
        emptyDetergent[fieldKey].forEach(fieldValue => {
          mappingObjectsToStrings.push(fieldValue.name);
        });
      }

      emptyDetergent[fieldKey] = mappingObjectsToStrings;
    });
  };

  const isCountryDisable = useMemo(() => status === FORM_STATUS.ADD_FORMULA, [
    status
  ]);
  const isDuplicateDetergent = useMemo(
    () => status === FORM_STATUS.DUPLICATE_DETERGENT,
    [status]
  );
  const getInitialRawMaterialRatios = () => {
    if (
      status !== FORM_STATUS.ADD_FORMULA &&
      status !== FORM_STATUS.DUPLICATE_DETERGENT
    ) {
      return detergentFormulaLatest.rawMaterialRatios;
    }

    if (!_.isEmpty(detergentFormulaLatest.rawMaterialRatios)) {
      return detergentFormulaLatest.rawMaterialRatios.map(rawMaterialRatio => ({
        ratio: rawMaterialRatio.ratio,
        rawMaterialId: rawMaterialRatio.rawMaterialId,
        order: rawMaterialRatio.order
      }));
    }

    return null;
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      const oldDetergent = { ...emptyDetergent };
      const newDetergent = assign(oldDetergent, values);

      if (!err) {
        if (
          status === FORM_STATUS.CREATE ||
          status === FORM_STATUS.DUPLICATE_DETERGENT
        ) {
          const formData = trimStringFieldsInObject(values);
          const densityChange = formData?.formulas?.[0]?.density;
          _.set(
            formData,
            'formulas.0.density',
            changeCommaToPoint(densityChange)
          );
          dispatch(createDetergent(formData));
        }

        if (status === FORM_STATUS.UPDATE) {
          const formData = trimStringFieldsInObject(newDetergent);
          dispatch(updateDetergent({ id: formData.id, detergent: formData }));
        }

        if (status === FORM_STATUS.ADD_FORMULA) {
          const newFormulaData = {};
          newFormulaData.sample = _.get(
            newDetergent,
            'formulas.0.sample',
            null
          );
          newFormulaData.form = _.get(newDetergent, 'formulas.0.form', null);
          newFormulaData.documents = _.get(
            newDetergent,
            'formulas.0.documents',
            null
          );
          newFormulaData.density = changeCommaToPoint(
            _.get(newDetergent, 'formulas.0.density', null)
          );
          newFormulaData.productionInstruction = _.get(
            newDetergent,
            'formulas.0.productionInstruction',
            null
          );
          newFormulaData.propertyDescription = _.get(
            newDetergent,
            'formulas.0.propertyDescription',
            null
          );
          newFormulaData.rawMaterialRatios = _.get(
            newDetergent,
            'formulas.0.rawMaterialRatios',
            null
          );
          dispatch(
            createFormula({ id: newDetergent.id, formula: newFormulaData })
          );
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 13 }
    }
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <div className="detergent">
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={handleSubmit}>
          <Form.Item
            label={
              <FormattedMessage
                id="detergent.number"
                defaultMessage="Detergent number"
              />
            }>
            {getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateDetergentNumber',
                    defaultMessage: 'Detergent number is required'
                  })
                },
                {
                  validator: (_rule, val, callback) => {
                    if (isDuplicateDetergent) {
                      if (val === detergent.number) {
                        callback(
                          new Error(
                            formatMessage({
                              id: 'common.messageDetergentNumberAlreadyExisted',
                              defaultMessage:
                                'This detergent number is already existed.'
                            })
                          )
                        );
                      }
                    }
                    callback();
                  }
                }
              ],
              initialValue: detergent.number
            })(<Input disabled={isCountryDisable} />)}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="detergent.name"
                defaultMessage="Detergent name"
              />
            }>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateDetergentName',
                    defaultMessage: 'Detergent name is required'
                  })
                }
              ],
              initialValue: detergent.name
            })(<Input disabled={isCountryDisable} />)}
          </Form.Item>
          <Country
            disabled={isCountryDisable}
            getFieldDecorator={getFieldDecorator}
            initValue={detergent.fromCountryId || undefined}
            field="fromCountryId"
          />
          <Row>
            <Col span={8}>
              <div className="formula">
                <FormattedMessage id="formula.title" defaultMessage="Formula" />
              </div>
            </Col>
          </Row>
          <Form.Item
            label={
              <FormattedMessage
                id="formula.sample"
                defaultMessage="Sample type of the formula"
              />
            }>
            {getFieldDecorator(`${firstDetergentFormulaInstance}.sample`, {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateSample',
                    defaultMessage: 'Sample type is required'
                  })
                }
              ],
              initialValue: detergentFormulaLatest.sample
            })(
              <Radio.Group className="radio" disabled={isDuplicateDetergent}>
                <Radio value={FORMULA_SAMPLE.A} className="aSample">
                  {FORMULA_SAMPLE.A}
                </Radio>
                <Radio value={FORMULA_SAMPLE.AB} className="abSample">
                  {FORMULA_SAMPLE.AB}
                </Radio>
                <Radio value={FORMULA_SAMPLE.B} className="bSample">
                  {FORMULA_SAMPLE.B}
                </Radio>
                <Radio value={FORMULA_SAMPLE.D} className="dSample">
                  {FORMULA_SAMPLE.D}
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage id="formula.form" defaultMessage="Form" />
            }>
            <div id="parent-node">
              {getFieldDecorator(`${firstDetergentFormulaInstance}.form`, {
                rules: [
                  {
                    required: true,
                    message: formatMessage({
                      id: 'common.validateForm',
                      defaultMessage: 'Form is required'
                    })
                  }
                ],
                initialValue: detergentFormulaLatest.form
                  ? detergentFormulaLatest.form
                  : undefined
              })(
                <Select
                  placeholder={formatMessage({
                    id: 'detergent.selectAForm',
                    defaultMessage: 'Select a form'
                  })}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  suffixIcon={<Icon type="caret-down" />}
                  disabled={isDuplicateDetergent}>
                  {FORMULA_FORM.map(item => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={getDensityLabel(
              getFieldValue(`${firstDetergentFormulaInstance}.form`)
            )}>
            {getFieldDecorator(`${firstDetergentFormulaInstance}.density`, {
              rules: [
                {
                  validator: (rule, val, callback) => {
                    const num = changeCommaToPoint(val);
                    if (val !== '' && val !== null && Number.isNaN(num)) {
                      callback(new Error());
                    }
                    callback();
                  },
                  message: formatMessage({
                    id: 'common.validateDensity',
                    defaultMessage: 'You should input number'
                  })
                }
              ],
              initialValue: detergentFormulaLatest.density
            })(<Input disabled={isDuplicateDetergent} />)}
          </Form.Item>
          <ValidatedFormulaSelection
            getFieldDecorator={getFieldDecorator}
            field={`${firstDetergentFormulaInstance}.rawMaterialRatios`}
            initialValue={getInitialRawMaterialRatios()}
            rawMaterials={allRawMaterials}
            placeholder={
              <FormattedMessage
                id="detergent.selectOneOrMoreRawMaterials"
                defaultMessage="Select one or more raw materials"
              />
            }
            status={status}
            disabled={isDuplicateDetergent}
            label={
              <FormattedMessage
                id="rawMaterial.title"
                defaultMessage="Raw Materials"
              />
            }
          />
          <Form.Item
            label={
              <FormattedMessage
                id="detergent.upload"
                defaultMessage="Upload documents"
              />
            }
            className="upload">
            {getFieldDecorator(`${firstDetergentFormulaInstance}.documents`, {
              valuePropName: 'fileList',
              initialValue: !isDuplicateDetergent
                ? detergentFormulaLatest.documents
                : [],
              rules: [
                {
                  validator: validateDocumentCategories(formatMessage)
                }
              ]
            })(
              <UploadDocuments
                type={UPLOAD_TYPES.DETERGENT}
                disabled={isDuplicateDetergent}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="formula.instructions"
                defaultMessage="Production instructions"
              />
            }>
            {getFieldDecorator(
              `${firstDetergentFormulaInstance}.productionInstruction`,
              {
                initialValue: detergentFormulaLatest.productionInstruction
              }
            )(<Input.TextArea rows={7} disabled={isDuplicateDetergent} />)}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="formula.properties"
                defaultMessage="Formula's properties"
              />
            }
            style={{ marginTop: '10px' }}>
            {getFieldDecorator(
              `${firstDetergentFormulaInstance}.propertyDescription`,
              {
                initialValue: detergentFormulaLatest.propertyDescription
              }
            )(<Input.TextArea rows={7} disabled={isDuplicateDetergent} />)}
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <div className="form-btn-actions">
                <Button className="btn-cancel" onClick={onCancel}>
                  <FormattedMessage
                    id="common.cancelButton"
                    defaultMessage="CANCEL"
                  />
                </Button>
                <Button className="btn-save" type="primary" htmlType="submit">
                  <FormattedMessage
                    id="common.saveButton"
                    defaultMessage="SAVE"
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverlayLoading>
  );
};

_DetergentForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const DetergentForm = Form.create({ name: 'create-new-formula' })(
  _DetergentForm
);
