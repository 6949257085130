import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '../../../../components/BaseModal';
import { DetergentForm } from '../Form';
import { FORM_STATUS } from '../../../../configs/constant';
import { ViewModal } from './ViewModal';
import { FormEditModal } from './EditModal';
import { AddProductionCycleModal } from './AddProductionCycleModal';
import { CompareDetergentModal } from './CompareDetergentModal';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';

export const DetergentModal = ({ status, onCancel, isVisible, title }) => {
  const { width } = useDeviceDetect();
  const renderContent = () => {
    // View Detail
    // Edit
    // Create new detergent, duplicate detergent or add new formula in the detergent
    // Compare detergents and their raw materials detail
    switch (status) {
      case FORM_STATUS.VIEW_DETAIL:
        return <ViewModal onCancel={() => onCancel()} />;

      case FORM_STATUS.UPDATE:
        return <FormEditModal onCancel={() => onCancel()} />;

      case FORM_STATUS.ADD_PRODUCTION_CYCLE:
        return <AddProductionCycleModal onCancel={() => onCancel()} />;

      case FORM_STATUS.CREATE:
      case FORM_STATUS.ADD_FORMULA:
      case FORM_STATUS.DUPLICATE_DETERGENT:
        return <DetergentForm status={status} onCancel={() => onCancel()} />;

      case FORM_STATUS.COMPARE:
        return <CompareDetergentModal onCancel={() => onCancel()} />;

      default:
        return null;
    }
  };

  let modalWidth;
  let modalClassName = 'detergent-common-modal';

  switch (status) {
    case FORM_STATUS.ADD_PRODUCTION_CYCLE:
      if (width > 1920) {
        modalWidth = '40%';
      } else if (width > 1440) {
        modalWidth = '45%';
      } else {
        modalWidth = '60%';
      }
      modalClassName += ' add-production-cycle-modal';
      break;
    case FORM_STATUS.COMPARE:
      modalWidth = '80%';
      break;
    default:
      modalWidth = '70%';
  }

  return (
    <BaseModal
      title={title}
      width={modalWidth}
      maskClosable={false}
      visible={isVisible}
      className={modalClassName}
      onCancel={() => onCancel()}>
      {isVisible && renderContent()}
    </BaseModal>
  );
};

DetergentModal.propTypes = {
  status: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired
};

DetergentModal.defaultProps = {
  status: ''
};
