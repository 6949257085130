import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ACTION_BUTTON_TYPES, STATUS_ENUM } from '../../../../configs/constant';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';

const Actions = props => {
  const { formatMessage } = useIntl();

  const { record, isSubList, selectedRowKeys } = props;

  const currentFormulaStatus = isSubList
    ? record.status
    : record.formulas?.[0]?.status;

  const currentFormulaId = record?.formulas?.length
    ? record.formulas?.[0].id
    : record.id;

  const formulaStatusIsEditable = [
    STATUS_ENUM.INPROGRESS,
    STATUS_ENUM.UN_APPROVED
  ];

  const isChecked = selectedRowKeys.includes(currentFormulaId);

  const isEditable = formulaStatusIsEditable.includes(currentFormulaStatus);

  return (
    <>
      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.CREATE}>
        {isSubList ? null : (
          <Tooltip
            placement="bottomLeft"
            title={formatMessage({
              id: 'detergent.tooltipAddNewFormula',
              defaultMessage: 'Add new formula'
            })}>
            <img
              className={`${ACTION_BUTTON_TYPES.ADD_NEW_FOMULAR} action-item`}
              alt=""
              src="/icon/ic-add.svg"
            />
          </Tooltip>
        )}
      </ProtectedComponent>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.CREATE}>
        <Tooltip
          placement="bottomLeft"
          title={formatMessage({
            id: 'detergent.tooltipDuplicateDetergent',
            defaultMessage: 'Duplicate detergent'
          })}>
          <img
            className={`${ACTION_BUTTON_TYPES.DUPLICATE_DETERGENT} duplicate-icon action-item`}
            alt=""
            src="/icon/ic-copy.svg"
          />
        </Tooltip>
      </ProtectedComponent>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.READ}>
        <img
          className={`${ACTION_BUTTON_TYPES.VIEW_DETERGENT_DETAIL} action-item`}
          title=""
          alt=""
          src="/icon/ic-view-details.svg"
        />
      </ProtectedComponent>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.EDIT}>
        {isEditable ? (
          <img
            className={`${ACTION_BUTTON_TYPES.EDIT_DETERGENTS} action-item`}
            title=""
            alt=""
            src="/icon/ic-edit.svg"
          />
        ) : (
          <span className="margin-left" />
        )}
      </ProtectedComponent>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.READ}>
        <input
          className={`${ACTION_BUTTON_TYPES.CHECK_BOX_DETERGENTS} detergent--check-box action-item`}
          type="checkbox"
          checked={isChecked}
          readOnly
        />
      </ProtectedComponent>

      {isSubList ? <span className="action-margin-left" /> : null}
    </>
  );
};

Actions.propTypes = {
  isSubList: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    formulas: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default Actions;
