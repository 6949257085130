import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  getFormFieldKey,
  getCurrentProductionCycleInstanceKey
} from '../../../Util/productionCycle/getFormFieldKey';
import { getCheckboxConfig } from '../../../../../../utils/common';
import ProcurementEmailFileSelectorModal from '../../ProcurementEmailFileSelectorModal/index';
import { disableRowBySelectedProductionCyle } from '../util/disableRowBySelectedProductionCyle';
import { FORM_STATUS } from '../../../../../../configs/constant';

export const LabelingTeam = ({
  form,
  record,
  selectedProductionCycle,
  groupPartNumbersById,
  isInactivePartNumber
}) => {
  const [
    isProcurementEmailFileSelectorModalVisible,
    setIsProcurementEmailFileSelectorModal
  ] = useState(false);

  const handleProcurementEmailFileSelectorModalVisibility = useCallback(() => {
    setIsProcurementEmailFileSelectorModal(
      !isProcurementEmailFileSelectorModalVisible
    );
  }, [isProcurementEmailFileSelectorModalVisible]);

  const formValue = form.getFieldsValue();
  const currentProductionCycleInstanceKey = getCurrentProductionCycleInstanceKey(
    record
  );

  const currentProductionCycles = {
    ...formValue.productionCycles[currentProductionCycleInstanceKey],
    id: record.id
  };

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.labelingTeam"
            defaultMessage="Labeling team"
          />
        </span>
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'partsListUploaded'),
          getCheckboxConfig(record?.partsListUploaded)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.partsListUploaded"
              defaultMessage="9. Parts list uploaded"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'productLabelUploaded'),
          getCheckboxConfig(record?.productLabelUploaded)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.productLabelUploaded"
              defaultMessage="10. Product label uploaded"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'transportLabelUploaded'),
          getCheckboxConfig(record?.transportLabelUploaded)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.transportLabelUploaded"
              defaultMessage="11. Transport label uploaded"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'labelingInstructionsUploaded'),
          getCheckboxConfig(record?.labelingInstructionsUploaded)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.labelingInstructionsUploaded"
              defaultMessage="12. Labeling instructions uploaded"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        <Button
          disabled={
            disableRowBySelectedProductionCyle(
              selectedProductionCycle,
              record
            ) || isInactivePartNumber
          }
          className="yellow-button"
          icon="right"
          onClick={handleProcurementEmailFileSelectorModalVisibility}>
          <FormattedMessage
            id="productionCycle.forwardToProcurement"
            defaultMessage="Forward to procurement"
          />
        </Button>
      </div>
      <ProcurementEmailFileSelectorModal
        type={FORM_STATUS.UPDATE}
        visible={isProcurementEmailFileSelectorModalVisible}
        setVisible={setIsProcurementEmailFileSelectorModal}
        productionCycles={currentProductionCycles}
        form={form}
        record={record}
        selectedProductionCycle={selectedProductionCycle}
        groupPartNumbersById={groupPartNumbersById}
      />
    </>
  );
};

LabelingTeam.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.shape(PropTypes.any).isRequired,
  selectedProductionCycle: PropTypes.shape({}),
  groupPartNumbersById: PropTypes.shape({}).isRequired,
  isInactivePartNumber: PropTypes.bool.isRequired
};

LabelingTeam.defaultProps = {
  selectedProductionCycle: undefined
};
