import React from 'react';
import { useLocation } from 'react-router';
import { onSignIn } from '../../utils/auth/userManager';
import { kaercherLogo } from '../../assets';
import { setPreLoginPathName, clearPreLoginPathName } from './util';
import { clearCognitoInfo } from '../../utils/auth/cognito';

const LoginComponent = () => {
  const style = {
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  };

  const buttonStyle = {
    border: 'none',
    background: '#ffed00',
    padding: '10px 70px',
    marginTop: '30px',
    fontFamily: 'ClanPro-Medium'
  };
  const textLogin = {
    fontFamily: 'ClanPro-Medium',
    fontSize: '25px',
    marginTop: '30px'
  };
  const styleLogo = { width: '150px' };

  clearPreLoginPathName();
  const location = useLocation();

  return (
    <div style={style}>
      <img style={styleLogo} src={kaercherLogo} alt="logo" />
      <div style={textLogin}>Welcome to Detergents Database</div>
      <button
        onClick={() => {
          clearCognitoInfo();
          setPreLoginPathName(location.pathname + location.search);
          onSignIn();
        }}
        type="button"
        style={buttonStyle}>
        LOGIN
      </button>
    </div>
  );
};

export default LoginComponent;
