import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '../../../../components/BaseModal';
import { FORM_STATUS } from '../../../../configs/constant';

import EditUserRoleModal from './EditRole';

const UserManagementModal = props => {
  const { status, title, isVisible, onCancel, modalData } = props;

  const modalWidth = status === FORM_STATUS.UPDATE ? '35%' : '60%';

  const renderContent = () => {
    const configModalContent = {
      status,
      onCancel,
      modalData
    };

    switch (status) {
      // case FORM_STATUS.CREATE:
      //   return <PartNumberForm {...configModalContent} />;

      case FORM_STATUS.UPDATE:
        return <EditUserRoleModal {...configModalContent} />;

      default:
        return null;
    }
  };

  return (
    <BaseModal
      title={title}
      width={modalWidth}
      maskClosable={false}
      visible={isVisible}
      className="raw-material-common-modal"
      onCancel={() => onCancel()}>
      {isVisible && renderContent()}
    </BaseModal>
  );
};

UserManagementModal.defaultProps = {
  modalData: {}
};

UserManagementModal.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalData: PropTypes.objectOf(PropTypes.any)
};

export default UserManagementModal;
