import React from 'react';
import PropType from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import generateTestSchema from '../../../../Util/measurement/testSchema';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../../../../configs/constant';
import measurementMeasuredMinMaxCommentTableHeader from '../Util/measurementMeasuredMinMaxCommentTableHeader';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { testTableId } from './constant';

const BlankMeasurementDataSheetTestTable = ({ measurement }) => {
  const { formatMessage } = useIntl();
  const metaDataColumns = measurementMeasuredMinMaxCommentTableHeader(
    MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
    formatMessage,
    'cellKeyTest',
    <FormattedMessage id="measurement.testTable.title" defaultMessage="TESTS" />
  );

  const generatedData = generateTestSchema(measurement);

  return (
    <BaseTable
      className="generated-file-measurement-table measurement-test-table"
      columns={metaDataColumns}
      dataSource={generatedData}
      pagination={false}
      tableElementId={testTableId}
    />
  );
};

BlankMeasurementDataSheetTestTable.propTypes = {
  measurement: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetTestTable;
