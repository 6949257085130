import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR
} from './action.js';

const initialState = {
  countries: {
    fetching: false,
    list: []
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        list: []
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.payload
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}
