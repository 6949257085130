import React from 'react';
import { CallbackComponent } from 'redux-oidc';
import PropTypes from 'prop-types';
import userManager from '../../utils/auth/userManager';
import { loginErrorHandle } from './util';

const CallBackComponent = ({ history }) => {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => history.push('/exchange-token')}
      errorCallback={error => {
        loginErrorHandle(error);
        return history.push('/login');
      }}>
      <div style={{ textAlign: 'center' }}>Redirecting...</div>
    </CallbackComponent>
  );
};

CallBackComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default CallBackComponent;
