import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Select, Icon } from 'antd';
import {
  MEASUREMENT_MAPPING_FIELD,
  PASS_FAIL_LABELS,
  DISPLAY_PASS_FAIL
} from '../../../../configs/constant';
import EditableColumnField from '../../../../components/EditableColumnField';

let passFail = [];

const onHandleDropdown = (e, record) => {
  passFail = [...PASS_FAIL_LABELS];
  const getValue = record.passFailed ? record.passFailed : '';
  const passFails = passFail.filter(item => item.value !== getValue);
  passFail = [...passFails];
};

export const renderMeasurementStorageTestColumns = isEditable => [
  {
    title: () => {
      return (
        <>
          <FormattedMessage
            id="measurement.storageTable.title"
            defaultMessage="STORAGE TEST"
          />
        </>
      );
    },
    key: 'cellKeyStorage',
    render: data => {
      return _.get(
        MEASUREMENT_MAPPING_FIELD,
        `${data.cellKeyStorage}.name`,
        `No label: ${data.cellKeyStorage}`
      );
    },
    width: '30%'
  },
  {
    title: () => {
      return (
        <div id="storage-title-parent-node">
          <EditableColumnField
            tabName="measurement"
            editable={isEditable}
            readableField={
              <Select
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                className="select-passfailAll-read">
                {passFail.map(item => {
                  return (
                    <Select.Option key={item.id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            }
            cellKey="allPassFailed"
            columnKey="passFail"
            editableField={
              <Select
                getPopupContainer={() =>
                  document.getElementById('storage-title-parent-node')
                }
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                className="select-passfailAll-edit">
                {PASS_FAIL_LABELS.map(item => {
                  return (
                    <Select.Option
                      className="passFailAll"
                      key={item.id}
                      value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            }
          />
        </div>
      );
    },
    key: 'passFailed',
    width: '45%',
    render: record => {
      return (
        <div id="storage-passfail-parent-node">
          <EditableColumnField
            tabName="measurement"
            editable={isEditable}
            readableField={
              <Select
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                style={{ width: '22%' }}
                className="measure-storage"
                value={DISPLAY_PASS_FAIL[record.passFailed]}>
                {passFail.map(item => {
                  return (
                    <Select.Option key={item.id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            }
            columnKey="passFailed"
            cellKey={record.cellKeyStorage}
            editableField={
              <Select
                getPopupContainer={() =>
                  document.getElementById('storage-passfail-parent-node')
                }
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                style={{ width: '22%' }}
                value={DISPLAY_PASS_FAIL[record.passFailed]}
                onDropdownVisibleChange={e => onHandleDropdown(e, record)}>
                {passFail.map(item => (
                  <Select.Option key={item.id} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </div>
      );
    }
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.comment.title"
          defaultMessage="Comment"
        />
      </div>
    ),
    key: 'comment',
    width: '25%',
    render: record => {
      return (
        <EditableColumnField
          tabName="measurement"
          editable={isEditable}
          readableField={
            <Input.TextArea
              className="measure-display"
              defaultValue={record.comment}
              disabled={!isEditable}
              autoSize={{ maxRows: 3 }}
            />
          }
          editableField={
            <Input.TextArea
              className="measure-edit-comment"
              defaultValue={record.comment}
              disabled={!isEditable}
              autoSize={{ maxRows: 3 }}
            />
          }
          columnKey="comment"
          cellKey={record.cellKeyStorage}
        />
      );
    }
  }
];
