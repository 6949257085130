import React from 'react';

export default () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        id="ic-check"
        d="M6.72 14.6642L16 1.80023L13.696 0.13623L5.92 10.9202L1.424 8.32823L0 10.8082L6.72 14.6642Z"
        fill="#646464"
      />
    </svg>
  );
};
