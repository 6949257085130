import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import {
  ACTION_BUTTON_TYPES,
  PART_NUMBER_STATUS,
  DISPLAY_PART_NUMBER_STATUS,
  MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME
} from '../../../../configs/constant';
import {
  patchPartNumber,
  setSelectedDropdownFieldForPartNumber
} from '../../data/partNumbers/actions';
import EditStatusForm from '../../../../components/EditStatus/index';

const UpdatePartNumberStatus = props => {
  const { partNumber, form } = props;

  const dispatch = useDispatch();

  const { selectedPartNumberId } = useSelector(state => ({
    selectedPartNumberId: state.partNumbers.selectedPartNumberChange.id
  }));
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    setIsEditable(selectedPartNumberId !== partNumber.id);
  }, [selectedPartNumberId]);

  const onOpenChangeStatusForm = useCallback(() => {
    dispatch(
      setSelectedDropdownFieldForPartNumber({
        selectedPartNumberId: partNumber.id
      })
    );
    setIsEditable(!isEditable);
  }, [isEditable]);

  const onEditStatusSaveChangesHandler = useCallback(
    (value, editForm, e) => {
      e.stopPropagation();
      dispatch(
        patchPartNumber({
          id: partNumber.id,
          data: {
            status: value
          }
        })
      );

      editForm.resetFields();
      setIsEditable(!isEditable);
    },
    [isEditable]
  );

  const onCancelChangesHandler = useCallback(() => {
    setIsEditable(!isEditable);
  }, [isEditable]);

  const displayStatus = useCallback(status => {
    return (
      <span
        className={`${ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER} ${MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME[status]}`}>
        {DISPLAY_PART_NUMBER_STATUS[status]}
      </span>
    );
  }, []);

  const renderEditButton = useCallback(() => {
    const isInvisible = !isEditable ? 'hidden' : '';

    return (
      <img
        alt=""
        aria-hidden
        onClick={onOpenChangeStatusForm}
        className={`${ACTION_BUTTON_TYPES.EDIT_PART_NUMBER_STATUS} ${isInvisible} part-number-edit-icon`}
        src="/icon/ic-edit.svg"
      />
    );
  }, [isEditable]);

  return (
    <div className="part-number-status-wrapper">
      <EditStatusForm
        currentInitialValue={partNumber.status}
        field="status"
        isEditable={isEditable}
        onSaveChangesHandler={onEditStatusSaveChangesHandler}
        onCancelChangesHandler={onCancelChangesHandler}
        selectList={PART_NUMBER_STATUS}
        displaySelectItemName={displayStatus}
        form={form}
        classActionTypes={ACTION_BUTTON_TYPES.EDIT_PART_NUMBER_STATUS}
      />
      {renderEditButton()}
    </div>
  );
};

UpdatePartNumberStatus.propTypes = {
  partNumber: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({}).isRequired
};

const UpdatePartNumberStatusForm = Form.create({
  name: 'update-part-number-status'
})(UpdatePartNumberStatus);

export default UpdatePartNumberStatusForm;
