import React, { useEffect, useRef } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';
import GeneratedFileTitleDateHeader from '../Util/GeneratedFileTitleDateHeader';
import GeneratedFormulaFileMetaDataTable from './GeneratedFormulaFileMetaDataTable';
import GeneratedFormulaFileRawMaterialTable from './GeneratedFormulaFileRawMaterialTable';
import GeneratedFileUtilDetergentMetaData from '../Util/GeneratedFileUtilDetergentMetaData';
import GeneratedFileUtilDetergentProductionInstruction from '../Util/GeneratedFileUtilDetergentProductionInstruction';
import '../index.scss';
import {
  addDynamicTable,
  addElementToPdf,
  addNextElementToPdf,
  parsePixelToPoint,
  addElementOnlyAfterDynamicTable
} from '../../util/pdf';
import { backgroundFillCell } from '../../util/constant';
import {
  metaDataTableColumnWidth,
  metaDataTableId,
  rawMaterialTableId,
  rawMaterialTableColumnWidth
} from './constant';
import { roundDecimalNumber } from '../../../../../../../utils/common';
import {
  customMetaDataTableStyles,
  customRawMaterialTableStyles
} from '../../util/util';

const GeneratedFormulaFile = ({
  isRenderPdfNow,
  onPDFRenderedComplete,
  pdfRenderer,
  componentProps
}) => {
  const { detergent, ratios } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent,
    ratios: state.detergent.precalculationForm.fetchedList
  }));

  const formulaInfoContainer = useRef();
  const formulaTitleTypography = useRef();
  const sumRatiosRef = useRef();
  const detergentProductionInstruction = useRef();

  useEffect(() => {
    async function render() {
      const { canvas } = await addElementToPdf(
        pdfRenderer,
        formulaInfoContainer.current
      );

      addDynamicTable(pdfRenderer, {
        id: metaDataTableId,
        y: parsePixelToPoint(canvas.height),
        headStyles: backgroundFillCell,
        columnStyles: metaDataTableColumnWidth,
        willDrawCell: data => customMetaDataTableStyles(data)
      });

      await addElementOnlyAfterDynamicTable(
        pdfRenderer,
        formulaTitleTypography.current
      );

      addDynamicTable(pdfRenderer, {
        id: rawMaterialTableId,
        y:
          pdfRenderer.lastAutoTable.finalY +
          parsePixelToPoint(formulaTitleTypography.current.offsetHeight),
        headStyles: backgroundFillCell,
        columnStyles: rawMaterialTableColumnWidth,
        willDrawCell: data => customRawMaterialTableStyles(data)
      });

      await addElementOnlyAfterDynamicTable(pdfRenderer, sumRatiosRef.current);

      if (!componentProps.isExternal) {
        await addNextElementToPdf(
          pdfRenderer,
          detergentProductionInstruction.current,
          {
            y:
              pdfRenderer.lastAutoTable.finalY +
              parsePixelToPoint(sumRatiosRef.current.offsetHeight)
          }
        );
      }

      onPDFRenderedComplete();
    }

    if (isRenderPdfNow) {
      render();
    }
  }, [isRenderPdfNow, componentProps.isExternal]);

  const sum = ratios.reduce((prev, curr) => {
    return prev + curr.ratio;
  }, 0);

  return (
    <>
      <section
        className="generated-file--padding-top"
        ref={formulaInfoContainer}>
        <GeneratedFileTitleDateHeader
          title={
            <FormattedMessage
              id={
                componentProps.isExternal
                  ? 'detergent.formulaExternal'
                  : 'detergent.formula'
              }
              defaultMessage={
                componentProps.isExternal ? 'FORMULA EXTERNAL' : 'FORMULA'
              }
            />
          }
        />
        <hr />
        <section className="generated-file-aligned-content">
          <GeneratedFileUtilDetergentMetaData detergent={detergent} />
        </section>
      </section>

      <GeneratedFormulaFileMetaDataTable detergent={detergent} />
      <section ref={formulaTitleTypography}>
        <section className="generated-file-aligned-content">
          <Typography.Title level={4} className="formula-title">
            <strong>
              <FormattedMessage id="formula.title" defaultMessage="Formula" />:
            </strong>
          </Typography.Title>
        </section>
      </section>
      <GeneratedFormulaFileRawMaterialTable ratios={ratios} />
      <section ref={sumRatiosRef} className="ratios-sum--wrapper">
        <div className="ratios-sum--content">
          <div className="ratios-sum">
            <span className="ratios-sum--text">
              <FormattedMessage id="detergent.sum" defaultMessage="Sum" />:{' '}
            </span>
            <strong className="ratios-sum--value">
              {roundDecimalNumber(sum * 100, 4)}
            </strong>
          </div>
        </div>
      </section>
      <section
        className="generated-file-aligned-content"
        ref={detergentProductionInstruction}>
        <GeneratedFileUtilDetergentProductionInstruction
          productionInstruction={detergent.formulas?.[0]?.productionInstruction}
        />
        <Typography.Title level={4} className="formula-detergent-properties">
          <strong>
            <FormattedMessage
              id="detergent.properties"
              defaultMessage="Detergent properties"
            />
            :
          </strong>
        </Typography.Title>
        <p className="formula-detergent-properties-text">
          {detergent.formulas?.[0]?.propertyDescription}
        </p>
      </section>
    </>
  );
};

GeneratedFormulaFile.propTypes = {
  isRenderPdfNow: PropTypes.bool,
  onPDFRenderedComplete: PropTypes.func.isRequired,
  pdfRenderer: PropTypes.instanceOf(jsPDF),
  componentProps: PropTypes.shape({
    isExternal: PropTypes.bool
  }).isRequired
};

GeneratedFormulaFile.defaultProps = {
  isRenderPdfNow: false,
  pdfRenderer: null
};

export default GeneratedFormulaFile;
