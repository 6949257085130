import {
  REQUEST_PRESIGNED_URL_AND_UPLOAD,
  REQUEST_PRESIGNED_URL_AND_UPLOAD_ERROR,
  REQUEST_PRESIGNED_URL_AND_UPLOAD_SUCCESS,
  QUEUE_EMAIL,
  QUEUE_EMAIL_SUCCESS,
  QUEUE_EMAIL_ERROR,
  CLEAR_PROCUREMENT_EMAIL
} from './action';

const getInitialState = () => {
  return {
    uploadedGeneratedFiles: [],
    failedUploadedGeneratedFiles: [],
    isQueuedSuccessful: null
  };
};

export default function reducer(state = getInitialState(), action) {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_PRESIGNED_URL_AND_UPLOAD:
      return { ...state, failedUploadedGeneratedFiles: [] };
    case REQUEST_PRESIGNED_URL_AND_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadedGeneratedFiles: [...state.uploadedGeneratedFiles, payload]
      };
    case REQUEST_PRESIGNED_URL_AND_UPLOAD_ERROR:
      return {
        ...state,
        failedUploadedGeneratedFiles: [
          ...state.failedUploadedGeneratedFiles,
          payload
        ]
      };
    case QUEUE_EMAIL:
      return {
        ...state,
        failedUploadedGeneratedFiles: [],
        isQueuedSuccessful: null
      };
    case QUEUE_EMAIL_SUCCESS:
      return {
        ...state,
        isQueuedSuccessful: true
      };
    case QUEUE_EMAIL_ERROR:
      return {
        ...state,
        isQueuedSuccessful: false
      };
    case CLEAR_PROCUREMENT_EMAIL:
      return {
        ...getInitialState()
      };
    default:
      return state;
  }
}
