import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { OverlayLoading } from '../../../../../components/Loading';
import { getRawMaterialByFormulasCompare } from '../../../data/detergent/actions';
import { ViewCompareDetail } from './ViewCompareDetail';

const _CompareDetergentModal = ({ form, onCancel }) => {
  const dispatch = useDispatch();
  const {
    isFetchingRawMaterialByFormulaId,
    compareDetergents,
    compareRawMaterials
  } = useSelector(state => ({
    isFetchingRawMaterialByFormulaId:
      state.detergent.compareDetergents.fetching,
    compareDetergents: state.detergent.compareDetergents.detergents,
    compareRawMaterials: state.detergent.compareDetergents.rawMaterials
  }));

  const compareFormulasIds = useMemo(
    () => compareDetergents.map(record => record.formulas[0].id),
    [compareDetergents]
  );

  const mappedCompareDetergents = useMemo(() => {
    // check empty compareRawMaterials
    if (
      Object.keys(compareRawMaterials).length === 0 &&
      compareRawMaterials.constructor === Object
    ) {
      return compareDetergents;
    }
    const detergents = [...compareDetergents];
    detergents.forEach(detergent => {
      if (compareRawMaterials.has(detergent.formulas[0].id)) {
        detergent.rawMaterials = compareRawMaterials.get(
          detergent.formulas[0].id
        );
      }
    });

    return detergents;
  }, [compareDetergents, compareRawMaterials]);

  useEffect(() => {
    dispatch(getRawMaterialByFormulasCompare(compareFormulasIds));
  }, []);

  return (
    <OverlayLoading loading={isFetchingRawMaterialByFormulaId}>
      <Form className="detergent-detail">
        <div className="compare-detergents-modal">
          {mappedCompareDetergents.map(record => (
            <ViewCompareDetail
              key={`compare-detergents-${record.id}`}
              isLoading={isFetchingRawMaterialByFormulaId}
              form={form}
              detergentData={record}
              rawMaterialData={record?.rawMaterials?.results}
            />
          ))}
        </div>
        <div className="form-btn-actions">
          <Button className="btn-save" onClick={onCancel}>
            <FormattedMessage id="common.close" defaultMessage="CLOSE" />
          </Button>
        </div>
      </Form>
    </OverlayLoading>
  );
};

_CompareDetergentModal.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired
};

export const CompareDetergentModal = Form.create({
  name: 'compare-detergents-form'
})(_CompareDetergentModal);
