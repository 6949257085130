import { put, takeLatest } from 'redux-saga/effects';
import { getProductionCycleApi } from './api';
import {
  GET_PRODUCTION_CYCLE,
  getProductionCycleSuccess,
  getProductionCycleError
} from './action';
import { errorHandle } from '../../../../utils/common';

export function* getProductionCycleSaga(action) {
  try {
    const response = yield getProductionCycleApi({
      pagination: action.payload.pagination,
      filter: JSON.stringify(action.payload.filterList || ''),
      order: JSON.stringify(action.payload.order)
    });
    yield put(getProductionCycleSuccess(response.data));
  } catch (error) {
    yield put(getProductionCycleError(error));
    errorHandle(error);
  }
}

export default function* productionCyclesSaga() {
  yield takeLatest(GET_PRODUCTION_CYCLE, getProductionCycleSaga);
}
