import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes, defaultProps } from './detergentPropType';

const GeneratedFileUtilDetergentMetaData = ({ detergent }) => {
  return (
    <>
      <section>
        <span>
          <FormattedMessage
            id="detergent.number"
            defaultMessage="Detergent Number"
          />
          : <strong> {detergent.number} </strong>
        </span>
        <span className="formula-version">
          <FormattedMessage
            id="formula.version"
            defaultMessage="Formula Version"
          />
          : <strong> {detergent.formulas?.[0]?.version} </strong>
        </span>
      </section>
      <p>
        <FormattedMessage id="detergent.name" defaultMessage="Detergent Name" />
        :
        <strong className="detergent-name-label-pdf"> {detergent.name} </strong>
      </p>
    </>
  );
};

GeneratedFileUtilDetergentMetaData.propTypes = propTypes;

GeneratedFileUtilDetergentMetaData.defaultProps = defaultProps;

export default GeneratedFileUtilDetergentMetaData;
