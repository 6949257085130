import { AutoComplete, Icon } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export const AutoCompleteSAPPrice = props => {
  const {
    isFetching,
    formatMessage,
    getSAPPrice,
    onSelected,
    filterableSapPrices,
    renderSAPPriceOption,
    onChange
  } = props;

  return (
    <AutoComplete
      dropdownClassName="style-autocomplete"
      getPopupContainer={() => document.getElementById('parent-node')}
      style={{ width: 150 }}
      dataSource={filterableSapPrices.map(renderSAPPriceOption)}
      optionLabelProp="display"
      notFoundContent={
        isFetching ? (
          <Icon type="sync" spin />
        ) : (
          formatMessage({
            id: 'common.nodata',
            defaultMessage: 'No data'
          })
        )
      }
      onFocus={getSAPPrice}
      onSelect={onSelected}
      onChange={onChange}
      {...props}
    />
  );
};

AutoCompleteSAPPrice.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func.isRequired,
  getSAPPrice: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  filterableSapPrices: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSAPPriceOption: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
