import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { ACTION_BUTTON_TYPES } from '../../../../../configs/constant';
import { getFullNameOfCognitoUser } from '../../../../../utils/common';

const ConfirmChangeStatusDialog = props => {
  const { status, record } = props;
  const warningMessage =
    status === ACTION_BUTTON_TYPES.ACTIVATE_USER
      ? {
          id: 'userManagement.modal.activate.content',
          defaultMessage:
            'will be activated and the user can access to the database.'
        }
      : {
          id: 'userManagement.modal.deactivate.content',
          defaultMessage:
            'will be deactivated and the user has no longer access to the database.'
        };

  return (
    <span>
      <p className="content">
        <Icon type="warning" className="warning-icon" />
        <span>
          <span style={{ textTransform: 'capitalize' }}>
            <b>“{getFullNameOfCognitoUser(record)}” </b>
          </span>
          <span>
            <FormattedMessage
              id={warningMessage.id}
              defaultMessage={warningMessage.defaultMessage}
            />
          </span>
        </span>
      </p>
      <p className="content">
        <span>
          <FormattedMessage
            id="common.modal.deactivate.confirmMessage"
            defaultMessage="Are you sure you want to continue?"
          />
        </span>
      </p>
    </span>
  );
};

ConfirmChangeStatusDialog.propTypes = {
  record: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};

export default ConfirmChangeStatusDialog;
