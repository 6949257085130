import { all } from 'redux-saga/effects';

/** * sagas */
import { rawMaterialProducerSaga } from '../containers/RawMaterialProducer/data/rawMaterialProducers/saga';
import rawMaterialSaga from '../containers/RawMaterial/data/rawMaterial/saga';
import rawMaterialGroupSaga from '../containers/RawMaterialGroup/data/rawMaterialGroup/saga';
import detergentSaga from '../containers/Detergent/data/detergent/saga';
import detergentProducerSaga from '../containers/DetergentProducers/data/detergentProducers/saga';
import partNumberSaga from '../containers/PartNumbers/data/partNumbers/saga';
import userManagementSaga from '../containers/UserManagement/data/userManagement/saga';
import logsSaga from '../containers/Logs/data/logs/saga';
import countriesSaga from '../data/countries/saga';
import detergentNonPagingSaga from '../data/detergentNonPaging/saga';
import detergentProducerNonPagingSaga from '../data/detergentProducerNonPaging/saga';
import partNumberNonPagingSaga from '../data/partNumberNonPaging/saga';
import procurementEmailSaga from '../containers/Detergent/components/Modal/ProcurementEmailFileSelectorModal/state/saga';
import languageSaga from '../data/language/saga';
import sapPriceSaga from '../data/sap/saga';
import containerTypeSaga from '../containers/ContainerType/data/containerType/saga';
import productionCyclesSaga from '../containers/ProductionCycle/data/productionCycles/saga';
import universalSearchSaga from '../data/universalSearch/saga';

export default function* rootSaga() {
  yield all([
    rawMaterialProducerSaga(),
    rawMaterialSaga(),
    rawMaterialGroupSaga(),
    countriesSaga(),
    detergentSaga(),
    detergentProducerSaga(),
    partNumberSaga(),
    detergentNonPagingSaga(),
    detergentProducerNonPagingSaga(),
    partNumberNonPagingSaga(),
    userManagementSaga(),
    procurementEmailSaga(),
    logsSaga(),
    languageSaga(),
    sapPriceSaga(),
    containerTypeSaga(),
    productionCyclesSaga(),
    universalSearchSaga()
  ]);
}
