import { USER_ROLES } from '../../configs/constant';

const LOCAL_STORAGE_COGNITO_TOKEN = 'cognitoToken';
const LOCAL_STORAGE_COGNITO_ROLES = 'cognitoRoles';
const LOCAL_STORAGE_COGNITO_EXPIRATION_TIME = 'cognitoExpirationTime';
const LOCAL_STORAGE_COGNITO_LANG = 'cognitoLang';

export const setCognitoToken = (token = '') => {
  localStorage.setItem(LOCAL_STORAGE_COGNITO_TOKEN, token);
};

export const setCognitoRoles = (roles = []) => {
  localStorage.setItem(LOCAL_STORAGE_COGNITO_ROLES, roles.join(','));
};

export const setCognitoExpirationTime = expirationTime => {
  localStorage.setItem(LOCAL_STORAGE_COGNITO_EXPIRATION_TIME, expirationTime);
};

export const setCognitoLang = (lang = '') => {
  localStorage.setItem(LOCAL_STORAGE_COGNITO_LANG, lang);
};

export const getCognitoInfo = () => {
  const roles = localStorage.getItem(LOCAL_STORAGE_COGNITO_ROLES) || '';

  return {
    token: localStorage.getItem(LOCAL_STORAGE_COGNITO_TOKEN),
    roles: roles.split(',').filter(e => !!e),
    expirationTime:
      parseFloat(localStorage.getItem(LOCAL_STORAGE_COGNITO_EXPIRATION_TIME)) ||
      0,
    lang: localStorage.getItem(LOCAL_STORAGE_COGNITO_LANG)
  };
};

export const isAdminUser = () => {
  const { roles } = getCognitoInfo();

  return roles.includes(USER_ROLES.Administrator);
};

export const clearCognitoInfo = () => {
  localStorage.removeItem(LOCAL_STORAGE_COGNITO_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_COGNITO_ROLES);
  localStorage.removeItem(LOCAL_STORAGE_COGNITO_EXPIRATION_TIME);
  localStorage.removeItem(LOCAL_STORAGE_COGNITO_LANG);
};
