import './GeneratingFileSelectorModal.scss';
import GeneratedFormulaFile from './GeneratedFileOption/GeneratedFormulaFile/index';
import BlankMeasurementDataSheetOne from './GeneratedFileOption/BlankMeasurementDataSheet';
import FilledMeasurementDataSheetOne from './GeneratedFileOption/FilledMeasurementDataSheet';
import ProductionTestProtocol from './GeneratedFileOption/ProductionProtocol/ProductionTestProtocol';
import InitialProductionTestProtocol from './GeneratedFileOption/ProductionProtocol/InitialProductionTestProtocol';

/**
 *
 * @param {*} React
 * @returns {
 *  Array<{
 *    isSelected: Boolean,
 *    formattedMessageId: String,
 *    defaultMessage: String,
 *    isRendered: Boolean,
 *    components: Array<Component>,
 *    componentProps: Object<any>
 *    fileName: String
 *  }>
 * }
 */
export const getGeneratingEditableFileSelectorModalInitialState = React =>
  [
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.productionTestProtocol',
      defaultMessage: 'Production test protocol (*.pdf)',
      isRendered: false,
      components: [ProductionTestProtocol],
      fileName: 'ProductionTestProtocol'
    },
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.initialProductionTestProtocol',
      defaultMessage: 'Initial production test protocol (*.pdf)',
      isRendered: false,
      components: [InitialProductionTestProtocol],
      fileName: 'InitialProductionTestProtocol'
    }
  ].map(item => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

/**
 *
 * @param {*} React
 * @returns {
 *  Array<{
 *    isSelected: Boolean,
 *    formattedMessageId: String,
 *    defaultMessage: String,
 *    isRendered: Boolean,
 *    components: Array<Component>,
 *    componentProps: Object<any>
 *    fileName: String
 *  }>
 * }
 */
export const getGeneratingUneditableFileSelectorModalInitialState = React =>
  [
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.formula',
      defaultMessage: 'Formula (*.pdf)',
      isRendered: false,
      components: [GeneratedFormulaFile],
      componentProps: {
        isExternal: false
      },
      fileName: 'Formula'
    },
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.formulaExternal',
      defaultMessage: 'Formula external (*.pdf)',
      isRendered: false,
      components: [GeneratedFormulaFile],
      componentProps: {
        isExternal: true
      },
      fileName: 'FormulaExternal'
    },
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.filledMeasurementData',
      defaultMessage: 'Measurement data - filled out (*.pdf)',
      isRendered: false,
      components: [FilledMeasurementDataSheetOne],
      fileName: 'FilledMeasurement'
    },
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.blankMeasurementData',
      defaultMessage: 'Measurement data - blanked (*.pdf)',
      isRendered: false,
      components: [BlankMeasurementDataSheetOne],
      fileName: 'BlankMeasurement',
    },
  ].map((item) => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

export const getGeneratingProductionTestProtocolFileSelectorModalInitialState = (
  React
) =>
  [
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.productionTestProtocol',
      defaultMessage: 'Production test protocol',
      isRendered: false,
      components: [ProductionTestProtocol],
      fileName: 'ProductionTestProtocol',
    },
  ].map((item) => {
    return {
      ...item,
      refs: item.components.map(() => React.createRef()),
    };
  });

export const getGeneratingInitialProductionTestProtocolFileSelectorModalInitialState = (
  React
) =>
  [
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.initialProductionTestProtocol',
      defaultMessage: 'Initial production test protocol',
      isRendered: false,
      components: [InitialProductionTestProtocol],
      fileName: 'InitialProductionTestProtocol',
    },
  ].map((item) => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

export const getGeneratingFormulaFileSelectorModalInitialState = (React) =>
  [
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.formula',
      defaultMessage: 'Formula',
      isRendered: false,
      components: [GeneratedFormulaFile],
      componentProps: {
        isExternal: false,
      },
      fileName: 'Formula',
    },
  ].map((item) => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

export const getGeneratingFormulaExternalFileSelectorModalInitialState = (
  React
) =>
  [
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.formulaExternal',
      defaultMessage: 'Formula external',
      isRendered: false,
      components: [GeneratedFormulaFile],
      componentProps: {
        isExternal: true,
      },
      fileName: 'FormulaExternal',
    },
  ].map((item) => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

export const getGeneratingMeasurementDataFilledOutFileSelectorModalInitialState = (
  React
) =>
  [
    {
      isSelected: false,
      formattedMessageId:
        'detergent.generatingFileOption.filledMeasurementData',
      defaultMessage: 'Measurement data - filled out',
      isRendered: false,
      components: [FilledMeasurementDataSheetOne],
      fileName: 'FilledMeasurement',
    },
  ].map((item) => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });

export const getGeneratingMeasurementDataBlankedFileSelectorModalInitialState = (
  React
) =>
  [
    {
      isSelected: false,
      formattedMessageId: 'detergent.generatingFileOption.blankMeasurementData',
      defaultMessage: 'Measurement data - blanked',
      isRendered: false,
      components: [BlankMeasurementDataSheetOne],
      fileName: 'BlankMeasurement'
    }
  ].map(item => {
    return { ...item, refs: item.components.map(() => React.createRef()) };
  });
