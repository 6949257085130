export const GET_RAW_MATERIALS = 'GET_RAW_MATERIALS';
export const GET_RAW_MATERIALS_SUCCESS = 'GET_RAW_MATERIALS_SUCCESS';
export const GET_RAW_MATERIALS_ERROR = 'GET_RAW_MATERIALS_ERROR';

export const CREATE_RAW_MATERIAL = 'CREATE_RAW_MATERIAL';
export const CREATE_RAW_MATERIAL_SUCCESS = 'CREATE_RAW_MATERIAL_SUCCESS';
export const CREATE_RAW_MATERIAL_ERROR = 'CREATE_RAW_MATERIAL_ERROR';

export const UPDATE_RAW_MATERIAL = 'UPDATE_RAW_MATERIAL';
export const UPDATE_RAW_MATERIAL_SUCCESS = 'UPDATE_RAW_MATERIAL_SUCCESS';
export const UPDATE_RAW_MATERIAL_ERROR = 'UPDATE_RAW_MATERIAL_ERROR';

export const GET_RAW_MATERIAL_BY_ID = 'GET_RAW_MATERIAL_BY_ID';
export const GET_RAW_MATERIAL_BY_ID_SUCCESS = 'GET_RAW_MATERIAL_BY_ID_SUCCESS';
export const GET_RAW_MATERIAL_BY_ID_ERROR = 'GET_RAW_MATERIAL_BY_ID_ERROR';

export const BLOCK_RAW_MATERIAL = 'BLOCK_RAW_MATERIAL';
export const BLOCK_RAW_MATERIAL_SUCCESS = 'BLOCK_RAW_MATERIAL_SUCCESS';
export const BLOCK_RAW_MATERIAL_ERROR = 'BLOCK_RAW_MATERIAL_ERROR';

export const GET_RAW_MATERIAL_HISTORY = 'GET_RAW_MATERIAL_HISTORY';
export const GET_RAW_MATERIAL_HISTORY_SUCCESS =
  'GET_RAW_MATERIAL_HISTORY_SUCCESS';
export const GET_RAW_MATERIAL_HISTORY_ERROR = 'GET_RAW_MATERIAL_HISTORY_ERROR';

export const GET_ALL_RAW_MATERIAL = 'GET_ALL_RAW_MATERIAL';
export const GET_ALL_RAW_MATERIAL_SUCCESS = 'GET_ALL_RAW_MATERIAL_SUCCESS';
export const GET_ALL_RAW_MATERIAL_ERROR = 'GET_ALL_RAW_MATERIAL_ERROR';

export const RAW_MATERIAL_MODAL = 'RAW_MATERIAL_MODAL';
export const CLEAR_RAW_MATERIAL_DATA_WHEN_UNMOUNT =
  'CLEAR_RAW_MATERIAL_DATA_WHEN_UNMOUNT';

export const GET_DETERGENT_BY_RAW_MATERIAL_ID =
  'GET_DETERGENT_BY_RAW_MATERIAL_ID';
export const GET_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS =
  'GET_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS';
export const GET_DETERGENT_BY_RAW_MATERIAL_ID_ERROR =
  'GET_DETERGENT_BY_RAW_MATERIAL_ID_ERROR';
export const CLEAR_DETERGENT_DATA_BY_RAW_MATERIAL_ID_WHEN_UNMOUNT =
  'CLEAR_DETERGENT_DATA_BY_RAW_MATERIAL_ID_WHEN_UNMOUNT';

export const SET_LOADING_RAW_MATERIAL = 'SET_LOADING_RAW_MATERIAL';
export const CHECK_DETERGENT_FORMULA_BLOCK = 'CHECK_DETERGENT_FORMULA_BLOCK';
export const CHECK_DETERGENT_FORMULA_BLOCK_SUCCESS =
  'CHECK_DETERGENT_FORMULA_BLOCK_SUCCESS';
export const CHECK_DETERGENT_FORMULA_BLOCK_ERROR =
  'CHECK_DETERGENT_FORMULA_BLOCK_ERROR';
export const RAW_MATERIAL_FETCH_FLAG = 'RAW_MATERIAL_FETCH_FLAG';

export const EXPORT_DETERGENT_BY_RAW_MATERIAL_ID =
  'EXPORT_DETERGENT_BY_RAW_MATERIAL_ID';
export const EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS =
  'EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS';
export const EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_ERROR =
  'EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_ERROR';

// Fetch raw materials
export const getRawMaterials = payload => ({
  type: GET_RAW_MATERIALS,
  payload
});

export const getRawMaterialsSuccess = payload => ({
  type: GET_RAW_MATERIALS_SUCCESS,
  payload
});

export const getRawMaterialsError = payload => ({
  type: GET_RAW_MATERIALS_ERROR,
  payload
});

// Create raw material
export const createRawMaterial = payload => ({
  type: CREATE_RAW_MATERIAL,
  payload
});

export const createRawMaterialSuccess = payload => ({
  type: CREATE_RAW_MATERIAL_SUCCESS,
  payload
});

export const createRawMaterialError = payload => ({
  type: CREATE_RAW_MATERIAL_ERROR,
  payload
});

// Update raw material
export const updateRawMaterial = payload => ({
  type: UPDATE_RAW_MATERIAL,
  payload
});

export const updateRawMaterialSuccess = payload => ({
  type: UPDATE_RAW_MATERIAL_SUCCESS,
  payload
});

export const updateRawMaterialError = payload => ({
  type: UPDATE_RAW_MATERIAL_ERROR,
  payload
});

// get raw material by id
export const getRawMaterialsById = payload => ({
  type: GET_RAW_MATERIAL_BY_ID,
  payload
});

export const getRawMaterialsByIdSuccess = payload => ({
  type: GET_RAW_MATERIAL_BY_ID_SUCCESS,
  payload
});

export const getRawMaterialsByIdError = payload => ({
  type: GET_RAW_MATERIAL_BY_ID_ERROR,
  payload
});

// Block Raw Material
export const blockRawMaterial = payload => ({
  type: BLOCK_RAW_MATERIAL,
  payload
});

export const blockRawMaterialSuccess = payload => ({
  type: BLOCK_RAW_MATERIAL_SUCCESS,
  payload
});

export const blockRawMaterialError = payload => ({
  type: BLOCK_RAW_MATERIAL_ERROR,
  payload
});

// get raw material history
export const getRawMaterialHistory = payload => ({
  type: GET_RAW_MATERIAL_HISTORY,
  payload
});

export const getRawMaterialHistorySuccess = payload => ({
  type: GET_RAW_MATERIAL_HISTORY_SUCCESS,
  payload
});

export const getRawMaterialHistoryError = payload => ({
  type: GET_RAW_MATERIAL_HISTORY_ERROR,
  payload
});

// Open Modal
export const processRawMaterialModal = payload => ({
  type: RAW_MATERIAL_MODAL,
  payload
});

// GET_ALL
export const getAllRawMaterial = () => ({
  type: GET_ALL_RAW_MATERIAL
});

export const getAllRawMaterialSuccess = payload => ({
  type: GET_ALL_RAW_MATERIAL_SUCCESS,
  payload
});

export const getAllRawMaterialError = payload => ({
  type: GET_ALL_RAW_MATERIAL_ERROR,
  payload
});

export const clearRawMaterialDataWhenUnmount = () => ({
  type: CLEAR_RAW_MATERIAL_DATA_WHEN_UNMOUNT
});

// GET_DETERGENT_BY_RAW_MATERIAL_ID
export const getDetergentByRawMaterialId = payload => ({
  type: GET_DETERGENT_BY_RAW_MATERIAL_ID,
  payload
});

export const getDetergentByRawMaterialIdSuccess = payload => ({
  type: GET_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS,
  payload
});

export const getDetergentByRawMaterialIdError = payload => ({
  type: GET_DETERGENT_BY_RAW_MATERIAL_ID_ERROR,
  payload
});

export const clearDetergentByRawMaterialIdWhenUnmount = () => ({
  type: CLEAR_DETERGENT_DATA_BY_RAW_MATERIAL_ID_WHEN_UNMOUNT
});

export const setLoadingRawMaterial = payload => ({
  type: SET_LOADING_RAW_MATERIAL,
  payload
});

// CHECK DETERGENT FORMULA BLOCK
export const checkDetergentFormulaBlock = payload => ({
  type: CHECK_DETERGENT_FORMULA_BLOCK,
  payload
});

export const checkDetergentFormulaBlockSuccess = payload => ({
  type: CHECK_DETERGENT_FORMULA_BLOCK_SUCCESS,
  payload
});

export const checkDetergentFormulaBlockError = payload => ({
  type: CHECK_DETERGENT_FORMULA_BLOCK_ERROR,
  payload
});

export const rawMaterialFetchFlag = payload => ({
  type: RAW_MATERIAL_FETCH_FLAG,
  payload
});

export const exportDetergentByRawMaterialId = rawMaterialId => ({
  type: EXPORT_DETERGENT_BY_RAW_MATERIAL_ID,
  payload: { rawMaterialId }
});

export const exportDetergentByRawMaterialIdSuccess = payload => ({
  type: EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_SUCCESS,
  payload
});

export const exportDetergentByRawMaterialIdError = payload => ({
  type: EXPORT_DETERGENT_BY_RAW_MATERIAL_ID_ERROR,
  payload
});
