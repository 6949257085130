import { WebStorageStateStore } from 'oidc-client';
import configs from '../../configs';

const { protocol, hostname, port } = window.location;
const location = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const authConfig = {
  client_id: configs.authClientId,
  redirect_uri: `${location}/callback`,
  post_logout_redirect_uri: `${location}`,
  response_type: 'code',
  scope: 'openid profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: `https://cognito-idp.eu-central-1.amazonaws.com/${configs.poolId}`,
  silent_redirect_uri: `${location}/silent_renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
};

export default authConfig;
