export const GET_RAW_MATERIAL_PRODUCERS = "GET_RAW_MATERIAL_PRODUCERS";
export const GET_RAW_MATERIAL_PRODUCERS_SUCCESS =
  "GET_RAW_MATERIAL_PRODUCERS_SUCCESS";
export const GET_RAW_MATERIAL_PRODUCERS_ERROR =
  "GET_RAW_MATERIAL_PRODUCERS_ERROR";

export const CREATE_RAW_MATERIAL_PRODUCER = "CREATE_RAW_MATERIAL_PRODUCER";
export const CREATE_RAW_MATERIAL_PRODUCER_SUCCESS =
  "CREATE_RAW_MATERIAL_PRODUCER_SUCCESS";
export const CREATE_RAW_MATERIAL_PRODUCER_ERROR =
  "CREATE_RAW_MATERIAL_PRODUCER_ERROR";

export const UPDATE_RAW_MATERIAL_PRODUCER = "UPDATE_RAW_MATERIAL_PRODUCER";
export const UPDATE_RAW_MATERIAL_PRODUCER_SUCCESS =
  "UPDATE_RAW_MATERIAL_PRODUCER_SUCCESS";
export const UPDATE_RAW_MATERIAL_PRODUCER_ERROR =
  "UPDATE_RAW_MATERIAL_PRODUCER_ERROR";

export const GET_ALL_RAW_MATERIAL_PRODUCERS = "GET_ALL_RAW_MATERIAL_PRODUCERS";
export const GET_ALL_RAW_MATERIAL_PRODUCERS_SUCCESS =
  "GET_ALL_RAW_MATERIAL_PRODUCERS_SUCCESS";
export const GET_ALL_RAW_MATERIAL_PRODUCERS_ERROR =
  "GET_ALL_RAW_MATERIAL_PRODUCERS_ERROR";

export const CLEAR_RAW_MATERIAL_PRODUCER_DATA_WHEN_UNMOUNT =
  "CLEAR_RAW_MATERIAL_PRODUCER_DATA_WHEN_UNMOUNT";

export const SET_LOADING_RAW_MATERIAL_PRODUCER =
  "SET_LOADING_RAW_MATERIAL_PRODUCER";
export const TOGGLE_RAW_MATERIAL_PRODUCER_MODAL =
  "TOGGLE_RAW_MATERIAL_PRODUCER_MODAL";

//Fetch List Producers
export const getRawMaterialProducers = payload => ({
  type: GET_RAW_MATERIAL_PRODUCERS,
  payload
});

export const getRawMaterialProducersSuccess = payload => ({
  type: GET_RAW_MATERIAL_PRODUCERS_SUCCESS,
  payload
});

export const getRawMaterialProducersError = payload => ({
  type: GET_RAW_MATERIAL_PRODUCERS_ERROR,
  payload
});

//Create Producer
export const createRawMaterialProducer = payload => ({
  type: CREATE_RAW_MATERIAL_PRODUCER,
  payload
});

export const createRawMaterialProducerSuccess = payload => ({
  type: CREATE_RAW_MATERIAL_PRODUCER_SUCCESS,
  payload
});

export const createRawMaterialProducerError = payload => ({
  type: CREATE_RAW_MATERIAL_PRODUCER_ERROR,
  payload
});

//Update
export const updateRawMaterialProducer = payload => ({
  type: UPDATE_RAW_MATERIAL_PRODUCER,
  payload
});

export const updateRawMaterialProducerSuccess = payload => ({
  type: UPDATE_RAW_MATERIAL_PRODUCER_SUCCESS,
  payload
});

export const updateRawMaterialProducerError = payload => ({
  type: UPDATE_RAW_MATERIAL_PRODUCER_ERROR,
  payload
});

//Open Modal
export const processProducerModal = payload => ({
  type: TOGGLE_RAW_MATERIAL_PRODUCER_MODAL,
  payload
});

//Fetch All Producers
export const getAllRawMaterialProducer = () => ({
  type: GET_ALL_RAW_MATERIAL_PRODUCERS
});

export const getAllRawMaterialProducerSuccess = payload => ({
  type: GET_ALL_RAW_MATERIAL_PRODUCERS_SUCCESS,
  payload
});

export const getAllRawMaterialProducerError = payload => ({
  type: GET_ALL_RAW_MATERIAL_PRODUCERS_ERROR,
  payload
});

export const clearRawMaterialProducerDataWhenUnmount = () => ({
  type: CLEAR_RAW_MATERIAL_PRODUCER_DATA_WHEN_UNMOUNT
});

export const setLoadingRawMaterialProducer = payload => ({
  type: SET_LOADING_RAW_MATERIAL_PRODUCER,
  payload
});
