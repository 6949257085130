export const GET_DETERGENT_PRODUCER_NON_PAGING =
  'GET_DETERGENT_PRODUCER_NON_PAGING';
export const GET_DETERGENT_PRODUCER_NON_PAGING_SUCCESS =
  'GET_DETERGENT_PRODUCER_NON_PAGING_SUCCESS';
export const GET_DETERGENT_PRODUCER_NON_PAGING_ERROR =
  'GET_DETERGENT_PRODUCER_NON_PAGING_ERROR';

// Detergent non paging
export const getDetergentProducerNonPaging = () => ({
  type: GET_DETERGENT_PRODUCER_NON_PAGING
});

export const getDetergentProducerNonPagingSuccess = payload => ({
  type: GET_DETERGENT_PRODUCER_NON_PAGING_SUCCESS,
  payload
});

export const getDetergentProducerNonPagingError = payload => ({
  type: GET_DETERGENT_PRODUCER_NON_PAGING_ERROR,
  payload
});
