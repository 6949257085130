import { editableFile, uneditableFile, formula, formulaExternal, initialProductionTestProtocol, productionTestProtocol, measurementDataBlanked, measurementDataFilledOut } from './constant';

export const getInitialStates = () => ({
  rendereredCompletePdfs: [],
  renderedFile: {
    [editableFile]: false,
    [uneditableFile]: false,
    [formula]: false,
    [formulaExternal]: false,
    [initialProductionTestProtocol]: false,
    [productionTestProtocol]: false,
    [measurementDataBlanked]: false,
    [measurementDataFilledOut]: false
  },
  isHandleRenderedPDFComplete: false
});

export const ON_PDF_GENERATED_COMPLETE = 'ON_PDF_GENERATED_COMPLETE';
export const ON_CLEAR = 'ON_CLEAR';

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_CLEAR: {
      return {
        ...getInitialStates()
      };
    }
    case ON_PDF_GENERATED_COMPLETE: {
      const newRendereredCompletePdfs = [...state.rendereredCompletePdfs];
      const { rendereredCompletePdf, fileType } = payload;

      if (rendereredCompletePdf.pdfRenderer) {
        newRendereredCompletePdfs.push(rendereredCompletePdf);
      }

      const newRenderedFile = { ...state.renderedFile };
      newRenderedFile[fileType] = true;

      const isHandleRenderedPDFComplete =
        newRenderedFile[formula] && newRenderedFile[formulaExternal] && newRenderedFile[initialProductionTestProtocol] && newRenderedFile[productionTestProtocol] && newRenderedFile[measurementDataBlanked] && newRenderedFile[measurementDataFilledOut];
      return {
        ...state,
        rendereredCompletePdfs: newRendereredCompletePdfs,
        renderedFile: newRenderedFile,
        isHandleRenderedPDFComplete
      };
    }

    default:
      return state;
  }
};
