import React from 'react';
import PropType from 'prop-types';
import FileSelectorCheckBox from '../FileSelectorCheckBox';

const PrecalculationDownLoadCheckBox = ({
  isPrecalculationSelected,
  setIsPrecalculationSelected,
  isOkClicked
}) => {
  return (
    <FileSelectorCheckBox
      defaultMessage="Precalculation *.Google Sheets"
      formattedMessageId="detergent.measurement.generatingFile.precalculation"
      checked={isPrecalculationSelected}
      disabled={isOkClicked}
      onChange={() => setIsPrecalculationSelected(!isPrecalculationSelected)}
    />
  );
};

PrecalculationDownLoadCheckBox.propTypes = {
  isPrecalculationSelected: PropType.bool.isRequired,
  setIsPrecalculationSelected: PropType.func.isRequired,
  isOkClicked: PropType.bool.isRequired
};

export default PrecalculationDownLoadCheckBox;
