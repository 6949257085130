import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, Icon, Form, Input } from 'antd';
import {
  CONTAINER_TYPE,
  CONTAINER_GROUP
} from '../../../../../configs/constant';
import ValidateContainerSize from '../../../../../components/ContainerSize';

export const DummyPartNumberFields = props => {
  const {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    detergent,
    totalDummyPartNumbers
  } = props;
  const containerTypes = CONTAINER_TYPE();
  const intl = useIntl();
  const { formatMessage } = intl;

  const containerGroups = CONTAINER_GROUP(formatMessage);

  const initialPartNumberValue = [
    detergent.number,
    `V${detergent?.formulas?.[0]?.version}`,
    String(totalDummyPartNumbers + 1).padStart(3, '0')
  ].join('-');

  const isContainerTypeOthers = getFieldValue('containerType') === 'Others';

  const onSelectChange = () => {
    setFieldsValue({
      containerTypeDescription: ''
    });
  };

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.partNumber"
            defaultMessage="Part number"
          />
        }>
        {getFieldDecorator('partNumber', {
          initialValue: initialPartNumberValue
        })(<Input />)}
      </Form.Item>

      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerType"
            defaultMessage="Container type"
          />
        }>
        {getFieldDecorator('containerType', {
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'partNumber.validate.containSize',
                defaultMessage: 'Container size is required.'
              })
            }
          ]
        })(
          <Select
            suffixIcon={<Icon type="caret-down" />}
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectContainerType"
                defaultMessage="Select container type"
              />
            }
            onChange={onSelectChange}>
            {containerTypes.map(type => (
              <Select.Option key={type.id}>{type.containerType}</Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerTypeDescription"
            defaultMessage="Container type description"
          />
        }
        required={isContainerTypeOthers}>
        {getFieldDecorator('containerTypeDescription', {
          rules: [
            {
              required: isContainerTypeOthers,
              message: formatMessage({
                id: 'partNumber.validate.containTypeDescription',
                defaultMessage: 'Container type description is required'
              })
            }
          ]
        })(<Input disabled={!isContainerTypeOthers} />)}
      </Form.Item>

      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerGroup"
            defaultMessage="Container group"
          />
        }>
        {getFieldDecorator('containerGroup', {
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'partNumber.validate.containGroup',
                defaultMessage: 'Container group is required'
              })
            }
          ]
        })(
          <Select
            suffixIcon={<Icon type="caret-down" />}
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectContainerGroup"
                defaultMessage="Select container group"
              />
            }>
            {containerGroups.map(group => (
              <Select.Option key={group.value} value={group.value}>
                {group.containerGroup}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <ValidateContainerSize
        getFieldDecorator={getFieldDecorator}
        field="containerSize"
      />
    </>
  );
};

DummyPartNumberFields.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  detergent: PropTypes.objectOf(PropTypes.any).isRequired,
  totalDummyPartNumbers: PropTypes.number.isRequired
};
