import {
  GET_PRODUCTION_CYCLE,
  GET_PRODUCTION_CYCLE_SUCCESS,
  GET_PRODUCTION_CYCLE_ERROR,
  CLEAR_PRODUCTION_CYCLE_DATA_WHEN_UNMOUNT
} from './action';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    },
    fetching: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTION_CYCLE:
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          },
          fetching: true
        }
      };

    case GET_PRODUCTION_CYCLE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          fetching: false
        }
      };

    case GET_PRODUCTION_CYCLE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };

    case CLEAR_PRODUCTION_CYCLE_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
