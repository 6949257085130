import {
  put,
  takeLatest,
  all,
  call,
  takeLeading,
  takeEvery
} from 'redux-saga/effects';
import * as _ from 'lodash';
import {
  getDetergentApi,
  addDetergentApi,
  updateDetergentApi,
  addFormulaApi,
  getDetergentFormulaById,
  patchFormulaApi,
  getDetergentHistoryApi,
  getMeasurement,
  getRawMaterialByFormulaApi,
  updateRawMaterialsApi,
  putDetergentFormulaApi,
  updateMeasurementDataFieldApi,
  getDetergentProductionCycleListApi,
  createProductionCycleApi,
  createDummyPartNumberApi,
  downloadPrecalculationApi,
  updateProductionCycleApi,
  getPartNumberListByFormulaApi,
  sendEmailApi,
  createMeasurementDataFieldApi,
  deleteProductionCycleApi,
  getProductionCycleHistoryApi
} from './api';
import {
  GET_DETERGENT,
  getDetergentSuccess,
  getDetergentError,
  CREATE_DETERGENT,
  createDetergentSuccess,
  createDetergentError,
  updateDetergentSuccess,
  updateDetergentError,
  UPDATE_DETERGENT,
  CREATE_FORMULA,
  createFormulaSuccess,
  createFormulaError,
  PATCH_FORUMLA,
  patchFormulaSuccess,
  patchFormulaError,
  GET_DETERGENT_BY_ID,
  getDetergentFormulaByIdSuccess,
  getDetergentFormulaByIdError,
  getDetergentHistoryError,
  getDetergentHistorySuccess,
  GET_DETERGENT_HISTORY,
  GET_RAW_MATERIAL_BY_FORMULA,
  getRawMaterialByFormulaSuccess,
  getRawMaterialByFormulaError,
  getMeasurementSuccess,
  getMeasurementError,
  GET_MEASUREMENT,
  updateDetergentFormulaSuccess,
  updateDetergentFormulaError,
  UPDATE_DETERGENT_FORMULA,
  GET_DETERGENT_PRODUCTION_CYCLE_LIST,
  getDetergentProductionCycleListSuccess,
  getDetergentProductionCycleListError,
  CREATE_PRODUCTION_CYCLE,
  createProductionCycleSuccess,
  createProductionCycleError,
  CREATE_DUMMY_PART_NUMBER,
  createDummyPartNumberError,
  DOWNLOAD_PRECALCULATION,
  downloadPrecalculationError,
  downloadPrecalculationSucess,
  GET_PART_NUMBER_LIST_BY_FORMULA,
  getPartNumberListByFormulaSuccess,
  getPartNumberListByFormulaError,
  SEND_EMAIL_TO_SAFETY_TEAM,
  sendEmailToSafetyTeamSuccess,
  sendEmailToSafetyTeamError,
  SEND_EMAIL_TO_LABELING_TEAM,
  sendEmailToLabelingTeamSuccess,
  sendEmailToLabelingTeamError,
  UPDATE_PRODUCTION_CYCLES,
  updateProductionCyclesSuccess,
  updateProductionCyclesError,
  CHECK_RAW_MATERIAL_UNBLOCK,
  checkRawMaterialBlockSuccess,
  checkRawMaterialBlockError,
  getRawMaterialByFormulasCompareError,
  GET_RAW_MATERIAL_BY_FORMULA_COMPARE,
  getRawMaterialByFormulasCompareSuccess,
  DELETE_PRODUCTION_CYCLE,
  deleteProductionCycleSuccess,
  deleteProductionCycleError,
  GET_PRODUCTION_CYCLE_HISTORY_LIST,
  getProductionCycleHistoryByFormulaSuccess,
  getProductionCycleHistoryByFormulaError
} from './actions';

import { BASE_DB_FIELDS } from '../../../../configs/constant';
import {
  errorHandle,
  convertJsonToFormData,
  DetailsError
} from '../../../../utils/common';
import {
  showErrorToast,
  showSuccessToast
} from '../../../../services/toasterService';
import { uploadDocumentsWithCategory } from '../../../../utils/s3Service';
import { initialCreateProductionCycleData } from '../../components/Util/productionCycle/initialMasterData';
import measurementInitialState from './measurementInitialState';

export function* getDetergentSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    if (
      !action.payload.modalLoadLatestDataAfterCreateOrUpdate &&
      order.hasOwnProperty(BASE_DB_FIELDS.UPDATE_AT)
    ) {
      delete order[BASE_DB_FIELDS.UPDATE_AT];
    }
    const response = yield getDetergentApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        name: action.payload.searchByText
      }),
      order: JSON.stringify(order),
      filter: JSON.stringify(action.payload.filterList || '')
    });

    response.data.data.results = response.data.data.results.map(detergent => {
      detergent.formulas = _.orderBy(
        detergent.formulas,
        ['status', 'version'],
        ['asc', 'desc']
      );
      return detergent;
    });
    yield put(getDetergentSuccess(response.data));
  } catch (error) {
    yield put(getDetergentError(error));
  }
}

export function* createDetergentSaga(action) {
  try {
    const documents = _.get(action.payload, 'formulas.0.documents', []);
    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        return {
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e)
        };
      });
      _.set(action.payload, 'formulas.0.documents', uploadedDocuments);
    }
    // Send a request to create new detergent
    const detergentResponseData = yield addDetergentApi(action.payload);
    const formulasMeasure = measurementInitialState;
    _.set(
      formulasMeasure,
      'densityDIN51757.measured',
      detergentResponseData?.data?.data?.formulas?.[0]?.density
    );

    yield createMeasurementDataFieldApi({
      id: detergentResponseData?.data?.data?.id,
      formulaId: detergentResponseData?.data?.data?.formulas?.[0]?.id,
      data: formulasMeasure
    });
    yield put(createDetergentSuccess(detergentResponseData.data));

    showSuccessToast('common.notify.detergent.createSuccess');
  } catch (error) {
    yield put(createDetergentError(error));
    errorHandle(error);
  }
}

export function* updateDetergentSaga(action) {
  try {
    const data = convertJsonToFormData(action.payload.detergent);
    const response = yield updateDetergentApi(action.payload.id, data);
    yield put(updateDetergentSuccess(response.data));
    showSuccessToast('common.notify.detergent.updateSuccess');
  } catch (error) {
    yield put(updateDetergentError(error));
    errorHandle(error);
  }
}

export function* createFormulaSaga(action) {
  try {
    // Upload documents, then add document keys to the detergent formula data
    const documents = action.payload.formula.documents || [];
    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        return {
          id: document.id,
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e)
        };
      });
      action.payload.formula.documents = uploadedDocuments;
    }
    // Send a request to create new formula of detergent
    const formulaResponseData = yield addFormulaApi(
      action.payload.id,
      action.payload.formula
    );
    const formulasMeasure = measurementInitialState;
    _.set(
      formulasMeasure,
      'densityDIN51757.measured',
      formulaResponseData?.data?.data?.density
    );
    yield createMeasurementDataFieldApi({
      id: formulaResponseData?.data?.data?.detergentId,
      formulaId: formulaResponseData?.data?.data?.id,
      data: formulasMeasure
    });
    yield put(createFormulaSuccess(formulaResponseData.data));

    showSuccessToast('common.notify.formula.createSuccess');
  } catch (error) {
    yield put(createFormulaError(error));
    errorHandle(error);
  }
}

export function* getDetergentFormulaByIdSaga(action) {
  try {
    const response = yield getDetergentFormulaById(
      action.payload.id,
      action.payload.formulaId
    );

    yield put(getDetergentFormulaByIdSuccess(response.data.data));
  } catch (error) {
    yield put(getDetergentFormulaByIdError(error));
    errorHandle(error);
  }
}

export function* patchFormulaSaga(action) {
  try {
    const response = yield patchFormulaApi(
      action.payload.id,
      action.payload.formulaId,
      action.payload.data
    );
    yield put(patchFormulaSuccess(response.data));
    showSuccessToast('common.notify.formula.updateSuccess');
  } catch (error) {
    yield put(patchFormulaError(error));
    errorHandle(error);
  }
}

export function* getDetergentHistorySaga(action) {
  try {
    const response = yield getDetergentHistoryApi(
      action.payload.id,
      action.payload.formulaId,
      action.payload.pagination
    );
    yield put(getDetergentHistorySuccess(response.data.data));
  } catch (error) {
    yield put(getDetergentHistoryError(error));
    errorHandle(error);
  }
}

export function* getMeasurementSaga(action) {
  try {
    const response = yield getMeasurement(
      action.payload.id,
      action.payload.formulaId
    );
    yield put(getMeasurementSuccess(response.data.data));
  } catch (error) {
    yield put(getMeasurementError(error));
    errorHandle(error);
  }
}

export function* getRawMaterialByFormulaSaga(action) {
  try {
    const response = yield getRawMaterialByFormulaApi(action.payload.id);
    yield put(getRawMaterialByFormulaSuccess(response.data.data.results));
  } catch (error) {
    yield put(getRawMaterialByFormulaError(error));
    errorHandle(error);
  }
}

export function* getRawMaterialByFormulaCompareSaga(action) {
  try {
    const {
      payload: [firstFormulaId, secondFormulaId]
    } = action;

    const mappedResult = new Map();

    const resultFromFirstId = yield getRawMaterialByFormulaApi(firstFormulaId);
    mappedResult.set(firstFormulaId, resultFromFirstId.data.data);

    const resultFromSecondId = yield getRawMaterialByFormulaApi(
      secondFormulaId
    );
    mappedResult.set(secondFormulaId, resultFromSecondId.data.data);

    yield put(getRawMaterialByFormulasCompareSuccess(mappedResult));
  } catch (error) {
    yield put(getRawMaterialByFormulasCompareError(error));
  }
}

export function* updateDetergentFormulaSaga(action) {
  try {
    const {
      detergent,
      detergentId,
      formulaId,
      measurementDataForm,
      precalculationRawMaterials,
      productionCycles
    } = action.payload;

    const promises = [];

    // Upload documents
    const documents = detergent.formulas[0].documents || [];
    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        const productionCycleDocuments = (
          document.productionCycles || []
        ).map(productionCycle =>
          typeof productionCycle === 'number'
            ? { id: productionCycle }
            : { id: productionCycle.id }
        );
        return {
          id: document.id,
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e),
          productionCycles: productionCycleDocuments
        };
      });
      detergent.formulas[0].documents = uploadedDocuments;
    }

    // Update detergent formula

    promises.push(putDetergentFormulaApi(detergentId, formulaId, detergent));

    // Update raw materials
    const updateRawMaterialPriceErrors = [];
    yield all(
      precalculationRawMaterials.map(rawMaterialItem =>
        call(function* updateRawMaterial() {
          const { id, ...rawMaterialItemData } = rawMaterialItem;
          try {
            yield call(function* updateRawMaterialSaga() {
              yield updateRawMaterialsApi(id, rawMaterialItemData);
            });
          } catch (err) {
            if (
              err?.response?.data?.data?.message === 'price is in wrong format'
            ) {
              updateRawMaterialPriceErrors.push(rawMaterialItemData?.name);
            }
          }
        })
      )
    );

    if (updateRawMaterialPriceErrors.length > 0) {
      const rawMaterialsName = updateRawMaterialPriceErrors.join(', ');

      throw new DetailsError('Invalid price', {
        rawMaterialsName
      });
    }

    // Update measurement Measurement Data
    const formulasMeasure = measurementDataForm;
    _.set(
      formulasMeasure,
      'densityDIN51757.measured',
      detergent?.formulas[0].density
    );
    promises.push(
      updateMeasurementDataFieldApi(detergentId, formulaId, formulasMeasure)
    );

    // Update formula's production cycles
    if (productionCycles.length) {
      productionCycles.forEach(productionCycle => {
        promises.push(
          updateProductionCycleApi(productionCycle.id, productionCycle)
        );
      });
    }

    const [
      updatedDetergentFomula,
      updatedFormulaContainerSize,
      updatedMeasurement,
      ...updatedProductionCycles
    ] = (yield all(promises)).map(promiseResult => promiseResult.data.data);

    // Refresh detergent's raw materials after updated
    yield call(getRawMaterialByFormulaSaga, { payload: { id: formulaId } });

    showSuccessToast('common.notify.detergentFormula.updateSuccess');

    yield put(
      updateDetergentFormulaSuccess({
        updatedDetergentFomula,
        updatedFormulaContainerSize,
        updatedMeasurement,
        updatedProductionCycles
      })
    );
  } catch (error) {
    yield put(updateDetergentFormulaError(error));
    if (error.message === 'Invalid price') {
      showErrorToast('error.errorMessageInvalidPrice', {
        dataRespond: error?.errorExtraParams?.rawMaterialsName
      });
      return;
    }
    errorHandle(error);
  }
}

export function* getDetergentProductionCyclesSaga(action) {
  try {
    const response = yield getDetergentProductionCycleListApi(
      action.payload.formulaId
    );
    yield put(getDetergentProductionCycleListSuccess(response.data.data));
  } catch (error) {
    yield put(getDetergentProductionCycleListError(error));
    errorHandle(error);
  }
}

export function* createProductionCycleSaga(action) {
  try {
    // Send a request to create new formula of detergent
    const response = yield createProductionCycleApi(action.payload);
    yield put(createProductionCycleSuccess(response.data));

    showSuccessToast('common.notify.productionCycle.createSuccess');
  } catch (error) {
    yield put(createProductionCycleError(error));
    errorHandle(error);
  }
}

export function* createDummyPartNumberSaga(action) {
  try {
    // Create dummy part number
    const response = yield createDummyPartNumberApi(action.payload);
    const dummyPartNumberId = response?.data?.data?.id;

    // Create production cycle
    const productionCycleData = {
      ...initialCreateProductionCycleData,
      partNumbers: [
        {
          id: dummyPartNumberId
        }
      ]
    };

    yield call(createProductionCycleSaga, { payload: productionCycleData });
  } catch (error) {
    yield put(createDummyPartNumberError(error));
    errorHandle(error);
  }
}

function* downloadPrecalculationSaga(action) {
  const {
    payload: { formulaId, languageCode }
  } = action;
  try {
    const response = yield downloadPrecalculationApi(formulaId, languageCode);
    yield put(downloadPrecalculationSucess(response.data.data));
  } catch (error) {
    yield put(downloadPrecalculationError(error));
    errorHandle(error);
  }
}

function* getPartNumberByFormulaSaga(action) {
  try {
    const response = yield getPartNumberListByFormulaApi(
      action.payload.formulaId
    );
    yield put(getPartNumberListByFormulaSuccess(response.data.data));
  } catch (error) {
    yield put(getPartNumberListByFormulaError(error));
    errorHandle(error);
  }
}

function* sendEmailToSafetyTeamSaga(action) {
  try {
    yield sendEmailApi(action.payload);
    yield put(
      sendEmailToSafetyTeamSuccess({
        productionCycleId: action.payload.param.productionCycleId
      })
    );
    showSuccessToast(
      'common.notify.productionCycle.forwardToSafetyTeamSuccess'
    );
  } catch (error) {
    yield put(
      sendEmailToSafetyTeamError({
        productionCycleId: action.payload.param.productionCycleId
      })
    );
    errorHandle(error);
  }
}

function* sendEmailToLabelingTeamSaga(action) {
  try {
    yield sendEmailApi(action.payload);
    yield put(
      sendEmailToLabelingTeamSuccess({
        productionCycleId: action.payload.param.productionCycleId
      })
    );
    showSuccessToast(
      'common.notify.productionCycle.forwardToLabelingTeamSuccess'
    );
  } catch (error) {
    yield put(
      sendEmailToLabelingTeamError({
        productionCycleId: action.payload.param.productionCycleId
      })
    );
    errorHandle(error);
  }
}

export function* updateProductionCyclesSaga(action) {
  try {
    const promises = [];

    const { productionCycles, detergent, documents } = action.payload;
    if (!productionCycles.length) {
      throw new Error('productionCycles.areEmpty');
    }

    // Upload documents
    detergent.formulas[0].documents = [];

    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        const productionCycleDocuments = (
          document.productionCycles || []
        ).map(productionCycle =>
          typeof productionCycle === 'number'
            ? { id: productionCycle }
            : { id: productionCycle.id }
        );
        return {
          id: document.id,
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e),
          productionCycles: productionCycleDocuments
        };
      });
      detergent.formulas[0].documents = uploadedDocuments;
    }

    // Update detergent formula
    promises.push(
      putDetergentFormulaApi(
        detergent.id,
        detergent?.formulas?.[0]?.id,
        detergent
      )
    );

    // Update production cycles
    productionCycles.forEach(productionCycle => {
      promises.push(
        updateProductionCycleApi(productionCycle.id, productionCycle)
      );
    });
    const updatedProductionCycles = (yield all(promises)).map(
      promiseResult => promiseResult.data.data
    );

    showSuccessToast('common.notify.productionCycles.updateSuccess');

    yield put(
      updateProductionCyclesSuccess({
        updatedProductionCycles
      })
    );
  } catch (error) {
    yield put(updateProductionCyclesError(error));
    errorHandle(error);
  }
}

export function* deleteProductionCycleSaga(action) {
  try {
    yield deleteProductionCycleApi(action.payload.id);
    yield put(deleteProductionCycleSuccess(action.payload));
    showSuccessToast('common.notify.productionCycle.deleteSuccess');
  } catch (error) {
    yield put(deleteProductionCycleError(error));
    errorHandle(error);
  }
}

export function* getProductionCycleHistorySaga(action) {
  try {
    const response = yield getProductionCycleHistoryApi(
      action.payload.formulaId
    );
    yield put(getProductionCycleHistoryByFormulaSuccess(response.data.data));
  } catch (error) {
    yield put(getProductionCycleHistoryByFormulaError(error));
    errorHandle(error);
  }
}

export function* checkRawMaterialBlockSaga(action) {
  try {
    // Send a request to create new formula of detergent
    const response = yield getRawMaterialByFormulaApi(action.payload);
    yield put(checkRawMaterialBlockSuccess(response));
  } catch (error) {
    yield put(checkRawMaterialBlockError(error));
    errorHandle(error);
  }
}

export default function* detergentSaga() {
  yield takeLatest(GET_DETERGENT, getDetergentSaga);
  yield takeLatest(CREATE_DETERGENT, createDetergentSaga);
  yield takeLatest(UPDATE_DETERGENT, updateDetergentSaga);
  yield takeLatest(CREATE_FORMULA, createFormulaSaga);
  yield takeLatest(GET_DETERGENT_BY_ID, getDetergentFormulaByIdSaga);
  yield takeLatest(PATCH_FORUMLA, patchFormulaSaga);
  yield takeLatest(GET_DETERGENT_HISTORY, getDetergentHistorySaga);
  yield takeLatest(GET_MEASUREMENT, getMeasurementSaga);
  yield takeLatest(GET_RAW_MATERIAL_BY_FORMULA, getRawMaterialByFormulaSaga);
  yield takeLatest(
    GET_RAW_MATERIAL_BY_FORMULA_COMPARE,
    getRawMaterialByFormulaCompareSaga
  );
  yield takeLatest(UPDATE_DETERGENT_FORMULA, updateDetergentFormulaSaga);
  yield takeLatest(
    GET_DETERGENT_PRODUCTION_CYCLE_LIST,
    getDetergentProductionCyclesSaga
  );
  yield takeLatest(CREATE_PRODUCTION_CYCLE, createProductionCycleSaga);
  yield takeLatest(CREATE_DUMMY_PART_NUMBER, createDummyPartNumberSaga);
  yield takeLeading(DOWNLOAD_PRECALCULATION, downloadPrecalculationSaga);

  yield takeLeading(
    GET_PART_NUMBER_LIST_BY_FORMULA,
    getPartNumberByFormulaSaga
  );

  yield takeEvery(SEND_EMAIL_TO_SAFETY_TEAM, sendEmailToSafetyTeamSaga);
  yield takeEvery(SEND_EMAIL_TO_LABELING_TEAM, sendEmailToLabelingTeamSaga);
  yield takeLatest(UPDATE_PRODUCTION_CYCLES, updateProductionCyclesSaga);
  yield takeLatest(DELETE_PRODUCTION_CYCLE, deleteProductionCycleSaga);
  yield takeLatest(CHECK_RAW_MATERIAL_UNBLOCK, checkRawMaterialBlockSaga);
  yield takeLatest(
    GET_PRODUCTION_CYCLE_HISTORY_LIST,
    getProductionCycleHistorySaga
  );
}
