import { translateHistoryFieldTextByKeyName } from '../../../utils/common';
import {
  MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
  MAPPING_RAW_MATERIAL_HISTORY_TO_TRANSLATION_ID,
  MAPPING_PART_NUMBER_HISTORY_TO_TRANSLATION_ID,
  MAPPING_RAW_MATERIAL_GROUPS_HISTORY_TO_TRANSLATION_ID,
  MAPPING_RAW_MATERIAL_PRODUCER_HISTORY_TO_TRANSLATION_ID,
  MAPPING_PRODUCTION_CYCLE_HISTORY_TO_TRANSLATION_ID,
  MAPPING_USER_HISTORY_TO_TRANSLATION_ID
} from '../../../configs/constant';

export function getFieldTextByTableName(intl, tableName, key) {
  const mappingTableNameToTranslation = {
    Detergent: MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
    RawMaterial: MAPPING_RAW_MATERIAL_HISTORY_TO_TRANSLATION_ID,
    PartNumber: MAPPING_PART_NUMBER_HISTORY_TO_TRANSLATION_ID,
    RawMarterialGroup: MAPPING_RAW_MATERIAL_GROUPS_HISTORY_TO_TRANSLATION_ID,
    RawMaterialProducer: MAPPING_RAW_MATERIAL_PRODUCER_HISTORY_TO_TRANSLATION_ID,
    DetergentProducer: MAPPING_RAW_MATERIAL_PRODUCER_HISTORY_TO_TRANSLATION_ID,
    ProductionCycle: MAPPING_PRODUCTION_CYCLE_HISTORY_TO_TRANSLATION_ID,
    DetergentFormula: MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
    User: MAPPING_USER_HISTORY_TO_TRANSLATION_ID
  };

  if (!mappingTableNameToTranslation[tableName]) {
    // eslint-disable-next-line no-console
    console.error('Unknown table:', tableName);
    return '';
  }

  return translateHistoryFieldTextByKeyName(
    intl,
    key,
    mappingTableNameToTranslation[tableName] || {}
  );
}
