import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DISPLAY_PRODUCTION_CYCLE_ACTION,
  DISPLAY_PRODUCTION_CYCLE_STAGE
} from '../../../../../../../configs/constant';
import { formatDateTimeByReactIntl } from '../../../../../../../utils/reactIntlHelpers';

export const columns = intl => [
  {
    title: (
      <FormattedMessage
        id="productionCycleHistory.columnCycleID"
        defaultMessage="Cycle ID"
      />
    ),
    key: 'cycleId',
    dataIndex: 'cycleId',
    width: '20%'
  },
  {
    title: (
      <FormattedMessage
        id="productionCycleHistory.columnDateTime"
        defaultMessage="Date/Time"
      />
    ),
    key: 'createAt',
    width: '20%',
    render: record => formatDateTimeByReactIntl(intl, record.createAt)
  },
  {
    title: (
      <FormattedMessage
        id="productionCycleHistory.columnUser"
        defaultMessage="User"
      />
    ),
    key: 'user',
    dataIndex: 'creatorEmail',
    width: '20%'
  },
  {
    title: (
      <FormattedMessage
        id="productionCycleHistory.columnAction"
        defaultMessage="Action"
      />
    ),
    key: 'action',
    dataIndex: 'action',
    render: action => DISPLAY_PRODUCTION_CYCLE_ACTION[action],
    width: '20%'
  },
  {
    title: (
      <FormattedMessage
        id="productionCycleHistory.columnStage"
        defaultMessage="Stage"
      />
    ),
    key: 'stage',
    dataIndex: 'stage',
    render: stage => DISPLAY_PRODUCTION_CYCLE_STAGE[stage],
    width: '20%'
  }
];
