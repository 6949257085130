import { MEASUREMENT_MAPPING_FIELD } from '../../../../../configs/constant';

const generate = measurement => {
  const corrosivityTestInfo = { ...measurement.corrosivityTest };
  const flashPointAccordingToDINInfo = {
    ...measurement.flashPointAccordingToDIN
  };
  const flashPointSixtyPointFiveCIATAInfo = {
    ...measurement.flashPointSixtyPointFiveCIATA
  };

  return [
    {
      cellKeySafety: MEASUREMENT_MAPPING_FIELD.corrosivityTest.key,

      measured: corrosivityTestInfo.measured,
      min: corrosivityTestInfo.min,
      max: corrosivityTestInfo.max,
      comment: corrosivityTestInfo.comment
    },
    {
      cellKeySafety: MEASUREMENT_MAPPING_FIELD.flashPointAccordingToDIN.key,

      measured: flashPointAccordingToDINInfo.measured,
      min: flashPointAccordingToDINInfo.min,
      max: flashPointAccordingToDINInfo.max,
      comment: flashPointAccordingToDINInfo.comment
    },
    {
      cellKeySafety:
        MEASUREMENT_MAPPING_FIELD.flashPointSixtyPointFiveCIATA.key,

      measured: flashPointSixtyPointFiveCIATAInfo.measured,
      min: flashPointSixtyPointFiveCIATAInfo.min,
      max: flashPointSixtyPointFiveCIATAInfo.max,
      comment: flashPointSixtyPointFiveCIATAInfo.comment
    }
  ];
};

export default generate;
