import { createUserManager } from 'redux-oidc';

import authConfig from './auth-config';
import { clearCognitoInfo } from './cognito';

const userManager = createUserManager(authConfig);
export default userManager;
export const onSignIn = () => userManager.signinRedirect();
export const onSignOut = () => {
  userManager.signoutRedirect();
  clearCognitoInfo();
};
