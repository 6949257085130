import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Icon } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSearchData,
  clearSearchData
} from '../../../../data/universalSearch/actions';
import { BaseTable } from '../../../../components/BaseTable';
import { renderUniversalSeachColumns } from './renderColumns';

export const UniversalSearchModal = ({ onCancel }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { keywords, isLoading, dataList, pagination } = useSelector(state => ({
    keywords: state.universalSearch.keywords,
    isLoading: state.universalSearch.fetching,
    dataList: state.universalSearch.data.results,
    pagination: state.universalSearch.data.pagination
  }));

  const [searchKeywords, setSearchKeywords] = useState(keywords);
  const [searchValue, setSearchValue] = useState(keywords);
  const searchDebounceRef = useRef();

  useEffect(() => {
    if (keywords?.length) {
      dispatch(
        getSearchData({
          keywords,
          pagination
        })
      );
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(clearSearchData());
    },
    []
  );

  const onChangeSearch = useCallback(e => {
    e.persist();
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    searchDebounceRef.current = setTimeout(() => {
      setSearchKeywords(e.target.value);
    }, 300);
  });

  const onSearch = useCallback(value => {
    if (!value?.length) return;
    setSearchValue(value);
    dispatch(
      getSearchData({
        keywords: value
      })
    );
  });

  const onPageSizeChange = useCallback(
    value => {
      const newPagination = {
        ...pagination,
        current: 1,
        pageSize: value
      };

      dispatch(
        getSearchData({
          keywords: searchValue,
          pagination: newPagination
        })
      );
    },
    [pagination]
  );

  const onPageChange = useCallback(
    value => {
      const newPagination = {
        ...pagination,
        current: value + 1
      };

      dispatch(
        getSearchData({
          keywords: searchValue,
          pagination: newPagination
        })
      );
    },
    [pagination]
  );

  const onScrollToTop = () => {
    const modalElement = document.querySelector(
      '.universal-search-modal > .ant-modal-content > .ant-modal-body'
    );

    if (modalElement) {
      modalElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const columns = useMemo(() => renderUniversalSeachColumns(searchValue), [
    searchValue
  ]);

  return (
    <div className="universal-search">
      <div className="universal-search__actions">
        <p className="universal-search__title">
          {formatMessage({
            id: 'universalSearch.title',
            defaultMessage: 'ALL SEARCH RESULTS'
          })}
        </p>
        <div className="search-action">
          <Input.Search
            className="search-place"
            defaultValue={searchValue}
            placeholder={formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearch(value)}
          />
          <Button
            className="search-action-btn yellow-button"
            onClick={() => onSearch(searchKeywords)}>
            {formatMessage({
              id: 'header.search',
              defaultMessage: 'SEARCH'
            })}
          </Button>
        </div>
      </div>
      <div className="universal-search__table">
        <BaseTable
          pagination={pagination}
          columns={columns}
          loading={isLoading && { indicator: <Icon type="sync" spin /> }}
          dataSource={dataList}
          onPageSizeChange={value => onPageSizeChange(value)}
          onPageChange={current => onPageChange(current)}
        />
      </div>
      <div className="universal-search__footer">
        <Button className="back-to-top-btn" onClick={() => onScrollToTop()}>
          {formatMessage({
            id: 'common.backToTop',
            defaultMessage: 'Back to top'
          })}
        </Button>
        <Button className="btn-cancel yellow-button" onClick={onCancel}>
          {formatMessage({
            id: 'common.close',
            defaultMessage: 'CLOSE'
          })}
        </Button>
      </div>
    </div>
  );
};

UniversalSearchModal.propTypes = {
  onCancel: PropTypes.func.isRequired
};
