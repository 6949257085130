import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginComponent from '../containers/Login';
import CallBackComponent from '../containers/Login/CallBackComponent';
import ExchangeTokenComponent from '../containers/ExchangeToken';

const PublicRouteComponent = () => {
  return (
    <>
      <Switch>
        <Route path="/callback" component={CallBackComponent} />
        <Route path="/exchange-token" component={ExchangeTokenComponent} />
        <Route exact path="*" component={LoginComponent} />
      </Switch>
    </>
  );
};

export default PublicRouteComponent;
