import { put, takeLatest } from 'redux-saga/effects';
import { getDetergentProducerNonPagingApi } from './api';
import {
  GET_DETERGENT_PRODUCER_NON_PAGING,
  getDetergentProducerNonPagingSuccess,
  getDetergentProducerNonPagingError
} from './action';
import { errorHandle } from '../../utils/common';

export function* getDetergentProducerNonPagingSaga() {
  try {
    const response = yield getDetergentProducerNonPagingApi();
    yield put(getDetergentProducerNonPagingSuccess(response.data.data));
  } catch (error) {
    yield put(getDetergentProducerNonPagingError(error));
    errorHandle(error);
  }
}

export default function* detergentNonPagingSaga() {
  yield takeLatest(
    GET_DETERGENT_PRODUCER_NON_PAGING,
    getDetergentProducerNonPagingSaga
  );
}
