export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

// Fetch raw materials
export const getCountries = (options) => ({
  type: GET_COUNTRIES,
  payload: options
});

export const getCountriesSuccess = (payload) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload
});

export const getCountriesError = (payload) => ({
  type: GET_COUNTRIES_ERROR,
  payload
});
