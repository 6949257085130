import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Select, Row, Col, Icon, Form, InputNumber, Input } from 'antd';
import * as _ from 'lodash';
import {
  getAnnualDemandYears,
  changeCommaToPoint
} from '../../../../../../utils/common';
import { validatorErpNumber } from '../../../../../../utils/validatorErpNumber';

const AnnualDemandContainer = props => {
  const {
    form: { getFieldDecorator, setFieldsValue },
    annualDemandList,
    annualDemandIndex,
    yearList,
    onChangeHandler,
    handleRemoveAnnualDemand
  } = props;

  const intl = useIntl();
  const { formatMessage } = intl;
  const originYearList = getAnnualDemandYears();

  const getInitialFieldValue = (index, fieldName) => {
    return _.get(annualDemandList, `${index}.${fieldName}`, '');
  };

  const onChangeSeletor = value => {
    setFieldsValue({
      [`partNumber.annualDemands[${annualDemandIndex}]['year']`]: value
    });
    onChangeHandler();
  };

  return (
    <Row gutter={24}>
      <Col span={8}>
        <Form.Item>
          <div id="annual-demand-node">
            {getFieldDecorator(
              `partNumber.annualDemands[${annualDemandIndex}]['year']`,
              {
                rules: [
                  {
                    required: true,
                    message: formatMessage({
                      id: 'partNumber.validate.annualDemandYears',
                      defaultMessage: 'Annual demand year is required'
                    })
                  }
                ],
                initialValue: getInitialFieldValue(annualDemandIndex, 'year')
                  ? getInitialFieldValue(annualDemandIndex, 'year')
                  : undefined
              }
            )(
              <Select
                getPopupContainer={() =>
                  document.getElementById('annual-demand-node')
                }
                onChange={onChangeSeletor}
                placeholder={
                  <FormattedMessage
                    id="partNumber.form.selectAYear"
                    defaultMessage="Select a year"
                  />
                }
                suffixIcon={<Icon type="caret-down" />}>
                {originYearList.map(year => (
                  <Select.Option
                    disabled={!yearList.includes(year)}
                    key={year}
                    value={year}>
                    {year}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item>
          {getFieldDecorator(
            `partNumber.annualDemands[${annualDemandIndex}]['numberOfContainers']`,
            {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'partNumber.validate.annualDemandContainerNumbers',
                    defaultMessage:
                      'Annual demand container number is required.'
                  })
                },
                {
                  validator: validatorErpNumber(formatMessage)
                }
              ],
              initialValue: getInitialFieldValue(
                annualDemandIndex,
                'numberOfContainers'
              )
                ? getInitialFieldValue(annualDemandIndex, 'numberOfContainers')
                : undefined
            }
          )(<InputNumber type="number" />)}
        </Form.Item>
      </Col>
      {annualDemandIndex > 0 && (
        <Col md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }}>
          <div
            className="remove"
            onClick={() => handleRemoveAnnualDemand(annualDemandIndex)}>
            <p style={{ marginRight: '5px' }}>x</p>
            <u>
              <FormattedMessage
                id="common.deleteButton"
                defaultMessage="Delete"
              />
            </u>
          </div>
        </Col>
      )}
    </Row>
  );
};

AnnualDemandContainer.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
  getFieldDecorator: PropTypes.objectOf(PropTypes.func).isRequired,
  setFieldsValue: PropTypes.objectOf(PropTypes.func).isRequired,
  annualDemandList: PropTypes.objectOf(PropTypes.array).isRequired,
  annualDemandIndex: PropTypes.number.isRequired,
  yearList: PropTypes.objectOf(PropTypes.array).isRequired,
  onChangeHandler: PropTypes.objectOf(PropTypes.func).isRequired,
  handleRemoveAnnualDemand: PropTypes.objectOf(PropTypes.func).isRequired
};

export default AnnualDemandContainer;
