import { put, takeLatest } from 'redux-saga/effects';
import { exportContainerTypeApi, getContainerTypeApi } from './apis';
import {
  GET_CONTAINER_TYPE,
  getContainerTypeSuccess,
  getContainerTypeError,
  EXPORT_CONTAINER_TYPE,
  exportContainerTypeSuccess,
  exportContainerTypeError
} from './actions';
import { downloadFile, errorHandle } from '../../../../utils/common';

function* getPartNumberSaga(action) {
  try {
    const { order, pagination, filterList } = action.payload;

    const response = yield getContainerTypeApi({
      pagination,
      order: JSON.stringify(order),
      filter: JSON.stringify(filterList)
    });

    yield put(getContainerTypeSuccess(response.data));
  } catch (error) {
    yield put(getContainerTypeError(error));
    errorHandle(error);
  }
}

function* exportContainerTypeSaga(action) {
  try {
    const { filterList } = action.payload;
    const response = yield exportContainerTypeApi({
      filter: JSON.stringify(filterList)
    });

    // download file as csv
    const downloadType = 'data:text/csv;charset=utf-8';
    const fileName = `Container-Type-${new Date().toJSON().slice(0, 10)}.csv`;
    downloadFile(fileName, response.data.data, downloadType);

    yield put(exportContainerTypeSuccess(response.data));
  } catch (error) {
    yield put(exportContainerTypeError(error));
    errorHandle(error);
  }
}

export default function* containerTypeSaga() {
  yield takeLatest(GET_CONTAINER_TYPE, getPartNumberSaga);
  yield takeLatest(EXPORT_CONTAINER_TYPE, exportContainerTypeSaga);
}
