import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Input, Menu, Dropdown, Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import List from './components/List';
import { RawMaterialGroupModal } from './components/Modal';
import {
  getRawMaterialGroup,
  processGroupModal,
  setRawMaterialGroupFetching,
  clearRawMaterialGroupDataWhenUnmount
} from './data/rawMaterialGroup/action';
import { deleteRawMaterialGroupApi } from './data/rawMaterialGroup/api';
import { BASE_DB_FIELDS, FORM_STATUS } from '../../configs/constant';
import { getRawMaterialApi } from '../RawMaterial/data/rawMaterial/api';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';
import { getCountries } from '../../data/countries/action';
import { FilterFormRawMaterialGroup } from './components/Form/FilterFormRawMaterialGroup';
import FilterForm from '../../components/Filter';

const { confirm } = Modal;

const RawMaterialGroup = props => {
  const intl = useIntl();

  const { formatMessage } = intl;

  const dispatch = useDispatch();

  const {
    pagination,
    searchByText,
    rawMaterialGroup,
    order,
    modalLoadLatestDataAfterCreateOrUpdate,
    countsRefeshList,
    filterList
  } = useSelector(state => ({
    pagination: state.rawMaterialGroup.list.data.pagination,
    modalLoadLatestDataAfterCreateOrUpdate:
      state.rawMaterialGroup.modal.loadLatestDataAfterCreateOrUpdate,
    searchByText: state.rawMaterialGroup.searchByText,
    rawMaterialGroup: state.rawMaterialGroup,
    order: state.rawMaterialGroup.order,
    countsRefeshList: state.rawMaterialGroup.modal.countsRefeshList,
    filterList: state.rawMaterialGroup.filterList
  }));
  useEffect(() => {
    let newOrder = { ...order };
    const newPagination = { ...pagination };

    if (modalLoadLatestDataAfterCreateOrUpdate) {
      newOrder = {
        [BASE_DB_FIELDS.UPDATE_AT]: 'DESC'
      };
      newPagination.current = 1;
    }

    dispatch(
      getRawMaterialGroup({
        pagination: newPagination,
        searchByText,
        order: newOrder,
        filterList,
        modalLoadLatestDataAfterCreateOrUpdate
      })
    );
  }, [countsRefeshList]);

  useEffect(() => {
    dispatch(getCountries());

    return () => {
      // Unmount
      dispatch(clearRawMaterialGroupDataWhenUnmount());
    };
  }, []);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    dispatch(
      getRawMaterialGroup({ pagination, searchByText, order, filterList })
    );
  };

  const onPageChange = current => {
    pagination.current = ++current;
    dispatch(
      getRawMaterialGroup({ pagination, searchByText, order, filterList })
    );
  };

  const onEditGroup = record => {
    if (!isEmpty(record)) {
      dispatch(
        processGroupModal({
          group: record,
          statusForm: FORM_STATUS.UPDATE,
          title: formatMessage({
            id: 'rawMaterialGroup.modalEditRawMaterialGroupTitle',
            defaultMessage: 'EDIT RAW MATERIAL GROUP'
          }),
          isVisible: true
        })
      );
    }
  };

  const deleteAction = async record => {
    await deleteRawMaterialGroupApi(record.id);
    dispatch(
      getRawMaterialGroup({ pagination, searchByText, order, filterList })
    );
    return true;
  };

  const onDeleteGroup = async record => {
    if (isEmpty(record)) return;

    dispatch(setRawMaterialGroupFetching(true));

    const rawMaterialByGroup = await getRawMaterialApi({
      filter: JSON.stringify({
        rawMaterialGroup: record.id
      })
    });

    dispatch(setRawMaterialGroupFetching(false));

    const totalRawMaterials = _.get(
      rawMaterialByGroup,
      'data.data.pagination.total',
      0
    );
    const translateConfig = [
      {
        id:
          totalRawMaterials > 0
            ? 'common.messageWarningDeleteGroup'
            : 'common.messageAreYouSureWantToDelete',
        defaultMessage:
          totalRawMaterials > 0
            ? '“Group {name}” is used for Raw material. Are you sure you want to delete?'
            : 'Are you sure you want to delete “{name}”?'
      },
      {
        name: record.name
      }
    ];

    confirm({
      className: 'base-confirm-modal',
      title: formatMessage({
        id: 'rawMaterialGroup.modalDeleteRawMaterialGroupTitle',
        defaultMessage: 'DELETE RAW MATERIAL GROUP'
      }),
      content: (
        <p className="content">
          <Icon type="warning" className="warning-icon" />
          <span>{formatMessage(...translateConfig)}</span>
        </p>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      icon: null,
      onOk() {
        return deleteAction(record);
      }
    });
  };

  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');
    pagination.current = 1;
    dispatch(
      getRawMaterialGroup({
        pagination,
        searchByText: valueChange,
        order,
        filterList
      })
    );
  };

  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(
        getRawMaterialGroup({ pagination, searchByText: '', order, filterList })
      );
    }
  };

  const popupAction = action => {
    if (action === 'open') {
      dispatch(
        processGroupModal({
          statusForm: FORM_STATUS.CREATE,
          title: formatMessage({
            id: 'rawMaterialGroup.modalAddRawMaterialGroupTitle',
            defaultMessage: 'ADD NEW RAW MATERIAL GROUP'
          }),
          isVisible: true
        })
      );
    } else {
      dispatch(
        processGroupModal({
          isVisible: false
        })
      );
    }
  };

  const onSortChange = key => {
    if (order[key] === '') {
      order[key] = 'ASC';
    } else {
      order[key] = '';
    }

    dispatch(
      getRawMaterialGroup({ pagination, searchByText, order, filterList })
    );
  };

  const SortPlace = () => {
    return (
      <Menu className="menu-sort">
        <Menu.Item
          key="0"
          className="sort-item"
          onClick={() => onSortChange('name')}>
          <div>
            <FormattedMessage
              id="common.alphabetize"
              defaultMessage="Alphabetize by name"
            />
          </div>{' '}
          {order.name ? <Icon type="check" /> : ''}
        </Menu.Item>
        <Menu.Item
          key="1"
          className="sort-item"
          onClick={() => onSortChange('status')}>
          <div>
            <FormattedMessage
              id="common.sortByStatus"
              defaultMessage="Sort by status"
            />
          </div>{' '}
          {order.status ? <Icon type="check" /> : ''}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <div className="detergent-database-content">
        <div className="main-title">
          <FormattedMessage
            id="header.componentRawMaterialGroup"
            defaultMessage="Raw Materials Groups"
          />
        </div>
        <div className="rmg-filter-area filter-area">
          <FilterForm
            dropdownClassName="sort-dropdown filter-rmg"
            dropdownOverlayClassName="filter-content"
            FilterFormLayout={FilterFormRawMaterialGroup}
          />
          <div id="rmgroup-sort-modal" className="sort-modal">
            <Dropdown
              getPopupContainer={() =>
                document.getElementById('rmgroup-sort-modal')
              }
              className="sort-dropdown"
              overlay={<SortPlace />}
              trigger={['click']}
              overlayClassName="content-sort-form">
              <div className="ant-dropdown-link">
                <div className="edit_caret">
                  <Icon type="caret-up" />
                  <Icon type="caret-down" />
                </div>
                <div className="sort">
                  <FormattedMessage id="header.sort" defaultMessage="Sort" />
                </div>
              </div>
            </Dropdown>
          </div>
          <Input.Search
            // value={searchByText}
            className="search-place"
            name="search"
            placeholder={formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearchList(value)}
          />

          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIAL_GROUPS.CREATE}>
            <Button className="add-button" onClick={() => popupAction('open')}>
              <Icon className="add-icon" type="plus" />
              <div className="text">
                <FormattedMessage
                  id="rawMaterialGroup.createButton"
                  defaultMessage="ADD GROUP"
                />
              </div>
            </Button>
          </ProtectedComponent>

          <RawMaterialGroupModal
            status={rawMaterialGroup.modal.statusForm}
            title={rawMaterialGroup.modal.title}
            isVisible={rawMaterialGroup.modal.isVisible}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <List
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onEditGroup={record => onEditGroup(record)}
        onDeleteGroup={record => onDeleteGroup(record)}
      />
    </>
  );
};

export default RawMaterialGroup;
