export const formatDateTimeByReactIntl = (intl, datetime) => {
  if (!datetime) return null;

  return [
    intl.formatDate(datetime),
    intl.formatTime(datetime, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
  ].join(' ');
};
