import React from 'react';
import PropTypes from 'prop-types';
import { ACTION_BUTTON_TYPES } from '../../configs/constant';

export const preventOnRowTrigger = ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER;

export const configContent = ({
  year,
  annualTonnageContent,
  totalValueContent
}) => (
  <>
    <span className={`${preventOnRowTrigger} annual-year`}>{year}</span>
    <span className={`${preventOnRowTrigger} annual-tonnage`}>
      {annualTonnageContent}
    </span>
    <span className={`${preventOnRowTrigger} total-annual-tonnage-value`}>
      {totalValueContent}
    </span>
  </>
);

configContent.propTypes = {
  year: PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.number.isRequired
  ]).isRequired,
  annualTonnageContent: PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  totalValueContent: PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.string.isRequired
  ]).isRequired
};
