import React, { useMemo } from 'react';
import { Select, Icon, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FilterableSelect from '../FilterableSelect';
import useCountriesByRole from '../../hooks/useCountriesByRole';

const Country = ({
  className,
  getFieldDecorator,
  field,
  initValue,
  disabled
}) => {
  const countries = useCountriesByRole();
  const checkDisabled = useMemo(() => disabled === true, [disabled]);
  return (
    <Form.Item
      label={
        <FormattedMessage id="producer.country" defaultMessage="Country" />
      }
      className={className}>
      <div id="db-node">
        {getFieldDecorator(field, {
          rules: [
            {
              required: true,
              message: (
                <FormattedMessage
                  id="error.country"
                  defaultMessage="Country is required!"
                />
              )
            }
          ],
          initialValue: initValue
        })(
          <FilterableSelect
            disabled={checkDisabled}
            getPopupContainer={() => document.getElementById('db-node')}
            placeholder={
              <FormattedMessage
                id="rawMaterial.selectCountries"
                defaultMessage="Select countries"
              />
            }
            suffixIcon={<Icon type="caret-down" />}>
            {countries.map(country => (
              <Select.Option key={country.value} value={country.value}>
                {country.name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </div>
    </Form.Item>
  );
};

Country.propTypes = {
  className: PropTypes.string,
  getFieldDecorator: PropTypes.func,
  field: PropTypes.string,
  initValue: PropTypes.number,
  disabled: PropTypes.bool
};

Country.defaultProps = {
  className: undefined,
  getFieldDecorator: () => {},
  field: undefined,
  initValue: undefined,
  disabled: false
};

export default Country;
