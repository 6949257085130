import React from 'react';

import { Form, InputNumber } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const ValidateContainerSize = props => {
  // eslint-disable-next-line react/prop-types
  const { getFieldDecorator, initialValue, field } = props;
  const { formatMessage } = useIntl();

  return (
    <Form.Item
      label={
        <FormattedMessage
          id="partNumber.form.containerSizeInLiter"
          defaultMessage="Container size"
        />
      }>
      {getFieldDecorator(field, {
        rules: [
          {
            required: true,
            message: formatMessage({
              id: 'partNumber.validate.containSize',
              defaultMessage: 'Container size is required'
            })
          }
        ],
        initialValue
      })(
        <InputNumber
          formatter={value => value.replace('.', ',')}
          parser={value => value.replace(',', '.')}
        />
      )}
    </Form.Item>
  );
};

export default ValidateContainerSize;
