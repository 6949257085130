import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { preventOnRowTrigger } from '../../../../components/AnnualTonnageDropdown/configContent';
import AnnualDemandDetail from './AnnualDemandDetail';

const AnnualTonnageDropDown = ({ years, title }) => {
  let dataOfCurrentYear;
  const currentYear = new Date().getFullYear();
  const usePlannedContainer = title.defaultMessage === 'Planned Container';

  const annualTonnageList = (
    <div className={`annual-tonnage-container ${preventOnRowTrigger}`}>
      <div className={`${preventOnRowTrigger} container-title`}>
        <FormattedMessage
          id={title.id}
          defaultMessage={title.defaultMessage}
        />
      </div>

      <span className="drop-down-header-title">
        {AnnualDemandDetail({
          year: null,
          value: (
            <FormattedMessage
              id="rawMaterial.columnsTitle.value"
              defaultMessage="Value"
            />
          )
        })}
      </span>
      <div className={`${preventOnRowTrigger} horizon-line`} />
      {years.map(({ year, annualTonnage, plannedContainer }) => {
        const value = usePlannedContainer ? plannedContainer : annualTonnage;

        if (year === currentYear) {
          dataOfCurrentYear = value;
        }

        return (
          <div key={year}>
            <span>
              {AnnualDemandDetail({
                year,
                value
              })}
            </span>
            <div className={`${preventOnRowTrigger} horizon-line`} />
          </div>
        )
      })}
    </div>
  );

  if (!dataOfCurrentYear) {
    if (usePlannedContainer) {
      dataOfCurrentYear = years.length > 0 && years[0].plannedContainer;
    } else {
      dataOfCurrentYear = years.length > 0 && years[0].annualTonnage;
    }
  }

  return (
    <div id="drop-down-parent-node">
      <Dropdown
        trigger={['click']}
        getPopupContainer={() =>
          document.getElementById('drop-down-parent-node')
        }
        overlay={annualTonnageList}
        overlayClassName={preventOnRowTrigger}
        className={`${preventOnRowTrigger} dropdown-wrapper dropdown-wrapper--float-left`}
        placement="bottomCenter">
        <div>
          <span className={`${preventOnRowTrigger} annual-tonnage-value`}>
            {dataOfCurrentYear}
          </span>
          <div className={`${preventOnRowTrigger} drop-down-icon`} />
        </div>
      </Dropdown>
    </div>
  );
};

AnnualTonnageDropDown.propTypes = {
  years: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired
  })
};

export default AnnualTonnageDropDown;
