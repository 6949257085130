import * as _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { parseInt } from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';
import {
  changeCommaToPoint,
  changeCommaToPointWhenValueNumber
} from '../../../../utils/common';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

const FormularSelection = props => {
  const {
    rawMaterials = [],
    placeholder,
    value: rawMaterialRatios,
    disabled,
    onChange: setRawMaterialRatios
  } = props;
  const [selectedRawMaterialIds, setSelectedRawMaterialIds] = useState([]); // [1, 2, 3, 4]
  const [totalPercent, setTotalPercent] = useState(0);
  const [mappingRawMaterial, setMappingRawMaterial] = useState({});
  const focusedValue = useRef();
  // Component did mount
  // Mapping raw material ids to raw material names
  useEffect(() => {
    if (!_.isEmpty(rawMaterials)) {
      const newMappingRawMaterial = {};
      rawMaterials.forEach(rawMaterial => {
        newMappingRawMaterial[rawMaterial.id] = rawMaterial.name;
      });

      setMappingRawMaterial(newMappingRawMaterial);
    }
  }, []);

  // If user add new formula into existing detergent, we will get the old raw material ratios and show them
  useEffect(() => {
    const initSelectedRawMaterialIds = _.map(
      rawMaterialRatios,
      'rawMaterialId'
    );
    setSelectedRawMaterialIds(initSelectedRawMaterialIds);
  }, [rawMaterialRatios]);

  // Update total percent when user changing raw material ratios
  useEffect(() => {
    let newTotalPercent = 0;

    if (!_.isEmpty(rawMaterialRatios)) {
      newTotalPercent = rawMaterialRatios.reduce(
        (previousTotalPercent, rawMaterialRatio) => {
          return previousTotalPercent + rawMaterialRatio.ratio * 100;
        },
        0
      );
    }

    if (newTotalPercent !== totalPercent) {
      setTotalPercent(newTotalPercent);
    }
  }, [rawMaterialRatios]);
  const handleOnChange = rawMaterialIds => {
    const arrayRawMaterialId = [];
    rawMaterialIds.map(item => {
      arrayRawMaterialId.push(item.id);
    });
    // Set ids
    setSelectedRawMaterialIds(arrayRawMaterialId);

    // Mapping, set ratio, set form
    let newRawMaterialRatios = [];
    // when removing raw materials and ratios
    if (selectedRawMaterialIds.length > arrayRawMaterialId.length) {
      // find which raw material was removed
      const arrayRemovedRawMaterialId = selectedRawMaterialIds.filter(ids => {
        return !arrayRawMaterialId.includes(ids);
      });
      const removedRawMaterialId = _.get(arrayRemovedRawMaterialId, '0', null);

      // remove ratio match with the raw material id
      newRawMaterialRatios = _.filter(rawMaterialRatios, rawMaterialRatio => {
        return rawMaterialRatio.rawMaterialId !== removedRawMaterialId;
      });
      rawMaterialIds.map((rawMaterialId, index) => {
        _.set(newRawMaterialRatios, `${index}.order`, index + 1);
      });
    }
    // when adding raw materials and ratios
    else if (arrayRawMaterialId.length > 0) {
      newRawMaterialRatios = arrayRawMaterialId.map((rawMaterialId, index) => {
        const ratio = _.get(rawMaterialRatios, `${index}.ratio`, null);
        const rawMaterialRatioId = _.get(
          rawMaterialRatios,
          `${index}.id`,
          null
        );
        const order = _.get(rawMaterialRatios, `${index}.order`, index + 1);

        const submitObject = {
          ratio: ratio !== null ? ratio : 0,
          rawMaterialId,
          order
        };

        return rawMaterialRatioId === null
          ? submitObject
          : Object.assign(submitObject, { id: rawMaterialRatioId });
      });
    }

    // Set ratios to parent component (Form)
    setRawMaterialRatios(newRawMaterialRatios);
  };

  const onChangeRawMaterialRatio = (e, idx) => {
    const { value } = e.target;
    const val = changeCommaToPoint(value);
    const cloneRawMaterialRatios = JSON.parse(
      JSON.stringify(rawMaterialRatios)
    ); // remove references of deep objects
    const newRatio = !val ? 0 : val / 100;
    _.set(cloneRawMaterialRatios, `${idx}.ratio`, newRatio);
    _.set(cloneRawMaterialRatios, `${idx}.rawRatioValue`, value);
    setRawMaterialRatios(cloneRawMaterialRatios);
  };
  const [flag, setFlag] = useState(false);
  const onBlurOrder = (inputOrder, changedItemIndex) => {
    const cloneRawMaterialRatios = JSON.parse(
      JSON.stringify(rawMaterialRatios)
    );
    if (flag) {
      if (inputOrder.target.value !== '') {
        const value = parseInt(inputOrder.target.value);
        const isDecreasing = value < focusedValue.current;
        rawMaterialRatios.forEach((ratio, index) => {
          const { order: currentOrder } = ratio;
          let itemNewOrder = currentOrder;
          if (isDecreasing) {
            // if it's not currently the changed item, it's order go forward
            itemNewOrder =
              index !== changedItemIndex && itemNewOrder >= value
                ? itemNewOrder + 1
                : itemNewOrder;
            _.set(cloneRawMaterialRatios, `${index}.order`, itemNewOrder);
          } else if (index !== changedItemIndex) {
            // if it's not currently the changed item, it's order go forward
            if (itemNewOrder === value) itemNewOrder -= 1;
            else if (itemNewOrder > value) itemNewOrder += 1;
            _.set(cloneRawMaterialRatios, `${index}.order`, itemNewOrder);
          }
        });
      } else {
        _.set(
          cloneRawMaterialRatios,
          `${changedItemIndex}.order`,
          parseInt(focusedValue.current)
        );
      }
      const sortedRawMaterialRatios = cloneRawMaterialRatios.sort(
        (a, b) => a.order - b.order
      );
      sortedRawMaterialRatios.forEach((rawMaterialRatio, index) => {
        rawMaterialRatio.order = index + 1;
      });
      setRawMaterialRatios(sortedRawMaterialRatios);
    }
    setFlag(false);
  };
  const onChangeOrder = (e, idx) => {
    setFlag(true);
    const cloneRawMaterialRatios = JSON.parse(
      JSON.stringify(rawMaterialRatios)
    );
    _.set(cloneRawMaterialRatios, `${idx}.order`, parseInt(e.target.value));
    setRawMaterialRatios(cloneRawMaterialRatios);
  };

  const onFocusOrder = oldValue => {
    focusedValue.current = parseInt(oldValue.target.value);
  };

  const renderRawMaterialRatios = () => {
    if (!rawMaterialRatios || !rawMaterialRatios.length) return null;

    return rawMaterialRatios.map((rawMaterialRatio, idx) => {
      const ratio = +(rawMaterialRatio.ratio * 100).toFixed(12);
      const ratioValue = changeCommaToPointWhenValueNumber(ratio);
      const ratioOrderDisplay = idx + 1;
      return (
        <div key={rawMaterialRatio.rawMaterialId} className="single-field">
          <Input
            defaultValue={ratioOrderDisplay}
            value={rawMaterialRatio.order}
            onChange={e => onChangeOrder(e, idx)}
            onBlur={e => onBlurOrder(e, idx)}
            type="number"
            className="order-column"
            onFocus={oldValue => onFocusOrder(oldValue)}
            disabled={disabled}
          />
          <div className="label">
            {mappingRawMaterial[rawMaterialRatio.rawMaterialId]}
          </div>
          <Input
            defaultValue={ratioValue}
            value={rawMaterialRatio.rawRatioValue || ratioValue}
            onChange={e => onChangeRawMaterialRatio(e, idx)}
            addonAfter="%"
            disabled={disabled}
          />
        </div>
      );
    });
  };

  const getRawMaterialName = () => {
    const rawMaterialNames = new Map();
    rawMaterials.forEach(item => {
      if (selectedRawMaterialIds.includes(item.id)) {
        rawMaterialNames.set(item.id, item.name);
      }
    });
    return selectedRawMaterialIds.map(id => ({
      id,
      name: rawMaterialNames.get(id)
    }));
  };

  return (
    <div id="formula-selection-parent-node">
      <VirtualizedSelect
        options={rawMaterials}
        onChange={values => handleOnChange(values)}
        value={getRawMaterialName()}
        valueKey="id"
        labelKey="name"
        multi
        placeholder={placeholder}
        closeOnSelect={false}
        clearable={false}
        className="virtualizedSelect-style"
        disabled={disabled}
      />
      <div className="formular-selection">
        {/* All raw materials */}
        {renderRawMaterialRatios()}
        {/* Total */}
        {selectedRawMaterialIds.length > 0 && (
          <div className="single-field">
            <div className="total-label">
              <FormattedMessage id="formula.total" defaultMessage="Total" />
            </div>
            <div className="value-total">{+totalPercent.toFixed(12)}</div>
            <div className="percent-sign">%</div>
          </div>
        )}
      </div>
    </div>
  );
};

FormularSelection.propTypes = {
  rawMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.node.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

FormularSelection.defaultProps = {
  value: null,
  onChange: () => {},
  disabled: false
};

export default FormularSelection;
