export const pdfExtension = 'pdf';

export const backgroundFillCell = {
  fontSize: 9,
  textColor: '#000',
  fillColor: '#dcdcdc',
  valign: 'middle',
  lineWidth: 0.5,
  lineColor: '#c7c7c7'
};

export const editableFile = 'Editable';

export const uneditableFile = 'Uneditable';

export const formula = 'Formula';

export const formulaExternal ='FormulaExternal';

export const initialProductionTestProtocol = 'InitialProductionTestProtocol';

export const productionTestProtocol = 'ProductionTestProtocol';

export const measurementDataBlanked = 'MeasurementDataBlanked';

export const measurementDataFilledOut = 'MeasurementDataFilledOut';
