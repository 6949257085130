import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import { groupColumns as columns } from './renderColumns';
import { ACTION_BUTTON_TYPES } from '../../../../configs/constant';
import { closest } from '../../../../utils/common';

const List = props => {
  const { pagination, listGroups, loading } = useSelector(state => ({
    pagination: state.rawMaterialGroup.list.data.pagination,
    listGroups: state.rawMaterialGroup.list.data.results,
    loading: state.rawMaterialGroup.fetching
  }));

  const { onDeleteGroup, onEditGroup, onPageSizeChange, onPageChange } = props;

  const onClickRow = (event, record) => {
    const isDeleteEvent = closest(
      event.target,
      `.${ACTION_BUTTON_TYPES.DELETE_RAW_MATERIAL_GROUP}`
    );
    const isEditEvent = closest(
      event.target,
      `.${ACTION_BUTTON_TYPES.EDIT_RAW_MATERIAL_GROUP}`
    );

    if (isDeleteEvent) {
      onDeleteGroup(record);
    } else if (isEditEvent) {
      onEditGroup(record);
    }
  };

  return (
    <div className="table-raw-material-group">
      <BaseTable
        pagination={pagination}
        onRow={record => {
          return {
            onClick: event => onClickRow(event, record)
          };
        }}
        columns={columns}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        dataSource={listGroups}
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        bordered
        stickyHeader
      />
    </div>
  );
};

List.propTypes = {
  onDeleteGroup: PropTypes.func.isRequired,
  onEditGroup: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default List;
