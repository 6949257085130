import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13">
      <path
        fill="#646464"
        fillRule="nonzero"
        d="M6.698 12.98l8.12-11.256L12.802.268 5.998 9.704 2.064 7.436.818 9.606z"
      />
    </svg>
  );
};
