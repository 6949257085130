import {
  MEASUREMENT_MAPPING_FIELD,
  MAPPING_ASF_MEASURED_LABELS
} from '../../../../../configs/constant';

const generate = measurement => {
  const chemicalOxygenDemandInfo = { ...measurement.chemicalOxygenDemand };
  const viscosityTwentyCInfo = { ...measurement.viscosityTwentyC };
  const adapterInfo = { ...measurement.adapter };
  const rotationalSpeedInfo = { ...measurement.rotationalSpeed };
  const freezingPointCInfo = { ...measurement.freezingPointC };
  const asfOenorm5106Info = {
    ...measurement.asfOenorm5106
  };
  const temperature = { ...measurement.temperature };

  return [
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.viscosityTwentyC.key,
      measured: viscosityTwentyCInfo.measured,
      comment: viscosityTwentyCInfo.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.temperature.key,
      measured: temperature.measured,
      comment: temperature.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.rotationalSpeed.key,
      measured: rotationalSpeedInfo.measured,
      comment: rotationalSpeedInfo.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.adapter.key,
      measured: adapterInfo.measured,
      comment: adapterInfo.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.freezingPointC.key,
      measured: freezingPointCInfo.measured,
      comment: freezingPointCInfo.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.chemicalOxygenDemand.key,
      measured: chemicalOxygenDemandInfo.measured,
      comment: chemicalOxygenDemandInfo.comment
    },
    {
      cellKeyOther: MEASUREMENT_MAPPING_FIELD.asfOenorm5106.key,
      measured: MAPPING_ASF_MEASURED_LABELS[asfOenorm5106Info.measured],
      comment: asfOenorm5106Info.comment
    },
  ];
};

export default generate;
