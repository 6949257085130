import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

const Search = props => {
  const dispatch = useDispatch();

  const { getData, pagination, order, filterList } = props;
  const { formatMessage } = props.intl;
  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');
    pagination.current = 1;
    dispatch(
      getData({
        pagination,
        searchByText: valueChange,
        order,
        filterList
      })
    );
  };
  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(getData({ pagination, searchByText: '', order, filterList }));
    }
  };
  return (
    <Input.Search
      className="search-place"
      placeholder={formatMessage({
        id: 'header.search',
        defaultMessage: 'Search'
      })}
      onChange={e => onChangeSearch(e)}
      onSearch={value => onSearchList(value)}
    />
  );
};

export default injectIntl(Search);
