import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { getProductionCycleHistoryByFormula } from '../../../../../data/detergent/actions';
import { columns } from './renderColumns';

const ProductionCycleHistory = ({ formulaId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { fetching, productionCycleHistoryData } = useSelector(state => ({
    fetching: state.detergent.productionCycleHistoryList.fetching,
    productionCycleHistoryData: state.detergent.productionCycleHistoryList.data
  }));

  useEffect(() => {
    dispatch(getProductionCycleHistoryByFormula({ formulaId }));
  }, [dispatch]);

  return (
    <BaseTable
      className="detergent-production-cycle-history"
      dataSource={productionCycleHistoryData}
      columns={columns(intl)}
      bordered
      loading={fetching && { indicator: <Icon type="sync" spin /> }}
    />
  );
};

ProductionCycleHistory.propTypes = {
  formulaId: PropTypes.number.isRequired
};

export default ProductionCycleHistory;
