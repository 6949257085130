import React from 'react';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../../components/BaseTable';
import { renderAnnualDemandColumns } from '../ViewDetail/renderAnnualDemandColumns';

export const EditAnnualDemand = props => {
  const {
    results,
    density,
    onChangeTableField,
    yearList,
    restrictedYears,
    handleRemoveAnnualDemand
  } = props;

  return (
    <BaseTable
      className="annual-demand"
      bordered
      columns={renderAnnualDemandColumns({
        density,
        isEditable: true,
        onChangeTableField,
        yearList,
        restrictedYears,
        handleRemoveAnnualDemand
      })}
      dataSource={results}
    />
  );
};

EditAnnualDemand.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  density: PropTypes.number.isRequired,
  onChangeTableField: PropTypes.func.isRequired,
  yearList: PropTypes.arrayOf(PropTypes.number).isRequired,
  restrictedYears: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleRemoveAnnualDemand: PropTypes.func.isRequired
};
