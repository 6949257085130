import React from 'react';
import { Icon, Menu, Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const SortProductionCycle = ({ order, onSortChange }) => {
  const SortPlace = (
    <Menu className="menu-sort">
      <Menu.Item
        key="0"
        className="sort-item"
        onClick={() => onSortChange('id')}>
        <FormattedMessage
          id="common.sortByCycleId"
          defaultMessage="Sort by cycle ID"
        />{' '}
        {order.id === 'DESC' ? <Icon type="check" /> : ''}
      </Menu.Item>
      <Menu.Item
        key="1"
        className="sort-item"
        onClick={() => onSortChange('detergentName')}>
        <FormattedMessage
          id="common.sortByDetergentName"
          defaultMessage="Sort by detergent name"
        />{' '}
        {order.detergentName === 'ASC' ? <Icon type="check" /> : ''}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      getPopupContainer={triggerNode => triggerNode.parentNode}
      className="sort-dropdown"
      overlay={SortPlace}
      placement="bottomRight"
      trigger={['click']}
      overlayClassName="content-sort-form">
      <div className="ant-dropdown-link">
        <div className="edit_caret">
          <Icon type="caret-up" />
          <Icon type="caret-down" />
        </div>
        <div className="sort">
          <FormattedMessage id="header.sort" defaultMessage="Sort" />
        </div>
      </div>
    </Dropdown>
  );
};

SortProductionCycle.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    createAt: PropTypes.string,
    detergentName: PropTypes.string
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
};
