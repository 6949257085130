import {
  GET_CONTAINER_TYPE,
  GET_CONTAINER_TYPE_ERROR,
  GET_CONTAINER_TYPE_SUCCESS,
  CLEAR_CONTAINER_TYPE_DATA_WHEN_UNMOUNT,
  CHANGE_TABLE_PAGE,
  CHANGE_TABLE_PAGE_SIZE,
  SET_FILTER_LIST,
  EXPORT_CONTAINER_TYPE,
  EXPORT_CONTAINER_TYPE_SUCCESS,
  EXPORT_CONTAINER_TYPE_ERROR,
  CLEAR_FILTER_LIST
} from './actions';
import { getAnnualDemandDataListByYears } from '../../../../utils/common';
import {
  DEFAULT_PAGE_SIZE_VALUE,
  DEFAULT_PAGE_VALUE
} from '../../../../configs/constant';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: DEFAULT_PAGE_SIZE_VALUE,
        current: DEFAULT_PAGE_VALUE
      }
    },
    fetching: false
  },
  filterList: {
    containerGroup: '',
    detergentProducer: '',
    containerSize: ''
  },
  order: {
    partNumber: 'ASC'
  },
  export: {
    fetching: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTAINER_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case GET_CONTAINER_TYPE_SUCCESS: {
      const results = action.payload.data.results?.map(result => ({
        ...result,
        years: getAnnualDemandDataListByYears(
          result?.annualDemands,
          result?.annualDemands?.length
        )
      }));

      return {
        ...state,
        list: {
          ...state.list,
          data: {
            pagination: action.payload.data.pagination,
            results
          },
          fetching: false
        }
      };
    }
    case GET_CONTAINER_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    case CHANGE_TABLE_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination: {
              ...state.list.data.pagination,
              current: page
            }
          }
        }
      };
    }

    case CHANGE_TABLE_PAGE_SIZE: {
      const { pageSize } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination: {
              ...state.list.data.pagination,
              current: DEFAULT_PAGE_VALUE,
              pageSize
            }
          }
        }
      };
    }

    case SET_FILTER_LIST: {
      const { filterList } = action.payload;
      const newFilterList = Object.fromEntries(
        Object.entries(filterList).filter(([, value]) => !!value)
      );
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination: {
              ...state.list.data.pagination,
              current: DEFAULT_PAGE_VALUE
            }
          }
        },
        filterList: {
          ...state.filterList,
          ...newFilterList
        }
      };
    }

    case CLEAR_FILTER_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            pagination: {
              ...state.list.data.pagination,
              current: DEFAULT_PAGE_VALUE
            }
          }
        },
        filterList: {
          ...initialState.filterList
        }
      };

    case EXPORT_CONTAINER_TYPE:
      return {
        ...state,
        export: {
          fetching: true
        }
      };
    case EXPORT_CONTAINER_TYPE_SUCCESS:
    case EXPORT_CONTAINER_TYPE_ERROR:
      return {
        ...state,
        export: {
          fetching: false
        }
      };
    case CLEAR_CONTAINER_TYPE_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
