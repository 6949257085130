export const testTableId = 'measurement-testTableId';
export const phValueTableId = 'measurement-phValueTableId';
export const safetyTableId = 'measurement-safetyTableId';
export const storageTestTableId = 'measurement-storageTestTableId';
export const otherTableId = 'measurement-otherTableId';
export const otherAppendixTableId = 'measurement-otherAppendixTableId';
export const formulaMetaDataTableId = 'measurement-formulaMetaDataTableId';

export const measurementMeasuredMinMaxCommentTableColumnWidth = {
  0: { cellWidth: 150 },
  1: { cellWidth: 90, halign: 'right' },
  2: { cellWidth: 45, halign: 'right' },
  3: { cellWidth: 45, halign: 'right' }
};

export const storageTestTableColumnWidth = {
  0: { cellWidth: 175 },
  1: { cellWidth: 70, halign: 'center' },
  2: { cellWidth: 85, halign: 'center' }
};

export const otherTableColumnWidth = {
  0: { cellWidth: 230 },
  1: { cellWidth: 100, halign: 'right' }
};

export const otherAppendixTableColumnWidth = {
  0: { cellWidth: 240 },
  1: { cellWidth: 45, halign: 'center' },
  2: { cellWidth: 45, halign: 'center' }
};
