import React from 'react';
import PropType from 'prop-types';

const Label = ({ label, labelClass, className }) => {
  return (
    <span
      style={{ width: '35%', display: 'inline-block' }}
      className={className}>
      {label}
    </span>
  );
};

Label.propTypes = {
  label: PropType.string.isRequired,
  labelClass: PropType.string
};

Label.defaultProps = {
  labelClass: ''
};

export default Label;
