import { AxiosService } from '../../../../services/axiosService';

export const updateCognitoUserApi = data => {
  return AxiosService.put('/internalUser/user', data);
};

export const getCognitoUserApi = () => {
  return AxiosService.get('/internalUser/list');
};

export const getKaercherUserApi = searchValue => {
  return AxiosService.get(`/employee/${encodeURI(searchValue)}`);
};

export const addKaercherUserApi = data => {
  return AxiosService.post('/internalUser/user', data);
};
