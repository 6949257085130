export const PART_NUMBERS_MODAL = 'PART_NUMBERS_MODAL';
export const GET_PART_NUMBER_BY_ID = 'GET_PART_NUMBER_BY_ID';
export const GET_PART_NUMBER_BY_ID_SUCCESS = 'GET_PART_NUMBER_BY_ID_SUCCESS';
export const GET_PART_NUMBER_BY_ID_ERROR = 'GET_PART_NUMBER_BY_ID_ERROR';

export const GET_PART_NUMBER_HISTORY = 'GET_PART_NUMBER_HISTORY';
export const GET_PART_NUMBER_HISTORY_SUCCESS =
  'GET_PART_NUMBER_HISTORY_SUCCESS';
export const GET_PART_NUMBER_HISTORY_ERROR = 'GET_PART_NUMBER_HISTORY_ERROR';

export const CREATE_PART_NUMBER = 'CREATE_PART_NUMBER';
export const CREATE_PART_NUMBER_SUCCESS = 'CREATE_PART_NUMBER_SUCCESS';
export const CREATE_PART_NUMBER_ERROR = 'CREATE_PART_NUMBER_ERROR';
export const GET_PART_NUMBER = 'GET_PART_NUMBER';
export const GET_PART_NUMBER_SUCCESS = 'GET_PART_NUMBER_SUCCESS';
export const GET_PART_NUMBER_ERROR = 'GET_PART_NUMBER_ERROR';

export const UPDATE_PART_NUMBER = 'UPDATE_PART_NUMBER';
export const UPDATE_PART_NUMBER_SUCCESS = 'UPDATE_PART_NUMBER_SUCCESS';
export const UPDATE_PART_NUMBER_ERROR = 'UPDATE_PART_NUMBER_ERROR';

export const MIGRATE_ANNUAL_DEMAND = 'MIGRATE_ANNUAL_DEMAND';
export const MIGRATE_ANNUAL_DEMAND_SUCCESS = 'MIGRATE_ANNUAL_DEMAND_SUCCESS';
export const MIGRATE_ANNUAL_DEMAND_ERROR = 'MIGRATE_ANNUAL_DEMAND_ERROR';

export const PATCH_PART_NUMBER = 'PATCH_PART_NUMBER';
export const PATCH_PART_NUMBER_SUCCESS = 'PATCH_PART_NUMBER_SUCCESS';
export const PATCH_PART_NUMBER_ERROR = 'PATCH_PART_NUMBER_ERROR';

export const SET_SELECTED_DROPDOWN_FIELD_FOR_PART_NUMBER =
  'SET_SELECTED_DROPDOWN_FIELD_FOR_PART_NUMBER';

export const CLEAR_PART_NUMBERS_DATA_WHEN_UNMOUNT =
  'CLEAR_PART_NUMBERS_DATA_WHEN_UNMOUNT';

export const processPartNumbersModal = payload => ({
  type: PART_NUMBERS_MODAL,
  payload
});

export const getPartNumberById = payload => ({
  type: GET_PART_NUMBER_BY_ID,
  payload
});

export const getPartNumberByIdSuccess = payload => ({
  type: GET_PART_NUMBER_BY_ID_SUCCESS,
  payload
});

export const getPartNumberByIdError = payload => ({
  type: GET_PART_NUMBER_BY_ID_ERROR,
  payload
});

export const getPartNumberHistory = payload => ({
  type: GET_PART_NUMBER_HISTORY,
  payload
});

export const getPartNumberHistorySuccess = payload => ({
  type: GET_PART_NUMBER_HISTORY_SUCCESS,
  payload
});

export const getPartNumberHistoryError = payload => ({
  type: GET_PART_NUMBER_HISTORY_ERROR,
  payload
});

// Fetch part number
export const getPartNumber = payload => ({
  type: GET_PART_NUMBER,
  payload
});

export const getPartNumberSuccess = payload => ({
  type: GET_PART_NUMBER_SUCCESS,
  payload
});

export const getPartNumberError = payload => ({
  type: GET_PART_NUMBER_ERROR,
  payload
});

export const createPartNumber = payload => ({
  type: CREATE_PART_NUMBER,
  payload
});

export const createPartNumberSuccess = payload => ({
  type: CREATE_PART_NUMBER_SUCCESS,
  payload
});

export const createPartNumberError = payload => ({
  type: CREATE_PART_NUMBER_ERROR,
  payload
});

export const updatePartNumber = payload => ({
  type: UPDATE_PART_NUMBER,
  payload
});

export const updatePartNumberSuccess = payload => ({
  type: UPDATE_PART_NUMBER_SUCCESS,
  payload
});

export const updatePartNumberError = payload => ({
  type: UPDATE_PART_NUMBER_ERROR,
  payload
});

export const patchPartNumber = ({ id, data }) => ({
  type: PATCH_PART_NUMBER,
  payload: {
    id,
    data
  }
});

export const patchPartNumberSuccess = payload => ({
  type: PATCH_PART_NUMBER_SUCCESS,
  payload
});

export const patchPartNumberError = payload => ({
  type: PATCH_PART_NUMBER_ERROR,
  payload
});

export const migrateAnnualDemand = payload => ({
  type: MIGRATE_ANNUAL_DEMAND,
  payload
});

export const migrateAnnualDemandSuccess = payload => ({
  type: MIGRATE_ANNUAL_DEMAND_SUCCESS,
  payload
});

export const migrateAnnualDemandError = payload => ({
  type: MIGRATE_ANNUAL_DEMAND_ERROR,
  payload
});

export const setSelectedDropdownFieldForPartNumber = payload => ({
  type: SET_SELECTED_DROPDOWN_FIELD_FOR_PART_NUMBER,
  payload
});

export const clearPartNumbersDataWhenUnmount = () => ({
  type: CLEAR_PART_NUMBERS_DATA_WHEN_UNMOUNT
});
