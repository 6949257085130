import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Icon, Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  DETERGENT_PRODUCER_STATUS,
  DISPLAY_COUNTRY
} from '../../../../configs/constant';
import { getRawMaterialProducers } from '../../data/rawMaterialProducers/actions';
import useCountriesByRole from '../../../../hooks/useCountriesByRole';

const _FilterForm = props => {
  const dispatch = useDispatch();
  const countries = useCountriesByRole();
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    onCancel
  } = props;
  const { filterList, pagination, searchByText, order } = useSelector(
    state => ({
      filterList: state.rawMaterialProducers.filterList,
      pagination: state.rawMaterialProducers.list.data.pagination,
      searchByText: state.rawMaterialProducers.searchByText,
      order: state.rawMaterialProducers.order
    })
  );

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onCancel();
        pagination.current = 1;
        dispatch(
          getRawMaterialProducers({
            pagination,
            searchByText,
            order,
            filterList: values
          })
        );
      }
    });
  };

  const onClearFilter = () => {
    resetFields();
    const keys = Object.keys(filterList);
    keys.forEach(element => {
      filterList[element] = '';
    });
    pagination.current = 1;
    dispatch(
      getRawMaterialProducers({ pagination, searchByText, order, filterList })
    );
    onCancel();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 15 }
    }
  };
  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onSubmit={handleSubmit}>
      <Form.Item
        label={
          <FormattedMessage
            id="rawMaterialProducer.filter"
            defaultMessage="Producer/Supplier status"
          />
        }>
        <div id="rmproducer-filter-status">
          {getFieldDecorator('status', {
            initialValue: filterList.status ? filterList.status : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('rmproducer-filter-status')
              }
              placeholder={
                <FormattedMessage
                  id="partNumbers.selectStatus"
                  defaultMessage="Select status"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {DETERGENT_PRODUCER_STATUS.map(psItem => (
                <Select.Option key={psItem.value} value={psItem.value}>
                  {psItem.nameDisplay}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Form.Item
        label={
          <FormattedMessage id="common.fromCountry" defaultMessage="Country" />
        }>
        <div id="rawMaterialProducer-country-filter">
          {getFieldDecorator('fromCountryId', {
            initialValue: filterList.fromCountryId
              ? filterList.fromCountryId
              : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('rawMaterialProducer-country-filter')
              }
              className="filter-select"
              placeholder={
                <FormattedMessage
                  id="common.selectCountry"
                  defaultMessage="Select country"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {countries.map(country => (
                <Select.Option key={country.value} value={country.value}>
                  {DISPLAY_COUNTRY[country.value]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button className="btn-cancel" onClick={() => onClearFilter()}>
              <FormattedMessage
                id="common.clearFilter"
                defaultMessage="CLEAR FILTER"
              />
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              <FormattedMessage id="common.okButton" defaultMessage="OK" />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export const FilterForm = Form.create({
  name: 'filter-raw-material-producers-form'
})(_FilterForm);

_FilterForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func.isRequired
};
