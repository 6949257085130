import PropType from 'prop-types';
import React from 'react';
import BlankMeasurementDataSheetPhValueTable from './BlankMeasurementDataSheetPhValueTable';
import BlankMeasurementDataSheetSafetyTable from './BlankMeasurementDataSheetSafetyTable';
import BlankMeasurementDataSheetStorageTestTable from './BlankMeasurementDataSheetStorageTestTable';
import BlankMeasurementDataSheetTestTable from './BlankMeasurementDataSheetTestTable';
import BlankMeasurementDataSheetOtherTable from './BlankMeasurementDataSheetOtherTable';

const BlankMeasurementDataSheetTable = ({ measurement }) => {
  return (
    <>
      <BlankMeasurementDataSheetTestTable measurement={measurement} />

      <BlankMeasurementDataSheetPhValueTable measurement={measurement} />

      <BlankMeasurementDataSheetSafetyTable measurement={measurement} />

      <BlankMeasurementDataSheetStorageTestTable measurement={measurement} />
      <BlankMeasurementDataSheetOtherTable measurement={measurement} />
    </>
  );
};

BlankMeasurementDataSheetTable.propTypes = {
  measurement: PropType.shape({}).isRequired,
  formula: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetTable;
