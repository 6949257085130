import { Form } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  COGNITO_USER_ATTRIBUTES,
  DATE_FORMAT_YYYY_MM_DD,
  FAKE_USER_NAMES,
  FORMULA_ENUM_FORM,
  HISTORY_TYPES,
  STATUS_ENUM,
  URL_QUERY_NAMES,
  USER_ROLES,
  COUNTRY,
  COUNTRY_BY_ROLE_KNA,
  COUNTRY_BY_ROLE_DE
} from '../configs/constant';
import { showErrorToast } from '../services/toasterService';
/**
 * getToken()
 * Return the authorization token from the local storage
 */
export function getToken() {
  // TODO: Will be defined the logic
  return 'sgfgwuiegfuigsiufb432384876sdfuisdfsgfgwuiegfuigsiufb432384876sdfuisdfsgfgwuiegfuigsiufb432384876sdfuisdfsgfgwuiegfuigsiufb432384876sdfuisdfsgfgwuiegfuigsiufb432384876sdfuisdf';
}
/**
 * transformFields()
 * Use form Transform nested fields for Antd Form
 */
export const transformFields = obj => {
  return Object.keys(obj).reduce((acc, cv) => {
    return {
      ...acc,
      [cv]:
        typeof obj[cv] === 'object' && !('value' in obj[cv])
          ? _.isArray(obj[cv])
            ? obj[cv].map(item => transformFields(item))
            : transformFields(obj[cv])
          : Form.createFormField({
              ...obj[cv],
              value: obj[cv]
            })
    };
  }, {});
};

export const filterObject = (obj, keys = []) => {
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === 'object') {
      filterObject(obj[i], keys);
    } else if (keys.indexOf(i) > -1) {
      delete obj[i];
    }
  }

  return obj;
};

export const IGNORES_UPDATE_FIELDS = [
  'createAt',
  'updateAt',
  'allowUpdateFields'
];

export function prepareDataBeforeUpdate(object, cloneBeforeClean = false) {
  try {
    let json = object;
    if (cloneBeforeClean) {
      json = JSON.parse(JSON.stringify(object));
    }

    json = filterObject(object, IGNORES_UPDATE_FIELDS);

    return json;
  } catch (error) {
    return {};
  }
}

export function errorHandle(error) {
  let message = 'error.errorMessageDefault';
  const errorCode = error?.response?.data?.code;
  const responseData =
    error?.response?.data?.data || error?.response?.data || error?.message;
  let responseDataMessage = responseData;
  let responseDataDetail;

  // [{message: '...'}]
  // [{constraints: {...}}]
  if (Array.isArray(responseData) && responseData.length) {
    const errorObject = responseData[0] || {};
    responseDataMessage = null;

    if (errorObject?.error?.detail) {
      responseDataDetail = errorObject?.error?.detail;
    }

    if (errorObject.constraints) {
      responseDataMessage = Object.values(errorObject.constraints).join('\n');
    } else if (errorObject?.error?.message) {
      responseDataMessage = errorObject?.error?.message;
    }
  }

  // {message: '...'}
  if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
    responseDataMessage = responseData.message;
  }
  if (responseDataMessage) {
    const regexCheckDuplicateNameField = /duplicate key value violates unique constraint \".*?(_name_|part_number_unique_idx)?/i;
    if (
      responseDataMessage === 'data duplication' ||
      responseDataMessage.startsWith('Duplicate key of') ||
      regexCheckDuplicateNameField.test(responseDataMessage)
    ) {
      message = 'error.errorMessageDuplicate';
    }
    if (
      responseDataMessage.indexOf(
        'duplicate key value violates unique constraint "detergent_number_unique_idx"'
      ) !== -1
    ) {
      message = 'error.errorMessageDuplicateNumber';
    }
    if (
      responseDataMessage.indexOf('invalid input syntax for integer') !== -1
    ) {
      message = 'error.errorMessageInteger';
    }
    if (
      responseDataMessage.indexOf(
        'density of detergent formula must be existed'
      ) !== -1
    ) {
      message = 'error.densityInPartNumberIsNull';
    }
    if (
      responseDataMessage.indexOf('Part number') !== -1 &&
      responseDataMessage.indexOf('inactive') !== -1
    ) {
      message = 'error.partNumberInactive';
    }
  }

  if (responseDataDetail) {
    if (responseDataDetail?.startsWith(`Key ("erpNumber")`)) {
      message = 'error.errorMessageDuplicateErpNumber';
    }
  }

  if (errorCode === 'UsernameExistsException') {
    message = 'error.errorUserAlreadyExists';
  }

  showErrorToast(message);
}

export function uniqId() {
  return Math.floor(Math.random(1, +new Date()) * 1000000000000);
}

export function getDeselectedListItems(items = [], key, values = []) {
  if (!items || !items.length) return [];

  return items.filter(item => {
    let index;
    if (item.hasOwnProperty(key)) {
      index = values.indexOf(item.name);
    }
    return index === -1;
  });
}

export const processArraySelectValues = (data = [], values = []) => {
  // PROCESS array values then return new_array with the "id" and "name"
  const new_array = [];

  for (let i = 0; i < data.length; i++) {
    const dataName = [];
    dataName.push(data[i].name);
    values.forEach(val => {
      if (dataName.find(element => element) === val) {
        new_array.push({ id: data[i].id, name: data[i].name });
      }
    });
  }
  return new_array;
};

export function convertJsonToFormData(json) {
  const formData = new FormData();

  if (_.isEmpty(json)) {
    return formData;
  }

  function getFormData(formData, data, previousKey) {
    if (data instanceof Object) {
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (
          value instanceof Object &&
          !Array.isArray(value) &&
          !(value instanceof File)
        ) {
          return getFormData(formData, value, key);
        }

        if (previousKey) {
          key = `${previousKey}[${key}]`;
        }
        if (Array.isArray(value)) {
          value.forEach((val, i) => {
            if (val instanceof Object && !Array.isArray(val)) {
              return getFormData(formData, val, `${key}[${i}]`);
            }
            formData.append(`${key}[]`, val);
          });
        } else {
          formData.append(key, value);
        }
      });
    }
  }

  getFormData(formData, json);

  return formData;
}

export function closest(el, selector) {
  let matchesFn;

  // find vendor prefix
  [
    'matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector'
  ].some(function(fn) {
    if (typeof document.body[fn] === 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  let parent;

  // traverse parents
  while (el) {
    parent = el.parentElement;
    if (parent && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
}

export const orderByField = (_array, _field, _type) => {
  return _type === 'DES'
    ? _array.sort((a, b) => b[_field] - a[_field])
    : _array.sort((a, b) => a[_field] - b[_field]);
};

export const trimStringFieldsInObject = object => {
  const newObject = {};
  if (!object) return newObject;

  for (const key in object) {
    if (!object.hasOwnProperty(key)) continue;

    if (typeof object[key] === 'string') {
      newObject[key] = String(object[key]).trim();
    } else {
      newObject[key] = object[key];
    }
  }

  return newObject;
};

export const trimmedArrayElement = array => {
  return array.map(element => element.trim());
};

export const locationString = (
  street,
  street_number,
  postal_code,
  city,
  country
) => {
  if (postal_code == null) postal_code = '';
  const locationAddressPattern = [
    `${street} ${street_number}`,
    `${postal_code} ${city}`,
    `${country}`
  ];
  let locationAddress = '';

  if (street === '' && street_number === '') {
    locationAddressPattern.splice(0, 1);
    if (country === '' || (postal_code === '' && city === ''))
      locationAddress = trimmedArrayElement(locationAddressPattern).join('');
    else
      locationAddress = trimmedArrayElement(locationAddressPattern).join(', ');
  } else if (postal_code === '' && city === '' && country === '') {
    locationAddressPattern.splice(1, 2);
    locationAddress = trimmedArrayElement(locationAddressPattern).join('');
  } else {
    if (postal_code === '' && city === '') {
      locationAddressPattern.splice(1, 1);
    } else if (country === '') {
      locationAddressPattern.splice(2);
    }
    locationAddress = trimmedArrayElement(locationAddressPattern).join(', ');
  }

  return locationAddress;
};

export const roundDecimalNumber = (number, digits) => {
  const parseToInteger = Number.parseFloat(number, 10);
  if (Number.isNaN(parseToInteger)) return '';

  return parseToInteger.toFixed(digits);
};

export const priceOfOneKilogramOfDetergent = rawMaterial => {
  const rawMaterialPrice = _.get(rawMaterial, 'rawMaterial.price', 0);
  return rawMaterial.ratio * rawMaterialPrice;
};

export const priceOfDetergentContainer = rawMaterial => {
  const containerSize = _.get(rawMaterial, 'containerSize', 0);

  return (
    containerSize *
    changeCommaToPoint(rawMaterial.density) *
    priceOfOneKilogramOfDetergent(rawMaterial)
  );
};

export const buildUrlQueryParams = (
  url,
  pathParams = {},
  pathName = URL_QUERY_NAMES.PARAMS
) => {
  if (!_.isEmpty(pathParams)) {
    const queryParams = encodeURIComponent(JSON.stringify(pathParams));
    const prefix = url.includes('?') ? '&' : '?';
    return [url, queryParams].join(`${prefix}${pathName}=`);
  }
  return url;
};

export const getUrlParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  const pathValue = decodeURIComponent(results[2].replace(/\+/g, ' '));

  if (/^{.*}$/.test(pathValue)) {
    // Parse to JSON object if the pathValue is JSON string
    return JSON.parse(pathValue);
  }

  return pathValue;
};

/**
 * 
 *{
    "detergent": {
      "name": {
        "oldValue": null,
        "newValue": "comment here..."
      },
      "number": {
        "oldValue": null,
        "newValue": "comment here..."
      },
    }
  }
 * @param {*} historyList 
 */

export const transformHistoryToStandardData = (rootKey, historyList = []) => {
  if (_.isEmpty(historyList)) return [];

  const blackListKeys = ['allowUpdateFields', 'url', 'id'];
  let tempHistory;

  const transform = (parentKey, object, objectFromArrayList = false) => {
    const objectData =
      !parentKey || objectFromArrayList ? object : object[parentKey];

    if (typeof objectData === 'undefined') {
      return tempHistory;
    }

    Object.keys(objectData).forEach(childKey => {
      if (
        !Object.prototype.hasOwnProperty.call(objectData, childKey) ||
        blackListKeys.includes(childKey)
      ) {
        return;
      }

      const objectValue = objectData[childKey];
      const newKey = parentKey ? `${parentKey}.${childKey}` : childKey;

      if (_.isPlainObject(objectValue)) {
        const { oldValue, newValue } = objectValue;

        if (oldValue || newValue) {
          // value is end object
          const compare = {
            key: newKey,
            oldValue,
            newValue
          };

          tempHistory.push(compare);
        } else {
          // value is deep object
          transform(newKey, objectValue, true);
        }
      } else if (_.isArray(objectValue)) {
        // value is array
        objectValue.forEach(objectItem => {
          transform(newKey, objectItem, true);
        });
      }
    });

    return tempHistory;
  };

  return historyList.map(history => {
    tempHistory = [];
    const jsonData = JSON.parse(history.valueJson);
    const valueJson = transform(rootKey, jsonData);

    return {
      ...history,
      isAddedNewRecord: jsonData.type === HISTORY_TYPES.ADDED,
      valueJson
    };
  });
};

export const formatDateByMoment = (date, format = DATE_FORMAT_YYYY_MM_DD) => {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.format(format);
  }

  return date;
};

export const getRandomUserName = () => {
  return FAKE_USER_NAMES[Math.floor(Math.random() * FAKE_USER_NAMES.length)];
};

/**
 * translateDetergentHistoryByKeys
 * @param {*} keyName
 * keyName = detergent.formulas.formulaMeasurement.tabWaterLabel
 * => / / /
 */
export const translateHistoryFieldTextByKeyName = (
  { formatMessage },
  keyName,
  mappingFieldToTranslationId
) => {
  if (!keyName) return;
  const keys = keyName.split('.');
  const fieldNames = keys
    .map(key => {
      const translation = mappingFieldToTranslationId[key];

      if (translation) {
        const id = _.isObject(translation) ? translation.id : translation;
        const values = translation.values || {};

        return formatMessage({ id }, values);
      }

      return translation;
    })
    .filter(fieldName => fieldName)
    .join(' / ');
  return fieldNames;
};

export const getAnnualDemandYears = () => {
  const currentYear = new Date().getFullYear();
  const expandYears = [];

  for (let yearIndex = 1; yearIndex <= 3; yearIndex++) {
    expandYears.push(currentYear + yearIndex);
    expandYears.push(currentYear - yearIndex);
  }

  return [].concat(currentYear, expandYears).sort();
};

export const wrapDateStringWithMoment = date => {
  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    return null;
  }

  return momentDate;
};

export const getDensityLabel = formulaFormValue => {
  if (
    formulaFormValue === FORMULA_ENUM_FORM.POWDER ||
    formulaFormValue === FORMULA_ENUM_FORM.TABS
  ) {
    return (
      <FormattedMessage
        id="formula.bulkDensity"
        defaultMessage="Bulk density"
      />
    );
  }
  return <FormattedMessage id="formula.density" defaultMessage="Density" />;
};

export const getCheckboxConfig = (initialValue = null, params = {}) => ({
  valuePropName: 'checked',
  initialValue,
  ...params
});

export const getLongDateFormat = locale => {
  return moment()
    .locale(locale)
    .localeData()._longDateFormat.L;
};

export const getCognitoUserInfo = (user, type) => {
  if (user?.Attributes)
    return user?.Attributes.find(item => item?.Name === type)?.Value;
  return '';
};

export const getCognitoUserStatus = isEnabled =>
  isEnabled ? STATUS_ENUM.ACTIVE : STATUS_ENUM.IN_ACTIVE;

export const getFullNameOfCognitoUser = user => {
  const emailString = getCognitoUserInfo(user, COGNITO_USER_ATTRIBUTES.email);

  return getFullNameFromEmail(emailString);
};

export const getFullNameFromEmail = emailString => {
  if (emailString === null || emailString === undefined) return '';
  return emailString
    .split('@')[0]
    .split('.')
    .join(' ');
};

export const changePointToComma = num => {
  const string = parseFloat(num);
  const val = string.toFixed(4);
  const parts = val.toString().split('.');
  const parse =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
    (parts[1] ? `,${parts[1]}` : '');
  if (parse === 'NaN') {
    return '';
  }
  return parse;
};
export const changePointToCommaExport = num => {
  if (!num && num !== 0) return '';
  const string = num.toString();
  const parse = string.replace('.', ',');
  return parse;
};

export const changePointToCommaWithDecimals = (num, digits) => {
  if (!num && num !== 0) return '';
  const string = num.toFixed(digits);
  return string.replace('.', ',');
};

export const changeCommaToPoint = string => {
  if (typeof string !== 'string') {
    string = String(string);
  }

  const parse = string.replace(',', '.');
  return parseFloat(parse);
};
export const changeCommaToPointPrice = string => {
  if (typeof string !== 'string') {
    string = String(string);
  }

  const parse = string.replace(',', '.');
  if (parse === 'null') {
    return null;
  }
  return parse;
};
export const changeCommaToPointWhenValueNumber = num => {
  if (num === null) {
    return '';
  }
  if (typeof num !== 'string') {
    num = String(num);
  }
  const parse = num.replace('.', ',');
  return parse;
};
export const getAnnualTonnageByYear = (year, annualDemands) => {
  const annualTonnageByYear = annualDemands.filter(
    annualDemand => annualDemand?.year === year
  );

  return annualTonnageByYear.length !== 0
    ? annualTonnageByYear?.[0]?.annualTonnage
    : 0;
};

export const getTotalAnnualTonnageByYear = (year, rawMaterialRatios) => {
  let total = 0;
  rawMaterialRatios.forEach(rawMaterialRatio => {
    const ratio = rawMaterialRatio?.ratio;
    const partNumbers = rawMaterialRatio?.formula?.partNumbers;

    partNumbers.forEach(partNumber => {
      const annualDemands = partNumber?.annualDemands;
      const annualTonnage = getAnnualTonnageByYear(year, annualDemands);

      total += parseFloat(annualTonnage) * ratio;
    });
  });
  return total;
};

export const renderProducerNames = (title, firstName, lastName) => {
  if (
    typeof firstName !== 'string' ||
    typeof lastName !== 'string' ||
    (!firstName.trim() && !lastName.trim())
  ) {
    return '';
  }
  return `${title}. ${firstName} ${lastName}`;
};

export const getDensityByFormulaId = (detergentFormulas, formulaId) => {
  return detergentFormulas.find(formual => formual?.id === formulaId)?.density;
};

export const getFromCountry = (listCountry, fromCountryIds) => {
  return listCountry.find(country => country.id === fromCountryIds);
};
export const changeDateFormatToDot = () => {
  const date = new Date().toLocaleDateString();
  const currentDate = String(date);
  return currentDate.split('/').join('.');
};
export const changeRatioDotToComma = ratio => {
  return (ratio * 100).toFixed(4).replace('.', ',');
};

export const getCountriesByRole = role => {
  switch (role) {
    case USER_ROLES.ExternalDetergentProducers:
      return COUNTRY_BY_ROLE_KNA;
    case USER_ROLES.DetergentDevelopment:
    case USER_ROLES.Procurement:
    case USER_ROLES.InHouseProduction:
      return COUNTRY_BY_ROLE_DE;
    default:
      return COUNTRY;
  }
};

export const getAnnualDemandDataListByYears = (annualDemands, numberOfYear = 3) => {
  if (!annualDemands || annualDemands?.length <= 0) {
    return [];
  };

  const annualDemandDetailList = [];
  const currentYear = new Date().getFullYear();

  annualDemands.forEach(annualDemand => {
    if (
      annualDemand?.year <= currentYear &&
      !annualDemandDetailList.includes(annualDemand?.year)
    ) {
      annualDemandDetailList.push({
        year: annualDemand.year,
        annualTonnage: changePointToComma(annualDemand.annualTonnage),
        plannedContainer: annualDemand.numberOfContainers
      });
    }
  });

  return annualDemandDetailList.sort((a, b) => b.year - a.year).slice(0, numberOfYear);
};

export const downloadFile = (fileName, file, dataType) => {
  const linkSource = `${dataType},${file}`;
  const dowloadLink = document.createElement('a');

  dowloadLink.href = linkSource;
  dowloadLink.download = fileName;
  dowloadLink.style.display = 'none';

  document.body.appendChild(dowloadLink);
  dowloadLink.click();
  document.body.removeChild(dowloadLink);
};

export const capitalizeFirstLetter = string => {
  if (!string) return '';
  if (typeof string !== 'string') {
    string = String(string);
  }
  if (string.includes('\t')) {
    string = string.replace(/\t/g, ' ');
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export class DetailsError extends Error {
  constructor(message, errorExtraParams) {
    super(message);
    this._errorExtraParams = errorExtraParams;
  }

  get errorExtraParams() {
    return this._errorExtraParams;
  }
}
