import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Select, Icon } from 'antd';
import PropTypes from 'prop-types';
import {
  addKaercherUser,
  getKaecherUser
} from '../../data/userManagement/actions';
import { USER_ROLES } from '../../../../configs/constant';
import { removeMiddleSpace } from '../../../../utils/stringCommon';

const UserSearchForm = props => {
  const {
    form: { getFieldDecorator, resetFields, validateFields }
  } = props;

  const { kaercherUserList, loading, isAdding } = useSelector(state => ({
    kaercherUserList: state.userManagement.kaercherUserList.data,
    loading: state.userManagement.kaercherUserList.fetching,
    isAdding: state.userManagement.kaercherUserList.isAdding
  }));

  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [searchValue, setSearchValue] = useState('');
  const searchDebounceRef = useRef(0);
  // to get the previous state of is adding
  const isAddingRef = useRef(false);

  useEffect(() => {
    if (!isAdding && isAddingRef.current) {
      resetFields();
    }
    isAddingRef.current = isAdding;
  }, [isAdding]);

  useEffect(() => {
    if (searchValue === '') return;

    dispatch(
      getKaecherUser({
        searchValue: removeMiddleSpace(searchValue)
      })
    );
  }, [searchValue, dispatch]);

  const handleSearchChanges = changeValue => {
    const changedValue = changeValue || '';

    if (searchDebounceRef.current) clearTimeout(searchDebounceRef.current);
    searchDebounceRef.current = setTimeout(() => {
      setSearchValue(changedValue.trim());
    }, 300);
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) return;
      const data = values.selectedUsers.map(submitValue => ({
        id: submitValue.split('/')[0],
        email: submitValue.split('/')[1],
        roles: [USER_ROLES.NA]
      }));

      if (!data.length) return;

      dispatch(addKaercherUser(data));
    });
  };

  const selectSuffixIcon = useMemo(() => {
    const iconType = loading ? 'sync' : 'search';
    return <Icon type={iconType} spin={loading} />;
  }, [loading]);

  return (
    <Form onSubmit={handleSubmit} className="user-list-container">
      <div id="user-management-dropdown-node">
        {getFieldDecorator('selectedUsers', {
          initialValue: []
        })(
          <Select
            getPopupContainer={() =>
              document.getElementById('user-management-dropdown-node')
            }
            showSearch
            placeholder={formatMessage({
              id: 'common.search.SearchAKaercherUser',
              defaultMessage: 'Search a Kärcher user...'
            })}
            placement="bottomCenter"
            onSearch={handleSearchChanges}
            mode="multiple"
            showArrow
            suffixIcon={selectSuffixIcon}
            filterOption={false}
            dropdownRender={menu => {
              return (
                <div>
                  {menu}
                  <Form.Item
                    className="kaercher-user-search-button-wrapper"
                    onMouseDown={e => e.preventDefault()}>
                    <div className="form-btn-actions button-wrapper kaercher-user-search-submit-button">
                      <Button
                        disabled={isAdding}
                        htmlType="submit"
                        className="btn-save button-align-right">
                        <div className="text">
                          <FormattedMessage
                            id="common.button.addUserToTheKDD"
                            defaultMessage="ADD USER TO THE KDD"
                          />
                        </div>
                      </Button>
                    </div>
                  </Form.Item>
                </div>
              );
            }}>
            {kaercherUserList.map(user => (
              <Select.Option key={user.id} value={`${user.id}/${user.email}`}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
    </Form>
  );
};

UserSearchForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired
};

export const UserSearchList = Form.create({ name: 'user' })(UserSearchForm);
