import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, Dropdown, Menu, Button, Icon, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { changeLanguage } from '../../../data/language/action';
import { onSignOut } from '../../../utils/auth/userManager';
import { setActivationAdminMode } from '../../../data/administration/actions';
import { setSearchKeywords } from '../../../data/universalSearch/actions';
import {
  DEFAULT_ROUTE_BY_PAGE_MODE,
  PAGE_MODES
} from '../../../configs/constant';
import { ProtectedComponent } from '../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../configs/rolesOfPermissions';
import { checkPermissionByRoles } from '../../../utils/checkPermission';
import { setCognitoLang } from '../../../utils/auth/cognito';
import { BaseModal } from '../../../components/BaseModal';
import { UniversalSearchModal } from './UniversalSearchModal';

const userMenu = (
  <Menu>
    <Menu.Item key="logout" onClick={onSignOut}>
      <FormattedMessage id="auth.logout" defaultMessage="Logout" />
    </Menu.Item>
  </Menu>
);

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { isActivatingAdminMode, languageCode } = useSelector(state => ({
    isActivatingAdminMode: state.administration.isActivatingAdminMode,
    languageCode: state.language.languageCode
  }));

  const [isDisplaySearchInput, setIsDisplaySearchInput] = useState(false);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);

  const handleChange = value => {
    setCognitoLang(value);
    dispatch(changeLanguage({ languageCode: value, changedByHeader: true }));
  };

  const switchPageMode = () => {
    const isSwitchingToAdminMode = !isActivatingAdminMode;
    const routeUrl = isSwitchingToAdminMode
      ? DEFAULT_ROUTE_BY_PAGE_MODE[PAGE_MODES.AdminMode]
      : DEFAULT_ROUTE_BY_PAGE_MODE[PAGE_MODES.UserMode];

    dispatch(setActivationAdminMode(isSwitchingToAdminMode));
    history.push(routeUrl);
  };

  const onDisplaySearch = useCallback(() => {
    setIsDisplaySearchInput(!isDisplaySearchInput);
  }, [isDisplaySearchInput]);

  const onPressEnterSearch = useCallback(
    e => {
      dispatch(setSearchKeywords({ keywords: e.target.value }));
      setIsSearchModalVisible(true);
    },
    [isSearchModalVisible]
  );

  const onCancelSearch = useCallback(() => {
    setIsSearchModalVisible(false);
  }, [isSearchModalVisible]);

  return (
    <div
      className={`db-header ${
        checkPermissionByRoles(ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ)
          ? 'admin-header'
          : 'user-header'
      }`}>
      <div className="logo">
        <img alt="" src="/logoKaercher_black.svg" />
      </div>
      <div className="title">
        {isActivatingAdminMode ? (
          <>
            <FormattedMessage
              id="header.adminText"
              defaultMessage="Administration"
              description="Change Header"
            />
          </>
        ) : (
          <>
            <FormattedMessage
              id="header.app"
              defaultMessage="Detergents Database"
              description="Change Header"
            />
          </>
        )}
      </div>

      <ProtectedComponent
        allowRoles={ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ}>
        <Button onClick={switchPageMode} className="switch-page-mode-button">
          {isActivatingAdminMode ? (
            <>
              <img className="icon-menu" alt="" src="/icon/ic-kdd.svg" />
              <span className="kdd-text">
                <FormattedMessage id="header.kddText" defaultMessage="KDD" />
              </span>
            </>
          ) : (
            <>
              <img className="icon-menu" alt="" src="/icon/ic-admin.svg" />
              <span>
                <FormattedMessage
                  id="header.adminText"
                  defaultMessage="Administration"
                />
              </span>
            </>
          )}
        </Button>
      </ProtectedComponent>

      <div
        className={`search-box ${
          isDisplaySearchInput ? 'search-box-enable' : ''
        }`}>
        <Input
          className={`search-input ${
            isDisplaySearchInput ? 'search-input-enable' : ''
          }`}
          placeholder={formatMessage({
            id: 'header.search',
            defaultMessage: 'Search'
          })}
          onPressEnter={onPressEnterSearch}
        />
        <Button className="search-btn" onClick={onDisplaySearch}>
          {isDisplaySearchInput ? (
            <Icon type="close" />
          ) : (
            <Icon type="search" />
          )}
        </Button>
      </div>

      <div className="profile">
        <img src="/icon/ic-language.svg" alt="icon" />
        <Select
          style={{ width: '100px' }}
          value={languageCode}
          onChange={handleChange}
          className="language">
          <Select.Option value="en">English</Select.Option>
          <Select.Option value="de">Deutsch</Select.Option>
        </Select>
        <Dropdown
          overlay={userMenu}
          placement="bottomRight"
          trigger={['click']}>
          <img src="/icon/ic_account.svg" alt="icon" />
        </Dropdown>
      </div>

      <BaseModal
        className="universal-search-modal"
        title=""
        width="80%"
        maskClosable={false}
        visible={isSearchModalVisible}
        onCancel={() => onCancelSearch()}>
        {isSearchModalVisible && (
          <UniversalSearchModal onCancel={onCancelSearch} />
        )}
      </BaseModal>
    </div>
  );
};

export default Header;
