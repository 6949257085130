import { AxiosService } from '../../services/axiosService';

export const getSearchDataApi = ({ keywords, pagination }) => {
  return AxiosService.get(
    'v2/search',
    {
      keywords
    },
    {
      pagination
    }
  );
};
