export const GET_DETERGENT_NON_PAGING = 'GET_DETERGENT_NON_PAGING';
export const GET_DETERGENT_NON_PAGING_SUCCESS =
  'GET_DETERGENT_NON_PAGING_SUCCESS';
export const GET_DETERGENT_NON_PAGING_ERROR = 'GET_DETERGENT_NON_PAGING_ERROR';

// Detergent non paging
export const getDetergentNonPaging = () => ({
  type: GET_DETERGENT_NON_PAGING
});

export const getDetergentNonPagingSuccess = payload => ({
  type: GET_DETERGENT_NON_PAGING_SUCCESS,
  payload
});

export const getDetergentNonPagingError = payload => ({
  type: GET_DETERGENT_NON_PAGING_ERROR,
  payload
});
