import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Siderbar from '../Sidebar';
import Header from '../Header';
import RawMaterial from '../../RawMaterial';
import RawMaterialProducer from '../../RawMaterialProducer';
import RawMaterialGroup from '../../RawMaterialGroup';
import Detergent from '../../Detergent';
import DetergentProducer from '../../DetergentProducers';
import PartNumber from '../../PartNumbers';
import UserManagement from '../../UserManagement';
import ContainerType from '../../ContainerType';
import ProductionCycle from '../../ProductionCycle';
import { sidebarLinksOfAdminMode } from '../../../configs/sidebarLinks';
import { setActivationAdminMode } from '../../../data/administration/actions';
import { ROLES_OF_PERMISSIONS } from '../../../configs/rolesOfPermissions';
import { checkPermissionByRoles } from '../../../utils/checkPermission';
import { Logs } from '../../Logs';

const routes = [
  {
    path: ['/detergent/:detergentId/:formulaId', '/detergent'],
    component: Detergent,
    roles: ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.READ
  },
  {
    path: '/raw-material',
    component: RawMaterial,
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIALS.READ
  },
  {
    path: '/part-numbers',
    component: PartNumber,
    roles: ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.READ
  },
  {
    path: '/raw-material-producers',
    component: RawMaterialProducer,
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIAL_PRODUCERS_SUPPLIERS.READ
  },
  {
    path: '/group',
    component: RawMaterialGroup,
    roles: ROLES_OF_PERMISSIONS.RAW_MATERIAL_GROUPS.READ
  },
  {
    path: '/detergent-producers',
    component: DetergentProducer,
    roles: ROLES_OF_PERMISSIONS.DETERGENT_PRODUCERS.READ
  },
  {
    path: '/user-management',
    component: UserManagement,
    roles: ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ
  },
  {
    path: '/logs',
    component: Logs,
    roles: ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ
  },
  {
    path: '/container-type',
    component: ContainerType,
    roles: ROLES_OF_PERMISSIONS.CONTAINER_TYPE.READ
  },
  {
    path: '/production-cycle',
    component: ProductionCycle,
    roles: ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.READ
  }
];

const RouteWithSubRoutes = route => {
  return (
    <Route
      exact
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <div className="content">
          <route.component {...props} routes={route.routes} />
        </div>
      )}
    />
  );
};

const Dashboard = ({ userRoles = [] }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const renderRouteWithSubRoutes = () => {
    const result = [];

    routes.forEach(route => {
      const isAllowed = route.roles.some(role => userRoles.includes(role));
      if (isAllowed) {
        result.push(<RouteWithSubRoutes key={route.path} {...route} />);
      }
    });

    return result;
  };

  useEffect(() => {
    const isAdminMode =
      checkPermissionByRoles(ROLES_OF_PERMISSIONS.USER_ADMINISTRATION.READ) &&
      sidebarLinksOfAdminMode.some(
        sidebarLink => sidebarLink.href === pathname
      );

    dispatch(setActivationAdminMode(isAdminMode));
  }, []);
  const messageStyle = {
    fontFamily: 'ClanPro-Medium',
    fontSize: '25px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    textAlign: 'center'
  };
  const layoutWarningMessageWhenUserInactive = () => {
    if (userRoles[0] === 'NA') {
      return (
        <p style={messageStyle}>
          <FormattedMessage
            id="header.messageWarningUserDeactive"
            defaultMessage="Your account has been deactivated. Please contact your site
          administrator."
          />
        </p>
      );
    }
    return <Switch>{renderRouteWithSubRoutes()}</Switch>;
  };
  return (
    <>
      <Header />
      <Layout style={{ minHeight: '100vh' }}>
        <Siderbar />
        <Layout id="Content" className="right-side">
          {layoutWarningMessageWhenUserInactive()}
        </Layout>
      </Layout>
    </>
  );
};

Dashboard.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Dashboard;
