import React from 'react';
import PropTypes from 'prop-types';

const defaultButton = ({ children, ...props }) => (
  <button type="button" {...props}>
    {children}
  </button>
);

defaultButton.propTypes = {
  children: PropTypes.node
};

defaultButton.defaultProps = {
  children: null
};

export const DefaultButton = defaultButton;
