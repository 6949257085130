export const PROCESS_USER_MANAGEMENT_MODAL = 'PROCESS_USER_MANAGEMENT_MODAL';

export const UPDATE_COGNITO_USER = 'UPDATE_COGNITO_USER';
export const UPDATE_COGNITO_USER_SUCCESS = 'UPDATE_COGNITO_USER_SUCCESS';
export const UPDATE_COGNITO_USER_ERROR = 'UPDATE_COGNITO_USER_ERROR';
export const GET_COGNITO_USER = 'GET_COGNITO_USER';
export const GET_COGNITO_USER_SUCCESS = 'GET_COGNITO_USER_SUCCESS';
export const GET_COGNITO_USER_ERROR = 'GET_COGNITO_USER_ERROR';
export const GET_KAERCHER_USER = 'GET_KAERCHER_USER';
export const GET_KAERCHER_USER_SUCCESS = 'GET_KAERCHER_USER_SUCCESS';
export const GET_KAERCHER_USER_ERROR = 'GET_KAERCHER_USER_ERROR';
export const ADD_KAERCHER_USER = 'ADD_KAERCHER_USER';
export const ADD_KAERCHER_USER_SUCCESS = 'ADD_KAERCHER_USER_SUCCESS';
export const ADD_KAERCHER_USER_ERROR = 'ADD_KAERCHER_USER_ERROR';

export const processUserManagementModal = payload => ({
  type: PROCESS_USER_MANAGEMENT_MODAL,
  payload
});

export const updateCognitoUser = payload => ({
  type: UPDATE_COGNITO_USER,
  payload
});

export const updateCognitoUserSuccess = payload => ({
  type: UPDATE_COGNITO_USER_SUCCESS,
  payload
});

export const updateCognitoUserError = payload => ({
  type: UPDATE_COGNITO_USER_ERROR,
  payload
});

export const getCognitoUser = () => ({
  type: GET_COGNITO_USER
});

export const getCognitoUserSuccess = payload => ({
  type: GET_COGNITO_USER_SUCCESS,
  payload
});

export const getCognitoUserError = payload => ({
  type: GET_COGNITO_USER_ERROR,
  payload
});

export const getKaecherUser = payload => ({
  type: GET_KAERCHER_USER,
  payload
});

export const getKaecherUserSuccess = payload => ({
  type: GET_KAERCHER_USER_SUCCESS,
  payload
});

export const getKaecherUserError = payload => ({
  type: GET_KAERCHER_USER_ERROR,
  payload
});

export const addKaercherUser = payload => ({
  type: ADD_KAERCHER_USER,
  payload
});

export const addKaercherUserSuccess = payload => ({
  type: ADD_KAERCHER_USER_SUCCESS,
  payload
});

export const addKaercherUserError = payload => ({
  type: ADD_KAERCHER_USER_ERROR,
  payload
});
