/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as _ from 'lodash';
import React from 'react';
import {
  ACTION_BUTTON_TYPES,
  URL_PARAMS_ACTIONS
} from '../../../../configs/constant';
import { buildUrlQueryParams } from '../../../../utils/common';

export const renderDetergentListExpandIcons = ({
  onExpand,
  expandable,
  expanded,
  record
}) => {
  const isCollapse = expandable && !expanded;
  const imgSrc = isCollapse ? 'arrow-expand' : 'arrow-collapse';

  const expandIcon = (
    <>
      <img
        onClick={e => onExpand(record, e)}
        className="action-item action-item-visible"
        alt=""
        src={`/icon/${imgSrc}.svg`}
      />
      <img
        className={`${ACTION_BUTTON_TYPES.SHOW_DETERGENT_ROW} action-item action-item-visible-bold`}
        onClick={e => onExpand(record, e)}
        alt=""
        src={`/icon/${imgSrc}-bold.svg`}
      />
    </>
  );

  const viewDetailUrl = buildUrlQueryParams('/detergent', {
    data: {
      detergentId: record.id,
      formulaId: _.get(record, 'formulas.0.id')
    },
    action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
  });

  return (
    <div className="action-col">
      <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
        <img
          className={`${ACTION_BUTTON_TYPES.VIEW_DETERGENT_DETAIL} action-item-visible-no-effect`}
          title=""
          alt=""
          src="/icon/ic-view-details.svg"
        />
      </a>
      {record.formulas.length > 1 ? (
        expandIcon
      ) : (
        <span className="margin-left" />
      )}
    </div>
  );
};
