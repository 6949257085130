import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import { BaseTable } from '../../../../../../components/BaseTable';
import { renderPartNumberColumns } from './renderPartNumberColumns';
import { getPartNumberListByFormula } from '../../../../data/detergent/actions';

const PartNumber = props => {
  const { formulaId } = props;

  const dispatch = useDispatch();

  const { partNumberLoading, partNumberData } = useSelector(state => ({
    partNumberLoading: state.detergent.partNumberList.fetching,
    partNumberData: state.detergent.partNumberList.data
  }));

  useEffect(() => {
    dispatch(getPartNumberListByFormula({ formulaId }));
  }, []);

  return (
    <BaseTable
      className="detergent-partnumber-table"
      dataSource={partNumberData}
      columns={renderPartNumberColumns}
      bordered
      loading={partNumberLoading && { indicator: <Icon type="sync" spin /> }}
    />
  );
};

PartNumber.propTypes = {
  formulaId: PropTypes.number.isRequired
};

export default PartNumber;
