import React from 'react';
import { BaseModal } from '../../../../components/BaseModal';
import { RawMaterialGroupForm } from '../Form';
import { FORM_STATUS } from '../../../../configs/constant';

export const RawMaterialGroupModal = props => {
  const renderContent = () => {
    if (props.status === FORM_STATUS.DELETE) {
      return null;
    }

    return (
      <RawMaterialGroupForm
        status={props.status}
        onCancel={() => props.onCancel()}
      />
    );
  };

  return (
    <BaseModal
      title={props.title}
      width="45%"
      maskClosable={false}
      visible={props.isVisible}
      className="raw-material-common-modal"
      onCancel={() => props.onCancel()}>
      {props.isVisible && renderContent()}
    </BaseModal>
  );
};
