import PropType from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../../../../configs/constant';
import generatePhValueSchema from '../../../../Util/measurement/phValueSchema';
import measurementMeasuredMinMaxCommentTableHeader from '../Util/measurementMeasuredMinMaxCommentTableHeader';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { phValueTableId } from './constant';

const BlankMeasurementDataSheetPhValueTable = ({ measurement }) => {
  const { formatMessage } = useIntl();
  const metaDataColumns = measurementMeasuredMinMaxCommentTableHeader(
    MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID,
    formatMessage,
    'cellKeyPh',
    <FormattedMessage
      id="measurement.phValueTable.title"
      defaultMessage="PH VALUE DIN 19268"
    />
  );

  const generatedData = generatePhValueSchema(measurement);

  return (
    <BaseTable
      className="generated-file-measurement-table measurement-ph-value-table"
      columns={metaDataColumns}
      dataSource={generatedData}
      pagination={false}
      tableElementId={phValueTableId}
    />
  );
};

BlankMeasurementDataSheetPhValueTable.propTypes = {
  measurement: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetPhValueTable;
