import { SET_ACTIVATION_ADMIN_MODE } from './actions';

const initialState = {
  isActivatingAdminMode: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVATION_ADMIN_MODE:
      return {
        ...state,
        isActivatingAdminMode: action.payload
      };

    default:
      return state;
  }
}
