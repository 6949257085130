import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayLoading } from '../../../../../components/Loading';
import { getPartNumberNonPaging } from '../../../../../data/partNumberNonPaging/action';
import { PartNumberFields } from './PartNumberFields';
import { DummyPartNumberFields } from './DummyPartNumberFields';
import {
  createDummyPartNumber,
  createProductionCycle
} from '../../../data/detergent/actions';
import {
  initialCreateProductionCycleData,
  initialCreateDummyPartNumberData
} from '../../Util/productionCycle/initialMasterData';
import { STATUS_ENUM } from '../../../../../configs/constant';

const _AddProductionCycleModal = props => {
  const [isCreatingDummyPartNumber, setIsCreatingDummyPartNumber] = useState(
    false
  );
  const [totalDummyPartNumbers, setTotalDummyPartNumbers] = useState(0);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    modalLoading,
    detergent,
    isPartNumberNonPagingFetching,
    partNumberNonPagingData,
    detergentProducerNonPaging
  } = useSelector(state => ({
    modalLoading: state.detergent.modal.loading,
    detergent: state.detergent.emptyDetergent,
    isPartNumberNonPagingFetching: state.partNumberNonPaging.isFetching,
    partNumberNonPagingData: state.partNumberNonPaging.data,
    detergentProducerNonPaging: state.detergentProducerNonPaging.data
  }));

  const {
    form,
    form: { getFieldDecorator },
    onCancel
  } = props;
  const isFetching = modalLoading || isPartNumberNonPagingFetching;
  const formulaId = detergent?.formulas?.[0]?.id;
  const initialDetergentFieldValue = [
    detergent.number,
    detergent.name,
    formatMessage(
      {
        id: 'detergent.formulaVersion',
        defaultMessage: 'Version {version}'
      },
      { version: detergent?.formulas?.[0]?.version }
    )
  ].join(' - ');

  useEffect(() => {
    dispatch(
      getPartNumberNonPaging({
        formulaId,
        filter: {
          partNumberStatus: STATUS_ENUM.ACTIVE
        }
      })
    );
  }, []);

  useEffect(() => {
    let total = 0;
    if (Array.isArray(partNumberNonPagingData)) {
      partNumberNonPagingData.forEach(partNumber => {
        if (partNumber && !partNumber.detergentProducer) {
          total += 1;
        }
      });
    }

    setTotalDummyPartNumbers(total);
  }, [partNumberNonPagingData]);

  const handleSwitchCreatingMode = () => {
    setIsCreatingDummyPartNumber(!isCreatingDummyPartNumber);
  };

  const handleCreateDummyPartNumber = values => {
    const {
      partNumber,
      containerType,
      containerSize,
      containerTypeDescription,
      containerGroup
    } = values;

    const initialDummyPartNumberData = JSON.parse(
      JSON.stringify(initialCreateDummyPartNumberData)
    );
    const data = {
      ...initialDummyPartNumberData,
      detergentFormula: {
        id: formulaId
      },
      partNumber,
      containerType,
      containerSize,
      containerTypeDescription,
      containerGroup
    };

    dispatch(createDummyPartNumber(data));
  };

  const handleCreateProductionCycle = values => {
    const partNumbers = values.partNumbers.map(partNumberId => ({
      id: partNumberId
    }));

    let producerInfo = null;
    if (values.productionCycles) {
      const producerId =
        values.productionCycles[Object.keys(values.productionCycles)[0]]
          .detergentProducer;
      producerInfo = detergentProducerNonPaging.find(
        producer => producer.id === producerId
      );
    } else {
      const partNumberFound = partNumberNonPagingData.find(
        partNumber => partNumber.id === partNumbers[0].id
      );
      producerInfo = partNumberFound.detergentProducer;
    }

    const initialProductionCycleData = JSON.parse(
      JSON.stringify(initialCreateProductionCycleData)
    );

    const data = {
      ...initialProductionCycleData,
      partNumbers,
      productionCycleProducerId: producerInfo ? producerInfo.id : null,
      productionCycleProducerName: producerInfo ? producerInfo.name : ''
    };

    dispatch(createProductionCycle(data));
  };

  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        if (isCreatingDummyPartNumber) {
          handleCreateDummyPartNumber(values);
        } else {
          handleCreateProductionCycle(values);
        }
      }
    });
  };

  return (
    <OverlayLoading loading={isFetching}>
      <Form className="add-production-cycle" onSubmit={handleSubmit}>
        <div className="part-number edit-form-fields no-background-fields">
          <Form.Item
            label={
              <FormattedMessage
                id="partNumbers.columnDetergentName"
                defaultMessage="Detergent"
              />
            }>
            {getFieldDecorator('detergent', {
              initialValue: initialDetergentFieldValue
            })(<Input disabled />)}
          </Form.Item>

          {isCreatingDummyPartNumber ? (
            <DummyPartNumberFields
              form={form}
              detergent={detergent}
              totalDummyPartNumbers={totalDummyPartNumbers}
            />
          ) : (
            <PartNumberFields
              form={form}
              partNumbers={partNumberNonPagingData}
            />
          )}

          <div className="formItem">
            <div className="label-col" />
            <div className="value-col">
              <Button
                type="link"
                className={`padding-zero ${
                  !isCreatingDummyPartNumber ? 'add-more' : 'delete-item'
                }`}
                onClick={handleSwitchCreatingMode}>
                <span>
                  {!isCreatingDummyPartNumber ? (
                    <span className="add-more-plus">+</span>
                  ) : (
                    <span className="delete-icon">x</span>
                  )}

                  <span>
                    <u>
                      {!isCreatingDummyPartNumber ? (
                        <FormattedMessage
                          id="detergent.createDummyPartNumber"
                          defaultMessage="Create a dummy part number"
                        />
                      ) : (
                        <FormattedMessage
                          id="detergent.deleteDummyPartNumber"
                          defaultMessage="Delete a dummy part number"
                        />
                      )}
                    </u>
                  </span>
                </span>
              </Button>
            </div>
          </div>
        </div>

        <div className="form-btn-actions">
          <Button className="btn-cancel" onClick={onCancel}>
            <FormattedMessage
              id="common.cancelButton"
              defaultMessage="CANCEL"
            />
          </Button>
          <Button className="btn-save" type="primary" htmlType="submit">
            <FormattedMessage id="common.saveButton" defaultMessage="SAVE" />
          </Button>
        </div>
      </Form>
    </OverlayLoading>
  );
};

_AddProductionCycleModal.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export const AddProductionCycleModal = Form.create({
  name: 'form-add-production-cycle'
})(_AddProductionCycleModal);
