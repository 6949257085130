import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import {
  changePointToComma,
  getTotalAnnualTonnageByYear
} from '../../utils/common';
import { configContent, preventOnRowTrigger } from './configContent';

const AnnualTonnageDropDown = props => {
  const { record, dropdownTitle, value, valueOfCurrentYear } = props;

  const rawMaterialRatios = record?.rawMaterialRatios;
  const rawMaterialPrice =
    record?.price === null ? 0 : parseFloat(record?.price);

  const totalValue = year =>
    changePointToComma(
      getTotalAnnualTonnageByYear(year, rawMaterialRatios) * rawMaterialPrice
    );

  const getAllYears = () => {
    const years = [];
    rawMaterialRatios.forEach(rawMaterialRatio => {
      const partNumbers = rawMaterialRatio?.formula?.partNumbers;

      partNumbers.forEach(partNumber => {
        const annualDemands = partNumber?.annualDemands;
        annualDemands.forEach(annualDemand => {
          if (!years.includes(annualDemand?.year)) {
            years.push(annualDemand?.year);
          }
        });
      });
    });
    return years.sort((a, b) => a - b);
  };

  const annualTonnageList = (
    <div className={`annual-tonnage-container ${preventOnRowTrigger}`}>
      <div className={`${preventOnRowTrigger} container-title`}>
        {dropdownTitle}
      </div>

      <span className="drop-down-header-title">
        {configContent({
          year: null,
          annualTonnageContent: (
            <FormattedMessage
              id="rawMaterial.columnsTitle.totalAnnualTonnage"
              defaultMessage="Total annual tonnage (kg)"
            />
          ),
          totalValueContent: (
            <FormattedMessage
              id="rawMaterial.columnsTitle.value"
              defaultMessage="Value"
            />
          )
        })}
      </span>
      <div className={`${preventOnRowTrigger} horizon-line`} />
      {getAllYears().map(year => (
        <>
          <span key={year}>
            {configContent({
              year,
              annualTonnageContent: value(year),
              totalValueContent: totalValue(year)
            })}
          </span>
          <div className={`${preventOnRowTrigger} horizon-line`} />
        </>
      ))}
    </div>
  );

  return (
    <div id="drop-down-parent-node">
      <Dropdown
        trigger={['click']}
        getPopupContainer={() =>
          document.getElementById('drop-down-parent-node')
        }
        overlay={annualTonnageList}
        overlayClassName={preventOnRowTrigger}
        className={`${preventOnRowTrigger} dropdown-wrapper`}
        placement="bottomCenter">
        <div>
          <span className={`${preventOnRowTrigger} annual-tonnage-value`}>
            {changePointToComma(valueOfCurrentYear)}{' '}
          </span>
          <div className={`${preventOnRowTrigger} drop-down-icon`} />
        </div>
      </Dropdown>
    </div>
  );
};

AnnualTonnageDropDown.propTypes = {
  record: PropTypes.shape({}).isRequired,
  value: PropTypes.func.isRequired,
  dropdownTitle: PropTypes.shape({}).isRequired,
  valueOfCurrentYear: PropTypes.number.isRequired
};

export default AnnualTonnageDropDown;
