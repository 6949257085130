import { AxiosService } from '../../../../services/axiosService';

export const getDetergentProducersApi = options => {
  return AxiosService.get(
    'detergent-producer',
    {
      search: options.searchByText,
      filter: options.filter,
      order: options.order
    },
    {
      pagination: options.pagination
    }
  );
};

export const addDetergentProducerApi = data => {
  return AxiosService.post('detergent-producer', data);
};

export const updateDetergentProducerApi = (id, data) => {
  return AxiosService.put(`${'detergent-producer'}/${id}`, data);
};

export const getAllDetergentProducerApi = () => {
  return AxiosService.get(`${'detergent-producer'}/non-paging`);
};

export const getPartNumberForDetergentProducerApi = id => {
  return AxiosService.get(`${'detergent-producer'}/${id}/part-number`);
};
