// eslint-disable-next-line import/extensions
import {
  GET_SAP_PRICE,
  GET_SAP_PRICE_SUCCESS,
  GET_SAP_PRICE_ERROR
} from './action';

const initialState = {
  list: {
    fetching: false,
    data: []
  },
  filter: {
    partNumber: '',
    supplierNumber: []
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SAP_PRICE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          fetching: true
        },
        filter: action.payload.filter || initialState.filter
      };
    case GET_SAP_PRICE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          data: action.payload || []
        }
      };
    case GET_SAP_PRICE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    default:
      return state;
  }
}
