import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';
import { STATUS_ENUM, ACTION_BUTTON_TYPES } from '../../../../configs/constant';

const RenderUserRoleActions = props => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { record, onEditUserRole, onChangeUserStatus, userStatus } = props;

  return (
    <div className="action-col">
      {userStatus === STATUS_ENUM.ACTIVE ? (
        <Tooltip
          placement="bottom"
          title={formatMessage({
            id: 'common.statusEnumDeactivate',
            defaultMessage: 'Deactivate'
          })}>
          <Button
            type="link"
            onClick={() =>
              onChangeUserStatus(record, ACTION_BUTTON_TYPES.DEACTIVATE_USER)
            }>
            <img
              className={`${ACTION_BUTTON_TYPES.DEACTIVATE_USER} action-item`}
              alt=""
              src="/icon/block.svg"
            />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip
          placement="bottom"
          title={formatMessage({
            id: 'common.statusEnumActivate',
            defaultMessage: 'Activate'
          })}>
          <Button
            type="link"
            onClick={() =>
              onChangeUserStatus(record, ACTION_BUTTON_TYPES.ACTIVATE_USER)
            }>
            <img
              className={`${ACTION_BUTTON_TYPES.ACTIVATE_USER} action-item`}
              alt=""
              src="/icon/active.svg"
            />
          </Button>
        </Tooltip>
      )}
      <Tooltip
        placement="bottom"
        title={formatMessage({
          id: 'common.tooltipEditRole',
          defaultMessage: 'Edit Role'
        })}>
        <Button
          type="link"
          onClick={() => onEditUserRole(record)}
          disabled={userStatus !== STATUS_ENUM.ACTIVE}>
          <img
            className={`${ACTION_BUTTON_TYPES.EDIT_USER_ROLE} action-item`}
            alt=""
            src="/icon/ic-edit.svg"
          />
        </Button>
      </Tooltip>
    </div>
  );
};

RenderUserRoleActions.propTypes = {
  record: PropTypes.shape({}).isRequired,
  onEditUserRole: PropTypes.func.isRequired,
  onChangeUserStatus: PropTypes.func.isRequired,
  userStatus: PropTypes.string
};

RenderUserRoleActions.defaultProps = {
  userStatus: ''
};

export default RenderUserRoleActions;
