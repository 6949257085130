import { AxiosService } from '../../../../services/axiosService';
import { STATUS_ENUM } from '../../../../configs/constant';

export const getRawMaterialApi = options => {
  return AxiosService.get(
    'raw-material',
    {
      search: options.searchByText,
      order: options.order,
      filter: options.filter
    },
    {
      pagination: options.pagination
    }
  );
};

export const getRawMaterialById = id => {
  return AxiosService.get(`raw-material/${id}`);
};

export const blockRawMaterialApi = data => {
  return AxiosService.put(`raw-material/${data.id}`, data);
};

export const getRawMaterialHistory = (rawMaterialId, pagination) => {
  return AxiosService.get(
    `raw-material/${rawMaterialId}/changes`,
    {},
    {
      pagination
    }
  );
};

export const addRawMaterialApi = data => {
  return AxiosService.post('raw-material', data);
};

export const updateRawMaterialApi = (id, data) => {
  return AxiosService.put(`raw-material/${id}`, data);
};

export const getAllRawMaterialApi = () => {
  return AxiosService.get('raw-material/non-paging', {
    filter: JSON.stringify({
      status: [STATUS_ENUM.ACTIVE, STATUS_ENUM.IN_ACTIVE]
    })
  });
};

export const getDetergentByRawMaterialIdApi = (
  rawMaterialId,
  pagination,
  filter,
  order
) => {
  return AxiosService.get(
    `raw-material/${rawMaterialId}/detergent`,
    {
      filter,
      order
    },
    {
      pagination
    }
  );
};

export const exportDetergentByRawMaterialIdApi = rawMaterialId => {
  return AxiosService.get(`export/raw-material/${rawMaterialId}/detergent`);
};
