import React, { useState, useEffect, useCallback } from 'react';
import * as _ from 'lodash';
import { Button, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ViewPartNumbers } from './ViewPartNumbers';
import { OverlayLoading } from '../../../../../components/Loading';
import {
  getPartNumberById,
  processPartNumbersModal
} from '../../../data/partNumbers/actions';
import { HistoryList } from './HistoryList';
import { AnnualDemandList } from './AnnualDemandList';
import { FORM_STATUS } from '../../../../../configs/constant';
import { ProtectedComponent } from '../../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../../configs/rolesOfPermissions';
import {
  downloadPrecalculation,
  setNamePrecalculationData
} from '../../../../Detergent/data/detergent/actions';

export const ViewPartNumbersDetail = props => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  const {
    partNumberDetailsData,
    isPartNumberDetailsFetching,
    isDownloading,
    languageCode
  } = useSelector(state => ({
    partNumberDetailsData: state.partNumbers.partNumberDetailsData.data,
    isPartNumberDetailsFetching:
      state.partNumbers.partNumberDetailsData.isFetching,
    isDownloading: state.detergent.file.precalculation.isDownloading,
    languageCode: state.language.languageCode
  }));
  const { onCancel, modalData } = props;
  const { partNumberId } = modalData;
  useEffect(() => {
    if (partNumberId) {
      dispatch(
        getPartNumberById({
          partNumberId
        })
      );
    }
  }, []);
  useEffect(() => {
    if (partNumberDetailsData.detergentFormula) {
      dispatch(
        setNamePrecalculationData({
          name: partNumberDetailsData.detergentFormula.detergent.name,
          version: partNumberDetailsData.detergentFormula.version
        })
      );
    }
  }, [isPartNumberDetailsFetching]);
  const changeTab = activeKey => {
    setActiveTab(activeKey);
  };

  const onGeneratingFileSelectorModalOpen = useCallback(() => {
    // TODO:
    dispatch(
      downloadPrecalculation(
        partNumberDetailsData.detergentFormula.id,
        languageCode ?? 'en'
      )
    );
  });

  const handleEdit = useCallback(() => {
    dispatch(
      processPartNumbersModal({
        statusForm: FORM_STATUS.UPDATE,
        title: (
          <FormattedMessage
            id="partNumbers.modalEditPartNumberTitle"
            defaultMessage="EDIT PART NUMBER"
          />
        ),
        isVisible: true,
        modalData: {
          partNumberId: partNumberDetailsData.id
        }
      })
    );
  }, [partNumberDetailsData]);

  return (
    <>
      <OverlayLoading loading={isPartNumberDetailsFetching}>
        <div className="view-detail-modal">
          <ViewPartNumbers partNumberDetailsData={partNumberDetailsData} />

          <Tabs
            className="tabs-partNumber-history"
            onChange={changeTab}
            defaultActiveKey={activeTab}>
            <Tabs.TabPane
              className="annual-demand-detail"
              tab={
                <FormattedMessage
                  id="partNumbers.annualDemandTitle"
                  defaultMessage="Annual demand"
                />
              }
              key="1">
              <AnnualDemandList
                density={_.get(
                  partNumberDetailsData,
                  'detergentFormula.density',
                  ''
                )}
                results={partNumberDetailsData.annualDemands}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="history-details"
              tab={
                <FormattedMessage
                  id="partNumbers.historyTabTitle"
                  defaultMessage="History"
                />
              }
              key="2">
              <div className="title">
                <FormattedMessage
                  id="partNumbers.allTheChangesThatHaveBeenMadeTo"
                  defaultMessage="All the changes that have been made to the part number"
                />{' '}
              </div>

              <div className="history-list-detail">
                <HistoryList partNumberId={partNumberId} />
              </div>
            </Tabs.TabPane>
          </Tabs>

          <div className="form-btn-actions">
            <Button
              loading={isDownloading}
              className="btn-cancel"
              onClick={onGeneratingFileSelectorModalOpen}>
              <img src="/icon/ic-Excel.svg" className="generate-file-icon" />
              <FormattedMessage
                id="partNumbers.button.generatingFileButton"
                defaultMessage="GENERATE PRICE CALCULATION SHEET"
              />
            </Button>

            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.EDIT}>
              <Button className="btn-cancel" onClick={handleEdit}>
                <FormattedMessage id="common.edit" defaultMessage="EDIT" />
              </Button>
            </ProtectedComponent>

            <Button className="btn-save" onClick={onCancel}>
              <FormattedMessage id="common.close" defaultMessage="CLOSE" />
            </Button>
          </div>
        </div>
      </OverlayLoading>
    </>
  );
};

ViewPartNumbersDetail.defaultProps = {
  modalData: {}
};

ViewPartNumbersDetail.propTypes = {
  onCancel: PropTypes.func.isRequired,
  modalData: PropTypes.shape({})
};
