import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select
} from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UploadDocuments } from '../../../../components/BaseUpload/UploadDocuments';
import Country from '../../../../components/Country';
import FilterableSelect from '../../../../components/FilterableSelect';
import { OverlayLoading } from '../../../../components/Loading';
import {
  BLANK_OPTION,
  COMMODITY_GROUPS,
  CURRENCIES,
  FORM_STATUS,
  UPLOAD_TYPES
} from '../../../../configs/constant';
import { getSapPrice } from '../../../../data/sap/action';
import {
  changeCommaToPoint,
  getDeselectedListItems,
  getFromCountry,
  processArraySelectValues,
  trimStringFieldsInObject
} from '../../../../utils/common';
import { validateDocumentCategories } from '../../../../utils/validator';
import { validatorFormatPatNumber } from '../../../../utils/validatorFormatPartNumber';
import {
  createRawMaterial,
  updateRawMaterial
} from '../../data/rawMaterial/action';
import { AutoCompleteSAPPrice } from './AutoCompleteSAPPrice';

const _RawMaterialForm = props => {
  const {
    intl: { formatMessage, formatDate },
    form: { getFieldValue, setFieldsValue, getFieldDecorator },
    onCancel,
    status
  } = props;

  const { Option } = AutoComplete;
  const dispatch = useDispatch();
  const [rawMaterial, setRawMaterial] = useState({});
  const {
    modalLoading,
    rawMaterialData,
    allRawMaterials,
    allProducersSuppliers,
    allRawMaterialGroups,
    countries,
    sapPrices,
    isSAPPriceFetching
  } = useSelector(state => ({
    modalLoading: state.rawMaterials.modal.loading,
    rawMaterialData: JSON.parse(
      JSON.stringify(state.rawMaterials.rawMaterialData)
    ),
    allRawMaterials: state.rawMaterials.allRawMaterials.list,
    countries: state.countries.list,
    allProducersSuppliers:
      state.rawMaterialProducers.allProducersSuppliers.list,
    allRawMaterialGroups: state.rawMaterialGroup.allRawMaterialGroups.list,
    sapPrices: state.sapPrice.list.data,
    isSAPPriceFetching: state.sapPrice.list.fetching
  }));
  const erpNumbersRef = useRef([]);
  const partNumberRef = useRef('');
  const searchInputRef = useRef('');

  const [filterableSapPrices, setFilterableSapPrices] = useState([]);
  const sapPrice = getFieldValue('sapPrice');

  const allRawMaterialsBasedFormStatus = useMemo(
    () =>
      status === FORM_STATUS.UPDATE
        ? allRawMaterials.filter(record => record.id !== rawMaterialData.id)
        : allRawMaterials,
    [status, rawMaterialData]
  );

  useEffect(() => {
    setFilterableSapPrices(sapPrices);
  }, [isSAPPriceFetching]);

  useEffect(() => {
    setFieldsValue({
      sapPrice: rawMaterialData.sapPrice
    });
  }, []);

  const onPriceChange = value => {
    if (value !== searchInputRef) {
      searchInputRef.current = value;
      setFieldsValue({
        sapPrice: null
      });
    }
  };

  const renderSAPPriceOption = (item, index) => {
    return (
      <Option
        key={item.id}
        value={`${index}-${item.supplier.name}`}
        display={item.price}
        label={item.supplier.name}>
        {item.price} - {formatDate(item.purchaseDate)} - {item.supplier.name}
      </Option>
    );
  };

  const onHandleSelected = selectedSAPPriceValue => {
    const selectedSAPPriceIndex = selectedSAPPriceValue.split('-')[0];
    setFieldsValue({
      sapPrice: { ...filterableSapPrices[selectedSAPPriceIndex] }
    });
  };

  const getErpNumberFromSupplierAndProducer = (values = []) => {
    return (
      (values && values.map(id => allProducersSuppliers[id].erpNumber)) || []
    );
  };

  const getSapPriceByPartNumberAndSupplier = () => {
    setFilterableSapPrices([]);
    const partNumberFromRawMaterial = getFieldValue(`partNumber`);
    const producers = getErpNumberFromSupplierAndProducer(
      getFieldValue(`producers`)
    );
    const suppliers = getErpNumberFromSupplierAndProducer(
      getFieldValue(`suppliers`)
    );

    const erpNumbers = producers
      .concat(suppliers)
      .filter(erpNumber => erpNumber !== null);
    if (
      partNumberFromRawMaterial &&
      erpNumbers.length > 0 &&
      (erpNumbers.toString() !== erpNumbersRef.current.toString() ||
        partNumberFromRawMaterial !== partNumberRef.current)
    ) {
      erpNumbersRef.current = erpNumbers;
      partNumberRef.current = partNumberFromRawMaterial;
      dispatch(
        getSapPrice({
          filter: {
            partNumber: partNumberFromRawMaterial,
            supplierNumbers: erpNumbers
          }
        })
      );
    }
  };
  useEffect(() => {
    mappingArrayObjectToArrayString([
      'rawMaterialGroups',
      'retrictedCountries',
      'identicalSubstitutes',
      'limitedSubstitutes'
    ]);
    rawMaterialData.suppliers = rawMaterialData.suppliers.map(supplier =>
      allProducersSuppliers.findIndex(
        producerSupplier => supplier.id === producerSupplier.id
      )
    );
    rawMaterialData.producers = rawMaterialData.producers.map(producer =>
      allProducersSuppliers.findIndex(
        producerSupplier => producer.id === producerSupplier.id
      )
    );
    setRawMaterial(rawMaterialData);
  }, []);

  const mappingArrayObjectToArrayString = fieldKeys => {
    fieldKeys.forEach(fieldKey => {
      const newArray = [];
      if (!rawMaterialData[fieldKey]) return;

      if (
        fieldKey === 'retrictedCountries' &&
        rawMaterialData[fieldKey] &&
        rawMaterialData[fieldKey].length > 0
      ) {
        rawMaterialData.isRestricted = true;
      }

      if (Array.isArray(rawMaterialData[fieldKey])) {
        rawMaterialData[fieldKey].forEach(childItem => {
          newArray.push(childItem.name);
        });
      }

      rawMaterialData[fieldKey] = newArray;
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        values.commodityGroup =
          values.commodityGroup === undefined ? '' : values.commodityGroup;
        values.producers = values.producers.map(
          producerId => allProducersSuppliers[producerId]
        );
        values.rawMaterialGroups = processArraySelectValues(
          allRawMaterialGroups,
          values.rawMaterialGroups
        );
        values.suppliers = values.suppliers.map(
          supplierId => allProducersSuppliers[supplierId]
        );
        values.retrictedCountries = processArraySelectValues(
          countries,
          values.retrictedCountries
        );
        values.identicalSubstitutes = processArraySelectValues(
          allRawMaterials,
          values.identicalSubstitutes
        );
        values.limitedSubstitutes = processArraySelectValues(
          allRawMaterials,
          values.limitedSubstitutes
        );

        if (!values.isSupplierDifferentFromProducer) {
          values.suppliers = [];
        }
        if (!values.isRestricted) {
          values.retrictedCountries = [];
        }

        values.price = values.sapPrice ? values.sapPrice.price : values.price;

        if (props.status === FORM_STATUS.CREATE) {
          const rawMaterialFormData = trimStringFieldsInObject(values);
          dispatch(createRawMaterial(rawMaterialFormData));
        } else if (props.status === FORM_STATUS.UPDATE) {
          const oldRawMaterial = JSON.parse(JSON.stringify(rawMaterialData));
          delete oldRawMaterial.sapPriceId;
          const newRawMaterial = _.assign(oldRawMaterial, values); // Use _.assign to override the nested array objects
          const rawMaterialFormData = trimStringFieldsInObject(newRawMaterial);

          _.set(
            rawMaterialFormData,
            'fromCountry',
            getFromCountry(countries, rawMaterialFormData.fromCountryId)
          );
          dispatch(
            updateRawMaterial({
              id: rawMaterialFormData.id,
              rawMaterial: rawMaterialFormData
            })
          );
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 8, offset: 1 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 12 }
    }
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <div className="raw-material">
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={handleSubmit}>
          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.name"
                defaultMessage="Trade name"
              />
            }>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateRawMaterialTradeName',
                    defaultMessage: 'Trade name is required'
                  })
                }
              ],
              initialValue: rawMaterial.name
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterTradeName',
                  defaultMessage: 'Enter trade name'
                })}
              />
            )}
          </Form.Item>
          <Country
            getFieldDecorator={getFieldDecorator}
            initValue={rawMaterial.fromCountryId || undefined}
            field="fromCountryId"
          />
          <Form.Item
            label={
              !getFieldValue('isSupplierDifferentFromProducer') ? (
                <FormattedMessage
                  id="rawMaterial.producerSupplier"
                  defaultMessage="Producers/Suppliers"
                />
              ) : (
                <FormattedMessage
                  id="rawMaterial.producer"
                  defaultMessage="Producers"
                />
              )
            }>
            <div id="parent-node">
              {getFieldDecorator('producers', {
                initialValue: rawMaterial.producers
              })(
                <FilterableSelect
                  mode="multiple"
                  placeholder={`${
                    !getFieldValue('isSupplierDifferentFromProducer')
                      ? formatMessage({
                          id: 'rawMaterial.selectProducersSuppliers',
                          defaultMessage: 'Select Producers/Suppliers'
                        })
                      : formatMessage({
                          id: 'rawMaterial.selectProducers',
                          defaultMessage: 'Select Producers'
                        })
                  }`}
                  suffixIcon={<Icon type="caret-down" />}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  loading>
                  {allProducersSuppliers.map((psItem, idx) => (
                    <Select.Option key={psItem.id} value={idx}>
                      {psItem.name}
                    </Select.Option>
                  ))}
                </FilterableSelect>
              )}
            </div>
          </Form.Item>

          <Form.Item label=" " className="check-differ">
            {getFieldDecorator('isSupplierDifferentFromProducer', {
              valuePropName: 'checked',
              initialValue: rawMaterial.isSupplierDifferentFromProducer
            })(
              <Checkbox>
                {formatMessage({
                  id: 'common.theProducerDiffersFromTheSupplier',
                  defaultMessage: 'The producer differs from the supplier'
                })}
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.supplier"
                defaultMessage="Suppliers"
              />
            }>
            <div id="parent-node">
              {getFieldDecorator('suppliers', {
                initialValue: rawMaterial.suppliers
              })(
                <FilterableSelect
                  disabled={!getFieldValue('isSupplierDifferentFromProducer')}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  mode="multiple"
                  placeholder={formatMessage({
                    id: 'rawMaterial.selectSuppliers',
                    defaultMessage: 'Select suppliers'
                  })}
                  suffixIcon={<Icon type="caret-down" />}>
                  {allProducersSuppliers.map((psItem, idx) => (
                    <Select.Option key={psItem.id} value={idx}>
                      {psItem.name}
                    </Select.Option>
                  ))}
                </FilterableSelect>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.rawMaterialGroup"
                defaultMessage="Raw material groups"
              />
            }>
            <div id="parent-node">
              {getFieldDecorator('rawMaterialGroups', {
                initialValue: rawMaterial.rawMaterialGroups
              })(
                <Select
                  mode="multiple"
                  placeholder={formatMessage({
                    id: 'rawMaterial.selectRawMaterialGroups',
                    defaultMessage: 'Select raw material groups'
                  })}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  suffixIcon={<Icon type="caret-down" />}
                  loading>
                  {getDeselectedListItems(
                    allRawMaterialGroups,
                    'id',
                    getFieldValue('rawMaterialGroups')
                  ).map(psItem => (
                    <Select.Option key={psItem.id} value={psItem.name}>
                      {psItem.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.commodityGroup"
                defaultMessage="Commodity group"
              />
            }>
            <div id="parent-node">
              {getFieldDecorator('commodityGroup', {
                initialValue: rawMaterial.commodityGroup || undefined
              })(
                <Select
                  placeholder="Select commodity group"
                  suffixIcon={<Icon type="caret-down" />}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  loading>
                  {getDeselectedListItems(
                    COMMODITY_GROUPS,
                    'id',
                    getFieldValue('commodityGroup')
                  ).map(psItem => (
                    <Select.Option key={psItem.id} value={psItem.id}>
                      {psItem.value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.rawMaterialChemicalName"
                defaultMessage="Chemical name"
              />
            }>
            {getFieldDecorator('chemicalName', {
              initialValue: rawMaterial.chemicalName || ''
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterChemicalName',
                  defaultMessage: 'Enter chemical name'
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.casNumber"
                defaultMessage="CAS number"
              />
            }>
            {getFieldDecorator('casNumber', {
              initialValue: rawMaterial.casNumber
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterCASNumber',
                  defaultMessage: 'Enter CAS number'
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.einecNumber"
                defaultMessage="Einecs number"
              />
            }>
            {getFieldDecorator('einecsNumber', {
              initialValue: rawMaterial.einecsNumber
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterEinecsNumber',
                  defaultMessage: 'Enter einecs number'
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.storageLife"
                defaultMessage="Storage life"
              />
            }>
            {getFieldDecorator('storageLife', {
              initialValue: rawMaterial.storageLife || ''
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterStorageLife',
                  defaultMessage: 'Enter storage life'
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            wrapperCol={{ lg: { span: 10 } }}
            label={
              <FormattedMessage
                id="rawMaterial.pricePerKiligram"
                defaultMessage="Price per kilogram"
              />
            }
            className="price">
            <Input.Group compact>
              <div classNames="parent-node">
                {getFieldDecorator('price', {
                  rules: [
                    {
                      validator: (_rule, val, callback) => {
                        const num = changeCommaToPoint(val);
                        if (
                          (val !== '' && val !== null && Number.isNaN(num)) ||
                          num < 0
                        ) {
                          callback(new Error());
                        }
                        callback();
                      },
                      message: formatMessage({
                        id: 'common.validatePrice',
                        defaultMessage: 'You should input a positive number.'
                      })
                    }
                  ],
                  initialValue: rawMaterial.price
                })(
                  <AutoCompleteSAPPrice
                    isFetching={isSAPPriceFetching}
                    formatMessage={formatMessage}
                    onChange={onPriceChange}
                    getSAPPrice={getSapPriceByPartNumberAndSupplier}
                    onSelected={onHandleSelected}
                    filterableSapPrices={filterableSapPrices}
                    renderSAPPriceOption={renderSAPPriceOption}
                  />
                )}
              </div>

              <div classNames="parent-node">
                {getFieldDecorator('currency', {
                  initialValue: rawMaterial.currency
                })(
                  <Select
                    className="item-selection"
                    placeholder="Select currency"
                    getPopupContainer={() =>
                      document.getElementById('parent-node')
                    }
                    suffixIcon={<Icon type="caret-down" />}>
                    {CURRENCIES.map(currency => (
                      <Select.Option key={currency.value} value={currency.name}>
                        {currency.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
              <div className="style-supplier">
                {getFieldDecorator('sapPrice', {
                  initialValue: rawMaterial.sapPrice
                })(<div>{sapPrice?.supplier?.name}</div>)}
              </div>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.upload"
                defaultMessage="Upload documents"
              />
            }
            className="upload">
            {getFieldDecorator('documents', {
              valuePropName: 'fileList',
              initialValue: rawMaterial.documents,
              rules: [
                {
                  validator: validateDocumentCategories(formatMessage)
                }
              ]
            })(<UploadDocuments type={UPLOAD_TYPES.RAW_MATERIAL} />)}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.rawMaterialPartNumber"
                defaultMessage="Part number"
              />
            }>
            {getFieldDecorator('partNumber', {
              rules: [
                {
                  validator: validatorFormatPatNumber(formatMessage)
                }
              ],
              initialValue: rawMaterial.partNumber
            })(
              <Input
                placeholder={formatMessage({
                  id: 'rawMaterial.enterPartNumber',
                  defaultMessage: 'Enter part number'
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.identicalSubstitutes"
                defaultMessage="Identical Substitutes"
              />
            }>
            {getFieldDecorator('identicalSubstitutes', {
              initialValue: rawMaterial.identicalSubstitutes
            })(
              <FilterableSelect
                mode="multiple"
                placeholder={formatMessage({
                  id: 'rawMaterial.selectIdenticalSubstitutes',
                  defaultMessage: 'Select identical substitutes'
                })}
                suffixIcon={<Icon type="caret-down" />}
                loading>
                {allRawMaterialsBasedFormStatus.map(record => (
                  <Select.Option
                    key={`rm-identical-${record.id}`}
                    value={record.name}>
                    {record.name}
                  </Select.Option>
                ))}
              </FilterableSelect>
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.limitedSubstitutes"
                defaultMessage="Limited Substitutes"
              />
            }>
            {getFieldDecorator('limitedSubstitutes', {
              initialValue: rawMaterial.limitedSubstitutes
            })(
              <FilterableSelect
                mode="multiple"
                placeholder={formatMessage({
                  id: 'rawMaterial.selectLimitedSubstitutes',
                  defaultMessage: 'Select limited substitutes'
                })}
                suffixIcon={<Icon type="caret-down" />}
                loading>
                {allRawMaterialsBasedFormStatus.map(record => (
                  <Select.Option
                    key={`rm-limited-${record.id}`}
                    value={record.name}>
                    {record.name}
                  </Select.Option>
                ))}
              </FilterableSelect>
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.additional"
                defaultMessage="Additional Information"
              />
            }>
            {getFieldDecorator('additionalInformation', {
              initialValue: rawMaterial.additionalInformation
            })(<Input.TextArea rows={4} />)}
          </Form.Item>

          <Form.Item label=" " className="check-differ">
            {getFieldDecorator('isRestricted', {
              valuePropName: 'checked',
              initialValue: rawMaterial.isRestricted
            })(
              <Checkbox>
                {formatMessage({
                  id:
                    'rawMaterial.theRawMaterialIsRestrictedToCertainCountries',
                  defaultMessage:
                    'The raw material is restricted to certain countries'
                })}
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="rawMaterial.country"
                defaultMessage="Countries that allow the raw material"
              />
            }>
            <div className="parent-node">
              {getFieldDecorator('retrictedCountries', {
                initialValue: rawMaterial.retrictedCountries
              })(
                <Select
                  disabled={!getFieldValue('isRestricted')}
                  mode="multiple"
                  placeholder={formatMessage({
                    id: 'rawMaterial.selectCountries',
                    defaultMessage: 'Select countries'
                  })}
                  getPopupContainer={() =>
                    document.getElementById('parent-node')
                  }
                  suffixIcon={<Icon type="caret-down" />}>
                  {getDeselectedListItems(
                    countries,
                    'id',
                    getFieldValue('retrictedCountries')
                  ).map(psItem => (
                    <Select.Option key={psItem.id} value={psItem.name}>
                      {psItem.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <div className="form-btn-actions">
                <Button className="btn-cancel" onClick={onCancel}>
                  {formatMessage({
                    id: 'common.cancelButton',
                    defaultMessage: 'CANCEL'
                  })}
                </Button>
                <Button className="btn-save" type="primary" htmlType="submit">
                  {formatMessage({
                    id: 'common.saveButton',
                    defaultMessage: 'SAVE'
                  })}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverlayLoading>
  );
};

export const RawMaterialForm = Form.create({ name: 'raw-material-form' })(
  injectIntl(_RawMaterialForm)
);

_RawMaterialForm.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    formatDate: PropTypes.func
  }),
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

_RawMaterialForm.defaultProps = {
  form: {
    getFieldValue: () => {},
    setFieldsValue: () => {},
    getFieldDecorator: () => {},
    validateFields: () => {}
  },
  intl: {
    formatMessage: () => {},
    formatDate: () => {}
  }
};
