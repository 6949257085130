import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Select, Icon } from 'antd';
import {
  CONTAINER_TYPE,
  CONTAINER_GROUP
} from '../../../../../../configs/constant';
import ValidateContainerSize from '../../../../../../components/ContainerSize/index';
import ContainerTypeDescription from '../../../ContainerTypeDescription';

const ContainerSize = props => {
  const {
    getFieldDecorator,
    initialValue,
    getFieldValue,
    setFieldsValue
  } = props;
  const containerTypeCollection = CONTAINER_TYPE();
  const intl = useIntl();
  const { formatMessage } = intl;

  const containerGroups = CONTAINER_GROUP(formatMessage);

  const isContainerTypeOthers =
    getFieldValue('partNumber.containerType') === 'Others';

  const onSelectChange = () => {
    setFieldsValue({
      'partNumber.containerTypeDescription': ''
    });
  };

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerType"
            defaultMessage="Container type"
          />
        }>
        <div id="container-size-node">
          {getFieldDecorator('partNumber.containerType', {
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'partNumber.validate.containType',
                  defaultMessage: 'Container type is required'
                })
              }
            ],
            initialValue: initialValue.containerType || undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('container-size-node')
              }
              suffixIcon={<Icon type="caret-down" />}
              placeholder={
                <FormattedMessage
                  id="partNumber.form.selectContainerType"
                  defaultMessage="Select container type"
                />
              }
              onChange={onSelectChange}>
              {containerTypeCollection.map(type => (
                <Select.Option key={type.id}>
                  {type.containerType}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>
      <ContainerTypeDescription
        initialValue={initialValue.containerTypeDescription}
        getFieldDecorator={getFieldDecorator}
        field="partNumber.containerTypeDescription"
        isContainerTypeOthers={isContainerTypeOthers}
      />
      <ValidateContainerSize
        initialValue={initialValue.containerSize}
        getFieldDecorator={getFieldDecorator}
        field="partNumber.containerSize"
      />
      <Form.Item
        label={
          <FormattedMessage
            id="partNumber.form.containerGroup"
            defaultMessage="Container group"
          />
        }>
        {getFieldDecorator('partNumber.containerGroup', {
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'partNumber.validate.containGroup',
                defaultMessage: 'Container group is required'
              })
            }
          ],
          initialValue: initialValue.containerGroup || undefined
        })(
          <Select
            getPopupContainer={triggerNode => triggerNode}
            suffixIcon={<Icon type="caret-down" />}
            placeholder={
              <FormattedMessage
                id="partNumber.form.selectContainerGroup"
                defaultMessage="Select container group"
              />
            }>
            {containerGroups.map(group => (
              <Select.Option key={group.value} value={group.value}>
                {group.containerGroup}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </>
  );
};

ContainerSize.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    containerType: PropTypes.string,
    containerTypeDescription: PropTypes.string,
    containerSize: PropTypes.string,
    containerGroup: PropTypes.string
  }).isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired
};

export default ContainerSize;
