import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import userManager from '../../utils/auth/userManager';
import {
  setCognitoToken,
  setCognitoRoles,
  setCognitoExpirationTime,
  setCognitoLang,
  getCognitoInfo
} from '../../utils/auth/cognito';
import { setActivationAdminMode } from '../../data/administration/actions';
import {
  DEFAULT_ROUTE_BY_PAGE_MODE,
  PAGE_MODES,
  COGNITO_USER_LANGUAGE,
  USER_ROLES,
  ADMIN_MODE_ROUTES,
  PAGE_ROUTES
} from '../../configs/constant';
import { getPreLoginPathName } from '../Login/util';
import { changeLanguage } from '../../data/language/action';

const ExchangeToken = ({ history }) => {
  const dispatch = useDispatch();

  const checkRoleAndRedirectRoute = () => {
    const path = getPreLoginPathName();
    const defaultPath = '/';
    const { roles } = getCognitoInfo();
    if (
      roles.includes(USER_ROLES.Administrator) &&
      ADMIN_MODE_ROUTES.includes(path)
    ) {
      dispatch(setActivationAdminMode(true));
      history.push(path);
    } else if (path !== defaultPath && PAGE_ROUTES.includes(path)) {
      history.push(path);
    } else {
      history.push(DEFAULT_ROUTE_BY_PAGE_MODE[PAGE_MODES.UserMode]);
    }
  };

  const checkCognitoLanguage = lang =>
    lang
      ? dispatch(changeLanguage({ languageCode: lang }))
      : dispatch(changeLanguage({ languageCode: COGNITO_USER_LANGUAGE.en }));

  useEffect(() => {
    const handleExchangeToken = async () => {
      const user = await userManager.getUser();
      // eslint-disable-next-line camelcase
      const cognitoToken = user?.id_token;

      if (!cognitoToken) {
        history.push('/login');
        return;
      }

      const {
        profile: { 'custom:lang': cognitoLang, 'custom:role': cognitoRole },
        expires_at: cognitoExpirationTime
      } = user;

      const cognitoRoles = (cognitoRole || USER_ROLES.NA).split(',');
      // save data to localstorage
      setCognitoToken(cognitoToken);
      setCognitoRoles(cognitoRoles);
      setCognitoLang(cognitoLang || COGNITO_USER_LANGUAGE.en);
      setCognitoExpirationTime(cognitoExpirationTime);

      checkCognitoLanguage(cognitoLang);
    };

    handleExchangeToken().then(() => {
      checkRoleAndRedirectRoute();
    });
  }, [history]);

  return <div style={{ textAlign: 'center' }}>Redirecting...</div>;
};

ExchangeToken.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default ExchangeToken;
