import { AxiosService } from '../../../../services/axiosService';

export const getRawMaterialProducersApi = options => {
  return AxiosService.get(
    'raw-material-producer',
    {
      search: options.searchByText,
      order: options.order,
      filter: options.filter
    },
    {
      pagination: options.pagination
    }
  );
};

export const addRawMaterialProducerApi = data => {
  return AxiosService.post('raw-material-producer', data);
};

export const updateRawMaterialProducerApi = (id, data) => {
  return AxiosService.put(`raw-material-producer/${id}`, data);
};

export const getAllRawMaterialProducerApi = () => {
  return AxiosService.get('raw-material-producer/non-paging');
};
