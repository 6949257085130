import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useReducer
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';
import PropType from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GeneratedFileCheckBoxesContainer from '../GeneratingFileSelectorModal/util/GeneratedFileCheckBoxesContainer';
import PrecalculationDownLoadCheckBox from '../GeneratingFileSelectorModal/GeneratedFileOption/PrecalculationDownLoadCheckBox';

// import {
//   accessFile
// } from '../../../data/detergent/actions';
import { showInfoToast } from '../../../../../services/toasterService';

import {
  queueEmail,
  clearProcurementEmail,
  requestPresignedUrlAndUpload,
  getPrecalculationXlsxAsBase64
} from '../ProcurementEmailFileSelectorModal/state/action';

import {
  reducer,
  ON_CLEAR,
  ON_OK,
  ON_PRECALCULATION,
  ON_FILES_GENERATED_COMPLETE,
  getInitialStates,
  ON_OPEN
} from '../ProcurementEmailFileSelectorModal/indexReducer';

import {
  pdfContentType,
  pdfExtension
} from '../../../../../components/ReactToPdf/constants';

const GeneratingFileSelectorModal = ({ visible, setVisible }) => {
  const { detergent, procurementEmail } = useSelector(state => ({
    detergent: state.detergent.emptyDetergent,
    procurementEmail: state.procurementEmail
  }));

  const { languageCode } = useSelector(state => {
    return {
      languageCode: state.language.languageCode
    };
  });

  const [localState, localDispatch] = useReducer(reducer, getInitialStates());

  const {
    isOkClicked,
    isCancel,
    isPrecalculationSelected,
    generatedCompleteFiles,
    isHandleGeneratedFileComplete
  } = localState;

  const dispatch = useDispatch();

  const onCancel = () => {
    setVisible(false);
    dispatch(clearProcurementEmail());
    localDispatch({
      type: ON_CLEAR,
      payload: {
        isCancel: true,
        isHandleGeneratedFileComplete: false,
        isPrecalculationSelected: false,
        isOkClicked: false
      }
    });
  };

  useEffect(() => {
    if (!visible) return;
    localDispatch({
      type: ON_OPEN
    });
  }, [visible]);

  useEffect(() => {
    if (!procurementEmail.isQueuedSuccessful) return;
    onCancel();
    showInfoToast('common.notify.accessFile.success');
  }, [
    procurementEmail.uploadedGeneratedFiles.length,
    procurementEmail.isQueuedSuccessful
  ]);

  useEffect(() => {
    let uploadedFilesToWait = generatedCompleteFiles.length;
    if (isPrecalculationSelected) uploadedFilesToWait += 1;

    const validationStatus =
      procurementEmail.failedUploadedGeneratedFiles.length === 0 &&
      procurementEmail.uploadedGeneratedFiles.length === uploadedFilesToWait &&
      isHandleGeneratedFileComplete;

    if (!validationStatus) return;
    // Wil use this after fix at BE
    const param = {
      files: [],
      generatedFiles: procurementEmail.uploadedGeneratedFiles,
      detergent: {
        id: detergent.id,
        formulaId: detergent?.formulas?.[0]?.id,
        name: detergent.name,
        number: detergent.number,
        formulaVersion: detergent?.formulas?.[0]?.version
      }
    };

    // dispatch(accessFile({ template: "ACCESSFILE", param }));
    dispatch(
      queueEmail({
        template: 'ACCESSFILE',
        param
      })
    );
  }, [
    procurementEmail.failedUploadedGeneratedFiles.length,
    procurementEmail.uploadedGeneratedFiles.length,
    isHandleGeneratedFileComplete
  ]);

  useEffect(() => {
    if (isHandleGeneratedFileComplete && generatedCompleteFiles.length) {
      generatedCompleteFiles.forEach(({ pdfRenderer, fileName }) => {
        dispatch(
          requestPresignedUrlAndUpload({
            name: `${folder}/${fileName}.${pdfExtension}`,
            contentType: pdfContentType,
            file: pdfRenderer.output('blob')
          })
        );
      });
    }
  }, [isHandleGeneratedFileComplete]);

  const onOk = useCallback(() => {
    // Dispatch Ok for the children components to sync
    localDispatch({
      type: ON_OK
    });
    if (isPrecalculationSelected) {
      // TODO: handle upload precalculation
      dispatch(
        getPrecalculationXlsxAsBase64({
          detergent,
          languageCode: languageCode ?? 'en'
        })
      );
    }
  }, [isPrecalculationSelected]);

  const folder = useMemo(
    () => `${detergent.name}-${detergent.formulas?.[0].version}`
  );

  const setIsPrecalculationSelected = value => {
    localDispatch({
      type: ON_PRECALCULATION,
      payload: {
        value
      }
    });
  };

  return (
    <>
      <Modal
        className="generating-file-modal"
        onCancel={onCancel}
        footer={
          <>
            <Button onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CLOSE"
              />
            </Button>
            <Button
              className="generating-file-save-button icon-button"
              loading={isOkClicked}
              onClick={onOk}>
              <FormattedMessage
                id="common.createFile"
                defaultMessage="CREATE"
              />
            </Button>
          </>
        }
        visible={visible}
        title={
          <FormattedMessage
            id="detergent.button.accessFileButton"
            defaultMessage="ACCESS FILES"
          />
        }>
        <GeneratedFileCheckBoxesContainer
          isCancel={isCancel}
          onHandleFileComplete={files => {
            localDispatch({
              type: ON_FILES_GENERATED_COMPLETE,
              payload: {
                generatedCompleteFiles: files
              }
            });
          }}
          isOkClicked={isOkClicked}
        />
        <PrecalculationDownLoadCheckBox
          isPrecalculationSelected={isPrecalculationSelected}
          setIsPrecalculationSelected={setIsPrecalculationSelected}
          isOkClicked={isOkClicked}
        />
      </Modal>
    </>
  );
};

GeneratingFileSelectorModal.propTypes = {
  visible: PropType.bool,
  setVisible: PropType.func.isRequired
};

GeneratingFileSelectorModal.defaultProps = {
  visible: false
};

export default GeneratingFileSelectorModal;
