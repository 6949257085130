import {
  DETERGENT_FORMULA_DOCUMENT_CATEGORIES,
  RAW_MATERIAL_DOCUMENT_CATEGORIES,
  COMMODITY_GROUPS,
  CONTAINER_TYPE,
  MAPPING_PRODUCTION_CYCLE_HISTORY_TO_TRANSLATION_ID,
  USER_ROLE_LABELS,
  USER_ROLES_COLLECTION,
  FORMATTEDMESSAGE_STATUS,
  DISPLAY_FORMULA_FORM,
  DISPLAY_PROJECT_TYPES,
  CONTAINER_GROUP,
  PRODUCTION_CYCLE_STATUS
} from '../../../configs/constant';
import { TRANSFORM_LOGGING_SPECIAL_KEYS } from './loggingKeys';

const PRODUCTION_CYCLE_CHECKBOX_FIELDS = Object.keys(
  MAPPING_PRODUCTION_CYCLE_HISTORY_TO_TRANSLATION_ID
).filter(
  fieldKey =>
    ![
      'productionDate',
      'producerAccessDate',
      'comment',
      'partNumber',
      'id',
      'noteSafetyTextReviewed',
      'cycleClosed',
      'status'
    ].includes(fieldKey)
);

const PASS_FAIL = {
  Passed: 'detergent.passed',
  Failed: 'detergent.failed'
};

const getProductionCycleStatus = value => {
  const productionCycleStatus = PRODUCTION_CYCLE_STATUS.find(
    item => item.value === value
  );
  return productionCycleStatus.name || '';
};

export const transformHistoryValue = (key, value, intl) => {
  if (
    !value &&
    !PRODUCTION_CYCLE_CHECKBOX_FIELDS.some(fieldKey => key.includes(fieldKey))
  ) {
    return 'null';
  }

  // Formula measurement Pass/Fail
  if (
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.FORMULA_MEASUREMENT_PASS_FAIL)
  ) {
    if (value !== null) {
      return intl.formatMessage({ id: `${PASS_FAIL[value]}` });
    }
  }
  // Formula project type
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.FORMULA_PROJECT_TYPES)) {
    const valueType = DISPLAY_PROJECT_TYPES.find(e => e.value === value);
    if (valueType) {
      return intl.formatMessage({ id: valueType.name });
    }
  }
  // Formula Form field
  if (key === TRANSFORM_LOGGING_SPECIAL_KEYS.FORMULA_FORM) {
    return intl.formatMessage({ id: `${DISPLAY_FORMULA_FORM[value]}` });
  }

  // Detergent document category fields
  if (
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.DETERGENT_DOCUMENT_CATEGORY)
  ) {
    const data = DETERGENT_FORMULA_DOCUMENT_CATEGORIES.find(
      category => category.id === parseFloat(value)
    );

    return data?.name || 'null';
  }

  // Raw material document category fields
  if (
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.RAW_MATERIAL_DOCUMENT_CATEGORY)
  ) {
    const data = RAW_MATERIAL_DOCUMENT_CATEGORIES.find(
      category => category.id === parseFloat(value)
    );

    return data?.name || 'null';
  }

  // Commodity group field
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.COMMODITY_GROUP)) {
    const data = COMMODITY_GROUPS.find(
      commodityGroup => commodityGroup.id === value
    );

    return data?.name || 'null';
  }
  if (
    key.includes(
      TRANSFORM_LOGGING_SPECIAL_KEYS.IS_SUPPLIER_DIFFERENT_FROM_PRODUCER
    )
  )
    return intl.formatMessage(
      {
        id: value === null ? 'common.unChecked' : 'common.checked'
      },
      {}
    );

  // Yes/No measurement fields
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.ASF_OENORM_5106)) {
    if (value !== null) {
      let messageId;
      if (parseFloat(value) === 0) {
        messageId = 'productionCycle.no';
      } else if (parseFloat(value) === 1) {
        messageId = 'productionCycle.yes';
      } else {
        messageId = 'productionCycle.blank';
      }
      return intl.formatMessage({
        id: messageId
      });
    }
  }

  // Yes/No production cycle fields
  if (
    key.includes(
      TRANSFORM_LOGGING_SPECIAL_KEYS.PRESENCE_OF_DETERGENT_DEVELOPMENT
    )
  ) {
    return intl.formatMessage({
      id: value ? 'productionCycle.yes' : 'productionCycle.no'
    });
  }

  // Check/Uncheck production cycle fields
  if (
    PRODUCTION_CYCLE_CHECKBOX_FIELDS.some(fieldKey => key.includes(fieldKey))
  ) {
    return intl.formatMessage({
      id: value === 'true' ? 'common.checked' : 'common.unChecked'
    });
  }

  // Container type field
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.CONTAINER_TYPE)) {
    const containerTypeObject = CONTAINER_TYPE(true, intl);
    return containerTypeObject[value]?.containerType || 'null';
  }

  // Container group field
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.CONTAINER_GROUP)) {
    const containerGroupObject = CONTAINER_GROUP(intl.formatMessage, true);
    return containerGroupObject[value]?.containerGroup || 'null';
  }

  // User role field
  if (key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.ROLE)) {
    return USER_ROLE_LABELS[value] || 'null';
  }

  // Transform object to json string
  if (Object.prototype.toString.call(value) === '[object Object]') {
    return JSON.stringify(value);
  }
  const val = USER_ROLES_COLLECTION.find(roleName => roleName.id === value);
  if (val) {
    return String(USER_ROLE_LABELS[val.id]);
  }

  // Status field
  if (
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.RAW_MATERIAL_STATUS) ||
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.LOGS_STATUS) ||
    key.includes(TRANSFORM_LOGGING_SPECIAL_KEYS.DETERGENT_FORMULA_STATUS)
  ) {
    return intl.formatMessage({
      id: FORMATTEDMESSAGE_STATUS[value] || getProductionCycleStatus(value)
    });
  }

  return String(value);
};
