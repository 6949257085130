import React from 'react';
import PropType from 'prop-types';

const CheckBoxLabelField = ({ children }) => {
  return (
    <>
      <input type="checkbox" />
      <span> {children} </span>
    </>
  );
};

CheckBoxLabelField.propTypes = {
  children: PropType.node
};

CheckBoxLabelField.defaultProps = {
  children: null
};

export default CheckBoxLabelField;
