import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PageSizeOption } from './PageSizeOption';
import { DefaultButton } from './DefaultPageButton';

const _Pagination = props => {
  const {
    pages,
    page: currentPageIndex,
    pageSize,
    onPageChange,
    onPageSizeChange,
    pageSizeOptions,
    calculateTotalPage,
    PageButtonComponent
  } = props;

  const [scrollPage, setScrollPage] = useState(0);

  const filterPages = (visiblePages, totalPages) =>
    visiblePages.filter(page => page <= totalPages);

  const getVisiblePages = (page, total) => {
    if (!total) return [];
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };
  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(null, pages)
  );

  useEffect(() => {
    setVisiblePages(getVisiblePages(null, pages));
  }, [pages]);

  useEffect(() => {
    const contentElement = document.getElementById('Content');
    if (contentElement) {
      contentElement.addEventListener('scroll', ev => {
        setScrollPage(ev.target.scrollTop);
      });
    }
  }, []);

  const changePage = page => {
    const activePage = currentPageIndex + 1;

    if (page === activePage) {
      return;
    }

    const visiblePagesUpdate = getVisiblePages(page, pages);

    setVisiblePages(filterPages(visiblePagesUpdate, pages));
    onPageChange(page - 1);
  };

  useEffect(() => {
    changePage(currentPageIndex + 1);
  }, [currentPageIndex]);

  const activePage = currentPageIndex + 1;

  const onChangePageSize = value => {
    onPageSizeChange(value);
    calculateTotalPage(value);
  };

  const backToTop = () => {
    document.getElementById('Content').scrollTop = 0;
  };

  const displayBackToTop = () => {
    const contentElement = document.getElementById('Content');
    return contentElement && scrollPage > 0 ? (
      <>
        <span>
          <FormattedMessage
            id="common.backToTop"
            defaultMessage="Back to top"
          />
          {'  '}
        </span>
        <Icon type="up-circle" theme="filled" style={{ fontSize: '20px' }} />
      </>
    ) : null;
  };

  return (
    <div className="table__pagination-wrapper">
      <PageSizeOption
        pageSize={pageSize}
        handleChangeSize={onChangePageSize}
        pageSizeOptions={pageSizeOptions}
      />
      <div className="table__pagination">
        <div className="table__prev-page-wrapper">
          <PageButtonComponent
            className="table__page-button"
            onClick={() => {
              if (activePage === 1) return;
              changePage(activePage - 1);
            }}
            disabled={activePage === 1}>
            <Icon type="left-circle" theme="filled" />
          </PageButtonComponent>
        </div>
        <div className="table__visible-pages-wrapper">
          {visiblePages.map((page, index, array) => (
            <PageButtonComponent
              key={page}
              className={
                activePage === page
                  ? 'table__page-button table__page-button--active'
                  : 'table__page-button'
              }
              onClick={() => changePage(page)}>
              {array[index - 1] + 2 < page ? `... ${page}` : page}
            </PageButtonComponent>
          ))}
        </div>
        <div className="table__next-page-wrapper">
          <PageButtonComponent
            className="table__page-button"
            onClick={() => {
              if (activePage === pages) return;
              changePage(activePage + 1);
            }}
            disabled={activePage === pages}>
            <Icon type="right-circle" theme="filled" />
          </PageButtonComponent>
        </div>
      </div>
      <div
        className="table__back-to-top"
        onClick={backToTop}
        onKeyPress={null}
        role="button"
        tabIndex="0">
        {displayBackToTop()}
      </div>
    </div>
  );
};

_Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  calculateTotalPage: PropTypes.func,
  PageButtonComponent: PropTypes.func
};

_Pagination.defaultProps = {
  pages: 1,
  page: 0,
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  onPageChange: () => {},
  onPageSizeChange: () => {},
  calculateTotalPage: () => {},
  PageButtonComponent: DefaultButton
};

export const Pagination = _Pagination;
