import React from 'react';
import { Icon } from 'antd';
import PropType from 'prop-types';
import CheckSvgIcon from './CheckSvgIcon';
import DotSvgIcon from './DotSvgIcon';
import SaveChangeSvgIcon from './SaveChangeSvgIcon';
import CancelSaveChangeSvgIcon from './CancelSaveChangeSvgIcon';

const mapSvgIcon = {
  check: CheckSvgIcon,
  dot: DotSvgIcon,
  saveChange: SaveChangeSvgIcon,
  cancelChange: CancelSaveChangeSvgIcon
};

const SvgIcon = props => {
  const { componentId, ...rest } = props;
  return <Icon component={mapSvgIcon[componentId]} {...rest} />;
};

SvgIcon.propTypes = {
  componentId: PropType.string.isRequired
};

export default SvgIcon;
