import * as _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

export const ExpandIcon = ({ onExpand, expandable, expanded, record }) => {
  const isCollapse = expandable && !expanded;
  const imgSrc = isCollapse ? 'arrow-expand' : 'arrow-collapse';

  return (
    <div className="action-col">
      <img
        onClick={e => onExpand(record, e)}
        className="action-item action-item-visible"
        alt=""
        src={`/icon/${imgSrc}.svg`}
      />
      <img
        className="action-item action-item-visible-bold"
        onClick={e => onExpand(record, e)}
        alt=""
        src={`/icon/${imgSrc}-bold.svg`}
      />
    </div>
  );
};

ExpandIcon.propTypes = {
  onExpand: PropTypes.func.isRequired,
  expandable: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired
};
