import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon, Input } from 'antd';
import {
  priceOfOneKilogramOfDetergent,
  priceOfDetergentContainer
} from '../../../../utils/common';
import { BaseTable } from '../../../../components/BaseTable';
import { getRawMaterialByFormula } from '../../data/detergent/actions';
import EditableColumnField from '../../../../components/EditableColumnField/index';
import {
  renderPrecalculationColumns,
  footerColumns
} from './renderPrecalculationColumns';

const Precalculation = props => {
  const dispatch = useDispatch();

  const [
    rawMaterialsPrecalculationData,
    setRawMaterialsPrecalculationData
  ] = useState([]);

  const { currentData, isEditable } = props;

  const formulaId = _.get(currentData, 'formulas.0.id', null);
  const formulaDensity = _.get(currentData, 'formulas.0.density', 0);
  const formulaContainerSize = _.get(
    currentData,
    'formulas.0.containerSize',
    0
  );

  useEffect(() => {
    dispatch(
      getRawMaterialByFormula({
        id: formulaId
      })
    );
  }, []);

  const { precalculationForm, precalculationFormFetching } = useSelector(
    state => ({
      precalculationForm: state.detergent.precalculationForm.list,
      precalculationFormFetching: state.detergent.precalculationForm.fetching
    })
  );

  function mapRawMaterialListToPrecalculationForm(form) {
    if (!form) return [];

    return form.map(rawMaterial => ({
      ...rawMaterial,
      density: formulaDensity,
      containerSize: formulaContainerSize
    }));
  }

  useEffect(() => {
    setRawMaterialsPrecalculationData(
      mapRawMaterialListToPrecalculationForm(precalculationForm)
    );
  }, [precalculationForm]);

  const renderContainerSize = () => (
    <div className="container-size-wrapper">
      <span className="container-size-title">
        <FormattedMessage
          id="detergent.containerSizeTitle"
          defaultMessage="Container size"
        />
      </span>
      <span>
        <EditableColumnField
          editable={isEditable}
          tabName="precalculation-containerSize"
          detergentId={currentData.id}
          formulaId={formulaId}
          readableField={
            <Input
              className="hide-container-size-field"
              defaultValue={formulaContainerSize}
              type="number"
              disabled
            />
          }
          editableField={
            <Input
              defaultValue={formulaContainerSize}
              disabled={false}
              type="number"
            />
          }
        />
      </span>
      <span>(l)</span>
    </div>
  );

  const renderFooterRow = rawMaterialList => {
    let sumOfDetergentPrice = 0;
    let sumOfDetergentContainerPrice = 0;

    rawMaterialList.forEach(rawMaterial => {
      sumOfDetergentPrice += priceOfOneKilogramOfDetergent(rawMaterial);
      sumOfDetergentContainerPrice += priceOfDetergentContainer(rawMaterial);
    });

    const footerData = [
      {
        sumOfDetergentPrice,
        sumOfDetergentContainerPrice
      }
    ];

    return (
      <BaseTable
        className="detergent-precalculation-footer"
        columns={footerColumns}
        dataSource={footerData}
        showHeader={false}
        bordered={false}
      />
    );
  };

  return (
    <>
      <div className="precalculation-container-size">
        {renderContainerSize()}
      </div>
      <div className="precalculation-list-table-wrapper">
        <BaseTable
          className="precalculation-list-table"
          bordered
          loading={
            precalculationFormFetching && {
              indicator: <Icon type="sync" spin />
            }
          }
          columns={renderPrecalculationColumns(isEditable)}
          footer={rawMaterialList => renderFooterRow(rawMaterialList)}
          dataSource={rawMaterialsPrecalculationData}
        />
      </div>
    </>
  );
};

Precalculation.propTypes = {
  currentData: PropTypes.shape({}).isRequired
};

export default Precalculation;
