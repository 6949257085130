import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '../../../../components/BaseModal';
import { FORM_STATUS } from '../../../../configs/constant';
import { ViewPartNumbersDetail } from './ViewDetail';
import PartNumberForm from './createModal';
import { FormEditModal } from './EditModal';
import MigrateAnnualDemand from './MigrateAnnualDemand';

export const PartNumberModal = props => {
  const { status, title, isVisible, onCancel, modalData } = props;

  const renderContent = () => {
    const configModalContent = {
      status,
      onCancel,
      modalData
    };

    switch (status) {
      case FORM_STATUS.CREATE:
        return <PartNumberForm {...configModalContent} />;

      case FORM_STATUS.VIEW_DETAIL:
        return <ViewPartNumbersDetail {...configModalContent} />;

      case FORM_STATUS.UPDATE:
        return <FormEditModal {...configModalContent} />;

      case FORM_STATUS.MIGRATE_ANNUAL_DEMAND:
        return <MigrateAnnualDemand {...configModalContent} />;

      default:
        return null;
    }
  };

  const modalWidth =
    status === FORM_STATUS.MIGRATE_ANNUAL_DEMAND ? '35%' : '60%';

  return (
    <BaseModal
      title={title}
      width={modalWidth}
      maskClosable={false}
      visible={isVisible}
      className="raw-material-common-modal"
      onCancel={() => onCancel()}>
      {isVisible && renderContent()}
    </BaseModal>
  );
};

PartNumberModal.defaultProps = {
  modalData: {}
};

PartNumberModal.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalData: PropTypes.objectOf(PropTypes.any)
};
