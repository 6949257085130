import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { pdfExtension } from './constant';

/**
 * @param  {HTMLElement} element html element to convert to
 * @param  {Object} options to convert to image
 * @returns {{ img: String, canvas: HTMLCanvasElement }} image as based 64
 */
const convertDOMElementToImage = async (element, options = {}) => {
  const canvasedElement = await html2canvas(element, {
    logging: false,
    useCORS: true,
    scale: 1,
    ...options
  });

  return {
    img: canvasedElement.toDataURL('image/jpeg', 1.0),
    canvas: canvasedElement
  };
};

/**
 * @param  {jsPDF} pdf
 * @param  {String} img
 */
const addImageToPdf = (pdf, img, { x = 0, y = 0 }) => {
  pdf.addImage(img, 'JPEG', x, y);
};

/**
 * @param  {jsPDF} pdf
 * @param  {HTMLElement} element html element to convert to
 * @returns {{ canvas: HTMLCanvasElement }} added image to pdf from this canvas
 */
const addElementToPdf = async (pdf, element, options = {}) => {
  const { img, canvas } = await convertDOMElementToImage(element);
  addImageToPdf(pdf, img, options);

  return {
    canvas
  };
};

/**
 * add a DOM element into the pdf document in a well calculated way for breaking page
 * @param {jsPDF} pdfRenderer pdf instance
 * @param  {HTMLElement} element html element to convert to
 * @param {Object} options pdf options
 */
const addNextElementToPdf = async (pdfRenderer, element, options) => {
  const { img, canvas } = await convertDOMElementToImage(element);

  // additional 10 points for a separated space
  const finalYInPx = options.y + 10;
  const pageHeightInPx = pdfRenderer.getPageHeight() + 10;
  const isCanvasHigherThanLeftSpace =
    parsePixelToPoint(canvas.height) > pageHeightInPx - finalYInPx;
  let conclusionY = finalYInPx;
  if (isCanvasHigherThanLeftSpace) {
    conclusionY = 20;
    addPdfPage(pdfRenderer);
  }

  addImageToPdf(pdfRenderer, img, { x: 0, y: conclusionY });
};

/**
 * @param  {Number} pixelValue
 * @returns {Number}
 */
const parsePixelToPoint = pixelValue => pixelValue / 1.333333333 + 10;

/**
 * @param  {jsPDF} pdf
 * @param  {{ id: String, y: Number }} config config for the added table
 */
const addDynamicTable = (pdf, config) => {
  autoTable(pdf, {
    startY: config.y,
    html: `#${config.id}`,
    useCss: false,
    theme: 'grid',
    ...config
  });
};

/**
 * @param  {jsPDF} pdf
 * @param  {Array<{ id: String, y: Number }>} configs config for the added table
 */
const addDynamicTables = (pdf, configs) => {
  for (let index = 0; index < configs.length; index += 1) {
    const config = configs[index];
    addDynamicTable(pdf, config);
  }
};

/**
 * create a new instance of pdf renderer
 * @param {Object} options pdf options
 * @returns {jsPDF} a new pdf
 */
const createPdfRenderer = (options = {}) => {
  // eslint-disable-next-line new-cap
  return new jsPDF({
    ...options,
    orientation: 'p',
    unit: 'pt',
    format: 'a4'
  });
};

/**
 * download memories rendered file in the instance
 * @param {jsPDF} pdfRenderer pdf options
 * @param {String} fileName expected filename
 */
const downloadPdf = (pdfRenderer, fileName) => {
  fileName += `.${pdfExtension}`;
  pdfRenderer.save(fileName);
};

/**
 * add a new page in current memory render
 * @param {jsPDF} pdfRenderer pdf instance
 */
const addPdfPage = pdfRenderer => {
  pdfRenderer.addPage();
};

/**
 * add a DOM element into the pdf document in a well calculated way for breaking page
 * @param {jsPDF} pdfRenderer pdf instance
 * @param  {HTMLElement} element html element to convert to
 */
const addElementOnlyAfterDynamicTable = async (pdfRenderer, element) => {
  const { img, canvas } = await convertDOMElementToImage(element);

  // additional 10 points for a separated space
  const finalYInPx = pdfRenderer.lastAutoTable.finalY + 10;
  const pageHeightInPx = pdfRenderer.getPageHeight() + 10;
  const isCanvasHigherThanLeftSpace =
    parsePixelToPoint(canvas.height) > pageHeightInPx - finalYInPx;

  let conclusionY = finalYInPx;
  if (isCanvasHigherThanLeftSpace) {
    conclusionY = 0;
    addPdfPage(pdfRenderer);
  }

  addImageToPdf(pdfRenderer, img, { x: 0, y: conclusionY });
};

export {
  createPdfRenderer,
  convertDOMElementToImage,
  addImageToPdf,
  addElementToPdf,
  addNextElementToPdf,
  parsePixelToPoint,
  addDynamicTable,
  addDynamicTables,
  downloadPdf,
  addPdfPage,
  addElementOnlyAfterDynamicTable
};
