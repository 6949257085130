export const validatorErpNumber = formatMessage => (rule, val, callback) => {
  try {
    const string = String(val);
    if (val !== '' && val !== null && string.indexOf('.') !== -1) {
      throw new Error(
        formatMessage({
          id: 'erp.integer',
          defaultMessage: 'Should be an integer.'
        })
      );
    }
    callback();
  } catch (error) {
    callback(error);
  }
};
