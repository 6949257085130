import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import renderContainerTypeColumns from './renderColumns';

const List = ({ onPageSizeChange, onPageChange }) => {
  const { pagination, containerTypeList, isLoading } = useSelector(state => ({
    pagination: state.containerType.list.data.pagination,
    containerTypeList: state.containerType.list.data.results,
    isLoading: state.containerType.list.fetching
  }));

  return (
    <div className="table-producer">
      <BaseTable
        bordered
        stickyHeader
        columns={renderContainerTypeColumns()}
        dataSource={containerTypeList}
        loading={isLoading && { indicator: <Icon type="sync" spin /> }}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        pagination={pagination}
        rowKey="partNumber"
      />
    </div>
  );
};

List.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default injectIntl(List);
