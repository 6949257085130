export const INITIAL_FILTER_DETERGENT_LIST = {
  status: ''
};

export const INITIAL_SORT_DETERGENT_LIST = {
  status: 'ASC',
  number: 'ASC'
};

export const CompareOptions = {
  ASC: 'ASC',
  DESC: 'DESC'
};
