import { useState, useEffect } from 'react';

// Custom hook to check the screen size of the device
const useDeviceDetect = () => {
  const [hasRun, setHasRun] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleWindowSizeChange = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    if (!hasRun) {
      setHasRun(true);
      handleWindowSizeChange();
    }

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [hasRun]);

  return size;
};

export default useDeviceDetect;
