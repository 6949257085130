export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_SEARCH_DATA_SUCCESS = 'GET_SEARCH_DATA_SUCCESS';
export const GET_SEARCH_DATA_ERROR = 'GET_SEARCH_DATA_ERROR';
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

export const getSearchData = ({ keywords, pagination }) => ({
  type: GET_SEARCH_DATA,
  payload: {
    keywords,
    pagination
  }
});

export const getSearchDataSuccess = ({ results, pagination }) => ({
  type: GET_SEARCH_DATA_SUCCESS,
  payload: {
    results,
    pagination
  }
});

export const getSearchDataError = ({ error }) => ({
  type: GET_SEARCH_DATA_ERROR,
  payload: {
    error
  }
});

export const setSearchKeywords = ({ keywords }) => ({
  type: SET_SEARCH_KEYWORDS,
  payload: {
    keywords
  }
});

export const clearSearchData = () => ({
  type: CLEAR_SEARCH_DATA
});
