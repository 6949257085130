import { AxiosService } from '../../../../services/axiosService';

export const getProductionCycleApi = options => {
  return AxiosService.get(
    'production-cycle',
    {
      filter: options.filter,
      order: options.order
    },
    {
      pagination: options.pagination
    }
  );
};
