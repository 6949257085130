import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CheckBoxIcon from './CheckBoxIcon';

export const DetergentDevelopment = ({ record, onSendEmailToSafetyTeam }) => {
  const { areSendingToSafetyTeamForProductionCycleIds } = useSelector(
    state => ({
      areSendingToSafetyTeamForProductionCycleIds:
        state.detergent.mail.safetyTeam
          .areSendingToSafetyTeamForProductionCycleIds
    })
  );

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.detergentDevelopment"
            defaultMessage="Detergent Development"
          />
        </span>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record.formulaReviewed}>
          <FormattedMessage
            id="productionCycle.formulaReviewed"
            defaultMessage="1. Formula reviewed"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <CheckBoxIcon checked={record.productionInstructionsEntered}>
          <FormattedMessage
            id="productionCycle.productionInstructionsEntered"
            defaultMessage="2. Production instructions entered"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.necessaryProtocols"
            defaultMessage="3. Necessary protocols"
          />
        </span>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={record?.necessaryProtocols?.initialProductionTestProtocol}>
          <FormattedMessage
            id="productionCycle.initialProductionTestProtocol"
            defaultMessage="Initial production test protocol"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={record?.necessaryProtocols?.productionTestProtocol}>
          <FormattedMessage
            id="productionCycle.productionTestProtocol"
            defaultMessage="Production test protocol"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.inCaseOfInitialProduction"
            defaultMessage="4. In case of initial production: Presence of detergent development required?"
          />
        </span>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon checked={record?.presenceOfDetergentDevelopment}>
          <FormattedMessage id="productionCycle.yes" defaultMessage="Yes" />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={
            record?.presenceOfDetergentDevelopment !== undefined &&
            record?.presenceOfDetergentDevelopment !== null &&
            !record?.presenceOfDetergentDevelopment
          }>
          <FormattedMessage id="productionCycle.no" defaultMessage="No" />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.rawMaterialsThatAreNotUsedAnymore"
            defaultMessage="5. Raw materials that are not used anymore:"
          />
        </span>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={
            record?.rawMaterialsNotUsedAnymore?.useUpInPreviousFormulaVersion
          }>
          <FormattedMessage
            id="productionCycle.useUpInPreviousFormulaVersion"
            defaultMessage="Use up in previous formula version"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={record?.rawMaterialsNotUsedAnymore?.useInOtherFormulas}>
          <FormattedMessage
            id="productionCycle.useInOtherFormulas"
            defaultMessage="Use in other formulas"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item level-3">
        <CheckBoxIcon
          checked={record?.rawMaterialsNotUsedAnymore?.doNotUseAnymore}>
          <FormattedMessage
            id="productionCycle.doNotUseAnymoreDispose"
            defaultMessage="Do not use anymore (dispose)"
          />
        </CheckBoxIcon>
      </div>
      <div className="row-item level-3">
        <CheckBoxIcon
          checked={
            record?.rawMaterialsNotUsedAnymore?.rawMaterialsAreNotAffected
          }>
          <FormattedMessage
            id="productionCycle.rawMaterialsAreNotAffected"
            defaultMessage="Raw materials are not affected"
          />
        </CheckBoxIcon>
      </div>

      <div className="row-item">
        <Button
          className="yellow-button"
          icon="right"
          disabled={areSendingToSafetyTeamForProductionCycleIds.includes(
            record.id
          )}
          loading={areSendingToSafetyTeamForProductionCycleIds.includes(
            record.id
          )}
          onClick={() => onSendEmailToSafetyTeam(record.id)}>
          <FormattedMessage
            id="productionCycle.forwardToSafetyTeam"
            defaultMessage="Forward to safety team"
          />
        </Button>
      </div>
    </>
  );
};

DetergentDevelopment.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onSendEmailToSafetyTeam: PropTypes.func.isRequired
};
