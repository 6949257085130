import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { LabelingTeam } from './ExpandedSections/LabelingTeam';
import { DetergentDevelopment } from './ExpandedSections/DetergentDevelopment';
import { SafetyTeam } from './ExpandedSections/SafetyTeam';
import {
  getCurrentProductionCycleInstanceKey,
  getFormFieldKey
} from '../../Util/productionCycle/getFormFieldKey';
import { disableRowBySelectedProductionCyle } from './util/disableRowBySelectedProductionCyle';
import { STATUS_ENUM } from '../../../../../configs/constant';

export const ExpandedProductionCycleRow = ({
  form,
  record,
  onSendEmailToSafetyTeam,
  onSendEmailToLabelingTeam,
  selectedProductionCycle,
  groupPartNumbersById,
  partNumbers
}) => {
  const [isInactivePartNumber, setIsInactivePartNumber] = useState(false);

  const currentProductionCycleInstanceKey = getCurrentProductionCycleInstanceKey(
    record
  );

  const productionCycles = form.getFieldValue('productionCycles');
  const selectedPartNumberIds =
    productionCycles[currentProductionCycleInstanceKey]?.partNumbers;

  useEffect(() => {
    let isExistedInactivePartNumber = false;
    selectedPartNumberIds.forEach(partNumberId => {
      const partNumber = partNumbers.find(p => p.id === partNumberId);
      if (partNumber.status === STATUS_ENUM.IN_ACTIVE) {
        isExistedInactivePartNumber = true;
      }
    });

    setIsInactivePartNumber(isExistedInactivePartNumber);
  }, [selectedPartNumberIds, partNumbers]);

  const sectionProps = {
    form,
    record,
    onSendEmailToSafetyTeam,
    onSendEmailToLabelingTeam,
    selectedProductionCycle,
    groupPartNumbersById,
    isInactivePartNumber
  };

  const { TextArea } = Input;
  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="top"
        className="expanded-production-cycle-row">
        <Col
          xxl={{ span: 14 }}
          xl={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          className="col-left">
          <DetergentDevelopment {...sectionProps} />
        </Col>
        <Col
          xxl={{ span: 10 }}
          xl={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          className="col-right">
          <SafetyTeam {...sectionProps} />
          <LabelingTeam {...sectionProps} />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="top"
        className="expanded-production-cycle-row">
        <Col span={24} className="col-left col-right">
          <Form.Item
            className="comment-row"
            label={
              <FormattedMessage
                id="productionCycle.comment"
                defaultMessage="Comment"
              />
            }
            labelCol={{
              xxl: { span: 2 },
              xl: { span: 4 },
              sm: { span: 24 },
              xs: { span: 24 }
            }}
            wrapperCol={{
              xxl: { span: 22 },
              xl: { span: 20 },
              sm: { span: 24 },
              xs: { span: 24 }
            }}>
            {form.getFieldDecorator(getFormFieldKey(record, 'comment'), {
              initialValue: record?.comment
            })(
              <TextArea
                rows={6}
                disabled={
                  disableRowBySelectedProductionCyle(
                    selectedProductionCycle,
                    record
                  ) || isInactivePartNumber
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

ExpandedProductionCycleRow.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired,
  record: PropTypes.shape({
    comment: PropTypes.string
  }).isRequired,
  selectedProductionCycle: PropTypes.shape({}),
  groupPartNumbersById: PropTypes.shape({}),
  partNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSendEmailToSafetyTeam: PropTypes.func.isRequired,
  onSendEmailToLabelingTeam: PropTypes.func.isRequired
};

ExpandedProductionCycleRow.defaultProps = {
  selectedProductionCycle: undefined,
  groupPartNumbersById: {}
};
