import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import * as _ from 'lodash';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { getRawMaterialByFormula } from '../../../data/detergent/actions';
import FormularSelection from '../../Form/FormularSelection';
import { FORM_STATUS } from '../../../../../configs/constant';
import ValidatedFormulaSelection from '../../Form/ValidatedFormulaSelection';

const EditRawMaterialFormula = props => {
  const {
    form: { getFieldDecorator }
  } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { detergent, rawMaterialsByFormulaId, allRawMaterials } = useSelector(
    state => ({
      detergent: JSON.parse(JSON.stringify(state.detergent.emptyDetergent)),
      rawMaterialsByFormulaId: state.detergent.precalculationForm.list,
      allRawMaterials: state.rawMaterials.allRawMaterials.list
    })
  );

  const currentDetergentFormulaId = _.get(detergent, 'formulas.0.id', null);

  useEffect(() => {
    dispatch(
      getRawMaterialByFormula({
        id: currentDetergentFormulaId
      })
    );
  }, []);

  const currentFormulaRawMaterials = rawMaterialsByFormulaId.map(
    ({ ratio, rawMaterialId, id, order }) => ({
      ratio,
      rawMaterialId,
      id,
      order
    })
  );
  const firstDetergentFormulaInstance = 'formulas[0]';

  return (
    <ValidatedFormulaSelection
      getFieldDecorator={getFieldDecorator}
      field={`${firstDetergentFormulaInstance}.rawMaterialRatios`}
      initialValue={currentFormulaRawMaterials}
      rawMaterials={allRawMaterials}
      placeholder={
        <FormattedMessage
          id="detergent.selectOneOrMoreRawMaterials"
          defaultMessage="Select one or more raw materials"
        />
      }
      status={FORM_STATUS.UPDATE}
    />
  );
};

EditRawMaterialFormula.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired
};

export default EditRawMaterialFormula;
