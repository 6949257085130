import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import { BaseTable } from '../../../../../components/BaseTable';
import { getRawMaterialByFormula } from '../../../data/detergent/actions';
import { renderRawMaterialFomularColumns } from './renderRawMaterialFomularColumns';

const ViewRawMaterialFormula = ({ formulaId }) => {
  const dispatch = useDispatch();
  const { ratios, isLoading } = useSelector(state => ({
    ratios: state.detergent.precalculationForm.fetchedList,
    isLoading: state.detergent.precalculationForm.fetching
  }));

  const rawMaterials = ratios.map(ratio => {
    return {
      ...ratio,
      ...ratio.rawMaterial
    };
  });

  useEffect(() => {
    dispatch(
      getRawMaterialByFormula({
        id: formulaId
      })
    );
  }, []);

  return (
    <BaseTable
      loading={isLoading && { indicator: <Icon type="sync" spin /> }}
      className="raw-material-detail-list-table"
      bordered
      columns={renderRawMaterialFomularColumns()}
      dataSource={rawMaterials}
    />
  );
};

ViewRawMaterialFormula.propTypes = {
  formulaId: PropType.number.isRequired
};
export default ViewRawMaterialFormula;
