import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDetergentProducers } from '../DetergentProducers/data/detergentProducers/actions';
import FilterForm from '../../components/Filter';
import { ProductionCycleList } from './components/List';
import { FilterFormProductionCycle } from './components/Form/FilterForm';
import { SortProductionCycle } from './components/SortComponent';
import {
  clearProductionCycleDataWhenUnmount,
  getProductionCycle
} from './data/productionCycles/action';
import {
  INITIAL_FILTER_PRODUCTION_CYCLE,
  INITIAL_SORT_PRODUCTION_CYCLE
} from './utils/constants';

const ProductionCycles = () => {
  const dispatch = useDispatch();

  const { dataList, pagination, isLoadingList } = useSelector(state => ({
    dataList: state.productionCycles.list.data.results,
    pagination: state.productionCycles.list.data.pagination,
    isLoadingList: state.productionCycles.list.fetching
  }));

  const [paginationPage, setPaginationPage] = useState(pagination);
  const [filterValues, setFilterValues] = useState(
    INITIAL_FILTER_PRODUCTION_CYCLE
  );
  const [order, setOrder] = useState(INITIAL_SORT_PRODUCTION_CYCLE);

  const onPageSizeChange = useCallback(value => {
    setPaginationPage(currentPagination => ({
      ...currentPagination,
      current: 1,
      pageSize: value
    }));
  }, []);

  const onPageChange = useCallback(current => {
    setPaginationPage(currentPagination => ({
      ...currentPagination,
      current: current + 1
    }));
  }, []);

  const onFilter = useCallback(values => {
    if (Object.values(values).filter(value => !!value).length > 0) {
      setPaginationPage(currentPagination => ({
        ...currentPagination,
        current: 1
      }));
      setFilterValues(values);
    }
  }, []);

  const onClearFilter = useCallback(() => {
    setPaginationPage(currentPagination => ({
      ...currentPagination,
      current: 1
    }));
    setFilterValues(INITIAL_FILTER_PRODUCTION_CYCLE);
  }, []);

  const onSortChange = key => {
    const newOrder = { ...order };
    if (key === 'detergentName') {
      if (newOrder.detergentName === '') {
        newOrder.detergentName = 'ASC';
      } else {
        newOrder.detergentName = '';
      }
    } else if (newOrder[key] === '') {
      newOrder[key] = 'DESC';
    } else {
      newOrder[key] = '';
    }

    setPaginationPage(currentPagination => ({
      ...currentPagination,
      current: 1
    }));
    setOrder(newOrder);
  };

  useEffect(() => {
    setPaginationPage(currentPagination => ({
      ...currentPagination,
      total: pagination.total
    }));
  }, [pagination.total]);

  useEffect(() => {
    dispatch(
      getProductionCycle({
        pagination: paginationPage,
        order,
        filterList: filterValues
      })
    );
  }, [
    paginationPage.pageSize,
    paginationPage.current,
    filterValues,
    order,
    dispatch
  ]);

  useEffect(() => {
    dispatch(getAllDetergentProducers());

    return () => {
      dispatch(clearProductionCycleDataWhenUnmount());
    };
  }, []);

  return (
    <>
      <div className="detergent-database-content production-cycles--tab">
        <div className="main-title">
          <FormattedMessage
            id="detergent.productionCycleTabTitle"
            defaultMessage="Production Cycles"
          />
        </div>
        <div className="filter-area">
          <FilterForm
            dropdownClassName="production-cycles--filter"
            dropdownOverlayClassName="production-cycles--filter-content"
            FilterFormLayout={FilterFormProductionCycle}
            filterFormProps={{
              filterValues,
              onFilter,
              onClearFilter
            }}
          />
          <SortProductionCycle order={order} onSortChange={onSortChange} />
        </div>
      </div>
      <ProductionCycleList
        dataList={dataList}
        isLoadingList={isLoadingList}
        pagination={paginationPage}
        onPageChange={current => onPageChange(current)}
        onPageSizeChange={value => onPageSizeChange(value)}
      />
    </>
  );
};

export default ProductionCycles;
