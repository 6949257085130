import { put, takeLatest } from 'redux-saga/effects';
import { getSapPriceApi } from './api';
import { GET_SAP_PRICE, getSapPriceSuccess, getSapPriceError } from './action';
import { errorHandle } from '../../utils/common';

export function* getSapPriceSaga(action) {
  try {
    const response = yield getSapPriceApi({
      filter: JSON.stringify(action.payload.filter)
    });
    yield put(getSapPriceSuccess(response.data.data));
  } catch (error) {
    yield put(getSapPriceError(error));
    errorHandle(error);
  }
}

export default function* rawMaterialSaga() {
  yield takeLatest(GET_SAP_PRICE, getSapPriceSaga);
}
