import { put, takeLatest, call } from 'redux-saga/effects';
import * as _ from 'lodash';

import {
  getRawMaterialApi,
  addRawMaterialApi,
  updateRawMaterialApi,
  getRawMaterialById,
  blockRawMaterialApi,
  getRawMaterialHistory,
  getAllRawMaterialApi,
  getDetergentByRawMaterialIdApi,
  exportDetergentByRawMaterialIdApi
} from './api';
import {
  GET_RAW_MATERIALS,
  getRawMaterialsSuccess,
  getRawMaterialsError,
  CREATE_RAW_MATERIAL,
  createRawMaterialSuccess,
  createRawMaterialError,
  updateRawMaterialSuccess,
  updateRawMaterialError,
  UPDATE_RAW_MATERIAL,
  getAllRawMaterialSuccess,
  getAllRawMaterialError,
  GET_ALL_RAW_MATERIAL,
  GET_RAW_MATERIAL_BY_ID,
  getRawMaterialsByIdSuccess,
  getRawMaterialsByIdError,
  blockRawMaterialSuccess,
  blockRawMaterialError,
  BLOCK_RAW_MATERIAL,
  getRawMaterialHistoryError,
  getRawMaterialHistorySuccess,
  GET_RAW_MATERIAL_HISTORY,
  GET_DETERGENT_BY_RAW_MATERIAL_ID,
  getDetergentByRawMaterialIdSuccess,
  getDetergentByRawMaterialIdError,
  CHECK_DETERGENT_FORMULA_BLOCK,
  checkDetergentFormulaBlockSuccess,
  checkDetergentFormulaBlockError,
  exportDetergentByRawMaterialIdSuccess,
  exportDetergentByRawMaterialIdError,
  EXPORT_DETERGENT_BY_RAW_MATERIAL_ID
} from './action';
import { errorHandle } from '../../../../utils/common';
import { showSuccessToast } from '../../../../services/toasterService';

import { BASE_DB_FIELDS } from '../../../../configs/constant';
import { uploadDocumentsWithCategory } from '../../../../utils/s3Service';
import { CompareOptions } from '../../utils/constants';

export function* getRawMaterialsSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    if (
      !action.payload.modalLoadLatestDataAfterCreateOrUpdate &&
      Object.prototype.hasOwnProperty.call(order, BASE_DB_FIELDS.UPDATE_AT)
    ) {
      delete order[BASE_DB_FIELDS.UPDATE_AT];
    }
    const response = yield getRawMaterialApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        name: action.payload.searchByText
      }),
      // order: JSON.stringify(action.payload.order || ""),
      filter: JSON.stringify(action.payload.filterList || ''),
      order: JSON.stringify(order)
    });

    if (order.name) {
      response.data.data.results.sort((a, b) =>
        order.name === CompareOptions.ASC
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    }

    yield put(getRawMaterialsSuccess(response.data));
  } catch (error) {
    yield put(getRawMaterialsError(error));
    errorHandle(error);
  }
}

export function* createRawMaterialSaga(action) {
  try {
    const documents = action.payload.documents || [];
    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        return {
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e)
        };
      });
      action.payload.documents = uploadedDocuments;
    }

    const response = yield addRawMaterialApi(action.payload);
    yield put(createRawMaterialSuccess(response.data));

    showSuccessToast('common.notify.rawMaterial.createSuccess');
  } catch (error) {
    yield put(createRawMaterialError(error));
    errorHandle(error);
  }
}

export function* updateRawMaterialSaga(action) {
  try {
    const documents = action.payload.rawMaterial.documents || [];
    if (documents && documents.length) {
      let uploadedDocuments = yield call(
        uploadDocumentsWithCategory,
        documents
      );
      uploadedDocuments = uploadedDocuments.map(document => {
        return {
          id: document.id,
          name: document.name,
          key: document.key,
          category: document.category,
          url: document.url.split('?').find(e => !!e)
        };
      });
      action.payload.rawMaterial.documents = uploadedDocuments;
    }

    const response = yield updateRawMaterialApi(
      action.payload.id,
      action.payload.rawMaterial
    );
    yield put(updateRawMaterialSuccess(response.data));

    showSuccessToast('common.notify.rawMaterial.updateSuccess');
  } catch (error) {
    yield put(updateRawMaterialError(error));
    errorHandle(error);
  }
}

export function* getRawMaterialByIdSaga(action) {
  try {
    const response = yield getRawMaterialById(action.payload.id);
    yield put(getRawMaterialsByIdSuccess(response.data.data));
  } catch (error) {
    yield put(getRawMaterialsByIdError(error));
    errorHandle(error);
  }
}

export function* blockRawMaterialSaga(action) {
  try {
    const response = yield blockRawMaterialApi(action.payload.data);
    yield put(blockRawMaterialSuccess(response.data.data));
    if (action.payload.data.status === 'Blocked') {
      showSuccessToast('common.notify.isBlock', {
        dataRespond: action.payload.data.name
      });
    } else {
      showSuccessToast('common.notify.isUnblock', {
        dataRespond: action.payload.data.name
      });
    }
  } catch (error) {
    yield put(blockRawMaterialError(error));
  }
}

export function* getRawMaterialHistorySaga(action) {
  try {
    const response = yield getRawMaterialHistory(
      action.payload.rawMaterialId,
      action.payload.pagination
    );
    yield put(getRawMaterialHistorySuccess(response.data.data));
  } catch (error) {
    yield put(getRawMaterialHistoryError(error));
    errorHandle(error);
  }
}

export function* getAllRawMaterialSaga() {
  try {
    const response = yield getAllRawMaterialApi();
    yield put(getAllRawMaterialSuccess(response.data.data));
  } catch (error) {
    yield put(getAllRawMaterialError(error));
    errorHandle(error);
  }
}

export function* getDetergentByRawMaterialIdSaga(action) {
  try {
    const { rawMaterialId, pagination, filter, order } = action.payload;
    const response = yield getDetergentByRawMaterialIdApi(
      rawMaterialId,
      pagination,
      JSON.stringify(filter),
      JSON.stringify(order)
    );

    response.data.data.results = response.data.data.results.map(detergent => {
      detergent.formulas = _.orderBy(
        detergent.formulas,
        ['status', 'version'],
        ['asc', 'desc']
      );
      return detergent;
    });
    yield put(getDetergentByRawMaterialIdSuccess(response));
  } catch (error) {
    yield put(getDetergentByRawMaterialIdError(error));
    errorHandle(error);
  }
}

export function* checkDetergentFormulaBlockSaga(action) {
  try {
    const rawMaterialId = action.payload;
    const response = yield getDetergentByRawMaterialIdApi(rawMaterialId);
    yield put(checkDetergentFormulaBlockSuccess(response));
  } catch (error) {
    yield put(checkDetergentFormulaBlockError(error));
    errorHandle(error);
  }
}

export function* exportDetergentByRawMaterialIdSaga(action) {
  try {
    const response = yield exportDetergentByRawMaterialIdApi(
      action.payload.rawMaterialId
    );
    yield put(exportDetergentByRawMaterialIdSuccess(response.data.data));
  } catch (error) {
    yield put(exportDetergentByRawMaterialIdError(error));
    errorHandle(error);
  }
}

export default function* rawMaterialSaga() {
  yield takeLatest(GET_RAW_MATERIALS, getRawMaterialsSaga);
  yield takeLatest(CREATE_RAW_MATERIAL, createRawMaterialSaga);
  yield takeLatest(UPDATE_RAW_MATERIAL, updateRawMaterialSaga);
  yield takeLatest(GET_RAW_MATERIAL_BY_ID, getRawMaterialByIdSaga);
  yield takeLatest(BLOCK_RAW_MATERIAL, blockRawMaterialSaga);
  yield takeLatest(GET_RAW_MATERIAL_HISTORY, getRawMaterialHistorySaga);
  yield takeLatest(GET_ALL_RAW_MATERIAL, getAllRawMaterialSaga);
  yield takeLatest(
    GET_DETERGENT_BY_RAW_MATERIAL_ID,
    getDetergentByRawMaterialIdSaga
  );
  yield takeLatest(
    CHECK_DETERGENT_FORMULA_BLOCK,
    checkDetergentFormulaBlockSaga
  );
  yield takeLatest(
    EXPORT_DETERGENT_BY_RAW_MATERIAL_ID,
    exportDetergentByRawMaterialIdSaga
  );
}
