import _ from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../../components/BaseTable';
import { getPartNumberHistory } from '../../../data/partNumbers/actions';
import { renderPartNumberHistoryColumns } from './renderPartNumberHistoryColumns';

export const HistoryList = ({ partNumberId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { history, isFetchingHistory } = useSelector(state => ({
    history: state.partNumbers.history.data,
    isFetchingHistory: state.partNumbers.history.isFetchingHistory
  }));

  function dispatchGetHistoryList(pagination = history.pagination) {
    dispatch(
      getPartNumberHistory({
        id: partNumberId,
        pagination
      })
    );
  }

  useEffect(() => {
    dispatchGetHistoryList();
  }, []);

  function onPageSizeChange(value) {
    const pagination = {
      ...history.pagination,
      current: 1,
      pageSize: value
    };

    dispatchGetHistoryList(pagination);
  }

  function onPageChange(current) {
    const pagination = {
      ...history.pagination,
      current: current + 1
    };

    dispatchGetHistoryList(pagination);
  }

  return (
    <BaseTable
      className="raw-material-detail-list-table partnumber-history-table"
      pagination={history.pagination}
      bordered
      loading={isFetchingHistory && { indicator: <Icon type="sync" spin /> }}
      columns={renderPartNumberHistoryColumns(intl)}
      dataSource={history.results}
      onPageSizeChange={value => onPageSizeChange(value)}
      onPageChange={current => onPageChange(current)}
    />
  );
};

HistoryList.propTypes = {
  partNumberId: PropTypes.number.isRequired
};
