import React from 'react';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import { changePointToComma } from '../../../../../utils/common';
import { ANNUAL_DEMAND_COLUMNS } from '../../../../../configs/constant';
import { SelectionYears } from '../../SelectionYears';

export const renderAnnualDemandColumns = ({
  density,
  isEditable = false,
  onChangeTableField = () => {},
  yearList = [],
  restrictedYears = [],
  handleRemoveAnnualDemand
}) => {
  // NUMBER_OF_CONTAINERS
  let configColumnPlannedContainer = {
    render: record => {
      return <div className="normal-text">{record.numberOfContainers}</div>;
    }
  };

  if (isEditable) {
    configColumnPlannedContainer = {
      render: record => {
        return (
          <Input
            value={record.numberOfContainers}
            onChange={event =>
              onChangeTableField({
                value: event.target.value,
                record,
                columnKey: ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS
              })
            }
          />
        );
      }
    };
  }

  return [
    {
      title: (
        <FormattedMessage id="partNumbers.columnYear" defaultMessage="Year" />
      ),
      key: 'year',
      width: '20%',
      render: record => {
        if (isEditable && record.tempId) {
          return (
            <SelectionYears
              value={record?.year}
              yearList={yearList}
              restrictedYears={restrictedYears}
              onChange={value =>
                onChangeTableField({
                  value,
                  record,
                  columnKey: ANNUAL_DEMAND_COLUMNS.YEAR
                })
              }
            />
          );
        }

        return <span>{record.year}</span>;
      }
    },
    {
      title: (
        <FormattedMessage
          id="partNumbers.columnPlannedContainer"
          defaultMessage="Planned Container"
        />
      ),
      key: ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS,
      width: '25%',
      ...configColumnPlannedContainer
    },
    {
      title: (
        <FormattedMessage
          id="partNumbers.columnDensity"
          defaultMessage="Density"
        />
      ),
      key: 'density',
      width: '25%',
      render: () => (
        <span className="normal-text">{changePointToComma(density)}</span>
      )
    },
    {
      title: (
        <FormattedMessage
          id="partNumbers.columnAnnualTonnage"
          defaultMessage="Annual Tonnage"
        />
      ),
      key: 'annualTonnage',
      width: '15%',
      render: record => {
        return <div>{changePointToComma(record.annualTonnage)}</div>;
      }
    },
    {
      title: '',
      width: '15%',
      render: record => {
        if (!isEditable) return null;
        return (
          <div
            className="remove"
            onClick={() => handleRemoveAnnualDemand(record)}>
            <p style={{ marginRight: '5px' }}>x</p>
            <u>
              <FormattedMessage
                id="common.deleteButton"
                defaultMessage="Delete"
              />
            </u>
          </div>
        );
      }
    }
  ];
};
