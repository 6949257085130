export const GET_CONTAINER_TYPE = 'GET_CONTAINER_TYPE';
export const GET_CONTAINER_TYPE_SUCCESS = 'GET_CONTAINER_TYPE_SUCCESS';
export const GET_CONTAINER_TYPE_ERROR = 'GET_CONTAINER_TYPE_ERROR';
export const CLEAR_CONTAINER_TYPE_DATA_WHEN_UNMOUNT =
  'CLEAR_CONTAINER_TYPE_DATA_WHEN_UNMOUNT';
export const CHANGE_TABLE_PAGE = 'CHANGE_TABLE_PAGE';
export const CHANGE_TABLE_PAGE_SIZE = 'CHANGE_TABLE_PAGE_SIZE';
export const SET_FILTER_LIST = 'SET_FILTER_LIST';
export const CLEAR_FILTER_LIST = 'CLEAR_FILTER_LIST';
export const EXPORT_CONTAINER_TYPE = 'EXPORT_CONTAINER_TYPE';
export const EXPORT_CONTAINER_TYPE_SUCCESS = 'EXPORT_CONTAINER_TYPE_SUCCESS';
export const EXPORT_CONTAINER_TYPE_ERROR = 'EXPORT_CONTAINER_TYPE_ERROR';

export const getContainerType = ({ pagination, filterList, order }) => ({
  type: GET_CONTAINER_TYPE,
  payload: { pagination, filterList, order }
});

export const getContainerTypeSuccess = payload => ({
  type: GET_CONTAINER_TYPE_SUCCESS,
  payload
});

export const getContainerTypeError = payload => ({
  type: GET_CONTAINER_TYPE_ERROR,
  payload
});

export const clearContainerTypeDataWhenUnmount = () => ({
  type: CLEAR_CONTAINER_TYPE_DATA_WHEN_UNMOUNT
});

export const setFilterList = ({ filterList }) => ({
  type: SET_FILTER_LIST,
  payload: { filterList }
});

export const clearFilterList = () => ({
  type: CLEAR_FILTER_LIST
});

export const changeTablePage = ({ page }) => ({
  type: CHANGE_TABLE_PAGE,
  payload: { page }
});

export const changeTablePageSize = ({ pageSize }) => ({
  type: CHANGE_TABLE_PAGE_SIZE,
  payload: { pageSize }
});

export const exportContainerType = ({ filterList }) => ({
  type: EXPORT_CONTAINER_TYPE,
  payload: { filterList }
});

export const exportContainerTypeSuccess = payload => ({
  type: EXPORT_CONTAINER_TYPE_SUCCESS,
  payload
});

export const exportContainerTypeError = payload => ({
  type: EXPORT_CONTAINER_TYPE_ERROR,
  payload
});
