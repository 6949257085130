import React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';

export const CompareForm = ({ selectedDetergents, onCompareDetergents }) => {
  const { formatMessage } = useIntl();
  const { width } = useDeviceDetect();
  return width > 1366 ? (
    <div className="detergent-compare-area">
      <Tooltip
        overlayClassName="detergent-compare--tooltip"
        title={
          selectedDetergents?.[0]?.name ||
          formatMessage({
            id: 'compare.firstDetergent',
            defaultMessage: 'First Detergent'
          })
        }
        placement="bottom">
        <div>
          <Input
            value={selectedDetergents?.[0]?.name}
            placeholder={formatMessage({
              id: 'compare.firstDetergent',
              defaultMessage: 'First Detergent'
            })}
            disabled
            className="detergent-compare--input"
          />
        </div>
      </Tooltip>
      <Tooltip
        overlayClassName="detergent-compare--tooltip"
        title={
          selectedDetergents?.[1]?.name ||
          formatMessage({
            id: 'compare.secondDetergent',
            defaultMessage: 'Second Detergent'
          })
        }
        placement="bottom">
        <div>
          <Input
            value={selectedDetergents?.[1]?.name}
            placeholder={formatMessage({
              id: 'compare.secondDetergent',
              defaultMessage: 'Second Detergent'
            })}
            disabled
            className="detergent-compare--input"
          />
        </div>
      </Tooltip>
      <Button
        className="compare-button"
        disabled={selectedDetergents.length !== 2}
        onClick={() => onCompareDetergents(selectedDetergents)}>
        <div className="text">
          <FormattedMessage
            id="detergent.compareButton"
            defaultMessage="Compare"
          />
        </div>
      </Button>
    </div>
  ) : (
    <Tooltip
      overlayClassName="compare-tooltip"
      title={
        <>
          <section className="compare-tooltip--item">
            <span className="compare-tooltip--content">
              <FormattedMessage
                id="compare.firstDetergent"
                defaultMessage="First Detergent"
              />
            </span>
            :
            <p className="compare-tooltip--text">
              {selectedDetergents?.[0]?.name}
            </p>
          </section>
          <section className="compare-tooltip--item">
            <span className="compare-tooltip--content">
              <FormattedMessage
                id="compare.secondDetergent"
                defaultMessage="Second Detergent"
              />
            </span>
            :
            <p className="compare-tooltip--text">
              {selectedDetergents?.[1]?.name}
            </p>
          </section>
        </>
      }
      placement="bottom">
      <Button
        className="compare-button"
        disabled={selectedDetergents.length !== 2}
        onClick={() => onCompareDetergents(selectedDetergents)}>
        <div className="text">
          <FormattedMessage
            id="detergent.compareButton"
            defaultMessage="Compare"
          />
        </div>
      </Button>
    </Tooltip>
  );
};

CompareForm.propTypes = {
  selectedDetergents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCompareDetergents: PropTypes.func.isRequired
};
