import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  URL_PARAMS_ACTIONS,
  FORMATTEDMESSAGE_STATUS,
  CONTAINER_TYPE_VIEW_PART_NUMBER,
  CONTAINER_GROUP_VIEW_PART_NUMBER
} from '../../../configs/constant';
import { buildUrlQueryParams } from '../../../utils/common';

export const renderRowFormulaVersion = (
  label,
  version,
  detergentId,
  formulaId
) => {
  const viewDetailUrl = buildUrlQueryParams('/detergent', {
    data: {
      detergentId,
      formulaId
    },
    action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
  });

  return (
    <div className="row-detail">
      <div className="label">{label}</div>
      <div className="value">
        <div>{version}</div>
        <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
          <img alt="" src="/icon/ic-view-details.svg" />
        </a>
      </div>
    </div>
  );
};

export const renderRowStatus = (label, value) => {
  const valueStatus = () => {
    if (value) {
      return <FormattedMessage id={FORMATTEDMESSAGE_STATUS[value]} />;
    }
    return value;
  };
  return (
    <div className="row-detail">
      <div className="label">{label}</div>
      <div className={`value status ${value}`}>
        <span>{valueStatus()}</span>
      </div>
    </div>
  );
};

export const renderRowFields = (label, value) => {
  return (
    <div className="row-detail">
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
};

export const renderContainerTypeFields = (label, value) => {
  if (value) {
    const getNameContainerType = CONTAINER_TYPE_VIEW_PART_NUMBER.find(
      item => item.value === value
    );
    return (
      <div className="row-detail">
        <div className="label">{label}</div>
        <div className="value">
          <FormattedMessage id={getNameContainerType.name} />
        </div>
      </div>
    );
  }
};

export const renderContainerGroupFields = (label, value) => {
  if (!value) return null;
  const getNameContainerTypeGroup = CONTAINER_GROUP_VIEW_PART_NUMBER.find(
    item => item.value === value
  );
  return (
    <div className="row-detail">
      <div className="label">{label}</div>
      <div className="value">
        <FormattedMessage id={getNameContainerTypeGroup.name} />
      </div>
    </div>
  );
};
