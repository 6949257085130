import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

export const PAGE_SIZE = 0;
export const DEFAULT_PAGE_VALUE = 1;
export const DEFAULT_PAGE_SIZE_VALUE = 25;
export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const DISPLAY_COMMODITY_GROUP = {
  R00A00: 'rawMaterial.commodity.perfume',
  R00B00: 'rawMaterial.commodity.colouring',
  R00C00: 'rawMaterial.commodity.solvents',
  R00C01: 'rawMaterial.commodity.acids',
  R00C02: 'rawMaterial.commodity.miscellaneous',
  R00D00: 'rawMaterial.commodity.complexing',
  R00E00: 'rawMaterial.commodity.anionic',
  R00E01: 'rawMaterial.commodity.cationic',
  R00E02: 'rawMaterial.commodity.nonionic',
  R00E03: 'rawMaterial.commodity.amphotheric',
  R00E04: 'rawMaterial.commodity.surfactant',
  R00F00: 'rawMaterial.commodity.specialty'
};
export const COMMODITY_GROUPS = [
  {
    id: 'R00A00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.perfume"
        defaultMessage="Perfume"
      />
    ),
    name: 'Perfume'
  },
  {
    id: 'R00B00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.colouring"
        defaultMessage="Colouring agents"
      />
    ),
    name: 'Colouring agents'
  },
  {
    id: 'R00C00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.solvents"
        defaultMessage="Solvents"
      />
    ),
    name: 'Solvents'
  },
  {
    id: 'R00C01',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.acids"
        defaultMessage="Acids & alkalis"
      />
    ),
    name: 'Acids & alkalis'
  },
  {
    id: 'R00C02',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.miscellaneous"
        defaultMessage="Miscellaneous"
      />
    ),
    name: 'Miscellaneous'
  },
  {
    id: 'R00D00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.complexing"
        defaultMessage="Complexing agents"
      />
    ),
    name: 'Complexing agents'
  },
  {
    id: 'R00E00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.anionic"
        defaultMessage="Anionic surfactant"
      />
    ),
    name: 'Anionic surfactant'
  },
  {
    id: 'R00E01',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.cationic"
        defaultMessage="Cationic surfactant"
      />
    ),
    name: 'Cationic surfactant'
  },
  {
    id: 'R00E02',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.nonionic"
        defaultMessage="Nonionic surfactant"
      />
    ),
    name: 'Nonionic surfactant'
  },
  {
    id: 'R00E03',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.amphotheric"
        defaultMessage="Amphotheric surfactant"
      />
    ),
    name: 'Amphotheric surfactant'
  },
  {
    id: 'R00E04',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.surfactant"
        defaultMessage="Surfactant mixture"
      />
    ),
    name: 'Surfactant mixture'
  },
  {
    id: 'R00F00',
    value: (
      <FormattedMessage
        id="rawMaterial.commodity.specialty"
        defaultMessage="Specialty chemicals"
      />
    ),
    name: 'Specialty chemicals'
  },
  {
    id: '',
    value: <FormattedMessage id="common.blank" defaultMessage="Blank" />,
    name: 'Blank'
  }
];
export const CONTAINER_TYPE_VIEW_PART_NUMBER = [
  {
    name: 'containerType.value.flasche.1ltr.profi',
    value: 'Flasche 1ltr Profi'
  },
  {
    name: 'containerType.value.kanister.10l',
    value: 'Kanister 10l UN'
  },
  {
    name: 'containerType.value.flasche.500ml',
    value: 'Flasche 500ml Profi'
  },
  {
    name: 'containerType.value.fremdgebinde',
    value: 'Fremdgebinde'
  },
  {
    name: 'containerType.value.kanister.25l',
    value: 'Kanister 25l'
  },
  {
    name: 'containerType.value.1kgDose',
    value: '1kg Dose'
  },
  {
    name: 'containerType.value.ibc1000l',
    value: 'IBC 1000l'
  },
  {
    name: 'containerType.value.kanister.20l',
    value: 'Kanister 20l UN'
  },
  {
    name: 'containerType.value.kanister.5l',
    value: 'Kanister 5l UN'
  },
  {
    name: 'containerType.value.flasche.30ml',
    value: 'Flasche 30ml'
  },
  {
    name: 'containerType.value.10kgEimer',
    value: '10 kg Eimer'
  },
  {
    name: 'containerType.value.flasche.pe2.5l',
    value: 'Flasche PE 2.5l'
  },
  {
    name: 'containerType.value.spundfass',
    value: 'Spundfass blau 220 L'
  },
  {
    name: 'containerType.value.konturbeutel.teppichreiniger',
    value: 'Konturbeutel Teppichreiniger 100ml'
  },
  {
    name: 'containerType.value.flasche.advance',
    value: 'Flasche Advance 1L'
  },
  {
    name: 'containerType.value.4x',
    value: '4 x Konturbeutel bedruckt 20ml'
  },
  {
    name: 'containerType.value.flasche.1ltr.retail',
    value: 'Flasche 1ltr Retail'
  },
  {
    name: 'containerType.value.konturbeutel.bedruckt',
    value: 'Konturbeutel bedruckt 20ml'
  },
  {
    name: 'containerType.value.fremdgebinde',
    value: 'Flasche 0.5ltr Retail'
  },
  {
    name: 'containerType.value.tabs',
    value: 'Tabs'
  },
  {
    name: 'containerType.value.flasche.125ml',
    value: 'Flasche 125ml'
  },
  {
    name: 'containerType.value.flasche.50ml',
    value: 'Flasche 50ml'
  },
  {
    name: 'containerType.value.flasche.0.5ltr.retail.kurzhals',
    value: 'Flasche 0,5ltr Retail (kurzhals)'
  },
  {
    name: 'containerType.value.kanister.natural',
    value: 'Kanister natural 3l UN'
  },
  {
    name: 'containerType.value.pulver',
    value: 'Pulver'
  },
  {
    name: 'containerType.value.flasche.0.5ltr.retail.schwarz',
    value: 'Flasche 0,5ltr Retail schwarz'
  },
  {
    name: 'containerType.value.others',
    value: 'Others'
  }
];
export const CONTAINER_TYPE = (isReturnObject = false, intlInstance = null) => {
  let formatMessage;

  if (!intlInstance) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const intl = useIntl();
    formatMessage = intl.formatMessage;
  } else {
    formatMessage = intlInstance.formatMessage;
  }

  const containerTypes = [
    {
      id: 'Flasche 1ltr Profi',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.1ltr.profi',
        defaultMessage: 'Flasche 1ltr Profi'
      })
    },
    {
      id: 'Kanister 10l UN',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.kanister.10l',
        defaultMessage: 'Kanister 10l UN'
      })
    },
    {
      id: 'Flasche 500ml Profi',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.500ml',
        defaultMessage: 'Flasche 500ml Profi'
      })
    },
    {
      id: 'Fremdgebinde',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.fremdgebinde',
        defaultMessage: 'Fremdgebinde'
      })
    },
    {
      id: 'Kanister 25l',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.kanister.25l',
        defaultMessage: 'Kanister 25l'
      })
    },
    {
      id: '1kg Dose',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.1kgDose',
        defaultMessage: '1kg Dose'
      })
    },
    {
      id: 'IBC 1000l',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.ibc1000l',
        defaultMessage: 'IBC 1000l'
      })
    },
    {
      id: 'Kanister 20l UN',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.kanister.20l',
        defaultMessage: 'Kanister 20l UN'
      })
    },
    {
      id: 'Kanister 5l UN',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.kanister.5l',
        defaultMessage: 'Kanister 5l UN'
      })
    },
    {
      id: 'Flasche 30ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.30ml',
        defaultMessage: 'Flasche 30ml'
      })
    },
    {
      id: '10 kg Eimer',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.10kgEimer',
        defaultMessage: '10 kg Eimer'
      })
    },
    {
      id: 'Flasche PE 2.5l',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.pe2.5l',
        defaultMessage: 'Flasche PE 2.5l'
      })
    },
    {
      id: 'Spundfass blau 220 L',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.spundfass',
        defaultMessage: 'Spundfass blau 220 L'
      })
    },
    {
      id: 'Konturbeutel Teppichreiniger 100ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.konturbeutel.teppichreiniger',
        defaultMessage: 'Konturbeutel Teppichreiniger 100ml'
      })
    },
    {
      id: 'Flasche Advance 1L',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.advance',
        defaultMessage: 'Flasche Advance 1L'
      })
    },
    {
      id: '4 x Konturbeutel bedruckt 20ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.4x',
        defaultMessage: '4 x Konturbeutel bedruckt 20ml'
      })
    },
    {
      id: 'Flasche 1ltr Retail',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.1ltr.retail',
        defaultMessage: 'Flasche 1ltr Retail'
      })
    },
    {
      id: 'Konturbeutel bedruckt 20ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.konturbeutel.bedruckt',
        defaultMessage: 'Konturbeutel bedruckt 20ml'
      })
    },
    {
      id: 'Flasche 0.5ltr Retail',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.0.5ltr.retail',
        defaultMessage: 'Flasche 0,5ltr Retail'
      })
    },
    {
      id: 'Tabs',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.tabs',
        defaultMessage: 'Tabs'
      })
    },
    {
      id: 'Flasche 125ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.125ml',
        defaultMessage: 'Flasche 125ml'
      })
    },
    {
      id: 'Flasche 50ml',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.50ml',
        defaultMessage: 'Flasche 50ml'
      })
    },
    {
      id: 'Flasche 0,5ltr Retail (kurzhals)',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.0.5ltr.retail.kurzhals',
        defaultMessage: 'Flasche 0,5ltr Retail (kurzhals)'
      })
    },
    {
      id: 'Kanister natural 3l UN',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.kanister.natural',
        defaultMessage: 'Kanister natural 3l UN'
      })
    },
    {
      id: 'Pulver',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.pulver',
        defaultMessage: 'Pulver'
      })
    },
    {
      id: 'Flasche 0,5ltr Retail schwarz',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.flasche.0.5ltr.retail.schwarz',
        defaultMessage: 'Flasche 0,5ltr Retail schwarz'
      })
    },
    {
      id: 'Others',
      containerSize: null,
      containerType: formatMessage({
        id: 'containerType.value.others',
        defaultMessage: 'Others'
      })
    }
  ];

  if (isReturnObject) {
    const containerTypeObject = {};
    containerTypes.forEach(containerType => {
      containerTypeObject[containerType.id] = containerType;
    });

    return containerTypeObject;
  }

  return containerTypes;
};

export const CONTAINER_TYPE_ENUM = {
  'Flasche 1ltr Profi': 'Flasche 1ltr Profi',
  'Kanister 10l UN': 'Kanister 10l UN',
  'Flasche 500ml Profi': 'Flasche 500ml Profi',
  'Fremdgebinde': 'Fremdgebinde',
  'Kanister 25l': 'Kanister 25l',
  '1kg Dose': '1kg Dose',
  'IBC 1000l': 'IBC 1000l',
  'Kanister 20l UN': 'Kanister 20l UN',
  'Kanister 5l UN': 'Kanister 5l UN',
  'Flasche 30ml': 'Flasche 30ml',
  '10 kg Eimer': '10 kg Eimer',
  'Flasche PE 2.5l': 'Flasche PE 2.5l',
  'Spundfass blau 220 L': 'Spundfass blau 220 L',
  'Konturbeutel Teppichreiniger 100ml': 'Konturbeutel Teppichreiniger 100ml',
  'Flasche Advance 1L': 'Flasche Advance 1L',
  '4 x Konturbeutel bedruckt 20ml': '4 x Konturbeutel bedruckt 20ml',
  'Flasche 1ltr Retail': 'Flasche 1ltr Retail',
  'Konturbeutel bedruckt 20ml': 'Konturbeutel bedruckt 20ml',
  'Flasche 0.5ltr Retail': 'Flasche 0.5ltr Retail',
  'Tabs': 'Tabs',
  'Flasche 125ml': 'Flasche 125ml',
  'Flasche 50ml': 'Flasche 50ml',
  'Flasche 0,5ltr Retail (kurzhals)': 'Flasche 0,5ltr Retail (kurzhals)',
  'Kanister natural 3l UN': 'Kanister natural 3l UN',
  'Pulver': 'Pulver',
  'Flasche 0,5ltr Retail schwarz': 'Flasche 0,5ltr Retail schwarz',
  'Others': 'Others'
};

export const CONTAINER_GROUP_ENUM = {
  SAMPLE_POUCH: 'Sample pouch',
  SMALL_BOTTLE: 'Small bottle',
  BIG_BOTTLE: 'Big bottle',
  CANISTER: 'Canister',
  BARREL: 'Barrel',
  IBC: 'IBC'
};

export const CONTAINER_GROUP = (formatMessage, isReturnObject = false) => {
  const containerGroups = [
    {
      value: CONTAINER_GROUP_ENUM.SAMPLE_POUCH,
      containerGroup: formatMessage({
        id: 'containerGroup.value.samplePouch',
        defaultMessage: CONTAINER_GROUP_ENUM.SAMPLE_POUCH
      })
    },
    {
      value: CONTAINER_GROUP_ENUM.SMALL_BOTTLE,
      containerGroup: formatMessage({
        id: 'containerGroup.value.smallBottle',
        defaultMessage: CONTAINER_GROUP_ENUM.SMALL_BOTTLE
      })
    },
    {
      value: CONTAINER_GROUP_ENUM.BIG_BOTTLE,
      containerGroup: formatMessage({
        id: 'containerGroup.value.bigBottle',
        defaultMessage: CONTAINER_GROUP_ENUM.BIG_BOTTLE
      })
    },
    {
      value: CONTAINER_GROUP_ENUM.CANISTER,
      containerGroup: formatMessage({
        id: 'containerGroup.value.canister',
        defaultMessage: CONTAINER_GROUP_ENUM.CANISTER
      })
    },
    {
      value: CONTAINER_GROUP_ENUM.BARREL,
      containerGroup: formatMessage({
        id: 'containerGroup.value.barrel',
        defaultMessage: CONTAINER_GROUP_ENUM.BARREL
      })
    },
    {
      value: CONTAINER_GROUP_ENUM.IBC,
      containerGroup: formatMessage({
        id: 'containerGroup.value.ibc',
        defaultMessage: CONTAINER_GROUP_ENUM.IBC
      })
    }
  ];

  if (isReturnObject) {
    const containerGroupObject = {};
    containerGroups.forEach(containerGroup => {
      containerGroupObject[containerGroup.value] = containerGroup;
    });

    return containerGroupObject;
  }

  return containerGroups;
};

export const CONTAINER_GROUP_VIEW_PART_NUMBER = [
  {
    name: 'containerGroup.value.samplePouch',
    value: 'Sample pouch'
  },
  {
    name: 'containerGroup.value.smallBottle',
    value: 'Small bottle'
  },
  {
    name: 'containerGroup.value.bigBottle',
    value: 'Big bottle'
  },
  {
    name: 'containerGroup.value.canister',
    value: 'Canister'
  },
  {
    name: 'containerGroup.value.barrel',
    value: 'Barrel'
  },
  {
    name: 'containerGroup.value.ibc',
    value: 'IBC'
  },
  {
    name: 'containerGroup.value.notDefined',
    value: 'Not defined'
  }
];

export const STATUS_ENUM = {
  IN_ACTIVE: 'Inactive',
  BLOCKED: 'Blocked',
  ACTIVE: 'Active',
  INPROGRESS: 'Inprogress',
  APPROVED: 'Approved',
  UN_APPROVED: 'Unapproved'
};
export const FORMATTEDMESSAGE_STATUS = {
  Inactive: 'common.statusEnumInactive',
  Blocked: 'common.statusEnumBlocked',
  Active: 'common.statusEnumActive',
  Inprogress: 'common.statusEnumInprogress',
  Approved: 'common.statusEnumApproved',
  Unapproved: 'common.statusEnumUnapproved'
};

export const COUNTRY_ID_ENUM = {
  UNITED_STATES: 244,
  GERMANY: 88
};

export const COUNTRY = [
  {
    value: COUNTRY_ID_ENUM.UNITED_STATES,
    name: (
      <FormattedMessage
        id="common.countryEnumUnitedStates"
        defaultMessage="United States"
      />
    )
  },
  {
    value: COUNTRY_ID_ENUM.GERMANY,
    name: (
      <FormattedMessage
        id="common.countryEnumGermany"
        defaultMessage="Germany"
      />
    )
  }
];

export const DISPLAY_COUNTRY = {
  244: (
    <FormattedMessage
      id="common.countryEnumUnitedStates"
      defaultMessage="United States"
    />
  ),
  88: (
    <FormattedMessage id="common.countryEnumGermany" defaultMessage="Germany" />
  )
};

export const COUNTRY_BY_ROLE_KNA = [
  {
    value: COUNTRY_ID_ENUM.UNITED_STATES,
    name: (
      <FormattedMessage
        id="common.countryEnumUnitedStates"
        defaultMessage="United States"
      />
    )
  }
];

export const COUNTRY_BY_ROLE_DE = [
  {
    value: COUNTRY_ID_ENUM.GERMANY,
    name: (
      <FormattedMessage
        id="common.countryEnumGermany"
        defaultMessage="Germany"
      />
    )
  }
];

export const FORMULA_STATUS = [
  {
    value: STATUS_ENUM.IN_ACTIVE,
    name: (
      <FormattedMessage
        id="common.statusEnumInactive"
        defaultMessage="Inactive"
      />
    )
  },
  {
    value: STATUS_ENUM.BLOCKED,
    name: (
      <FormattedMessage
        id="common.statusEnumBlocked"
        defaultMessage="Blocked"
      />
    )
  },
  {
    value: STATUS_ENUM.INPROGRESS,
    name: (
      <FormattedMessage
        id="common.statusEnumInprogress"
        defaultMessage="InProgress"
      />
    )
  },
  {
    value: STATUS_ENUM.APPROVED,
    name: (
      <FormattedMessage
        id="common.statusEnumApproved"
        defaultMessage="Approved"
      />
    )
  },
  {
    value: STATUS_ENUM.UN_APPROVED,
    name: (
      <FormattedMessage
        id="common.statusEnumUnapproved"
        defaultMessage="Unapproved"
      />
    )
  }
];

export const PART_NUMBER_STATUS = [
  {
    value: STATUS_ENUM.ACTIVE,
    name: (
      <FormattedMessage id="common.statusEnumActive" defaultMessage="Active" />
    )
  },
  {
    value: STATUS_ENUM.IN_ACTIVE,
    name: (
      <FormattedMessage
        id="common.statusEnumInactive"
        defaultMessage="Inactive"
      />
    )
  }
];

export const DISPLAY_PART_NUMBER_STATUS = {
  Active: (
    <FormattedMessage id="common.statusEnumActive" defaultMessage="Active" />
  ),
  Inactive: (
    <FormattedMessage
      id="common.statusEnumInactive"
      defaultMessage="Inactive"
    />
  )
};

export const FORMULA_SAMPLE = {
  A: 'A-sample',
  AB: 'AB-sample',
  B: 'B-sample',
  D: 'D-sample'
};

export const TYPE_FORMULA_FILTER = [
  {
    name: 'A-sample',
    value: FORMULA_SAMPLE.A
  },
  {
    name: 'AB-sample',
    value: FORMULA_SAMPLE.AB
  },
  {
    name: 'B-sample',
    value: FORMULA_SAMPLE.B
  },
  {
    name: 'D-sample',
    value: FORMULA_SAMPLE.D
  }
];

export const FORMULA_ENUM_FORM = {
  LIQUID: 'Liquid',
  GEL: 'Gel',
  PASTE: 'Paste',
  POWDER: 'Powder',
  STICKS: 'Sticks',
  TABS: 'Tabs'
};
export const DISPLAY_FORMULA_FORM = {
  Liquid: 'detergent.liquid',
  Gel: 'detergent.gel',
  Paste: 'detergent.paste',
  Powder: 'detergent.powder',
  Sticks: 'detergent.sticks',
  Tabs: 'containerType.value.tabs'
};
export const FORMULA_FORM = [
  {
    value: FORMULA_ENUM_FORM.LIQUID,
    name: <FormattedMessage id="detergent.liquid" defaultMessage="Liquid" />
  },
  {
    value: FORMULA_ENUM_FORM.GEL,
    name: <FormattedMessage id="detergent.gel" defaultMessage="Gel" />
  },
  {
    value: FORMULA_ENUM_FORM.PASTE,
    name: <FormattedMessage id="detergent.paste" defaultMessage="Paste" />
  },
  {
    value: FORMULA_ENUM_FORM.POWDER,
    name: <FormattedMessage id="detergent.powder" defaultMessage="Powder" />
  },
  {
    value: FORMULA_ENUM_FORM.STICKS,
    name: <FormattedMessage id="detergent.sticks" defaultMessage="Sticks" />
  },
  {
    value: FORMULA_ENUM_FORM.TABS,
    name: (
      <FormattedMessage id="containerType.value.tabs" defaultMessage="Tabs" />
    )
  }
];

export const RAW_MATERIAL_STATUS = [
  {
    value: STATUS_ENUM.IN_ACTIVE,
    name: 'Inactive',
    nameDisplay: <FormattedMessage id="common.statusEnumInactive" />
  },
  {
    value: STATUS_ENUM.ACTIVE,
    name: 'Active',
    nameDisplay: <FormattedMessage id="common.statusEnumActive" />
  },
  {
    value: STATUS_ENUM.BLOCKED,
    name: 'Blocked',
    nameDisplay: <FormattedMessage id="common.statusEnumBlocked" />
  }
];

export const DETERGENT_PRODUCER_STATUS = [
  {
    value: STATUS_ENUM.ACTIVE,
    name: 'Active',
    nameDisplay: <FormattedMessage id="common.statusEnumActive" />
  },
  {
    value: STATUS_ENUM.IN_ACTIVE,
    name: 'Inactive',
    nameDisplay: <FormattedMessage id="common.statusEnumInactive" />
  }
];

export const DETERGENT_STATUS = [
  {
    value: STATUS_ENUM.IN_ACTIVE,
    name: 'Inactive',
    nameDisplay: <FormattedMessage id="common.statusEnumInactive" />
  },
  {
    value: STATUS_ENUM.INPROGRESS,
    name: 'Inprogress',
    nameDisplay: <FormattedMessage id="common.statusEnumInProgress" />
  },
  {
    value: STATUS_ENUM.APPROVED,
    name: 'Approved',
    nameDisplay: <FormattedMessage id="common.statusEnumApproved" />
  },
  {
    value: STATUS_ENUM.BLOCKED,
    name: 'Blocked',
    nameDisplay: <FormattedMessage id="common.statusEnumBlocked" />
  },
  {
    value: STATUS_ENUM.UN_APPROVED,
    name: 'Unapproved',
    nameDisplay: <FormattedMessage id="common.statusEnumUnapproved" />
  }
];
export const DISPLAY_CATEGORY_RAW_MATERIAL = {
  1: 'rawMaterial.technical',
  2: 'rawMaterial.safety',
  3: 'detergent.document.other'
};
export const RAW_MATERIAL_DOCUMENT_CATEGORIES = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="rawMaterial.technical"
        defaultMessage="Technical data sheet"
      />
    )
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="rawMaterial.safety"
        defaultMessage="Safety data sheet"
      />
    )
  },
  {
    id: 3,
    name: (
      <FormattedMessage id="detergent.document.other" defaultMessage="Other" />
    )
  }
];

export const DISPLAY_FORMULA_DOCUMENTS_CATEGORIES = {
  'Lab project': (
    <FormattedMessage
      id="detergent.document.labProject"
      defaultMessage="Lab project"
    />
  ),
  'Development request': (
    <FormattedMessage
      id="detergent.document.development"
      defaultMessage="Development request"
    />
  ),
  'Initial production test protocol': (
    <FormattedMessage
      id="detergent.document.production"
      defaultMessage="Production test protocol"
    />
  ),
  'Production test protocol': (
    <FormattedMessage
      id="detergent.document.production"
      defaultMessage="Production test protocol"
    />
  ),
  'Safety data sheet': (
    <FormattedMessage
      id="detergent.document.safety"
      defaultMessage="Safety data sheet"
    />
  ),
  'Parts list': (
    <FormattedMessage
      id="detergent.document.parts"
      defaultMessage="Parts list"
    />
  ),
  'Product label': (
    <FormattedMessage
      id="detergent.document.productLabel"
      defaultMessage="Product label"
    />
  ),
  'Transport label': (
    <FormattedMessage
      id="detergent.document.transport"
      defaultMessage="Transport label"
    />
  ),
  'Labeling instructions': (
    <FormattedMessage
      id="detergent.document.labelInstructions"
      defaultMessage="Labeling instructions"
    />
  ),
  Other: (
    <FormattedMessage id="detergent.document.other" defaultMessage="Other" />
  )
};

export const DETERGENT_FORMULA_DOCUMENT_CATEGORIES = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="detergent.document.labProject"
        defaultMessage="Lab project"
      />
    )
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="detergent.document.development"
        defaultMessage="Development request"
      />
    )
  },
  {
    id: 3,
    name: (
      <FormattedMessage
        id="detergent.document.initialProduction"
        defaultMessage="Initial production test protocol"
      />
    )
  },
  {
    id: 4,
    name: (
      <FormattedMessage
        id="detergent.document.production"
        defaultMessage="Production test protocol"
      />
    )
  },
  {
    id: 5,
    name: (
      <FormattedMessage
        id="detergent.document.safety"
        defaultMessage="Safety data sheet"
      />
    )
  },
  {
    id: 6,
    name: (
      <FormattedMessage
        id="detergent.document.parts"
        defaultMessage="Parts list"
      />
    )
  },
  {
    id: 7,
    name: (
      <FormattedMessage
        id="detergent.document.productLabel"
        defaultMessage="Product label"
      />
    )
  },
  {
    id: 8,
    name: (
      <FormattedMessage
        id="detergent.document.transport"
        defaultMessage="Transport label"
      />
    )
  },
  {
    id: 9,
    name: (
      <FormattedMessage
        id="detergent.document.labelInstructions"
        defaultMessage="Labeling instructions"
      />
    )
  },
  {
    id: 10,
    name: (
      <FormattedMessage id="detergent.document.other" defaultMessage="Other" />
    )
  }
];
export const DISPLAY_PROJECT_TYPES = [
  {
    value: 'Lab project',
    name: 'detergent.document.labProject'
  },
  {
    value: 'Development request',
    name: 'detergent.document.development'
  },
  {
    value: 'Change request',
    name: 'detergent.document.changeRequest'
  }
];

export const PROJECT_TYPES = [
  {
    id: 1,
    value: 'Lab project',
    name: (
      <FormattedMessage
        id="detergent.document.labProject"
        defaultMessage="Lab project"
      />
    )
  },
  {
    id: 2,
    value: 'Development request',
    name: (
      <FormattedMessage
        id="detergent.document.development"
        defaultMessage="Development request"
      />
    )
  },
  {
    id: 3,
    value: 'Change request',
    name: (
      <FormattedMessage
        id="detergent.document.changeRequest"
        defaultMessage="Change request"
      />
    )
  },
  {
    id: 4,
    value: null,
    name: <FormattedMessage id="common.blank" defaultMessage="Blank" />
  }
];

export const CURRENCIES = [
  {
    name: 'USD',
    id: 'USD'
  },
  {
    name: 'EUR',
    id: 'EUR'
  }
];

export const ACCEPT_FILE_TYPES_OF_DOCUMENT = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
].join(',');

export const ACTION_BUTTON_TYPES = {
  DELETE_RAW_MATERIAL_GROUP: 'DELETE_RAW_MATERIAL_GROUP',
  EDIT_RAW_MATERIAL_GROUP: 'EDIT_RAW_MATERIAL_GROUP',
  DELETE_RAW_MATERIAL_PROUCER: 'DELETE_RAW_MATERIAL_PROUCER',
  VIEW_RAW_MATERIAL_DETAIL: 'VIEW_RAW_MATERIAL_DETAIL',
  BLOCK_RAW_MATERIAL: 'BLOCK_RAW_MATERIAL',
  UNLOCK_RAW_MATERIAL: 'UNLOCK_RAW_MATERIAL',
  EDIT_RAW_MATERIAL: 'EDIT_RAW_MATERIAL',

  BLOCK_RAW_MATERIAL_PRODUCER: 'BLOCK_RAW_MATERIAL_PRODUCER',
  UNBLOCK_RAW_MATERIAL_PRODUCER: 'UNBLOCK_RAW_MATERIAL_PRODUCER',
  EDIT_RAW_MATERIAL_PRODUCER: 'EDIT_RAW_MATERIAL_PRODUCER',
  BLOCK_DETERGENT_PRODUCER: 'BLOCK_DETERGENT_PRODUCER',
  UNBLOCK_DETERGENT_PRODUCER: 'UNBLOCK_DETERGENT_PRODUCER',
  EDIT_DETERGENT_PRODUCER: 'EDIT_DETERGENT_PRODUCER',

  ADD_NEW_FOMULAR: 'ADD_NEW_FOMULAR',
  EDIT_DETERGENTS: 'EDIT_DETERGENTS',
  VIEW_DETERGENT_DETAIL: 'VIEW_DETERGENT_DETAIL',
  CHECK_BOX_DETERGENTS: 'CHECK_BOX_DETERGENTS',
  DUPLICATE_DETERGENT: 'DUPLICATE_DETERGENT',
  EDIT_DETERGENT_FORMULA_SAMPLE: 'EDIT_DETERGENT_FORMULA_SAMPLE',
  EDIT_DETERGENT_FORMULA_STATUS: 'EDIT_DETERGENT_FORMULA_STATUS',
  UPDATE_FORMULA_STATUS_ACTIONS: 'UPDATE_FORMULA_STATUS_ACTIONS',
  PREVENT_ON_ROW_TRIGGER: 'PREVENT_ON_ROW_TRIGGER',

  DEACTIVATE_USER: 'DEACTIVATE_USER',
  ACTIVATE_USER: 'ACTIVATE_USER',
  EDIT_USER_ROLE: 'EDIT_USER_ROLE',

  SELECT_DROPDOWN: 'ant-select',

  EDIT_PARTNUMBER_ACTION: 'EDIT_PARTNUMBER_ACTION',
  EDIT_PART_NUMBER_STATUS: 'EDIT_PART_NUMBER_STATUS',

  EDIT_PRODUCTION_CYCLE_SINGLE: 'EDIT_PRODUCTION_CYCLE_SINGLE',

  DELETE_PRODUCTION_CYCLE: 'DELETE_PRODUCTION_CYCLE'
};

export const BASE_DB_FIELDS = {
  ID: 'id',
  UPDATE_AT: 'updateAt',
  CREATE_AT: 'createAt'
};

export const BLACK_LIST_FORM_FIELDS = {
  RAW_MATERIAL_FORM: ['isRestricted']
};

export const RAW_MATERIAL_LABELS = {
  name: 'Name',
  isSupplierDifferentFromProducer: 'Supplier is not producer',
  commodityGroup: 'Commodity group',
  chemicalName: 'Chemical name',
  casNumber: 'CAS number',
  einecsNumber: 'Einecs number',
  price: 'Price per kilogram',
  currency: 'Currency',
  partNumber: 'Part number',
  additionalInformation: 'Additional information',
  documents: 'Documents',
  retrictedCountries: 'Retricted countries',
  producers: 'Producers',
  suppliers: 'Suppliers',
  rawMaterialGroups: 'Raw material groups'
};

export const DETERGENT_LABELS = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return {
    number: formatMessage({
      id: 'detergent.number',
      defaultMessage: 'Detergent Number'
    }),
    name: formatMessage({
      id: 'detergent.name',
      defaultMessage: 'Detergent Name'
    }),
    form: formatMessage({
      id: 'detergent.form',
      defaultMessage: 'Form'
    }),
    sample: formatMessage({
      id: 'formula.type',
      defaultMessage: 'Formula type'
    }),
    density: formatMessage({
      id: 'formula.density',
      defaultMessage: 'Density'
    }),
    productionInstruction: formatMessage({
      id: 'formula.instructions',
      defaultMessage: 'Production instructions'
    }),
    propertyDescription: formatMessage({
      id: 'formula.properties',
      defaultMessage: 'Formulas properties'
    }),
    rawMaterialRatios: formatMessage({
      id: 'formula.rawMaterialRatios',
      defaultMessage: 'Raw materials'
    })
  };
};

export const RAW_MATERIAL_GROUP_STATUS = {
  IN_USE: 'In use',
  NOT_IN_USE: 'Not in use'
};
export const UPDATE_FORMULA_STATUS = [
  {
    id: 'common.statusEnumInProgress',
    value: 'Inprogress'
  },
  {
    id: 'common.statusEnumApproved',
    value: 'Approved'
  },
  {
    id: 'common.statusEnumUnapproved',
    value: 'Unapproved'
  },
  {
    id: 'common.statusEnumInactive',
    value: 'Inactive'
  },
  {
    id: 'common.statusEnumActive',
    value: 'Active'
  }
];
export const DISPLAY_FORMULA_STATUS = {
  Inprogress: (
    <FormattedMessage
      id="common.statusEnumInProgress"
      defaultMessage="In Progress"
    />
  ),
  Approved: (
    <FormattedMessage
      id="common.statusEnumApproved"
      defaultMessage="Approved"
    />
  ),
  Unapproved: (
    <FormattedMessage
      id="common.statusEnumUnapproved"
      defaultMessage="Unapproved"
    />
  ),
  Inactive: (
    <FormattedMessage
      id="common.statusEnumInactive"
      defaultMessage="Inactive"
    />
  ),
  Blocked: (
    <FormattedMessage id="common.statusEnumBlocked" defaultMessage="Blocked" />
  )
};

export const FORM_STATUS = {
  CREATE: 'create',
  UPDATE: 'update',
  ADD_FORMULA: 'addFormula',
  VIEW_DETAIL: 'viewDetail',
  DELETE: 'delete',
  ADD_PRODUCTION_CYCLE: 'addProductionCycle',
  COMPARE: 'compare',
  DUPLICATE_DETERGENT: 'duplicateDetergent',
  MIGRATE_ANNUAL_DEMAND: 'migrateAnnualDemand',
  SEARCH: 'search'
};

export const AXIOS_RETRY_CODES = [502];

export const UPLOAD_TYPES = {
  DETERGENT: 'detergent',
  RAW_MATERIAL: 'rawMaterial'
};
export const MEASUREMENT_MAPPING_FIELD_CHEMICAL = {
  densityDIN51757: {
    key: 'measurement.Test.densityDIN',
    name: 'Density DIN 51757 (g/cm³)'
  },
  bulkDensityDIN697: {
    key: 'measurement.Test.bulkDensityDIN',
    name: 'Bulk density DIN 697 (g/cm³)'
  },
  refrectionIndex: {
    key: 'measurement.Test.refractionIndex',
    name: 'Refraction index'
  },
  cloudPointDIN53917: {
    key: 'chemical.cloud.point.din',
    name: 'Cloud point DIN °C 53917'
  },
  dryWeightMettlerIRdryer155: {
    key: 'measurement.Test.dryWeight',
    name: 'Dry weight (%) Mettler IR-dryer 155°C'
  },
  mass: {
    key: 'measurement.Test.mass',
    name: 'Mass (g/tablet)'
  },
  appearanceColourSmell: {
    key: 'measurement.Test.appearanceColourSmell',
    name: 'Appearance, colour, smell'
  },
  viscosityTwentyC: {
    key: 'measurement.other.viscosity',
    name: 'Viscosity (20°C) [mPa*s]'
  },
  adapter: {
    key: 'measurement.other.adapter',
    name: 'Adapter'
  },
  rotationalSpeed: {
    key: 'measurement.other.rotationalSpeed',
    name: 'Rotational speed'
  },
  temperature: {
    key: 'measurement.other.temperature',
    name: 'Temperature'
  }
};
export const MEASUREMENT_MAPPING_FIELD = {
  waterSolubility: {
    key: 'waterSolubility',
    name: (
      <FormattedMessage
        defaultMessage="Water solubility"
        id="measurement.Test.waterSolubility"
      />
    )
  },
  appearanceColourSmell: {
    key: 'appearanceColourSmell',
    name: (
      <FormattedMessage
        defaultMessage="Appearance, colour, smell"
        id="measurement.Test.appearanceColourSmell"
      />
    )
  },
  tabWaterLabel: {
    key: 'tabWaterLabel',
    name: (
      <FormattedMessage
        defaultMessage="in tab water"
        id="measurement.phValue.tabWater"
      />
    )
  },
  labInformation: {
    key: 'labInformation',
    name: (
      <FormattedMessage
        defaultMessage="Lab Information"
        id="measurement.lab.title"
      />
    )
  },
  cloudPointDIN53917: {
    key: 'cloudPointDIN53917',
    name: (
      <FormattedMessage
        defaultMessage="Cloud point DIN °C 53917"
        id="chemical.cloud.point.din"
      />
    )
  },
  densityDIN51757: {
    key: 'densityDIN51757',
    name: (
      <FormattedMessage
        defaultMessage="Density DIN 51757 (g/cm³)"
        id="measurement.Test.densityDIN"
      />
    )
  },
  refrectionIndex: {
    key: 'refrectionIndex',
    name: (
      <FormattedMessage
        defaultMessage="Refraction index"
        id="measurement.Test.refractionIndex"
      />
    )
  },
  bulkDensityDIN697: {
    key: 'bulkDensityDIN697',
    name: (
      <FormattedMessage
        defaultMessage="Bulk density DIN 697 (g/cm³)"
        id="measurement.Test.bulkDensityDIN"
      />
    )
  },
  dissolutionTimeMinutes: {
    key: 'dissolutionTimeMinutes',
    name: (
      <FormattedMessage
        defaultMessage="Dissolution time (minutes)"
        id="measurement.Test.dissolutionTime"
      />
    )
  },
  dryWeightMettlerIRdryer155: {
    key: 'dryWeightMettlerIRdryer155',
    name: (
      <FormattedMessage
        defaultMessage="Dry weight (%) Mettler IR-dryer 155°C"
        id="measurement.Test.dryWeight"
      />
    )
  },
  mass: {
    key: 'mass',
    name: (
      <FormattedMessage
        defaultMessage="Mass (g/tablet)"
        id="measurement.Test.mass"
      />
    )
  },
  dimensions: {
    key: 'dimensions',
    name: (
      <FormattedMessage
        defaultMessage="Dimensions (mm)"
        id="measurement.Test.dimensions"
      />
    )
  },
  durability: {
    key: 'durability',
    name: (
      <FormattedMessage
        defaultMessage="Durability"
        id="measurement.Test.durability"
      />
    )
  },
  recommendedStorageTemparature: {
    key: 'recommendedStorageTemparature',
    name: (
      <FormattedMessage
        defaultMessage="Recommended storage temparature"
        id="measurement.Test.recommendStorageTemparature"
      />
    )
  },
  roomTemperatureFourWeeks: {
    key: 'roomTemperatureFourWeeks',
    name: (
      <FormattedMessage
        defaultMessage="Room temperature, 4 weeks"
        id="measurement.storageTest.RoomTemparatureBy4Weeks"
      />
    )
  },
  roomTemperatureEightWeeks: {
    key: 'roomTemperatureEightWeeks',
    name: (
      <FormattedMessage
        defaultMessage="Room temperature, 8 weeks"
        id="measurement.storageTest.RoomTemparatureBy8Weeks"
      />
    )
  },
  roomTemperatureSixMonths: {
    key: 'roomTemperatureSixMonths',
    name: (
      <FormattedMessage
        defaultMessage="Room temperature, 6 months"
        id="measurement.storageTest.RoomTemparatureBy6Months"
      />
    )
  },
  roomTemperatureTwelveMonths: {
    key: 'roomTemperatureTwelveMonths',
    name: (
      <FormattedMessage
        defaultMessage="Room temperature, 12 months"
        id="measurement.storageTest.RoomTemparatureBy12Months"
      />
    )
  },
  roomTemperatureTwentyFourMonths: {
    key: 'roomTemperatureTwentyFourMonths',
    name: (
      <FormattedMessage
        defaultMessage="Room temperature, 24 months"
        id="measurement.storageTest.RoomTemparatureBy24Months"
      />
    )
  },
  fiftyCThreeDays: {
    key: 'fiftyCThreeDays',
    name: (
      <FormattedMessage
        defaultMessage="50°C, 3 days"
        id="measurement.storageTest.days"
      />
    )
  },
  thirtySevenCFourWeeks: {
    key: 'thirtySevenCFourWeeks',
    name: (
      <FormattedMessage
        defaultMessage="37°C, 4 weeks"
        id="measurement.storageTest.37weeks"
      />
    )
  },
  fourCFourWeeks: {
    key: 'fourCFourWeeks',
    name: (
      <FormattedMessage
        defaultMessage="4°C, 4 weeks"
        id="measurement.storageTest.4weeks"
      />
    )
  },
  negativeTwentyOneDay: {
    key: 'negativeTwentyOneDay',
    name: (
      <FormattedMessage
        defaultMessage="-20°C, 1 day"
        id="measurement.storageTest.day"
      />
    )
  },
  concentrate: {
    key: 'concentrate',
    name: (
      <FormattedMessage
        defaultMessage="Concentrate"
        id="measurement.phValue.concentrate"
      />
    )
  },
  tabWater: {
    key: 'tabWater',
    name: (
      <FormattedMessage
        defaultMessage="in tab water"
        id="measurement.phValue.tabWater"
      />
    )
  },
  corrosivityTest: {
    key: 'corrosivityTest',
    name: (
      <FormattedMessage
        defaultMessage="Corrosivity test according to ATSM"
        id="measurement.safety.corrosivity"
      />
    )
  },
  flashPointAccordingToDIN: {
    key: 'flashPointAccordingToDIN',
    name: (
      <FormattedMessage
        defaultMessage="Flash point DIN 51755/51758 (°C)"
        id="measurement.safety.flashPointDIN"
      />
    )
  },
  flashPointSixtyPointFiveCIATA: {
    key: 'flashPointSixtyPointFiveCIATA',
    name: (
      <FormattedMessage
        defaultMessage="Flash point <60.5°C IATA"
        id="measurement.safety.flashPointIATA"
      />
    )
  },
  chemicalOxygenDemand: {
    key: 'chemicalOxygenDemand',
    name: (
      <FormattedMessage
        defaultMessage="Chemical oxygen demand (0.1% solution in tab water/LCK)"
        id="measurement.other.chemicalOxygen"
      />
    )
  },
  viscosityTwentyC: {
    key: 'viscosityTwentyC',
    name: (
      <FormattedMessage
        defaultMessage="Viscosity (20°C) [mPa*s]"
        id="measurement.other.viscosity"
      />
    )
  },
  adapter: {
    key: 'adapter',
    name: (
      <FormattedMessage
        defaultMessage="Adapter"
        id="measurement.other.adapter"
      />
    )
  },
  rotationalSpeed: {
    key: 'rotationalSpeed',
    name: (
      <FormattedMessage
        defaultMessage="Rotational speed (rpm)"
        id="measurement.other.rotationalSpeed"
      />
    )
  },
  freezingPointC: {
    key: 'freezingPointC',
    name: (
      <FormattedMessage
        defaultMessage="Freezing point (°C)"
        id="measurement.other.freezingPoint"
      />
    )
  },
  asfOenorm5106: {
    key: 'asfOenorm5106',
    name: (
      <FormattedMessage
        defaultMessage="ASF OENORM 5106 (fast separation of oil/water)"
        id="measurement.other.ASF"
      />
    )
  },
  temperature: {
    key: 'temperature',
    name: (
      <FormattedMessage
        defaultMessage="Temperature [20/25°C]"
        id="measurement.other.temperature"
      />
    )
  }
};
export const MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID = {
  [STATUS_ENUM.UN_APPROVED]: 'common.statusEnumUnapproved',
  [STATUS_ENUM.IN_ACTIVE]: 'common.statusEnumInactive',
  [STATUS_ENUM.INPROGRESS]: 'common.statusEnumInProgress',
  [STATUS_ENUM.BLOCKED]: 'common.statusEnumBlocked',
  [STATUS_ENUM.APPROVED]: 'common.statusEnumApproved',
  [STATUS_ENUM.ACTIVE]: 'common.statusEnumActive'
};

export const MAPPING_FORMULA_STATUS_TO_CLASS_NAME = {
  [STATUS_ENUM.IN_ACTIVE]: 'grey-text',
  [STATUS_ENUM.UN_APPROVED]: 'grey-text',
  [STATUS_ENUM.INPROGRESS]: 'light-blue-text',
  [STATUS_ENUM.BLOCKED]: 'orange-text',
  [STATUS_ENUM.APPROVED]: 'green-text',
  [STATUS_ENUM.ACTIVE]: 'green-text'
};

export const MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME = {
  [STATUS_ENUM.IN_ACTIVE]: 'grey-text',
  [STATUS_ENUM.ACTIVE]: 'green-text'
};

export const MAPPING_RAW_MATERIAL_GROUP_TO_TEXT = {
  [RAW_MATERIAL_GROUP_STATUS.IN_USE]: 'rawMaterialGroup.status.inUse',
  [RAW_MATERIAL_GROUP_STATUS.NOT_IN_USE]: 'rawMaterialGroup.status.notInUse'
};

export const MAPPING_RAW_MATERIAL_GROUP_TO_CLASS_NAME = {
  [RAW_MATERIAL_GROUP_STATUS.IN_USE]: 'green-text',
  [RAW_MATERIAL_GROUP_STATUS.NOT_IN_USE]: 'red-text'
};

export const URL_PARAMS_ACTIONS = {
  OPEN_DETERGENT_DETAIL_DIALOG: 'OPEN_DETERGENT_DETAIL_DIALOG',
  OPEN_DETERGENT_EDIT_DIALOG: 'OPEN_DETERGENT_EDIT_DIALOG',
  OPEN_PARTNUMBER_DETAIL_DIALOG: 'OPEN_PARTNUMBER_DETAIL_DIALOG',
  OPEN_RAW_MATERIAL_DETAIL_DIALOG: 'OPEN_RAW_MATERIAL_DETAIL_DIALOG'
};

export const URL_QUERY_NAMES = {
  PARAMS: 'params'
};

export const DATE_FORMAT_LLL = 'lll';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY';
export const DATE_FORMAT_MM_DD_YYYY = 'L';
export const DATE_FORMAT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_FORMAT_UTC = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const FAKE_USER_NAMES = [
  'Jason',
  'Ian',
  'Tanu',
  'Sharon',
  'Sylvia',
  'Byron',
  'Tom',
  'Baxter',
  'Cameron',
  'Christian'
];

export const MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID = {
  detergent: 'detergent.title',
  name: 'common.name',
  number: 'detergent.number',
  sample: 'detergent.sample',
  form: 'formula.form',
  density: 'formula.density',
  productionInstruction: 'formula.instructions',
  propertyDescription: 'formula.properties',
  status: 'formula.status',
  containerSize: 'detergent.containerSizeTitle',
  noteOnFormulaChanges: 'formula.note',
  formulas: 'detergent.formulas',
  documents: 'detergent.formulas.documents',
  formulaMeasurement: 'detergent.formulas.formulaMeasurement',
  rawMaterialRatios: 'detergent.formulas.rawMaterialRatios',
  ratio: 'detergent.formulas.rawMaterialRatios.ratio',
  measured: 'measurement.measured.title',
  min: 'measurement.min.title',
  max: 'measurement.max.title',
  comment: 'measurement.comment.title',
  passFailed: 'measurement.passFailed.title',
  cloudPointDIN53917: 'measurement.Test.cloudPointDIN',
  densityDIN51757: 'measurement.Test.densityDIN',
  refrectionIndex: 'measurement.Test.refractionIndex',
  bulkDensityDIN697: 'measurement.Test.bulkDensityDIN',
  dissolutionTimeMinutes: 'measurement.Test.dissolutionTime',
  waterSolubility: 'measurement.Test.waterSolubility',
  dryWeightMettlerIRdryer155: 'measurement.Test.dryWeight',
  appearanceColourSmell: 'measurement.Test.appearanceColourSmell',
  mass: 'measurement.Test.mass',
  dimensions: 'measurement.Test.dimensions',
  durability: 'measurement.Test.durability',
  recommendedStorageTemparature: 'measurement.Test.recommendStorageTemparature',
  roomTemperatureFourWeeks: 'measurement.storageTest.RoomTemparatureBy4Weeks',
  roomTemperatureEightWeeks: 'measurement.storageTest.RoomTemparatureBy8Weeks',
  roomTemperatureSixMonths: 'measurement.storageTest.RoomTemparatureBy6Months',

  roomTemperatureTwelveMonths:
    'measurement.storageTest.RoomTemparatureBy12Months',

  roomTemperatureTwentyFourMonths:
    'measurement.storageTest.RoomTemparatureBy24Months',

  fiftyCThreeDays: 'measurement.storageTest.days',
  thirtySevenCFourWeeks: 'measurement.storageTest.37weeks',

  fourCFourWeeks: 'measurement.storageTest.4weeks',

  negativeTwentyOneDay: 'measurement.storageTest.day',
  concentrate: 'measurement.phValue.concentrate',
  tabWaterLabel: 'measurement.tabWaterLabel.title',
  tabWater: 'measurement.tabWaterValue.title',
  corrosivityTest: 'measurement.safety.corrosivity',
  flashPointAccordingToDIN: 'measurement.safety.flashPointDIN',
  flashPointSixtyPointFiveCIATA: 'measurement.safety.flashPointIATA',
  chemicalOxygenDemand: 'measurement.other.chemicalOxygen',
  viscosityTwentyC: 'measurement.other.viscosity',
  rotationalSpeed: 'measurement.other.rotationalSpeed',
  adapter: 'measurement.other.adapter',
  freezingPointC: 'measurement.other.freezingPoint',
  asfOenorm5106: 'measurement.other.ASF',
  labInformation: 'measurement.lab.title',
  temperature: 'measurement.other.temperature',
  url: 'document.url',
  key: 'document.key',
  category: 'document.category',
  developerEmail: 'detergent.label.formulaDeveloper',
  projectType: 'formula.projectType',
  projectNumber: 'formula.projectNumber',
  fromCountry: 'dbFromCountry'
};

export const MEASUREMENT_ASF_CORE_VALUE = {
  yes: 'Yes',
  no: 'No'
};

export const MAPPING_ASF_MEASURED_LABELS = {
  '0': MEASUREMENT_ASF_CORE_VALUE.no,
  '1': MEASUREMENT_ASF_CORE_VALUE.yes
};
export const DISPLAY_MEASUREMENT_ASF = {
  No: 'productionCycle.no',
  Yes: 'productionCycle.yes'
};

export const DISPLAY_MEASUREMENT_TEMPERATURES = {
  '20': '20°C',
  '25': '25°C'
};

export const FORMULA_FORM_STATUS_TYPE = {
  STATUS: 'status',
  SAMPLE: 'sample'
};
export const MAPPING_HISTORY_RAW_MATERIAL_TO_TRANSLATE_ID = {
  isSupplierDifferentFromProducer: {
    true: { id: 'common.checked' },
    false: { id: 'common.unChecked' }
  }
};

export const ANNUAL_DEMAND_COLUMNS = {
  YEAR: 'year',
  NUMBER_OF_CONTAINERS: 'numberOfContainers',
  ANNUAL_TONNAGE: 'annualTonnage',
  ID: 'id',
  TEMP_ID: 'tempId'
};

export const MAPPING_PART_NUMBER_HISTORY_TO_TRANSLATION_ID = {
  partNumber: 'partNumbers.columnPartNumber',
  descriptionSAP: 'partNumbers.columnSAPDescription',
  detergentFormula: 'detergent.formulas',
  detergent: 'partNumbers.columnDetergentName',
  version: 'partNumbers.columnFormulaVersion',
  detergentProducer: 'partNumber.form.detergentProducer',
  name: 'partNumbers.name',
  id: 'common.columnId',
  annualDemands: 'partNumbers.annualDemandTitle',
  containerType: 'partNumber.form.containerType',
  containerTypeDescription: 'partNumber.form.containerTypeDescription',
  containerGroup: 'partNumber.form.containerGroup',
  dateOfProducerAccess: 'partNumbers.columnDetergentProducerAccessDate',
  approvedDate: 'partNumbers.columnFormulaApprovalDate',
  [ANNUAL_DEMAND_COLUMNS.YEAR]: 'partNumbers.columnYear',
  [ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS]:
    'partNumbers.columnPlannedContainer',
  [ANNUAL_DEMAND_COLUMNS.ANNUAL_TONNAGE]: 'partNumbers.columnAnnualTonnage',
  number: 'common.number',
  fromCountry: 'dbFromCountry',
  status: 'partNumbers.columnStatus'
};

export const LIST_EDITING_DETERGENT_PERMISIONS = [
  STATUS_ENUM.INPROGRESS,
  STATUS_ENUM.UN_APPROVED
];

export const LIST_EDITING_PRODUCTION_CYCLES_PERMISIONS = [STATUS_ENUM.APPROVED];

export const USER_ROLES = {
  Administrator: 'administrator',
  DetergentDevelopment: 'detergentDevelopment',
  Procurement: 'procurement',
  ExternalDetergentProducers: 'kna',
  InHouseProduction: 'inHouse',
  NA: 'NA'
};

export const USER_ROLE_LABELS = {
  [USER_ROLES.Administrator]: (
    <FormattedMessage id="role.admin" defaultMessage="Administrator" />
  ),
  [USER_ROLES.DetergentDevelopment]: (
    <FormattedMessage
      id="role.development"
      defaultMessage="Detergent Development (DE)"
    />
  ),
  [USER_ROLES.Procurement]: (
    <FormattedMessage id="role.procurement" defaultMessage="Procurement (DE)" />
  ),
  [USER_ROLES.ExternalDetergentProducers]: (
    <FormattedMessage
      id="role.external"
      defaultMessage="External detergent producers (KNA)"
    />
  ),
  [USER_ROLES.InHouseProduction]: (
    <FormattedMessage
      id="role.inHouse"
      defaultMessage="In-house Production (DE)"
    />
  ),
  [USER_ROLES.NA]: <FormattedMessage id="role.na" defaultMessage="NA" />
};

export const USER_ROLES_COLLECTION = [
  {
    id: USER_ROLES.Administrator,
    label: USER_ROLE_LABELS.administrator
  },
  {
    id: USER_ROLES.DetergentDevelopment,
    label: USER_ROLE_LABELS.detergentDevelopment
  },
  {
    id: USER_ROLES.Procurement,
    label: USER_ROLE_LABELS.procurement
  },
  {
    id: USER_ROLES.InHouseProduction,
    label: USER_ROLE_LABELS.inHouse
  },
  {
    id: USER_ROLES.ExternalDetergentProducers,
    label: USER_ROLE_LABELS.kna
  }
];

export const PAGE_MODES = {
  AdminMode: 'admin-mode',
  UserMode: 'user-mode'
};

export const DEFAULT_ROUTE_BY_PAGE_MODE = {
  [PAGE_MODES.AdminMode]: '/user-management',
  [PAGE_MODES.UserMode]: '/detergent'
};

export const ADMIN_MODE_ROUTES = ['/logs', '/user-management'];

export const PAGE_ROUTES = [
  '/detergent',
  '/raw-material',
  '/raw-material-producers',
  '/part-numbers',
  '/group',
  '/detergent-producers',
  '/production-cycle'
];

export const COGNITO_USER_ATTRIBUTES = {
  region: 'custom:region',
  status: 'custom:status',
  role: 'custom:role',
  email: 'email'
};

export const EMAIL_TEMPLATE = {
  SAFETY_TEAM: 'SAFETY',
  LABELING_TEAM: 'LABELING'
};

export const RAW_MATERIAL_COLUMNS = {
  IS_SUPPLIER_DIFFERENT_FROM_PRODUCER: 'isSupplierDifferentFromProducer'
};

export const MAPPING_RAW_MATERIAL_HISTORY_TO_TRANSLATION_ID = {
  name: 'rawMaterial.columnName',
  commodityGroup: 'rawMaterial.commodityGroup',
  chemicalName: 'rawMaterial.rawMaterialChemicalName',
  casNumber: 'rawMaterial.casNumber',
  einecsNumber: 'rawMaterial.einecNumber',
  price: 'rawMaterial.pricePerKiligram',
  currency: 'common.currency',
  partNumber: 'rawMaterial.rawMaterialPartNumber',
  additionalInformation: 'rawMaterial.additional',
  isSupplierDifferentFromProducer: 'common.theProducerDiffersFromTheSupplier',
  documents: 'rawMaterial.documents',
  category: 'rawMaterial.documentCategory',
  url: 'document.url',
  key: 'document.key',
  status: 'rawMaterialGroup.status',
  'raw-material': 'generateFormula.rawMaterial',
  suppliers: 'rawMaterial.supplier',
  retrictedCountries: 'producer.country',
  producers: 'rawMaterial.producer',
  rawMaterialGroups: 'rawMaterial.rawMaterialGroup',
  fromCountry: 'dbFromCountry',
  identicalSubstitutes: 'rawMaterial.identicalSubstitutes',
  limitedSubstitutes: 'rawMaterial.limitedSubstitutes'
};

export const HISTORY_TYPES = {
  UNCHANGED: 0,
  ADDED: 1,
  DELETED: 2,
  UPDATED: 3
};

export const MAPPING_RAW_MATERIAL_GROUPS_HISTORY_TO_TRANSLATION_ID = {
  name: 'rawMaterialGroup.name',
  description: 'rawMaterialGroup.description',
  status: 'rawMaterialGroup.status',
  fromCountry: 'dbFromCountry'
};

export const MAPPING_RAW_MATERIAL_PRODUCER_HISTORY_TO_TRANSLATION_ID = {
  name: 'producer.name',
  country: 'producer.country',
  street: 'producer.street',
  street_number: 'producer.streetNumber',
  postal_code: 'producer.postalCode',
  city: 'producer.city',
  contact_list: 'contactPerson.title',
  title: 'contactPerson.titleName',
  first_name: 'contactPerson.firstName',
  last_name: 'contactPerson.lastName',
  email: 'contactPerson.email',
  phone: 'contactPerson.phone',
  comment: 'comment.title',
  status: 'rawMaterialGroup.status',
  partNumbers: 'header.componentPartNumbers',
  partNumber: 'partNumber.form.partNumber',
  erpNumber: 'erp.title',
  fromCountry: 'fromCountryProducer'
};

export const MAPPING_PRODUCTION_CYCLE_HISTORY_TO_TRANSLATION_ID = {
  formulaReviewed: 'productionCycle.formulaReviewed',
  productionInstructionsEntered:
    'productionCycle.productionInstructionsEntered',
  necessaryProtocols: 'productionCycle.necessaryProtocols',
  initialProductionTestProtocol:
    'productionCycle.initialProductionTestProtocol',
  productionTestProtocol: 'productionCycle.productionTestProtocol',
  presenceOfDetergentDevelopment: 'productionCycle.inCaseOfInitialProduction',
  rawMaterialsNotUsedAnymore:
    'productionCycle.rawMaterialsThatAreNotUsedAnymore',
  useUpInPreviousFormulaVersion:
    'productionCycle.useUpInPreviousFormulaVersion',
  useInOtherFormulas: 'productionCycle.useInOtherFormulas',
  doNotUseAnymore: 'productionCycle.doNotUseAnymoreDispose',
  rawMaterialsAreNotAffected: 'productionCycle.rawMaterialsAreNotAffected',
  safetyDataSheetUploaded: 'productionCycle.safetyDataSheetUploaded',
  safetyTextReviewed: 'productionCycle.safetyTextReviewed',
  labelingInformation: 'productionCycle.labelingInformation',
  newLabel: 'productionCycle.newLabel',
  useUpLabelsInStock: 'productionCycle.useUpLabelsInStock',
  destroyLabelsInStock: 'productionCycle.destroyLabelsInStock',
  labelsNotAffected: 'productionCycle.labelsNotAffected',
  partsListUploaded: 'productionCycle.partsListUploaded',
  productLabelUploaded: 'productionCycle.productLabelUploaded',
  transportLabelUploaded: 'productionCycle.transportLabelUploaded',
  labelingInstructionsUploaded: 'productionCycle.labelingInstructionsUploaded',
  productionDate: 'productionCycle.columnProductionDate',
  producerAccessDate: 'partnumbers.producerAccessDate',
  comment: 'productionCycle.comment',
  partNumber: 'partNumbers.columnPartNumber',
  id: 'common.columnId',
  noteSafetyTextReviewed: 'productionCycle.noteSafetyTextReviewed',
  status: 'productionCycle.status',
  cycleClosed: 'productionCycle.cycleClosed'
};

export const MAPPING_USER_HISTORY_TO_TRANSLATION_ID = {
  status: 'rawMaterialGroup.status',
  roles: 'common.columnRole',
  role: 'common.columnRole',
  lang: 'common.languageText'
};

export const INITIAL_FILTER_DETERGENT_LIST_BUTTON_TEXT = () => {
  const { formatMessage } = useIntl();
  return {
    filterTitle: formatMessage({
      id: 'common.filter',
      defaultMessage: 'Filter'
    }),
    filterReset: formatMessage({
      id: 'common.resetBtn',
      defaultMessage: 'Reset'
    })
  };
};

export const FILTER_DETERGENT_LIST_BY_STATUS_LABEL = [
  {
    text: DISPLAY_FORMULA_STATUS[STATUS_ENUM.IN_ACTIVE],
    value: STATUS_ENUM.IN_ACTIVE
  },
  {
    text: DISPLAY_FORMULA_STATUS[STATUS_ENUM.BLOCKED],
    value: STATUS_ENUM.BLOCKED
  },
  {
    text: DISPLAY_FORMULA_STATUS[STATUS_ENUM.INPROGRESS],
    value: STATUS_ENUM.INPROGRESS
  },
  {
    text: DISPLAY_FORMULA_STATUS[STATUS_ENUM.APPROVED],
    value: STATUS_ENUM.APPROVED
  },
  {
    text: DISPLAY_FORMULA_STATUS[STATUS_ENUM.UN_APPROVED],
    value: STATUS_ENUM.UN_APPROVED
  }
];

export const COGNITO_USER_LANGUAGE = {
  en: 'en',
  de: 'de'
};

export const PASS_FAIL_LABELS = [
  {
    id: 1,
    value: 'Passed',
    name: <FormattedMessage id="detergent.passed" defaultMessage="Passed" />
  },
  {
    id: 2,
    value: 'Failed',
    name: <FormattedMessage id="detergent.failed" defaultMessage="Failed" />
  },
  {
    id: 3,
    value: '',
    name: <FormattedMessage id="productionCycle.blank" defaultMessage="Blank" />
  }
];

export const DISPLAY_PASS_FAIL = {
  Passed: <FormattedMessage id="detergent.passed" defaultMessage="Passed" />,
  Failed: <FormattedMessage id="detergent.failed" defaultMessage="Failed" />,
  '': <FormattedMessage id="productionCycle.blank" defaultMessage="Blank" />
};

export const BLANK_OPTION = {
  id: v4(),
  value: null,
  name: <FormattedMessage id="common.blank" defaultMessage="Blank" />
};

export const DOCUMENTS_FOR_PROCUREMENT = [
  'Formula',
  'ProductionTestProtocol',
  'InitialProductionTestProtocol'
];

export const MAPPING_CONTAINER_TYPE_TO_TEXT = {
  'Flasche 1ltr Profi': 'containerType.value.flasche.1ltr.profi',
  'Kanister 10l UN': 'containerType.value.kanister.10l',
  'Flasche 500ml Profi': 'containerType.value.flasche.500ml',
  'Fremdgebinde': 'containerType.value.fremdgebinde',
  'Kanister 25l': 'containerType.value.kanister.25l',
  '1kg Dose': 'containerType.value.1kgDose',
  'IBC 1000l': 'containerType.value.ibc1000l',
  'Kanister 20l UN': 'containerType.value.kanister.20l',
  'Kanister 5l UN': 'containerType.value.kanister.5l',
  'Flasche 30ml': 'containerType.value.flasche.30ml',
  '10 kg Eimer': 'containerType.value.10kgEimer',
  'Flasche PE 2.5l': 'containerType.value.flasche.pe2.5l',
  'Spundfass blau 220 L': 'containerType.value.spundfass',
  'Konturbeutel Teppichreiniger 100ml':
    'containerType.value.konturbeutel.teppichreiniger',
  'Flasche Advance 1L': 'containerType.value.flasche.advance',
  '4 x Konturbeutel bedruckt 20ml': 'containerType.value.4x',
  'Flasche 1ltr Retail': 'containerType.value.flasche.1ltr.retail',
  'Konturbeutel bedruckt 20ml': 'containerType.value.konturbeutel.bedruckt',
  'Flasche 0.5ltr Retail': 'containerType.value.flasche.0.5ltr.retail',
  'Tabs': 'containerType.value.tabs',
  'Flasche 125ml': 'containerType.value.flasche.125ml',
  'Flasche 50ml': 'containerType.value.flasche.50ml',
  'Flasche 0,5ltr Retail (kurzhals)':
    'containerType.value.flasche.0.5ltr.retail.kurzhals',
  'Kanister natural 3l UN': 'containerType.value.kanister.natural',
  'Pulver': 'containerType.value.pulver',
  'Flasche 0,5ltr Retail schwarz':
    'containerType.value.flasche.0.5ltr.retail.schwarz',
  'Others': 'containerType.value.others'
};

export const MAPPING_CONTAINER_GROUP_TO_TEXT = {
  [CONTAINER_GROUP_ENUM.SAMPLE_POUCH]: 'containerGroup.value.samplePouch',
  [CONTAINER_GROUP_ENUM.SMALL_BOTTLE]: 'containerGroup.value.smallBottle',
  [CONTAINER_GROUP_ENUM.BIG_BOTTLE]: 'containerGroup.value.bigBottle',
  [CONTAINER_GROUP_ENUM.CANISTER]: 'containerGroup.value.canister',
  [CONTAINER_GROUP_ENUM.BARREL]: 'containerGroup.value.barrel',
  [CONTAINER_GROUP_ENUM.IBC]: 'containerGroup.value.ibc',
  'Not defined': 'containerGroup.value.notDefined'
};

export const PRODUCTION_CYCLE_STATUS_ENUM = {
  IN_DEVELOPMENT: 'In Development',
  PRODUCT_SAFETY: 'Product Safety',
  LABELING: 'Labeling',
  PROCUREMENT: 'Procurement'
};

export const PRODUCTION_CYCLE_STATUS = [
  {
    name: 'productionCycle.statusInDevelopment',
    value: PRODUCTION_CYCLE_STATUS_ENUM.IN_DEVELOPMENT
  },
  {
    name: 'productionCycle.statusProductSafety',
    value: PRODUCTION_CYCLE_STATUS_ENUM.PRODUCT_SAFETY
  },
  {
    name: 'productionCycle.statusLabeling',
    value: PRODUCTION_CYCLE_STATUS_ENUM.LABELING
  },
  {
    name: 'productionCycle.stageProcurement',
    value: PRODUCTION_CYCLE_STATUS_ENUM.PROCUREMENT
  }
];

export const DISPLAY_PRODUCTION_CYCLE_STATUS = {
  [PRODUCTION_CYCLE_STATUS_ENUM.IN_DEVELOPMENT]: (
    <FormattedMessage
      id="productionCycle.statusInDevelopment"
      defaultMessage="In Development"
    />
  ),
  [PRODUCTION_CYCLE_STATUS_ENUM.PRODUCT_SAFETY]: (
    <FormattedMessage
      id="productionCycle.statusProductSafety"
      defaultMessage="Product Safety"
    />
  ),
  [PRODUCTION_CYCLE_STATUS_ENUM.LABELING]: (
    <FormattedMessage
      id="productionCycle.statusLabeling"
      defaultMessage="Labeling"
    />
  ),
  [PRODUCTION_CYCLE_STATUS_ENUM.PROCUREMENT]: (
    <FormattedMessage
      id="productionCycle.stageProcurement"
      defaultMessage="Procurement"
    />
  )
};

export const PRODUCTION_CYCLE_ACTION_ENUM = {
  CREATED: 'Created',
  FORWARDED: 'Forwarded',
  EDITED: 'Edited',
  DELETED: 'Deleted'
};

export const DISPLAY_PRODUCTION_CYCLE_ACTION = {
  [PRODUCTION_CYCLE_ACTION_ENUM.CREATED]: (
    <FormattedMessage
      id="productionCycle.actionCreated"
      defaultMessage="Created"
    />
  ),
  [PRODUCTION_CYCLE_ACTION_ENUM.FORWARDED]: (
    <FormattedMessage
      id="productionCycle.actionForwarded"
      defaultMessage="Forwarded"
    />
  ),
  [PRODUCTION_CYCLE_ACTION_ENUM.EDITED]: (
    <FormattedMessage
      id="productionCycle.actionEdited"
      defaultMessage="Edited"
    />
  ),
  [PRODUCTION_CYCLE_ACTION_ENUM.DELETED]: (
    <FormattedMessage
      id="productionCycle.actionDeleted"
      defaultMessage="Deleted"
    />
  )
};

export const PRODUCTION_CYCLE_STAGE_ENUM = {
  SAFETY: 'Safety',
  LABELING: 'Labeling',
  PROCUREMENT: 'Procurement'
};

export const DISPLAY_PRODUCTION_CYCLE_STAGE = {
  [PRODUCTION_CYCLE_STAGE_ENUM.SAFETY]: (
    <FormattedMessage
      id="productionCycle.stageSafety"
      defaultMessage="Safety"
    />
  ),
  [PRODUCTION_CYCLE_STAGE_ENUM.LABELING]: (
    <FormattedMessage
      id="productionCycle.stageLabeling"
      defaultMessage="Labeling"
    />
  ),
  [PRODUCTION_CYCLE_STAGE_ENUM.PROCUREMENT]: (
    <FormattedMessage
      id="productionCycle.stageProcurement"
      defaultMessage="Procurement"
    />
  )
};

export const FILTER_PRODUCTION_CYCLE_BY_STATUS = [
  {
    text: (
      <FormattedMessage
        id="productionCycle.statusInDevelopment"
        defaultMessage="In Development"
      />
    ),
    value: PRODUCTION_CYCLE_STATUS_ENUM.IN_DEVELOPMENT
  },
  {
    text: (
      <FormattedMessage
        id="productionCycle.statusProductSafety"
        defaultMessage="Product Safety"
      />
    ),
    value: PRODUCTION_CYCLE_STATUS_ENUM.PRODUCT_SAFETY
  },
  {
    text: (
      <FormattedMessage
        id="productionCycle.statusLabeling"
        defaultMessage="Labeling"
      />
    ),
    value: PRODUCTION_CYCLE_STATUS_ENUM.LABELING
  },
  {
    text: (
      <FormattedMessage
        id="productionCycle.stageProcurement"
        defaultMessage="Procurement"
      />
    ),
    value: PRODUCTION_CYCLE_STATUS_ENUM.PROCUREMENT
  }
];
