import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Icon, Row, Col, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  DETERGENT_STATUS,
  DISPLAY_COUNTRY
} from '../../../../configs/constant';
import { getPartNumber } from '../../data/partNumbers/actions';
import useCountriesByRole from '../../../../hooks/useCountriesByRole';

const _FilterFormPartNumber = props => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const dispatch = useDispatch();
  const countries = useCountriesByRole();
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    onCancel
  } = props;
  const { filterList, pagination, searchByText, order } = useSelector(
    state => ({
      filterList: state.partNumbers.filterList,
      pagination: state.partNumbers.list.data.pagination,
      searchByText: state.partNumbers.searchByText,
      order: state.partNumbers.order
    })
  );

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onCancel();
        pagination.current = 1;
        dispatch(
          getPartNumber({
            pagination,
            searchByText,
            order,
            filterList: values
          })
        );
      }
    });
  };

  const onClearFilter = () => {
    resetFields();
    const keys = Object.keys(filterList);
    keys.forEach(element => {
      filterList[element] = '';
    });
    pagination.current = 1;
    dispatch(getPartNumber({ pagination, searchByText, order, filterList }));
    onCancel();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 10 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 14 }
    }
  };
  return (
    <Form
      className="part-number-filter-form"
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onSubmit={handleSubmit}>
      <Form.Item
        label={
          <FormattedMessage
            id="partNumbers.filter"
            defaultMessage="Detergent Status"
          />
        }>
        <div id="parent-node">
          {getFieldDecorator('detergentStatus', {
            initialValue: filterList.detergentStatus
              ? filterList.detergentStatus
              : undefined
          })(
            <Select
              getPopupContainer={() => document.getElementById('parent-node')}
              suffixIcon={<Icon type="caret-down" />}
              className="filter-select"
              loading
              placeholder={formatMessage({
                id: 'partNumbers.selectStatus',
                defaultMessage: 'Select status'
              })}>
              {DETERGENT_STATUS.map(psItem => (
                <Select.Option key={psItem.value} value={psItem.value}>
                  {psItem.nameDisplay}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Form.Item
        label={formatMessage({
          id: 'common.fromCountry',
          defaultMessage: 'Country'
        })}>
        <div id="part-number-country-filter">
          {getFieldDecorator('fromCountryId', {
            initialValue: filterList.fromCountryId
              ? filterList.fromCountryId
              : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('part-number-country-filter')
              }
              className="filter-select"
              placeholder={formatMessage({
                id: 'common.selectCountry',
                defaultMessage: 'Select country'
              })}
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {countries.map(country => (
                <Select.Option key={country.value} value={country.value}>
                  {DISPLAY_COUNTRY[country.value]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button className="btn-cancel" onClick={() => onClearFilter()}>
              <FormattedMessage
                id="common.clearFilter"
                defaultMessage="CLEAR FILTER"
              />
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              <FormattedMessage id="common.okButton" defaultMessage="OK" />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

_FilterFormPartNumber.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export const FilterFormPartNumber = Form.create({
  name: 'filter-detergent-producers-form'
})(_FilterFormPartNumber);
