import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import { BaseTable } from '../../../../components/BaseTable';
import { RawMaterialColumnsFormat } from './renderColumns';
import { ACTION_BUTTON_TYPES } from '../../../../configs/constant';

const _List = props => {
  const { pagination, rawMaterials, loading } = useSelector(state => ({
    pagination: state.rawMaterials.list.data.pagination,
    rawMaterials: state.rawMaterials,
    loading: state.rawMaterials.fetching
  }));
  const onClickRow = (event, record) => {
    if (typeof event.target.className !== 'string') return;

    const isViewDetailEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.VIEW_RAW_MATERIAL_DETAIL
    );
    const isBlockRawMaterialEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.BLOCK_RAW_MATERIAL
    );
    const isEditRawMaterialEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.EDIT_RAW_MATERIAL
    );
    const isUnBlockRawMaterialEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.UNLOCK_RAW_MATERIAL
    );
    const isPreventOnRowTrigger = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER
    );

    if (isBlockRawMaterialEvent !== -1) {
      props.onBlockRawMaterial(record, 'Blocked');
    } else if (isEditRawMaterialEvent !== -1) {
      props.onEditRawMaterial(record);
    } else if (isUnBlockRawMaterialEvent !== -1) {
      props.onBlockRawMaterial(record, 'Inactive');
    } else if (isViewDetailEvent !== -1) {
      props.onViewDetail(record.id);
    } else if (isPreventOnRowTrigger !== -1) {
      // do nothing
    } else {
      props.onViewDetail(record.id);
    }
  };

  return (
    <div className="table-producer">
      <BaseTable
        bordered
        stickyHeader
        onRow={record => {
          return {
            onClick: event => onClickRow(event, record)
          };
        }}
        className="raw-material-list-table"
        pagination={pagination}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        columns={RawMaterialColumnsFormat()}
        dataSource={rawMaterials.list.data.results}
        onPageSizeChange={value => props.onPageSizeChange(value)}
        onPageChange={current => props.onPageChange(current)}
      />
    </div>
  );
};

export const RawMaterialList = _List;
export { DetergentList } from './DetergentList';
export { HistoryList } from './HistoryList';
