import React from 'react';
import { InputNumber } from 'antd';

const FormattedInputNumber = props => {
  return (
    <InputNumber
      formatter={value => value.replace('.', ',')}
      parser={value => value.replace(',', '.')}
      precision={4}
      {...props}
    />
  );
};

export default FormattedInputNumber;
