import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon } from 'antd';
import isEmpty from 'lodash/isEmpty';

import UserList from './components/List';
import { UserSearchList } from './components/UsersAddition/UserSearchList';
import {
  FORM_STATUS,
  STATUS_ENUM,
  ACTION_BUTTON_TYPES
} from '../../configs/constant';
import {
  processUserManagementModal,
  updateCognitoUser,
  getCognitoUser
} from './data/userManagement/actions';
import UserManagementModal from './components/Modal';
import { openConfirmModal } from '../../utils/dialogs';
import ConfirmChangeStatusDialog from './components/Modal/ConfirmChangeStatusDialog';
import { getCognitoUserStatus } from '../../utils/common';

const UserManagement = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;

  const { modal, countsRefeshList } = useSelector(state => ({
    modal: state.userManagement.modal,
    countsRefeshList: state.userManagement.modal.countsRefeshList
  }));

  useEffect(() => {
    dispatch(getCognitoUser());
  }, [dispatch, countsRefeshList]);

  const popupAction = useCallback(
    action => {
      if (action === 'open') {
        dispatch(
          processUserManagementModal({
            statusForm: FORM_STATUS.CREATE,
            title: 'Add external user',
            isVisible: true
          })
        );
      } else {
        dispatch(
          processUserManagementModal({
            isVisible: false
          })
        );
      }
    },
    [dispatch]
  );

  const onEditUserRole = useCallback(
    record => {
      if (!isEmpty(record)) {
        dispatch(
          processUserManagementModal({
            statusForm: FORM_STATUS.UPDATE,
            title: formatMessage({
              id: 'userManagement.modal.setRoleToUser',
              defaultMessage: 'SET ROLE TO USER'
            }),
            isVisible: true,
            modalData: {
              user: record
            }
          })
        );
      }
    },
    [dispatch]
  );

  const handleChangUserStatus = record => {
    const mappingOldStatusToNewStatus = {
      [STATUS_ENUM.ACTIVE]: STATUS_ENUM.IN_ACTIVE,
      [STATUS_ENUM.IN_ACTIVE]: STATUS_ENUM.ACTIVE
    };

    const status = getCognitoUserStatus(record.Enabled);

    if (!mappingOldStatusToNewStatus[status]) {
      throw new Error(`Invalid user status: ${status}`);
    }

    const data = {
      modifiedUserId: record.Username,
      status: mappingOldStatusToNewStatus[status]
    };

    dispatch(updateCognitoUser(data));
  };

  const onChangeUserStatus = (record, type) => {
    let configTitleTranslation = {
      id: 'userManagement.modal.deactivate.title',
      defaultMessage: 'DEACTIVATE USER'
    };

    if (type === ACTION_BUTTON_TYPES.ACTIVATE_USER) {
      configTitleTranslation = {
        id: 'userManagement.modal.activate.title',
        defaultMessage: 'ACTIVATE USER'
      };
    }

    const getConfigConfirmModal = () => ({
      intl,
      title: formatMessage(configTitleTranslation),
      record,
      type,
      actionHandler: handleChangUserStatus,
      content: <ConfirmChangeStatusDialog status={type} record={record} />,
      onCancel: () => popupAction('close')
    });

    // trigger confirm modal
    openConfirmModal(getConfigConfirmModal());
  };

  return (
    <>
      <div className="detergent-database-content">
        <div className="main-title">
          <FormattedMessage
            id="header.componentUserManagement"
            defaultMessage="User Management"
          />
        </div>
        <div className="filter-area">
          <UserSearchList />
          <UserManagementModal
            status={modal.statusForm}
            title={modal.title}
            isVisible={modal.isVisible}
            modalData={modal.modalData}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <UserList
        onEditUserRole={onEditUserRole}
        onChangeUserStatus={onChangeUserStatus}
      />
    </>
  );
};

export default UserManagement;
