import * as _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { OverlayLoading } from '../../../../../components/Loading';
import { EditPartNumber } from './EditPartNumber';
import {
  getPartNumberById,
  updatePartNumber
} from '../../../data/partNumbers/actions';
import { getDetergentNonPaging } from '../../../../../data/detergentNonPaging/action';
import { getDetergentProducerNonPaging } from '../../../../../data/detergentProducerNonPaging/action';
import { EditAnnualDemand } from './EditAnnualDemand';
import {
  getAnnualDemandYears,
  uniqId,
  getDensityByFormulaId,
  getFromCountry,
  changeCommaToPoint
} from '../../../../../utils/common';
import { ANNUAL_DEMAND_COLUMNS } from '../../../../../configs/constant';

// eslint-disable-next-line no-underscore-dangle
const _FormEditModal = props => {
  // Parent Form
  const dispatch = useDispatch();
  const {
    form,
    onCancel,
    modalData: { partNumberId }
  } = props;
  const {
    modalLoading,
    detergentNonPaging,
    detergentProducerNonPaging,
    partNumberDetailsData,
    isDetergentNonPagingFetching,
    isDetergentProducerNonPagingFetching,
    isPartNumberDetailsFetching,
    countries
  } = useSelector(state => ({
    modalLoading: state.partNumbers.modal.loading,
    isDetergentNonPagingFetching: state.detergentNonPaging.isFetching,
    detergentNonPaging: state.detergentNonPaging.data,
    isDetergentProducerNonPagingFetching:
      state.detergentProducerNonPaging.isFetching,
    detergentProducerNonPaging: state.detergentProducerNonPaging.data,
    partNumberDetailsData: state.partNumbers.partNumberDetailsData.data,
    isPartNumberDetailsFetching:
      state.partNumbers.partNumberDetailsData.isFetching,
    countries: state.countries.list
  }));
  const [annualDemands, setAnnualDemands] = useState([]);
  const yearList = getAnnualDemandYears();
  const [restrictedYears, setRestrictedYears] = useState([]);
  const [annualDemandCount, setAnnualDemandCount] = useState(
    annualDemands.length
  );
  const [formulasIds, setFormulasIds] = useState('');

  const [densityChanged, setDensityChanged] = useState('');
  useEffect(() => {
    setDensityChanged(partNumberDetailsData?.detergentFormula?.density);
    setFormulasIds(partNumberDetailsData?.detergentFormula?.id);
  }, [partNumberDetailsData]);

  useEffect(() => {
    // Set prop annualDemands to state
    if (Array.isArray(partNumberDetailsData.annualDemands)) {
      setAnnualDemands(
        partNumberDetailsData.annualDemands.sort((a, b) => a.year - b.year)
      );
    }
  }, [partNumberDetailsData.annualDemands]);

  useEffect(() => {
    // Check annualDemands to set restricted years
    if (Array.isArray(annualDemands)) {
      const selectedYears = annualDemands.map(
        annualDemand => annualDemand.year
      );

      setRestrictedYears(selectedYears);
    }

    setAnnualDemandCount(annualDemands.length);
  }, [annualDemands]);

  const isFetching =
    modalLoading ||
    isDetergentNonPagingFetching ||
    isDetergentProducerNonPagingFetching ||
    isPartNumberDetailsFetching;

  const update = ({ partNumber }) => {
    // Prepare detergent + formula data before updating
    const getVersion = _.get(
      partNumberDetailsData,
      'detergentFormula.version',
      ''
    );

    let detergentFormula = {
      id: null,
      version: null,
      status: null,
      containerSize: null,
      detergent: {
        id: partNumber?.detergentFormula?.detergent?.id,
        name: null,
        number: null
      }
    };
    if (getVersion !== partNumber?.detergentFormula?.id) {
      detergentFormula.id = partNumber?.detergentFormula?.id;
      setFormulasIds(partNumber?.detergentFormula?.id);
    } else {
      detergentFormula.id = _.get(
        partNumberDetailsData,
        'detergentFormula.id',
        ''
      );
      setFormulasIds(_.get(partNumberDetailsData, 'detergentFormula.id', ''));
    }
    const selectedDetergent = detergentNonPaging.find(detergent => {
      return detergent.id === partNumber?.detergentFormula?.detergent?.id;
    });

    if (selectedDetergent) {
      // Merge detergent fields
      const { name, number } = selectedDetergent;
      detergentFormula.detergent = {
        ...detergentFormula.detergent,
        name,
        number
      };

      // Merge formula fields
      if (selectedDetergent.formulas.length > 0) {
        const selectedFormula = selectedDetergent.formulas.find(
          formula => formula.id === formulasIds
        );

        if (selectedFormula) {
          const { version, status, containerSize } = selectedFormula;
          detergentFormula = {
            ...detergentFormula,
            version,
            status,
            containerSize
          };
        }
      }
    }

    // Prepare detergent producer
    const detergentProducer = {};
    if (partNumber?.detergentProducer?.id !== '') {
      const selectedDetergentProducer = detergentProducerNonPaging.find(
        ({ id }) => id === partNumber?.detergentProducer?.id
      );
      detergentProducer.id = partNumber?.detergentProducer?.id;
      detergentProducer.name = selectedDetergentProducer?.name;
    }

    _.set(
      partNumber,
      'fromCountry',
      getFromCountry(countries, partNumber.fromCountryId)
    );
    _.set(
      partNumber,
      'containerSize',
      changeCommaToPoint(partNumber.containerSize)
    );
    const data = {
      ...partNumber,
      id: partNumberDetailsData.id,
      annualDemands,
      detergentFormula,
      detergentProducer
    };

    dispatch(updatePartNumber(data));
  };

  useEffect(() => {
    if (partNumberId) {
      dispatch(getDetergentNonPaging());
      dispatch(getDetergentProducerNonPaging());
      dispatch(getPartNumberById({ partNumberId }));
    }
  }, []);

  const transformTableColumnValue = (columnKey, value) => {
    if (value !== '') {
      switch (columnKey) {
        case ANNUAL_DEMAND_COLUMNS.YEAR:
        case ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS:
          return parseFloat(value);

        default:
          return value;
      }
    } else {
      return '';
    }
  };

  /**
   * onChangeTableField()
   * eg. columnKey = 'numberOfContainers'
   */
  const onChangeTableField = useCallback(
    ({ value, columnKey, record }) => {
      const rowIndex = annualDemands.findIndex(annualDemand => {
        if (annualDemand.tempId) {
          return annualDemand.tempId === record.tempId;
        }

        return annualDemand.id === record.id;
      });
      const annualTonnageAfterChangeContainer = column => {
        if (column === ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS) {
          return (
            value *
            partNumberDetailsData.containerSize *
            partNumberDetailsData.detergentFormula.density
          );
        }
        return '';
      };
      const cloneAnnualDemands = JSON.parse(JSON.stringify(annualDemands));
      cloneAnnualDemands[rowIndex] = {
        ...cloneAnnualDemands[rowIndex],
        [columnKey]: transformTableColumnValue(columnKey, value),
        annualTonnage: annualTonnageAfterChangeContainer(columnKey)
      };
      setAnnualDemands(cloneAnnualDemands);
    },
    [annualDemands]
  );

  const handleAddNewAnnualDemand = () => {
    const newAnnualDemand = {
      [ANNUAL_DEMAND_COLUMNS.TEMP_ID]: uniqId(),
      [ANNUAL_DEMAND_COLUMNS.ANNUAL_TONNAGE]: '',
      [ANNUAL_DEMAND_COLUMNS.YEAR]: '',
      [ANNUAL_DEMAND_COLUMNS.NUMBER_OF_CONTAINERS]: ''
    };
    setAnnualDemandCount(annualDemandCount + 1);
    setAnnualDemands([...annualDemands, newAnnualDemand]);
  };

  const handleRemoveAnnualDemand = removedAnnualDemand => {
    const newAnnualDemands = annualDemands.filter(annualDemand =>
      removedAnnualDemand.id === undefined
        ? annualDemand?.tempId !== removedAnnualDemand?.tempId
        : annualDemand?.id !== removedAnnualDemand?.id
    );
    setAnnualDemands(newAnnualDemands);

    if (annualDemandCount > 0) {
      setAnnualDemandCount(annualDemandCount - 1);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        update(values);
      }
    });
  };

  const handleDensityChange = (formulas, formulaSelected) => {
    const selectedFormulaDensity = getDensityByFormulaId(
      formulas,
      formulaSelected
    );
    setDensityChanged(selectedFormulaDensity);
  };

  return (
    <OverlayLoading loading={isFetching}>
      <Form className="detergent-detail" onSubmit={handleSubmit}>
        <EditPartNumber
          form={form}
          handleDensityChange={handleDensityChange}
          partNumberDetailsData={partNumberDetailsData}
          detergentProducerNonPaging={detergentProducerNonPaging}
          detergentNonPaging={detergentNonPaging}
        />

        <Tabs className="tabs-partNumber-history" defaultActiveKey={1}>
          <Tabs.TabPane
            className="annual-demand-detail"
            tab={
              <FormattedMessage
                id="partNumbers.annualDemandTitle"
                defaultMessage="Annual demand"
              />
            }
            key="1">
            <EditAnnualDemand
              onChangeTableField={onChangeTableField}
              density={densityChanged}
              results={annualDemands}
              yearList={yearList}
              restrictedYears={restrictedYears}
              handleRemoveAnnualDemand={handleRemoveAnnualDemand}
            />
            {annualDemandCount < 7 ? (
              <Button
                type="link"
                className="add-more padding-zero"
                onClick={handleAddNewAnnualDemand}>
                <span>
                  <span className="add-more-plus">+</span>
                  <span>
                    <u>
                      <FormattedMessage
                        id="common.addMore"
                        defaultMessage="Add more"
                      />
                    </u>
                  </span>
                </span>
              </Button>
            ) : null}
          </Tabs.TabPane>
        </Tabs>

        <div className="form-btn-actions">
          <Button className="btn-cancel" onClick={onCancel}>
            <FormattedMessage
              id="common.cancelButton"
              defaultMessage="CANCEL"
            />
          </Button>
          <Button className="btn-save" type="primary" htmlType="submit">
            <FormattedMessage id="common.saveButton" defaultMessage="SAVE" />
          </Button>
        </div>
      </Form>
    </OverlayLoading>
  );
};

_FormEditModal.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  modalData: PropTypes.objectOf(PropTypes.object).isRequired
};

export const FormEditModal = Form.create({ name: 'form-edit-part-number' })(
  _FormEditModal
);
