export const GET_RAW_MATERIAL_GROUP = 'GET_RAW_MATERIAL_GROUP';
export const GET_RAW_MATERIAL_GROUP_SUCCESS = 'GET_RAW_MATERIAL_GROUP_SUCCESS';
export const GET_RAW_MATERIAL_GROUP_ERROR = 'GET_RAW_MATERIAL_GROUP_ERROR';

export const CREATE_RAW_MATERIAL_GROUP = 'CREATE_RAW_MATERIAL_GROUP';
export const CREATE_RAW_MATERIAL_GROUP_SUCCESS = 'CREATE_RAW_MATERIAL_GROUP_SUCCESS';
export const CREATE_RAW_MATERIAL_GROUP_ERROR = 'CREATE_RAW_MATERIAL_GROUP_ERROR';

export const UPDATE_RAW_MATERIAL_GROUP = 'UPDATE_RAW_MATERIAL_GROUP';
export const UPDATE_RAW_MATERIAL_GROUP_SUCCESS = 'UPDATE_RAW_MATERIAL_GROUP_SUCCESS';
export const UPDATE_RAW_MATERIAL_GROUP_ERROR = 'UPDATE_RAW_MATERIAL_GROUP_ERROR';

export const GROUP_MODAL = 'GROUP_MODAL';

export const GET_ALL_RAW_MATERIAL_GROUP = 'GET_ALL_RAW_MATERIAL_GROUP';
export const GET_ALL_RAW_MATERIAL_GROUP_SUCCESS = 'GET_ALL_RAW_MATERIAL_GROUP_SUCCESS';
export const GET_ALL_RAW_MATERIAL_GROUP_ERROR = 'GET_ALL_RAW_MATERIAL_GROUP_ERROR';

export const SET_RAW_MATERIAL_GROUP_FETCHING = 'SET_RAW_MATERIAL_GROUP_FETCHING';

export const CLEAR_RAW_MATERIAL_GROUP_DATA_WHEN_UNMOUNT = "CLEAR_RAW_MATERIAL_GROUP_DATA_WHEN_UNMOUNT";

//GET RAW MATERIAL GROUP
export const getRawMaterialGroup = (payload) => ({
  type: GET_RAW_MATERIAL_GROUP,
  payload
});

export const getRawMaterialGroupSuccess = (payload) => ({
  type: GET_RAW_MATERIAL_GROUP_SUCCESS,
  payload
});

export const getRawMaterialGroupError = (payload) => ({
  type: GET_RAW_MATERIAL_GROUP_ERROR,
  payload
});

//CREATE RAW MATERIAL GROUP
export const createRawMaterialGroup = (payload) => ({
  type: CREATE_RAW_MATERIAL_GROUP,
  payload
});

export const createRawMaterialGroupSuccess = (payload) => ({
  type: CREATE_RAW_MATERIAL_GROUP_SUCCESS,
  payload
});

export const createRawMaterialGroupError = (payload) => ({
  type: CREATE_RAW_MATERIAL_GROUP_ERROR,
  payload
});

//UPDATE RAW MATERIAL GROUP
export const updateRawMaterialGroup = (payload) => ({
  type: UPDATE_RAW_MATERIAL_GROUP,
  payload
});

export const updateRawMaterialGroupSuccess = (payload) => ({
  type: UPDATE_RAW_MATERIAL_GROUP_SUCCESS,
  payload
});

export const updateRawMaterialGroupError = (payload) => ({
  type: UPDATE_RAW_MATERIAL_GROUP_ERROR,
  payload
});

//Open Modal 
export const processGroupModal = (payload) => ({
  type: GROUP_MODAL,
  payload
})

//GET ALL RAW MATERIAL GROUP
export const getAllRawMaterialGroup = () => ({
  type: GET_ALL_RAW_MATERIAL_GROUP
});

export const getAllRawMaterialGroupSuccess = (payload) => ({
  type: GET_ALL_RAW_MATERIAL_GROUP_SUCCESS,
  payload
});

export const getAllRawMaterialGroupError = (payload) => ({
  type: GET_ALL_RAW_MATERIAL_GROUP_ERROR,
  payload
});

export const setRawMaterialGroupFetching = (payload) => ({
  type: SET_RAW_MATERIAL_GROUP_FETCHING,
  payload
});

export const clearRawMaterialGroupDataWhenUnmount = () => ({
  type: CLEAR_RAW_MATERIAL_GROUP_DATA_WHEN_UNMOUNT
});