/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import {
  setPrecalculationField,
  setContainerSize,
  setDisableDropdownAll
} from '../../containers/Detergent/data/detergent/actions';
import {
  setMeasurementDataField,
  setMeasurementDataNonGroup,
  setSelectAll
} from '../../containers/Detergent/data/detergent/actions';
import { changeCommaToPoint } from '../../utils/common';
const EditableColumnField = (props) => {
  const [dropdownAllDisplay, setDropdownAllDisplay] = useState(true);
  useEffect(() => {
    dispatch(setDisableDropdownAll({ display: dropdownAllDisplay }));
  }, [dropdownAllDisplay]);
  const dispatch = useDispatch();

  const {
    editable,
    readableField,
    editableField,
    cellKey,
    tabName,
    detergentId,
    formulaId,
    columnKey,
  } = props;

  if (!editable) {
    return readableField;
  }
  
  const handleViewDetergent = {
    measurement: (inputValue, selectValue, checkboxValue) => {
      const fieldValue =
      inputValue === null ? selectValue || checkboxValue : inputValue;
      const fieldValueSelect = checkboxValue;
      let fieldName = cellKey;
      if (fieldValueSelect) {
        setDropdownAllDisplay(false);
      } else {
        setDropdownAllDisplay(true);
      }

      if (cellKey === 'allPassFailed' && columnKey === 'passFail') {
        dispatch(
          setSelectAll({
            col: columnKey,
            val: fieldValue
          })
        );
      } else {
        if (prototypeCellKey[cellKey]) {
          prototypeCellKey[cellKey](fieldName, fieldValue);
        } else {
          let valFormula = changeCommaToPoint(fieldValue);
          if (
            columnKey === 'comment' ||
            columnKey === 'passFailed' ||
            cellKey === 'asfOenorm5106' ||
            cellKey === 'temperature'
          ) {
            valFormula = fieldValue;
          }
          dispatch(
            setMeasurementDataField({
              row: fieldName,
              col: columnKey,
              val: valFormula
            })
          );
        }
      }
    },
    precalculation: (inputValue, selectValue) => {
      let fieldValue = inputValue == null ? selectValue : inputValue;
      if (fieldValue === selectValue) {
        fieldValue = selectValue;
      } else {
        fieldValue = changeCommaToPoint(inputValue);
      }
      dispatch(
        setPrecalculationField({
          id: cellKey,
          fieldName: columnKey,
          fieldValue
        })
      );
    },
    'precalculation-containerSize': (inputValue) => {
      dispatch(
        setContainerSize({
          id: detergentId,
          formulaId,
          inputValue
        })
      );
    }
  };

  const prototypeCellKey = {
    labInformation: (fieldName, fieldValue) => {
      dispatch(
        setMeasurementDataNonGroup({
          row: fieldName,
          val: fieldValue
        })
      );
    },
    tabWaterLabel: (fieldName, fieldValue) => {
      dispatch(
        setMeasurementDataNonGroup({
          row: fieldName,
          val: parseFloat(fieldValue)
        })
      );
    }
  };

  const onChange = (e, value) => {
    let inputValue;
    if (typeof e === 'number') {
      inputValue = e;
    }
    else {
      inputValue = _.get(e, 'target.value', null);
    }
    const selectValue = _.get(value, 'props.value', null);
    const checkboxValue = _.get(e, 'target.checked', null);
    handleViewDetergent[tabName](inputValue, selectValue, checkboxValue);
  };
  return React.cloneElement(editableField, {
    onChange
  });
};
export default EditableColumnField;
