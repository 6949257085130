import React from 'react';
import { FormattedMessage } from 'react-intl';
import RenderUserRoleActions from './renderUserRoleActions';
import {
  STATUS_ENUM,
  MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID,
  USER_ROLE_LABELS,
  COGNITO_USER_ATTRIBUTES,
  USER_ROLES
} from '../../../../configs/constant';
import {
  getCognitoUserInfo,
  getCognitoUserStatus,
  getFullNameOfCognitoUser
} from '../../../../utils/common';

export const renderUserListColumns = ({
  onEditUserRole,
  onChangeUserStatus
}) => [
  {
    title: (
      <FormattedMessage id="common.columnUsername" defaultMessage="Username" />
    ),
    key: 'username',
    dataIndex: 'Username'
  },
  {
    title: (
      <FormattedMessage id="common.columnFullName" defaultMessage="Full Name" />
    ),
    key: 'fullname',
    render: record => {
      const fullname = getFullNameOfCognitoUser(record);
      return <span style={{ textTransform: 'capitalize' }}>{fullname}</span>;
    }
  },
  {
    title: <FormattedMessage id="contactPerson.email" defaultMessage="Email" />,
    key: 'email',
    render: record => {
      const emailString = getCognitoUserInfo(
        record,
        COGNITO_USER_ATTRIBUTES.email
      );
      return <span>{emailString}</span>;
    }
  },
  {
    title: (
      <FormattedMessage id="rawMaterial.columnStatus" defaultMessage="Status" />
    ),
    key: 'status',
    render: record => {
      const userStatus = getCognitoUserStatus(record.Enabled);
      if (!userStatus) return '';
      const statusClass =
        userStatus === STATUS_ENUM.ACTIVE
          ? 'user-status-active'
          : 'user-status-inactive';
      return (
        <span className={statusClass}>
          <FormattedMessage
            id={MAPPING_FORMULA_STATUS_TO_TRANSLATION_ID[userStatus]}
            defaultMessage={userStatus}
          />
        </span>
      );
    }
  },
  {
    title: <FormattedMessage id="common.columnRole" defaultMessage="Role" />,
    key: 'role',
    render: record => {
      const userRole =
        getCognitoUserInfo(record, COGNITO_USER_ATTRIBUTES.role) ||
        USER_ROLES.NA;

      const roleName = USER_ROLE_LABELS[userRole];
      return <span>{roleName}</span>;
    }
  },
  {
    title: '',
    render: record => {
      const userStatus = getCognitoUserStatus(record.Enabled);
      return (
        <RenderUserRoleActions
          record={record}
          userStatus={userStatus}
          onChangeUserStatus={onChangeUserStatus}
          onEditUserRole={onEditUserRole}
        />
      );
    }
  }
];
