import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Radio } from 'antd';
import PropTypes from 'prop-types';

import { OverlayLoading } from '../../../../../components/Loading';
import { updateCognitoUser } from '../../../data/userManagement/actions';
import {
  USER_ROLES_COLLECTION,
  COGNITO_USER_ATTRIBUTES
} from '../../../../../configs/constant';
import {
  getCognitoUserInfo,
  getFullNameOfCognitoUser
} from '../../../../../utils/common';

const EditUserRoleModal = props => {
  const {
    onCancel,
    modalData: { user },
    form: { getFieldDecorator, validateFields }
  } = props;

  const modifiedUserId = user.Username;
  const userRole = getCognitoUserInfo(user, COGNITO_USER_ATTRIBUTES.role);
  const userFullName = getFullNameOfCognitoUser(user);

  const { modalLoading } = useSelector(state => ({
    modalLoading: state.userManagement.modal.loading
  }));

  const intl = useIntl();
  const dispatch = useDispatch();

  const { formatMessage } = intl;

  const handleSubmit = event => {
    event.preventDefault();
    validateFields((err, values) => {
      if (err) return;
      const role = new Array(values?.roles);
      const submitValues = {
        modifiedUserId,
        role
      };

      dispatch(updateCognitoUser(submitValues));
    });
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <Form onSubmit={handleSubmit}>
        <Form.Item className="user-roles-checkbox-container">
          <div className="user-fullname">{userFullName}</div>
          {getFieldDecorator('roles', {
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'common.validateRoles',
                  defaultMessage: 'Role is required'
                })
              }
            ],
            initialValue: userRole
          })(
            <Radio.Group>
              {USER_ROLES_COLLECTION.map(role => (
                <Radio key={role.id} value={role.id}>
                  {role.label}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item>
          <div className="form-btn-actions button-wrapper">
            <Button className="btn-cancel" onClick={onCancel}>
              <FormattedMessage
                id="common.cancelButton"
                defaultMessage="CANCEL"
              />
            </Button>
            <Button htmlType="submit" className="btn-save">
              <div className="text">
                <FormattedMessage
                  id="common.saveButton"
                  defaultMessage="SAVE"
                />
              </div>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </OverlayLoading>
  );
};

EditUserRoleModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  modalData: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Form.create({ name: 'edit-user-role-form' })(EditUserRoleModal);
