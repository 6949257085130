import React, { useMemo } from 'react';
import { Col, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getS3FileUrl } from '../../utils/s3Service';
import { groupedDocumentsByCategory } from '../../containers/Detergent/components/Util/sortFormulaDocuments';
import { DISPLAY_FORMULA_DOCUMENTS_CATEGORIES } from '../../configs/constant';

const PreviewFilesDetail = ({ fileList }) => {
  const handleDownloadFile = async (e, key) => {
    e.preventDefault();
    const url = await getS3FileUrl(key);
    window.open(url.data.data);
  };

  const groupedDocuments = useMemo(() => groupedDocumentsByCategory(fileList), [
    fileList
  ]);

  const renderCycleIds = documentItem =>
    documentItem && documentItem.productionCycles?.length > 0
      ? documentItem.productionCycles.map(({ id }) => id).join(', ')
      : '';

  if (!Array.isArray(fileList)) return null;

  return Object.keys(groupedDocuments).map(key => (
    <section className="file-category-preview" key={key}>
      <div className="file-category-preview-title">
        {DISPLAY_FORMULA_DOCUMENTS_CATEGORIES[key]}
      </div>
      {groupedDocuments[key].map(documentItem => (
        <Row className="preview-files-info">
          <Col className="preview-files-info--item" span={10}>
            <Icon type="paper-clip" />
            <a
              style={{ color: '#2b2b2b' }}
              className="fileName"
              href="/"
              onClick={e => handleDownloadFile(e, documentItem.key)}
              target="blank"
              rel="noopener noreferrer">
              {documentItem.name}
            </a>
          </Col>
          <Col className="preview-files-info--item" span={14}>
            <FormattedMessage
              id="productionCycle.columnCycleID"
              defaultMessage="Cycle ID"
            />
            :
            <strong className="preview-files-info--cycle-ids">
              {renderCycleIds(documentItem)}
            </strong>
          </Col>
        </Row>
      ))}
    </section>
  ));
};

PreviewFilesDetail.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PreviewFilesDetail;
