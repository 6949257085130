import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'antd';
import { ViewDetergent } from '../ViewModal/ViewDetergent';
import { renderRawMaterialFomularColumns } from '../ViewModal/renderRawMaterialFomularColumns';
import { BaseTable } from '../../../../../components/BaseTable';

export const ViewCompareDetail = ({
  isLoading,
  form,
  detergentData,
  rawMaterialData
}) => {
  const intl = useIntl();
  const rawMaterials = rawMaterialData.map(ratio => {
    return {
      ...ratio,
      ...ratio.rawMaterial
    };
  });

  const setHeightForEachRowDetail = useCallback(rowDetails => {
    const firstDetergentViewDetailRow = rowDetails.length / 2;

    for (let i = 0; i < firstDetergentViewDetailRow; i += 1) {
      const height =
        rowDetails[i].offsetHeight >
        rowDetails[i + firstDetergentViewDetailRow].offsetHeight
          ? rowDetails[i].offsetHeight
          : rowDetails[i + firstDetergentViewDetailRow].offsetHeight;

      rowDetails[i].style.minHeight = `${height}px`;
      rowDetails[
        i + firstDetergentViewDetailRow
      ].style.minHeight = `${height}px`;
    }
  }, []);

  useEffect(() => {
    const rowDetailItems = document.querySelectorAll(
      '.compare-detergents--detail .row-detail'
    );

    if (!rowDetailItems.length) return;

    setHeightForEachRowDetail(rowDetailItems);
  }, []);

  return (
    <div className="compare-detergents--detail">
      <ViewDetergent
        detergent={detergentData}
        intl={intl}
        areEditingProductionCycles={false}
        isComparingDetergents
        form={form}
      />
      <div className="compare-detergents--raw-material">
        <div className="compare-detergents--tab">
          <span className="compare-detergents--tab-title">
            <FormattedMessage
              id="detergent.rawMaterialListTabTitle"
              defaultMessage="Raw Material"
            />
          </span>
        </div>
        <BaseTable
          loading={isLoading && { indicator: <Icon type="sync" spin /> }}
          className="compare-detergents--table"
          bordered
          columns={renderRawMaterialFomularColumns()}
          dataSource={rawMaterials}
        />
      </div>
    </div>
  );
};

ViewCompareDetail.propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.shape({}),
  detergentData: PropTypes.shape({}),
  rawMaterialData: PropTypes.arrayOf(PropTypes.shape({}))
};

ViewCompareDetail.defaultProps = {
  isLoading: false,
  form: {},
  detergentData: {},
  rawMaterialData: []
};
