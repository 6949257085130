import {
  PROCESS_USER_MANAGEMENT_MODAL,
  UPDATE_COGNITO_USER,
  UPDATE_COGNITO_USER_SUCCESS,
  UPDATE_COGNITO_USER_ERROR,
  GET_COGNITO_USER,
  GET_COGNITO_USER_ERROR,
  GET_COGNITO_USER_SUCCESS,
  GET_KAERCHER_USER,
  GET_KAERCHER_USER_ERROR,
  GET_KAERCHER_USER_SUCCESS,
  ADD_KAERCHER_USER,
  ADD_KAERCHER_USER_SUCCESS,
  ADD_KAERCHER_USER_ERROR
} from './actions';

const initialState = {
  list: {
    data: {
      results: []
    },
    fetching: false
  },
  kaercherUserList: {
    data: [],
    fetching: false,
    isAdding: false
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    countsRefeshList: 0,
    modalData: {}
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PROCESS_USER_MANAGEMENT_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false,
          modalData: action.payload.modalData || {}
        }
      };
    case UPDATE_COGNITO_USER:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        },
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_COGNITO_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        },
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1,
          loadLatestDataAfterCreateOrUpdate: true
        }
      };
    case UPDATE_COGNITO_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        },
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case GET_COGNITO_USER:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case GET_COGNITO_USER_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            results: action.payload.Users
          },
          fetching: false
        }
      };
    }
    case GET_COGNITO_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        }
      };
    case GET_KAERCHER_USER:
      return {
        ...state,
        kaercherUserList: {
          ...state.kaercherUserList,
          fetching: true
        }
      };
    case GET_KAERCHER_USER_SUCCESS:
      return {
        ...state,
        kaercherUserList: {
          ...state.kaercherUserList,
          fetching: false,
          data: action.payload.data
        }
      };
    case GET_KAERCHER_USER_ERROR:
      return {
        ...state,
        kaercherUserList: {
          ...state.kaercherUserList,
          fetching: false
        }
      };
    case ADD_KAERCHER_USER:
      return {
        ...state,
        kaercherUserList: {
          ...state.kaercherUserList,
          isAdding: true
        },
        list: {
          ...state.list,
          fetching: true
        }
      };
    case ADD_KAERCHER_USER_SUCCESS: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const payloads = action?.payload;
      const newDataList = [];
      payloads.forEach(data => {
        newDataList.push(data?.data?.User);
      });

      const currentList = cloneState?.list?.data?.results;
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          data: {
            ...state.list.data,
            results: [...currentList, ...newDataList]
          }
        },
        modal: {
          ...state.modal,
          countsRefeshList: state.modal.countsRefeshList + 1
        },
        kaercherUserList: {
          ...state.kaercherUserList,
          isAdding: false
        }
      };
    }
    case ADD_KAERCHER_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false
        },
        kaercherUserList: {
          ...state.kaercherUserList,
          isAdding: false
        }
      };
    default:
      return state;
  }
}
