import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { checkPermissionByRoles } from '../../utils/checkPermission';

export const ProtectedComponent = props => {
  const { allowRoles, children } = props;

  const childrenProps = {
    ...props
  };
  delete childrenProps.allowRoles;
  delete childrenProps.children;

  const isValidPermission = checkPermissionByRoles(allowRoles);

  if (!isValidPermission) {
    return null;
  }

  const childrenWithProps = Children.map(children, child =>
    cloneElement(child, childrenProps)
  );

  return childrenWithProps;
};

ProtectedComponent.propTypes = {
  allowRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ProtectedComponent.defaultProps = {
  children: null
};
