import { Button, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { OverlayLoading } from '../../../../components/Loading';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';
import { changePointToComma } from '../../../../utils/common';
import {
  getRawMaterialsById,
  exportDetergentByRawMaterialId
} from '../../data/rawMaterial/action';
import { DetergentList, HistoryList } from '../List';
import {
  renderRowFields,
  rowDetailSupplierAndPrice
} from './renderFieldsFunction';

export const RawMaterialDetail = ({ onEdit, onCancel }) => {
  const { formatMessage } = useIntl();
  const detergentListTitle = formatMessage({
    id: 'rawMaterial.detergentListTabTitle'
  });
  const historyTitle = formatMessage({
    id: 'rawMaterial.historyTabTitle',
    defaultMessage: 'History'
  });

  const dispatch = useDispatch();

  const {
    rawMaterialId,
    modalLoading,
    rawMaterial,
    isExportingDetergentList,
    detergentListByRawMaterial
  } = useSelector(state => ({
    rawMaterialId: state.rawMaterials.rawMaterialData.id,
    modalLoading: state.rawMaterials.modal.loading,
    rawMaterial: state.rawMaterials.rawMaterialData,
    isExportingDetergentList:
      state.rawMaterials.export.detergentListByRawMaterial.isExporting,
    detergentListByRawMaterial:
      state.rawMaterials.detergentListByRawMaterial.data.results
  }));

  useEffect(() => {
    dispatch(getRawMaterialsById({ id: rawMaterialId }));
  }, [dispatch, rawMaterialId]);
  const checkCountryId = rawMaterials => {
    if (Object.keys(rawMaterials).length !== 1) {
      if (rawMaterials.fromCountryId !== null) {
        return rawMaterials.fromCountry.name;
      }
      return '';
    }
    return '';
  };

  const isDetergentListByRawMaterialEmpty = useMemo(
    () => !detergentListByRawMaterial?.length,
    [detergentListByRawMaterial]
  );

  const onExportDetergentList = useCallback(() => {
    dispatch(exportDetergentByRawMaterialId(rawMaterialId));
  }, []);

  return (
    <OverlayLoading loading={modalLoading}>
      <div className="raw-material-detail">
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.name',
            defaultMessage: 'Trade name'
          }),
          rawMaterial.name || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'producer.country',
            defaultMessage: 'Country'
          }),
          checkCountryId(rawMaterial)
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.producer',
            defaultMessage: 'Producers'
          }),
          rawMaterial.producers || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.supplier',
            defaultMessage: 'Suppliers'
          }),
          rawMaterial.suppliers || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.rawMaterialGroup',
            defaultMessage: 'Raw material groups'
          }),
          rawMaterial.rawMaterialGroups || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.commodityGroup',
            defaultMessage: 'Commodity group'
          }),
          rawMaterial.commodityGroup || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.rawMaterialChemicalName',
            defaultMessage: 'Chemical name'
          }),
          rawMaterial.chemicalName || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.casNumber',
            defaultMessage: 'CAS number'
          }),
          rawMaterial.casNumber || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.einecNumber',
            defaultMessage: 'Einecs number'
          }),
          rawMaterial.einecsNumber || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.storageLife',
            defaultMessage: 'Storage life'
          }),
          rawMaterial.storageLife || ''
        )}
        <div className="supplier-name">
          {rowDetailSupplierAndPrice(
            formatMessage({
              id: 'rawMaterial.pricePerKiligram',
              defaultMessage: 'Price per kilogram'
            }),
            `${changePointToComma(rawMaterial.price) ||
              ''} ${rawMaterial.currency || ''}`
          )}
          {rowDetailSupplierAndPrice(
            formatMessage({
              id: 'rawMaterial.columnSupplier',
              defaultMessage: 'Supplier'
            }),
            rawMaterial.sapPrice?.supplier?.name || ''
          )}
        </div>

        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.documents',
            defaultMessage: 'Documents'
          }),
          rawMaterial.documents || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.rawMaterialPartNumber',
            defaultMessage: 'Part number'
          }),
          rawMaterial.partNumber || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.identicalSubstitutes',
            defaultMessage: 'Identical substitutes'
          }),
          rawMaterial.identicalSubstitutes || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.limitedSubstitutes',
            defaultMessage: 'Limited substitutes'
          }),
          rawMaterial.limitedSubstitutes || []
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.additional',
            defaultMessage: 'Additional Information'
          }),
          rawMaterial.additionalInformation || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.country',
            defaultMessage: 'Countries that allow the raw material'
          }),
          rawMaterial.retrictedCountries || ''
        )}
        {renderRowFields(
          formatMessage({
            id: 'rawMaterial.columnStatus',
            defaultMessage: 'Status'
          }),
          rawMaterial.status || ''
        )}

        <Tabs className="tabs-detergent-history" defaultActiveKey="1">
          <Tabs.TabPane
            className="detergent-list"
            tab={detergentListTitle}
            key="1">
            <div className="title">
              {formatMessage({
                id: 'rawMaterial.theDetergentsThatTheRawMaterialIsUsedFor',
                defaultMessage:
                  'The detergents that the raw material is used for:'
              })}{' '}
              <Button
                className="export-button"
                loading={isExportingDetergentList}
                disabled={isDetergentListByRawMaterialEmpty}
                onClick={onExportDetergentList}>
                <img
                  src="/icon/ic-print.svg"
                  alt=""
                  className="generate-file-icon"
                />
                <span>Export</span>
              </Button>
            </div>
            <DetergentList rawMaterialId={rawMaterialId} />
          </Tabs.TabPane>
          <Tabs.TabPane className="history-details" tab={historyTitle} key="2">
            <div className="title">
              {formatMessage({
                id: 'rawMaterial.allTheChangesThatHaveBeenMadeTo',
                defaultMessage:
                  'All the changes that have been made to the raw material'
              })}{' '}
            </div>
            <HistoryList rawMaterialId={rawMaterial.id} />
          </Tabs.TabPane>
        </Tabs>
        <div className="form-btn-actions">
          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIALS.EDIT}>
            <Button className="btn-cancel" onClick={() => onEdit()}>
              {formatMessage({ id: 'common.edit', defaultMessage: 'EDIT' })}
            </Button>
          </ProtectedComponent>
          <Button className="btn-save" onClick={onCancel}>
            {formatMessage({ id: 'common.close', defaultMessage: 'CLOSE' })}
          </Button>
        </div>
      </div>
    </OverlayLoading>
  );
};

RawMaterialDetail.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
