import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCountriesByRole } from '../utils/common';

// Custom hook watch role to return correct countries
const useCountriesByRole = () => {
  const {
    user: {
      profile: { 'custom:role': role }
    }
  } = useSelector(state => ({
    user: state.auth.user
  }));

  return useMemo(() => getCountriesByRole(role), [role]);
};

export default useCountriesByRole;
