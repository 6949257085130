import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { Input, Form, Row, Col, Icon, Select } from 'antd';
import { ProductionCyclesSelection } from './ProductionCyclesSelection';
import { UploadDocuments } from '../../../../../components/BaseUpload/UploadDocuments';
import {
  FORMULA_FORM,
  FORMULA_STATUS,
  TYPE_FORMULA_FILTER,
  UPLOAD_TYPES,
  FORMULA_ENUM_FORM,
  COGNITO_USER_ATTRIBUTES,
  PROJECT_TYPES,
  STATUS_ENUM,
  DISPLAY_FORMULA_STATUS,
  BLANK_OPTION
} from '../../../../../configs/constant';
import { validateDocumentCategories } from '../../../../../utils/validator';
import { getCognitoUser } from '../../../../UserManagement/data/userManagement/actions';
import {
  getFullNameFromEmail,
  getFullNameOfCognitoUser,
  changeCommaToPoint
} from '../../../../../utils/common';
import FilterableSelect from '../../../../../components/FilterableSelect';
import userManager from '../../../../../utils/auth/userManager';
import countries from '../../../../../configs/country.json';
import countryUSA from '../../../../../configs/countryUSA.json';
import { getCognitoInfo } from '../../../../../utils/auth/cognito';

const EditDetergent = props => {
  const dispatch = useDispatch();

  const {
    form: { getFieldDecorator, getFieldValue }
  } = props;
  const { formatMessage, formatDate } = useIntl();

  const dateFormation = date => (date ? formatDate(date) : null);

  const { detergent, userList } = useSelector(state => ({
    detergent: JSON.parse(JSON.stringify(state.detergent.emptyDetergent)),
    userList: state.userManagement.list.data.results
  }));

  const [approvalName, setApprovalName] = useState(
    getFullNameFromEmail(detergent?.formulas?.[0]?.approverEmail)
  );
  const [nameEmail, setNameEmail] = useState('');
  useEffect(() => {
    dispatch(getCognitoUser());
    handleGetNameEmail();
  }, []);
  const { roles } = getCognitoInfo();
  const role = roles.join();
  const countrySelect = () => {
    switch (role) {
      case 'kna':
        // eslint-disable-next-line no-return-assign
        return countryUSA;
      default:
        // eslint-disable-next-line no-return-assign
        return countries;
    }
  };
  const handleGetNameEmail = async () => {
    const user = await userManager.getUser();

    return setNameEmail(getFullNameFromEmail(user.profile.email));
  };

  const firstDetergentFormulaInstance = 'formulas[0]';
  const currentFormulasStatus = _.get(detergent, 'formulas.0.status', '');

  const restrictedStatusList = FORMULA_STATUS.filter(status => {
    return status.value !== currentFormulasStatus;
  });
  const getDensityLabel = formulaFormValue => {
    if (
      formulaFormValue === FORMULA_ENUM_FORM.POWDER ||
      formulaFormValue === FORMULA_ENUM_FORM.TABS
    ) {
      return (
        <FormattedMessage
          id="formula.bulkDensity"
          defaultMessage="Bulk density"
        />
      );
    }

    return <FormattedMessage id="formula.density" defaultMessage="Density" />;
  };
  const [approvalDate, setApprovalDate] = useState(
    dateFormation(detergent?.formulas?.[0]?.approvedDate)
  );
  const getApprovalDate = status => {
    if (status === STATUS_ENUM.APPROVED) {
      setApprovalDate(dateFormation(Date().toString()));
      setApprovalName(nameEmail);
    } else {
      setApprovalDate(dateFormation(detergent?.formulas?.[0]?.approvedDate));
      setApprovalName(
        getFullNameFromEmail(detergent?.formulas?.[0]?.approverEmail)
      );
    }
  };

  return (
    <div className="detergent-detail">
      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="detergent.number"
              defaultMessage="Detergent number"
            />
          </div>
          <div className="value">
            {getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateDetergentNumber',
                    defaultMessage: 'Detergent number is required'
                  })
                }
              ],
              initialValue: detergent.number
            })(<Input />)}
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="detergent.name"
              defaultMessage="Detergent name"
            />
          </div>
          <div className="value">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: formatMessage({
                    id: 'common.validateDetergentName',
                    defaultMessage: 'Detergent name is required'
                  })
                }
              ],
              initialValue: detergent.name
            })(<Input />)}
          </div>
        </div>
      </Form.Item>
      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage id="producer.country" defaultMessage="Country" />
          </div>
          <div className="value" id="parent-node">
            {getFieldDecorator('fromCountryId', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="error.country"
                      defaultMessage="Country is required!"
                    />
                  )
                }
              ],
              initialValue: detergent.fromCountryId
            })(
              <FilterableSelect
                getPopupContainer={() => document.getElementById('parent-node')}
                placeholder={
                  <FormattedMessage
                    id="rawMaterial.selectCountries"
                    defaultMessage="Select countries"
                  />
                }
                suffixIcon={<Icon type="caret-down" />}>
                {countrySelect().map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </FilterableSelect>
            )}
          </div>
        </div>
      </Form.Item>
      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage id="formula.type" defaultMessage="Formula type" />
          </div>
          <div className="value">
            <div id="parent-node-1">
              {getFieldDecorator(`${firstDetergentFormulaInstance}.sample`, {
                rules: [
                  {
                    required: true,
                    message: formatMessage({
                      id: 'common.validateSample',
                      defaultMessage: 'Sample type is required'
                    })
                  }
                ],
                initialValue: _.get(detergent, 'formulas.0.sample')
              })(
                <Select
                  placeholder={formatMessage({
                    id: 'detergent.selectAtype',
                    defaultMessage: 'Select a sample type'
                  })}
                  getPopupContainer={() =>
                    document.getElementById('parent-node-1')
                  }
                  suffixIcon={<Icon type="caret-down" />}>
                  {TYPE_FORMULA_FILTER.map(item => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </div>
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage id="formula.form" defaultMessage="Form" />
          </div>
          <div className="value">
            <div id="parent-node-1">
              {getFieldDecorator(`${firstDetergentFormulaInstance}.form`, {
                rules: [
                  {
                    required: true,
                    message: formatMessage({
                      id: 'common.validateForm',
                      defaultMessage: 'Form is required'
                    })
                  }
                ],
                initialValue: _.get(detergent, 'formulas.0.form')
                  ? _.get(detergent, 'formulas.0.form')
                  : undefined
              })(
                <Select
                  placeholder={formatMessage({
                    id: 'detergent.selectAForm',
                    defaultMessage: 'Select a form'
                  })}
                  getPopupContainer={() =>
                    document.getElementById('parent-node-1')
                  }
                  suffixIcon={<Icon type="caret-down" />}>
                  {FORMULA_FORM.map(item => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </div>
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            {getDensityLabel(
              getFieldValue(`${firstDetergentFormulaInstance}.form`)
            )}
          </div>
          <div className="value">
            {getFieldDecorator(`${firstDetergentFormulaInstance}.density`, {
              rules: [
                {
                  validator: (rule, val, callback) => {
                    const num = changeCommaToPoint(val);
                    if (val !== '' && val !== null && Number.isNaN(num)) {
                      callback(new Error());
                    }
                    callback();
                  },
                  message: formatMessage({
                    id: 'common.validateDensity',
                    defaultMessage: 'You should input number'
                  })
                }
              ],
              initialValue: _.get(detergent, 'formulas.0.density')
            })(<Input />)}
          </div>
        </div>
      </Form.Item>

      <Form.Item className="upload-detergent">
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="detergent.upload"
              defaultMessage="Upload documents"
            />
          </div>
          <div className="value">
            {getFieldDecorator(`${firstDetergentFormulaInstance}.documents`, {
              valuePropName: 'fileList',
              initialValue: _.get(detergent, 'formulas.0.documents'),
              rules: [
                {
                  validator: validateDocumentCategories(formatMessage)
                }
              ]
            })(
              <UploadDocuments
                type={UPLOAD_TYPES.DETERGENT}
                expandSelection={(fileItem, onChangeDocumentProperty) => (
                  <ProductionCyclesSelection
                    fileItem={fileItem}
                    onChangeDocumentProperty={onChangeDocumentProperty}
                  />
                )}
              />
            )}
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="formula.instructions"
              defaultMessage="Production instructions"
            />
          </div>
          <div className="value">
            {getFieldDecorator(
              `${firstDetergentFormulaInstance}.productionInstruction`,
              {
                initialValue: _.get(
                  detergent,
                  'formulas.0.productionInstruction'
                )
              }
            )(<Input.TextArea rows={7} />)}
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="formula.properties"
              defaultMessage="Formula's properties"
            />
          </div>
          <div className="value">
            {getFieldDecorator(
              `${firstDetergentFormulaInstance}.propertyDescription`,
              {
                initialValue: _.get(detergent, 'formulas.0.propertyDescription')
              }
            )(<Input.TextArea rows={7} />)}
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="formula.note"
              defaultMessage="Note on formula changes"
            />
          </div>
          <div className="value">
            {getFieldDecorator(
              `${firstDetergentFormulaInstance}.noteOnFormulaChanges`,
              {
                initialValue: _.get(
                  detergent,
                  'formulas.0.noteOnFormulaChanges'
                )
              }
            )(<Input.TextArea rows={7} />)}
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="formula.status"
              defaultMessage="Formula Status"
            />
          </div>
          <div className="value" style={{ width: '15%' }}>
            <div id="parent-node-1">
              {getFieldDecorator(`${firstDetergentFormulaInstance}.status`, {
                initialValue:
                  DISPLAY_FORMULA_STATUS[_.get(detergent, 'formulas.0.status')]
              })(
                <Select
                  getPopupContainer={() =>
                    document.getElementById('parent-node-1')
                  }
                  onChange={value => getApprovalDate(value)}
                  value={_.get(detergent, 'formulas.0.status')}
                  suffixIcon={<Icon type="caret-down" />}>
                  {restrictedStatusList.map(status => {
                    return (
                      <Select.Option key={status.value} value={status.value}>
                        {status.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </div>
          </div>
        </div>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col>
            <div className="row-detail">
              <div className="edit-columns-label">
                <FormattedMessage
                  id="productionCycle.columnCreateDate"
                  defaultMessage="Creation date"
                />
              </div>
              <div className="edit-columns-value">
                {dateFormation(detergent?.formulas?.[0]?.createAt)}
              </div>
              <div className="edit-columns-label">
                <FormattedMessage
                  id="detergent.label.approvalDate"
                  defaultMessage="Approval date"
                />
              </div>
              <div className="edit-columns-value">{approvalDate}</div>
            </div>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col>
            <div className="row-detail">
              <div className="edit-columns-label">
                <FormattedMessage
                  id="formula.creator"
                  defaultMessage="Formula creator"
                />
              </div>
              <div className="edit-columns-value">
                <span style={{ textTransform: 'capitalize' }}>
                  {getFullNameFromEmail(detergent?.formulas?.[0]?.creatorEmail)}
                </span>
              </div>
              <div className="edit-columns-label">
                <FormattedMessage
                  id="detergent.label.formulaApprover"
                  defaultMessage="Formula approver"
                />
              </div>
              <div className="edit-columns-value">
                <span style={{ textTransform: 'capitalize' }}>
                  {approvalName}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <div className="row-detail">
          <div className="label">
            <FormattedMessage
              id="detergent.label.formulaDeveloper"
              defaultMessage="Formula developer"
            />
          </div>
          <div className="value" style={{ width: '15%' }}>
            <div id="formula-developer-parent-node">
              {getFieldDecorator(
                `${firstDetergentFormulaInstance}.developerEmail`,
                {
                  initialValue:
                    getFullNameFromEmail(
                      detergent?.formulas?.[0].developerEmail
                    ) || undefined
                }
              )(
                <Select
                  getPopupContainer={() =>
                    document.getElementById('formula-developer-parent-node')
                  }
                  value={_.get(detergent, 'formulas.0.developerEmail')}
                  suffixIcon={<Icon type="caret-down" />}>
                  {userList
                    .map(user => {
                      return (
                        <Select.Option
                          key={user.Username}
                          value={getFullNameOfCognitoUser(
                            user,
                            COGNITO_USER_ATTRIBUTES.email
                          )}>
                          <span style={{ textTransform: 'capitalize' }}>
                            {getFullNameOfCognitoUser(user)}
                          </span>
                        </Select.Option>
                      );
                    })
                    .concat(
                      <Select.Option
                        key={BLANK_OPTION.id}
                        value={BLANK_OPTION.value}>
                        {BLANK_OPTION.name}
                      </Select.Option>
                    )}
                </Select>
              )}
            </div>
          </div>
        </div>
      </Form.Item>
      <Form.Item>
        <Row>
          <Col>
            <div className="row-detail">
              <div className="edit-columns-label">
                <FormattedMessage
                  id="formula.projectType"
                  defaultMessage="Project type"
                />
              </div>
              <div className="edit-columns-value">
                <div id="project-type-parent-node" style={{ width: '60%' }}>
                  {getFieldDecorator(
                    `${firstDetergentFormulaInstance}.projectType`,
                    {
                      initialValue:
                        detergent?.formulas?.[0].projectType || undefined
                    }
                  )(
                    <Select
                      getPopupContainer={() =>
                        document.getElementById('project-type-parent-node')
                      }
                      value={detergent?.formulas?.[0].projectType}
                      suffixIcon={<Icon type="caret-down" />}>
                      {PROJECT_TYPES.map(projectType => (
                        <Select.Option
                          key={projectType.id}
                          value={projectType.value}>
                          {projectType.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
              <div className="edit-columns-label" style={{ width: '30% ' }}>
                <FormattedMessage
                  id="formula.projectNumber"
                  defaultMessage="Project number"
                />
              </div>
              <div className="edit-columns-value" style={{ width: '70% ' }}>
                {getFieldDecorator(
                  `${firstDetergentFormulaInstance}.projectNumber`,
                  {
                    initialValue: detergent?.formulas?.[0]?.projectNumber
                  }
                )(<Input style={{ width: '50% ' }} />)}
              </div>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

EditDetergent.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired
};

export default EditDetergent;
