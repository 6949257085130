import React from 'react';
import * as _ from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { translateHistoryFieldTextByKeyName } from '../../../../utils/common';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../configs/constant';
import { formatDateTimeByReactIntl } from '../../../../utils/reactIntlHelpers';
import { transformHistoryValue } from '../../../Logs/Util/transformHistoryValue';

export const renderDetergentHistoryColumns = intl => {
  return [
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnDatetime"
          defaultMessage="Datetime"
        />
      ),
      key: 'createAt',
      width: '10%',
      render: record => {
        return <div>{formatDateTimeByReactIntl(intl, record.createAt)}</div>;
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnAction"
          defaultMessage="Action"
        />
      ),
      key: 'action',
      width: '10%',
      render: record => {
        return (
          <span className="text-black">
            <FormattedHTMLMessage
              id={
                record.isAddedNewRecord
                  ? 'common.addedNewOne'
                  : 'common.madeChanges'
              }
              values={{
                user: record.creatorEmail
              }}
            />
          </span>
        );
      }
    },
    {
      title: (
        <FormattedMessage id="rawMaterial.columnField" defaultMessage="Field" />
      ),
      key: 'field',
      width: '30%',
      ellipsis: true,
      render: record => {
        if (record.isAddedNewRecord) return '';

        const fieldNames = _.map(record.valueJson, 'key');

        return (
          <div className="div-changes">
            {fieldNames.map(fieldName => (
              <div>
                -{' '}
                {translateHistoryFieldTextByKeyName(
                  intl,
                  fieldName,
                  MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID
                )}
              </div>
            ))}
          </div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnOriginValue"
          defaultMessage="Original Value"
        />
      ),
      key: 'oldValue',
      width: '25%',
      ellipsis: true,
      render: record => {
        if (record.isAddedNewRecord) return '';

        return (
          <div className="div-changes">
            {record.valueJson.map(({ oldValue, key }) => (
              <div>- {transformHistoryValue(key, oldValue, intl)}</div>
            ))}
          </div>
        );
      }
    },
    {
      title: (
        <FormattedMessage
          id="rawMaterial.columnNewValue"
          defaultMessage="New Value"
        />
      ),
      key: 'newValue',
      width: '25%',
      ellipsis: true,
      render: record => {
        if (record.isAddedNewRecord) return '';

        return (
          <div className="div-changes">
            {record.valueJson.map(({ newValue, key }) => (
              <div>- {transformHistoryValue(key, newValue, intl)}</div>
            ))}
          </div>
        );
      }
    }
  ];
};
