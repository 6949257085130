import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LogsList from './components/List';
import { getLogs, clearLogsDataWhenUnmount } from './data/logs/actions';

export const Logs = () => {
  const dispatch = useDispatch();
  const { listData } = useSelector(state => ({
    listData: state.logs.list.data
  }));

  useEffect(() => {
    dispatchGetLogsList();

    return () => {
      dispatch(clearLogsDataWhenUnmount());
    };
  }, []);

  function dispatchGetLogsList(pagination = listData.pagination) {
    dispatch(
      getLogs({
        pagination
      })
    );
  }

  function onPageSizeChange(value) {
    const pagination = {
      ...listData.pagination,
      current: 1,
      pageSize: value
    };

    dispatchGetLogsList(pagination);
  }

  function onPageChange(current) {
    const pagination = {
      ...listData.pagination,
      current: current + 1
    };

    dispatchGetLogsList(pagination);
  }

  return (
    <LogsList
      onPageSizeChange={value => onPageSizeChange(value)}
      onPageChange={current => onPageChange(current)}
    />
  );
};
