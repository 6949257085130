import React from 'react';
import { Select } from 'antd';
import PropType from 'prop-types';
import { isEqual } from '../../utils/stringCommon';

const FilterableSelect = React.forwardRef(({ children, ...props }, ref) => (
  <Select
    ref={ref}
    getPopupContainer={triggerNode => triggerNode}
    showSearch
    filterOption={(input, option) => isEqual(input, option.props.children)}
    {...props}>
    {children}
  </Select>
));

FilterableSelect.propTypes = {
  children: PropType.node.isRequired
};

export default FilterableSelect;
