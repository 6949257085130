import * as _ from 'lodash';
import React from 'react';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  ACTION_BUTTON_TYPES,
  MAPPING_RAW_MATERIAL_GROUP_TO_CLASS_NAME,
  MAPPING_RAW_MATERIAL_GROUP_TO_TEXT
} from '../../../../configs/constant';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';

export const groupColumns = [
  {
    title: (
      <FormattedMessage id="rawMaterialGroup.name" defaultMessage="Name" />
    ),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterialGroup.description"
        defaultMessage="Description"
      />
    ),
    dataIndex: 'description',
    key: 'description',
    width: '45%'
  },
  {
    title: (
      <FormattedMessage id="rawMaterialGroup.status" defaultMessage="Status" />
    ),
    key: 'status',
    width: '195px',
    render: record => {
      const rawMaterialStatus = record?.status;

      return (
        <span
          className={
            MAPPING_RAW_MATERIAL_GROUP_TO_CLASS_NAME[rawMaterialStatus]
          }>
          <FormattedMessage
            id={MAPPING_RAW_MATERIAL_GROUP_TO_TEXT[rawMaterialStatus]}
            defaultMessage={rawMaterialStatus}
          />
        </span>
      );
    }
  },
  {
    title: '',
    key: 'action',
    width: '100px',
    render: record => {
      if (!_.isEmpty(record)) {
        return (
          <div className="action-col">
            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIAL_GROUPS.DEACTIVATE}>
              <div
                className={`${ACTION_BUTTON_TYPES.DELETE_RAW_MATERIAL_GROUP} action-item`}>
                <Icon type="delete" />
              </div>
            </ProtectedComponent>

            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.RAW_MATERIAL_GROUPS.EDIT}>
              <div
                className={`${ACTION_BUTTON_TYPES.EDIT_RAW_MATERIAL_GROUP} action-item edit-rawMaterialGroup`}>
                <Icon type="edit" />
              </div>
            </ProtectedComponent>
          </div>
        );
      }
    }
  }
];
