import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useReducer
} from 'react';
import PropTypes from 'prop-types';
import GeneratedFileCheckBoxes from './GeneratedFileCheckBoxes';
import {
  getGeneratingFormulaFileSelectorModalInitialState,
  getGeneratingFormulaExternalFileSelectorModalInitialState,
  getGeneratingInitialProductionTestProtocolFileSelectorModalInitialState,
  getGeneratingProductionTestProtocolFileSelectorModalInitialState,
  getGeneratingMeasurementDataBlankedFileSelectorModalInitialState,
  getGeneratingMeasurementDataFilledOutFileSelectorModalInitialState,
} from '../GeneratingFileSelectorModalInitialState';
import '../GeneratingFileSelectorModal.scss';
import FileSelectorCheckBox from '../FileSelectorCheckBox';
import {
  reducer,
  ON_CLEAR,
  ON_PDF_GENERATED_COMPLETE,
  getInitialStates
} from './generatedFileCheckBoxesReducer';
import {
  formula,
  formulaExternal,
  initialProductionTestProtocol,
  productionTestProtocol,
  measurementDataBlanked,
  measurementDataFilledOut,
} from './constant';
import { useSelector } from 'react-redux';
import messageEn from '../../../../../../Translations/en.json';
import messageDe from '../../../../../../Translations/de.json';

const GeneratedFileCheckBoxesContainer = ({
  isOkClicked,
  isCancel,
  onHandleFileComplete
}) => {
  // a state to select a set of check boxes related to procument department
  const [
    isDocumentsForProcurementCheckBoxSelected,
    setDocumentsForProcurementCheckSelected
  ] = useState(false);
  const [localState, localDispatch] = useReducer(reducer, getInitialStates());
  const { rendereredCompletePdfs, isHandleRenderedPDFComplete } = localState;

  const messages = {
    de: messageDe,
    en: messageEn
  };

  const { languageCode } = useSelector(state => {
    return {
      languageCode: state.language.languageCode
    };
  });

  // used to set initial state for checkboxes
  const initialFormulaCheckBoxes = useMemo(
    () => getGeneratingFormulaFileSelectorModalInitialState(React),
    [isCancel]
  );
  const initialFormulaExternalCheckBoxes = useMemo(
    () => getGeneratingFormulaExternalFileSelectorModalInitialState(React),
    [isCancel]
  );
  const initialInitialProductionTestProtocolCheckBoxes = useMemo(
    () => getGeneratingInitialProductionTestProtocolFileSelectorModalInitialState(React),
    [isCancel]
  );
  const initialProductionTestProtocolCheckBoxes = useMemo(
    () => getGeneratingProductionTestProtocolFileSelectorModalInitialState(React),
    [isCancel]
  );
  const initialMeasurementDataBlankedCheckBoxes = useMemo(
    () => getGeneratingMeasurementDataBlankedFileSelectorModalInitialState(React),
    [isCancel]
  );
  const initialMeasurementDataFilledOutCheckBoxes = useMemo(
    () => getGeneratingMeasurementDataFilledOutFileSelectorModalInitialState(React),
    [isCancel]
  );

  const onPDFRendered = useCallback(({ pdfRenderer, fileName }, fileType, prefixName) => {
    localDispatch({
      type: ON_PDF_GENERATED_COMPLETE,
      payload: {
        fileType,
        rendereredCompletePdf: {
          pdfRenderer,
          fileName: `${prefixName}-${fileName}`
        }
      }
    });
  }, []);

  useEffect(() => {
    if (isCancel) {
      localDispatch({
        type: ON_CLEAR
      });
      setDocumentsForProcurementCheckSelected(false);
    }
  }, [isCancel]);

  useEffect(() => {
    if (isHandleRenderedPDFComplete) {
      onHandleFileComplete(rendereredCompletePdfs);
    }
  }, [isHandleRenderedPDFComplete]);

  return (
    <>
      <FileSelectorCheckBox
        defaultMessage="Documents for procurement"
        formattedMessageId="common.documentsForProcurement"
        checked={isDocumentsForProcurementCheckBoxSelected}
        disabled={isOkClicked}
        onChange={() => {
          setDocumentsForProcurementCheckSelected(
            !isDocumentsForProcurementCheckBoxSelected
          );
        }}
      />

      {/* Checkboxes for initialFormulaCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialFormulaCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
          onPDFRendered({ pdfRenderer, fileName }, formula, messages[languageCode]["detergent.document.formula"]);
        }}
      />
      {/* Checkboxes for initialFormulaExternalCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialFormulaExternalCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
          onPDFRendered({ pdfRenderer, fileName }, formulaExternal, messages[languageCode]["detergent.document.formulaExternal"]);
        }}
      />
      {/* Checkboxes for initialInitialProductionTestProtocolCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialInitialProductionTestProtocolCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
         onPDFRendered({ pdfRenderer, fileName }, initialProductionTestProtocol, messages[languageCode]["detergent.document.initialProductionTestProtocol"]);
        }}
      />
      {/* Checkboxes for initialProductionTestProtocolCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialProductionTestProtocolCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
         onPDFRendered({ pdfRenderer, fileName }, productionTestProtocol, messages[languageCode]["detergent.document.productionTestProtocol"]);
        }}
      />
      {/* Checkboxes for initialMeasurementDataBlankedCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialMeasurementDataBlankedCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
          onPDFRendered({ pdfRenderer, fileName }, measurementDataBlanked, messages[languageCode]["detergent.document.blankMeasurementData"]);
        }}
      />
      {/* Checkboxes for initialMeasurementDataFilledOutCheckBoxes files */}
      <GeneratedFileCheckBoxes
        isOkClicked={isOkClicked}
        isCancel={isCancel}
        isDocumentsForProcurementCheckBoxSelected={
          isDocumentsForProcurementCheckBoxSelected
        }
        initialPdfCheckBoxes={initialMeasurementDataFilledOutCheckBoxes}
        onPDFRendered={({ pdfRenderer, fileName }) => {
          onPDFRendered({ pdfRenderer, fileName }, measurementDataFilledOut, messages[languageCode]["detergent.document.filledMeasurementData"]);
        }}
      />
    </>
  );
};

GeneratedFileCheckBoxesContainer.propTypes = {
  isOkClicked: PropTypes.bool.isRequired,
  isCancel: PropTypes.bool.isRequired,
  onHandleFileComplete: PropTypes.func.isRequired
};

export default GeneratedFileCheckBoxesContainer;
