import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ProducerContactModal } from './components/Modal';
import Filter from '../../components/Filter';
import {
  BASE_DB_FIELDS,
  FORM_STATUS,
  STATUS_ENUM
} from '../../configs/constant';
import { List } from './components/List';
import {
  getDetergentProducers,
  processProducerModal,
  updateDetergentProducers,
  clearDetergentProducerDataWhenUnmount,
  setLoadingDetergentProducer
} from './data/detergentProducers/actions';
import { openDeactiveProducerConfirmModal } from '../../utils/dialogs';
import { FilterForm } from './components/Form/filterForm';
import { ConfirmDeactiveDialog } from './components/ConfirmDeactiveDialog';
import { getPartNumberForDetergentProducerApi } from './data/detergentProducers/api';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';
import { getCountries } from '../../data/countries/action';

const DetergentsProducer = ({ intl }) => {
  const [isUpdatingProducerStatus, setIsUpdatingProducerStatus] = useState(
    false
  );
  const dispatch = useDispatch();
  const {
    pagination,
    detergentProducers,
    searchByText,
    order,
    modalLoadLatestDataAfterCreateOrUpdate,
    filterList,
    confirmModalVisibility,
    countsRefeshList
  } = useSelector(state => ({
    pagination: state.detergentProducers.list.data.pagination,
    detergentProducers: JSON.parse(JSON.stringify(state.detergentProducers)),
    searchByText: state.detergentProducers.searchByText,
    order: state.detergentProducers.order,
    modalLoadLatestDataAfterCreateOrUpdate:
      state.detergentProducers.modal.loadLatestDataAfterCreateOrUpdate,
    filterList: state.detergentProducers.filterList,
    confirmModalVisibility:
      state.detergentProducers.modal.confirmModalVisibility,
    countsRefeshList: state.detergentProducers.modal.countsRefeshList
  }));

  useEffect(() => {
    if (!confirmModalVisibility) {
      let newOrder = { ...order };
      const newPagination = { ...pagination };

      if (modalLoadLatestDataAfterCreateOrUpdate) {
        newOrder = {
          [BASE_DB_FIELDS.UPDATE_AT]: 'DESC'
        };
        newPagination.current = 1;
      }
      dispatch(
        getDetergentProducers({
          pagination: newPagination,
          searchByText,
          order: newOrder,
          filterList,
          modalLoadLatestDataAfterCreateOrUpdate
        })
      );
    }

    if (!confirmModalVisibility) {
      setIsUpdatingProducerStatus(false);
    }
  }, [countsRefeshList, confirmModalVisibility]);

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearDetergentProducerDataWhenUnmount());
    };
  }, []);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    dispatch(
      getDetergentProducers({ pagination, searchByText, filterList, order })
    );
  };

  const onPageChange = current => {
    pagination.current = ++current;
    dispatch(
      getDetergentProducers({ pagination, searchByText, filterList, order })
    );
  };

  const onEditProducer = record => {
    if (!isEmpty(record)) {
      dispatch(
        processProducerModal({
          producer: record,
          statusForm: FORM_STATUS.UPDATE,
          title: intl.formatMessage({
            id: 'detergentProducer.modal.edit.title',
            defaultMessage: 'EDIT DETERGENT PRODUCER'
          }),
          isVisible: true
        })
      );
    }
  };

  const deactiveProducerHandler = async (record, type) => {
    const data = {
      ...record,
      status: type
    };
    dispatch(
      updateDetergentProducers({
        id: record.id,
        producer: data
      })
    );
    return true;
  };
  const checkProducerRelatedToRawMaterial = async producerId => {
    const detergentProducer = await getPartNumberForDetergentProducerApi(
      producerId
    );
    const countProducers = detergentProducer.data.data.results.length;
    if (countProducers > 0) {
      return true;
    }
    return false;
  };
  const onDeactiveProducer = async (record, type) => {
    if (isUpdatingProducerStatus) {
      return;
    }

    setIsUpdatingProducerStatus(true);
    dispatch(setLoadingDetergentProducer({ fetching: true }));

    let configTitleTranslation = {
      id: 'detergentProducer.modal.deactivate.title',
      defaultMessage: 'DEACTIVATE DETERGENT PRODUCER'
    };

    if (type === STATUS_ENUM.ACTIVE) {
      configTitleTranslation = {
        id: 'detergentProducer.modal.activate.title',
        defaultMessage: 'ACTIVATE DETERGENT PRODUCER'
      };
    }

    const getConfigConfirmModal = (isRelated = false) => ({
      intl,
      title: intl.formatMessage(configTitleTranslation),
      record,
      type,
      deactiveProducerHandler,
      content: (
        <ConfirmDeactiveDialog
          intl={intl}
          type={type}
          isRelated={isRelated}
          record={record}
        />
      ),
      onCancel: () => {
        setIsUpdatingProducerStatus(false);
        dispatch(setLoadingDetergentProducer({ fetching: false }));
      }
    });
    // Case active
    if (type === STATUS_ENUM.ACTIVE) {
      openDeactiveProducerConfirmModal(getConfigConfirmModal(false));
      return;
    }

    // Case inactive
    const isRelated = await checkProducerRelatedToRawMaterial(record.id);
    openDeactiveProducerConfirmModal(getConfigConfirmModal(isRelated));
  };

  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');
    pagination.current = 1;
    dispatch(
      getDetergentProducers({
        pagination,
        searchByText: valueChange,
        filterList,
        order
      })
    );
  };

  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(
        getDetergentProducers({
          pagination,
          searchByText: '',
          filterList,
          order
        })
      );
    }
  };

  const popupAction = action => {
    if (action === 'open') {
      dispatch(
        processProducerModal({
          statusForm: FORM_STATUS.CREATE,
          title: intl.formatMessage({
            id: 'detergentProducer.modal.add.title',
            defaultMessage: 'ADD NEW DETERGENT PRODUCER'
          }),
          isVisible: true
        })
      );
    } else {
      dispatch(
        processProducerModal({
          isVisible: false
        })
      );
    }
  };

  return (
    <>
      <div className="detergent-database-content detergent-producer-tab">
        <div className="main-title">
          <FormattedMessage
            id="header.componentDetergentProducer"
            defaultMessage="Detergent Producers"
          />
        </div>
        <div className="filter-area">
          <Filter
            dropdownClassName="sort-dropdown"
            dropdownOverlayClassName="filter-detergentProducer"
            FilterFormLayout={FilterForm}
          />

          <Input.Search
            className="search-place"
            placeholder={intl.formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearchList(value)}
          />

          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.DETERGENT_PRODUCERS.CREATE}>
            <Button className="add-button" onClick={() => popupAction('open')}>
              <Icon className="add-icon" type="plus" />
              <div className="text">
                <FormattedMessage
                  id="common.addProducerButton"
                  defaultMessage="ADD PRODUCER"
                />
              </div>
            </Button>
          </ProtectedComponent>

          <ProducerContactModal
            status={detergentProducers.modal.statusForm}
            title={detergentProducers.modal.title}
            isVisible={detergentProducers.modal.isVisible}
            onCancel={() => popupAction('close')}
          />
        </div>
      </div>
      <List
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onEditProducer={record => onEditProducer(record)}
        onDeactiveProducer={(record, type) => onDeactiveProducer(record, type)}
      />
    </>
  );
};

export default injectIntl(DetergentsProducer);
