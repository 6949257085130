import {
  GET_SEARCH_DATA,
  GET_SEARCH_DATA_ERROR,
  GET_SEARCH_DATA_SUCCESS,
  CLEAR_SEARCH_DATA,
  SET_SEARCH_KEYWORDS
} from './actions';

export const initialState = {
  keywords: '',
  fetching: false,
  data: {
    results: [],
    pagination: {
      total: 10,
      pageSize: 20,
      current: 1
    }
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_DATA: {
      return {
        ...state,
        fetching: true,
        data: {
          ...state.data,
          pagination: action.payload.pagination || initialState.data.pagination
        }
      };
    }

    case GET_SEARCH_DATA_SUCCESS: {
      const { results, pagination } = action.payload;
      return {
        ...state,
        fetching: false,
        data: {
          results,
          pagination
        }
      };
    }

    case GET_SEARCH_DATA_ERROR: {
      return {
        ...state,
        fetching: false
      };
    }

    case SET_SEARCH_KEYWORDS: {
      return {
        ...state,
        keywords: action.payload.keywords
      };
    }

    case CLEAR_SEARCH_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
