import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { BaseTable } from '../../../../components/BaseTable';
import { getDetergentListColumns } from './renderColumns';
import {
  getDetergentByRawMaterialId,
  clearDetergentByRawMaterialIdWhenUnmount
} from '../../data/rawMaterial/action';
import { renderDetergentListExpandIcons } from './renderDetergentListExpandIcons';
import { INITIAL_FILTER_DETERGENT_LIST_BUTTON_TEXT } from '../../../../configs/constant';
import { INITIAL_FILTER_DETERGENT_LIST, INITIAL_SORT_DETERGENT_LIST } from '../../utils/constants';
import BaseExpandableTable from '../../../../components/BaseExpandableTable';

const DetergentList = ({ rawMaterialId }) => {
  const dispatch = useDispatch();

  const {
    detergentListByRawMaterial,
    isFetchingDetergentList,
    paginationDetergentList
  } = useSelector(state => ({
    detergentListByRawMaterial:
      state.rawMaterials.detergentListByRawMaterial.data.results,
    isFetchingDetergentList:
      state.rawMaterials.detergentListByRawMaterial.fetching,
    paginationDetergentList:
      state.rawMaterials.detergentListByRawMaterial.data.pagination
  }));

  const [filterableDetergentList, setFilterableDetergentList] = useState(
    INITIAL_FILTER_DETERGENT_LIST
  );

  const [sortDetergentList, setSortDetergentList] = useState(
    INITIAL_SORT_DETERGENT_LIST
  );

  const [paginationPage, setPaginationPage] = useState(paginationDetergentList);

  const initialFilterButtonText = INITIAL_FILTER_DETERGENT_LIST_BUTTON_TEXT();

  useEffect(() => {
    const newPagination = { ...paginationDetergentList };

    dispatch(
      getDetergentByRawMaterialId({
        rawMaterialId,
        pagination: newPagination,
        filter: filterableDetergentList,
        order: sortDetergentList
      })
    );
  }, [filterableDetergentList, paginationPage]);

  useEffect(() => {
    // Unmount
    return () => {
      dispatch(clearDetergentByRawMaterialIdWhenUnmount());
    };
  }, []);

  const onPageSizeChange = value => {
    paginationDetergentList.current = 1;
    paginationDetergentList.pageSize = value;
    setPaginationPage(paginationDetergentList);
  };

  const onPageChange = current => {
    paginationDetergentList.current = current + 1;
    setPaginationPage(paginationDetergentList);
  };

  const onTableChange = (_, filters) => {
    paginationDetergentList.current = 1;
    setPaginationPage(paginationDetergentList);
    const parseFilters = { ...filters };
    if (filters.status.length) {
      const [status] = parseFilters.status;
      parseFilters.status = status;
      setFilterableDetergentList(parseFilters);
    } else {
      setFilterableDetergentList(INITIAL_FILTER_DETERGENT_LIST);
    }
  };

  const renderExpandedTable = record => {
    const detergentFormulasExceptLatestOne = record.formulas.slice(1);

    return detergentFormulasExceptLatestOne.length ? (
      <BaseTable
        dataSource={detergentFormulasExceptLatestOne}
        columns={getDetergentListColumns(true)}
        bordered
        showHeader={false}
        rowClassName="expanded-rows"
      />
    ) : null;
  };

  return (
    <BaseExpandableTable
      pagination={
        paginationDetergentList?.total ? paginationDetergentList : null
      }
      className="raw-material-detail-list-table fixed-table-layout"
      bordered
      columns={getDetergentListColumns()}
      dataSource={detergentListByRawMaterial}
      loading={
        isFetchingDetergentList && { indicator: <Icon type="sync" spin /> }
      }
      onPageSizeChange={value => onPageSizeChange(value)}
      onPageChange={current => onPageChange(current)}
      expandedRowRender={record => renderExpandedTable(record)}
      expandIcon={props => renderDetergentListExpandIcons(props)}
      expandIconAsCell={false}
      expandIconColumnIndex={5}
      onChange={onTableChange}
      locale={initialFilterButtonText}
      getPopupContainer={triggerNode => triggerNode}
    />
  );
};

DetergentList.propTypes = {
  rawMaterialId: PropTypes.number.isRequired
};

export { DetergentList };
