import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  buildUrlQueryParams,
  changePointToComma
} from '../../../../../../utils/common';
import {
  DISPLAY_PART_NUMBER_STATUS,
  MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME,
  URL_PARAMS_ACTIONS
} from '../../../../../../configs/constant';

export const renderPartNumberColumns = [
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnPartNumber"
        defaultMessage="Part Number"
      />
    ),
    width: '20%',
    key: 'part number',
    dataIndex: 'partNumber'
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnProducer"
        defaultMessage="Producer"
      />
    ),
    width: '25%',
    key: 'producer',
    render: record => {
      const producerName = record.detergentProducer?.name;
      return <span>{producerName}</span>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnStatus"
        defaultMessage="Product Status"
      />
    ),
    width: '15%',
    key: 'status',
    render: record => (
      <span className={MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME[record.status]}>
        {DISPLAY_PART_NUMBER_STATUS[record?.status]}
      </span>
    )
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.containerSize"
        defaultMessage="Container Size"
      />
    ),
    width: '20%',
    key: 'container size',
    render: record => <span>{changePointToComma(record?.containerSize)}</span>
  },
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnViewDetails"
        defaultMessage="View details"
      />
    ),
    width: '20%',
    render: record => {
      const { id } = record;
      const viewDetailUrl = buildUrlQueryParams('/part-numbers', {
        data: {
          id
        },
        action: URL_PARAMS_ACTIONS.OPEN_PARTNUMBER_DETAIL_DIALOG
      });
      return (
        <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
          <img className="action-item" src="/icon/ic-view-details.svg" />
        </a>
      );
    }
  }
];
