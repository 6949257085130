import { put, takeLatest } from 'redux-saga/effects';
import { getDetergentNonPagingApi } from './api';
import {
  GET_DETERGENT_NON_PAGING,
  getDetergentNonPagingSuccess,
  getDetergentNonPagingError
} from './action';
import { errorHandle } from '../../utils/common';

export function* getDetergentNonPagingSaga() {
  try {
    const response = yield getDetergentNonPagingApi();
    yield put(getDetergentNonPagingSuccess(response.data.data));
  } catch (error) {
    yield put(getDetergentNonPagingError(error));
    errorHandle(error);
  }
}

export default function* detergentNonPagingSaga() {
  yield takeLatest(GET_DETERGENT_NON_PAGING, getDetergentNonPagingSaga);
}
