import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import getTestTableColumns from '../ChemicalQualityControl/testTableColumns';
import generateProductionProtocolSchema from '../../../../../Util/measurement/productionProtocolSchema';
import { BaseTable } from '../../../../../../../../components/BaseTable';
import { chemicalQualityControlTestTableId } from '../constant';

const TestTable = () => {
  const { formatMessage } = useIntl();
  const { measurement } = useSelector(state => ({
    measurement: state.detergent.measurementData.data
  }));

  const generatedData = generateProductionProtocolSchema(measurement);
  return (
    <BaseTable
      className="generated-file-measurement-table measurement-test-table"
      columns={getTestTableColumns(formatMessage)}
      dataSource={generatedData}
      pagination={false}
      bordered
      tableElementId={chemicalQualityControlTestTableId}
    />
  );
};

export default TestTable;
