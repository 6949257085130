export default {
  tabWaterLabel: null,
  labInformation: null,
  cloudPointDIN53917: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  densityDIN51757: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  refrectionIndex: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  bulkDensityDIN697: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },

  dissolutionTimeMinutesInfo: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  waterSolubility: {
    measured: null,
    comment: null
  },
  dryWeightMettlerIRdryer155: {
    measured: null,
    comment: null
  },
  appearanceColourSmell: {
    measured: null,
    comment: null
  },
  mass: {
    measured: null,
    comment: null
  },
  dimensions: {
    measured: null,
    comment: null
  },
  durability: {
    measured: null,
    comment: null
  },
  recommendedStorageTemparature: {
    measured: null,
    comment: null
  },
  roomTemperatureFourWeeks: {
    passFailed: null,
    comment: null
  },
  roomTemperatureEightWeeks: {
    passFailed: null,
    comment: null
  },
  roomTemperatureSixMonths: {
    passFailed: null,
    comment: null
  },
  roomTemperatureTwelveMonths: {
    passFailed: null,
    comment: null
  },
  roomTemperatureTwentyFourMonths: {
    passFailed: null,
    comment: null
  },
  fiftyCThreeDays: {
    passFailed: null,
    comment: null
  },
  thirtySevenCFourWeeks: {
    passFailed: null,
    comment: null
  },
  fourCFourWeeks: {
    passFailed: null,
    comment: null
  },
  negativeTwentyOneDay: {
    passFailed: null,
    comment: null
  },
  concentrate: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  tabWater: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  corrosivityTest: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  flashPointAccordingToDIN: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  flashPointSixtyPointFiveCIATA: {
    measured: null,
    min: null,
    max: null,
    comment: null
  },
  chemicalOxygenDemand: {
    measured: null,
    comment: null
  },
  viscosityTwentyC: {
    measured: null,
    comment: null
  },
  rotationalSpeed: {
    measured: null,
    comment: null
  },
  adapter: {
    measured: null,
    comment: null
  },
  freezingPointC: {
    measured: null,
    comment: null
  },
  asfOenorm5106: {
    measured: null,
    comment: null
  },
  temperature: {
    measured: null,
    comment: null
  }
};
