import React, { useState, useEffect } from 'react';
import { Select, Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import {
  RAW_MATERIAL_DOCUMENT_CATEGORIES,
  DETERGENT_FORMULA_DOCUMENT_CATEGORIES,
  UPLOAD_TYPES
} from '../../configs/constant';

export const PreviewUploadFiles = ({
  fileList,
  onChangeDocumentProperty,
  onDeleteFile,
  documentType,
  expandSelection
}) => {
  const [documentsHaveCategory, setDocumentsHaveCategory] = useState([]);
  const [hasFile, setHasFiles] = useState(false);

  useEffect(() => {
    // check when fileList isn't undefined
    if (!hasFile && fileList && fileList.length !== 0) {
      setHasFiles(true);
    }
  }, [fileList]);

  useEffect(() => {
    // if fileList isn't undefined, start to get the list id of the old documents
    if (hasFile) {
      const initialDocuments = fileList.map(file => file.id);
      setDocumentsHaveCategory(initialDocuments);
    }
  }, [hasFile]);

  const onRemoveFile = removedFile => {
    // because initial files contain 'id' properties not the 'tempId'
    if (removedFile?.id) {
      // if these are the initial files
      const cloneDocuments = documentsHaveCategory.filter(
        documentId => documentId !== removedFile?.id
      );
      // its id should be removed from 'documentsHaveCategory'
      setDocumentsHaveCategory(cloneDocuments);
    }

    onDeleteFile(removedFile);
  };

  const documentCategories =
    documentType === UPLOAD_TYPES.DETERGENT
      ? DETERGENT_FORMULA_DOCUMENT_CATEGORIES
      : RAW_MATERIAL_DOCUMENT_CATEGORIES;

  const onChange = (changed, fileItem) => {
    if (changed !== undefined) {
      const cloneDocuments = [...documentsHaveCategory];
      cloneDocuments.push(fileItem.tempId);
      setDocumentsHaveCategory(cloneDocuments);
    }
  };

  const getSelectionClassName = currentId => {
    return documentsHaveCategory.includes(currentId)
      ? 'has-non-error-document'
      : '';
  };

  function renderFileItems() {
    if (fileList && fileList.length) {
      return fileList.map((fileItem, fileIndex) => {
        return (
          <div
            className="file-item"
            key={fileItem.tempId || fileItem.id || fileIndex}>
            <div className="file-info">
              <Icon type="paper-clip" />
              <span className="fileName">{fileItem.name}</span>
              <Button
                size="small"
                type="link"
                onClick={() => onRemoveFile(fileItem)}>
                <Icon type="close" />
              </Button>
            </div>
            <div id="parent-node" className="select-document-category">
              <Select
                id="select"
                getPopupContainer={() => document.getElementById('parent-node')}
                placeholder="Select document category"
                onSelect={value =>
                  onChangeDocumentProperty(fileItem, 'category', value)
                }
                value={parseInt(fileItem.category, 10) || null}
                suffixIcon={<Icon type="caret-down" />}
                className={getSelectionClassName(
                  fileItem.tempId ? fileItem.tempId : fileItem.id
                )}
                onChange={changed => onChange(changed, fileItem)}>
                {documentCategories.map(documentCategory => (
                  <Select.Option
                    key={documentCategory.id}
                    value={documentCategory.id}>
                    {documentCategory.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {expandSelection(fileItem)}
          </div>
        );
      });
    }

    return null;
  }

  return <div className="preview-raw-material-files">{renderFileItems()}</div>;
};

PreviewUploadFiles.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeDocumentProperty: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  expandSelection: PropTypes.node
};

PreviewUploadFiles.defaultProps = {
  fileList: [],
  expandSelection: null
};
