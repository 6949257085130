import {
  GET_DETERGENT_PRODUCERS,
  GET_DETERGENT_PRODUCERS_SUCCESS,
  GET_DETERGENT_PRODUCERS_ERROR,
  CREATE_DETERGENT_PRODUCERS,
  CREATE_DETERGENT_PRODUCERS_SUCCESS,
  CREATE_DETERGENT_PRODUCERS_ERROR,
  UPDATE_DETERGENT_PRODUCERS,
  UPDATE_DETERGENT_PRODUCERS_SUCCESS,
  UPDATE_DETERGENT_PRODUCERS_ERROR,
  GET_ALL_DETERGENT_PRODUCERS,
  GET_ALL_DETERGENT_PRODUCERS_SUCCESS,
  GET_ALL_DETERGENT_PRODUCERS_ERROR,
  CLEAR_DETERGENT_PRODUCER_DATA_WHEN_UNMOUNT,
  SET_LOADING_DETERGENT_PRODUCER,
  TOGGLE_DETERGENT_PRODUCER_MODAL
} from './actions.js';

export const defaultDetergentProducerContact = {
  title: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: ''
};

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    }
  },
  allProducersSuppliers: {
    fetching: false,
    list: []
  },
  order: {
    name: 'ASC'
  },
  searchByText: '',
  fetching: false,
  filterList: {
    status: '',
    fromCountryId: ''
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    confirmModalVisibility: false,
    countsRefeshList: 0
  },
  detergentProducer: {
    name: '',
    street: '',
    street_number: '',
    city: '',
    postal_code: null,
    country: '',
    fromCountryId: '',
    comment: '',
    erpNumber: '',
    contact_list: []
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DETERGENT_PRODUCERS:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          }
        },
        filterList: action.payload.filterList || initialState.filterList,
        fetching: true,
        searchByText: action.payload.searchByText || '',
        order: action.payload.order || initialState.order,
        modal: {
          ...state.modal
        }
      };
    case GET_DETERGENT_PRODUCERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.payload
      };
    case GET_DETERGENT_PRODUCERS_ERROR:
      return {
        ...state,
        fetching: false
      };
    case CREATE_DETERGENT_PRODUCERS:
      return {
        ...state,
        detergentProducer: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_DETERGENT_PRODUCERS_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case CREATE_DETERGENT_PRODUCERS_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case UPDATE_DETERGENT_PRODUCERS:
      return {
        ...state,
        detergentProducer: action.payload.producer,
        modal: {
          ...state.modal,
          loading: true,
          confirmModalVisibility: true
        }
      };
    case UPDATE_DETERGENT_PRODUCERS_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          confirmModalVisibility: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case UPDATE_DETERGENT_PRODUCERS_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          confirmModalVisibility: false
        }
      };
    case TOGGLE_DETERGENT_PRODUCER_MODAL:
      return {
        ...state,
        detergentProducer:
          action.payload.producer || initialState.detergentProducer,
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false
        }
      };
    case GET_ALL_DETERGENT_PRODUCERS:
      return {
        ...state,
        allProducersSuppliers: {
          ...state.allProducersSuppliers,
          list: [],
          fetching: true
        }
      };
    case GET_ALL_DETERGENT_PRODUCERS_SUCCESS:
      return {
        ...state,
        allProducersSuppliers: {
          ...state.allProducersSuppliers,
          list: action.payload,
          fetching: false
        }
      };
    case GET_ALL_DETERGENT_PRODUCERS_ERROR:
      return {
        ...state,
        allProducersSuppliers: {
          ...state.allProducersSuppliers,
          fetching: false
        }
      };
    case CLEAR_DETERGENT_PRODUCER_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    case SET_LOADING_DETERGENT_PRODUCER:
      return {
        ...state,
        fetching: action.payload.fetching
      };
    default:
      return state;
  }
}
