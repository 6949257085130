import React from 'react';
import { FormattedMessage } from 'react-intl';
import { changePointToComma } from '../../../../utils/common';
import AnnualTonnageContainerTypeDropDown from '../AnnualTonnageDropdown';
import { MAPPING_CONTAINER_GROUP_TO_TEXT, MAPPING_CONTAINER_TYPE_TO_TEXT } from '../../../../configs/constant';

export default () => {
  return [
    {
      title: (
        <FormattedMessage
          id="containerType.columnPartNumber"
          defaultMessage="Part Number"
        />
      ),
      dataIndex: 'partNumber',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage
          id="detergent.name"
          defaultMessage="Detergent Name"
        />
      ),
      dataIndex: 'detergentFormula.detergent.name',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage
          id="containerType.columnContainerType"
          defaultMessage="Container Type"
        />
      ),
      dataIndex: 'containerType',
      width: '10%',
      render: containerType => (
        <FormattedMessage
          id={MAPPING_CONTAINER_TYPE_TO_TEXT[containerType]}
          defaultMessage={containerType} 
        />
      )
    },
    {
      title: (
        <FormattedMessage
          id="containerType.columnContainerSize"
          defaultMessage="Container Size"
        />
      ),
      dataIndex: 'containerSize',
      width: '10%',
      render: text => <>{changePointToComma(text)}</>
    },
    {
      title: (
        <FormattedMessage
          id="containerType.columnContainerGroup"
          defaultMessage="Container Group"
        />
      ),
      dataIndex: 'containerGroup',
      width: '10%',
      render: containerGroup => (
        <FormattedMessage 
          id={MAPPING_CONTAINER_GROUP_TO_TEXT[containerGroup]}
          defaultMessage={containerGroup}
        />
      )
    },
    {
      title: (
        <FormattedMessage
          id="containerType.columnDetergentProducer"
          defaultMessage="Detergent Producer"
        />
      ),
      dataIndex: 'detergentProducer.name',
      width: '10%'
    },
    {
      title: (
        <FormattedMessage
          id="containerType.columnAnnualDemand"
          defaultMessage="Annual Demand"
        />
      ),
      dataIndex: 'annualDemands',
      key: 'plannedContainer',
      width: '8%',
      render: (_, { years }) => {
        const canShowPlannedContainer = years && years.length > 0;
        const title = {
          id: "partNumbers.columnPlannedContainer",
          defaultMessage: "Planned Container"
        };

        return canShowPlannedContainer && (
          <AnnualTonnageContainerTypeDropDown
            years={years}
            title={title}
          />
        )
      }
    }
  ]
};
