import { MEASUREMENT_MAPPING_FIELD } from '../../../../../configs/constant';

const generate = measurement => {
  const concentrateInfo = { ...measurement.concentrate };
  const tabWaterInfo = { ...measurement.tabWater };

  return [
    {
      cellKeyPh: MEASUREMENT_MAPPING_FIELD.concentrate.key,

      measured: concentrateInfo.measured,
      min: concentrateInfo.min,
      max: concentrateInfo.max,
      comment: concentrateInfo.comment
    },
    {
      cellKeyPh: MEASUREMENT_MAPPING_FIELD.tabWater.key,

      measured: tabWaterInfo.measured,
      min: tabWaterInfo.min,
      max: tabWaterInfo.max,
      comment: tabWaterInfo.comment
    }
  ];
};

export default generate;
