import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  UPDATE_COGNITO_USER,
  updateCognitoUserSuccess,
  updateCognitoUserError,
  GET_COGNITO_USER,
  getCognitoUserSuccess,
  getCognitoUserError,
  GET_KAERCHER_USER,
  getKaecherUserSuccess,
  getKaecherUserError,
  ADD_KAERCHER_USER,
  addKaercherUserSuccess,
  addKaercherUserError
} from './actions';
import {
  updateCognitoUserApi,
  getCognitoUserApi,
  getKaercherUserApi,
  addKaercherUserApi
} from './api';
import { showSuccessToast } from '../../../../services/toasterService';
import { errorHandle } from '../../../../utils/common';
import { STATUS_ENUM } from '../../../../configs/constant';

export function* updateCognitoUserSaga(action) {
  try {
    const response = yield updateCognitoUserApi(action.payload);
    yield put(updateCognitoUserSuccess(response.data));

    let translationId = 'common.notify.userManagement.updateSuccess';

    // Message for activate/deactivate
    if (action.payload?.status) {
      translationId =
        action.payload.status === STATUS_ENUM.ACTIVE
          ? 'common.notify.userManagement.activationSuccess'
          : 'common.notify.userManagement.deactivationSuccess';
    }

    if (action.payload?.roles) {
      translationId = 'common.notify.userManagement.updateRoleSuccess';
    }

    showSuccessToast(translationId);
  } catch (error) {
    yield put(updateCognitoUserError(error));
    errorHandle(error);
  }
}

export function* getCognitoUserSaga() {
  try {
    const response = yield getCognitoUserApi();
    yield put(getCognitoUserSuccess(response.data));
  } catch (error) {
    yield put(getCognitoUserError(error));
    errorHandle(error);
  }
}

export function* getKaecherUserSaga(action) {
  try {
    const response = yield getKaercherUserApi(action.payload.searchValue);
    yield put(getKaecherUserSuccess(response.data));
  } catch (error) {
    yield put(getKaecherUserError(error));
    errorHandle(error);
  }
}

export function* addKaercherUserSaga(action) {
  try {
    // const response = yield addKaercherUserApi(action.payload);

    const response = yield all(
      action.payload.map(user => {
        return addKaercherUserApi(user);
      })
    );

    yield put(addKaercherUserSuccess(response));

    showSuccessToast('common.notify.userManagement.addUserSuccess');
  } catch (error) {
    yield put(addKaercherUserError(error));

    errorHandle(error);
  }
}

export default function* userManagementSaga() {
  yield takeLatest(UPDATE_COGNITO_USER, updateCognitoUserSaga);
  yield takeLatest(GET_COGNITO_USER, getCognitoUserSaga);
  yield takeLatest(GET_KAERCHER_USER, getKaecherUserSaga);
  yield takeLatest(ADD_KAERCHER_USER, addKaercherUserSaga);
}
