import PropType from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BaseTable } from '../../../../../../../components/BaseTable';
import { MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID } from '../../../../../../../configs/constant';
import EnumPassFailed from '../../../../Util/measurement/enumPassFailed';
import generateStorageTestSchema from '../../../../Util/measurement/storageTestSchema';
import { storageTestTableId } from './constant';
import renderPDFCheckBox from '../Util/renderPDFCheckBox';
import { capitalizeFirstLetter } from '../../../../../../../utils/common';

const BlankMeasurementDataSheetStorageTestTable = ({ measurement }) => {
  const { formatMessage } = useIntl();
  const metaDataColumns = [
    {
      title: (
        <FormattedMessage
          id="measurement.storageTable.title"
          defaultMessage="STORAGE TEST"
        />
      ),
      dataIndex: 'cellKeyStorage',
      render: value => {
        // TODO: add default message
        const key = MAPPING_DETERGENT_HISTORY_TO_TRANSLATION_ID[value];
        return formatMessage({
          id: typeof key === 'object' ? key.id : key
        });
      },
      width: 280
    },
    {
      title: <FormattedMessage id="detergent.passed" defaultMessage="Passed" />,
      dataIndex: 'passFailed',
      width: 96,
      render: value => {
        return renderPDFCheckBox({ checked: value === EnumPassFailed.PASS });
      }
    },
    {
      title: (
        <FormattedMessage
          id="detergent.measurementDataNotPassed"
          defaultMessage="Failed"
        />
      ),
      dataIndex: 'passFailed',
      width: 104,
      render: value => {
        return renderPDFCheckBox({ checked: value === EnumPassFailed.FAILED });
      }
    },
    {
      title: <FormattedMessage id="comment.title" defaultMessage="Comment" />,
      dataIndex: 'comment',
      render: value => capitalizeFirstLetter(value),
      width: 240
    }
  ];

  const generatedData = generateStorageTestSchema(measurement);

  return (
    <BaseTable
      className="generated-file-measurement-table measurement-storage-test-table"
      columns={metaDataColumns}
      dataSource={generatedData}
      pagination={false}
      tableElementId={storageTestTableId}
    />
  );
};

BlankMeasurementDataSheetStorageTestTable.propTypes = {
  measurement: PropType.shape({}).isRequired
};

export default BlankMeasurementDataSheetStorageTestTable;
