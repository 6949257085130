import {
  DISPLAY_MEASUREMENT_TEMPERATURES,
  MEASUREMENT_MAPPING_FIELD_CHEMICAL
} from '../../../../../configs/constant';

const tabWaterKeyMap = {
  1: {
    id: 'detergent.measurement.productionProtocal.phValue.tabWater.onePercent',
    defaultMessage: `pH-value DIN 19268 (1% in tab water)`
  },
  10: {
    id: 'detergent.measurement.productionProtocal.phValue.tabWater.tenPercent',
    defaultMessage: `pH-value DIN 19268 (10% in tab water)`
  }
};

const defaultTablWaterKey = {
  id: 'detergent.measurement.productionProtocal.phValue.tabWater.default',
  defaultMessage: `pH-value DIN 19268 (% in tab water)`
};

const generate = measurement => {
  const concentrateInfo = { ...measurement.concentrate };
  const tabWaterInfo = { ...measurement.tabWater };
  const { tabWaterLabel } = measurement;
  const densityDIN51757Info = { ...measurement.densityDIN51757 };
  const cloudPointDIN53917Info = { ...measurement.cloudPointDIN53917 };
  const refrectionIndexInfo = { ...measurement.refrectionIndex };
  const bulkDensityDIN697Info = { ...measurement.bulkDensityDIN697 };
  const dryWeightMettlerIRdryer155Info = {
    ...measurement.dryWeightMettlerIRdryer155
  };
  const massInfo = { ...measurement.mass };
  const appearanceColourSmellInfo = {
    ...measurement.appearanceColourSmell
  };
  const viscosityTwentyCInfo = { ...measurement.viscosityTwentyC };
  const adapter = { ...measurement.adapter };
  const rotationalSpeed = { ...measurement.rotationalSpeed };
  const temperature = { ...measurement.temperature };

  return [
    {
      testTitleKey: {
        id: 'detergent.measurement.productionProtocal.phValue.concentrate',
        defaultMessage: 'pH-value DIN 19268 (concentrate)'
      },
      measured: concentrateInfo.measured,
      min: concentrateInfo.min,
      max: concentrateInfo.max,
      comment: concentrateInfo.comment
    },
    {
      testTitleKey: tabWaterKeyMap[tabWaterLabel * 100] || defaultTablWaterKey,
      measured: tabWaterInfo.measured,
      min: tabWaterInfo.min,
      max: tabWaterInfo.max,
      comment: tabWaterInfo.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.densityDIN51757.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.densityDIN51757.name
      },
      measured: densityDIN51757Info.measured,
      min: densityDIN51757Info.min,
      max: densityDIN51757Info.max,
      comment: densityDIN51757Info.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.bulkDensityDIN697.key,
        defaultMessage:
          MEASUREMENT_MAPPING_FIELD_CHEMICAL.bulkDensityDIN697.name
      },
      measured: bulkDensityDIN697Info.measured,
      min: bulkDensityDIN697Info.min,
      max: bulkDensityDIN697Info.max,
      comment: bulkDensityDIN697Info.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.refrectionIndex.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.refrectionIndex.name
      },
      measured: refrectionIndexInfo.measured,
      min: refrectionIndexInfo.min,
      max: refrectionIndexInfo.max,
      comment: refrectionIndexInfo.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.cloudPointDIN53917.key,
        defaultMessage:
          MEASUREMENT_MAPPING_FIELD_CHEMICAL.cloudPointDIN53917.name
      },
      measured: cloudPointDIN53917Info.measured,
      min: cloudPointDIN53917Info.min,
      max: cloudPointDIN53917Info.max,
      comment: cloudPointDIN53917Info.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.dryWeightMettlerIRdryer155.key,
        defaultMessage:
          MEASUREMENT_MAPPING_FIELD_CHEMICAL.dryWeightMettlerIRdryer155.name
      },
      measured: dryWeightMettlerIRdryer155Info.measured,
      comment: dryWeightMettlerIRdryer155Info.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.mass.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.mass.name
      },
      measured: massInfo.measured,
      comment: massInfo.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.appearanceColourSmell.key,
        defaultMessage:
          MEASUREMENT_MAPPING_FIELD_CHEMICAL.appearanceColourSmell.name
      },
      measured: appearanceColourSmellInfo.measured,
      comment: appearanceColourSmellInfo.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.viscosityTwentyC.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.viscosityTwentyC.name
      },
      measured: viscosityTwentyCInfo.measured,
      comment: viscosityTwentyCInfo.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.adapter.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.adapter.name
      },
      measured: adapter.measured,
      comment: adapter.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.rotationalSpeed.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.rotationalSpeed.name
      },
      measured: rotationalSpeed.measured,
      comment: rotationalSpeed.comment
    },
    {
      testTitleKey: {
        id: MEASUREMENT_MAPPING_FIELD_CHEMICAL.temperature.key,
        defaultMessage: MEASUREMENT_MAPPING_FIELD_CHEMICAL.temperature.name
      },
      measured: DISPLAY_MEASUREMENT_TEMPERATURES[temperature.measured],
      comment: temperature.comment
    }
  ];
};

export default generate;
