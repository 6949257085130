import { put, takeLatest } from 'redux-saga/effects';
import { getPartNumberNonPagingApi } from './api';
import {
  GET_PART_NUMBER_NON_PAGING,
  getPartNumberNonPagingSuccess,
  getPartNumberNonPagingError
} from './action';
import { errorHandle } from '../../utils/common';

export function* getPartNumberNonPagingSaga(action) {
  try {
    const { formulaId, filter } = action.payload;
    const response = yield getPartNumberNonPagingApi(formulaId, { filter });
    yield put(getPartNumberNonPagingSuccess(response.data.data));
  } catch (error) {
    yield put(getPartNumberNonPagingError(error));
    errorHandle(error);
  }
}

export default function* partNumberNonPagingSaga() {
  yield takeLatest(GET_PART_NUMBER_NON_PAGING, getPartNumberNonPagingSaga);
}
