import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import { MEASUREMENT_MAPPING_FIELD } from '../../../../configs/constant';
import EditableColumnField from '../../../../components/EditableColumnField';
import FormattedInputNumber from '../../../../components/FormattedInputNumber';

export const renderMeasurementSafetyColumns = isEditable => [
  {
    title: (
      <FormattedMessage
        id="measurement.safetyTable.title"
        defaultMessage="SAFETY"
      />
    ),
    render: data => {
      return _.get(
        MEASUREMENT_MAPPING_FIELD,
        `${data.cellKeySafety}.name`,
        `No label: ${data.cellKeySafety}`
      );
    },
    key: 'cellKeySafety',
    width: '30%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.measured.title"
          defaultMessage="Measured value"
        />
      </div>
    ),
    key: 'measured',
    width: '15%',
    render: record => {
      return (
        <EditableColumnField
          tabName="measurement"
          editable={isEditable}
          readableField={
            <FormattedInputNumber
              className="measure-display"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          editableField={
            <FormattedInputNumber
              className="measure-edit"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          cellKey={record.cellKeySafety}
          columnKey="measured"
        />
      );
    }
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage id="measurement.min.title" defaultMessage="Minimum" />
      </div>
    ),
    key: 'min',
    width: '15%',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <FormattedInputNumber
            className="measure-display"
            defaultValue={record.min}
            disabled={!isEditable}
          />
        }
        editableField={
          <FormattedInputNumber
            className="measure-edit"
            defaultValue={record.min}
            disabled={!isEditable}
          />
        }
        cellKey={record.cellKeySafety}
        columnKey="min"
      />
    )
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.max.title"
          defaultMessage="Maximum value"
        />
      </div>
    ),
    key: 'max',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <FormattedInputNumber
            className="measure-display"
            defaultValue={record.max}
            disabled={!isEditable}
          />
        }
        editableField={
          <FormattedInputNumber
            className="measure-edit"
            defaultValue={record.max}
            disabled={!isEditable}
          />
        }
        cellKey={record.cellKeySafety}
        columnKey="max"
      />
    ),
    width: '15%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.comment.title"
          defaultMessage="Comment"
        />
      </div>
    ),
    key: 'comment',
    render: record => {
      return (
        <EditableColumnField
          tabName="measurement"
          editable={isEditable}
          readableField={
            <Input.TextArea
              className="measure-display"
              defaultValue={record.comment}
              disabled={!isEditable}
              autoSize={{ maxRows: 3 }}
            />
          }
          editableField={
            <Input.TextArea
              className="measure-edit-comment"
              defaultValue={record.comment}
              disabled={!isEditable}
              autoSize={{ maxRows: 3 }}
            />
          }
          cellKey={record.cellKeySafety}
          columnKey="comment"
        />
      );
    },
    width: '25%'
  }
];
