import React, { useState, useEffect } from 'react';

import { Select, Icon, Form } from 'antd';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import { uniqId } from '../../utils/common';

const EditStatusForm = props => {
  const {
    isEditable,
    field,
    currentInitialValue,
    selectList,
    displaySelectItemName,
    onSaveChangesHandler,
    onCancelChangesHandler,
    classActionTypes,
    form
  } = props;

  const { getFieldDecorator, resetFields, getFieldValue } = form;

  const [actionClassNames, setActionClassNames] = useState('');

  useEffect(() => {
    setActionClassNames(isEditable ? 'hide-action-btn' : '');
  }, [isEditable]);

  const cancelChangesHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    onCancelChangesHandler();
    resetFields();
  };

  const formId = uniqId();

  return (
    <Form>
      {!isEditable ? (
        <div
          id={`id_of_dropdown_container-${formId}`}
          className="select-margin-top">
          {getFieldDecorator(field, {
            initialValue: displaySelectItemName(currentInitialValue)
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById(`id_of_dropdown_container-${formId}`)
              }
              suffixIcon={<Icon type="caret-down" />}
              className={`${classActionTypes} formula-select-fields ${
                isEditable ? 'hide-select-fields' : null
              }`}
              defaultOpen
              loading>
              {selectList.map(selectItem => (
                <Select.Option key={selectItem.value} value={selectItem.value}>
                  {displaySelectItemName(selectItem.value)}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      ) : (
        <span>{displaySelectItemName(currentInitialValue)}</span>
      )}

      <div className={`formula-actions-wrapper ${actionClassNames}`}>
        <button
          className={`${classActionTypes} edit-formula-action-btn`}
          type="submit"
          onClick={e => onSaveChangesHandler(getFieldValue(field), form, e)}>
          <SvgIcon className={classActionTypes} componentId="saveChange" />
        </button>
        <button
          type="button"
          className={`${classActionTypes} edit-formula-action-btn`}
          onClick={cancelChangesHandler}>
          <SvgIcon className={classActionTypes} componentId="cancelChange" />
        </button>
      </div>
    </Form>
  );
};

EditStatusForm.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  currentInitialValue: PropTypes.string.isRequired,
  selectList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displaySelectItemName: PropTypes.func.isRequired,
  onSaveChangesHandler: PropTypes.func.isRequired,
  onCancelChangesHandler: PropTypes.func.isRequired,
  classActionTypes: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired
  }).isRequired
};

export default EditStatusForm;
