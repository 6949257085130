import React from 'react';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  DISPLAY_COMMODITY_GROUP,
  DISPLAY_CATEGORY_RAW_MATERIAL
} from '../../../../configs/constant';
import { getS3FileUrl } from '../../../../utils/s3Service';

const handleDownloadFile = async (e, key) => {
  e.preventDefault();
  const url = await getS3FileUrl(key);
  window.open(url.data.data);
};

const renderRowFields = (label = '', value = '') => {
  // Check Array Object
  if (Array.isArray(value)) {
    // If Documents then processing documents display
    if (label === 'Documents' || label === 'Dokumente') {
      return (
        <div className="row-detail">
          <div className="label">{label}</div>
          <div className="value">
            {value.map((item, idx) => {
              return (
                <div
                  className={`documents${
                    idx === value.length - 1 ? ' last' : ''
                  }`}>
                  <Icon type="paper-clip" />
                  <a
                    href="/"
                    onClick={e => handleDownloadFile(e, item.key)}
                    target="_blank"
                    rel="noopener noreferrer">
                    {item.name}
                  </a>
                  <span>
                    <FormattedMessage
                      id={
                        DISPLAY_CATEGORY_RAW_MATERIAL[
                          parseInt(item.category, 10)
                        ]
                      }
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    // Another Array Object processing Display
    return (
      <div className="row-detail">
        <div className="label">{label}</div>
        <div className="value">
          {value.map((item, idx) => {
            if (idx === value.length - 1) {
              return <span key={label + idx}>{item.name}</span>;
            }
            return <span key={label + idx}>{item.name}, </span>;
          })}
        </div>
      </div>
    );
  }
  // Processing Status Display
  if (label === 'Status') {
    return (
      <div className="row-detail">
        <div className="label">{label}</div>
        <div className={`value status ${value}`}>
          <span>{value}</span>
        </div>
      </div>
    );
  }

  // Processing Commodity group display
  if (label === 'Commodity group' || label === 'Warengruppe') {
    if (value !== '') {
      return (
        <div className="row-detail">
          <div className="label">{label}</div>
          <div className="value-commodity">
            <FormattedMessage id={`${DISPLAY_COMMODITY_GROUP[value]}`} />
          </div>
        </div>
      );
    }
  }
  return defaultRowDetail(label, value);
};

const defaultRowDetail = (label, value) => (
  <div className="row-detail">
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
);
export const rowDetailSupplierAndPrice = (label, value) => {
  const getClassName =
    label === 'Price per kilogram' || label === 'Preis pro kg'
      ? 'row-detail-price row-detail'
      : 'row-detail-supplier row-detail';

  return (
    <div className={getClassName}>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
};
export const parseNameById = (id, array = []) => {
  let name = '';
  array.forEach(item => {
    if (item.id === id) {
      return (name = item.name);
    }
  });
  return name;
};

export { renderRowFields };
