import { AxiosService } from '../../../../services/axiosService';

export const getDetergentApi = options => {
  return AxiosService.get(
    'detergent',
    {
      search: options.searchByText,
      order: options.order,
      filter: options.filter
    },
    {
      pagination: options.pagination
    }
  );
};

export const addDetergentApi = data => {
  return AxiosService.post('detergent', data);
};

export const updateDetergentApi = (id, data) => {
  return AxiosService.put(`detergent/${id}`, data);
};

export const addFormulaApi = (id, data) => {
  return AxiosService.post(`detergent/${id}/formula`, data);
};

export const getDetergentFormulaById = (id, formulaId) => {
  return AxiosService.get(`detergent/${id}/formula/${formulaId}`);
};

export const putDetergentFormulaApi = (detergentId, formulaId, data) => {
  return AxiosService.put(
    `detergent/${detergentId}/formula/${formulaId}`,
    data
  );
};

export const patchFormulaApi = (id, formulaId, data) => {
  return AxiosService.patch(`detergent/${id}/formula/${formulaId}`, data);
};

export const getDetergentHistoryApi = (id, formulaId, pagination) => {
  return AxiosService.get(
    `/detergent/${id}/formula/${formulaId}/changes`,
    {},
    {
      pagination
    }
  );
};

export const getMeasurement = (id, formulaId) => {
  return AxiosService.get(`/detergent/${id}/formula/${formulaId}/measurement`);
};

export const updateMeasurementDataFieldApi = (id, formulaId, data) => {
  return AxiosService.put(
    `/detergent/${id}/formula/${formulaId}/measurement`,
    data
  );
};

export const createMeasurementDataFieldApi = data => {
  return AxiosService.put(
    `/detergent/${data.id}/formula/${data.formulaId}/measurement`,
    data.data
  );
};

export const getRawMaterialByFormulaApi = formulaId => {
  return AxiosService.get(`formula/${formulaId}/raw-material`);
};

export const updateRawMaterialsApi = (id, data) => {
  return AxiosService.patch(`raw-material/${id}`, data);
};

export const getDetergentProductionCycleListApi = formulaId => {
  return AxiosService.get(`formula/${formulaId}/production-cycle`);
};

export const createProductionCycleApi = data => {
  return AxiosService.post(`production-cycle`, data);
};

export const createDummyPartNumberApi = data => {
  return AxiosService.post(`part-number`, data);
};

export const downloadPrecalculationApi = (formulaId, languageCode) => {
  return AxiosService.get(`file/formula/${formulaId}/precalculation/${languageCode}`);
};

export const updateProductionCycleApi = (id, data) => {
  return AxiosService.put(`production-cycle/${id}`, data);
};

export const getPartNumberListByFormulaApi = formulaId => {
  return AxiosService.get(`formula/${formulaId}/part-number/non-paging`);
};

export const sendEmailApi = data => {
  return AxiosService.post(`/email-queue`, data);
};

export const deleteProductionCycleApi = id => {
  return AxiosService.delete(`production-cycle/${id}`);
};

export const getProductionCycleHistoryApi = formulaId => {
  return AxiosService.get(`formula/${formulaId}/production-cycle-history`);
};
