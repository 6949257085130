export const TRANSFORM_LOGGING_SPECIAL_KEYS = {
  DETERGENT_DOCUMENT_CATEGORY: 'formulas.documents.category',
  RAW_MATERIAL_DOCUMENT_CATEGORY: 'documents.category',
  COMMODITY_GROUP: 'commodityGroup',
  IS_SUPPLIER_DIFFERENT_FROM_PRODUCER: 'isSupplierDifferentFromProducer',
  ASF_OENORM_5106: 'formulaMeasurement.asfOenorm5106.measured',
  CONTAINER_TYPE: 'containerType',
  CONTAINER_GROUP: 'containerGroup',
  PRESENCE_OF_DETERGENT_DEVELOPMENT: 'presenceOfDetergentDevelopment',
  ROLE: 'role',
  RAW_MATERIAL_STATUS: 'raw-material.status',
  LOGS_STATUS: 'status',
  DETERGENT_FORMULA_STATUS: 'detergent.formulas.status',
  FORMULA_FORM: 'detergent.formulas.form',
  FORMULA_PROJECT_TYPES: 'detergent.formulas.projectType',
  FORMULA_MEASUREMENT_PASS_FAIL: 'passFailed'
};
