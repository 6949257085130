import React from 'react';
import { FormattedMessage } from 'react-intl';
import Label from '../Util/Label';
import LabelField from '../Util/LabelField';
import Style from '../Util/style';
import CheckBoxLabelField from './CheckBoxLabelField';
import FillableNote from './FillableNote';

const TestResult = () => {
  return (
    <section className="product-test-protocal-signature-test-information-container">
      <section style={Style.container}>
        <section className="test-resulted--note">
          <h6 className="test-resulted-note">
            <FormattedMessage
              id="detergent.productionTestProtocol.note"
              defaultMessage="If a measured value deviates, the TS [%] Mettler IR dryer 155°C must be determined"
            />
          </h6>
        </section>
      </section>

      <section style={Style.container}>
        <section className="test-resulted--wrapper">
          <h6 className="test-resulted">
            <FormattedMessage
              id="detergent.test.resulted"
              defaultMessage="The test resulted in:"
            />
          </h6>
        </section>
        <section className="product-test-protocal-signature--label test-resulted--checkbox">
          <input type="checkbox" />
          <span>
            <FormattedMessage
              id="detergent.no.complaint"
              defaultMessage="No Complaints"
            />
          </span>
        </section>
      </section>
      <section className="protocal-test-result">
        <Label
          className="product-test-protocal-signature--label"
          label={
            <CheckBoxLabelField>
              <FormattedMessage
                id="detergent.following"
                defaultMessage="The following complaints"
              />
            </CheckBoxLabelField>
          }
        />
        <FillableNote width={50}>
          <FormattedMessage
            id="detergent.in.case"
            defaultMessage="In case of complaint please enter complaints number"
          />
        </FillableNote>
      </section>
      <LabelField
        className="product-test-protocal-signature--label"
        containerClass="protocal-test-result"
        label={
          <CheckBoxLabelField>
            <FormattedMessage
              id="detergent.approved"
              defaultMessage="Approved with constraints"
            />
          </CheckBoxLabelField>
        }
      />
      <section className="protocol-signature" style={Style.container}>
        <section>
          <FormattedMessage
            id="detergent.dateSingnature"
            defaultMessage="Date/Signature"
          />
        </section>
        <FillableNote>
          <FormattedMessage
            id="detergent.tester.production"
            defaultMessage="Tester production"
          />
        </FillableNote>
        <FillableNote>
          <FormattedMessage
            id="detergent.test.qualityControl"
            defaultMessage="Test quality control"
          />
        </FillableNote>
        <FillableNote>
          <FormattedMessage
            id="common.statusEnumApproved"
            defaultMessage="Approved"
          />
        </FillableNote>
      </section>
    </section>
  );
};

export default TestResult;
