export const GET_SAP_PRICE = 'GET_SAP_PRICE';
export const GET_SAP_PRICE_SUCCESS = 'GET_SAP_PRICE_SUCCESS';
export const GET_SAP_PRICE_ERROR = 'GET_SAP_PRICE_ERROR';

// Fetch SAP PRICE
export const getSapPrice = options => ({
  type: GET_SAP_PRICE,
  payload: options
});

export const getSapPriceSuccess = payload => ({
  type: GET_SAP_PRICE_SUCCESS,
  payload
});

export const getSapPriceError = payload => ({
  type: GET_SAP_PRICE_ERROR,
  payload
});
