import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  TYPE_FORMULA_FILTER,
  ACTION_BUTTON_TYPES,
  FORMULA_STATUS,
  DISPLAY_FORMULA_STATUS,
  STATUS_ENUM,
  FORMULA_FORM_STATUS_TYPE
} from '../../../../configs/constant';
import {
  patchFormula,
  setSelectedDropdownFieldForDetergent,
  checkRawMaterialBlock
} from '../../data/detergent/actions';
import EditStatusForm from '../../../../components/EditStatus/index';
import { showErrorToast } from '../../../../services/toasterService';

const displayStatusType = type => {
  let statusClass = ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER;
  const displayStatus = DISPLAY_FORMULA_STATUS[type];
  if (type === STATUS_ENUM.APPROVED) {
    statusClass += ' formula-status-approved';
  } else if (type === STATUS_ENUM.INPROGRESS) {
    statusClass += ' formula-status-inprogress';
  }
  return <span className={statusClass}>{displayStatus}</span>;
};

const EditField = props => {
  const {
    isEditable,
    record,
    editableHandler,
    isSample,
    isExpandListElement,
    currentFormulasStatus,
    currentFormulasSample,
    field,
    form
  } = props;
  const dispatch = useDispatch();
  const { allRawMaterial, listRawMateriaByFormula } = useSelector(state => ({
    allRawMaterial: state.rawMaterials.allRawMaterials.list,
    listRawMateriaByFormula: state.detergent.rawMaterialByDetergentId.data
  }));

  const { formatMessage } = useIntl();
  const val = useRef('');
  const editform = useRef('');
  useEffect(() => {
    let errorBlockRawMaterialMessage = '';
    const existedRawMaterialNames = [];
    let arrList = [];
    const rawMaterialNon = allRawMaterial.map(item => {
      return item.id;
    });
    if (listRawMateriaByFormula.length !== undefined) {
      arrList = listRawMateriaByFormula;
    }
    arrList.map(element => {
      if (rawMaterialNon.indexOf(element.rawMaterialId) === -1) {
        existedRawMaterialNames.push(element.rawMaterial.name);
      }
    });
    if (val.current !== '' || editform.current !== '') {
      if (listRawMateriaByFormula.length !== undefined) {
        if (existedRawMaterialNames.length > 0) {
          const translateConfig = [
            {
              id: 'detergent.messageBlock',
              defaultMessage:
                '“{name}” is used in this formula but it is blocked. Please unblock this “{name}” berfore changing status of formula.'
            },
            { name: existedRawMaterialNames.join(', ') }
          ];
          errorBlockRawMaterialMessage = formatMessage(...translateConfig);
          showErrorToast(errorBlockRawMaterialMessage);
          editform.current.resetFields();
          editableHandler();
        } else {
          onEditStatusSaveChangesHandler(val.current, editform.current);
        }
        val.current = '';
        editform.current = '';
      }
    }
  }, [listRawMateriaByFormula]);

  const detergentID = isExpandListElement ? record.detergentId : record.id;

  const formulaID = isExpandListElement
    ? record.id
    : _.get(record, 'formulas.0.id', '');
  const formula = isExpandListElement ? record : record?.formulas?.[0];

  const currentInitialValue = isSample
    ? currentFormulasSample
    : currentFormulasStatus;

  const currentFormulaSampleIndex = _.findIndex(
    TYPE_FORMULA_FILTER,
    sample => sample.name === currentFormulasSample
  );

  const restrictedSampleList = TYPE_FORMULA_FILTER.filter(
    (sample, index) => index !== currentFormulaSampleIndex
  );

  const restrictedStatusList = FORMULA_STATUS.filter(status => {
    return status.value !== currentFormulasStatus;
  });

  const selectListValue = isSample
    ? restrictedSampleList
    : restrictedStatusList;
  const onEditStatusSaveChangesHandler = (values, editForm) => {
    const data = isSample
      ? { sample: values, status: formula?.status }
      : { status: values, sample: formula.sample };
    data.containerSize = formula?.containerSize;
    dispatch(
      patchFormula({
        id: detergentID,
        formulaId: formulaID,
        data
      })
    );

    editForm.resetFields();
    editableHandler();
  };
  const unBlockFormula = (values, editForm) => {
    dispatch(checkRawMaterialBlock(formulaID));
    val.current = values;
    editform.current = editForm;
  };
  const onCancelChangesHandler = () => {
    editableHandler();
  };

  const displaySelectItemName = displayName =>
    isSample ? displayName : displayStatusType(displayName);

  return (
    // import fields change form
    <EditStatusForm
      currentInitialValue={currentInitialValue}
      editableHandler={editableHandler}
      field={field}
      isEditable={isEditable}
      onSaveChangesHandler={unBlockFormula}
      onCancelChangesHandler={onCancelChangesHandler}
      selectList={selectListValue}
      displaySelectItemName={displaySelectItemName}
      form={form}
      classActionTypes={ACTION_BUTTON_TYPES.UPDATE_FORMULA_STATUS_ACTIONS}
    />
  );
};

const FormulaEditForm = props => {
  const { isSample, record, isExpandListElement, currentId } = props;

  const dispatch = useDispatch();

  const { selectedFormulaRowId, selectedFormulaRowType } = useSelector(
    state => ({
      selectedFormulaRowId:
        state.detergent.selectedChangeFormulaId.selectedFormulaRowId,
      selectedFormulaRowType:
        state.detergent.selectedChangeFormulaId.selectedFormulaRowType
    })
  );

  const type = isSample
    ? FORMULA_FORM_STATUS_TYPE.SAMPLE
    : FORMULA_FORM_STATUS_TYPE.STATUS;

  const currentFormulasSample = isExpandListElement
    ? record.sample
    : _.get(record, 'formulas.0.sample', '');

  const currentFormulasStatus = isExpandListElement
    ? record.status
    : _.get(record, 'formulas.0.status', '');

  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    setIsEditable(
      selectedFormulaRowId !== currentId || selectedFormulaRowType !== type
    );
  }, [selectedFormulaRowId, selectedFormulaRowType]);

  const editableHandler = () => {
    setIsEditable(!isEditable);
  };

  const openChangeFormulaStatusForm = () => {
    dispatch(
      setSelectedDropdownFieldForDetergent({
        selectedFormulaRowId: currentId,
        selectedFormulaRowType: type
      })
    );
    editableHandler();
  };

  const renderEditButton = () => {
    const buttonClassName = `${
      ACTION_BUTTON_TYPES.UPDATE_FORMULA_STATUS_ACTIONS
    } ${
      isSample
        ? ACTION_BUTTON_TYPES.EDIT_DETERGENT_FORMULA_SAMPLE
        : ACTION_BUTTON_TYPES.EDIT_DETERGENT_FORMULA_STATUS
    }`;

    const isInvisible = !isEditable ? 'hidden' : '';

    return (
      <img
        alt=""
        onClick={openChangeFormulaStatusForm}
        className={`${buttonClassName} ${isInvisible} formula-edit-icon`}
        src="/icon/ic-edit.svg"
      />
    );
  };

  return (
    <div className="sample-wrapper">
      <EditFormulaField
        record={record}
        isSample={isSample}
        isEditable={isEditable}
        editableHandler={editableHandler}
        currentFormulasSample={currentFormulasSample}
        currentFormulasStatus={currentFormulasStatus}
        isExpandListElement={isExpandListElement}
        field={type}
      />
      {renderEditButton()}
    </div>
  );
};

EditField.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  record: PropTypes.shape({}).isRequired,
  editableHandler: PropTypes.func.isRequired,
  isSample: PropTypes.bool.isRequired,
  isExpandListElement: PropTypes.bool.isRequired,
  currentFormulasSample: PropTypes.string.isRequired,
  currentFormulasStatus: PropTypes.string.isRequired,
  form: PropTypes.shape({}).isRequired,
  field: PropTypes.string.isRequired
};

FormulaEditForm.propTypes = {
  isSample: PropTypes.bool.isRequired,
  isExpandListElement: PropTypes.bool.isRequired,
  record: PropTypes.shape({}).isRequired,
  currentId: PropTypes.number.isRequired
};

const EditFormulaField = Form.create({ name: 'update-formula' })(EditField);

export default FormulaEditForm;
