import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Icon, Upload, Button, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayLoading } from '../../../../../components/Loading';
import { getAnnualDemandYears } from '../../../../../utils/common';
import { migrateAnnualDemand } from '../../../data/partNumbers/actions';
import { csvContentType } from '../../../Util/util';

const MigrateAnnualDemandForm = props => {
  const {
    form: { getFieldDecorator, validateFields },
    onCancel
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { modalLoading } = useSelector(state => ({
    modalLoading: state.partNumbers.modal.loading
  }));

  const originYearList = getAnnualDemandYears();

  const [fileList, setFileList] = useState([]);

  const uploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([file]);
      return false;
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const uploadFile = {
          name: `import/document_${new Date().getTime()}_${
            values.annualDemand[0].name
          }`,
          contentType: csvContentType,
          file: values.annualDemand[0].originFileObj
        };

        values.uploadFile = uploadFile;
        dispatch(migrateAnnualDemand(values));
      }
    });
  };

  return (
    <OverlayLoading loading={modalLoading}>
      <Form
        className="migrate-annual-demand"
        {...formItemLayout}
        onSubmit={onSubmit}>
        <Form.Item
          label={
            <FormattedMessage
              id="partNumbers.columnYear"
              defaultMessage="Year"
            />
          }>
          {getFieldDecorator('year', {
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'partNumber.validate.annualDemandYears',
                  defaultMessage: 'Annual demand year is required'
                })
              }
            ]
          })(
            <Select
              placeholder={
                <FormattedMessage
                  id="partNumber.form.selectAYear"
                  defaultMessage="Select a year"
                />
              }
              suffixIcon={<Icon type="caret-down" />}>
              {originYearList.map(year => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <FormattedMessage
              id="common.uploadFiles"
              defaultMessage="Select file"
            />
          }>
          {getFieldDecorator('annualDemand', {
            valuePropName: 'fileList',
            getValueFromEvent: e => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            },
            rules: [
              {
                required: true,
                message: formatMessage({
                  id: 'partNumber.validate.annualDemandFile',
                  defaultMessage: 'Please select a file!'
                })
              }
            ]
          })(
            <Upload {...uploadProps}>
              <Button
                className="migrate-annual-demand--upload"
                disabled={fileList.length >= 1}>
                <Icon className="upload-icon" type="upload" />
                <div className="text">
                  {' '}
                  <FormattedMessage
                    id="common.uploadFiles"
                    defaultMessage="Select File"
                  />
                </div>
              </Button>
            </Upload>
          )}
        </Form.Item>
        <div className="form-btn-actions">
          <Button className="btn-cancel" onClick={onCancel}>
            <FormattedMessage
              id="common.cancelButton"
              defaultMessage="CANCEL"
            />
          </Button>
          <Button className="btn-save" type="primary" htmlType="submit">
            <FormattedMessage id="common.saveButton" defaultMessage="SAVE" />
          </Button>
        </div>
      </Form>
    </OverlayLoading>
  );
};

MigrateAnnualDemandForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export default Form.create({ name: 'migrate-annual-demand-form' })(
  MigrateAnnualDemandForm
);
