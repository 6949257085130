import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getDetergentProducersApi,
  addDetergentProducerApi,
  updateDetergentProducerApi,
  getAllDetergentProducerApi
} from './api';
import {
  GET_DETERGENT_PRODUCERS,
  getDetergentProducersSuccess,
  getDetergentProducersError,
  CREATE_DETERGENT_PRODUCERS,
  createDetergentProducersSuccess,
  createDetergentProducersError,
  UPDATE_DETERGENT_PRODUCERS,
  updateDetergentProducersSuccess,
  updateDetergentProducersError,
  GET_ALL_DETERGENT_PRODUCERS,
  getAllDetergentProducersSuccess,
  getAllDetergentProducersError
} from './actions';

import { showSuccessToast } from '../../../../services/toasterService';
import { errorHandle } from '../../../../utils/common';
import { BASE_DB_FIELDS } from '../../../../configs/constant';

// Fetch List API
export function* getDetergentProducersSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    if (
      !action.payload.modalLoadLatestDataAfterCreateOrUpdate &&
      order.hasOwnProperty(BASE_DB_FIELDS.UPDATE_AT)
    ) {
      delete order[BASE_DB_FIELDS.UPDATE_AT];
    }

    const response = yield getDetergentProducersApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        name: action.payload.searchByText
      }),
      filter: JSON.stringify(action.payload.filterList || ''),
      order: JSON.stringify(order)
    });
    yield put(getDetergentProducersSuccess(response.data));
  } catch (error) {
    yield put(getDetergentProducersError(error));
    errorHandle(error);
  }
}

// Create Producer
export function* createDetergentProducerSaga(action) {
  try {
    const response = yield addDetergentProducerApi(action.payload);
    yield put(createDetergentProducersSuccess(response.data));
    showSuccessToast('common.success.createDetergentProducer');
  } catch (error) {
    yield put(createDetergentProducersError(error));
    errorHandle(error);
  }
}

// update Producer
export function* updateDetergentProducerSaga(action) {
  try {
    const response = yield updateDetergentProducerApi(
      action.payload.id,
      action.payload.producer
    );
    yield put(updateDetergentProducersSuccess(response.data));
    showSuccessToast('common.success.updateDetergentProducer');
  } catch (error) {
    yield put(updateDetergentProducersError(error));
    errorHandle(error);
  }
}

export function* getAllDetergentProducerSaga() {
  try {
    const response = yield getAllDetergentProducerApi();
    yield put(getAllDetergentProducersSuccess(response.data.data));
  } catch (error) {
    yield put(getAllDetergentProducersError(error));
    errorHandle(error);
  }
}

export default function* detergentProducerSaga() {
  yield takeLatest(UPDATE_DETERGENT_PRODUCERS, updateDetergentProducerSaga);
  yield takeLatest(GET_DETERGENT_PRODUCERS, getDetergentProducersSaga);
  yield takeLatest(CREATE_DETERGENT_PRODUCERS, createDetergentProducerSaga);
  yield takeLatest(GET_ALL_DETERGENT_PRODUCERS, getAllDetergentProducerSaga);
}
