import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '../../../../components/BaseModal';
import { RawMaterialForm } from '../Form';
import { RawMaterialDetail } from '../ViewDetail/RawMaterialDetail';
import { FORM_STATUS } from '../../../../configs/constant';

export const RawMaterialModal = ({
  title,
  status,
  isVisible,
  onCancel,
  onEditWhenOnViewDetail
}) => {
  return (
    <BaseModal
      title={title}
      width="58%"
      maskClosable={false}
      visible={isVisible}
      className="raw-material-common-modal"
      onCancel={() => onCancel()}>
      {status === FORM_STATUS.VIEW_DETAIL
        ? isVisible && (
            <RawMaterialDetail
              onCancel={() => onCancel()}
              onEdit={() => onEditWhenOnViewDetail()}
            />
          )
        : isVisible && (
            <RawMaterialForm status={status} onCancel={() => onCancel()} />
          )}
    </BaseModal>
  );
};

RawMaterialModal.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditWhenOnViewDetail: PropTypes.func
};

RawMaterialModal.defaultProps = {
  onEditWhenOnViewDetail: () => {}
};
