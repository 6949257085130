import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Radio, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFormFieldKey } from '../../../Util/productionCycle/getFormFieldKey';
import { getCheckboxConfig } from '../../../../../../utils/common';
import { disableRowBySelectedProductionCyle } from '../util/disableRowBySelectedProductionCyle';

export const DetergentDevelopment = ({
  form,
  record,
  onSendEmailToSafetyTeam,
  selectedProductionCycle,
  isInactivePartNumber
}) => {
  const { areSendingToSafetyTeamForProductionCycleIds } = useSelector(
    state => ({
      areSendingToSafetyTeamForProductionCycleIds:
        state.detergent.mail.safetyTeam
          .areSendingToSafetyTeamForProductionCycleIds
    })
  );

  return (
    <>
      <div className="row-item">
        <span className="title">
          <FormattedMessage
            id="productionCycle.detergentDevelopment"
            defaultMessage="Detergent Development"
          />
        </span>
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'formulaReviewed'),
          getCheckboxConfig(record?.formulaReviewed)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.formulaReviewed"
              defaultMessage="1. Formula reviewed"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'productionInstructionsEntered'),
          getCheckboxConfig(record?.productionInstructionsEntered)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.productionInstructionsEntered"
              defaultMessage="2. Production instructions entered"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.necessaryProtocols"
            defaultMessage="3. Necessary protocols"
          />
        </span>
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(
            record,
            'necessaryProtocols.initialProductionTestProtocol'
          ),
          getCheckboxConfig(
            record?.necessaryProtocols?.initialProductionTestProtocol
          )
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.initialProductionTestProtocol"
              defaultMessage="Initial production test protocol"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'necessaryProtocols.productionTestProtocol'),
          getCheckboxConfig(record?.necessaryProtocols?.productionTestProtocol)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.productionTestProtocol"
              defaultMessage="Production test protocol"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.inCaseOfInitialProduction"
            defaultMessage="4. In case of initial production: Presence of detergent development required?"
          />
        </span>
      </div>

      <div className="row-item radio-group-vertical">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'presenceOfDetergentDevelopment'),
          {
            initialValue: record?.presenceOfDetergentDevelopment
          }
        )(
          <Radio.Group
            className="radio-group"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <Radio className="radio-item" value>
              <FormattedMessage id="productionCycle.yes" defaultMessage="Yes" />
            </Radio>
            <Radio className="radio-item" value={false}>
              <FormattedMessage id="productionCycle.no" defaultMessage="No" />
            </Radio>
          </Radio.Group>
        )}
      </div>

      <div className="row-item level-2">
        <span>
          <FormattedMessage
            id="productionCycle.rawMaterialsThatAreNotUsedAnymore"
            defaultMessage="5. Raw materials that are not used anymore:"
          />
        </span>
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(
            record,
            'rawMaterialsNotUsedAnymore.useUpInPreviousFormulaVersion'
          ),
          getCheckboxConfig(
            record?.rawMaterialsNotUsedAnymore?.useUpInPreviousFormulaVersion
          )
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.useUpInPreviousFormulaVersion"
              defaultMessage="Use up in previous formula version"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(
            record,
            'rawMaterialsNotUsedAnymore.useInOtherFormulas'
          ),
          getCheckboxConfig(
            record?.rawMaterialsNotUsedAnymore?.useInOtherFormulas
          )
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.useInOtherFormulas"
              defaultMessage="Use in other formulas"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(record, 'rawMaterialsNotUsedAnymore.doNotUseAnymore'),
          getCheckboxConfig(record?.rawMaterialsNotUsedAnymore?.doNotUseAnymore)
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.doNotUseAnymoreDispose"
              defaultMessage="Do not use anymore (dispose)"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item level-3">
        {form.getFieldDecorator(
          getFormFieldKey(
            record,
            'rawMaterialsNotUsedAnymore.rawMaterialsAreNotAffected'
          ),
          getCheckboxConfig(
            record?.rawMaterialsNotUsedAnymore?.rawMaterialsAreNotAffected
          )
        )(
          <Checkbox
            className="checkbox-square"
            disabled={
              disableRowBySelectedProductionCyle(
                selectedProductionCycle,
                record
              ) || isInactivePartNumber
            }>
            <FormattedMessage
              id="productionCycle.rawMaterialsAreNotAffected"
              defaultMessage="Raw materials are not affected"
            />
          </Checkbox>
        )}
      </div>

      <div className="row-item">
        <Button
          className="yellow-button"
          icon="right"
          disabled={
            areSendingToSafetyTeamForProductionCycleIds.includes(record.id) ||
            disableRowBySelectedProductionCyle(
              selectedProductionCycle,
              record
            ) ||
            isInactivePartNumber
          }
          loading={areSendingToSafetyTeamForProductionCycleIds.includes(
            record.id
          )}
          onClick={() => onSendEmailToSafetyTeam(record)}>
          <FormattedMessage
            id="productionCycle.forwardToSafetyTeam"
            defaultMessage="Forward to safety team"
          />
        </Button>
      </div>
    </>
  );
};

DetergentDevelopment.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.shape(PropTypes.any).isRequired,
  selectedProductionCycle: PropTypes.shape({}),
  isInactivePartNumber: PropTypes.bool.isRequired,
  onSendEmailToSafetyTeam: PropTypes.func.isRequired
};

DetergentDevelopment.defaultProps = {
  selectedProductionCycle: undefined
};
