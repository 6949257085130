import { AxiosService } from '../../../../services/axiosService';

export const getPartNumberByIdApi = id => {
  return AxiosService.get(`/part-number/${id}`);
};

export const getPartNumberHistoryApi = (id, pagination) => {
  return AxiosService.get(
    `part-number/${id}/changes`,
    {},
    {
      pagination
    }
  );
};

export const createPartNumberApi = data => {
  return AxiosService.post('part-number', data);
};

export const getPartNumberApi = options => {
  return AxiosService.get(
    'part-number',
    {
      search: options.searchByText,
      order: options.order,
      filter: options.filter
    },
    {
      pagination: options.pagination
    }
  );
};

export const updatePartNumberApi = (partNumberId, data) => {
  return AxiosService.put(`part-number/${partNumberId}`, data);
};

export const patchPartNumberApi = (partNumberId, data) => {
  return AxiosService.patch(`part-number/${partNumberId}`, data);
};

export const migrateAnnualDemandApi = data => {
  return AxiosService.post('annual-demand', data);
};
