import React from 'react';
import { FormattedMessage } from 'react-intl';
import LabelField from '../Util/LabelField';
import Style from '../Util/style';

const MetaTable = () => {
  return (
    <section style={Style.container}>
      <table className="chemical-qc-form">
        <tr className="chemical-qc-form-tr">
          <td>
            <FormattedMessage id="formula.form" defaultMessage="Form" />
          </td>
          <td className="chemical-qc-form-td">
            <input type="checkbox" />
            <FormattedMessage id="detergent.powder" defaultMessage="Powder" />
          </td>
          <td className="chemical-qc-form-td">
            <input type="checkbox" />
            <FormattedMessage id="detergent.liquid" defaultMessage="Liquid" />
          </td>
        </tr>
        <tr className="chemical-qc-form-tr">
          <td> </td>
          <td className="chemical-qc-form-td">
            <input type="checkbox" />
            <FormattedMessage id="detergent.paste" defaultMessage="Paste" />
          </td>
          <td className="chemical-qc-form-td">
            <input type="checkbox" />
            <FormattedMessage id="detergent.gel" defaultMessage="Gel" />
          </td>
        </tr>
      </table>

      <section className="chemical-qc-form-attr">
        <LabelField
          containerClass="chemical-qc-meta-data-container"
          label={
            <FormattedMessage id="detergent.colour" defaultMessage="Colour:" />
          }
          fillableClass="chemical-qc-form-attr-label"
        />
        <LabelField
          containerClass="chemical-qc-meta-data-container"
          label={
            <FormattedMessage id="detergent.smell" defaultMessage="Smell:" />
          }
          fillableClass="chemical-qc-form-attr-label"
        />
        <LabelField
          containerClass="chemical-qc-meta-data-container"
          label={
            <FormattedMessage
              id="detergent.coagulation"
              defaultMessage="Coagulation/clouding:"
            />
          }
          fillableClass="chemical-qc-form-attr-label"
        />
      </section>
    </section>
  );
};

export default MetaTable;
