import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Icon, Row, Col, Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  RAW_MATERIAL_STATUS,
  COMMODITY_GROUPS,
  DISPLAY_COUNTRY
} from '../../../../configs/constant';
import { getRawMaterials } from '../../data/rawMaterial/action';
import FilterableSelect from '../../../../components/FilterableSelect';
import useCountriesByRole from '../../../../hooks/useCountriesByRole';

const _FilterFormRawMaterial = props => {
  const {
    intl: { formatMessage },
    form: { getFieldDecorator, resetFields, validateFields },
    onCancel
  } = props;

  const dispatch = useDispatch();

  const countries = useCountriesByRole();

  const {
    allProducersSuppliers,
    allRawMaterialGroups,
    filterList,
    pagination,
    searchByText,
    order
  } = useSelector(state => ({
    allProducersSuppliers:
      state.rawMaterialProducers.allProducersSuppliers.list,
    allRawMaterialGroups: state.rawMaterialGroup.allRawMaterialGroups.list,
    filterList: state.rawMaterials.filterList,
    pagination: state.rawMaterials.list.data.pagination,
    searchByText: state.rawMaterials.searchByText,
    order: state.rawMaterials.order
  }));

  useEffect(() => {}, []);

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onCancel();
        values.rawMaterialProducer =
          values.rawMaterialProducer === '' ? '' : +values.rawMaterialProducer;
        values.rawMaterialSupplier =
          values.rawMaterialSupplier === '' ? '' : +values.rawMaterialSupplier;
        values.rawMaterialGroup =
          values.rawMaterialGroup === '' ? '' : +values.rawMaterialGroup;
        pagination.current = 1;
        dispatch(
          getRawMaterials({
            pagination,
            searchByText,
            order,
            filterList: values
          })
        );
      }
    });
  };

  const onClearFilter = () => {
    resetFields();
    const keys = Object.keys(filterList);
    keys.forEach(element => {
      filterList[element] = '';
    });
    pagination.current = 1;
    dispatch(getRawMaterials({ pagination, searchByText, order, filterList }));
    onCancel();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 6 },
      lg: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 18 },
      lg: { span: 15 }
    }
  };
  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onSubmit={handleSubmit}>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.filterRawMaterialProducer',
          defaultMessage: 'Raw material producer'
        })}>
        {getFieldDecorator('rawMaterialProducer', {
          initialValue: filterList.rawMaterialProducer
            ? filterList.rawMaterialProducer
            : undefined
        })(
          <FilterableSelect
            placeholder={
              <FormattedMessage
                id="rawMaterial.selectProducers"
                defaultMessage="Select producer"
              />
            }
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {allProducersSuppliers.map(psItem => (
              <Select.Option key={psItem.id} value={psItem.id}>
                {psItem.name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.filterRawMaterialSupplier',
          defaultMessage: 'Raw material supplier'
        })}>
        {getFieldDecorator('rawMaterialSupplier', {
          initialValue: filterList.rawMaterialSupplier
            ? filterList.rawMaterialSupplier
            : undefined
        })(
          <FilterableSelect
            placeholder={
              <FormattedMessage
                id="rawMaterial.selectSuppliers"
                defaultMessage="Select supplier"
              />
            }
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {allProducersSuppliers.map(psItem => (
              <Select.Option key={psItem.id} value={psItem.id}>
                {psItem.name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.rawMaterialGroup',
          defaultMessage: 'Raw material groups'
        })}>
        {getFieldDecorator('rawMaterialGroup', {
          initialValue: filterList.rawMaterialGroup
            ? filterList.rawMaterialGroup
            : undefined
        })(
          <FilterableSelect
            placeholder={
              <FormattedMessage
                id="rawMaterial.selectRawMaterialGroups"
                defaultMessage="Select raw material group"
              />
            }
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {allRawMaterialGroups.map(psItem => (
              <Select.Option key={psItem.id} value={psItem.id}>
                {psItem.name}
              </Select.Option>
            ))}
          </FilterableSelect>
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.casNumber',
          defaultMessage: 'CAS number'
        })}>
        {getFieldDecorator('casNumber', {
          initialValue: filterList.casNumber ? filterList.casNumber : undefined
        })(
          <Input
            placeholder={formatMessage({
              id: 'rawMaterial.enterCASNumber',
              defaultMessage: 'Enter CAS number'
            })}
          />
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.einecNumber',
          defaultMessage: 'Einecs number'
        })}>
        {getFieldDecorator('einecsNumber', {
          initialValue: filterList.einecsNumber
            ? filterList.einecsNumber
            : undefined
        })(
          <Input
            placeholder={formatMessage({
              id: 'rawMaterial.enterEinecsNumber',
              defaultMessage: 'Enter einecs number'
            })}
          />
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.columnStatus',
          defaultMessage: 'Status'
        })}>
        <div id="rawMaterial-filter-status">
          {getFieldDecorator('status', {
            initialValue: filterList.status ? filterList.status : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('rawMaterial-filter-status')
              }
              placeholder={
                <FormattedMessage
                  id="partNumbers.selectStatus"
                  defaultMessage="Select status"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {RAW_MATERIAL_STATUS.map(psItem => (
                <Select.Option key={psItem.value} value={psItem.value}>
                  {psItem.nameDisplay}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>
      <Form.Item
        label={formatMessage({
          id: 'rawMaterial.commodityGroup',
          defaultMessage: 'Commodity group'
        })}>
        <div id="rawMaterial-commodity-group">
          {getFieldDecorator('commodityGroup', {
            initialValue: filterList.commodityGroup
              ? filterList.commodityGroup
              : undefined
          })(
            <Select
              getPopupContainer={() =>
                document.getElementById('rawMaterial-commodity-group')
              }
              placeholder={
                <FormattedMessage
                  id="rawMaterial.selectCommodityGroups"
                  defaultMessage="Select commodity group"
                />
              }
              suffixIcon={<Icon type="caret-down" />}
              loading>
              {COMMODITY_GROUPS.map(psItem => (
                <Select.Option key={psItem.id} value={psItem.id}>
                  {psItem.value}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </Form.Item>

      <Form.Item
        label={formatMessage({
          id: 'common.fromCountry',
          defaultMessage: 'Country'
        })}>
        {getFieldDecorator('fromCountryId', {
          initialValue: filterList.fromCountryId
            ? filterList.fromCountryId
            : undefined
        })(
          <Select
            getPopupContainer={triggerNode => triggerNode}
            placeholder={formatMessage({
              id: 'common.selectCountry',
              defaultMessage: 'Select country'
            })}
            suffixIcon={<Icon type="caret-down" />}
            loading>
            {countries.map(country => (
              <Select.Option key={country.value} value={country.value}>
                {DISPLAY_COUNTRY[country.value]}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Row gutter={24}>
        <Col span={24}>
          <div className="form-btn-actions">
            <Button className="btn-cancel" onClick={onCancel}>
              {formatMessage({
                id: 'common.cancelButton',
                defaultMessage: 'CANCEL'
              })}
            </Button>
            <Button className="btn-cancel" onClick={() => onClearFilter()}>
              {formatMessage({
                id: 'common.clearFilter',
                defaultMessage: 'CLEAR FILTER'
              })}
            </Button>
            <Button className="btn-save" type="primary" htmlType="submit">
              {formatMessage({ id: 'common.okButton', defaultMessage: 'OK' })}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

_FilterFormRawMaterial.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    resetFields: PropTypes.func,
    validateFields: PropTypes.func
  }),
  onCancel: PropTypes.func
};

_FilterFormRawMaterial.defaultProps = {
  intl: {
    formatMessage: () => {}
  },
  form: {
    getFieldDecorator: () => {},
    resetFields: () => {},
    validateFields: () => {}
  },
  onCancel: () => {}
};

export const FilterFormRawMaterial = Form.create({
  name: 'filter-raw-material-form'
})(injectIntl(_FilterFormRawMaterial));
