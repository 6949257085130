/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { ACTION_BUTTON_TYPES } from '../../../../configs/constant';
import Actions from './Actions';

export const renderIcons = props => {
  const { onExpand, expandable, expanded, record, selectedRowKeys } = props;
  const isCollapse = expandable && !expanded;

  let imgSrc = 'arrow-collapse';
  if (isCollapse) {
    imgSrc = 'arrow-expand';
  }
  const btn = (
    <>
      <img
        onClick={e => onExpand(record, e)}
        className="action-item action-item-visible"
        alt=""
        src={`/icon/${imgSrc}.svg`}
      />
      <img
        className={`${ACTION_BUTTON_TYPES.SHOW_DETERGENT_ROW} action-item action-item-visible-bold`}
        onClick={e => onExpand(record, e)}
        alt=""
        src={`/icon/${imgSrc}-bold.svg`}
      />
    </>
  );

  return (
    <div className="action-col">
      <Actions
        isSubList={false}
        record={record}
        selectedRowKeys={selectedRowKeys}
      />
      {record.formulas.length > 1 ? (
        btn
      ) : (
        <span className="action-btn-margin-left" />
      )}
    </div>
  );
};

renderIcons.propTypes = {
  onExpand: PropTypes.func.isRequired,
  expandable: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    formulas: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired
};
