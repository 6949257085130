import {
  GET_RAW_MATERIAL_GROUP,
  GET_RAW_MATERIAL_GROUP_SUCCESS,
  GET_RAW_MATERIAL_GROUP_ERROR,
  UPDATE_RAW_MATERIAL_GROUP,
  CREATE_RAW_MATERIAL_GROUP,
  CREATE_RAW_MATERIAL_GROUP_SUCCESS,
  CREATE_RAW_MATERIAL_GROUP_ERROR,
  UPDATE_RAW_MATERIAL_GROUP_SUCCESS,
  UPDATE_RAW_MATERIAL_GROUP_ERROR,
  GROUP_MODAL,
  GET_ALL_RAW_MATERIAL_GROUP,
  GET_ALL_RAW_MATERIAL_GROUP_SUCCESS,
  GET_ALL_RAW_MATERIAL_GROUP_ERROR,
  SET_RAW_MATERIAL_GROUP_FETCHING,
  CLEAR_RAW_MATERIAL_GROUP_DATA_WHEN_UNMOUNT
} from './action.js';

const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    }
  },
  allRawMaterialGroups: {
    fetching: false,
    list: []
  },
  fetching: false,
  searchByText: '',
  order: {
    name: 'ASC',
    status: ''
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    countsRefeshList: 0
  },
  emptyGroup: {
    name: '',
    fromCountryId: '',
    group: ''
  },
  filterList: {
    fromCountryId: ''
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RAW_MATERIAL_GROUP:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          }
        },
        fetching: true,
        searchByText: action.payload.searchByText || '',
        order: action.payload.order || initialState.order,
        filterList: action.payload.filterList || initialState.filterList,
        modal: {
          ...state.modal
        }
      };
    case GET_RAW_MATERIAL_GROUP_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false
      };
    case GET_RAW_MATERIAL_GROUP_ERROR:
      return {
        ...state,
        fetching: false
      };
    case CREATE_RAW_MATERIAL_GROUP:
      return {
        ...state,
        emptyGroup: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_RAW_MATERIAL_GROUP_SUCCESS:
      return {
        ...state,
        emptyGroup: initialState.emptyGroup,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case CREATE_RAW_MATERIAL_GROUP_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case UPDATE_RAW_MATERIAL_GROUP:
      return {
        ...state,
        emptyGroup: action.payload.group,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_RAW_MATERIAL_GROUP_SUCCESS:
      return {
        ...state,
        emptyGroup: initialState.emptyGroup,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case UPDATE_RAW_MATERIAL_GROUP_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case GROUP_MODAL:
      return {
        ...state,
        emptyGroup: action.payload.group || initialState.emptyGroup,
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false
        }
      };
    case GET_ALL_RAW_MATERIAL_GROUP:
      return {
        ...state,
        allRawMaterialGroups: {
          ...state.allRawMaterialGroups,
          list: [],
          fetching: true
        }
      };
    case GET_ALL_RAW_MATERIAL_GROUP_SUCCESS:
      return {
        ...state,
        allRawMaterialGroups: {
          ...state.allRawMaterialGroups,
          list: action.payload,
          fetching: false
        }
      };
    case GET_ALL_RAW_MATERIAL_GROUP_ERROR:
      return {
        ...state,
        allRawMaterialGroups: {
          ...state.allRawMaterialGroups,
          fetching: false
        }
      };
    case SET_RAW_MATERIAL_GROUP_FETCHING:
      return {
        ...state,
        fetching: action.payload
      };
    case CLEAR_RAW_MATERIAL_GROUP_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
