import { STATUS_ENUM } from '../../../../../../configs/constant';

export const checkInactivePartNumber = (
  productionCycles,
  productionCycleKey,
  partNumbers
) => {
  let isExistedInactivePartNumber = false;
  const selectedPartNumberIds =
    productionCycles[productionCycleKey]?.partNumbers;

  selectedPartNumberIds.forEach(partNumberId => {
    const partNumber = partNumbers.find(p => p.id === partNumberId);
    if (partNumber.status === STATUS_ENUM.IN_ACTIVE) {
      isExistedInactivePartNumber = true;
    }
  });

  return isExistedInactivePartNumber;
};
