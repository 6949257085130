import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import { BaseTable } from '../../../../components/BaseTable';
import BaseExpandableTable from '../../../../components/BaseExpandableTable';
import { detergentColumns as columns, formulaColumns } from './renderColumns';
import { ACTION_BUTTON_TYPES } from '../../../../configs/constant';
import { renderIcons } from './renderActionIcons';
import { changePointToComma } from '../../../../utils/common';
import { sortDocumentsByCategoryAndName } from '../Util/sortFormulaDocuments';

const ListDetergent = props => {
  const { pagination, listDetergent, loading } = useSelector(state => ({
    pagination: state.detergent.list.data.pagination,
    listDetergent: state.detergent.list.data.results,
    loading: state.detergent.fetching
  }));
  const {
    onAddNewFormula,
    onViewDetail,
    onPageChange,
    onPageSizeChange,
    onEditDetergent,
    onSelectedDetergents,
    selectedRowKeys,
    onDuplicateDetergent
  } = props;

  const onViewDetailHandler = (event, record, isSubList, formulaVersionId) => {
    if (typeof event.target.className !== 'string') return;

    const isAddNewFormulaEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.ADD_NEW_FOMULAR
    );

    const isViewDetergentDetailEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.VIEW_DETERGENT_DETAIL
    );

    const isEditDetergentEvent = event.target.className.includes(
      ACTION_BUTTON_TYPES.EDIT_DETERGENTS
    );

    const isUpdateFormulaActionEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.UPDATE_FORMULA_STATUS_ACTIONS
    );

    const isCheckDetergentEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.CHECK_BOX_DETERGENTS
    );

    const isDuplicateDetergentEvent = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.DUPLICATE_DETERGENT
    );

    const recordName = record.name;
    const recordNumber = record.number;

    let currentDetergentVersionFormula = {};
    if (isSubList) {
      const currentRecordFormula = _.find(record.formulas, {
        id: formulaVersionId
      });
      currentDetergentVersionFormula = { ...currentRecordFormula };
    } else {
      currentDetergentVersionFormula = { ...record.formulas[0] };
    }

    const currentRecord = {
      ...record,
      name: recordName,
      number: recordNumber,
      formulas: [
        {
          ...currentDetergentVersionFormula,
          documents: sortDocumentsByCategoryAndName(
            currentDetergentVersionFormula.documents
          )
        }
      ]
    };
    _.set(
      currentRecord,
      'formulas.0.density',
      changePointToComma(currentRecord.formulas[0].density)
    );
    const isPreventOnRowTrigger = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER
    );

    const isOtherClickEvents = event.target.className.indexOf(
      ACTION_BUTTON_TYPES.SELECT_DROPDOWN
    );

    if (isAddNewFormulaEvent !== -1) {
      onAddNewFormula(record);
    } else if (isEditDetergentEvent) {
      onEditDetergent(currentRecord);
    } else if (isViewDetergentDetailEvent !== -1) {
      onViewDetail(currentRecord);
    } else if (isCheckDetergentEvent !== -1) {
      onSelectedDetergents(
        event.target.checked,
        currentRecord,
        currentDetergentVersionFormula.id
      );
    } else if (isDuplicateDetergentEvent !== -1) {
      onDuplicateDetergent(currentRecord);
    } else if (
      isUpdateFormulaActionEvent !== -1 ||
      isOtherClickEvents !== -1 ||
      isPreventOnRowTrigger !== -1
    ) {
      // do nothing
    } else {
      onViewDetail(currentRecord);
    }
  };

  const renderExpandFormularows = record => {
    const recordDetergent = record;
    const detergentFormulasExceptLatestOne = record.formulas.slice(1);
    if (
      Array.isArray(detergentFormulasExceptLatestOne) &&
      detergentFormulasExceptLatestOne.length === 0
    ) {
      return null;
    }

    return (
      <BaseTable
        dataSource={detergentFormulasExceptLatestOne}
        columns={formulaColumns(selectedRowKeys)}
        showHeader={false}
        rowClassName="expanded-rows"
        onRow={currentRow => {
          return {
            onClick: event =>
              onViewDetailHandler(event, recordDetergent, true, currentRow.id)
          };
        }}
      />
    );
  };

  return (
    <div className="table-detergent">
      <BaseExpandableTable
        stickyHeader
        className="fixed-table-layout"
        pagination={pagination}
        onRow={record => {
          return {
            // onClick: event => onClickRow(event, record)
            onClick: event =>
              onViewDetailHandler(event, record, false, record.id)
          };
        }}
        columns={columns}
        loading={loading && { indicator: <Icon type="sync" spin /> }}
        dataSource={listDetergent}
        expandedRowRender={record => renderExpandFormularows(record)}
        expandIcon={expandIconProps =>
          renderIcons({ selectedRowKeys, ...expandIconProps })
        }
        expandIconAsCell={false}
        expandIconColumnIndex={columns.length - 1}
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        bordered
      />
    </div>
  );
};

ListDetergent.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onAddNewFormula: PropTypes.func.isRequired,
  onViewDetail: PropTypes.func.isRequired,
  onEditDetergent: PropTypes.func.isRequired,
  onSelectedDetergents: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  onDuplicateDetergent: PropTypes.func.isRequired
};

export const List = ListDetergent;
