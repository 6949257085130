import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getRawMaterialProducersApi,
  addRawMaterialProducerApi,
  updateRawMaterialProducerApi,
  getAllRawMaterialProducerApi
} from './api';
import {
  GET_RAW_MATERIAL_PRODUCERS,
  getRawMaterialProducersSuccess,
  getRawMaterialProducersError,
  CREATE_RAW_MATERIAL_PRODUCER,
  createRawMaterialProducerSuccess,
  createRawMaterialProducerError,
  UPDATE_RAW_MATERIAL_PRODUCER,
  updateRawMaterialProducerSuccess,
  updateRawMaterialProducerError,
  GET_ALL_RAW_MATERIAL_PRODUCERS,
  getAllRawMaterialProducerSuccess,
  getAllRawMaterialProducerError
} from './actions';

import { showSuccessToast } from '../../../../services/toasterService';

import { errorHandle } from '../../../../utils/common';
import { BASE_DB_FIELDS } from '../../../../configs/constant';

// Fetch List API
export function* getRawMaterialProducersSaga(action) {
  try {
    const order = JSON.parse(JSON.stringify(action.payload.order));
    if (
      !action.payload.modalLoadLatestDataAfterCreateOrUpdate &&
      order.hasOwnProperty(BASE_DB_FIELDS.UPDATE_AT)
    ) {
      delete order[BASE_DB_FIELDS.UPDATE_AT];
    }

    const response = yield getRawMaterialProducersApi({
      pagination: action.payload.pagination,
      searchByText: JSON.stringify({
        name: action.payload.searchByText
      }),

      filter: JSON.stringify(action.payload.filterList || ''),
      order: JSON.stringify(order)
    });
    yield put(getRawMaterialProducersSuccess(response.data));
  } catch (error) {
    yield put(getRawMaterialProducersError(error));
    errorHandle(error);
  }
}

// Create Producer
export function* createRawMaterialProducerSaga(action) {
  try {
    const response = yield addRawMaterialProducerApi(action.payload);
    yield put(createRawMaterialProducerSuccess(response.data));
    showSuccessToast('common.success.createRawMaterialProducer');
  } catch (error) {
    yield put(createRawMaterialProducerError(error));
    errorHandle(error);
  }
}

// update Producer
export function* updateRawMaterialProducerSaga(action) {
  try {
    const response = yield updateRawMaterialProducerApi(
      action.payload.id,
      action.payload.producer
    );
    yield put(updateRawMaterialProducerSuccess(response.data));
    showSuccessToast('common.success.updateRawMaterialProducer');
  } catch (error) {
    yield put(updateRawMaterialProducerError(error));
    errorHandle(error);
  }
}

export function* getAllRawMaterialProducerSaga() {
  try {
    const response = yield getAllRawMaterialProducerApi();
    yield put(getAllRawMaterialProducerSuccess(response.data.data));
  } catch (error) {
    yield put(getAllRawMaterialProducerError(error));
    errorHandle(error);
  }
}

export function* rawMaterialProducerSaga() {
  yield takeLatest(UPDATE_RAW_MATERIAL_PRODUCER, updateRawMaterialProducerSaga);
  yield takeLatest(GET_RAW_MATERIAL_PRODUCERS, getRawMaterialProducersSaga);
  yield takeLatest(CREATE_RAW_MATERIAL_PRODUCER, createRawMaterialProducerSaga);
  yield takeLatest(
    GET_ALL_RAW_MATERIAL_PRODUCERS,
    getAllRawMaterialProducerSaga
  );
}
