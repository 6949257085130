import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getFieldTextByTableName } from '../../Util/getFieldTextByTableName';
import { transformHistoryValue } from '../../Util/transformHistoryValue';
import { formatDateTimeByReactIntl } from '../../../../utils/reactIntlHelpers';

export const renderLogListColumns = intl => [
  {
    title: (
      <FormattedMessage
        id="rawMaterial.columnDatetime"
        defaultMessage="Datetime"
      />
    ),
    key: 'createAt',
    width: '10%',
    render: record => {
      return <div>{formatDateTimeByReactIntl(intl, record.createAt)}</div>;
    }
  },
  {
    title: (
      <FormattedMessage id="rawMaterial.columnAction" defaultMessage="Action" />
    ),
    key: 'action',
    width: '10%',
    render: record => {
      return (
        <span className="text-black">
          <FormattedHTMLMessage
            id={
              record.isAddedNewRecord
                ? 'common.addedNewOne'
                : 'common.madeChanges'
            }
            values={{
              user: record.creatorEmail
            }}
          />
        </span>
      );
    }
  },
  {
    title: <FormattedMessage id="logs.columnObject" defaultMessage="Object" />,
    key: 'object',
    width: '20%',
    render: record => {
      return <span className="green-text">{record.object}</span>;
    }
  },
  {
    title: <FormattedMessage id="logs.columnField" defaultMessage="Field" />,
    key: 'field',
    ellipsis: true,
    width: '20%',
    render: record => {
      if (record.isAddedNewRecord) return '';

      return (
        <div className="div-changes">
          {record.valueJson.map(({ key }) => (
            <div>- {getFieldTextByTableName(intl, record.tableName, key)}</div>
          ))}
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="logs.columnOldValue"
        defaultMessage="Original Value"
      />
    ),
    key: 'oldValue',
    ellipsis: true,
    width: '20%',
    render: record => {
      if (record.isAddedNewRecord) return '';
      return (
        <div className="div-changes">
          {record.valueJson.map(({ oldValue, key }) => (
            <div>- {transformHistoryValue(key, oldValue, intl)}</div>
          ))}
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage id="logs.columnNewValue" defaultMessage="New Value" />
    ),
    key: 'newValue',
    ellipsis: true,
    width: '20%',
    render: record => {
      if (record.isAddedNewRecord) return '';

      return (
        <div className="div-changes">
          {record.valueJson.map(({ newValue, key }) => (
            <div>- {transformHistoryValue(key, newValue, intl)}</div>
          ))}
        </div>
      );
    }
  }
];
