import React from 'react';
import { Col, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getS3FileUrl } from '../../../../../utils/s3Service';
import {
  DETERGENT_FORMULA_DOCUMENT_CATEGORIES,
  FORMATTEDMESSAGE_STATUS,
  DISPLAY_FORMULA_FORM,
  DISPLAY_PROJECT_TYPES
} from '../../../../../configs/constant';

const handleDownloadFile = async (e, key) => {
  e.preventDefault();
  const url = await getS3FileUrl(key);
  window.open(url.data.data);
};

const renderRowFields = (label = '', value = '', isCompared = false) => {
  const labelClassName = isCompared ? 'label label--compare' : 'label';
  const valueClassName = isCompared ? 'value value--compare' : 'value';
  // Check Array Object
  if (Array.isArray(value)) {
    // If Documents then processing documents display
    // Another Array Object processing Display
    return (
      <div className="row-detail">
        <div className={labelClassName}>{label}</div>
        <div className={valueClassName}>
          {value.map((item, idx) => {
            if (idx === value.length - 1) {
              return <span key={label + idx}>{item.name}</span>;
            }
            return <span key={label + idx}>{item.name}, </span>;
          })}
        </div>
      </div>
    );
  }
  // Process Form Display
  if (label === 'Form') {
    if (value) {
      return (
        <div className="row-detail">
          <div className={labelClassName}>{label}</div>
          <div style={{ color: '#2b2b2b' }}>
            <span>
              <FormattedMessage id={DISPLAY_FORMULA_FORM[value]} />
            </span>
          </div>
        </div>
      );
    }
  }
  // Processing Status Display
  if (label === 'Status') {
    if (value) {
      return (
        <div className="row-detail">
          <div className={labelClassName}>{label}</div>
          <div className={`${valueClassName} status ${value}`}>
            <span>
              <FormattedMessage id={FORMATTEDMESSAGE_STATUS[value]} />
            </span>
          </div>
        </div>
      );
    }
  }

  // Default Field - Value Display
  return defaultRowDetail(label, value, labelClassName, valueClassName);
};

const defaultRowDetail = (label, value, labelClassName, valueClassName) => (
  <div className="row-detail">
    <div className={labelClassName}>{label}</div>
    <div className={valueClassName}>{value}</div>
  </div>
);

export const parseNameById = (id, collections = []) => {
  collections.forEach(collection => {
    if (collection.id === id) {
      return collection.name;
    }
  });
  return '';
};

export const renderColumnsWithinRowFields = (columns, isCompared) => {
  return (
    <>
      {columns.map((item, idx) => (
        <Col
          md={{ span: 24, offset: 0 }}
          lg={{ span: isCompared ? 24 : 12, offset: 0 }}
          key={idx}>
          <div className="row-detail">
            <div
              className={`columns-label ${
                isCompared ? 'columns-label--compare' : ''
              }`}>
              {item?.label}
            </div>
            <div
              className={`columns-value ${
                isCompared ? 'columns-value--compare' : ''
              }`}>
              {item?.value}
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export const renderColumnsWithinRowFieldsProjectType = (
  columns,
  isCompared = false
) => {
  const getNameProjectValueByValue = val => {
    if (val) {
      const getNameByValue = DISPLAY_PROJECT_TYPES.find(
        item => item.value === val
      );
      if (getNameByValue) {
        return (
          <FormattedMessage
            id={getNameByValue.name}
            defaultMessage={getNameByValue.value}
          />
        );
      }
      return val;
    }
  };
  return (
    <>
      {columns.map((item, idx) => (
        <Col
          md={{ span: 24, offset: 0 }}
          lg={{ span: isCompared ? 24 : 12, offset: 0 }}
          key={idx}>
          <div className="row-detail">
            <div
              className={`columns-label ${
                isCompared ? 'columns-label--compare' : ''
              }`}>
              {item?.label}
            </div>
            <div
              className={`columns-value ${
                isCompared ? 'columns-value--compare' : ''
              }`}>
              {getNameProjectValueByValue(item?.value)}
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export { renderRowFields };
