import React from 'react';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import { buildUrlQueryParams } from '../../../../utils/common';
import {
  URL_PARAMS_ACTIONS,
  ACTION_BUTTON_TYPES
} from '../../../../configs/constant';

export const renderUniversalSeachColumns = keywords => [
  {
    title: (
      <FormattedMessage id="universalSearch.id" defaultMessage="Reference ID" />
    ),
    dataIndex: 'id',
    key: 'id',
    width: '15%'
  },
  {
    title: (
      <FormattedMessage id="universalSearch.results" defaultMessage="Results" />
    ),
    dataIndex: 'keywords',
    key: 'keywords',
    render: value => {
      return (
        <Highlighter
          searchWords={keywords?.trim()?.split(' ')}
          autoEscape
          textToHighlight={value}
          highlightClassName="search-highlight-text"
          highlightTag="span"
        />
      );
    },
    width: '75%'
  },
  {
    title: (
      <FormattedMessage
        id="universalSearch.details"
        defaultMessage="View details"
      />
    ),
    className: 'universal-search__view-details',
    key: 'action',
    render: record => {
      const {
        id,
        legacyReferenceObject: { detergentId }
      } = record;
      const formulaId = id.split('_')[1];

      const viewDetailUrl = buildUrlQueryParams('/detergent', {
        data: {
          detergentId,
          formulaId
        },
        action: URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG
      });

      return (
        <a href={viewDetailUrl} target="_blank" rel="noopener noreferrer">
          <img
            className={`${ACTION_BUTTON_TYPES.VIEW_DETERGENT_DETAIL} action-item-visible-no-effect`}
            title=""
            alt=""
            src="/icon/ic-view-details.svg"
          />
        </a>
      );
    },
    width: '10%'
  }
];
