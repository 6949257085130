import React from 'react';
import PropType from 'prop-types';
import BaseGeneratedFileHeader from './BaseGeneratedFileHeader';

const GeneratedFileTitle = ({ title, children }) => {
  return (
    <BaseGeneratedFileHeader>
      <h4>
        <strong> {title} </strong>
      </h4>
      {children}
    </BaseGeneratedFileHeader>
  );
};

GeneratedFileTitle.propTypes = {
  title: PropType.string.isRequired,
  children: PropType.node
};

GeneratedFileTitle.defaultProps = {
  children: null
};

export default GeneratedFileTitle;
