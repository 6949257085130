import { getFormFieldKey } from '../../../Util/productionCycle/getFormFieldKey';

export const validateProducers = (partNumbers, form, formatMessage) => (
  rule,
  value,
  callback
) => {
  try {
    const selectedDetergentProducer = [];

    partNumbers.forEach(partNumber => {
      const fieldKey = getFormFieldKey(partNumber, 'detergentProducer');
      const detergentProducerId = form.getFieldValue(fieldKey);
      selectedDetergentProducer.push({ id: detergentProducerId, fieldKey });
    });

    const isNotIdentical = selectedDetergentProducer.some(
      detergentProducer =>
        detergentProducer.id !== selectedDetergentProducer[0].id
    );

    if (isNotIdentical) {
      // formatMessage
      throw new Error(
        formatMessage({ id: 'error.messageDetergentProducerIdentical' })
      );
    } else {
      selectedDetergentProducer.forEach(detergentProducer => {
        form.setFields({
          [detergentProducer.fieldKey]: {
            value: detergentProducer.id
          }
        });
      });
    }

    callback();
  } catch (error) {
    callback(error);
  }
};
