import { put, takeLatest } from 'redux-saga/effects';
import { getCountriesApi } from './api';
import {
  GET_COUNTRIES,
  getCountriesSuccess,
  getCountriesError
} from './action';
import { errorHandle } from '../../utils/common';

export function* getCountriesSaga() {
  try {
    const response = yield getCountriesApi();
    yield put(getCountriesSuccess(response.data.data));
  } catch (error) {
    yield put(getCountriesError(error));
    errorHandle(error);
  }
}

export default function* rawMaterialSaga() {
  yield takeLatest(GET_COUNTRIES, getCountriesSaga);
}
