import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Select, Icon } from 'antd';
import { MEASUREMENT_MAPPING_FIELD } from '../../../../configs/constant';
import store from '../../../../app-state/store';
import EditableColumnField from '../../../../components/EditableColumnField';
import FormattedInputNumber from '../../../../components/FormattedInputNumber';

const phValuePercent = [
  {
    value: '0.01',
    name: '1%'
  },
  {
    value: '0.1',
    name: '10%'
  }
];

export const renderMeasurementPhValueColumns = isEditable => [
  {
    title: (
      <FormattedMessage
        id="measurement.phValueTable.title"
        defaultMessage="PH VALUE DIN 19268"
      />
    ),
    key: 'cellKeyPh',
    render: (data, record) => {
      const classNameToDisplay = isEditable ? 'phvalue-select' : '';

      const fieldName = _.get(
        MEASUREMENT_MAPPING_FIELD,
        `${data.cellKeyPh}.name`,
        `No label: ${data.cellKeyPh}`
      );

      if (data.cellKeyPh === 'concentrate') {
        return <span>{fieldName}</span>;
      }
      return (
        <span id="in-tab-water-node">
          <EditableColumnField
            editable={isEditable}
            readableField={
              <Select
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                style={{ width: '26%', marginRight: '5px' }}
                className={classNameToDisplay}
                value={`${store.getState().detergent.measurementDataForm
                  .tabWaterLabel * 100}%`}
              />
            }
            editableField={
              <Select
                getPopupContainer={() =>
                  document.getElementById('in-tab-water-node')
                }
                suffixIcon={<Icon type="caret-down" />}
                disabled={!isEditable}
                style={{ width: '26%', marginRight: '5px' }}
                className={classNameToDisplay}
                value={`${store.getState().detergent.measurementDataForm
                  .tabWaterLabel * 100}%`}>
                {phValuePercent.map((item, idx) => {
                  return (
                    <Select.Option key={idx} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            }
            cellKey={`${record.cellKeyPh}Label`}
            columnKey="tabLable"
            tabName="measurement"
          />
          <span>{fieldName}</span>
        </span>
      );
    },
    width: '30%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.measured.title"
          defaultMessage="Measured value"
        />
      </div>
    ),
    key: 'measured',
    width: '15%',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <FormattedInputNumber
            className="measure-display"
            defaultValue={record.measured}
            disabled={!isEditable}
          />
        }
        editableField={
          <FormattedInputNumber
            className="measure-edit"
            defaultValue={record.measured}
            disabled={!isEditable}
          />
        }
        cellKey={record.cellKeyPh}
        columnKey="measured"
      />
    )
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage id="measurement.min.title" defaultMessage="Minimum" />
      </div>
    ),
    key: 'min',
    width: '15%',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <FormattedInputNumber
            className="measure-display"
            defaultValue={record.min}
            disabled={!isEditable}
          />
        }
        editableField={
          <FormattedInputNumber
            className="measure-edit"
            defaultValue={record.min}
            disabled={!isEditable}
          />
        }
        cellKey={record.cellKeyPh}
        columnKey="min"
      />
    )
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.max.title"
          defaultMessage="Maximum value"
        />
      </div>
    ),
    key: 'max',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <FormattedInputNumber
            className="measure-display"
            defaultValue={record.max}
            disabled={!isEditable}
          />
        }
        editableField={
          <FormattedInputNumber
            className="measure-edit"
            defaultValue={record.max}
            disabled={!isEditable}
          />
        }
        cellKey={record.cellKeyPh}
        columnKey="max"
      />
    ),
    width: '15%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.comment.title"
          defaultMessage="Comment"
        />
      </div>
    ),
    key: 'comment',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <Input.TextArea
            className="measure-display"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
        editableField={
          <Input.TextArea
            className="measure-edit-comment"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
        cellKey={record.cellKeyPh}
        columnKey="comment"
      />
    ),
    width: '25%'
  }
];
