import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export const _BaseModal = props => {
  const {
    title,
    children,
    visible,
    className,
    width,
    onCancel,
    maskClosable
  } = props;

  return (
    <Modal
      title={title}
      width={width}
      visible={visible}
      className={className}
      onCancel={onCancel}
      footer={null}
      maskClosable={maskClosable}>
      {children}
    </Modal>
  );
};

_BaseModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  maskClosable: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCancel: PropTypes.func.isRequired
};

_BaseModal.defaultValue = {
  width: '50%',
  onCancel: () => false
};

export const BaseModal = _BaseModal;
