import React from 'react';

import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

function ContainerTypeDescription({
  getFieldDecorator,
  initialValue,
  field,
  isContainerTypeOthers
}) {
  const { formatMessage } = useIntl();

  return (
    <Form.Item
      label={
        <FormattedMessage
          id="partNumber.form.containerTypeDescription"
          defaultMessage="Container type description"
        />
      }
      required={isContainerTypeOthers}>
      {getFieldDecorator(field, {
        rules: [
          {
            required: isContainerTypeOthers,
            message: formatMessage({
              id: 'partNumber.validate.containTypeDescription',
              defaultMessage: 'Container type description is required'
            })
          }
        ],
        initialValue: initialValue || undefined,
      })(<Input disabled={!isContainerTypeOthers} />)}
    </Form.Item>
  );
}

ContainerTypeDescription.propTypes = {
  getFieldDecorator: PropTypes.func,
  initialValue: PropTypes.string,
  field: PropTypes.string,
  isContainerTypeOthers: PropTypes.bool
};

ContainerTypeDescription.defaultProps = {
  getFieldDecorator: () => null,
  initialValue: '',
  field: '',
  isContainerTypeOthers: false
};

export default ContainerTypeDescription;
