
export const GET_DETERGENT_PRODUCERS = "GET_DETERGENT_PRODUCERS";
export const GET_DETERGENT_PRODUCERS_SUCCESS =
  "GET_DETERGENT_PRODUCERS_SUCCESS";
export const GET_DETERGENT_PRODUCERS_ERROR = "GET_DETERGENT_PRODUCERS_ERROR";

export const CREATE_DETERGENT_PRODUCERS = "CREATE_DETERGENT_PRODUCERS";
export const CREATE_DETERGENT_PRODUCERS_SUCCESS =
  "CREATE_DETERGENT_PRODUCERS_SUCCESS";
export const CREATE_DETERGENT_PRODUCERS_ERROR =
  "CREATE_DETERGENT_PRODUCERS_ERROR";

export const UPDATE_DETERGENT_PRODUCERS = "UPDATE_DETERGENT_PRODUCERS";
export const UPDATE_DETERGENT_PRODUCERS_SUCCESS =
  "UPDATE_DETERGENT_PRODUCERS_SUCCESS";
export const UPDATE_DETERGENT_PRODUCERS_ERROR =
  "UPDATE_DETERGENT_PRODUCERS_ERROR";

export const GET_ALL_DETERGENT_PRODUCERS = "GET_ALL_DETERGENT_PRODUCERS";
export const GET_ALL_DETERGENT_PRODUCERS_SUCCESS =
  "GET_ALL_DETERGENT_PRODUCERS_SUCCESS";
export const GET_ALL_DETERGENT_PRODUCERS_ERROR =
  "GET_ALL_DETERGENT_PRODUCERS_ERROR";

export const TOGGLE_DETERGENT_PRODUCER_MODAL = 
  "TOGGLE_DETERGENT_PRODUCER_MODAL"

export const CLEAR_DETERGENT_PRODUCER_DATA_WHEN_UNMOUNT =
  "CLEAR_DETERGENT_PRODUCER_DATA_WHEN_UNMOUNT";

export const SET_LOADING_DETERGENT_PRODUCER = "SET_LOADING_DETERGENT_PRODUCER ";

//Fetch List Producers
export const getDetergentProducers = payload => ({
  type: GET_DETERGENT_PRODUCERS,
  payload
});

export const getDetergentProducersSuccess = payload => ({
  type: GET_DETERGENT_PRODUCERS_SUCCESS,
  payload
});

export const getDetergentProducersError = payload => ({
  type: GET_DETERGENT_PRODUCERS_ERROR,
  payload
});

//Create Producer
export const createDetergentProducers = payload => ({
  type: CREATE_DETERGENT_PRODUCERS,
  payload
});

export const createDetergentProducersSuccess = payload => ({
  type: CREATE_DETERGENT_PRODUCERS_SUCCESS,
  payload
});

export const createDetergentProducersError = payload => ({
  type: CREATE_DETERGENT_PRODUCERS_ERROR,
  payload
});

//Update/
export const updateDetergentProducers = payload => ({
  type: UPDATE_DETERGENT_PRODUCERS,
  payload
});

export const updateDetergentProducersSuccess = payload => ({
  type: UPDATE_DETERGENT_PRODUCERS_SUCCESS,
  payload
});

export const updateDetergentProducersError = payload => ({
  type: UPDATE_DETERGENT_PRODUCERS_ERROR,
  payload
});

//Open Modal 
export const processProducerModal = (payload) =>({
  type: TOGGLE_DETERGENT_PRODUCER_MODAL,
  payload
});

//Fetch All Producers
export const getAllDetergentProducers = () => ({
  type: GET_ALL_DETERGENT_PRODUCERS
});

export const getAllDetergentProducersSuccess = payload => ({
  type: GET_ALL_DETERGENT_PRODUCERS_SUCCESS,
  payload
});

export const getAllDetergentProducersError = payload => ({
  type: GET_ALL_DETERGENT_PRODUCERS_ERROR,
  payload
});

export const clearDetergentProducerDataWhenUnmount = () => ({
  type: CLEAR_DETERGENT_PRODUCER_DATA_WHEN_UNMOUNT
});

export const setLoadingDetergentProducer = payload => ({
  type: SET_LOADING_DETERGENT_PRODUCER,
  payload
});
