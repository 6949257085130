import * as _ from 'lodash';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import { List } from './components/List';
import { DetergentModal } from './components/Modal';
import {
  getDetergent,
  processDetergentModal,
  clearDetergentDataWhenUnmount,
  getMeasurement,
  getDetergentFormulaById,
  setSelectedDropdownFieldForDetergent
} from './data/detergent/actions';
import {
  BASE_DB_FIELDS,
  FORM_STATUS,
  URL_QUERY_NAMES,
  URL_PARAMS_ACTIONS
} from '../../configs/constant';
import { getAllRawMaterial } from '../RawMaterial/data/rawMaterial/action';
import FilterForm from './components/Form/FilterForm';
import { getUrlParameterByName, closest } from '../../utils/common';
import { ProtectedComponent } from '../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../configs/rolesOfPermissions';
import { getCognitoUser } from '../UserManagement/data/userManagement/actions';
import { getCountries } from '../../data/countries/action';
import Filter from '../../components/Filter';
import { CompareForm } from './components/Form/CompareForm';

const Detergent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    pagination,
    searchByText,
    detergent,
    order,
    modalLoadLatestDataAfterCreateOrUpdate,
    filterList,
    countsRefeshList,
    userList
  } = useSelector(state => ({
    pagination: state.detergent.list.data.pagination,
    modalLoadLatestDataAfterCreateOrUpdate:
      state.detergent.modal.loadLatestDataAfterCreateOrUpdate,
    searchByText: state.detergent.searchByText,
    detergent: state.detergent,
    order: state.detergent.order,
    filterList: state.detergent.filterList,
    countsRefeshList: state.detergent.modal.countsRefeshList,
    userList: state.userManagement.list.data.results
  }));

  const [paginationPage, setPaginationPage] = useState(pagination);
  const [
    selectedComparableDetergents,
    setSelectedComparableDetergents
  ] = useState([]);

  const popupAction = useCallback(
    (action, status) => {
      if (action === 'open') {
        dispatch(
          processDetergentModal({
            statusForm: FORM_STATUS.CREATE,
            title: (
              <FormattedMessage
                id="detergent.modalAddDetergentTitle"
                defaultMessage="ADD NEW DETERGENT"
              />
            ),
            isVisible: true
          })
        );
      } else {
        // clear selected comparable detergents when close modal
        if (status === FORM_STATUS.COMPARE) {
          setSelectedComparableDetergents([]);
        }
        dispatch(
          processDetergentModal({
            isVisible: false
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getAllRawMaterial());
    dispatch(getCognitoUser());
    dispatch(getCountries());
    return () => {
      dispatch(clearDetergentDataWhenUnmount());
    };
  }, []);

  useEffect(() => {
    const pathParams = getUrlParameterByName(URL_QUERY_NAMES.PARAMS);

    if (!_.isEmpty(pathParams)) {
      const { data, action } = pathParams;

      switch (action) {
        case URL_PARAMS_ACTIONS.OPEN_DETERGENT_DETAIL_DIALOG:
          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            onViewDetail({
              _viewDetailFromMail: true,
              id: data.detergentId,
              formulas: [
                {
                  id: data.formulaId
                }
              ]
            });
          });
          break;

        case URL_PARAMS_ACTIONS.OPEN_DETERGENT_EDIT_DIALOG:
          setTimeout(() => {
            // Fetch data
            dispatch(
              getDetergentFormulaById({
                id: data.detergentId,
                formulaId: data.formulaId
              })
            );

            // eslint-disable-next-line no-use-before-define
            onEditDetergent({
              id: data.detergentId,
              name: data.detergentName,
              formulas: [
                {
                  id: data.formulaId
                }
              ]
            });
          });
          break;

        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    let newOrder = { ...order };
    const newPagination = { ...pagination };

    if (modalLoadLatestDataAfterCreateOrUpdate) {
      newOrder = {
        [BASE_DB_FIELDS.UPDATE_AT]: 'DESC'
      };
      newPagination.current = 1;
    }

    dispatch(
      getDetergent({
        pagination: newPagination,
        searchByText,
        order: newOrder,
        modalLoadLatestDataAfterCreateOrUpdate,
        filterList
      })
    );
  }, [
    countsRefeshList,
    modalLoadLatestDataAfterCreateOrUpdate,
    paginationPage
  ]);

  // event
  const handleClick = e => {
    if (
      closest(e.srcElement, '.sample-wrapper') === null &&
      closest(e.srcElement, '.filter-form') === null
    ) {
      dispatch(
        setSelectedDropdownFieldForDetergent({
          selectedFormulaRowId: 0,
          selectedFormulaRowType: ''
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [dispatch]);

  const onPageSizeChange = value => {
    pagination.current = 1;
    pagination.pageSize = value;
    setPaginationPage(pagination);
  };

  const onPageChange = current => {
    pagination.current = current + 1;
    setPaginationPage(pagination);
  };

  const selectedDetergentKeys = useMemo(
    () => selectedComparableDetergents.map(record => record.formulas[0].id),
    [selectedComparableDetergents]
  );

  const onSelectedDetergents = (checked, record, formulaId) => {
    const newSelectedComparableDetergents = [...selectedComparableDetergents];
    if (checked) {
      if (newSelectedComparableDetergents.length >= 2) {
        newSelectedComparableDetergents.shift();
      }
      newSelectedComparableDetergents.push(record);
    } else {
      const index = selectedDetergentKeys.indexOf(formulaId);
      if (index > -1) {
        newSelectedComparableDetergents.splice(index, 1);
      }
    }
    setSelectedComparableDetergents(newSelectedComparableDetergents);
  };

  const onAddNewFormula = useCallback(
    record => {
      if (!isEmpty(record)) {
        dispatch(
          processDetergentModal({
            detergent: record,
            statusForm: FORM_STATUS.ADD_FORMULA,
            title: (
              <FormattedMessage
                id="formula.modalAddFormulaTitle"
                defaultMessage={`ADD FORMULA FOR ${record.name}`}
                values={{ name: record.name }}
              />
            ),
            isVisible: true
          })
        );
      }
    },
    [dispatch]
  );

  const onDuplicateDetergent = useCallback(
    record => {
      if (!isEmpty(record)) {
        dispatch(
          processDetergentModal({
            detergent: record,
            statusForm: FORM_STATUS.DUPLICATE_DETERGENT,
            title: (
              <FormattedMessage
                id="detergent.modalDuplicateDetergentTitle"
                defaultMessage="DUPLICATE DETERGENT"
              />
            ),
            isVisible: true
          })
        );
      }
    },
    [dispatch]
  );

  const onViewDetail = useCallback(
    record => {
      dispatch(
        processDetergentModal({
          detergent: record,
          statusForm: FORM_STATUS.VIEW_DETAIL,
          title: (
            <FormattedMessage
              id="detergent.viewDetail"
              defaultMessage="VIEW DETERGENT DETAILS"
            />
          ),
          isVisible: true
        })
      );
      dispatch(
        getMeasurement({ id: record.id, formulaId: record?.formulas?.[0]?.id })
      );
    },
    [dispatch]
  );

  const onEditDetergent = useCallback(
    detergentValues => {
      dispatch(
        processDetergentModal({
          detergent: detergentValues,
          statusForm: FORM_STATUS.UPDATE,
          title: (
            <FormattedMessage
              id="detergent.modalUpdateDetergentFormulaTitle"
              defaultMessage={`EDIT DETERGENT {detergentName} - FORMULA VERSION {formulaVersion}`}
              values={{
                detergentName: detergentValues.name,
                formulaVersion: detergentValues.formulas[0].version
              }}
            />
          ),
          isVisible: true
        })
      );
    },
    [dispatch]
  );

  const onCompareDetergents = useCallback(
    detergents => {
      dispatch(
        processDetergentModal({
          detergents,
          statusForm: FORM_STATUS.COMPARE,
          title: (
            <FormattedMessage
              id="detergent.modalCompareDetergentsTitle"
              defaultMessage="COMPARE DETERGENTS"
            />
          ),
          isVisible: true
        })
      );
    },
    [dispatch]
  );

  const onSearchList = value => {
    const valueChange = value.trim().replace(/\s+/g, ' ');
    pagination.current = 1;
    dispatch(
      getDetergent({ pagination, searchByText: valueChange, order, filterList })
    );
  };

  const onChangeSearch = e => {
    if (isEmpty(e.target.value)) {
      dispatch(
        getDetergent({ pagination, searchByText: '', order, filterList })
      );
    }
  };

  return (
    <>
      <div className="detergent-database-content formulas-tab">
        <div className="main-title">
          <FormattedMessage id="detergent.formulas" defaultMessage="Formulas" />
        </div>
        <div className="filter-area">
          <CompareForm
            selectedDetergents={selectedComparableDetergents}
            onCompareDetergents={onCompareDetergents}
          />
          <Filter
            dropdownClassName="sort-dropdown"
            dropdownOverlayClassName="filter-detergent"
            FilterFormLayout={FilterForm}
            filterFormProps={{ userList }}
          />
          <Input.Search
            className="search-place"
            name="search"
            placeholder={formatMessage({
              id: 'header.search',
              defaultMessage: 'Search'
            })}
            onChange={e => onChangeSearch(e)}
            onSearch={value => onSearchList(value)}
          />

          <ProtectedComponent
            allowRoles={ROLES_OF_PERMISSIONS.DETERGENTS_AND_FORMULAS.CREATE}>
            <Button className="add-button" onClick={() => popupAction('open')}>
              <Icon className="add-icon" type="plus" />
              <div className="text">
                <FormattedMessage
                  id="detergent.createButton"
                  defaultMessage="Create Detergent"
                />
              </div>
            </Button>
          </ProtectedComponent>

          <DetergentModal
            status={detergent.modal.statusForm}
            title={detergent.modal.title}
            isVisible={detergent.modal.isVisible}
            onCancel={() => popupAction('close', detergent.modal.statusForm)}
          />
        </div>
      </div>
      <List
        onPageSizeChange={value => onPageSizeChange(value)}
        onPageChange={current => onPageChange(current)}
        onAddNewFormula={record => onAddNewFormula(record)}
        onViewDetail={record => onViewDetail(record)}
        onEditDetergent={record => onEditDetergent(record)}
        onSelectedDetergents={(checked, record, formulaId) =>
          onSelectedDetergents(checked, record, formulaId)
        }
        selectedRowKeys={selectedDetergentKeys}
        onDuplicateDetergent={record => onDuplicateDetergent(record)}
      />
    </>
  );
};
export default Detergent;
