export const removeMiddleSpace = value => {
  return typeof value === 'string' && value.trim().replace(/\s+/g, ' ');
};

export const isEqual = (searchValue, source) => {
  return source
    .toString() // incase input is number like formular version
    .toLowerCase()
    .includes(removeMiddleSpace(searchValue).toLowerCase());
};
