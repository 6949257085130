import * as _ from 'lodash';
import base64ToBytesConverter from '../../components/Util/base64ToBytesConverter';
import saveFileAsBytes from '../../components/Util/saveFileAsBytes';

import measurementInitialState from './measurementInitialState';
import {
  GET_DETERGENT,
  GET_DETERGENT_SUCCESS,
  GET_DETERGENT_ERROR,
  CREATE_DETERGENT,
  CREATE_DETERGENT_SUCCESS,
  CREATE_DETERGENT_ERROR,
  UPDATE_DETERGENT,
  UPDATE_DETERGENT_SUCCESS,
  UPDATE_DETERGENT_ERROR,
  CREATE_FORMULA,
  CREATE_FORMULA_SUCCESS,
  CREATE_FORMULA_ERROR,
  DETERGENT_MODAL,
  GET_DETERGENT_BY_ID,
  GET_DETERGENT_BY_ID_SUCCESS,
  GET_DETERGENT_BY_ID_ERROR,
  PATCH_FORUMLA,
  PATCH_FORUMLA_SUCCESS,
  PATCH_FORUMLA_ERROR,
  GET_DETERGENT_HISTORY,
  GET_DETERGENT_HISTORY_SUCCESS,
  GET_DETERGENT_HISTORY_ERROR,
  GET_MEASUREMENT,
  GET_MEASUREMENT_SUCCESS,
  GET_MEASUREMENT_ERROR,
  SET_MEASUREMENT_DATA_FIELD,
  SET_MEASUREMENT_DATA_FORM,
  SET_MEASUREMENT_DATA_NON_GROUP,
  GET_RAW_MATERIAL_BY_FORMULA,
  GET_RAW_MATERIAL_BY_FORMULA_SUCCESS,
  GET_RAW_MATERIAL_BY_FORMULA_ERROR,
  GET_RAW_MATERIAL_BY_FORMULA_COMPARE,
  GET_RAW_MATERIAL_BY_FORMULA_COMPARE_SUCCESS,
  GET_RAW_MATERIAL_BY_FORMULA_COMPARE_ERROR,
  SET_PRECALCULATION_FIELD,
  SET_PRECALCULATION_DATA,
  SET_CONTAINER_SIZE,
  SET_MESSAGE_TOTAL,
  SET_SELECT_ALL,
  SET_DISABLE_DROPDOWN_ALL,
  SET_SELECTED_DROPDOWN_FIELD_FOR_DETERGENT,
  CLEAR_DETERGENT_DATA_WHEN_UNMOUNT,
  UPDATE_DETERGENT_FORMULA,
  UPDATE_DETERGENT_FORMULA_SUCCESS,
  UPDATE_DETERGENT_FORMULA_ERROR,
  GET_DETERGENT_PRODUCTION_CYCLE_LIST,
  GET_DETERGENT_PRODUCTION_CYCLE_LIST_ERROR,
  GET_DETERGENT_PRODUCTION_CYCLE_LIST_SUCCESS,
  CREATE_PRODUCTION_CYCLE,
  CREATE_PRODUCTION_CYCLE_SUCCESS,
  CREATE_PRODUCTION_CYCLE_ERROR,
  CREATE_DUMMY_PART_NUMBER,
  CREATE_DUMMY_PART_NUMBER_ERROR,
  DOWNLOAD_PRECALCULATION,
  DOWNLOAD_PRECALCULATION_SUCCESS,
  DOWNLOAD_PRECALCULATION_ERROR,
  CLEAR_DOWNLOAD_PRECALCULATION_ERROR,
  GET_PART_NUMBER_LIST_BY_FORMULA,
  GET_PART_NUMBER_LIST_BY_FORMULA_SUCCESS,
  GET_PART_NUMBER_LIST_BY_FORMULA_ERROR,
  SEND_EMAIL_TO_SAFETY_TEAM,
  SEND_EMAIL_TO_SAFETY_TEAM_SUCCESS,
  SEND_EMAIL_TO_SAFETY_TEAM_ERROR,
  SEND_EMAIL_TO_LABELING_TEAM,
  SEND_EMAIL_TO_LABELING_TEAM_SUCCESS,
  SEND_EMAIL_TO_LABELING_TEAM_ERROR,
  UPDATE_PRODUCTION_CYCLES,
  UPDATE_PRODUCTION_CYCLES_SUCCESS,
  UPDATE_PRODUCTION_CYCLES_ERROR,
  SET_LOADING_DETERGENT,
  CHECK_RAW_MATERIAL_UNBLOCK,
  CHECK_RAW_MATERIAL_UNBLOCK_ERROR,
  CHECK_RAW_MATERIAL_UNBLOCK_SUCCESS,
  SET_NAME_PRECALCULATION_DATA,
  DELETE_PRODUCTION_CYCLE,
  DELETE_PRODUCTION_CYCLE_SUCCESS,
  DELETE_PRODUCTION_CYCLE_ERROR,
  GET_PRODUCTION_CYCLE_HISTORY_LIST,
  GET_PRODUCTION_CYCLE_HISTORY_LIST_SUCCESS,
  GET_PRODUCTION_CYCLE_HISTORY_LIST_ERROR
} from './actions';
import { transformHistoryToStandardData } from '../../../../utils/common';
import { sortDocumentsByCategoryAndName } from '../../components/Util/sortFormulaDocuments';

export const initialState = {
  list: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    }
  },
  measure: {
    disabled: true
  },
  dropdownAll: {
    disabled: true
  },
  fetching: false,
  searchByText: '',
  order: {
    status: 'ASC',
    number: 'ASC'
  },
  modal: {
    loading: false,
    isVisible: false,
    statusForm: '',
    title: '',
    countsRefeshList: 0
  },
  filterList: {
    sample: '',
    status: '',
    creatorEmail: '',
    fromCountryId: ''
  },
  selectedChangeFormulaId: {
    selectedFormulaRowId: 0,
    selectedFormulaRowType: ''
  },
  emptyDetergent: {
    number: '',
    name: '',
    fromCountryId: '',
    formulas: [
      {
        documents: [],
        containerSize: 0,
        form: '',
        sample: '',
        density: null,
        productionInstruction: '',
        propertyDescription: '',
        rawMaterialRatios: []
      }
    ]
  },
  compareDetergents: {
    detergents: [],
    rawMaterials: {},
    fetching: false
  },
  history: {
    data: {
      results: [],
      pagination: {
        total: 10,
        pageSize: 25,
        current: 1
      }
    },
    isFetchingHistory: false
  },
  measurementData: {
    data: {},
    isFetching: false
  },
  partNumberList: {
    data: [],
    fetching: false
  },
  productionCycleList: {
    fetching: false,
    data: []
  },
  productionCycleHistoryList: {
    data: [],
    fetching: false
  },
  measurementDataForm: JSON.parse(JSON.stringify(measurementInitialState)),
  messageTotal: '',
  ratios: [],
  precalculationForm: {
    fetching: false,
    fetchedList: [],
    list: []
  },
  file: {
    precalculation: {
      isDownloading: false
    }
  },
  mail: {
    safetyTeam: {
      areSendingToSafetyTeamForProductionCycleIds: []
    },
    labelingTeam: {
      areSendingToLabelingTeamForProductionCycleIds: []
    }
  },
  rawMaterialByDetergentId: {
    data: {}
  }
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DETERGENT:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            pagination:
              action.payload.pagination || initialState.list.data.pagination
          }
        },
        filterList: action.payload.filterList || initialState.filterList,
        fetching: true,
        searchByText: action.payload.searchByText || '',
        order: action.payload.order || initialState.order,
        modal: {
          ...state.modal
        }
      };
    case GET_DETERGENT_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false
      };
    case GET_DETERGENT_ERROR:
      return {
        ...state,
        fetching: false
      };
    case CREATE_DETERGENT:
      return {
        ...state,
        emptyDetergent: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_DETERGENT_SUCCESS:
      return {
        ...state,
        emptyDetergent: initialState.emptyDetergent,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };

    case CREATE_DETERGENT_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case UPDATE_DETERGENT:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_DETERGENT_SUCCESS:
      return {
        ...state,
        emptyDetergent: initialState.emptyDetergent,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case UPDATE_DETERGENT_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case CREATE_FORMULA:
      return {
        ...state,
        emptyDetergent: action.payload,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_FORMULA_SUCCESS:
      return {
        ...state,
        emptyDetergent: initialState.emptyDetergent,
        modal: {
          ...state.modal,
          isVisible: false,
          loading: false,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };
    case CREATE_FORMULA_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case DETERGENT_MODAL:
      return {
        ...state,
        emptyDetergent: action.payload.detergent || initialState.emptyDetergent,
        compareDetergents: {
          ...state.compareDetergents,
          detergents:
            action.payload.detergents ||
            initialState.compareDetergents.detergents
        },
        modal: {
          ...state.modal,
          statusForm: action.payload.statusForm || '',
          title: action.payload.title || '',
          isVisible: action.payload.isVisible || false
        }
      };
    case CLEAR_DETERGENT_DATA_WHEN_UNMOUNT:
      return {
        ...initialState
      };
    case GET_DETERGENT_BY_ID:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };

    case GET_DETERGENT_BY_ID_SUCCESS: {
      const data = action.payload;
      data.formulas = data.formulas.map(formula => ({
        ...formula,
        documents: sortDocumentsByCategoryAndName(formula.documents)
      }));
      return {
        ...state,
        emptyDetergent: data,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    }

    case GET_DETERGENT_BY_ID_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case PATCH_FORUMLA:
      return {
        ...state,
        fetching: true
      };
    case PATCH_FORUMLA_SUCCESS:
      return {
        ...state,
        fetching: false,
        modal: {
          ...state.modal,
          countsRefeshList: state.modal.countsRefeshList + 1
        }
      };

    case PATCH_FORUMLA_ERROR:
      return {
        ...state,
        fetching: false
      };

    case GET_DETERGENT_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: true
        }
      };

    case GET_DETERGENT_HISTORY_SUCCESS: {
      const { results, pagination } = action.payload;
      const transformResults = transformHistoryToStandardData(
        'detergent',
        results
      );

      return {
        ...state,
        history: {
          ...state.history,
          data: {
            results: transformResults,
            pagination
          },
          isFetchingHistory: false
        }
      };
    }

    case GET_DETERGENT_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          isFetchingHistory: false
        }
      };

    case GET_MEASUREMENT:
      return {
        ...state,
        measurementData: {
          ...state.measurementData,
          isFetching: true
        },
        modal: {
          ...state.modal,
          loading: true
        }
      };

    case GET_MEASUREMENT_SUCCESS:
      return {
        ...state,
        measurementData: {
          ...state.measurementData,
          isFetching: false,
          data: action.payload
        },
        modal: {
          ...state.modal,
          loading: false
        }
      };

    case GET_MEASUREMENT_ERROR:
      return {
        ...state,
        isFetching: false
      };
    case SET_MEASUREMENT_DATA_FORM:
      return {
        ...state,
        measurementDataForm: action.payload
      };
    case SET_MEASUREMENT_DATA_NON_GROUP: {
      const fieldName = action.payload.row;
      const fieldValue = action.payload.val;
      const cloneState = JSON.parse(JSON.stringify(state));

      _.set(cloneState.measurementDataForm, `${fieldName}`, fieldValue);
      _.set(state, 'measurementDataForm', cloneState.measurementDataForm);
      return {
        ...state
      };
    }
    case SET_SELECT_ALL: {
      const fieldValue = action.payload.val;
      const cloneState = JSON.parse(JSON.stringify(state));

      const fieldNamesDepend = [
        'roomTemperatureFourWeeks',
        'roomTemperatureEightWeeks',
        'roomTemperatureSixMonths',
        'roomTemperatureTwelveMonths',
        'roomTemperatureTwentyFourMonths',
        'fiftyCThreeDays',
        'thirtySevenCFourWeeks',
        'fourCFourWeeks',
        'negativeTwentyOneDay'
      ];
      fieldNamesDepend.forEach(fieldName => {
        _.set(
          cloneState.measurementDataForm,
          `${fieldName}.passFailed`,
          fieldValue
        );
      });

      return {
        ...state,
        measurementDataForm: cloneState.measurementDataForm
      };
    }
    case SET_MEASUREMENT_DATA_FIELD: {
      const fieldName = action.payload.row;
      const columnName = action.payload.col;
      const fieldValue = action.payload.val;
      const cloneState = JSON.parse(JSON.stringify(state));

      _.set(
        cloneState.measurementDataForm,
        `${fieldName}.${columnName}`,
        fieldValue
      );

      _.set(state, 'measurementDataForm', cloneState.measurementDataForm);

      return {
        ...state
      };
    }
    case GET_RAW_MATERIAL_BY_FORMULA:
      return {
        ...state,
        precalculationForm: {
          ...state.precalculationForm,
          fetching: true
        }
      };

    case GET_RAW_MATERIAL_BY_FORMULA_SUCCESS:
      return {
        ...state,
        precalculationForm: {
          ...state.precalculationForm,
          list: action.payload,
          fetchedList: action.payload,
          fetching: false
        }
      };

    case GET_RAW_MATERIAL_BY_FORMULA_ERROR:
      return {
        ...state,
        precalculationForm: {
          ...state.precalculationForm,
          fetching: false
        }
      };

    case GET_RAW_MATERIAL_BY_FORMULA_COMPARE:
      return {
        ...state,
        compareDetergents: {
          ...state.compareDetergents,
          fetching: true
        }
      };

    case GET_RAW_MATERIAL_BY_FORMULA_COMPARE_SUCCESS:
      return {
        ...state,
        compareDetergents: {
          ...state.compareDetergents,
          rawMaterials: action.payload,
          fetching: false
        }
      };

    case GET_RAW_MATERIAL_BY_FORMULA_COMPARE_ERROR:
      return {
        ...state,
        compareDetergents: {
          ...state.compareDetergents,
          fetching: false
        }
      };

    case SET_PRECALCULATION_DATA:
      return {
        ...state,
        precalculationForm: {
          ...state.precalculationForm,
          list: action.payload.rawMaterials
        }
      };
    case SET_NAME_PRECALCULATION_DATA: {
      const cloneState = JSON.parse(JSON.stringify(state));
      const { name } = action.payload;
      const { version } = action.payload;
      _.set(cloneState, 'emptyDetergent.formulas.0.version', version);
      _.set(cloneState, 'emptyDetergent.name', name);
      return {
        ...cloneState
      };
    }
    case SET_PRECALCULATION_FIELD: {
      // clone state
      const cloneState = JSON.parse(JSON.stringify(state));

      // find record by cellKey
      const rawMaterialChangedIndex = _.findIndex(
        cloneState.precalculationForm.list,
        { rawMaterialId: action.payload.id }
      );

      const currentRawMaterial = _.get(
        cloneState.precalculationForm.list,
        rawMaterialChangedIndex,
        null
      );

      // set field by columnKey + value
      const field = action.payload.fieldName;
      const value = action.payload.fieldValue;

      _.set(currentRawMaterial, `rawMaterial.${field}`, value);

      return {
        ...state,
        precalculationForm: {
          ...state.precalculationForm,
          list: cloneState.precalculationForm.list
        }
      };
    }
    case SET_CONTAINER_SIZE: {
      const cloneState = JSON.parse(JSON.stringify(state));

      _.set(
        cloneState,
        'emptyDetergent.formulas.0.containerSize',
        action.payload.inputValue
      );

      cloneState.precalculationForm.list.map(rawMaterial => {
        return _.set(rawMaterial, 'containerSize', action.payload.inputValue);
      });

      return {
        ...state,
        emptyDetergent: cloneState.emptyDetergent,
        precalculationForm: {
          ...state.precalculationForm,
          list: cloneState.precalculationForm.list
        }
      };
    }
    case SET_MESSAGE_TOTAL: {
      return {
        ...state,
        messageTotal: action.payload.messageTotalFormula
      };
    }
    case SET_DISABLE_DROPDOWN_ALL: {
      return {
        ...state,
        dropdownAll: {
          ...state.dropdownAll,
          disabled: action.payload.display
        }
      };
    }
    case SET_SELECTED_DROPDOWN_FIELD_FOR_DETERGENT: {
      return {
        ...state,
        selectedChangeFormulaId: {
          selectedFormulaRowId: action.payload.selectedFormulaRowId,
          selectedFormulaRowType: action.payload.selectedFormulaRowType
        }
      };
    }
    case UPDATE_DETERGENT_FORMULA:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_DETERGENT_FORMULA_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false,
          countsRefeshList: state.modal.countsRefeshList + 1,
          loadLatestDataAfterCreateOrUpdate: true
        }
      };
    case UPDATE_DETERGENT_FORMULA_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case GET_DETERGENT_PRODUCTION_CYCLE_LIST:
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          data: [],
          fetching: true
        }
      };
    case GET_DETERGENT_PRODUCTION_CYCLE_LIST_SUCCESS:
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          fetching: false,
          data: action.payload
        }
      };
    case GET_DETERGENT_PRODUCTION_CYCLE_LIST_ERROR:
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          fetching: false
        }
      };
    case CREATE_PRODUCTION_CYCLE:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_PRODUCTION_CYCLE_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          isVisible: false
        }
      };
    case CREATE_PRODUCTION_CYCLE_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case CREATE_DUMMY_PART_NUMBER:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case CREATE_DUMMY_PART_NUMBER_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };

    case DOWNLOAD_PRECALCULATION: {
      return {
        ...state,
        file: {
          precalculation: {
            ...state.file.precalculation,
            isDownloading: true
          }
        }
      };
    }

    case DOWNLOAD_PRECALCULATION_SUCCESS: {
      const detergent = state.emptyDetergent;
      const byteArrays = base64ToBytesConverter(action.payload);
      const type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileName = `${detergent.name}-${detergent.formulas?.[0].version}-Precalculation.xlsx`;
      saveFileAsBytes(byteArrays, type, fileName);
      return {
        ...state,
        file: {
          precalculation: {
            ...state.file.precalculation,
            isDownloading: false
          }
        }
      };
    }
    case DOWNLOAD_PRECALCULATION_ERROR:
      return {
        ...state,
        file: {
          precalculation: {
            ...state.file.precalculation,
            isDownloading: false,
            error: action.payload.error
          }
        }
      };

    case CLEAR_DOWNLOAD_PRECALCULATION_ERROR:
      return {
        ...state,
        file: {
          precalculation: {
            ...state.file.precalculation,
            error: null
          }
        }
      };

    case GET_PART_NUMBER_LIST_BY_FORMULA:
      return {
        ...state,
        partNumberList: {
          ...state.partNumberList,
          fetching: true
        }
      };
    case GET_PART_NUMBER_LIST_BY_FORMULA_SUCCESS:
      return {
        ...state,
        partNumberList: {
          ...state.partNumberList,
          fetching: false,
          data: action.payload
        }
      };
    case GET_PART_NUMBER_LIST_BY_FORMULA_ERROR:
      return {
        ...state,
        partNumberList: {
          ...state.partNumberList,
          fetching: false
        }
      };
    case SEND_EMAIL_TO_SAFETY_TEAM: {
      const productionCycleIds = [
        ...state.mail.safetyTeam.areSendingToSafetyTeamForProductionCycleIds,
        action.payload.param.productionCycleId
      ];

      return {
        ...state,
        mail: {
          ...state.mail,
          safetyTeam: {
            ...state.mail.safetyTeam,
            areSendingToSafetyTeamForProductionCycleIds: productionCycleIds
          }
        }
      };
    }
    case SEND_EMAIL_TO_SAFETY_TEAM_SUCCESS: {
      const areSendingToSafetyTeamForProductionCycleIds = state.mail.safetyTeam.areSendingToSafetyTeamForProductionCycleIds.filter(
        id => id !== action.payload.productionCycleId
      );

      return {
        ...state,
        mail: {
          ...state.mail,
          safetyTeam: {
            ...state.mail.safetyTeam,
            areSendingToSafetyTeamForProductionCycleIds
          }
        }
      };
    }
    case SEND_EMAIL_TO_SAFETY_TEAM_ERROR: {
      const areSendingToSafetyTeamForProductionCycleIds = state.mail.safetyTeam.areSendingToLabelingTeamForProductionCycleIds.filter(
        id => id !== action.payload.productionCycleId
      );

      return {
        ...state,
        mail: {
          ...state.mail,
          safetyTeam: {
            ...state.mail.safetyTeam,
            areSendingToSafetyTeamForProductionCycleIds
          }
        }
      };
    }
    case SEND_EMAIL_TO_LABELING_TEAM: {
      const productionCycleIds = [
        ...state.mail.labelingTeam
          .areSendingToLabelingTeamForProductionCycleIds,
        action.payload.param.productionCycleId
      ];
      return {
        ...state,
        mail: {
          ...state.mail,
          labelingTeam: {
            ...state.mail.labelingTeam,
            areSendingToLabelingTeamForProductionCycleIds: productionCycleIds
          }
        }
      };
    }
    case SEND_EMAIL_TO_LABELING_TEAM_SUCCESS: {
      const areSendingToLabelingTeamForProductionCycleIds = state.mail.labelingTeam.areSendingToLabelingTeamForProductionCycleIds.filter(
        id => id !== action.payload.productionCycleId
      );

      return {
        ...state,
        mail: {
          ...state.mail,
          labelingTeam: {
            ...state.mail.labelingTeam,
            areSendingToLabelingTeamForProductionCycleIds
          }
        }
      };
    }
    case SEND_EMAIL_TO_LABELING_TEAM_ERROR: {
      const areSendingToLabelingTeamForProductionCycleIds = state.mail.labelingTeam.areSendingToLabelingTeamForProductionCycleIds.filter(
        id => id !== action.payload.productionCycleId
      );

      return {
        ...state,
        mail: {
          ...state.mail,
          labelingTeam: {
            ...state.mail.labelingTeam,
            areSendingToLabelingTeamForProductionCycleIds
          }
        }
      };
    }
    case UPDATE_PRODUCTION_CYCLES:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      };
    case UPDATE_PRODUCTION_CYCLES_SUCCESS:
    case UPDATE_PRODUCTION_CYCLES_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false
        }
      };
    case DELETE_PRODUCTION_CYCLE:
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          fetching: true
        }
      };
    case DELETE_PRODUCTION_CYCLE_SUCCESS: {
      const {
        payload: { id }
      } = action;
      const clonedProductionCycleList = [...state.productionCycleList.data];
      const deletedProductionCycleIndex = clonedProductionCycleList.findIndex(
        productionCycle => productionCycle.id === id
      );
      if (deletedProductionCycleIndex > -1);
      clonedProductionCycleList.splice(deletedProductionCycleIndex, 1);
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          data: clonedProductionCycleList,
          fetching: false
        }
      };
    }
    case DELETE_PRODUCTION_CYCLE_ERROR: {
      return {
        ...state,
        productionCycleList: {
          ...state.productionCycleList,
          fetching: false
        }
      };
    }
    case SET_LOADING_DETERGENT:
      return {
        ...state,
        fetching: action.payload.fetching
      };
    case CHECK_RAW_MATERIAL_UNBLOCK:
      return {
        ...state,
        fetching: true,
        rawMaterialByDetergentId: {
          ...state.rawMaterialByDetergentId,
          data: {}
        }
      };
    case CHECK_RAW_MATERIAL_UNBLOCK_SUCCESS:
      return {
        ...state,
        fetching: false,
        rawMaterialByDetergentId: {
          ...state.rawMaterialByDetergentId,
          data: action.payload.data.data.results
        }
      };
    case CHECK_RAW_MATERIAL_UNBLOCK_ERROR:
      return {
        ...state,
        fetching: false,
        rawMaterialByDetergentId: {
          ...state.rawMaterialByDetergentId
        }
      };
    case GET_PRODUCTION_CYCLE_HISTORY_LIST:
      return {
        ...state,
        productionCycleHistoryList: {
          ...state.productionCycleHistoryList,
          fetching: true
        }
      };
    case GET_PRODUCTION_CYCLE_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        productionCycleHistoryList: {
          data: action.payload,
          fetching: false
        }
      };
    case GET_PRODUCTION_CYCLE_HISTORY_LIST_ERROR:
      return {
        ...state,
        productionCycleHistoryList: {
          ...state.productionCycleHistoryList,
          fetching: false
        }
      };
    default:
      return state;
  }
}
