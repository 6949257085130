import Axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import { AxiosService } from '../services/axiosService';
// // file = url
// export const uploadToS3 = (name, file) => {
//   const data = {
//     name,
//     file
//   };
//   const formData = convertJsonToFormData(data);

//   return AxiosService.post('s3-service', formData);
// };

export const getS3PresignedUrl = async (name, contentType) => {
  return AxiosService.get('s3-presigned-url', {
    name,
    contentType
  });
};

export const uploadToPresignedUrlS3 = (presignedUrl, file, contentType) => {
  return Axios.put(presignedUrl, file, {
    headers: {
      'Content-Type': contentType
    }
  });
};

export const getPresignedUrlAndUpload = async (
  name,
  contentType,
  file,
  isReturnPresigned = false
) => {
  const presignedUrlResponse = await getS3PresignedUrl(name, contentType);
  const s3Url = uploadToPresignedUrlS3(
    presignedUrlResponse.data.data,
    file,
    contentType
  );
  return isReturnPresigned ? presignedUrlResponse.data.data : s3Url;
};

export const getS3FileUrl = async key => {
  const url = await AxiosService.get('/s3-presigned-url/get', {
    key
  });

  return url;
};

export const uploadDocumentsWithCategory = async document => {
  if (!document) return [];

  const documentList = Array.isArray(document) ? document : [document];

  const existDocuments = [];
  const newDocuments = [];

  const promises = documentList.map(async documentItem => {
    if (!documentItem.file) {
      existDocuments.push(documentItem);
    } else {
      const { file, id } = documentItem;
      const dotSplitter = file.name.split('.');
      const extension = dotSplitter[dotSplitter.length - 1];
      const key = `document_${new Date().getTime()}_${
        file.name
      }${uuidv1()}.${extension}`;
      const url = await getPresignedUrlAndUpload(key, file.type, file, true);

      // Remove property file object after uploaded
      delete documentItem.file;

      newDocuments.push({ ...documentItem, key, url, id });
    }
  });

  await Promise.all(promises);
  return [...existDocuments, ...newDocuments];
};
