import React from "react";
import { BaseModal } from "../../../../components/BaseModal";
import { ProducerForm } from "../Form";

export const ProducerContactModal = props => {
    return (
        <BaseModal
            title={props.title}
            width="58%"
            maskClosable={false}
            visible={props.isVisible}
            className="raw-material-common-modal"
            onCancel={() => props.onCancel()}
            >
            {props.isVisible && <ProducerForm status={props.status} onCancel={() => props.onCancel()} />}
        </BaseModal>
    )
}
