export const getInitialStates = () => ({
  isCancel: false,
  uploadedFiles: [],
  isPrecalculationSelected: false,
  isHandleGeneratedFileComplete: false,
  generatedCompleteFiles: [],
  isOkClicked: false
});

export const ON_MODAL_OPEN = 'ON_MODAL_OPEN';
export const ON_CLEAR = 'ON_CLEAR';
export const ON_OK = 'ON_OK';
export const ON_PRECALCULATION = 'ON_CLICK_PRECALCULATION_CHECKBOX';
export const ON_FILES_GENERATED_COMPLETE = 'ON_FILES_GENERATED_COMPLETE';
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const ON_OPEN = 'ON_OPEN';

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_MODAL_OPEN: {
      const { documents } = payload;
      const uploadedFiles = documents.map(
        ({ key, name, category, productionCycles }) => {
          return {
            key,
            name,
            category,
            productionCycles,
            isSelected: false
          };
        }
      );

      return {
        ...state,
        isCancel: false,
        uploadedFiles
      };
    }

    case ON_CLEAR:
      return {
        ...getInitialStates(),
        isCancel: payload.isCancel,
        isHandleGeneratedFileComplete: payload.isHandleGeneratedFileComplete ?? null,
        isPrecalculationSelected: payload.isPrecalculationSelected ?? null,
        isOkClicked: payload.isOkClicked ?? null
      };

    case ON_OK:
      return {
        ...state,
        isOkClicked: true
      };

    case ON_OPEN:
      return {
        ...state,
        isCancel: false
      };

    case ON_PRECALCULATION:
      return {
        ...state,
        isPrecalculationSelected: payload.value
      };

    case ON_FILES_GENERATED_COMPLETE:
      return {
        ...state,
        generatedCompleteFiles: payload.generatedCompleteFiles,
        isHandleGeneratedFileComplete: true
      };
    case SET_UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: payload.files
      };

    default:
      return state;
  }
};
