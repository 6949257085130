const DOCUMENT_CATEGORY = {
  1: 'Lab project',
  2: 'Development request',
  3: 'Initial production test protocol',
  4: 'Production test protocol',
  5: 'Safety data sheet',
  6: 'Parts list',
  7: 'Product label',
  8: 'Transport label',
  9: 'Labeling instructions',
  10: 'Other'
};

export const groupedDocumentsByCategory = documents => {
  if (!documents || !Array.isArray(documents)) return {};

  const groupedDocuments = {};

  documents.forEach(document => {
    const key = DOCUMENT_CATEGORY[document.category];
    if (key in groupedDocuments) {
      groupedDocuments[key].push(document);
    } else {
      groupedDocuments[key] = [document];
    }
  });

  return Object.keys(groupedDocuments)
    .sort()
    .reduce((object, key) => {
      object[key] = groupedDocuments[key].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return object;
    }, {});
};

export const sortDocumentsByCategoryAndName = documents => {
  if (!documents || !Array.isArray(documents)) return [];

  const result = [];

  const groupedDocuments = groupedDocumentsByCategory(documents);

  Object.keys(groupedDocuments).forEach(key => {
    groupedDocuments[key].forEach(document => {
      result.push(document);
    });
  });

  return result;
};
