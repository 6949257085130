export const validateDensityForPartnumber = (
  detergentFormulas,
  formatMessage
) => (rule, value, callback) => {
  try {
    const selectedFormulaDensity = detergentFormulas.find(
      formual => formual?.id === value
    )?.density;

    if (selectedFormulaDensity === null) {
      throw new Error(
        formatMessage({
          id: 'error.densityInPartNumberIsNull',
          defaultMessage: 'Density is required'
        })
      );
    }
    callback();
  } catch (error) {
    callback(error);
  }
};
