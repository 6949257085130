import React from 'react';
import { Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  ACTION_BUTTON_TYPES,
  MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME,
  DISPLAY_PART_NUMBER_STATUS
} from '../../../../configs/constant';
import { ProtectedComponent } from '../../../../components/ProtectedComponent';
import { ROLES_OF_PERMISSIONS } from '../../../../configs/rolesOfPermissions';
import { changePointToComma } from '../../../../utils/common';
import { checkPermissionByRoles } from '../../../../utils/checkPermission';
import UpdatePartNumberStatus from '../Form/UpdatePartNumberStatus';

export const renderPartNumberColumns = ({
  onViewPartNumberDetail,
  onEditPartNumber
}) => [
  {
    title: (
      <FormattedMessage
        id="partNumber.form.partNumber"
        defaultMessage="Part number"
      />
    ),
    key: 'partNumber',
    width: '10%',
    render: record => {
      return <>{record?.partNumber}</>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="detergent.number"
        defaultMessage="Detergent number"
      />
    ),
    key: 'detergentNumber',
    width: '8%',

    render: record => {
      return <>{record?.detergentFormula?.detergent?.number}</>;
    }
  },
  {
    title: (
      <FormattedMessage id="detergent.name" defaultMessage="Detergent name" />
    ),
    key: 'detergentName',
    width: '15%',
    render: record => {
      return <>{record?.detergentFormula?.detergent?.name}</>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnFormulaVersion"
        defaultMessage="Formula Version"
      />
    ),
    key: 'version',
    width: '8%',
    render: record => {
      return <>{record?.detergentFormula?.version}</>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnStatus"
        defaultMessage="Product Status"
      />
    ),
    key: 'status',
    className: `${ACTION_BUTTON_TYPES.PREVENT_ON_ROW_TRIGGER} part-number-status-cell`,
    render: record => {
      if (
        !checkPermissionByRoles(
          ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.EDIT
        )
      ) {
        return (
          <div
            className={MAPPING_PART_NUMBER_STATUS_TO_CLASS_NAME[record.status]}>
            {DISPLAY_PART_NUMBER_STATUS[record?.status]}
          </div>
        );
      }

      return <UpdatePartNumberStatus partNumber={record} />;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumber.form.detergentProducer"
        defaultMessage="Detergent Producer"
      />
    ),
    key: 'detergentProducer',
    width: '10%',

    render: record => {
      return <>{record?.detergentProducer?.name}</>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.containerSize"
        defaultMessage="Container Size"
      />
    ),
    className: 'text-align-right-important',
    key: 'containerSize',
    width: '8%',
    render: record => {
      return <>{changePointToComma(record?.containerSize)}</>;
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.annualDemandTitle"
        defaultMessage="Annual Demand"
      />
    ),
    className: 'text-align-right-important',
    key: 'annualDemands',
    width: '12%',

    render: record => {
      const currentYear = new Date().getFullYear();
      const currentYearAnnualDemand = record?.annualDemands.find(
        annualDemand => annualDemand.year === currentYear
      );
      return (
        <div className="annual-demand-item">
          {currentYearAnnualDemand?.numberOfContainers}
        </div>
      );
    }
  },
  {
    title: (
      <FormattedMessage
        id="partNumbers.columnAnnualTonnage"
        defaultMessage="Annual Tonnage"
      />
    ),
    className: 'text-align-right-important',
    key: 'annualTonnage',
    width: '12%',

    render: record => {
      const currentYear = new Date().getFullYear();
      const currentYearAnnualDemand = record?.annualDemands.find(
        annualDemand => annualDemand.year === currentYear
      );
      return (
        <div className="annual-tonnage">
          <span className="annual-tonnage-item">
            {changePointToComma(currentYearAnnualDemand?.annualTonnage)}
          </span>
          <div className="action-col">
            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.READ}>
              <Tooltip
                placement="bottomLeft"
                title={
                  <FormattedMessage
                    id="rawMaterial.columnViewDetails"
                    defaultMessage="View details"
                  />
                }>
                <Button
                  type="link"
                  onClick={() => onViewPartNumberDetail(record)}>
                  <img
                    className="action-item"
                    alt=""
                    src="/icon/ic-view-details.svg"
                  />
                </Button>
              </Tooltip>
            </ProtectedComponent>

            <ProtectedComponent
              allowRoles={ROLES_OF_PERMISSIONS.PART_NUMBER_ALLOCATION.EDIT}>
              <Button type="link" onClick={() => onEditPartNumber(record)}>
                <img
                  className={`${ACTION_BUTTON_TYPES.EDIT_PARTNUMBER_ACTION} action-item`}
                  alt=""
                  src="/icon/ic-edit.svg"
                />
              </Button>
            </ProtectedComponent>
          </div>
        </div>
      );
    }
  }
];
