import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import { MEASUREMENT_MAPPING_FIELD } from '../../../../configs/constant';
import EditableColumnField from '../../../../components/EditableColumnField';
import { changePointToComma } from '../../../../utils/common';
import FormattedInputNumber from '../../../../components/FormattedInputNumber';

const nonDisplayTestFields = [
  'waterSolubility',
  'dryWeightMettlerIRdryer155',
  'appearanceColourSmell',
  'mass',
  'dimensions',
  'durability',
  'recommendedStorageTemparature'
];

const nonDisplayMeasuredValues = [
  'appearanceColourSmell',
  'durability',
  'recommendedStorageTemparature'
];
export const renderMeasurementTestsColumns = isEditable => [
  {
    title: (
      <FormattedMessage
        id="measurement.testTable.title"
        defaultMessage="TESTS"
      />
    ),
    key: 'cellKeyTest',
    render: data => {
      return _.get(
        MEASUREMENT_MAPPING_FIELD,
        `${data.cellKeyTest}.name`,
        `No label: ${data.cellKeyTest}`
      );
    },
    width: '30%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.measured.title"
          defaultMessage="Measured value"
        />
      </div>
    ),
    key: 'measured',
    width: '15%',
    render: record => {
      if (nonDisplayMeasuredValues.includes(record.cellKeyTest)) {
        return <span />;
      }
      if (record.cellKeyTest === 'densityDIN51757') {
        return (
          <EditableColumnField
            tabName="measurement"
            editable={isEditable}
            readableField={
              <Input
                className="measure-display"
                defaultValue={changePointToComma(record.measured)}
                disabled
              />
            }
            editableField={
              <Input
                className="measure-edit"
                defaultValue={changePointToComma(record.measured)}
                disabled
              />
            }
            columnKey="measured"
            cellKey={record.cellKeyTest}
          />
        );
      }
      return (
        <EditableColumnField
          tabName="measurement"
          editable={isEditable}
          readableField={
            <FormattedInputNumber
              className="measure-display"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          editableField={
            <FormattedInputNumber
              className="measure-edit"
              defaultValue={record.measured}
              disabled={!isEditable}
            />
          }
          columnKey="measured"
          cellKey={record.cellKeyTest}
        />
      );
    }
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage id="measurement.min.title" defaultMessage="Minimum" />
      </div>
    ),
    key: 'min',
    render: record => {
      const isAvailable = !nonDisplayTestFields.includes(record.cellKeyTest);

      return (
        <>
          {isAvailable ? (
            <EditableColumnField
              tabName="measurement"
              editable={isEditable}
              readableField={
                <FormattedInputNumber
                  className="measure-display"
                  defaultValue={record.min}
                  disabled={!isEditable}
                />
              }
              columnKey="min"
              cellKey={record.cellKeyTest}
              editableField={
                <FormattedInputNumber
                  className="measure-edit"
                  defaultValue={record.min}
                  disabled={!isEditable}
                />
              }
            />
          ) : (
            <span />
          )}
        </>
      );
    },
    width: '15%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.max.title"
          defaultMessage="Maximum value"
        />
      </div>
    ),
    key: 'max',
    render: record => {
      const isAvailable = !nonDisplayTestFields.includes(record.cellKeyTest);

      return (
        <>
          {isAvailable ? (
            <EditableColumnField
              tabName="measurement"
              editable={isEditable}
              readableField={
                <FormattedInputNumber
                  className="measure-display"
                  defaultValue={record.max}
                  disabled={!isEditable}
                />
              }
              editableField={
                <FormattedInputNumber
                  className="measure-edit"
                  defaultValue={record.max}
                  disabled={!isEditable}
                />
              }
              columnKey="max"
              cellKey={record.cellKeyTest}
            />
          ) : (
            <span />
          )}
        </>
      );
    },
    width: '15%'
  },
  {
    title: (
      <div className="measurement-title">
        <FormattedMessage
          id="measurement.comment.title"
          defaultMessage="Comment"
        />
      </div>
    ),
    key: 'comment',
    render: record => (
      <EditableColumnField
        tabName="measurement"
        editable={isEditable}
        readableField={
          <Input.TextArea
            className="measure-display"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
        columnKey="comment"
        cellKey={record.cellKeyTest}
        editableField={
          <Input.TextArea
            className="measure-edit-comment"
            defaultValue={record.comment}
            disabled={!isEditable}
            autoSize={{ maxRows: 3 }}
          />
        }
      />
    ),
    width: '25%'
  }
];
