import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import assign from 'lodash/assign';

import { Form, Button, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import EditDetergent from './EditDetergent';
import EditRawMaterialFormula from './EditRawMaterialFormula';
import {
  getMeasurement,
  updateDetergentFormula,
  setMeasurementDataForm,
  getDetergentProductionCycleList
} from '../../../data/detergent/actions';
import MeasurementData from '../../MeasurementData';
import Precalculation from '../../Precalculation';
import { OverlayLoading } from '../../../../../components/Loading';
import EditProductionCyclesTab from '../EditProductionCyclesTab';
import { getPartNumberNonPaging } from '../../../../../data/partNumberNonPaging/action';

import { ROLES_OF_PERMISSIONS } from '../../../../../configs/rolesOfPermissions';
import { checkPermissionByRoles } from '../../../../../utils/checkPermission';
import { transformProductionCycleDataBeforeSaving } from '../../Util/productionCycle/transformProductionCycleDataBeforeSaving';
import {
  changeCommaToPoint,
  getFromCountry
} from '../../../../../utils/common';
import {
  STATUS_ENUM,
  UPDATE_FORMULA_STATUS
} from '../../../../../configs/constant';

// eslint-disable-next-line no-underscore-dangle
const _FormEditModal = props => {
  // Parent Form
  const dispatch = useDispatch();
  const { form, onCancel } = props;
  const [activeTab, setActiveTab] = useState('1');
  const [groupPartNumbersById, setGroupPartNumbersById] = useState({});

  const {
    detergent,
    measurementData,
    measurementDataForm,
    precalculationRawMaterials,
    modalLoading,
    isMeasurementFetching,
    isPartNumberNonPagingFetching,
    partNumberNonPagingData,
    countries
  } = useSelector(state => ({
    modalLoading: state.detergent.modal.loading,
    detergent: JSON.parse(JSON.stringify(state.detergent.emptyDetergent)),
    measurementData: state.detergent.measurementData.data,
    isMeasurementFetching: state.detergent.measurementData.isFetching,
    measurementDataForm: JSON.parse(
      JSON.stringify(state.detergent.measurementDataForm)
    ),
    precalculationRawMaterials: JSON.parse(
      JSON.stringify(state.detergent.precalculationForm.list)
    ),
    isPartNumberNonPagingFetching: state.partNumberNonPaging.isFetching,
    partNumberNonPagingData: state.partNumberNonPaging.data,
    countries: state.countries.list
  }));

  const currentFormulaId = detergent.formulas[0].id;

  const isFetching = modalLoading || isPartNumberNonPagingFetching;

  const disableSaveWhenStatusIsApproved =
    detergent?.formulas?.[0]?.status === STATUS_ENUM.APPROVED;

  useEffect(() => {
    dispatch(getMeasurement({ id: detergent.id, formulaId: currentFormulaId }));
    dispatch(
      getPartNumberNonPaging({
        formulaId: currentFormulaId
      })
    );
    dispatch(getDetergentProductionCycleList({ formulaId: currentFormulaId }));
  }, []);

  useEffect(() => {
    const groupPartNumbers = {};
    partNumberNonPagingData.forEach(partNumberItem => {
      groupPartNumbers[partNumberItem.id] = partNumberItem;
    });

    setGroupPartNumbersById(groupPartNumbers);
  }, [partNumberNonPagingData]);

  useEffect(() => {
    dispatch(setMeasurementDataForm(measurementData));
  }, [isMeasurementFetching]);

  const onChangeTab = tab => {
    setActiveTab(tab);
  };

  const update = values => {
    const detergentId = detergent.id;
    const formulaId = detergent.formulas[0].id;

    // Prepare detergent
    const newDetergent = { ...detergent, ...values };
    delete newDetergent.productionCycles;

    let newRawMaterialRatiosValue = values.formulas[0].rawMaterialRatios;
    if (newRawMaterialRatiosValue) {
      newRawMaterialRatiosValue = newRawMaterialRatiosValue.map(
        rawMaterialRatio => ({
          ...rawMaterialRatio,
          detergentFormulaId: currentFormulaId
        })
      );
    }
    const newFormula = {
      ...detergent.formulas[0],
      ...values.formulas[0],
      rawMaterialRatios: newRawMaterialRatiosValue
    };

    const densityChange = newFormula.density;
    _.set(newFormula, 'density', changeCommaToPoint(densityChange));
    UPDATE_FORMULA_STATUS.forEach(item => {
      if (newFormula.status.props && item.id === newFormula.status.props.id) {
        _.set(newFormula, 'status', item.value);
      } else {
        _.set(newFormula, 'status', newFormula.status);
      }
    });

    newDetergent.formulas[0] = newFormula;

    // Prepare measurement
    const updateMeasurementData = assign(measurementData, measurementDataForm);

    // {
    //   ...measurementData,
    //   ...measurementDataForm
    // };

    // Prepare production cycles
    let productionCycles = [];
    if (checkPermissionByRoles(ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.EDIT)) {
      productionCycles = transformProductionCycleDataBeforeSaving(
        values,
        groupPartNumbersById
      );
    }

    // Prepare precalculation
    const submitPrecalculation = precalculationRawMaterials.map(
      precalculation => {
        return {
          id: precalculation?.rawMaterial?.id,
          price: precalculation?.rawMaterial?.price,
          currency: precalculation?.rawMaterial?.currency,
          name: precalculation?.rawMaterial?.name
        };
      }
    );
    _.set(
      newDetergent,
      'fromCountry',
      getFromCountry(countries, newDetergent.fromCountryId)
    );
    dispatch(
      updateDetergentFormula({
        detergentId,
        formulaId,
        detergent: newDetergent,
        containerSize: detergent?.formulas?.[0]?.containerSize || 0,
        precalculationRawMaterials: submitPrecalculation,
        measurementDataForm: updateMeasurementData,
        productionCycles
      })
    );
  };

  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        update(values);
      }
    });
  };

  return (
    <OverlayLoading loading={isFetching}>
      <Form className="detergent-detail" onSubmit={handleSubmit}>
        <EditDetergent form={form} />

        <Tabs
          className="tabs-detergent-history"
          onChange={onChangeTab}
          defaultActiveKey={activeTab}>
          <Tabs.TabPane
            className="editable-raw-material-tab"
            tab={
              <FormattedMessage
                id="detergent.rawMaterialListTabTitle"
                defaultMessage="Raw Material"
              />
            }
            key="1">
            <EditRawMaterialFormula form={form} />
          </Tabs.TabPane>

          <Tabs.TabPane
            className="measurement-details"
            tab={
              <FormattedMessage
                id="detergent.measurementDataTabTitle"
                defaultMessage="Measurement Data"
              />
            }
            key="2">
            <MeasurementData isEditable />
          </Tabs.TabPane>

          <Tabs.TabPane
            className="precalculation-details"
            tab={
              <FormattedMessage
                id="detergent.precalculationTabTitle"
                defaultMessage="Precalculation"
              />
            }
            key="3">
            <Precalculation currentData={detergent} isEditable />
          </Tabs.TabPane>

          {checkPermissionByRoles(
            ROLES_OF_PERMISSIONS.PRODUCTION_CYCLES.EDIT
          ) ? (
            <Tabs.TabPane
              className="production-cycle-list"
              tab={
                <FormattedMessage
                  id="detergent.productionCycleTabTitle"
                  defaultMessage="Production Cycles"
                />
              }
              key="4">
              <EditProductionCyclesTab detergent={detergent} form={form} />
            </Tabs.TabPane>
          ) : null}
        </Tabs>

        <div className="form-btn-actions">
          <Button className="btn-cancel" onClick={onCancel}>
            <FormattedMessage
              id="common.cancelButton"
              defaultMessage="CANCEL"
            />
          </Button>
          <Button
            className="btn-save"
            type="primary"
            htmlType="submit"
            disabled={disableSaveWhenStatusIsApproved}>
            <FormattedMessage id="common.saveButton" defaultMessage="SAVE" />
          </Button>
        </div>
      </Form>
    </OverlayLoading>
  );
};

_FormEditModal.propTypes = {
  form: PropTypes.objectOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired
};

export const FormEditModal = Form.create({ name: 'form-edit-detergent' })(
  _FormEditModal
);
