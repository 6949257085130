import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Modal } from 'antd';

const { confirm } = Modal;
// open deactive raw material/detergent producers confirm modal
export const openDeactiveProducerConfirmModal = props => {
  confirm({
    className: 'base-confirm-modal',
    title: props.title,
    content: props.content,
    okText: props.intl.formatMessage({
      id: 'common.yesButton',
      defaultMessage: 'YES'
    }),
    okType: 'danger',
    cancelText: props.intl.formatMessage({
      id: 'common.noButton',
      defaultMessage: 'NO'
    }),
    icon: null,
    onOk() {
      if (props.type !== null) {
        return props.deactiveProducerHandler(props.record, props.type);
      }
      return props.deactiveProducerHandler(props.record);
    },
    onCancel() {
      props.onCancel && props.onCancel(props);
    }
  });
};

export const openConfirmModal = props => {
  const { intl, title, type, content, record, actionHandler, onCancel } = props;
  confirm({
    className: 'base-confirm-modal',
    title,
    content,
    okText: intl.formatMessage({
      id: 'productionCycle.yes',
      defaultMessage: 'Yes'
    }),
    okType: 'danger',
    cancelText: intl.formatMessage({
      id: 'productionCycle.no',
      defaultMessage: 'No'
    }),
    icon: null,
    onOk() {
      return actionHandler(record);
    },
    onCancel() {
      onCancel && onCancel(props);
    }
  });
};
